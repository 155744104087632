import WarrantyService from "../../services/WarrantyService";
import {isPastDate} from "@/utils/DateHelper"

const api = new WarrantyService();

const getDefaultState = () => {
  return {
    warrantyApartments: [],
    warrantyDeadlines: [],
    warrantySelectedApartment: null,
    apartmentContactInfo: null,
    apartmentRepairs: null
  };
};

const state = getDefaultState();

const getters = {
  getWarrantyApartments: state => state.warrantyApartments,
  getWarrantyDeadlines: state => state.warrantyDeadlines,
  getWarrantySelectedApartment: state => state.warrantySelectedApartment,
  getApartmentContactInfo: state => state.apartmentContactInfo,
  getApartmentRepairs: state => state.apartmentRepairs,
  getApartmentFaultById: state => faultId => {
    console.log("REPAIRS: ", state.apartmentRepairs)
    if (state.apartmentRepairs) {
      return state.apartmentRepairs.faultCategories.find(item => item.id == faultId);
    } else {
      return null;
    }

  },
  getIsWarrantyReportingActive: state => {
    if (state.warrantyDeadlines) {
      const repairEnd = state.warrantyDeadlines.find(function(item) {
        return item.phase == "REPAIREND";
      });
      if (repairEnd) {
        return !isPastDate(repairEnd.deadline);
      }
    }

    return false;
  }
};

const actions = {
  fetchWarrantyApartments({ commit }) {
    return new Promise(function (resolve, reject) {
      api
        .getApartments()
        .then(response => {
          console.log("Fetched Warranty Apartments: ", response.data);
          if (response && response.data.length == 1) {
            commit("setWarrantySelectedApartment", response.data[0])
          }
          commit("setWarrantyApartments", response.data);
          resolve(response.data)
        })
        .catch(err => {
          console.log("Fetching warranty apartments failed: ", err);
          reject(err);
        });
    });
  },
  fetchWarrantyDeadlines({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getDeadlines()
        .then(response => {
          console.log("Fetched Warranty Deadlines: ", response.data);
          commit("setWarrantyDeadlines", response.data);
          resolve(response.data)
        })
        .catch(err => {
          console.log("Fetching warranty Deadlines failed: ", err);
          reject(err)
        });
    });
  },
  saveApartmentContactInfo({ commit }, contactInfo) {
    return new Promise((resolve, reject) => {
      api
        .saveApartmentContactInfo(contactInfo)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          console.log("error inserting new contactInfo");
          reject(err);
        });
    });
  },
  saveRepairItems({ commit }, faultData) {
    return new Promise((resolve, reject) => {
      api
        .saveRepairItems(faultData)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          console.log("error inserting new fault");
          reject(err);
        });
    });
  },
  fetchApartmentContactInfo({ commit }, aptId) {
    return new Promise(function (resolve, reject) {
      api
        .getApartmentContactInfo(aptId)
        .then(response => {
          console.log("Fetched Warranty getApartmentContactInfo: ", response.data);
          commit("setApartmentContactInfo", response.data);
          resolve(response.data)
        })
        .catch(err => {
          console.log("Fetching warranty getApartmentContactInfo failed: ", err);
          reject(err);
        });
    });
  },
  fetchRepairsByApartment({ commit }, aptId) {
    return new Promise(function (resolve, reject) {
      api
        .getRepairsByApartment(aptId)
        .then(response => {
          console.log("Fetched Warranty getApartmentContactInfo: ", response.data);
          commit("setApartmentRepairs", response.data);
          resolve(response.data)
        })
        .catch(err => {
          console.log("Fetching warranty getApartmentContactInfo failed: ", err);
          reject(err);
        });
    });
  },
}

const mutations = {
  setWarrantyApartments: (state, res) => (state.warrantyApartments = res),
  setWarrantyDeadlines: (state, res) => (state.warrantyDeadlines = res),
  setWarrantySelectedApartment: (state, res) => (state.warrantySelectedApartment = res),
  setApartmentContactInfo: (state, res) => (state.apartmentContactInfo = res),
  setApartmentRepairs: (state, res) => (state.apartmentRepairs = res),
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
