<template>
  <div class="feedback">
    <div v-if="!feedbackSent">
      <AlertMessage type="error" v-if="errorMessage">{{errorMessage}}</AlertMessage>
      <strong>{{$t('feedback.title')}}</strong>
      <ul class="feedback__select">
        <li
          v-for="item in items"
          :key="item.cssClass"
          :class="{'active': form.selectedItem && form.selectedItem.cssClass == item.cssClass}"
        >
          <a href="#" @click="selectItem(item, $event)">
            <i :class="item.cssClass"></i>
          </a>
        </li>
      </ul>
      <div class="input-group" v-if="form.selectedItem">
        <label for>{{$t('feedback.comment.label')}}</label>
        <textarea name="comment" v-model="form.comment"></textarea>
        <Button
          @click="sendFeedback"
          class="button button--primary button--cta"
          :loading="loading"
        >{{$t('feedback.send')}}</Button>
      </div>
    </div>
    <Modal v-if="feedbackSent" @close="reset">
      <strong>{{$t('feedback.modal.title')}}</strong>
      <p>{{$t('feedback.modal.text')}}</p>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/common/Modal";
import AlertMessage from "@/components/common/AlertMessage";
import FeedbackService from "@/services/FeedbackService";

export default {
  name: "Feedback",
  components: {
    Modal,
    AlertMessage
  },
  data() {
    return {
      api: new FeedbackService(),
      errorMessage: "",
      items: [
        { cssClass: "fas fa-grin-beam", value: 5 },
        { cssClass: "fas fa-smile-beam", value: 4 },
        { cssClass: "fas fa-meh", value: 3 },
        { cssClass: "fas fa-frown", value: 2 },
        { cssClass: "fas fa-sad-tear", value: 1 }
      ],
      form: {
        selectedItem: null,
        comment: ""
      },
      feedbackSent: false,
      loading: false
    };
  },
  methods: {
    reset() {
      this.feedbackSent = false;
      this.form.comment = "";
      this.form.selectedItem = null;
      this.errorMessage = "";
    },
    selectItem(item, event) {
      if (event) event.preventDefault();
      if (this.form.selectedItem) {
        if (item.cssClass == this.form.selectedItem.cssClass) {
          this.form.selectedItem = null;
        } else {
          this.form.selectedItem = item;
        }
      } else this.form.selectedItem = item;
    },
    sendFeedback() {
      this.loading = true;
      this.api
        .postFeedback({
          comment: this.form.comment,
          rating: this.form.selectedItem.value
        })
        .then(res => {
          this.feedbackSent = true;
          this.loading = false;
          try {
            if (window.localStorage)
              localStorage.setItem("feedback-sent", new Date().toString());
          } catch (err) {
            console.log(err);
          }
        })
        .catch(e => {
          this.loading = false;
          this.errorMessage =
            "Something wen't wrong. Could not send feedback. Please try to send it again.";
        });
    }
  },
  mounted() {
    this.loading = false;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
.feedback {
  .feedback__select {
    list-style: none;
    font-size: 1.8rem;
    padding: 1rem 0 0 0;
    li {
      display: inline-block;
      margin-right: 1rem;
      a {
        i {
          transition: transform 0.15s ease-in-out;
        }
        i:hover,
        i:focus {
          transform: scale(1.1);
        }
      }

      &.active {
        a {
          color: $blueMedium;
          i {
            transform: scale(1.3);
          }
        }
      }
    }
  }
  .input-group {
    textarea,
    label {
      display: block;
    }
    textarea {
      min-height: 100px;
      width: 100%;
    }
  }

  /*button {
    .loader-icon {
      margin: 0;
      top: 0.3rem;
      margin-right: 0.5rem;
    }
  }*/
}
</style>