<template>
  <PageWrapperMain>
    <div class="dashboard">

      <PageSection :unstyled="true" v-if="$App.getFeature('calendar')">
        <template v-slot:header>
          <h2>{{ $t("homepage.common.tasks-and-events") }}</h2>
        </template>
        <CalendarList :limit="4" :noItemsText="$t('calendar.messages.noItemsText')" @on-items-to-display="displayRemindersLink=true" />
        <template v-slot:footer>
          <div class="text-center read-more" v-if="displayRemindersLink">
            <router-link to="/news" class="read-more">{{
              $t("homepage.show-more-tasks-and-events")
            }}</router-link>
          </div>
        </template>
      </PageSection>

      <template v-if="$App.getFeature('news')">
        <PageSection :unstyled="true">
          <template v-slot:header>
            <h2>{{ $t("common.heading.news") }}</h2>
          </template>
          <NewsList mode="latest" limit="4" />
          <template v-slot:footer>
            <div class="text-center read-more">
            <router-link class="read-more" to="/news">{{
              $t("common.check.other.news")
            }}</router-link>
            </div>
          </template>
        </PageSection>
      </template>

      <PageSection :unstyled="true">
        <template v-slot:header>
          <h2>{{ $t("dashboard.quicklinks.title") }}</h2>
        </template>
        <QuickLinks />
      </PageSection>

      <PageSection :unstyled="true" v-if="$App.getFeature('service-lounge')" class="dashboard-campaign-section">
        <template v-slot:header>
          <h2>{{ $t("homepage.common.campaigns") }}</h2>
        </template>
        <CampaignListMerged :count="6" gridClass="col-md-6" :fetch="true" sortBy="random" @on-no-items-to-display="displayCampaignsSection=false" v-if="displayCampaignsSection" />
        <ServiceLoungeBanner v-if="!displayCampaignsSection"
          :title="$t('serviceshop.banner.title')"
          :ctaButtonText="$t('serviceshop.banner.cta.title')"
          :ctaButtonLink="{name: '/service-shop/'}"
        />
        <template v-slot:footer>
          <div class="text-center read-more" v-if="displayCampaignsSection">
            <router-link class="read-more" to="/service-shop/">{{
              $t("common.check.other.campaigns")
            }}</router-link>
          </div>
        </template>
      </PageSection>

      <PageSection :unstyled="true" :title="$t('homepage.common.recommended-articles')" v-if="$App.getFeature('atp-feed')">
         <template v-slot:header>
          <h2>{{ $t("atp.latest.title") }}</h2>
        </template>
        <ATPLatestItems />
        <template v-slot:footer>
          <div class="text-center read-more">
            <router-link class="read-more" to="/isannointiliitto/archive">{{
              $t("homepage.show-more-tasks-and-events")
            }}</router-link>
          </div>
        </template>
      </PageSection>

      <PageSection :unstyled="true" :title="$t('homepage.common.yit-news')" v-if="$App.getFeature('yit-corporation-news')">
        <template v-slot:header>
          <h2>{{ $t("homepage.common.yit-news") }}</h2>
        </template>
        <YitCorporateNews />
        <template v-slot:footer>
          <div class="text-center read-more">
            <router-link class="read-more" to="/yit-news-archive">{{
              $t("homepage.show-more-tasks-and-events")
            }}</router-link>
          </div>
        </template>
      </PageSection>

      <PageSection
        :title="$t('homepage.common.feedback')"
        :hideHeader="true"
        :unstyled="true"
        class="page-section--align-right"
      >
        <Feedback />
      </PageSection>
    </div>
    
    <WelcomeDialog v-if="displayWelcomeDialog" />

  </PageWrapperMain>
</template>

<script>
import CalendarList from "@/components/Calendar/List";
import CampaignListMerged from "@/components/Campaign/ListMerged";
import NewsList from "@/components/News/List";
import Feedback from "@/components/Feedback";
import PageWrapperMain from "@/components/common/PageWrapperMain";
import { mapGetters, mapActions } from "vuex";
import ATPLatestItems from "@/components/ATP/LatestItems";
import YitCorporateNews from "@/components/News/Yit/News";
import BookingsSnippet from "@/components/ServiceLounge/BookingsSnippet";
import WelcomeDialog from "@/components/Steps/WelcomeDialog";
import QuickLinks from "@/components/QuickLinks/List";
import ServiceLoungeBanner from "@/components/ServiceLounge/Banner";

export default {
  name: "Dashboard",
  components: {
    CalendarList,
    CampaignListMerged,
    NewsList,
    PageWrapperMain,
    Feedback,
    ATPLatestItems,
    YitCorporateNews,
    BookingsSnippet,
    WelcomeDialog,
    QuickLinks,
    ServiceLoungeBanner
  },
  data() {
    return {
      displayRemindersLink: false,
      displayCampaignsSection: true
    }
  },
  computed: {
    ...mapGetters(["getCurrentHcState", "getPendingBookingsWaitingClientConfirmation", "getSelectedApartment", "getSteps", "getCurrentUser", "isDialogRead", "isLifecycleStepsActive"]),
    isApartmentSelected() {
      return this.getSelectedApartment && this.getSelectedApartment.aptext;
    },
    displayWelcomeDialog() {
      if(this.getCurrentHcState == 'construction' && this.getSteps.length) {
        if(this.isLifecycleStepsActive) {
          if(!this.isDialogRead('welcome-to-plus-dialog')) {
            return true;
          }
        }
      }

      return false;
    }
  },
  methods: {
    ...mapActions(['fetchCurrentProjectSteps'])
  },
  async mounted() {
    this.fetchCurrentProjectSteps();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/variables";
.scrollableList {
  display: flex;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 16px;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
</style>
