<template>
  <div class="hc-list site-header--fixed--top-by-height" :class="{'hc-list--open': open}" v-if="getUserDetails.hcs && getUserDetails.hcs.length">
    <div class="hc-list__inner">
      <button
        class="hc-list__toggle color--white"
        :class="{'hc-list__toggle--inactive': getUserDetails.hcs.length === 1}"
        @click="
          getUserDetails.hcs.length > 1 ||
          (getUserDetails.hcs[0].apts && getUserDetails.hcs[0].apts.length > 1)
            ? $emit('toggle')
            : false
        "
      >
        <div class="hc-list__current" v-if="activeHc">
          <div class="hc-list__current__hc color--white">
            <span class="current__hc__address">
              {{activeHc.addressStreet}}
              <span class="hc-list__current__apt color--white" v-if="getSelectedApartment && getSelectedApartment.no">
                {{ (getSelectedApartment.no !== getSelectedApartment.externalName) && getSelectedApartment.externalName ? `${getSelectedApartment.externalName} (${getSelectedApartment.no})`:getSelectedApartment.no}}
              </span>
            </span>
            <span class="current__hc__name">
              {{getHousingCompanyMinimizedName}}
            </span>
          </div>
        </div>
        <span class="hc-list__current__toggle-icon color--white"
          v-if="
            getUserDetails.hcs.length > 1 ||
              (getUserDetails.hcs[0].apts &&
                getUserDetails.hcs[0].apts.length > 1)
          "
        >
          <i class="yit-icon far fa-angle-down"></i>
          <i class="yit-icon far fa-angle-up"></i>
        </span>
      </button>
      <transition name="fade">
        <div
          class="hc-list__dropdown bg--grey-blue-medium"
          :class="{ 'has--search': getUserDetails.hcs.length > 10 }"
          v-if="open"
          v-closable="{
            exclude: ['hc-list__toggle'],
            handler: 'handleOutside'
          }"
        >
          <div class="hc-list__dropdown__search-wrapper" v-if="getUserDetails.hcs.length > 10">
            <SearchInput
              v-model="searchFilter"
              autocomplete="off"
              type="search"
              name="filter_housing_companies"
            />
          </div>
          <div class="hc-list__dropdown__content">
            <FormInput type="radio" name="currentHousingCompany">
              <div class="hc-list__item" v-for="(hc, key) in getFilteredHcs" :key="hc.id+key">
                <div class="hc-list__item__inner c-radio c-radio--light">
                  <input
                    type="radio"
                    name="hc"
                    v-model="hcId"
                    :value="hc.id"
                    :id="'hc-select__' + hc.id"
                    :checked="activeHc.id == hc.id"
                    @change="
                      hc.apts.length
                        ? (selectedApartmentNumber = hc.apts[0].no)
                        : false
                    "
                  />
                  <label :for="'hc-select__' + hc.id">
                    {{ getActiveHcName(hc) }}
                    <span class="hc-apt" v-if="hc.apts && hc.apts.length">
                      {{ (hc.apts[0].no !== hc.apts[0].externalName) && hc.apts[0].externalName ? `${hc.apts[0].externalName} (${hc.apts[0].no})`:hc.apts[0].no}}
                    </span>
                    <div
                      class="hc-name"
                      v-if="isUserYit || hc.addressCountry == 'FIN'"
                    >{{ hc.name }}</div>
                    <div class="hc-name" v-else>{{ hc.addressStreet }}</div>
                  </label>
                </div>

                <div v-if="hc.apts && hc.apts.length > 1 && hc.id == hcId" class="hc-list__item__apartments">
                  <FormInput type="radio" name="currentApartment">
                    <div
                      class="c-radio c-radio--light"
                      v-for="(apt, index) in hc.apts"
                      :key="apt.no+index"
                    >
                      <input
                        type="radio"
                        name="current-apartment"
                        v-model="selectedApartmentNumber"
                        :value="apt.no"
                        :id="'apartment-select__' + apt.no"
                        :checked="
                          selectedApartmentNumber == apt.no ||
                            (!selectedApartmentNumber && index === 0)
                        "
                      />
                      <label :for="'apartment-select__' + apt.no">
                        {{ (apt.no !== apt.externalName) && apt.externalName ? `${apt.externalName} (${apt.no})`:apt.no}}
                      </label>
                    </div>
                  </FormInput>
                </div>
              </div>
            </FormInput>
          </div>

          <div class="hc-list__dropdown__footer bg--grey-blue-medium">
            <Button
              type="button"
              class="button button--primary button--white"
              @click="switchHousingCompany"
              :loading="loading"
            >{{ $t("hc.dropdown.change-button") }}</Button>
          </div>
        </div>
      </transition>
      <div v-if="open" class="site-overlay"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import FormInput from "@/components/common/Form/Input";

export default {
  name: "HousingCompanyDropdown",
  components: {
    FormInput
  },
  props: ["open", "activeHc", "preventRedirectAfterChange", "isUserYit"],
  emits: ['close', 'toggle'],
  methods: {
    ...mapActions(["fetchUserDetails", "selectHousingCompany"]),
    ...mapMutations(["setApartment"]),
    switchHousingCompany() {
      const hcs = this.getUserDetails.hcs;
      const selectedHc = hcs.find(hc => hc.id == this.hcId);

      this.loading = true;
      this.selectHousingCompany(selectedHc).then(res => {
        this.searchFilter = "";
        if (this.selectedApartmentNumber)
        this.setApartment(
          this.getApartments.find(apt => apt.no == this.selectedApartmentNumber)
        );

        this.loading = false;
        if (!this.preventRedirectAfterChange)
          this.$router.push("/change-housing-company");
       
        this.$emit("close");
      }).catch(err => {
        this.loading = false;
        console.log(err);
        this.searchFilter = "";
        this.$emit("close");
      });

    },
    handleOutside() {
      this.$emit("close");
    },
    getActiveHcName(hc) {
      if (this.isUserYit || hc.addressCountry !== "FIN") {
        return hc.name;
      } else return hc.addressStreet ? hc.addressStreet : hc.name;
    }
  },
  computed: {
    ...mapGetters(["getUserDetails", "getSelectedApartment", "getApartments"]),
    getFilteredHcs() {
      let results = this.getUserDetails.hcs.slice();
      let keyword = this.searchFilter.toLowerCase();

      return results.filter(hc => {
        if (
          (hc.name && hc.name.toLowerCase().includes(keyword)) || 
          (hc.addressStreet && hc.addressStreet.toLowerCase().includes(keyword))
        )
          return true;
        else return false;
      });
    },
    getHousingCompanyMinimizedName() {
      if(!this.activeHc)
        return '';
      return this.activeHc.name
        .replace("Asunto Oy", "")
        .replace("Asunto oy", "")
        .replace("asunto oy", "")
        .replace("As Oy", "")
        .replace("As oy", "")
        .replace("as oy", "")
        .replace("", " ")
    }
  },
  data() {
    return {
      hcId: this.activeHc.id,
      housingCompanies: [],
      selectedApartmentNumber: null,
      searchFilter: "",
      loading: false
    };
  },
  mounted() {
    if (!this.getUserDetails) {
      this.fetchUserDetails();
    }
    if (this.getSelectedApartment)
      this.selectedApartmentNumber = this.getSelectedApartment.no;
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";

.hc-list {
  background-color: $greyBlueMedium;
  position: relative;
  .yit-icon {
    &.fa-angle-down {
      display: inline-block;
    }
    &.fa-angle-up {
      display: none;
    }
  }
  &--open {
    .yit-icon {
    &.fa-angle-down {
      display: none;
    }
    &.fa-angle-up {
      display: inline-block;
    }
  }
  }
  .hc-list__toggle {
    max-width: max-content;
    width: auto;
    outline: none;
  }
  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;

    @media screen and (max-width: $breakpoint-md-down) {
      height: 60px;
    }
  }

  &__toggle {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: .5rem 0 .6rem 0;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;

    &--inactive {
      cursor: text;
    }

    @media screen and (max-width: $breakpoint-md-down) {
      justify-content: left;
      padding-left: 1.2rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      max-width: 500px;
    }
  }

  &__current {
    margin-right: 0.4rem;
    position: relative;
    padding-right: .5rem;
    line-height: 1;
    text-align: left;

    @media screen and (max-width: $breakpoint-md-down) {
      width: 100%;
    }

    &__toggle-icon {
      position: relative;
      top: .05rem;
      margin-left: 0;
      margin-right: 1rem;
    }

    &__apt {
      margin-left: 0.3rem;
    }

    .current__hc {
      line-height: 1;
      &__name, &__address { 
        display: block;
      }
      &__name {
        font-size: .8rem;
      }
      &__address {
        font-size: 1rem;
        margin-bottom: .5rem;
      }

      @media screen and (min-width: $breakpoint-md) {
        &__name, &__address {
          font-size: .85rem;
          display: inline;
        }
      }
    }
  }

  .yit-icon {
    font-size: 1.4em;
    margin-left: 0.2rem;
    position: relative;
    top: 2px;
    @media screen and (min-width: $breakpoint-md) {
      top: 4px;
    }
  }

  &__dropdown {
    text-align: center;
    position: fixed;
    z-index: 10;
    width: 95%;
    max-height: 80%;
    height: auto;
    z-index: 10;
    left: 0;
    top: 9.5rem;
    color: #fff;
    transition: all 0.2s ease-in-out;
    max-width: 600px;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      content: "";
      top: -8px;
      left: auto;
      right: auto;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 1rem 1rem 1rem;
      border-color: transparent transparent $greyBlueMedium transparent;
      transition: all 0.2s ease-in-out 0.5s;
    }

    a {
      color: #fff;
    }

    .input-group {
      margin: 0;
      max-width: none;
    }

    li,
    .hc-list__item {
      font-size: 1rem;
      padding: 1rem 1rem 0.5rem 1rem;
      color: #fff;
      border-bottom: 1px solid #fff;
      text-align: left;

      &.active {
        font-weight: 700;
      }
      &:last-child {
        margin-bottom: 0;
      }

      .c-radio > label {
        padding-bottom: 0;
      }

      .hc-apt {
        margin-left: 0.5rem;
      }
      .hc-name {
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.7);
        @media screen and (min-width: $breakpoint-sm) {
          display: inline-block;
          margin-left: 1rem;
        }
      }

      &__apartments {
        font-size: 0.9em;
        margin-left: 1.7rem;
        padding-top: 1rem;
        .c-radio {
          margin-bottom: 1.1em;
        }
      }

      @media screen and (min-width: $breakpoint-md) {
        padding: 1em 1em 0.5rem 1em;
      }
    }

    &__content {
      overflow-y: auto;
      height: 100%;
      max-height: 400px;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    &__footer {
      padding: 1.5rem 0;
      .button {
        padding: 0.8rem 4rem;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    &__dropdown {
      top: 7.5rem;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &__dropdown {
      max-height: none;
      li,
      .hc-list__item .hc-list__item__inner,
      .hc-list__item__apartments {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
      .hc-list__item__apartments {
        padding-left: 1.7rem;
      }
    }
  }

  &--open {
    .site-overlay {
      top: 8.35rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      .site-overlay {
        top: 6.55rem;
      }
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
.hc-list__dropdown.has--search {
  .hc-list__dropdown__content {
    padding-top: 4rem;
  }
}
.hc-list__dropdown__search-wrapper {
  padding: 1rem 1rem;
  position: fixed;
  width: 100%;
  height: 4rem;
  background: $greyBlueMedium;
  z-index: 1;
  .search-input {
    max-width: 400px;
    margin: 0 auto;
    background: transparent;
    input {
      font-size: 0.8em;
      color: #fff;
    }
  }
}
</style>
