<template>
  <article class="page article-page">
    <div class="container-fluid container-fluid--to-lg">
      <div v-if="Object.entries(getCurrentPage).length > 0">
        <h1
          v-if="getCurrentPage.elements.title.value"
          class="page-title"
        >{{getCurrentPage.elements.title.value}}</h1>
        <div class="article-page__image" v-if="getCurrentPage.elements.images.value.length">
          <img :src="getCurrentPage.elements.images.value[0].url" alt />
        </div>
        <div class="article-page__content" v-html="getCurrentPage.elements.content.value"></div>
      </div>
      <div v-else-if="loading && Object.entries(getCurrentPage).length === 0" class="text-center">
        <LoaderIcon />
      </div>
      <PageNotFound v-else />
    </div>
  </article>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoaderIcon from "@/components/common/LoaderIcon";
import PageNotFound from "@/views/partials/404";
import { setPageTitle, sendPageLoadToGTM } from "@/utils/PageMetaUtil";

export default {
  name: "page",
  components: {
    LoaderIcon,
    PageNotFound
  },
  computed: {
    ...mapGetters(["getCurrentPage"])
  },
  data() {
    return {
      loading: true
    };
  },
  methods: {
    ...mapActions(["fetchSinglePage"]),
  },
  mounted() {
    //console.log(this.$route.path);
    let params =  {
      slug: this.$route.path
    };

    if(this.$route.query && this.$route.query.lang) {
      params.language = this.$route.query.lang;
    }

    this.fetchSinglePage(params)
      .then(res => {
        if(Array.isArray(res)) {
          if (res.length) {
            if (res[0] && res[0].elements && res[0].elements.title)
              sendPageLoadToGTM(this.$route, res[0].elements.title.value);

          } else
            sendPageLoadToGTM(this.$route, this.$t("page.404.title"));
        } else if (typeof res === 'object') {
          if (res && res.elements && res.elements.title) {
            console.log("setting document title: ", res.elements.title.value);
            sendPageLoadToGTM(this.$route, res.elements.title.value);
          } else
            sendPageLoadToGTM(this.$route, this.$t("page.404.title"));
        }

        console.log("fetched page, ", res);

        if(!res && !this.getCurrentPage)
          sendPageLoadToGTM(this.$route, this.$t("page.404.title"));

        this.loading = false;
      })
      .catch(err => {
        console.log("Page could not be loaded: ", err);
        this.loading = false;
      });
  }
};
</script>

<style>
</style>
