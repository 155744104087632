/**
 * 
 * @param {*} state 
 * @param {*} data 
 * @param {*} key 
 */
const set = (state, data, key) => {
    state[key] = data;
}

/**
 * 
 * @param {§} state 
 * @param {*} data 
 * @param {*} key 
 */
const append = (state, data, key) => {
    if (!Array.isArray(state[key]))
        set(state, data, key);
    state[key].push(data);
}

/**
 * 
 * @param {*} state 
 * @param {*} data 
 * @param {*} key 
 * @param {*} needleField 
 * @param {*} needleValue 
 */
const update = (state, data, key, needleField = null, needleValue = null) => {
    if (!Array.isArray(state[key]))
        set(state, data, key);
    
    if (Array.isArray(data)) {
        data.map(d => {
            _appendItem(state, d, key, needleField, d[needleField]);
        });
    } else {
        _appendItem(state, data, key, needleField, needleValue);
    }
}

/**
 * 
 * @param {*} state 
 * @param {*} data 
 * @param {*} key 
 * @param {*} needle 
 */
const remove = (state, data, key, needleField = null, needleValue = null) => {
    if (Array.isArray(state[key]))
        state[key] = state[key].filter(d => d[needleField] !== needleValue);
    return state[key];
}

const _appendItem = (state, item, key, needleField, needleValue) => {
    if (!needleField || !needleValue) {
        state[key].push(item);
    } else {
        let found = false;
        state[key] = state[key].map(d => {
            if (d[needleField] === needleValue) {
                d = item;
                found = true;
            }
            return d;
        });
        if (!found)
            state[key].push(item);
    }
}

export { set, append, update, remove };