import store from "@/store/index";
import BaseService from "./BaseService";
import { AzureFunctionClient } from "./AzureFunctionClient";

export default class ApplicationSettingsService extends BaseService {
  constructor() {
    super();
  }

  /**
   * Fetch application settings
   * @param {*} params
   */
  fetch() {
    return AzureFunctionClient.get("/getApplicationSettings");
  }
}
