import { i18n } from "@/plugins/i18n";

const types = {
  AZURE_SERVICE_REQUEST_SOURCE_KEY: "azure",
  LIFERAY_SERVICE_REQUEST_SOURCE_KEY: "liferay"
};

class ServiceRequestModel {
  constructor(item) {
    this.id = null;
    this.source = "";
    this.created = new Date();
    this.public = false;
    this.responses = [];

    Object.assign(this, item);
  }

  getId() {
    return this.id;
  }

  getSubject() {
    return "";
  }

  getType() {
    return this.type;
  }

  getCreatedDate() {
    return this.created;
  }

  isPublic() {
    return this.public ? true : false;
  }

  getStatusString() {
    return i18n.global.t(this.getDefaultStatusKey());
  }

  getDefaultStatusKey() {
    return "serviceRequest.status.open";
  }
}

export default ServiceRequestModel;
export { ServiceRequestModel, types };
