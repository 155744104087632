import store from "@/store";

const serviceLoungeFeature = {
    id: "service-lounge",
    name: "feature.service-lounge.name",
    iconClass: "fal fa-shopping-cart",
    globalSettings: {},
    schema: {},
    initialize: (feature, settings) => {
        if (!store.getters.getMainNavigationItemByUrl('/service-shop')) {
            let hcState = store.getters.getCurrentHcState;
            store.dispatch("addNavigationLink", {
                titleLanguageKey: "navigation.service-shop",
                href: "/service-shop",
                iconClass: "fal fa-shopping-cart",
                orderNumber: 45
            });
        }
    }
};

export { serviceLoungeFeature };