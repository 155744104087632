export default (user, { can, cannot, rules }) => {
  /**
   * Partner management
   */
  if (user.hasRole("yitBusinessAdmin") || user.hasRole("yitMarketing")) {
    can(["manage"], "partners");
    can(["manage"], "partnercompany");
  }

  if (user.hasRole('yitProject') && !user.hasRole('yitBusinessAdmin')) {
    can(["manage"], "partners");
    can(['create', 'update', 'delete'], "partnercompany", {
      type: "MaintenanceManager"
    });
    can(['create', 'update', 'delete'], "partnercompany", {
      type: "PropertyManager"
    });
  }

  if(user.hasRole("partner") || user.hasRole("propertyManager") || user.hasRole("maintenanceManager")) {
    if (Array.isArray(user.partnerCompanies)) {
      user.partnerCompanies.map(c => {
        can(["read", "update"], "partnercompany", {
          externalId: c.externalId
        });
        can(["read", "update", "remove"], "user", {
            partnerCompanies: {
              $elemMatch: { externalId: c.externalId }
            }
          });
      });
    }
  }
}