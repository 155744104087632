import store from "@/store";
/**
 * Used only with casl abilities atm
 */
export default class ArticleModel {
    constructor(article) {
      this.id = null;
      this._type = 'article';
      this.published = new Date().toString();
      this.lastModified = new Date().toString();
      this.title = "";
      this.content = "";
      this.locale = "en";
      this.buildingTaxonomies = [];
      this.buildingTaxonomyValues = [];
      this.user_id = null;
      this.authorRoles = [];
    }

    /**
     * Build from Kentico model
     * @param {*} article 
     */
    static fromKenticoModel(article) {
        let newArticle = new ArticleModel();

        if(!article.elements || !article.system)
            return new ArticleModel();

        newArticle.id = article.system.id;
        newArticle.lastModified = article.system.last_modified;
        if(article.elements.buildingtaxonomy) {
            newArticle.buildingTaxonomies = article.elements.buildingtaxonomy.values;
            article.elements.buildingtaxonomy.value.map(t => {
                newArticle.buildingTaxonomyValues.push(t.codename);
                return t;
            });
        }

        if(article.elements.user_id)
            newArticle.user_id = article.elements.user_id.value;
        
        if (article.elements.author_roles && article.elements.author_roles.value) {
            newArticle.authorRoles = String(article.elements.author_roles.value).split(",");
        }

        return newArticle;
    }

    /**
     * Build from Kentico Content variant model
     * @param {*} article 
     */
    static fromKenticoLanguageVariantModel(article) {
        let newArticle = new ArticleModel();

        if(!article || !article.elements || !article.item)
            return new ArticleModel();

        newArticle.id = article.item.id;

        if(article.elements.buildingtaxonomy) {
            newArticle.buildingTaxonomies = article.elements.buildingtaxonomy;
            article.elements.buildingtaxonomy.map(t => {
                let tax = store.getters.getBuildingTaxonomyCodenameById(t.id);
                if(tax && tax.length)
                    tax.map(_t => newArticle.buildingTaxonomyValues.push(_t.codename));
                return t;
            });
        }

        if(article.elements.user_id)
            newArticle.user_id = article.elements.user_id;
        
        if (article.elements.author_roles) {
            newArticle.authorRoles = String(article.elements.author_roles).split(",");
        }

        return newArticle;
    }
}