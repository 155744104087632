import FeedbackService from "@/services/FeedbackService";

let api = null;
if (FeedbackService) api = new FeedbackService();

const getDefaultState = () => {
  return {
    feedbackEntries: [],
    feedbackRating: []
  };
};

const state = getDefaultState();

const getters = {
  getFeedbackEntries: state => state.feedbackEntries,
  getFeedbackRatingByMonth: state => state.feedbackRating
};

const actions = {
  fetchFeedbackEntries({ commit }, params = {}) {
    if (!api) api = new FeedbackService();

    return api
      .getFeedbackEntries(params)
      .then(response => {
        commit("setFeedbackEntries", response.data.entries);
        commit("setFeedbackRating", response.data.grouped);
      })
      .catch(err => {
        console.log("Fetching feedback entries failed: ", err);
      });
  }
};

const mutations = {
  setFeedbackEntries: (state, res) => (state.feedbackEntries = res),
  setFeedbackRating: (state, res) => (state.feedbackRating = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
