import BaseService from "./BaseService";
import AzureFunctionClient from "./AzureFunctionClient";
import { param } from "jquery";

export default class WarrantyService extends BaseService {
  constructor() {
    super();
  }

  /**
   * Get faults 
   * @param {*} params 
   */
  async getFaults(params = {}) {
    params.type = 'note';
    let url = this._buildUrl(`/congridData`, params);
    return AzureFunctionClient.get(url);
  }

  /**
   * Get fault
   * @param {*} params 
   */
  async getFault(params = {}) {
    params.type = 'note';
    let url = this._buildUrl(`/congridData`, params);
    return AzureFunctionClient.get(url);
  }

  /**
   * Post fault
   * @param {*} params 
   */
  async addFault(params) {
    let payload = null;

    //multipart request with files
    if (params.photos && params.photos.length) {
      let payload = new FormData();
      payload.append('id', params.id);
      if(params.aptId)
        payload.append('aptId', params.aptId);
      payload.append('type', 'note');
      payload.append('name', params.name);
      payload.append('description', params.description);
      if(params.descriptionExtended)
        payload.append('descriptionExtended', params.descriptionExtended);
      params.photos.map((photo, i) => {
        payload.append('file'+(i+1), photo);
      })

      return AzureFunctionClient.post('/congridData', payload, {
        'Content-Type': 'multipart/form-data',
        timeout: 300000,
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

    //no files included
    } else {
      payload = {
        id: params.id,
        aptId: params.aptId,
        type: "note",
        name: params.name,
        description: params.description,
        descriptionExtended: params.descriptionExtended
      }
      
      return AzureFunctionClient.post('/congridData', payload);
    }
    
  }

  async updateFault(params) {

    if (!params.noteId)
      throw "property noteId missing";

    let payload = {
      id: params.id,
      noteId: params.noteId,
      aptId: params.aptId,
      type: "note",
      name: params.name,
      description: params.description,
      descriptionExtended: params.descriptionExtended
    }
    return AzureFunctionClient.put('/congridData', payload);
  }

  async markApartmentCheckedWithoutFaults(params) {
  
    let payload = {
      id: params.id,
      aptId: params.aptId,
      type: "nofaults"
    }
      
    return AzureFunctionClient.post('/congridData', payload);
  }

  /**
   * Delete fault
   * @param {*} params 
   */
  async deleteFault(item = {}) {

    if (!item.noteId)
      throw "Missing property noteId";
    
    item.type = 'note';

    let url = this._buildUrl(`/congridData`);
    
    return AzureFunctionClient.delete(url, {
      data: item
    });
  }

  /**
   * Post fault
   * @param {*} fault
   * @param {Array} files 
   */
  async addPhoto(fault, files = []) {

    let payload = new FormData();
    payload.append('id', fault.id);
    payload.append('aptId', fault.aptId);
    payload.append('noteId', fault.noteId);
    payload.append('type', 'photo');
    files.map((photo, i) => {
      payload.append('file'+(i+1), photo);
    })

    let url = this._buildUrl(`/congridData`);
    return AzureFunctionClient.post(url, payload);
  }

  /**
   * Post fault
   * @param {*} params 
   */
  async removePhoto(fault, photoId) {

    let payload = {
      id: fault.id,
      noteId: fault.noteId,
      aptId: fault.aptId,
      photoId,
      type: "photo",
    }

    let url = this._buildUrl(`/congridData`);
    return AzureFunctionClient.delete(url, { data: payload });
  }

  /**
   * Get targets to housing company or apartment
   * @param {*} params 
   * @returns 
   */
  async getTargets(params = {}) {
    params.type = 'targets';
    let url = this._buildUrl(`/congridData`, params);
    return AzureFunctionClient.get(url);
  }

  /**
   * Get targets to housing company or apartment
   * @param {*} params 
   * @returns 
   */
  async getTargetsForManagement(params = {}) {
    params.type = 'mappings';
    params.flat = "true";
    let url = this._buildUrl(`/congridManageData`, params);
    return AzureFunctionClient.get(url);
  }

  /**
   * Get apartment warranty details
   * @param {*} apartmentId 
   */
  async getApartmentWarrantyDetails(apartmentId) {
    let url = this._buildUrl(`/apartmentWarrantyDetails`, {apartmentId});
    return AzureFunctionClient.get(url);
  }

  /**
   * Save apartment warranty details
   * @param {*} apartmentId 
   */
  async updateApartmentWarrantyDetails(apartmentId, params = {}) {
    let url = this._buildUrl(`/apartmentWarrantyDetails`, { apartmentId });
    let payload = {
       canUseMasterKey: params.canUseMasterKey,
        phoneNumber: params.phoneNumber,
        tenant: params.tenant,
        tenantCanReport: params.tenantCanReport,
        tenantContactInformation: params.tenantContactInformation,
        additionalInformation: params.additionalInformation
    };
    return AzureFunctionClient.put(url, payload);
  }

  /**
   * 
   * @param {*} params 
   * @returns Promise
   */
  async getRepairStatus(params = {}) {

    if (!params.id)
      throw "Id must be defined";

    let payload = {
      id: params.id,
      type: 'repairstatus'
    };

    if (params.aptId)
      payload.aptId = params.aptId;

    let url = this._buildUrl(`/congridData`, payload);
    return AzureFunctionClient.get(url);
  }

  /**
   * 
   * @param {*} params 
   * @returns 
   */
  async acceptApartmentRepairs(params = {}) {
    // if (!params.apartmentId)
    //   throw "You must provide apartmentId to accept repairs";
    
    // return AzureFunctionClient.post('/acceptApartmentRepairs', params);
    let payload = {
      id: params.id,
      aptId: params.aptId,
      type: "repairsaccepted"
    }

    return AzureFunctionClient.post('/congridData', payload);
  }

  /**
   * 
   * @param {*} params 
   * @returns 
   */
  async acceptRepair(params = {}) {
    if (!params.id)
      throw "You must provide id to accept repair";
    if (!params.noteId)
      throw "You must provide noteId to accept repair";
    
    let payload = {
      id: params.id,
      noteId: params.noteId,
      type: "repairsaccepted"
    }
    if (params.aptId)
      payload.aptId = params.aptId;

    return AzureFunctionClient.post('/congridData', payload);
  }
  
  /**
   * 
   * @param {*} params 
   * @returns 
   */
  async getApartmentAndOwnerReport(params = {}) {
  
    let query = this._buildUrl('/congridReport', params);

    if(params.dataType == 'json')
      return AzureFunctionClient.get(query);
    else {
      let fileBuffer = await AzureFunctionClient.get(
        query,
        {
          responseType: 'blob'
        }
      );
      
      let now = new Date();

      let fileName = `report-${now.getTime()}.xlsx`;

      let fileURL = window.URL.createObjectURL(new Blob([fileBuffer.data]));
      let fileLink = document.createElement('a');
    
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      return;
    }
  }

  /**
   * 
   * @param {*} ids - Array of file object ids
   */
  async downloadFilesAsZip(ids = []) {
    let query = this._buildUrl(`/readFile`, { ids: ids.join(",") });
    
    try {
      let fileBuffer = await AzureFunctionClient.get(query, {
        responseType: 'blob'
      });

      let fileURL = window.URL.createObjectURL(new Blob([fileBuffer.data]));
      let fileLink = document.createElement('a');
    
      fileLink.href = fileURL;
      let now = new Date();
      fileLink.setAttribute('download', `yitplus-download-${now.getTime()}.zip`);
      document.body.appendChild(fileLink);
    
      fileLink.click();

      return true;

    } catch (err) {
      throw err;
    }
  }
}