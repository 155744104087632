<template>
  <div class="toast" role="alert" aria-atomic="true" :class="getCssClass">
    <span>
      <span v-if="message">{{ message }}</span>
      <slot></slot>
    </span>
    <button class="toast__close">
      <i v-if="closable" class="fal fa-times" aria-hidden="true"></i>
    </button>
  </div>
</template>
<script>
export default {
  name: "Toast",
  props: {
    type: {
      type: String
    },
    closalbe: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      delayInMs: 5000,
      closable: true,
      visible: false,
      message: ""
    };
  },
  methods: {
    show(message) {
      if (message) this.message = message;
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
        this.message = "";
      }, this.delayInMs);
    },
    hide() {
      this.visible = false;
      this.message = "";
    }
  },
  computed: {
    getCssClass() {
      let cssClasses = "";
      if (this.type) cssClasses += "toast--" + this.type + " ";

      if (this.visible) cssClasses += "toast--visible";

      return cssClasses;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/variables";
.toast {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 999;
  overflow: hidden;
  bottom: 1rem;
  right: 1rem;
  max-width: 350px;
  margin: 0;
  background: #333;
  font-size: 0.85rem;
  padding: 0.4rem 0.6rem;
  border-radius: 5px;
  color: #fff;
  transform: translateY(10rem);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.03, 1.44, 0.74, 0.93);
  &--visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  &--success {
    background: $green;
  }

  &--error {
    background: $red;
  }

  &__close {
    padding: 0.4rem 0.4rem 0.4rem 1rem;
    color: #fff;
    margin-left: auto;
  }
}
</style>
