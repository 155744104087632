import LiferayClient from "./LiferayClient";
import BaseService from "./BaseService";

export default class LiferayConsumptionService extends BaseService {
  constructor() {
      super();
      this.baseUrl = "/delegate/yithome-api/obixconsumption";
      this.hcID = null;
  }

  /**
   *
   * @param {obj} params
   *
   */
  fetchData(params) {
    let urlParams;

   // params.aptExtId = "1-DCH8QP";
   // params.hcExtId = "1-D04O1C";

   if(this.routeViaLiferay()) {

      if(params.aptExtId) {
        urlParams =  `${this.baseUrl}/hc/${params.hcExtId}/${params.aptExtId}/${params.series}/${params.year}/${params.monthNumber}`
      } else {
        urlParams =  `${this.baseUrl}/apts/${params.hcExtId}/${params.series}/${params.year}/${params.monthNumber}`
      }
      return LiferayClient.get(urlParams);

    } else {
      if(params.aptExtId) {
        return Promise.resolve({ data: {
          "types": [
              {
                  "calculatable": true,
                  "integrationCalculatable": false,
                  "common": false,
                  "id": 5,
                  "description": "Apartment cold water by meter value",
                  "type": "COLD_WATER"
              },
              {
                  "calculatable": true,
                  "integrationCalculatable": false,
                  "common": false,
                  "id": 6,
                  "description": "Apartment hot water by meter value",
                  "type": "HOT_WATER"
              }
          ],
          "values": [
              {
                  "typeId": 5,
                  "amount": 97080.00183105469,
                  "date": "Apr 11, 2016 3:00:00 PM"
              },
              {
                  "typeId": 5,
                  "amount": 97180.00030517578,
                  "date": "Apr 12, 2016 12:00:01 AM"
              },
              {
                  "typeId": 5,
                  "amount": 97370.00274658203,
                  "date": "Apr 13, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 97489.99786376953,
                  "date": "Apr 14, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 97669.99816894531,
                  "date": "Apr 15, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 97760.00213623047,
                  "date": "Apr 16, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 97959.99908447266,
                  "date": "Apr 17, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 98089.99633789062,
                  "date": "Apr 18, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 98419.99816894531,
                  "date": "Apr 19, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 98519.9966430664,
                  "date": "Apr 20, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 98769.9966430664,
                  "date": "Apr 21, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 98900.0015258789,
                  "date": "Apr 22, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 99120.00274658203,
                  "date": "Apr 23, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 99300.00305175781,
                  "date": "Apr 24, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 99540.00091552734,
                  "date": "Apr 25, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 99769.9966430664,
                  "date": "Apr 26, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 100000.0,
                  "date": "Apr 27, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 100209.99908447266,
                  "date": "Apr 28, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 100400.0015258789,
                  "date": "Apr 29, 2016 12:00:01 AM"
              },
              {
                  "typeId": 5,
                  "amount": 100610.00061035156,
                  "date": "Apr 30, 2016 12:00:01 AM"
              },
              {
                  "typeId": 5,
                  "amount": 100819.99969482422,
                  "date": "May 1, 2016 12:00:00 AM"
              },
              {
                  "typeId": 5,
                  "amount": 101069.99969482422,
                  "date": "May 2, 2016 12:00:01 AM"
              },
              {
                  "typeId": 6,
                  "amount": 45299.99923706055,
                  "date": "Apr 11, 2016 3:00:00 PM"
              },
              {
                  "typeId": 6,
                  "amount": 45389.99938964844,
                  "date": "Apr 12, 2016 12:00:01 AM"
              },
              {
                  "typeId": 6,
                  "amount": 45540.000915527344,
                  "date": "Apr 13, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 45610.00061035156,
                  "date": "Apr 14, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 45669.99816894531,
                  "date": "Apr 15, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 45709.999084472656,
                  "date": "Apr 16, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 45770.00045776367,
                  "date": "Apr 17, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 45849.998474121094,
                  "date": "Apr 18, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 45970.001220703125,
                  "date": "Apr 19, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46040.000915527344,
                  "date": "Apr 20, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46150.001525878906,
                  "date": "Apr 21, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46220.001220703125,
                  "date": "Apr 22, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46290.000915527344,
                  "date": "Apr 23, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46380.001068115234,
                  "date": "Apr 24, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46500.0,
                  "date": "Apr 25, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46639.99938964844,
                  "date": "Apr 26, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46689.998626708984,
                  "date": "Apr 27, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46790.000915527344,
                  "date": "Apr 28, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46830.00183105469,
                  "date": "Apr 29, 2016 12:00:01 AM"
              },
              {
                  "typeId": 6,
                  "amount": 46990.0016784668,
                  "date": "Apr 30, 2016 12:00:01 AM"
              },
              {
                  "typeId": 6,
                  "amount": 47029.998779296875,
                  "date": "May 1, 2016 12:00:00 AM"
              },
              {
                  "typeId": 6,
                  "amount": 47159.99984741211,
                  "date": "May 2, 2016 12:00:01 AM"
              }
          ]
      }});
      } else {
        return Promise.resolve({ data: {
          "aptDetails": {
              "Asunto Oy Helsingin Hjalmar B 46": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 40": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 41": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 42": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 43": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 44": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 45": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 23": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 140020.00427246094,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 140210.0067138672,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 140309.99755859375,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 140529.99877929688,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 140669.9981689453,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 140860.00061035156,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 141100.00610351562,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 141380.0048828125,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 141550.0030517578,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 141660.00366210938,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 141850.00610351562,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 141960.0067138672,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 142259.99450683594,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 142449.9969482422,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 142779.99877929688,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 142880.0048828125,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 143050.0030517578,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 143199.9969482422,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 143330.0018310547,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 143559.99755859375,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 143669.9981689453,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 143960.0067138672,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 105720.00122070312,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 105879.99725341797,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 105910.00366210938,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 106010.00213623047,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 106160.00366210938,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 106180.00030517578,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 106370.00274658203,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 106639.99938964844,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 106790.00091552734,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 106910.00366210938,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 107099.9984741211,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 107209.99908447266,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 107410.00366210938,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 107529.99877929688,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 107819.99969482422,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 107930.00030517578,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 108099.9984741211,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 108279.99877929688,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 108430.00030517578,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 108510.00213623047,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 108599.9984741211,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 108919.99816894531,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 22": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 126129.99725341797,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 126260.00213623047,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 126410.00366210938,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 126589.99633789062,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 126819.99969482422,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127000.0,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127180.00030517578,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127360.00061035156,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127510.00213623047,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127650.0015258789,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127849.9984741211,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128029.99877929688,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128149.99389648438,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128309.99755859375,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128570.00732421875,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128710.00671386719,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128869.9951171875,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129070.00732421875,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129270.00427246094,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129419.99816894531,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129580.00183105469,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129770.00427246094,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 76730.0033569336,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 76809.99755859375,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 76949.99694824219,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 77089.99633789062,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 77269.9966430664,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 77419.99816894531,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 77569.99969482422,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 77730.0033569336,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 77860.00061035156,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 77970.00122070312,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 78129.99725341797,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 78290.00091552734,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 78379.99725341797,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 78510.00213623047,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 78720.00122070312,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 78889.99938964844,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79000.0,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79169.99816894531,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79309.99755859375,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79449.99694824219,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79580.00183105469,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79760.00213623047,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 21": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 25719.999313354492,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 25719.999313354492,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 25799.999237060547,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 25950.000762939453,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26030.000686645508,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26090.00015258789,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26170.000076293945,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26329.999923706055,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26399.999618530273,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26420.000076293945,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26440.000534057617,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26510.000228881836,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26540.000915527344,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26629.9991607666,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26719.999313354492,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26799.999237060547,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26860.000610351562,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 26940.000534057617,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 27030.000686645508,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 27100.000381469727,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 27200.000762939453,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 27280.000686645508,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5230.000019073486,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5230.000019073486,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5289.999961853027,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5329.999923706055,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5409.999847412109,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5429.999828338623,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5489.999771118164,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5510.000228881836,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5539.999961853027,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5550.000190734863,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5570.000171661377,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5579.999923706055,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5590.000152587891,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5619.999885559082,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5650.000095367432,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5670.000076293945,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5719.99979019165,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5760.000228881836,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5780.00020980835,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5809.999942779541,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5849.999904632568,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5869.999885559082,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 20": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 70180.00030517578,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 70230.0033569336,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 70319.99969482422,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 70440.00244140625,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 70569.99969482422,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 70809.99755859375,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 70970.00122070312,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 71120.00274658203,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 71209.99908447266,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 71279.99877929688,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 71489.99786376953,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 71559.99755859375,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 71650.0015258789,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 71690.00244140625,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 71750.0,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 71919.99816894531,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 72010.00213623047,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 72099.9984741211,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 72309.99755859375,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 72459.99908447266,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 72550.00305175781,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 72730.0033569336,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42680.00030517578,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42700.00076293945,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42759.9983215332,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42840.00015258789,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42900.001525878906,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43130.001068115234,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43200.00076293945,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43299.99923706055,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43369.998931884766,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43409.99984741211,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43490.0016784668,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43549.99923706055,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43619.998931884766,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43630.001068115234,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43680.00030517578,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43750.0,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43819.99969482422,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43880.001068115234,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44009.9983215332,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44209.999084472656,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44279.998779296875,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44380.001068115234,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 29": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 29280.000686645508,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29290.000915527344,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29309.999465942383,
                          "date": "Apr 13, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29319.99969482422,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29329.999923706055,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29350.000381469727,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29379.9991607666,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29469.999313354492,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29479.999542236328,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29500.0,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29510.000228881836,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29590.00015258789,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29629.9991607666,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29670.000076293945,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29709.999084472656,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29739.999771118164,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29750.0,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29819.99969482422,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29840.00015258789,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29850.000381469727,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29889.999389648438,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 29989.999771118164,
                          "date": "May 2, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5789.999961853027,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5789.999961853027,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5800.000190734863,
                          "date": "Apr 13, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5809.999942779541,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5809.999942779541,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5809.999942779541,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5840.000152587891,
                          "date": "Apr 17, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5849.999904632568,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5849.999904632568,
                          "date": "Apr 19, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5860.000133514404,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5860.000133514404,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5869.999885559082,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5869.999885559082,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5880.000114440918,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5900.000095367432,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5909.999847412109,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5909.999847412109,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5920.000076293945,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5920.000076293945,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5920.000076293945,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5929.999828338623,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 5960.000038146973,
                          "date": "May 2, 2016 12:00:02 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 28": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 99879.99725341797,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 99910.00366210938,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100010.00213623047,
                          "date": "Apr 13, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100150.0015258789,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100300.00305175781,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100470.00122070312,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100680.00030517578,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100819.99969482422,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100980.0033569336,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 101110.00061035156,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 101220.00122070312,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 101339.99633789062,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 101519.9966430664,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 101690.00244140625,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 101830.00183105469,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 101989.99786376953,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 102120.00274658203,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 102230.0033569336,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 102379.99725341797,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 102500.0,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 102709.99908447266,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 102819.99969482422,
                          "date": "May 2, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46029.998779296875,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46040.000915527344,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46090.00015258789,
                          "date": "Apr 13, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46180.00030517578,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46250.0,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46310.001373291016,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46430.00030517578,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46459.999084472656,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46509.9983215332,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46599.998474121094,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46650.001525878906,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46700.00076293945,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46799.99923706055,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46979.99954223633,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47020.00045776367,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47099.998474121094,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47150.001525878906,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47189.998626708984,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47229.99954223633,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47290.000915527344,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47419.99816894531,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47479.99954223633,
                          "date": "May 2, 2016 12:00:02 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 27": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 78910.00366210938,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 79029.99877929688,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 79160.00366210938,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 79309.99755859375,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 79430.00030517578,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 79489.99786376953,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 79589.99633789062,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 79660.00366210938,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 79839.99633789062,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 79980.0033569336,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80129.99725341797,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80230.0033569336,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80449.99694824219,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80559.99755859375,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80680.00030517578,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80779.99877929688,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80940.00244140625,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81040.00091552734,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81129.99725341797,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81199.99694824219,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81290.00091552734,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81459.99908447266,
                          "date": "May 2, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31340.00015258789,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31350.000381469727,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31409.99984741211,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31489.999771118164,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31549.999237060547,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31559.999465942383,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31600.000381469727,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31649.999618530273,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31739.999771118164,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31790.000915527344,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31889.999389648438,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 31899.999618530273,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32000.0,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32049.999237060547,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32099.998474121094,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32110.000610351562,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32180.00030517578,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32240.001678466797,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32270.000457763672,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32319.99969482422,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32369.998931884766,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32490.001678466797,
                          "date": "May 2, 2016 12:00:02 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 26": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 104400.0015258789,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 104480.0033569336,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 104569.99969482422,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 104669.99816894531,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 104769.9966430664,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 104879.99725341797,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105089.99633789062,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105250.0,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105349.9984741211,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105410.00366210938,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105489.99786376953,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105699.99694824219,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105809.99755859375,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105949.99694824219,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106150.0015258789,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106190.00244140625,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106279.99877929688,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106339.99633789062,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106430.00030517578,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106589.99633789062,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106730.0033569336,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106919.99816894531,
                          "date": "May 2, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65029.998779296875,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65069.99969482422,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65160.003662109375,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65260.00213623047,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65379.99725341797,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65459.999084472656,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65620.00274658203,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65720.00122070312,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65809.99755859375,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65889.99938964844,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 65970.00122070312,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66080.00183105469,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66169.99816894531,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66260.00213623047,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66389.99938964844,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66440.00244140625,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66519.9966430664,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66589.99633789062,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66690.00244140625,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66790.00091552734,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 66860.00061035156,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 67010.00213623047,
                          "date": "May 2, 2016 12:00:02 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 25": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 35909.99984741211,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 35930.00030517578,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36000.0,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36029.998779296875,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36150.001525878906,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36180.00030517578,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36220.001220703125,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36270.00045776367,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36299.99923706055,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36340.00015258789,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36389.99938964844,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36430.00030517578,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36470.001220703125,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36509.9983215332,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36549.99923706055,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36590.00015258789,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36619.998931884766,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36650.001525878906,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36680.00030517578,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36759.9983215332,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36840.00015258789,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 36900.001525878906,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17399.999618530273,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17409.99984741211,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17450.000762939453,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17459.999084472656,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17500.0,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17520.000457763672,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17540.000915527344,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17559.999465942383,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17579.999923706055,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17590.00015258789,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17620.0008392334,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17629.9991607666,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17659.99984741211,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17670.000076293945,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17700.000762939453,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17739.999771118164,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17770.000457763672,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17799.999237060547,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17809.999465942383,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17829.999923706055,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17829.999923706055,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 17870.0008392334,
                          "date": "May 2, 2016 12:00:02 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 24": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 109559.99755859375,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 109599.9984741211,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 109650.0015258789,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 109739.99786376953,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 109790.00091552734,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 109900.0015258789,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110099.9984741211,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110220.00122070312,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110290.00091552734,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110379.99725341797,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110410.00366210938,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110410.00366210938,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110410.00366210938,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110410.00366210938,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110519.9966430664,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110669.99816894531,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110750.0,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110889.99938964844,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 110980.0033569336,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 111029.99877929688,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 111220.00122070312,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 111269.9966430664,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19590.00015258789,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19600.000381469727,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19600.000381469727,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19639.999389648438,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19659.99984741211,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19670.000076293945,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19729.999542236328,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19729.999542236328,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19760.000228881836,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19780.000686645508,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19780.000686645508,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19780.000686645508,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19780.000686645508,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19780.000686645508,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19790.000915527344,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19809.999465942383,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19819.99969482422,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19829.999923706055,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19860.000610351562,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19870.0008392334,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19930.00030517578,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 19930.00030517578,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 37": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 38": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 35": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 36": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 39": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar B 30": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 80690.00244140625,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80699.99694824219,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80769.9966430664,
                          "date": "Apr 13, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80860.00061035156,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80919.99816894531,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 80980.0033569336,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81110.00061035156,
                          "date": "Apr 17, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81169.99816894531,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81250.0,
                          "date": "Apr 19, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81330.00183105469,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81400.0015258789,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81459.99908447266,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81500.0,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81580.00183105469,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81620.00274658203,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81699.99694824219,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81739.99786376953,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81819.99969482422,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81889.99938964844,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 81959.99908447266,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 82029.99877929688,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 82099.9984741211,
                          "date": "May 2, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42729.99954223633,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42729.99954223633,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42759.9983215332,
                          "date": "Apr 13, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42770.00045776367,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42799.99923706055,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42799.99923706055,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42830.00183105469,
                          "date": "Apr 17, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42840.00015258789,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42900.001525878906,
                          "date": "Apr 19, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 42939.998626708984,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43000.0,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43040.000915527344,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43049.99923706055,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43119.998931884766,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43150.001525878906,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43209.999084472656,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43209.999084472656,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43279.998779296875,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43279.998779296875,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43299.99923706055,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43299.99923706055,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43310.001373291016,
                          "date": "May 2, 2016 12:00:02 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 33": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar A 8": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 66459.99908447266,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66489.99786376953,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66569.99969482422,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66629.99725341797,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66690.00244140625,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66750.0,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66779.99877929688,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66989.99786376953,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67050.00305175781,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67120.00274658203,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67129.99725341797,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67129.99725341797,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67160.00366210938,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67199.99694824219,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67410.00366210938,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67470.00122070312,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67500.0,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67519.9966430664,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67569.99969482422,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67620.00274658203,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67650.0015258789,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 67849.9984741211,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 37610.00061035156,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 37650.001525878906,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 37729.99954223633,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 37779.998779296875,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 37840.00015258789,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 37889.99938964844,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 37930.00030517578,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 37990.0016784668,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38040.000915527344,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38090.00015258789,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38090.00015258789,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38090.00015258789,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38130.001068115234,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38180.00030517578,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38259.9983215332,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38310.001373291016,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38310.001373291016,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38360.00061035156,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38400.001525878906,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38450.00076293945,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38479.99954223633,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 38549.99923706055,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 34": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar A 7": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 47560.001373291016,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 47580.00183105469,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 47630.001068115234,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 47669.99816894531,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 47740.0016784668,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 47799.99923706055,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 47840.00015258789,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 47930.00030517578,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 47990.0016784668,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48040.000915527344,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48099.998474121094,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48169.99816894531,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48220.001220703125,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48279.998779296875,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48360.00061035156,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48409.99984741211,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48490.0016784668,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48540.000915527344,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48590.00015258789,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48709.999084472656,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48770.00045776367,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 48849.998474121094,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25530.000686645508,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25559.999465942383,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25579.999923706055,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25590.00015258789,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25620.0008392334,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25649.999618530273,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25700.000762939453,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25739.999771118164,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25770.000457763672,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25799.999237060547,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25860.000610351562,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25889.999389648438,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25909.99984741211,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25940.000534057617,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25979.999542236328,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 26000.0,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 26040.000915527344,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 26069.99969482422,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 26090.00015258789,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 26139.999389648438,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 26159.99984741211,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 26200.000762939453,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 31": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 15390.000343322754,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15409.99984741211,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15439.9995803833,
                          "date": "Apr 13, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15470.000267028809,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15510.000228881836,
                          "date": "Apr 15, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15539.999961853027,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15569.999694824219,
                          "date": "Apr 17, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15649.999618530273,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15680.000305175781,
                          "date": "Apr 19, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15720.000267028809,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15770.000457763672,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15800.000190734863,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15840.00015258789,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15840.00015258789,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15859.999656677246,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15909.99984741211,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 15939.9995803833,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 16010.000228881836,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 16040.000915527344,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 16180.000305175781,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 16209.999084472656,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 16229.999542236328,
                          "date": "May 2, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11079.999923706055,
                          "date": "Apr 11, 2016 3:00:02 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11079.999923706055,
                          "date": "Apr 12, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11130.000114440918,
                          "date": "Apr 13, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11159.99984741211,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11199.999809265137,
                          "date": "Apr 15, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11229.999542236328,
                          "date": "Apr 16, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11250.0,
                          "date": "Apr 17, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11289.999961853027,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11319.999694824219,
                          "date": "Apr 19, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11369.999885559082,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11399.999618530273,
                          "date": "Apr 21, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11470.000267028809,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11520.000457763672,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11520.000457763672,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11550.000190734863,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11609.999656677246,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11649.999618530273,
                          "date": "Apr 27, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11739.999771118164,
                          "date": "Apr 28, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11760.000228881836,
                          "date": "Apr 29, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11800.000190734863,
                          "date": "Apr 30, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11829.999923706055,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11869.999885559082,
                          "date": "May 2, 2016 12:00:02 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 32": {
                  "types": [],
                  "values": []
              },
              "Asunto Oy Helsingin Hjalmar A 9": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 49220.001220703125,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 49340.00015258789,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 49439.998626708984,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 49509.9983215332,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 49720.001220703125,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 49779.998779296875,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 50000.0,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 50240.0016784668,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 50369.998931884766,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 50549.99923706055,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 50729.99954223633,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 50799.99923706055,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51090.00015258789,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51279.998779296875,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51520.00045776367,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51700.00076293945,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51790.000915527344,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51889.99938964844,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51959.999084472656,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52119.998931884766,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52400.001525878906,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52569.99969482422,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23709.999084472656,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23750.0,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23840.00015258789,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23899.999618530273,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23969.999313354492,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24040.000915527344,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24139.999389648438,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24200.000762939453,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24280.000686645508,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24360.000610351562,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24409.99984741211,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24469.999313354492,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24590.00015258789,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24649.999618530273,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24700.000762939453,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24790.000915527344,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24889.999389648438,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 24940.000534057617,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25000.0,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25079.999923706055,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25219.999313354492,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 25290.000915527344,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 3": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 126800.00305175781,
                          "date": "Apr 11, 2016 3:00:00 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 126959.99908447266,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127069.99969482422,
                          "date": "Apr 13, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127269.9966430664,
                          "date": "Apr 14, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127379.99725341797,
                          "date": "Apr 15, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127449.99694824219,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127449.99694824219,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127489.99786376953,
                          "date": "Apr 18, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127690.00244140625,
                          "date": "Apr 19, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127809.99755859375,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127919.99816894531,
                          "date": "Apr 21, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 127959.99908447266,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128320.00732421875,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128559.99755859375,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128740.00549316406,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 128850.00610351562,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129009.99450683594,
                          "date": "Apr 27, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129119.9951171875,
                          "date": "Apr 28, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129259.99450683594,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129320.00732421875,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129330.00183105469,
                          "date": "May 1, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 129399.99389648438,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79739.99786376953,
                          "date": "Apr 11, 2016 3:00:00 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79900.0015258789,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79959.99908447266,
                          "date": "Apr 13, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80129.99725341797,
                          "date": "Apr 14, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80250.0,
                          "date": "Apr 15, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80290.00091552734,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80290.00091552734,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80360.00061035156,
                          "date": "Apr 18, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80440.00244140625,
                          "date": "Apr 19, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80540.00091552734,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80699.99694824219,
                          "date": "Apr 21, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80760.00213623047,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80949.99694824219,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81069.99969482422,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81190.00244140625,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81330.00183105469,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81379.99725341797,
                          "date": "Apr 27, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81519.9966430664,
                          "date": "Apr 28, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81620.00274658203,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81690.00244140625,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81699.99694824219,
                          "date": "May 1, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81790.00091552734,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 18": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 116069.99969482422,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116169.99816894531,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116400.0015258789,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116629.99725341797,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116790.00091552734,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 116930.00030517578,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 117150.0015258789,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 117290.00091552734,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 117419.99816894531,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 117629.99725341797,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 117849.9984741211,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45889.99938964844,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45889.99938964844,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46020.00045776367,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46110.00061035156,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46209.999084472656,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46240.0016784668,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46380.001068115234,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46430.00030517578,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46509.9983215332,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46580.00183105469,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46639.99938964844,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 4": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 185279.99877929688,
                          "date": "Apr 11, 2016 3:00:00 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 185410.00366210938,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 185619.9951171875,
                          "date": "Apr 13, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 185839.99633789062,
                          "date": "Apr 14, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 186210.0067138672,
                          "date": "Apr 15, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 186490.00549316406,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 186860.00061035156,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 187300.0030517578,
                          "date": "Apr 18, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 187539.9932861328,
                          "date": "Apr 19, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 187789.9932861328,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 188029.99877929688,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 188190.00244140625,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 188429.99267578125,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 188630.0048828125,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 188979.99572753906,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 189259.99450683594,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 189500.0,
                          "date": "Apr 27, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 189710.0067138672,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 189970.00122070312,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 190100.00610351562,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 190490.00549316406,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 190710.0067138672,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 143220.00122070312,
                          "date": "Apr 11, 2016 3:00:00 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 143380.0048828125,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 143550.0030517578,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 143750.0,
                          "date": "Apr 14, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 144100.00610351562,
                          "date": "Apr 15, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 144309.99755859375,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 144550.0030517578,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 144869.9951171875,
                          "date": "Apr 18, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 145070.00732421875,
                          "date": "Apr 19, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 145229.99572753906,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 145449.9969482422,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 145539.9932861328,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 145710.0067138672,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 145830.0018310547,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 146149.99389648438,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 146410.00366210938,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 146610.00061035156,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 146800.0030517578,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 147000.0,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 147169.9981689453,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 147470.00122070312,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 147669.9981689453,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 5": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 130660.00366210938,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 130779.99877929688,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 130899.99389648438,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 131119.9951171875,
                          "date": "Apr 14, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 131270.00427246094,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 131360.00061035156,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 131740.00549316406,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132039.9932861328,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132149.99389648438,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132369.9951171875,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132539.9932861328,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132679.99267578125,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132789.9932861328,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133100.00610351562,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133330.0018310547,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133460.0067138672,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133610.00061035156,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133770.00427246094,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133990.00549316406,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 134139.99938964844,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 134309.99755859375,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 134539.9932861328,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79430.00030517578,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79540.00091552734,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79569.99969482422,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79709.99908447266,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79790.00091552734,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 79839.99633789062,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80029.99877929688,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80139.99938964844,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80160.00366210938,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80330.00183105469,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80500.0,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80550.00305175781,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80589.99633789062,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80790.00091552734,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 80989.99786376953,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81050.00305175781,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81089.99633789062,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81169.99816894531,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81330.00183105469,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81379.99725341797,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81449.99694824219,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 81629.99725341797,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 16": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 51299.99923706055,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51319.99969482422,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51389.99938964844,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51430.00030517578,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51479.99954223633,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51520.00045776367,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51619.998931884766,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51669.99816894531,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51779.998779296875,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51860.00061035156,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51909.99984741211,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 51990.0016784668,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52090.00015258789,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52139.99938964844,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52139.99938964844,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52139.99938964844,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52180.00030517578,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52279.998779296875,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52349.998474121094,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52430.00030517578,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52520.00045776367,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 52580.00183105469,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22409.99984741211,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22409.99984741211,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22459.999084472656,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22459.999084472656,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22459.999084472656,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22469.999313354492,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22520.000457763672,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22530.000686645508,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22549.999237060547,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22629.9991607666,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22629.9991607666,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22680.00030517578,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22729.999542236328,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22729.999542236328,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22729.999542236328,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22729.999542236328,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22750.0,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22809.999465942383,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22819.99969482422,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22829.999923706055,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22899.999618530273,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22940.000534057617,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 17": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 59119.998931884766,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59119.998931884766,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59180.00030517578,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59299.99923706055,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59360.00061035156,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59389.99938964844,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59389.99938964844,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59409.99984741211,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59459.999084472656,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59549.99923706055,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59630.001068115234,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59779.998779296875,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59790.000915527344,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59840.00015258789,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59939.998626708984,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 59990.0016784668,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 60060.001373291016,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 60200.00076293945,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 60369.998931884766,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 60430.00030517578,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 60509.9983215332,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 60569.99969482422,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22719.999313354492,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22719.999313354492,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22750.0,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22780.000686645508,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22819.99969482422,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22829.999923706055,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22829.999923706055,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22829.999923706055,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22870.0008392334,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22899.999618530273,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22950.000762939453,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22979.999542236328,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 22979.999542236328,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23010.000228881836,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23049.999237060547,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23079.999923706055,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23120.0008392334,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23149.999618530273,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23190.000534057617,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23209.999084472656,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23260.000228881836,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 23290.000915527344,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 6": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 172970.00122070312,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 173059.99755859375,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 173279.99877929688,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 173550.0030517578,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 173699.9969482422,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 173869.9951171875,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 174220.00122070312,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 174479.99572753906,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 174479.99572753906,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 174479.99572753906,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 174479.99572753906,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 174479.99572753906,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 174479.99572753906,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 174589.99633789062,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 174809.99755859375,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 175000.0,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 175160.00366210938,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 175410.00366210938,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 175529.99877929688,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 175710.0067138672,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 176130.0048828125,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 176279.99877929688,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 169460.0067138672,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 169539.9932861328,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 169779.99877929688,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 170110.00061035156,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 170279.99877929688,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 170449.9969482422,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 170789.9932861328,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171070.00732421875,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171070.00732421875,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171070.00732421875,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171070.00732421875,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171070.00732421875,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171070.00732421875,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171210.0067138672,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171429.99267578125,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171669.9981689453,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 171889.99938964844,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 172179.99267578125,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 172289.9932861328,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 172500.0,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 173089.99633789062,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 173199.9969482422,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 14": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 131970.00122070312,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132100.00610351562,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132210.0067138672,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132330.0018310547,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132539.9932861328,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132710.0067138672,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 132910.00366210938,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133050.0030517578,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133220.00122070312,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133369.9951171875,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133559.99755859375,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133779.99877929688,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 133970.00122070312,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 134130.0048828125,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 134350.00610351562,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 134529.99877929688,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 134639.99938964844,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 134779.99877929688,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 134970.00122070312,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 135119.9951171875,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 135380.0048828125,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 135619.9951171875,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43189.998626708984,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43209.999084472656,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43259.9983215332,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43299.99923706055,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43369.998931884766,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43409.99984741211,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43479.99954223633,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43540.000915527344,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43590.00015258789,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43650.001525878906,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43709.999084472656,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43759.9983215332,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43810.001373291016,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43880.001068115234,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 43959.999084472656,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44029.998779296875,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44080.00183105469,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44130.001068115234,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44200.00076293945,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44259.9983215332,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44389.99938964844,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 44470.001220703125,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 15": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 64220.001220703125,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 64309.99755859375,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 64449.99694824219,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 64610.00061035156,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 64769.996643066406,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 64900.001525878906,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 65059.99755859375,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 65190.00244140625,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 65319.99969482422,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 65449.99694824219,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 65559.99755859375,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 65680.00030517578,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 65839.99633789062,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 65989.99786376953,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66099.9984741211,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66250.0,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66389.99938964844,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66550.00305175781,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66639.99938964844,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66680.00030517578,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66680.00030517578,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 66680.00030517578,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11180.000305175781,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11180.000305175781,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11210.000038146973,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11250.0,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11270.000457763672,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11279.999732971191,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11310.0004196167,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11340.00015258789,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11359.999656677246,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11390.000343322754,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11409.99984741211,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11420.000076293945,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11449.999809265137,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11460.000038146973,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11489.999771118164,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11520.000457763672,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11529.999732971191,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11579.999923706055,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11600.000381469727,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11619.999885559082,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11619.999885559082,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 11619.999885559082,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 1": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 145940.00244140625,
                          "date": "Apr 11, 2016 3:00:00 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 146050.0030517578,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 146309.99755859375,
                          "date": "Apr 13, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 146520.00427246094,
                          "date": "Apr 14, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 146779.99877929688,
                          "date": "Apr 15, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 147119.9951171875,
                          "date": "Apr 16, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 147479.99572753906,
                          "date": "Apr 17, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 147940.00244140625,
                          "date": "Apr 18, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 148190.00244140625,
                          "date": "Apr 19, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 148320.00732421875,
                          "date": "Apr 20, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 148600.00610351562,
                          "date": "Apr 21, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 148960.0067138672,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 149410.00366210938,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 149720.00122070312,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 149990.00549316406,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 150270.00427246094,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 150610.00061035156,
                          "date": "Apr 27, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 150869.9951171875,
                          "date": "Apr 28, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 151100.00610351562,
                          "date": "Apr 29, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 151449.9969482422,
                          "date": "Apr 30, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 151649.99389648438,
                          "date": "May 1, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 151880.0048828125,
                          "date": "May 2, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40020.00045776367,
                          "date": "Apr 11, 2016 3:00:00 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40049.99923706055,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40159.99984741211,
                          "date": "Apr 13, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40220.001220703125,
                          "date": "Apr 14, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40270.00045776367,
                          "date": "Apr 15, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40369.998931884766,
                          "date": "Apr 16, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40470.001220703125,
                          "date": "Apr 17, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40610.00061035156,
                          "date": "Apr 18, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40669.99816894531,
                          "date": "Apr 19, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40720.001220703125,
                          "date": "Apr 20, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40810.001373291016,
                          "date": "Apr 21, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 40979.99954223633,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41130.001068115234,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41220.001220703125,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41319.99969482422,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41470.001220703125,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41560.001373291016,
                          "date": "Apr 27, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41630.001068115234,
                          "date": "Apr 28, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41700.00076293945,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41759.9983215332,
                          "date": "Apr 30, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41830.00183105469,
                          "date": "May 1, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 41919.99816894531,
                          "date": "May 2, 2016 12:00:00 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 12": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 73309.99755859375,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73379.99725341797,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73480.0033569336,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73610.00061035156,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73669.99816894531,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73669.99816894531,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73669.99816894531,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73739.99786376953,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73849.9984741211,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73970.00122070312,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74069.99969482422,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74120.00274658203,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74120.00274658203,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74120.00274658203,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74190.00244140625,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74300.00305175781,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74389.99938964844,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74529.99877929688,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74639.99938964844,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74690.00244140625,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74690.00244140625,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74690.00244140625,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32540.000915527344,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32569.99969482422,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32619.998931884766,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32689.998626708984,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32709.999084472656,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32709.999084472656,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32709.999084472656,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32740.001678466797,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32799.99923706055,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32849.998474121094,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32900.001525878906,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32919.99816894531,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32919.99816894531,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32919.99816894531,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 32950.00076293945,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 33020.00045776367,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 33060.001373291016,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 33130.001068115234,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 33229.99954223633,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 33240.0016784668,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 33240.0016784668,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 33240.0016784668,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 13": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 73589.99633789062,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73610.00061035156,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73739.99786376953,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 73919.99816894531,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74069.99969482422,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74169.99816894531,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74290.00091552734,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74400.0015258789,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74510.00213623047,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74610.00061035156,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74760.00213623047,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74870.00274658203,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 74980.0033569336,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 75089.99633789062,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 75260.00213623047,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 75349.9984741211,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 75449.99694824219,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 75589.99633789062,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 75680.00030517578,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 75800.00305175781,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 75900.0015258789,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 76050.00305175781,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 29860.000610351562,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 29870.0008392334,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 29920.000076293945,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 29959.999084472656,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30059.999465942383,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30090.00015258789,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30129.9991607666,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30180.00030517578,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30229.999542236328,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30299.999237060547,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30329.999923706055,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30350.000381469727,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30409.99984741211,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30459.999084472656,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30559.999465942383,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30600.000381469727,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30629.9991607666,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30670.000076293945,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30709.999084472656,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30780.000686645508,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30819.99969482422,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 30860.000610351562,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 2": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 97080.00183105469,
                          "date": "Apr 11, 2016 3:00:00 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 97180.00030517578,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 97370.00274658203,
                          "date": "Apr 13, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 97489.99786376953,
                          "date": "Apr 14, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 97669.99816894531,
                          "date": "Apr 15, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 97760.00213623047,
                          "date": "Apr 16, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 97959.99908447266,
                          "date": "Apr 17, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 98089.99633789062,
                          "date": "Apr 18, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 98419.99816894531,
                          "date": "Apr 19, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 98519.9966430664,
                          "date": "Apr 20, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 98769.9966430664,
                          "date": "Apr 21, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 98900.0015258789,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 99120.00274658203,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 99300.00305175781,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 99540.00091552734,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 99769.9966430664,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100000.0,
                          "date": "Apr 27, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100209.99908447266,
                          "date": "Apr 28, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100400.0015258789,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100610.00061035156,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 100819.99969482422,
                          "date": "May 1, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 101069.99969482422,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45299.99923706055,
                          "date": "Apr 11, 2016 3:00:00 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45389.99938964844,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45540.000915527344,
                          "date": "Apr 13, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45610.00061035156,
                          "date": "Apr 14, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45669.99816894531,
                          "date": "Apr 15, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45709.999084472656,
                          "date": "Apr 16, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45770.00045776367,
                          "date": "Apr 17, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45849.998474121094,
                          "date": "Apr 18, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45970.001220703125,
                          "date": "Apr 19, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46040.000915527344,
                          "date": "Apr 20, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46150.001525878906,
                          "date": "Apr 21, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46220.001220703125,
                          "date": "Apr 22, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46290.000915527344,
                          "date": "Apr 23, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46380.001068115234,
                          "date": "Apr 24, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46500.0,
                          "date": "Apr 25, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46639.99938964844,
                          "date": "Apr 26, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46689.998626708984,
                          "date": "Apr 27, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46790.000915527344,
                          "date": "Apr 28, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46830.00183105469,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46990.0016784668,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47029.998779296875,
                          "date": "May 1, 2016 12:00:00 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47159.99984741211,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 10": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 105650.0015258789,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105720.00122070312,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 105930.00030517578,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106150.0015258789,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106250.0,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106419.99816894531,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106580.00183105469,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106760.00213623047,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106870.00274658203,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 106970.00122070312,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 107169.99816894531,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 107269.9966430664,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 107440.00244140625,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 107569.99969482422,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 107760.00213623047,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 107860.00061035156,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 108029.99877929688,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 108139.99938964844,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 108290.00091552734,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 108620.00274658203,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 108779.99877929688,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 108910.00366210938,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45369.998931884766,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45459.999084472656,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45549.99923706055,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45619.998931884766,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45689.998626708984,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45819.99969482422,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45950.00076293945,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 45970.001220703125,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46099.998474121094,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46180.00030517578,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46250.0,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46319.99969482422,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46439.998626708984,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46529.998779296875,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46659.99984741211,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46729.99954223633,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46799.99923706055,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46919.99816894531,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 46979.99954223633,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47110.00061035156,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47220.001220703125,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 47290.000915527344,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar A 11": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 28319.99969482422,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28340.00015258789,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28360.000610351562,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28379.9991607666,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28409.99984741211,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28430.00030517578,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28450.000762939453,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28500.0,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28510.000228881836,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28540.000915527344,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28549.999237060547,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28569.99969482422,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28610.000610351562,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28639.999389648438,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28659.99984741211,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28690.000534057617,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28709.999084472656,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28739.999771118164,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28780.000686645508,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28799.999237060547,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28829.999923706055,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 28870.0008392334,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8000.0,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8000.0,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8000.0,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8010.000228881836,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8029.999732971191,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8029.999732971191,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8039.999961853027,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8060.000419616699,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8079.999923706055,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8090.000152587891,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8090.000152587891,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8100.000381469727,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8119.999885559082,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8140.000343322754,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8149.999618530273,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8159.999847412109,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8170.000076293945,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8180.000305175781,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8189.999580383301,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8199.999809265137,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8210.000038146973,
                          "date": "May 1, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 8229.999542236328,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              },
              "Asunto Oy Helsingin Hjalmar B 19": {
                  "types": [
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 5,
                          "description": "Apartment cold water by meter value",
                          "type": "COLD_WATER"
                      },
                      {
                          "calculatable": true,
                          "integrationCalculatable": false,
                          "common": false,
                          "id": 6,
                          "description": "Apartment hot water by meter value",
                          "type": "HOT_WATER"
                      }
                  ],
                  "values": [
                      {
                          "typeId": 5,
                          "amount": 112419.99816894531,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112430.00030517578,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112449.99694824219,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112449.99694824219,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112449.99694824219,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112449.99694824219,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112449.99694824219,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112449.99694824219,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112449.99694824219,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112449.99694824219,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112449.99694824219,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 5,
                          "amount": 112610.00061035156,
                          "date": "May 2, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54139.99938964844,
                          "date": "Apr 11, 2016 3:00:01 PM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 12, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 13, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 14, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 15, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 16, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 17, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 18, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 19, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 20, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54150.001525878906,
                          "date": "Apr 21, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "Apr 22, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "Apr 23, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "Apr 24, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "Apr 25, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "Apr 26, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "Apr 27, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "Apr 28, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "Apr 29, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "Apr 30, 2016 12:00:01 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "May 1, 2016 12:00:02 AM"
                      },
                      {
                          "typeId": 6,
                          "amount": 54290.000915527344,
                          "date": "May 2, 2016 12:00:01 AM"
                      }
                  ]
              }
          }
      }});
      }

    }
  }
}
