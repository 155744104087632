<template>
  <div class="404 text-center">
    <div class="error-page-icon">
      <i class="fal fa-exclamation-circle"></i>
    </div>
    <h1>{{$t('page.404.title')}}</h1>
    <p>{{$t('page.404.content')}}</p>
    <p>
      <router-link to="/">{{$t('page.404.to-homepage-link-text')}}</router-link>
    </p>
    <p>
      <br />
    </p>
  </div>
</template>

<script>

export default {
  name: "PageNotFound",
  components: {}
};
</script>
<style scoped>
.error-page-icon {
  font-size: 3rem;
  margin: 1rem 0;
}
</style>