import TimelineItemModel from "@/models/TimelineItemModel";
import { STEP_INTRODUCTION_MAX_LENGTH } from "@/models/ProjectStep";
import { TimelineItemDefault, TimelineItemToday, TimelineItemStep } from "./";
import { isYitNewsPosting, isPriorityNewsPosting } from "@/utils/KenticoUtil";
import { isDeadline } from "@/utils/CalendarUtil";
import { isPastDate } from "@/utils/DateHelper";
import { getFeature } from "@/features";

const convertNewsItemToTimelineArticle = item => {
  let article = new TimelineItemModel();
      
  article.setId(item.system.id);
  article.setType(CONTENT_TYPES.news.key);
  article.setTitle(item.elements.title.value);
  article.setDescription(item.elements.introduction.value);
  article.setDate(item.elements.publish_date.value);
  if (isYitNewsPosting(item))
    article.setIsYit(true);

  if (item.elements.images.value && item.elements.images.value.length)
    article.setThumbnailSrc(item.elements.images.value[0].url + '?width=200');

  if (item.elements.articletypes && item.elements.articletypes.value.length) {
    item.elements.articletypes.value.map(term => {
      if (term && term.codename && typeof CONTENT_TYPES[term.codename] != 'undefined') {
        article.addCategory(CONTENT_TYPES[term.codename]);
      }
    });
  } else {
    article.setCategory([CONTENT_TYPES.news]);
  }

  if (isPriorityNewsPosting(item))
    article.setIsPriority(true);

  article.setLinkUrl({
    name: "/news/:id",
    params: { id: item.system.codename }
  });

  return article;
};

const CONTENT_TYPES = {
  today: {
    key: "today",
    name: "Today",
    translateKey: "common.today",
    component: TimelineItemToday,
  },
  news: {
    key: "news",
    name: "News",
    translateKey: "news.tag.news",
    component: TimelineItemDefault,
  },
  announcement: {
    key: "announcement",
    name: "Announcement",
    translateKey: "news.tag.news",
    component: TimelineItemDefault,
  },
  status_update: {
    key: "status_update",
    name: "Status update",
    translateKey: "news.tag.status.update",
    component: TimelineItemDefault,
  },
  priority_news: {
    key: "priority_news",
    name: "Priority news",
    translateKey: "news.tag.priority.news",
    component: TimelineItemDefault,
  },
  marketing: {
    key: "marketing",
    name: "Marketing",
    translateKey: "news.tag.marketing",
    component: TimelineItemDefault,
  },
  task: {
    key: "task",
    name: "Task",
    translateKey: "news.tag.task",
    component: TimelineItemDefault,
  },
  event: {
    key: "event",
    name: "Event",
    translateKey: "news.tag.event",
    component: TimelineItemDefault,
  },
  deadline: {
    key: "deadline",
    name: "Deadline",
    translateKey: "news.tag.materialDeadline",
    component: TimelineItemDefault,
  },
  service: {
    key: "service",
    name: "Service",
    translateKey: "news.tag.service",
    component: TimelineItemDefault,
  },
  step: {
    key: "step",
    name: "Step",
    translateKey: "news.tag.step",
    component: TimelineItemStep,
  },
  booking: {
    key: "booking",
    name: "Booking",
    translateKey: "news.tag.booking",
    component: TimelineItemDefault
  }
};

/**
 * 
 */
const SOURCES = {
  news: {
    name: "article",
    dispatcher: "fetchNews",
    getter: "getNews",
    pagination: true,
    mapper: convertNewsItemToTimelineArticle
  },
  events: {
    name: "event",
    dispatcher: "fetchCalendarEntries",
    dispatcherArgs: {
      order: 'date_start',
      orderDirection: 'desc'
    },
    getter: "getAllCalendarEntries",
    pagination: true,
    mapper: item => {
      let article = new TimelineItemModel();
          
      article.setId(item.system.id);
      article.setTitle(item.elements.title.value);
      article.setDate(new Date(item.elements.start_time.value).toString());
      if (isDeadline(item)) {
        article.setType(CONTENT_TYPES.task.key);
        article.setCategory([CONTENT_TYPES.task]);
      } else {
        article.setType(CONTENT_TYPES.event.key);
        article.setCategory([CONTENT_TYPES.event]);
        article.setDateEnd(new Date(item.elements.end_time.value).toString());
      }
      article.setLinkUrl({
        name: "/calendar/entry/:id",
        params: { id: item.system.codename }
      });

      return article;
    }
  },
  step: {
    name: "step",
    dispatcher: "fetchCurrentProjectSteps",
    getter: "getSteps",
    pagination: false,
    mapper: item => {
      let article = new TimelineItemModel();
      article.setType(CONTENT_TYPES.step.key);
      
      article.setId(item.id);
      article.setTitle(item.title);
      article.setCategory([CONTENT_TYPES.step]);
      
      if (item.introduction)
        article.setDescription(item.introduction);
      else if (item.content)
        article.setDescription(String(item.content).substring(0, STEP_INTRODUCTION_MAX_LENGTH));
      
      article.setDate(new Date(item.date_start).toString());

      article.setLinkUrl({
        name: "/step/:id",
        params: { id: item.codename }
      });

      return article;
    }
  },
  welcome_article: {
    name: "welcome_article",
    dispatcher: "fetchWelcomeArticle",
    getter: "getWelcomeArticleAsArray",
    pagination: false,
    mapper: convertNewsItemToTimelineArticle
  },
  studio: {
    name: "studio",
    dispatcher: "fetchMaterialSelectionDeadlines",
    getter: "getMaterialSelectionDeadlines",
    requiredFeatures: ['material-selection'],
    mapper: item => {
      let article = new TimelineItemModel();
      if (!item.event)
        return;
      
      article.setId(item.event.id);
      article.setType(CONTENT_TYPES.deadline.key);
      article.setTitle(item.event.name);
      article.setDate(new Date(item.date).toString());
      article.setCategory([CONTENT_TYPES.deadline]);
      article.setLinkUrl({
        name: "/material-selection/deadline/:id",
        params: {id: item.event.id}
      });

      if (item.date && isPastDate(item.date)) article.state = "confirmed";

      return article;
    }
  },
  booking: {
    name: "booking",
    dispatcher: "fetchOne4AllBookings",
    dispatcherArgs: {
      past: 'true'
    },
    getter: "getOne4AllBookings",
    requiredFeatures: ['one4all-bookings'],
    mapper: item => {
      let article = new TimelineItemModel();
      article.setId(item.uuid);
      article.setType(CONTENT_TYPES.booking.key);
      article.setCategory([CONTENT_TYPES.booking]);
      article.setTitle(item.calendarName);
      article.setDate(item.begins);
      article.setDateEnd(item.ends);

      if (item.servicePicture && item.servicePicture.length)
        article.setThumbnailSrc(item.servicePicture);

      article.setLinkUrl({
        name: "/booking/:id",
        params: { id: item.uuid }
      });

      return article;
    }
  }
};

const getSourcesForTimeline = () => {
  return Object.values(SOURCES).filter(source => {
    if (!Array.isArray(source.requiredFeatures) || !source.requiredFeatures.length) {
      return true;
    } 

    let isRequiredFeatureNotActive = source.requiredFeatures.find(f => !getFeature(f));
    return !isRequiredFeatureNotActive ? true : false;
  });
}

export {
  CONTENT_TYPES,
  SOURCES,
  getSourcesForTimeline
}