import {AzureLiferayClient} from "./AzureLiferayClient";
import AzureFunctionClient from "./AzureFunctionClient";
import { includeJwt } from "./AzureFunctionClient";
import BaseService from "./BaseService";
import store from "@/store";

export default class CommentService extends BaseService {
  constructor() {
    super();
    this.baseUrl = "/delegate/yithome-api/functions";
  }

  routeViaLiferay() {
    return false;
  }

  /**
   * Fetch notifications for user
   */
  fetchUserNotifications(projectId = null) {

    if(this.routeViaLiferay()) {
      if(!projectId)
        projectId = store.getters.getSelectedHousingCompany.hcExt;
      return AzureLiferayClient.get(`${this.baseUrl}/getUserNotifications/${projectId}`);
    } else {
      let params = {};
      if(projectId)
        params.projectId = projectId;
      let query = this._buildUrl(`getUserNotifications`, params);
      return AzureFunctionClient.get(query);
    }
    
  }

  markNotificationRead(id) {

    if(this.routeViaLiferay()) {
      return AzureLiferayClient.get(`${this.baseUrl}/markNotificationRead/${id}`);
    } else {
      let params = {
        id: id
      };
      let query = this._buildUrl(`markNotificationRead`, params);
      return AzureFunctionClient.get(query);
    }
  }

  markNotificationsRead(payload) {   
    return AzureFunctionClient.post('markNotificationRead', payload);
  }

  fetchNotificationSettings(params){
    let query = this._buildUrl(`notificationSettings`, params);
    return AzureFunctionClient.get(query);
  }

  setNotificationSettings(params, settings) {
    let query = this._buildUrl(`notificationSettings`, params);
    return AzureFunctionClient.post(query, settings);
  }
}
