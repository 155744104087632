<template>
  <div class="timeline-section">
    <h2 class="timeline-section__title">{{getTitle}}</h2>
    <slot />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { i18n } from "@/plugins/i18n";

export default {
  name: "TimelineSection",
  props: {
    title: {type: String},
    date: {type: Date, default: new Date()}
  },
  computed: {
    getTitle() {
      if(this.title)
        return this.title
      else if (this.date)
        return dayjs(this.date).locale(i18n.locale).format('MMMM YYYY');
    }
  }
}
</script>

<style>

</style>