import { i18n } from "@/plugins/i18n";
import {validateMaxLength, validateMinLength, validateEmail} from "@/utils/ValidationUtil";

class FormValidationUtil {
  /**
   * Create a new Errors instance.
   */
  constructor(form, validation) {
    this.errors = {};
    this.validation = validation;
    this.form = form;
    this.availableValidators = {
      required: {
        message: i18n.global.t("common.messages.fieldRequired")
      },
      maxLength: {
        message: i18n.global.t("common.messages.fieldMaxLengthExceeded", [50])
      },
      minLength: {
        message: i18n.global.t("common.messages.generalFieldIsNotValidMessage")
      }
    };
  }

  /**
   * Determine if an errors exists for the given field.
   *
   * @param {string} field
   */
  has(field) {
    return this.errors.hasOwnProperty(field);
  }

  /**
   * Determine if we have any errors.
   */
  any() {
    return Object.keys(this.errors).length > 0;
  }

  /**
   * Temporary method for checking fields that have errors
   */
  getAllErrors() {
    return Object.keys(this.errors);
  }

  /**
   * Retrieve the error message for a field.
   *
   * @param {string} field
   */
  get(field) {
    //console.log("getting error", field);
    if (this.errors[field]) {
      return this.errors[field][0];
    }
    return false;
  }

  /**
   * Record the new errors.
   *
   * @param {object} errors
   */
  record(field, error) {
    if (!this.errors[field]) this.errors[field] = [];
    this.errors[field].push(error);
  }

  /**
   * Clear one or all error fields.
   *
   * @param {string|null} field
   */
  clear(field) {
    if (field) {
      delete this.errors[field];

      return;
    }

    this.errors = {};
  }

  /**
   * Loop through the requirements and run validation methods
   *
   * @param {object} errors
   */
  validate() {
    this.clear();
    let values = Object.entries(this.validation);
    let entries = Object.keys(this.validation);

    values.map(obj => {
      let fieldName = obj[0];
      let validators = obj[1];
      let methods = Object.keys(validators);

      methods.map(i => {
        let value = validators[i];
        let method = i;
        //console.log("field: " + fieldName, ", key: " + method, ", value: " + value);
        if (this.availableValidators.hasOwnProperty(method)) {
          let passed = this[method](fieldName, value);
          if (!passed) {
            this.record(fieldName, this.availableValidators[method].message);
          }
        }
      });
    });
    return !this.any();
  }

  /**
   * Validate that the value is inputed
   * @param {object} field
   */
  required(field) {
    if (!this.validation[field].required) return true;

    if (
      typeof this.form[field] !== "undefined" &&
      this.form[field] !== null &&
      this.form[field] !== ""
    )
      return true;
    else return false;
  }

  /**
   * Validate max length of a string
   * @param {object} field
   */
  maxLength(field) {
    //console.log("validating max length: ", this.validation[field]);
    if (!this.validation[field].maxLength) return true;

    if (
      typeof this.form[field] === "string" &&
      validateMaxLength(this.form[field], this.validation[field].maxLength)
    )
      return true;
    else return false;
  }

  /**
   * Validate min length of a string
   * @param {object} field
   */
  minLength(field) {
    //console.log("validating max length: ", this.validation[field]);
    if (!this.validation[field].minLength) return true;

    if (
      typeof this.form[field] === "string" &&
      validateMinLength(this.form[field], this.validation[field].minLength)
    )
      return true;
    else return false;
  }

  /**
   * Validate max length of a string
   * @param {object} field
   */
  isEmail(field) {
    console.log("validating email: ", this.validation[field]);
    if (!this.validation[field].maxLength) return true;

    if (
      typeof this.form[field] === "string" &&
      this.validateEmail(this.form[field])
    )
      return true;
    else return false;
  }

  /**
   * Validate value is number
   * @param {object} field
   */
  number(field) {
    if (this.validation[field].pattern) {
      //@TODO check pattern to match the number
      if (this.form[field]) return true;
    }

    return true;
  }

}

export default FormValidationUtil;
