<template>
  <div class="main-navigation" v-if="getMainNavigationItems.length">
    <ul>
      <li
        v-for="(item, key) in getMainNavigationItems"
        :key="item.href"
        :class="{ appended: getVisibility(key) }"
      >
        <router-link
          :to="item.href"
          v-if="!isAbsoluteUrl(item.href) && !item.hide"
          :exact="item.exact ? true : null"
        >
          <span v-on:click="onSelectItem(subitem)">
            {{ $t(item.titleLanguageKey) }}
          </span>
        </router-link>
        <a v-if="isAbsoluteUrl(item.href) && !item.hide" :href="item.href" :target="item.target ? item.target : '_self'">
          <i
            class="link-icon"
            :class="item.iconClass"
            aria-hidden="true"
            v-if="item.iconClass"
          ></i>
          {{ $t(item.titleLanguageKey) }}
        </a>
        <button
          class="button button--link navigation-toggle"
          v-if="item.items && item.items.length && item.hideChildren !== true"
          @click="toggleVisibility(key)"
        >
          <i class="far fa-angle-down"></i>
        </button>

        <transition name="slidedown">
          <div v-if="getVisibility(key)">
            <ul
              v-if="
                item.items && item.items.length && item.hideChildren !== true
              "
            >
              <li v-for="subitem in item.items" :key="subitem.href">
                <router-link
                  :to="subitem.href"
                  v-if="!isAbsoluteUrl(subitem.href) && !subitem.hide"
                  :exact="item.exact === true ? true : null"
                  >
                    <span v-on:click="onSelectItem(subitem)">
                      {{ $t(subitem.titleLanguageKey) }}
                    </span>
                  </router-link>
                <a
                  v-if="isAbsoluteUrl(subitem.href) && !subitem.hide"
                  :href="subitem.href"
                  :target="subitem.target ? subitem.target : '_self'"
                  >{{ $t(subitem.titleLanguageKey) }}</a
                >
              </li>
            </ul>
          </div>
        </transition>
      </li>
    </ul>
    <div
      class="section-switcher"
      v-if="getCurrentUser.hasAnyOfRoles(['admin', 'yitUser', 'contributor'])"
    >
      <router-link
        to="/"
        v-if="getIsAdminSection"
        class="section-switcher__to-home"
      >
        <i class="fal fa-cog-left"></i>
        <span v-on:click="$emit('on-item-click')">{{ $t("common.navigation.to-home-page") }}</span>
      </router-link>
      <router-link
        to="/admin"
        class="section-switcher__to-admin"
        v-else
      >
        <i class="fal fa-cog"></i>
        <span v-on:click="$emit('on-item-click')">{{ $t("common.navigation.to-admin-section") }}</span>
      </router-link>

      <router-link
        to="/profile"
        class="section-switcher__to-home"
      >
        <i class="fal fa-user"></i>
        <span v-on:click="$emit('on-item-click')">{{ $t("navigation.mysettings") }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MainNavigation",
  components: {},
  props: [],
  data() {
    return {
      visible: {}
    };
  },
  computed: {
    ...mapGetters([
      "getMainNavigationItems",
      "getCurrentNavigationItem",
      "getIsAdminSection",
      "getCurrentUser"
    ]),
    getVisibilities() {
      return this.visible;
    }
  },
  methods: {
    ...mapMutations(['resetTimelineModule']),
    toggleVisibility(key, activate = false) {
      if (!this.visible[key] || activate) this.visible[key] = true;
      else this.visible[key] = false;
      this.$forceUpdate();

      return this.visible;
    },
    getVisibility(key) {
      return this.visible[key] === true ? true : false;
    },
    onSelectItem(item, key) {
      this.resetTimelineModule(false);
      this.toggleVisibility(key);
      this.$emit("on-item-click");
    },
    isAbsoluteUrl(url) {
      if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    let openChildMenuOnMount = false;
    this.getMainNavigationItems.map((navLink, key) => {
      if (
        this.getCurrentNavigationItem &&
        this.getCurrentNavigationItem.href == navLink.href
      ) {
        this.visible[key] = true;
        openChildMenuOnMount = true;
      }
    });
    if (openChildMenuOnMount) this.$forceUpdate();
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
.main-navigation {
  @media screen and (min-width: $breakpoint-lg) {
    padding: 0 0 2rem 0;
    position: sticky;
    top: 80px;
  }
  @media screen and (min-width: $breakpoint-xl) {
    margin-right: 1.5rem;
    max-width: 400px;
  }
  ul {
    background: $rose30;
    list-style: none;
    margin: 0 0 0.5rem;
    li {
      margin: 0;
      padding: 0;
      position: relative;
      &:first-child a {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    a {
      span {
        display: inline-block;
        width: 100%;
      }
      .link-icon {
        font-size: 1.2em;
        margin-right: 0.7em;
        position: relative;
        top: 1px;
      }
      display: flex;
      align-items: center;
      padding: 1.4em 1em 1.4em 2em;
      line-height: 1.3;
      font-size: 1.1em;
      text-decoration: none;
      border-bottom: 1px solid $greyBlueLight;
      transition: all 0.2s ease-in-out;

      &.router-link-exact-active,
      &.router-link-active,
      &.active,
      &:focus {
        background-color: $backgroundColorGreyMid;
        &:hover {
          background-color: $backgroundColorGreyMid;
        }
      }

      &:hover {
        background: $brandColorPrimaryLight;
        color: $baseFontColor;
      }
    }

    .navigation-toggle {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      border-bottom: 0px !important;
      font-size: 1.5rem;
      z-index: 11;
      padding: 1rem;
    }

    a.router-link-exact-active ~ .navigation-toggle,
    a.router-link-active ~ .navigation-toggle,
    .appended .navigation-toggle,
    &.active .navigation-toggle {
      i {
        transform: rotate(180deg);
      }
    }
    a.router-link-exact-active ~ .navigation-toggle,
    a.router-link-active ~ .navigation-toggle,
    &.active .navigation-toggle {
      color: #fff;
    }

    /** Level 2 */
    ul,
    ol {
      margin: 0;
      li {
        a {
          padding-left: 3rem;
          font-size: 1.1rem;

          &.router-link-exact-active,
          &.active,
          &:focus {
            background-color: $brandColorPrimaryLight;
            color: $baseFontColor;
            &:hover {
              background-color: $brandColorPrimaryLight;
              color: $baseFontColor;
            }
          }
        }
      }
    }
  }
  .section-switcher {
    a {
      color: $blueMedium;
      display: block;
      margin-bottom: .5rem;
      span {
        text-decoration: underline;
      }
    }
    padding-left: 1rem;
    padding-top: 0.8rem;
    i {
      padding: 0 0.5rem;
    }
    &__to-home,
    &__to-admin {
      i {
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
</style>
