<template>
  <div class="admin-toolbar" :class="{ 'admin-toolbar--vertical': vertical }">
    <slot name="top"></slot>
    <button
      class="button button--link edit-link"
      v-if="showEdit"
      @click="$emit('edit-click', id, $event)"
    >
      <i class="far fa-pen"></i>
      <span :class="{ 'sr-only': !showLabels }">{{$t('common.edit')}}</span>
    </button>
    <button
      class="button button--link delete-link"
      v-if="showDelete"
      @click="onDeleteClick"
    >
      <i class="far fa-trash-alt"></i>
      <span :class="{ 'sr-only': !showLabels }">{{$t('common.remove')}} </span>
    </button>
    <slot></slot>
    <Modal @close="deleteModalOpen = false" v-if="deleteModalOpen">
      {{ confirmMessage ? confirmMessage : $t("common.onDeleteConfirmText") }}
      <template v-slot:footer>
        <div class="button-group text-center">
          <button
            type="button"
            class="button button--primary"
            @click="onDeleteApproveModal"
          >
            {{ $t("common.yes") }}
          </button>
          <button
            type="button"
            class="button button--link"
            @click="deleteModalOpen = false"
          >
            {{ $t("common.cancel") }}
          </button>
        </div>
      </template>
    </Modal>

    <Modal @close="banModalOpen = false" v-if="banModalOpen">
      <p>{{ $t("common.onBanConfirmText") }}</p>
      <date-picker
        type="date"
        dateFormat="DD.MM.YYYY"
        v-model="banExpiryDate"
        class="banDatePicker"
        />
      <template v-slot:footer>
        <div class="button-group text-center">
          <button
            type="button"
            class="button button--primary"
            @click="onBanApproveModal"
          >
            {{ $t("common.yes") }}
          </button>
          <button
            type="button"
            class="button button--link"
            @click="banModalOpen = false"
          >
            {{ $t("common.cancel") }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "@/components/common/Modal";
import DatePicker from "@/components/common/Form/DatePicker";
import dayjs from 'dayjs'

export default {
  name: "AdminToolBar",
  props: {
    id: {
      type: String
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    showBan: {
      type: Boolean,
      default: true
    },
    showConfirmOnDelete: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: "left"
    },
    vertical: {
      type: Boolean,
      default: false
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String
    },
    confirmMessage: {
      type: String
    }
  },
  components: {
    Modal,
    DatePicker
  },
  data() {
    return {
      deleteModalOpen: false,
      banModalOpen: false,
      banExpiryDate: null
    };
  },
  computed: {
    ...mapGetters(["getUserRole", "getCurrentUser", "getSelectedHousingCompany"]),
  },
  methods: {
    ...mapActions(["banUser"]),
    onDeleteClick(event) {
      if (this.showConfirmOnDelete) this.deleteModalOpen = true;
      else this.$emit("delete-click", this.id, event);
    },
    onDeleteApproveModal(event) {
      this.$emit("delete-click", this.id, event);
      this.deleteModalOpen = false;
    },
    onBanApproveModal() {
      this.banModalOpen = false
      this.banUser({ userUuid: this.userId, expiryDate: dayjs(this.banExpiryDate).endOf("day"), projectId: this.getSelectedHousingCompany.hcExt })
        .then(it => {
        })
        .catch(console.error)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";
.admin-toolbar {
  /*background: #313131;

  padding: 0.5rem 0.5rem;
  border-radius: 5px;*/
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0.8rem 0;
  font-size: 0.9rem;

  > .button,
    .button-with-confirmation button,
    .button-with-confirmation .button {
    background-color: transparent;
    border-color: transparent !important;
    margin-right: 1rem;
    font-size: .9rem;
    //color: #fff;

    span,
    i {
      transition: all 0.15s ease-in;
      //color: #fff;
    }

    &:hover,
    &:focus {
      border-color: rgba(255, 255, 255, 0.2);
      background: transparent !important;
    }
    /*@media screen and (min-width: $breakpoint-lg) {
      &:hover {
        span,
        i {
          transform: scale(1.15);
        }
      }
    }*/
  }

  a,
  a:hover,
  a:focus {
    color: #fff;
  }

  .ident {
    height: 1rem;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-right: 6px;
    margin-left: 5px;
  }

  &--vertical {
    flex-direction: column;
    align-items: flex-start !important;

     > .button,
    .button-with-confirmation button,
    .button-with-confirmation .button {
      text-align: left;
      margin: .5rem 0;
    }
  }
}
.button-group {
  button {
    margin-right: 0.5rem;
  }
}
</style>
<style lang="scss">
.admin-toolbar .button-with-confirmation button, .admin-toolbar .button-with-confirmation .button {
  border-color: transparent !important;
  font-size: 1em;
}

.admin-toolbar--vertical .notification-subscription-button {
  text-align: left;
  margin: 0.5rem 0;
  button {
    font-size: .9rem;
  }
}

.banDatePicker {
  margin: 0 auto;
}

</style>