import { normalize, normalizeSync } from "normalize-diacritics";
import { getMappedTaxonomy } from "./MappedTaxonomies";
import store from "@/store/index";
/**
 *
 * @param [array] filters
 *
 */
const normalizeBuildingTaxonomyForKentico = async filters => {
  let normalizedValues = [];
  for (const filter of filters) {
    const value = await normalize(filter);
    normalizedValues.push(value.replace(/\W/g, "_").toLowerCase());
  }

  return normalizedValues;
};

const normalizeBuildingTaxonomySync = (value) => {
  return getMappedTaxonomy(normalizeSync(value))
  .toLowerCase()
  .replace(/\s/g, "_")
  .replace("-", "_")
  .replace(".", "_");
};

const getBuildingTaxonomyFiltersForKentico = async (selectedHc = null) => {
  if(!selectedHc)
    selectedHc = store.getters.getSelectedHousingCompany;
  let filters = [];

  if (selectedHc.addressCountry) {
    filters.push(selectedHc.addressCountry);
  }

  if (selectedHc.businessUnit) {
    if (
      selectedHc.addressCountry &&
      selectedHc.businessUnit == selectedHc.addressCountry
    ) {
      filters.push(getMappedTaxonomy(selectedHc.businessUnit));
    } else {
      filters.push(selectedHc.businessUnit);
    }
  }

  if (selectedHc.addressCity) {
    if (
      selectedHc.businessUnit &&
      selectedHc.addressCity == selectedHc.businessUnit
    ) {
      filters.push(getMappedTaxonomy(selectedHc.addressCity));
    } else {
      filters.push(selectedHc.addressCity);
    }
  }

  if (selectedHc.marketingName) {
    if (
      selectedHc.addressCity &&
      selectedHc.marketingName == selectedHc.addressCity
    ) {
      filters.push(getMappedTaxonomy(selectedHc.marketingName));
    } else {
      filters.push(selectedHc.marketingName);
    }
  }

  if (selectedHc.name) {
    if (
      selectedHc.marketingName &&
      selectedHc.name == selectedHc.marketingName
    ) {
      console.log("FILTERS", getMappedTaxonomy(selectedHc.name));
      filters.push(getMappedTaxonomy(selectedHc.name));
    } else {
      filters.push(getMappedTaxonomy(selectedHc.name));
    }
  }

  try {
    console.log("FILTERS", filters);
    filters = await normalizeBuildingTaxonomyForKentico(filters);
    return filters;
  } catch (err) {
    console.log(err);
    return [];
  }
};



export {
  normalizeBuildingTaxonomyForKentico,
  normalizeBuildingTaxonomySync,
  getBuildingTaxonomyFiltersForKentico
};
