import { isPast } from "@/utils/DateHelper";
import store from "@/store";

const warrantyFeature = {
  id: "warranty",
  name: "feature.warranty.name",
  iconClass: "fal fa-screwdriver",
  globalSettings: {},
  schema: {
    /*showMainNavigationLink: {
          type: Boolean,
          label: "feature.common.showMainNavigationLink.label",
          default: true,
          required: true
        }*/
  },
  initialize: (feature, settings) => {
    //if(settings && settings.showMainNavigationLink) {
    //only for living state
    if (store.getters.getCurrentHcState != "living") return;

    let warrantyMainNavigationLink = {
      titleLanguageKey: "navigation.warranty",
      href: "/apartment/warranty",
      iconClass: "fal fa-screwdriver",
      hide: false,
      orderNumber: 20,
    };

    //Check first from Azure project
    if (
      store.getters.isWarrantyReportingActive &&
      store.getters.isWarrantyReportingStarted
    ) {
      //warrantyMainNavigationLink.href = "/apartment/warranty";
      store.dispatch("addNavigationLink", warrantyMainNavigationLink);
    } else {
      let currentHc = store.getters.getSelectedHousingCompany;
      if (
        currentHc &&
        !isPast(currentHc.warrantyPeriodEndDate) &&
        store.getters.isWarrantyReportingStarted
      ) {
        let payload = {
          id: currentHc.hcExt,
        };
        if (currentHc && currentHc.isYSEProject)
          warrantyMainNavigationLink.titleLanguageKey =
            "navigation.warranty.yse";

        let currentApt = store.getters.getSelectedApartment;
        if (currentApt) {
          payload.aptId = currentApt.aptext;
        }
        store
          .dispatch("fetchHousingCompanyRepairStatus", payload)
          .then((res) => {
            //If repairs are not accepted and reporting is open, let's display the main navigation link
            if (res && res != "ACCEPTED" && res != "NOT_OPEN") {
              store.dispatch("addNavigationLink", warrantyMainNavigationLink);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    //}
  },
};

export { warrantyFeature };
