<template>
  <div class="campaign">
    <Card
      :url="{ name: '/service-shop/campaign/:id', params: { id: campaign.campaign_ID } }"
      :thumbnailSrc="campaign.picture"
      :title="campaign.title"
      :ctaButtonText="$t('campaigns.common.moreinfo.button')"
      :badge="printDiscount"
    >
      <template v-slot:header>
        <div
          class="campaign-partner"
          v-if="campaign.company && campaign.company.name"
        >{{campaign.company.name}}</div>
      </template>
      <template v-slot:footerBottom>
        <div
          class="campaign-duration"
          v-if="isActive"
        >{{$t('campaigns.is-valid-between')}} {{$filters.date_format_numbers(campaign.activeStartDate)}} - {{$filters.date_format_numbers(campaign.activeEndDate)}}</div>
        <div class="campaign-duration" v-else>{{$t('service-shop.campaign.not-active')}}</div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/common/Lists/Card";

export default {
  name: "Campaign",
  props: ["campaign"],
  components: {
    Card
  },
  computed: {
    printDiscount() {
      return this.campaign.discountPercentage && !this.campaign.advertisement
        ? parseInt(this.campaign.discountPercentage * 100) + "%"
        : "";
    },
    isActive() {
      let endDate = new Date(this.campaign.activeEndDate);

      if (new Date().getTime() <= endDate.getTime()) return true;
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.campaign {
  height: 100%;
  padding-bottom: 2rem;
}
</style>