export default [
  {
    path: "/documents/",
    name: "/documents/",
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "@/views/Documents/View.vue"
      ),
    meta: {
      sectionTitle: "navigation.documents",
      title: "navigation.documents"
    }
  },
  {
    path: "/documents/download/:id",
    name: "/documents/download/:id",
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "@/views/Documents/Download.vue"
      ),
    meta: {
      sectionTitle: "navigation.documents",
      title: "navigation.documents"
    }
  },
  {
    path: "/my-apartment/documents/",
    name: "/my-apartment/documents/",
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "@/views/Documents/View.vue"
      ),
    meta: {
      sectionTitle: "navigation.documents",
      title: "navigation.documents"
    }
  },
  {
    path: "/admin/documents/:hcExtId/:aptExtId",
    name: "/admin/documents/:hcExtId/:aptExtId",
    component: () =>
      import(
        /* webpackChunkName: "documents-admin" */ "@/views/Admin/Documents/Index.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.documents",
      title: "navigation.documents",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'chairman', 'boardMember', 'rentalManager']
      }
    }
  },
  {
    path: "/admin/documents/:hcExtId",
    name: "/admin/documents/:hcExtId",
    component: () =>
      import(
        /* webpackChunkName: "documents-admin" */ "@/views/Admin/Documents/Index.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.documents",
      title: "navigation.documents",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'chairman', 'boardMember', 'ceeBoardMember', 'rentalManager']
      }
    }
  },
  {
    path: "/admin/videos/:hcExtId",
    name: "/admin/videos/:hcExtId",
    component: () =>
      import(
        /* webpackChunkName: "documents-admin" */ "@/views/Admin/Documents/Videos.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.documents",
      title: "navigation.documents",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'chairman', 'boardMember', 'ceeBoardMember']
      }
    }
  },
  {
    path: "/admin/documents",
    name: "/admin/documents",
    component: () =>
      import(
        /* webpackChunkName: "documents-admin" */ "@/views/Admin/Documents/HousingCompanies.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.documents",
      title: "navigation.documents",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'chairman', 'boardMember', 'ceeBoardMember', 'rentalManager']
      }
    }
  }
];