export default [
  {
    path: "/admin/contacts",
    name: "/admin/contacts",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Contacts/List.vue"),
    meta: {
      admin: true,
      sectionTitle: "News admin",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager']
      }
    }
  },
  {
    path: "/admin/contacts/edit/:id",
    name: "/admin/contacts/edit/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Contacts/Edit.vue"),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager']
      }
    }
  },
  {
    path: "/admin/contacts/created/:id",
    name: "/admin/contacts/created/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Contacts/AfterCreate.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/contacts/new",
    name: "/admin/contacts/new",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Contacts/Edit.vue"),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager']
      }
    }
  }
];