<template>
<div class="toolbar-link__wrapper">
  <a
    v-if="href && typeof href == 'string' && href.indexOf('http') !== -1"
    :target="target ? target : '_blank'"
    :href="href"
    class="toolbar-link"
  >
    <div class="toolbar-link__icon" :class="getCssClasses">
      <i class="disable-transition" :class="iconCssClass"></i>
    </div>
    <div class="toolbar-link__body">
      <slot>
        <span v-if="text" v-html="text"></span>
      </slot>
    </div>
    <span class="toolbar-link__arrow">
      <i class="fal fa-chevron-right"></i>
    </span>
  </a>
  <router-link
    v-else-if="href && href !='#'"
    :to="href"
    :target="target"
    class="toolbar-link"
  >
    <div class="toolbar-link__icon" :class="getCssClasses">
      <i class="disable-transition" :class="iconCssClass"></i>
    </div>
    <div class="toolbar-link__body">
      <slot>
        <span v-if="text">{{ text }}</span>
      </slot>
    </div>
    <span class="toolbar-link__arrow">
      <i class="far fa-chevron-right"></i>
    </span>
  </router-link>
  <div v-else class="toolbar-link">
    <div class="toolbar-link__icon" :class="getCssClasses">
      <i class="disable-transition" :class="iconCssClass"></i>
    </div>
    <div class="toolbar-link__body">
      <slot>
        <span v-if="text">{{ text }}</span>
      </slot>
    </div>
  </div>
  <div class="toolbar-link__footer" v-if="getLinks && getLinks.length > 0">
    <ButtonWithConfirm
        v-for="(link, index) in getLinks"
        :key="index"
        :title="$t('users.admin.remove.user.role')"
        type="link"
        @on-click="onButtonClick(link)"
        :confirmModalText="$t('users.admin.remove.user.role.confirm')"
      >
        <i class="fal fa-unlink"></i>
        {{ $t("users.admin.remove.user.roles", [$AppSettings.UIRoles[link] ? $t($AppSettings.UIRoles[link].translationKey) : $t("user.admin.role." + link)]) }}
      </ButtonWithConfirm>
      <slot name="footer"></slot>
  </div>
</div>
</template>

<script>
import ButtonWithConfirm from "@/components/Admin/Common/ButtonWithConfirm";
export default {
  name: "ToolbarLink",
  components: {ButtonWithConfirm},
  props: {
    icon: {},
    href: {},
    iconCssClass: {},
    text: {},
    target: {},
    links: {
      type: Array,
    },
    color: {
      type: String,
      default: "primary",
    },
    projectId: {},
    apartment: {}
  },
  computed: {
    getHref() {
      let href = "#";

      if (href) {
      }

      return ret;
    },
    getCssClasses() {
      let cssClasses = "";
      cssClasses += "bg--" + this.color;
      return cssClasses;
    },
    getLinks() {
      let ret = this.links;
      if(ret.length) {
        return ret.filter(l => l != 'siteAdministrator');
      }

      return [];
    }
  },
  methods: {
      onButtonClick(index, event) {
        this.$emit("on-click", {projectId: this.projectId, apartment: this.apartment, role: index}, event);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
.toolbar-link__wrapper {
  margin-bottom: .6rem;
}
.toolbar-link {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  border: 0;
  background: #fff;
  padding-right: 0.6rem;


  &__body,
  &__icon {
    padding: .7rem 0.3rem .6rem;
    font-size: 1rem;
    font-family: $fontPrimaryBold;
  }
  &__body {
    display: flex;
    align-items: center;
  }

    &__footer {
        border-top: 1px solid rgba(0,0,0,.1);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: .5rem 1rem .7rem 1rem;
        background: $white;

        small {
          font-size:.7rem;
        }
        .button-with-confirmation {
          margin-right: 1rem;
        }
    }

  &__icon {
    min-height: 2.8rem;
    height: auto;
    min-width: 4.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.6rem;

    i {
      font-size: 1.2rem;
    }
  }

  &__arrow {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

    i {
      font-size: 1.3rem;
      transform: translateX(0);
      transition: all 0.2s ease-in-out;
    }
  }

  &:hover,
  &:focus {
    .toolbar-link__arrow i {
      transform: translateX(0.4rem);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    padding-right: 1rem;

    &__body,
    &__icon {
      padding: 0.6rem 0.8rem;
    }
  }
}
</style>
