export default [
    {
        path: "/community",
        name: "/community",
        redirect: "/community/public-discussions",
        component: () =>
            import(
          /* webpackChunkName: "community" */ "@/views/Community/Index.vue"
            ),
        meta: {
            title: "navigation.community",
            sectionTitle: "navigation.community",
        },
        children: [
            {
                path: "public-discussions/:id",
                name: "public-discussions/:id",
                component: () =>
                    import(
              /* webpackChunkName: "community" */ "@/views/Community/PublicMessage.vue"
                    ),
                meta: {
                    title: "navigation.community",
                    sectionTitle: "navigation.community",
                    introduction: "navigation.community.introduction",
                    accessPolicies: {
                        features: ['public-discussion']
                    }
                }
            },
            {
                path: "public-discussions",
                name: "public-discussions",
                component: () =>
                    import(
              /* webpackChunkName: "community" */ "@/views/Community/PublicDiscussions.vue"
                    ),
                meta: {
                    title: "navigation.community",
                    sectionTitle: "navigation.community",
                    introduction: "navigation.community.introduction",
                    accessPolicies: {
                        features: ['public-discussion']
                    },
                    displayHeadSection: true
                }
            },
            {
                path: "private-messages",
                name: "private-messages",
                component: () =>
                    import(
              /* webpackChunkName: "community" */ "@/views/Community/PrivateMessages.vue"
                    ),
                meta: {
                    title: "navigation.community",
                    sectionTitle: "navigation.community",
                    introduction: "navigation.community.introduction",
                    accessPolicies: {
                        features: ['neighbours']
                    },
                    displayHeadSection: true
                }
            },
            {
                path: "private-messages/new",
                name: "private-messages/new",
                introduction: "navigation.community.introduction",
                component: () =>
                    import(
              /* webpackChunkName: "community" */ "@/views/Community/NewConversation.vue"
                    ),
                meta: {
                    title: "navigation.community",
                    sectionTitle: "navigation.community",
                    introduction: "navigation.community.introduction",
                    accessPolicies: {
                        features: ['neighbours']
                    }
                }
            },
            {
                path: "private-messages/:id",
                name: "private-messages/:id",
                component: () =>
                    import(
              /* webpackChunkName: "community" */ "@/views/Community/Conversation.vue"
                    ),
                meta: {
                    title: "navigation.community",
                    sectionTitle: "navigation.community",
                    introduction: "navigation.community.introduction",
                    accessPolicies: {
                        features: ['neighbours']
                    }
                }
            },
            {
                path: "my-neighbours",
                name: "my-neighbours",
                component: () =>
                    import(
              /* webpackChunkName: "community" */ "@/views/Community/MyNeighbours.vue"
                    ),
                meta: {
                    title: "navigation.community",
                    sectionTitle: "navigation.community",
                    introduction: "navigation.community.introduction",
                    accessPolicies: {
                        features: ['neighbours']
                    }
                }
            },
            {
                path: "area-discussions/:id",
                name: "area-discussions/:id",
                component: () =>
                    import(
              /* webpackChunkName: "community" */ "@/views/Community/PublicMessage.vue"
                    ),
                meta: {
                    title: "navigation.community",
                    sectionTitle: "navigation.community",
                    introduction: "navigation.community.introduction",
                    areaDiscussion: true,
                    accessPolicies: {
                        features: ['area-discussion']
                    }
                }
            },
            {
                path: "area-discussions",
                name: "area-discussions",
                component: () =>
                    import(
              /* webpackChunkName: "community" */ "@/views/Community/AreaDiscussions.vue"
                    ),
                meta: {
                    title: "navigation.community",
                    sectionTitle: "navigation.community",
                    introduction: "navigation.community.introduction",
                    accessPolicies: {
                        features: ['area-discussion']
                    },
                    displayHeadSection: true
                }
            },
        ]
    }
];