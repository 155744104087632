<template>
  <div
    class="profile-menu"
    v-closable="{
            exclude: ['profile-menu__toggle'],
            handler: 'handleOutside'
        }"
  >
    <button class="profile-menu__toggle" @click="$emit('toggle')">
      <i class="far fa-user"></i>
      <span class="sr-only">{{$t('profile-menu.open-menu')}}</span>
    </button>

    <transition name="slidedown">
      <div class="profile-menu__dropdown bg--primary color--white site-header--fixed--top-by-height-with-top-margin" v-if="open">
        <div class="profile-menu__container current-user">
            <div class="current-user__label">{{$t('profile-menu.logged-in-as')}}</div>
            <div class="current-user__name">{{ getName }}</div>
        </div>
        <div class="profile-menu__container">
            <ul class="user-menu">
                <li>
                    <router-link to="/profile">
                        <span @click="$emit('close')">
                            <i class="far fa-cog"></i>
                            {{$t('navigation.mysettings')}}
                        </span>
                    </router-link>
                </li>
                <li>
                    <a href="/profile" @click="logout">
                        <i class="far fa-sign-out"></i>
                        {{$t('mysettings.common.logout')}}
                    </a>
                    <iframe src="" id="logout" style="display:none" />
                </li>
            </ul>
        </div>
      </div>
    </transition>
    <div
      v-if="open"
      @click="$emit('close')"
      class="site-overlay site-header--fixed--top-by-height"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "ProfileMenu",
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },
    computed: {
    ...mapGetters([
      "getCurrentUser",
      "getLogOutUrl"
    ]),
    getName() {
        if(!this.getCurrentUser)   
            return "";
        return this.getCurrentUser.firstName + " " + this.getCurrentUser.lastName;
    }
  },
  methods: {
    logout: function(event) {
        if(event)
            event.preventDefault();
        this.$emit('close');
        let logoutUrl = this.getLogOutUrl;
        this.$store.dispatch("logout").then(() => {
            window.location.href = logoutUrl
        }).catch(err => {
            window.location.href = logoutUrl
        });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
.profile-menu {
    &__dropdown {
        position: absolute;
        width: 100%;
        right: 1rem;
        max-width: 290px;
        width: 100%;
        z-index: 10;
        color: #fff;
        font-size: .9rem;

        &.is-visible {
            box-shadow: 0px;
        }
        &.is-visible {
            display: block;
        }

        &:before {
            content: "";
            top: -8px;
            left: auto;
            right: 1rem;
            z-index: 0;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 1rem 1rem 1rem;
            border-color: transparent transparent $greyBlueMidDark transparent;
            transition: all 0.2s ease-in-out 0.5s;
        }
    }
    &__container {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid rgba(255,255,255,.15);
        &:last-child {
            border-bottom: 0;
        }
    }
    .current-user {
        &__label {
            font-size: .85rem;
            opacity: .7;
        }
        &__name {
            margin-top: .3rem;
            font-family: $fontPrimaryBold;
        }
    }
    .user-menu {
        padding: 0;
        margin: 0;
        list-style: none;
        li a {
            display: block;
            padding: .4rem 0;
            i {
                font-size: 1.1rem;
                position: relative;
                top: .1rem;
                margin-right: .5rem;
            }
        }
    }
}

</style>