import { KenticoDeliveryClient, includeJwt } from "./KenticoClient";
import { AzureFunctionClient } from "@/services/AzureFunctionClient";
import BaseService from "./BaseService";
import store from "@/store/index";
import dayjs from "dayjs";

export default class KenticoDeliveryApiService extends BaseService {
  constructor() {
    super();
    this.contentType = "article";
    this.targetByDefaultBuildingTaxonomy = false; //Use default buidling taxonomy query

    this.baseUrl =
      process.env.VUE_APP_API_KENTICO_DELIVERY_BASE_URL +
      "/" +
      process.env.VUE_APP_KENTICO_PROJECT_ID;

    this.filters = [];
    includeJwt();
  }

  setContentType(value) {
    this.contentType = value;
  }
  getArticleType() {
    return this.contentType;
  }

  /**
   *
   * @param {*} field
   * @param {*} value
   * @param {*} operator
   * @param {*} prefix
   */
  addFilter(field = "", value = null, operator = "=", prefix = "elements") {
    this.filters.push({
      field,
      value,
      operator,
      prefix
    });
  }

  getFilters() {
    return this.filters;
  }

  resetFilters() {
    this.filters = [];
  }

  _buildFilterQuery() {
    let ret = "";
    this.filters.map((filter, key) => {
      let operator =
        filter.operator == "=" ? filter.operator : "[" + filter.operator + "]=";
      ret += `&${filter.prefix}.${filter.field}${operator}${filter.value}`;
    });

    return ret;
  }

  /**
   * @param Object params
   * @returns Promise
   */
  fetchList(params = {}, elements = []) {

    //set defaults
    if (!params.language) params.language = this.getLanguage();
    if (!params.limit) params.limit = 20;
    if (!params.skip) params.skip = 0;
    if (!params.sticky) params.sticky = false;
    if (!params.order && !params.order != 'none') {
      params.order = "publish_date";
    }
    if (!params.orderDirection) {
      params.orderDirection = "desc";
    }

    let queryString = `${this.baseUrl}/items?language=${params.language}`;

    if (params.contentType) {
      if(Array.isArray(params.contentType))
        queryString += `&system.type=${params.contentType.join(',')}`;
      else
        queryString += `&system.type=${params.contentType}`;
    }
    else if (this.contentType) {
      queryString += `&system.type=${this.contentType}`;
    }

    //article type filtering
    if (params.articleTypes) {
      if(Array.isArray(params.articleTypes))
        queryString += `&elements.articletypes[any]=${params.articleTypes.toString()}`;
      else
        queryString += `&elements.articletypes[contains]=${params.articleTypes}`;
    }

    //building taxonomy filtering
    if (params.buildingTaxonomies) {
      queryString += `&elements.buildingtaxonomy[any]=${params.buildingTaxonomies}`;
    } else if (this.targetByDefaultBuildingTaxonomy) {
      queryString += `&elements.buildingtaxonomy[any]=${store.getters.getBuildingTaxonomyFilters.toString()}`;
    }

    if (params.sticky) {
      params.sticky_expiration = dayjs().format("YYYY-MM-DD");
      queryString += `&elements.sticky_news[contains]=true&elements.expiration_date_of_the_sticky[gte]=${params.sticky_expiration}`;
    }

    //@todo remove customStrignQuery at some point
    if (params.customStringQuery) {
      queryString += "&" + params.customStringQuery;
    } else if (this.filters.length) {
      queryString += this._buildFilterQuery();
    }

    //Limit queyry result set elements
    if (elements.length) {
      let elemQuery = "&elements=";
      elements.map((elem, index) => {
        elemQuery += elem;
        if (index + 1 < elements.length) elemQuery += ",";
      });
      queryString += elemQuery;
    }

    if (params.publishDateFrom || params.publishDateTo) {
      if (params.publishDateFrom && !params.publishDateTo) {
        queryString +=
          "&elements.publish_date[gte]=" +
          dayjs(params.publishDateFrom).format("YYYY-MM-DD");
      } else if (!params.publishDateFrom && params.publishDateTo) {
        queryString +=
          "&elements.publish_date[lte]=" +
          dayjs(params.publishDateTo).format("YYYY-MM-DD");
      } else if (params.publishDateFrom && params.publishDateTo) {
        queryString +=
          "&elements.publish_date[range]=" +
          dayjs(params.publishDateFrom).format("YYYY-MM-DD") +
          "," +
          dayjs(params.publishDateTo).format("YYYY-MM-DD");
      }
    }

    queryString += `&limit=${params.limit}`;

    if(params.partner_external_id)
      queryString += `&elements.partner_external_id=${params.partner_external_id}`;

    if (params.skip) {
      queryString += `&skip=${params.skip}`;
    }

    if(params.order && params.order != 'none' && params.orderDirection)
      queryString += `&order=elements.${params.order}[${params.orderDirection}]`;

    return KenticoDeliveryClient.get(`${queryString}`);
  }

  /**
   * @param String id
   * @returns Promise
   */
  fetchSingle(id, params = {}) {
    if (!params.language) params.language = this.getLanguage();
    return KenticoDeliveryClient.get(
      `${this.baseUrl}/items/${id}?language=${params.language}`
    );
  }

   /**
   * @param String codename
   * @param String language
   * @returns Promise
   */
  fetchPublicPage(params = {}) {
    return AzureFunctionClient.get(
      `${process.env.VUE_APP_APIM_PUBLIC_API_URL}/getPublicPages/${params.language}/${params.codename}`
    );
  }

  fetchTaxonomies(params = {}) {
    let queryString = `${this.baseUrl}/taxonomies`;

    if (Array.isArray(params.codenames)) {
      queryString += "?system.codename[in]=" + params.codenames.toString();
    }

    return KenticoDeliveryClient.get(queryString);
  }

  fetchTaxonomy(codename = "") {
    return KenticoDeliveryClient.get(`${this.baseUrl}/taxonomies/${codename}`);
  }
}
