<template>
  <div class="hero-image site-header--fixed--top-by-height">
    <div class="hero-image__bg" v-if="useAsBackgroundImage">
      <div class="hero-image__bg__image" role="banner" :style="getCssStyles">
        <transition name="fade">
          <div class="hero-image__title" v-if="initialized">
            <div v-text="getPageName + ' —'"></div>
            <div v-text="title"></div>
            <div v-if="afterTitleText" v-text="afterTitleText"></div>
          </div>
        </transition>
      </div>
    </div>
    <img :src="src" :alt="title" v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "HeroImage",
    props: {
        src: {required: true},
        title: {type: String},
        useAsBackgroundImage: {type: Boolean, default: true}
    },
    data() {
        return {
            initialized: false,
            afterTitleText: ""
        }
    },
    computed: {
      ...mapGetters(['getPageName']),
      getCssStyles() {
        console.log("hero: ", this.src)
        let styles = `background-image: url(${this.src})`;
        return styles;
      }
    },
    created() {
      setTimeout(() => {
        this.initialized = true
      }, 500)
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/variables";
.hero-image {
  position: relative;
  max-width: 1460px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  &__bg {
    height: 200px;
    transition: all .25s ease-in;
    &__image {
      height: 100%;
      background-size: 140%;
      background-position: 60% 100%;
      transition: all .25s ease-in;
    }
    @media screen and (min-width: $breakpoint-s) {
      &__image {
        background-size: 120%;
      }
    }
    @media screen and (min-width: $breakpoint-sm) {
      height: 250px;
      &__image {
        background-size: 100%;
      }
      .hero-image__bg__image {
        background-size: cover;
        background-position: 0% 100%;
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      height: 300px;
    }
    @media screen and (min-width: $breakpoint-lg) {
      height: 400px;
      .hero-image__bg__image {
        background-position: 50% 85%;
      }
    }
    @media screen and (min-width: $breakpoint-xxl) {
      height: 450px;
      .hero-image__bg__image {
        background-position: 50% 78%;
      }
    }
  }
  &__title {
    position: absolute;
    font-family: $fontPrimaryLight;
    font-size: 1.2rem;
    padding-left: 1rem;
    color: $brandColorPrimary;
    text-align: left;
    top: 50%;
    transform: translateY(-50%);
    max-width: 65%;
    text-shadow: 1px 1px rgba(0,0,0,0.1);

    @media screen and (min-width: $breakpoint-s) {
      margin-left: 1rem;
      font-size: 1.3rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-left: 10%;
      font-size: 1.5rem;
      text-align: center;
    }

    @media screen and (min-width: $breakpoint-lg) {
      font-size: 2rem;
    }

    @media screen and (min-width: $breakpoint-xl) {
      margin-left: 15%;
    }
  }
}
</style>