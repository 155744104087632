<template>
  <article :class="getCssClasses">
    <router-link :to="item.linkUrl">
      <div class="timeline-item__body bg--transparent">
        <div class="step-heart"><img :src="heartIcon" alt="" aria-hidden="true" /></div>
        <div class="timeline-item__title">
          <h1>{{item.title}}</h1>
        </div>
        <div class="timeline-item__description" v-if="item.introduction" v-html="this.$filters.nl2br(item.introduction)"></div>
        <div class="timeline-item__description" v-else-if="item.description" v-html="this.$filters.nl2br(item.description)"></div>
      </div>
    </router-link>
  </article>
</template>

<script>
import TimelineItemModel from "@/models/TimelineItemModel";
import { printDatesBetween } from "@/utils/DateHelper";
import YitIcon from "@/components/common/YitIcon";
import {isPastDate} from "@/utils/DateHelper";
import svgBg from "@/assets/images/timeline-dash-left-dark.svg";
import heartIcon from "@/assets/images/heart.svg"
import { mapMutations } from 'vuex';

export default {
  name: "TimelineItemDefault",
  props: {
    item: {type: TimelineItemModel},
    showHeader: {type: Boolean, default: false},
    showFooter: {type: Boolean, default: false}
  },
  components: {
    YitIcon
  },
  data() {
    return {
      likeCount: 0,
      svgBg,
      heartIcon
    }
  },
  computed: {
    getCssClasses() {
      let classes = ['timeline-item', 'timeline-item--step', 'text-center', 'bg--transparent'];
      if(!isPastDate(this.item.date)) {
        classes.push('timeline-item--upcoming')
      }
      return classes;
    }
  },
  methods: {
    ...mapMutations(['setSelectedTimeLineItem']),
    printDatesBetween,
    refreshLikes(value) {
      if(Array.isArray(value))
        this.likeCount = value.length;
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";
.timeline-item--step {
  //height: 325px;
  background-color: transparent;
  border: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .fal, .fas, .far, .step-heart img {
    animation: zoomBounceSmall 2.5s ease-in .2s infinite;
  }
  .fal, .fas, .far {
    font-size: 2.7rem;
    color: $red;
  }
  &:after {
    display: none;
  }
}

.timeline-item {
  &__image-as-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    img {
      height: 325px;
      width: 169px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@keyframes zoomBounceSmall {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

</style>