import ApplicationSettingsService from "@/services/ApplicationSettingsService";

const api = new ApplicationSettingsService();

const getDefaultState = () => {
  return {
    settings: null
  };
};

const state = getDefaultState();

const getters = {
  getApplicationSettings: state => state.settings,
  getServiceRequestSettings: state =>
    state.settings ? state.settings.serviceRequests : null,
  getDocumentsSettings: state =>
    state.settings ? state.settings.documents : null,
  getAvailableDocumentCategories: state => {
    if(state.settings && state.settings.documents) {
      return state.settings.documents.documentCategories ? state.settings.documents.documentCategories : [];
    }

    return [];
  }  
};

const actions = {
  fetchApplicationSettings({ commit }) {
    return api
      .fetch()
      .then(response => {
        commit("setApplicationSettings", response.data);
      })
      .catch(err => {
        console.log("Fetching application settings failed: ", err);
      });
  }
};

const mutations = {
  setApplicationSettings: (state, res) => (state.settings = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
