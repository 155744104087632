import LiferayClient from "./LiferayClient";
import { Store } from "vuex";

export default class LeanheatService {
  /**
   *
   * @param {obj} params
   *
   */
  fetchLeanheatData(params) {
    const urlParams = "/delegate/yithome-api/leanheat/apartments/aggregateData?apt_id="+ params.aptId + "&data=" + params.seriesId + "&start_time=" + params.start + "&end_time=" + params.end;
    return LiferayClient.get(urlParams);
  }

  fetchAvailableData(aptId) {
    const urlParams = "/delegate/yithome-api/leanheat/apartments/availableData?apt_id=" + aptId;
    return LiferayClient.get(urlParams);
  }

  fetchSiteMetadata() {
    const urlParams = "/delegate/yithome-api/leanheat/sites/metadata";
    return LiferayClient.get(urlParams);
  }

  fetchSiteApartmentMetadata(siteName) {
    const urlParams = "/delegate/yithome-api/leanheat/sites/aptMetadata/" + siteName;
    return LiferayClient.get(urlParams);
  }

}
