import dayjs from "dayjs";
import { i18n } from "@/plugins/i18n";
import filters from "@/filters";

/**
 * Get date
 * @param {*} date
 */
const getToday = (date) => {
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  return today;
};

/**
 * Date is today
 * @param {*} date
 */
const isToday = (date) => {
  let today = new Date();
  let diffDate = new Date(date);
  if (today.toDateString() == diffDate.toDateString()) return true;
  else return false;
};

/**
 * Is date before given date range
 * @param {*} date
 * @param {*} before
 */
const isBefore = (date, amount, variable) => {
  let today = dayjs(new Date());
  let compare = today.add(amount, variable);
  let diffDate = dayjs(new Date(date));

  if (diffDate.isBefore(compare)) return true;
  else return false;
};

const isTodayBetween = (date1, date2) => {
  let today = new Date();
  let diffDate1 = new Date(date1);
  let diffDate2 = new Date(date2);

  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  diffDate1.setHours(23);
  diffDate1.setMinutes(59);
  diffDate1.setSeconds(59);
  diffDate2.setHours(23);
  diffDate2.setMinutes(59);
  diffDate2.setSeconds(59);

  return today > diffDate1 && today < diffDate2;
};

/**
 * Date is in past
 * @param {*} date
 */
const isPastDate = (date) => {
  let today = dayjs(new Date());
  let diffDate = dayjs(new Date(date));
  if (
    today.valueOf() > diffDate.valueOf() &&
    today.format("YYYY-MM-DD") !== diffDate.format("YYYY-MM-DD")
  )
    return true;
  else return false;
};

/**
 * Date is in past
 * @param {*} date
 */
const isPast = (date) => {
  let today = dayjs(new Date());
  let diffDate = dayjs(new Date(date));
  if (today.valueOf() > diffDate.valueOf()) return true;
  else return false;
};

/**
 * Print general date presentation between to timestamps
 * @param {*} date start
 * @param {*} date end
 * @param {*} format default/short
 */
const printDatesBetween = (startTime, endTime, format = "default") => {
  let start = new Date(startTime);
  let end = new Date(endTime);
  let locale = i18n.locale;

  let ret = "";

  let monthFormat = "MMM";
  let dayFormat = "D";
  if (locale == "fi") {
    monthFormat = "MMMM[ta]";
    dayFormat = "D. ";
  }

  if (start) {
    //single day
    if (
      start.getMonth() == end.getMonth() &&
      start.getDate() == end.getDate()
    ) {
      //just start time defined
      if (!end) {
        if (format == "short") filters.date_format_ordinal(start);
        else filters.date_format_ordinal(start);
      }

      if (format == "short")
        ret = filters.date_format_short_ordinal(start);
      else ret = filters.date_format_ordinal(start);

      //if start and end time are same, hide the time
      if (start.getHours() == end.getHours()) {
        return ret;
      } else {
        ret +=
          " " +
          dayjs(start).format("HH:mm") +
          " - " +
          dayjs(end).format("HH:mm");
      }

      return ret;
    } else {
      //multi day

      //short date
      if (format == "short") {
        //Only dates
        if (start.getMonth() == end.getMonth()) {
          ret = dayjs(start).format("D.-");
          ret += dayjs(end)
            .locale(locale)
            .format(`${dayFormat} ${monthFormat} YYYY`);
        } else {
          ret =
            dayjs(start).locale(locale).format(`${dayFormat} ${monthFormat}`) +
            " - ";
          ret += dayjs(end)
            .locale(locale)
            .format(`${dayFormat} ${monthFormat} YYYY`);
        }
      } else {
        //Default date

        //Hours
        if (start.getHours() > 0 && end.getHours() > 0) {
          ret = filters.date_format_no_year(start);
          ret += dayjs(start).format(" HH:mm") + " - ";

          ret += filters.date_format(end);
          ret += dayjs(end).format(" HH:mm");
        } else {
          //Only dates
          if (start.getMonth() == end.getMonth()) {
            ret = dayjs(start).format("D.-");
            ret += dayjs(end)
              .locale(locale)
              .format(`${dayFormat} ${monthFormat} YYYY`);
          } else {
            ret =
              dayjs(start)
                .locale(locale)
                .format(`${dayFormat} ${monthFormat}`) + " - ";
            ret += dayjs(end)
              .locale(locale)
              .format(`${dayFormat} ${monthFormat} YYYY`);
          }
        }
      }

      return ret;
    }
  }
  return ret;
};

const isFutureDate = (value) => {
  let today = new Date();
  return new Date(value) >= today;
};

const sortArrayOfObjectsByDate = (items, property, direction = "asc") => {
  if (direction == "asc") {
    return items.sort((a, b) => {
      return new Date(a[property]) - new Date(b[property]);
    });
  } else {
    return items.sort((a, b) => {
      return new Date(b[property]) - new Date(a[property]);
    });
  }
};

function ordinal(number) {
  let locale = i18n.locale;

  if (locale != "en") return number;

  switch (parseInt(number)) {
    case 1:
    case 21:
      return number + "st of ";
      break;
    case 2:
    case 22:
      return number + "nd of ";
      break;
    case 3:
    case 23:
      return number + "rd of ";
      break;
    default:
      return number + "th of ";
  }
}

const getDateOfISOWeek = (w, y) => {
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
};

export {
  getToday,
  isToday,
  isPastDate,
  printDatesBetween,
  isTodayBetween,
  isBefore,
  isFutureDate,
  sortArrayOfObjectsByDate,
  ordinal,
  getDateOfISOWeek,
  isPast,
};
