export default [
  {
    path: "/admin/help",
    name: "/admin/help",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Help/Main.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/help/update/:id",
    name: "/admin/help/update/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Help/SingleUpdate.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/help/faq/:id",
    name: "/admin/help/faq/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Help/SingleFaq.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  }
];