// models
import * as rax from 'retry-axios';
import axios from "axios";
import store from "@/store";
import {TokenService} from "./TokenService";

const tokenInterceptor = async (config) => {
  const isLoggedIn = store.getters.isLogged;
  let token = TokenService.getToken('AUTH', 'b2c');
  //Listen if token is expired already

  if (isLoggedIn) {
    let tokenExpiry = TokenService.getToken('EXPIRY', 'b2c');
    let now = new Date().getTime() / 1000;

    console.log("Axios: Token expires in: ", new Date(tokenExpiry * 1000).toString());

    //no token at all
    //if (!token && !store.getters.getAccessToken) {
    if (!token) {
      console.log("Axios: No token found.");
      store.commit("setIsTokenExpired", true);
    }

    //token is expired
    if (tokenExpiry > 0 && now > tokenExpiry && !store.getters.getIsTokenExpired) {
      console.log("Axios: Token expired");
      store.commit("setIsTokenExpired", true);
      return;
    }

    //15 minutes before token is expired -> attempt to refresh token
    else if (tokenExpiry > 0 && now > tokenExpiry-900) {
      store.dispatch("refreshAccessToken");
    }
  }
  return config;
}

let deliveryConfig = {
  "Content-Type": "application/json"
};
if(process.env.VUE_APP_USE_APIM_GATEWAY == "true") {
  deliveryConfig = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key":
      process.env.VUE_APP_APIM_DELIVERY_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": "true"
  };
}

const KenticoDeliveryClient = axios.create({
  baseURL:
    process.env.VUE_APP_API_KENTICO_DELIVER_BASE_URL +
    "/" +
    process.env.VUE_APP_KENTICO_PROJECT_ID,
  timeout: 10000,
  headers: deliveryConfig
});

let managementConfig = {
  "Content-Type": "application/json",
  "Authorization": "Bearer " + process.env.VUE_APP_API_KENTICO_KEY
};
if(process.env.VUE_APP_USE_APIM_GATEWAY == "true") {
  managementConfig = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key":
      process.env.VUE_APP_APIM_MANAGEMENT_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": "true"
  };
}

const KenticoManagementClient = axios.create({
  baseURL:
    process.env.VUE_APP_API_KENTICO_MANAGE_V2_BASE_URL +
    "/" +
    process.env.VUE_APP_KENTICO_PROJECT_ID,
  timeout: 30000,
  headers: managementConfig
});


let previewConfig = {
  "Content-Type": "application/json",
  "Authorization": "Bearer " + process.env.VUE_APP_KENTICO_PREVIEW_API_KEY
};
if(process.env.VUE_APP_USE_APIM_GATEWAY == "true") {
  previewConfig = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key":
      process.env.VUE_APP_APIM_MANAGEMENT_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": "true"
  };
}

const KenticoPreviewClient = axios.create({
  baseURL:
    process.env.VUE_APP_KENTICO_PREVIEW_API_BASE_URL +
    "/" +
    process.env.VUE_APP_KENTICO_PROJECT_ID,
  timeout: 10000,
  headers: previewConfig
});

/*const client = new ContentManagementClient({
  projectId: process.env.VUE_APP_KENTICO_PROJECT_ID,
  apiKey: process.env.VUE_APP_API_KENTICO_KEY
});*/

const retrieveJwtForClients = () => {
  let token = store.getters.getAccessToken;
  if(!store.getters.isStateB2C && store.getters.getAuthToken) {
    token = store.getters.getAuthToken;
  }
  return token;
}

//Add jwt token to services that needs it
const includeJwt = () => {
  if(process.env.VUE_APP_USE_APIM_GATEWAY != "true") {
    return false;
  }
  
  KenticoDeliveryClient.interceptors.request.use(function (config) {
    let token = retrieveJwtForClients();
    if (token) {
      config.headers.Authorization =
        "Bearer " + token;
      config.headers['Ocp-Apim-Issuer'] = store.getters.getTokenIssuer;
    }

    return config;
  });

  KenticoManagementClient.interceptors.request.use(function (config) {
    let token = retrieveJwtForClients();
    if (token) {
      config.headers.Authorization =
        "Bearer " + token;
      config.headers['Ocp-Apim-Issuer'] = store.getters.getTokenIssuer;
    }
    return config;
  });

  KenticoPreviewClient.interceptors.request.use(function (config) {
    let token = retrieveJwtForClients();
    if (token) {
      config.headers.Authorization =
        "Bearer " + token;
      config.headers['Ocp-Apim-Issuer'] = store.getters.getTokenIssuer;
    }

    return config;
  });

  KenticoManagementClient.interceptors.request.use(tokenInterceptor);
  KenticoPreviewClient.interceptors.request.use(tokenInterceptor);
};
KenticoManagementClient.defaults.raxConfig = {
  instance: KenticoManagementClient,
  retry: 10,
   // Retry twice on errors that don't return a response (ENOTFOUND, ETIMEDOUT, etc).
  noResponseRetries: 2,
   // Milliseconds to delay at first.  Defaults to 100.
  retryDelay: 1000,
   // HTTP methods to automatically retry.  Defaults to:
  // ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT']
  httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],
   // The response status codes to retry.  Supports a double
  // array with a list of ranges.  Defaults to:
  // [[100, 199], [429, 429], [500, 599]]
  statusCodesToRetry: [[409, 429]],
   // You can set the backoff type.
  // options are 'exponential' (default), 'static' or 'linear'
  backoffType: 'static',

  // You can detect when a retry is happening, and figure out how many
  // retry attempts have been made
  onRetryAttempt: err => {
    const cfg = rax.getConfig(err);
    console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
  }
};

rax.attach(KenticoManagementClient);

export {
  KenticoDeliveryClient,
  KenticoManagementClient,
  KenticoPreviewClient,
  includeJwt
};
