import dayjs from "dayjs";
import "dayjs/locale/cs";
import "dayjs/locale/et";
import "dayjs/locale/fi";
import "dayjs/locale/lt";
import "dayjs/locale/lv";
import "dayjs/locale/pl";
import "dayjs/locale/ru";
import "dayjs/locale/sk";
import { i18n } from "@/plugins/i18n";
import striptags from "striptags";
import {ordinal} from "@/utils/DateHelper";

/**
 * Date filters
 */
const date_format = function(time, locale = null) {
  if (!locale) locale = i18n.global.locale;
  let date = new Date(time);

  if(locale == 'fi')
    return dayjs(date)
    .locale(locale)
    .format("D. MMMM[ta] YYYY");
  else
    return dayjs(date)
      .locale(locale)
      .format("D MMM YYYY");
};

const date_format_short = function(time, locale = null) {
  if (!locale) locale = i18n.global.locale;
  let date = new Date(time);

  if(locale == 'fi')
    return dayjs(date)
      .locale(locale)
      .format("D. MMMM[ta]");
  else
    return dayjs(date)
    .locale(locale)
    .format("D MMM");
}

const date_format_no_year = function(time, locale = null) {
  if (!locale) locale = i18n.global.locale;
  let date = new Date(time);

  if(locale == 'fi')
    return dayjs(date)
    .locale(locale)
    .format("D. MMMM[ta]");
  else
    return dayjs(date)
      .locale(locale)
      .format("D MMM");
}

const date_format_numbers = function(time, locale = null) {
  if (!locale) locale = i18n.global.locale;
  let date = time;
  if(!isNaN(new Date(time).valueOf())) {
    date = new Date(time);
  } else if( !isNaN(new Date(parseInt(time).valueOf()))) {
    date = new Date(parseInt(time));
  }

  return dayjs(date).format("DD.MM.YYYY");
}

const format_date = function(time, format = null, locale = null) {
  if (!locale) locale = i18n.global.locale;

  if (!format) return time;
  let date = new Date(time);

  return dayjs(date)
    .locale(locale)
    .format(format);
}

const date_time_format = function(time, locale = null) {
  if (!locale) locale = i18n.global.locale;

  let monthFormat = "MMM";
  if(locale == 'fi')
    monthFormat = "MMMM[ta]";

  let date = new Date(time);

  let dayjsObject = dayjs(date);
  if (locale == "en") {
    return (
      ordinal(dayjsObject.locale(locale).format("D")) +
      dayjsObject.locale(locale).format(`${monthFormat} YYYY HH.mm`)
    );
  } else {
    return (
      dayjsObject.locale(locale).format("D") +
      ". " +
      dayjsObject.locale(locale).format(`${monthFormat} YYYY HH.mm`)
    );
  }
}

const date_time_format_long = function(time, locale = null) {
  if (!locale) locale = i18n.global.locale;

  let date = new Date(time);

  let dayjsObject = dayjs(date);

  return (
    dayjsObject.locale(locale).format(`dddd D.M.YYYY HH.mm`)
  );
}

const date_time_format_simple = function(time, locale = null) {
  if (!locale) locale = i18n.global.locale;
  let date = new Date(time);
  let dayjsObject = dayjs(date);
  return dayjsObject.locale(locale).format("D.M.YYYY HH.mm");
}

const date_format_ordinal = function(time, locale = null) {
  if (!locale) locale = i18n.global.locale;
  
  let monthFormat = "MMM YYYY";
  if(locale == 'fi')
    monthFormat = "MMMM[ta] YYYY";
  
  let date = new Date(time);
  let dayjsObject = dayjs(date);

  if (locale == "en") {
    return (
      ordinal(dayjsObject.locale(locale).format("D")) +
      dayjsObject.locale(locale).format(`${monthFormat}`)
    );
  } else {
    return (
      dayjsObject.locale(locale).format("D") +
      ". " +
      dayjsObject.locale(locale).format(`${monthFormat}`)
    );
  }
}

const date_format_short_ordinal = function(time, locale = null) {
  if (!locale) locale = i18n.global.locale;
  let date = new Date(time);

  if(locale == 'fi')
    return (
      ordinal(dayjs(date).format('D')) + ". " +
      dayjs(date)
      .locale(locale)
      .format("MMMM[ta]")
      );
  else
    return (
      ordinal(dayjs(date).format('D')) + " " +
      dayjs(date)
      .locale(locale)
      .format("MMM")
    );
}

const date_daysleft = function(time) {
  let date = new Date(time);

  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  var today = new Date();

  if (date.getTime() < today.getTime()) return 0;

  var diffDays = Math.round(
    Math.abs((date.getTime() - today.getTime()) / oneDay)
  );

  return diffDays >= 0 ? diffDays : 0;
}


/**
 * Phone number
 */
const tel_link = function(value) {
  //let phoneRegEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (value) {
    let res = String(value).replace(/\s/g, "").replace(/[^\d+]/g, "");
    res = "tel:" + res;
    return res;
  }

  return value;
}

/**
 * Numbers
 */
const price = function(value) {
  return Number(value)
    .toFixed(2)
    .replace(".", ",");
}

const duration = function(value) {
  return String(value).replace(".", ",");
}

const filesize = function(value) {
  return Math.round(value / 1000) + "k";
}

const striphtml = function(value) {
  return striptags(value, []);
}

const truncate = function (value, length = 150, postfix = "...") {
  if (String(value).length < length)
    return value;
  return String(value).substring(0,length)+postfix;
}

const nl2br = function(value) {
  return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

const toLowerCase = function (value) {
    return value.toLowerCase('');
}

const zipcode = function(value) {
  if(typeof value === 'string' && value.length > 0) {
    if(value.length < 5) {
      switch(value.length) {
        case 1 :
          return '0000'+value;
        case 2 :
          return '000'+value;
        case 3 :
          return '00'+value;
        case 4 :
          return '0'+value;
      } 
    }
  }
  return value;
}

const formatRole = function (role) {
  if (!role)
    return role;
  if(role.startsWith("role")) {
    return i18n.global.t('user.admin.role.' + role) 
  } else {
    return i18n.global.t('user.admin.role.role' + role.charAt(0).toUpperCase() + role.slice(1)) 
  }
}

const month_and_year = function (date, locale = null) {
  if(!locale)
    locale = i18n.global.locale
  return dayjs(date).locale(locale).format('MMMM YYYY');
}

export default {
  date_format,
  date_format_short,
  date_format_no_year,
  date_format_numbers,
  format_date,
  date_time_format,
  date_time_format_long,
  date_time_format_simple,
  date_format_ordinal,
  date_format_short_ordinal,
  date_daysleft,
  tel_link,
  price,
  duration,
  filesize,
  striphtml,
  truncate,
  nl2br,
  zipcode,
  formatRole,
  toLowerCase,
  month_and_year
};