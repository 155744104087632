<template>
  <div class="notification-group">
    <div class="notification-group__header bg--grey-blue-mid-dark">
      {{ title }}
      <!--<button class="notification-group__close" @click="markNotificationsRead()">
                <i class="fal fa-times"></i>
            </button>  -->
    </div>
    <div class="notification-group__body bg--mid-grey">
      <div v-if="items && items.length" class="notification-group__scrollable">
        <div
          v-for="item in items"
          :key="item.id"
          class="notification-item"
          :class="{
            'notification-item__not-read':
              !item.isRead && typeof item.isRead !== 'undefined'
          }"
        >
          <router-link
            :to="item.url ? item.url : '#'"
            v-if="item.url"
          >
            <div class="notification-item__headline" v-if="item.headline" v-on:click="onItemClick(item)">
              {{ item.headline }}
            </div>
            <div class="notification-item__date" v-if="item.date">
              {{ $filters.date_format(item.date, $i18n.locale) }}
            </div>
            <div class="notification-item__title">{{ item.title }}</div>
          </router-link>
          <a
            :href="item.href ? item.href : '#'"
            @click="onItemClick(item, $event)"
            v-else
          >
            <div class="notification-item__headline" v-if="item.headline">
              {{ item.headline }}
            </div>
            <div class="notification-item__date" v-if="item.date">
              {{ $filters.date_format(item.date, $i18n.locale) }}
            </div>
            <div class="notification-item__title">{{ item.title }}</div>
          </a>
        </div>
      </div>
      <div v-else class="notification-group__no-results">
        <div class="notification-item">
          {{ $t("notificationpage.common.no.updates") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationService from "@/services/NotificationService";
import { getQueryVariable } from "@/utils/StringUtils";
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: "NotificationGroup",
  props: ["title", "items"],
  data() {
    return {
      api: new NotificationService()
    };
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getSelectedHousingCompany', "getApartments"])
  },
  methods: {
    ...mapActions(['selectHousingCompany']),
    ...mapMutations(['setApartment']),
    markNotificationsRead() {
      return;
    },
    async onItemClick(item, event) {
      if (event) event.preventDefault();
      
      let relativePath = "";
      if(item.href) {
        let domainCheckString = window.location.protocol + "//" + window.location.hostname;
        if(window.location.hostname == "localhost")
          domainCheckString = "http://localhost:8080";

        relativePath = item.href.replace(domainCheckString, "");
      }

      if(relativePath) {
        
        let hcId = getQueryVariable("_hc", item.href);
        let currentHcId = this.getSelectedHousingCompany && this.getSelectedHousingCompany.hcExt;
        console.log("clicked notification: ", item.href, hcId, currentHcId);
        if(hcId && hcId != currentHcId) {
          try {
            
            const hcs = this.getCurrentUser.hcs;
            const selectedHc = hcs.find(hc => hc.hcExt === hcId);
            if(selectedHc) {
              await this.selectHousingCompany(selectedHc);

              if(Array.isArray(selectedHc.apts) && selectedHc.apts.length) {

                let newApartment = null;
                let aptId = getQueryVariable("_apt", item.href);
                if(aptId)
                  newApartment = selectedHc.apts.find(apt => apt.aptext === aptId)
                
                if(!newApartment) {
                  newApartment = selectedHc.apts[0];
                }

                if(newApartment) {
                  this.setApartment(newApartment);
                }
              }
            }
          
          } catch(err) {
            console.error(err);
          }
        }

        item.isRead = true;
        
        try {
           this.api.markNotificationRead(item.id);
          } catch(error) {
            console.log(error);
          }
          this.$router.push(relativePath);

      } else {
         this.api
        .markNotificationRead(item.id)
        .then(res => {
          if (item.href) window.location.href = item.href;
        })
        .catch(err => {
          if (item.href) window.location.href = item.href;
        });
      }
      this.$emit("on-item-click", item);
     
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
.notification-group {
  z-index: 1;
  position: relative;
  &__scrollable {
    overflow-y: auto;
    height: 100%;
    max-height: 300px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @media screen and (min-width: $breakpoint-md) {
      max-height: 500px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    font-size: 0.95rem;
    padding: 1em 1rem;
  }
  &__body {
  }
  &__close {
    margin-left: auto;
    font-size: 1.5rem;
    color: #fff;
  }

  .notification-item {
    padding: 0.8rem 1rem 1rem 1rem;

    &__headline,
    &__date {
      font-size: 0.85rem;
      margin-bottom: 0.1rem;
    }
    &__title {
      font-family: $fontPrimaryBold;
    }
    &.notification-item__not-read {
      background-color: rgba(0, 0, 0, 0.1);
    }

    a {
      display: block;
    }
  }
  .notification-group__no-results {
    padding: 1rem 0;
    font-size: 0.85rem;
  }
}
</style>
