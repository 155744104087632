import { i18n } from "@/plugins/i18n"
import store from "@/store"
import { getFeature } from "@/features";

/**
 * Quick link modules available for user
 */
export const modules = [
  {
    moduleId: 'link-documents-v1',
    translationKey: 'navigation.documents',
    href: '/documents',
    icon: 'fa-folder-open',
    policies: {
      features: ['documents']
    }
  },
  {
    moduleId: 'link-documents-v2',
    translationKey: 'navigation.documents-and-instructions',
    href: '/my-apartment/documents',
    icon: 'fa-folder-open',
    policies: {
      features: ['documentsv2']
    }
  },
  {
    moduleId: 'link-warranty',
    translationKey: 'navigation.warranty',
    href: '/apartment/warranty',
    icon: 'fa-screwdriver',
    policies: {
      features: ['warranty']
    }
  },
  {
    moduleId: 'link-consumption',
    translationKey: 'navigation.consumption',
    href: '/my-apartment/consumption/water?initial=true',
    icon: 'fa-chart-bar',
    policies: {
      features: ['consumption']
    }
  },
  {
    moduleId: 'link-iot-movement-sensors',
    translationKey: 'navigation.smart-home.movement-sensors',
    href: '/my-apartment/movement-sensors',
    icon: 'fa-sensor-on',
    policies: {
      features: ['iot-movement-sensor']
    }
  },
  {
    moduleId: 'link-contact-book',
    translationKey: 'support.center.tab.contacts-book',
    href: '/support/contact-book',
    icon: 'fa-address-book'
  },
  {
    moduleId: 'link-service-requests',
    translationKey: 'navigation.supportcenter',
    href: '/support/service-requests?refresh=true',
    icon: 'fa-question-circle',
    policies: {
      features: ['service-requests']
    }
  },
  {
    moduleId: 'link-community',
    translationKey: 'navigation.community',
    href: '/community',
    icon: 'fa-users',
    policies: {
      features: ['public-discussion']
    }
  },
  {
    moduleId: 'link-service-shop',
    translationKey: 'navigation.service-shop',
    href: '/service-shop',
    icon: 'fa-shopping-cart',
    policies: {
      features: ['service-lounge']
    }
  },
  {
    moduleId: 'link-one4all-bookings',
    translationKey: 'navigation.one4all-bookings',
    href: '/book-common-spaces',
    icon: 'fa-calendar',
    policies: {
      features: ['one4all-bookings']
    }
  },
  {
    moduleId: 'link-material-selection',
    translationKey: 'navigation.material-selection',
    href: '/material-selection',
    icon: 'fa-wrench',
    policies: {
      features: ['material-selection']
    }
  }
]

export const defaultLivingPhase = () => {
  let moduleIds = ['link-contact-book']
  if (store.getters.isApartmentSelected) {
    if (getFeature('consumption')) moduleIds.push('link-consumption')
    if (getFeature('iot-movement-sensor')) moduleIds.push('link-iot-movement-sensors')
  }
  if(getFeature('documentsv2')) moduleIds.push('link-documents-v2')

  return modules.filter(m => moduleIds.includes(m.moduleId))
}
export const getDefaultConstructionPhase = () => {
  let moduleIds = ['link-contact-book']
  if(getFeature('material-selection')) moduleIds.push('link-material-selection')
  return modules.filter(m => moduleIds.includes(m.moduleId))
}

export const getDefaultQuickLinksForUser = (returnModuleIdata = false) => {
  let userModules = [];
  if (store.getters.getCurrentHcState === 'construction')
    userModules = getDefaultConstructionPhase()
  else
    userModules = defaultLivingPhase()
  
  if (returnModuleIdata)
    return buildModuleData(userModules);

  return userModules;
}

const _checkModulePolicies = (module) => {
  if(module.policies && module.policies.features && module.policies.features.length) {
    return module.policies.features.find(f => !getFeature(f)) ? false : true;
  } else 
    return true;
}

export const getModules = () => {
  return buildModuleData(modules).filter(_checkModulePolicies);
}

export const buildModuleData = (links = []) => {
  let moduleMap = {};
  modules.map(l => {
    moduleMap[l.moduleId] = l;
  });
  return links.map(link => {
    if (typeof link == 'string' && moduleMap[link])
      link = moduleMap[link]
    else if (typeof link == 'object' && link.moduleId && moduleMap[link.moduleId])
      link = { ...moduleMap[link.moduleId] }
    return link;
  })
} 

export default modules;