const deepCopyArray = array => {
  return JSON.parse(JSON.stringify(array));
};

/**
 * 
 * @param {Array} arr 
 * @param {Integer} n 
 */
function getRandom(arr, n) {
  return arr.sort(() => Math.random() - Math.random()).slice(0, n)
}

/**
 * 
 * @param {any[]} arr 
 * @param {string} key 
 * @returns  {any[]}
 */
function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

function isIterable(value) {
  if (value == null) {
    return false;
  }
  return typeof value[Symbol.iterator] === 'function';
}

export { deepCopyArray, getRandom, getUniqueListBy, isIterable };
