import axios from "axios";
import store from "@/store/index";
import {axiosNoAuthInterceptor} from "@/services/TokenService";

const AzureLiferayClient = axios.create({
  baseURL: process.env.VUE_APP_LIFERAY_API_BASE_URL,
  timeout: 20000,
  withCredentials: true, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "text/plain"
  }
});

const AzureLiferayClientWithMasterToken = axios.create({
  baseURL: process.env.VUE_APP_LIFERAY_API_BASE_URL,
  timeout: 20000,
  withCredentials: true, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "text/plain"
  }
});

const includeAzureLiferayJwt = (token = null) => {
  if(!token)
    token = store.getters.getAuthToken;
  AzureLiferayClient.interceptors.request.use(function(config) {
      config.headers.Authorization =
        "Bearer " + token
    return config;
  });
  //AzureLiferayClient.interceptors.response.use(response => response, axiosNoAuthInterceptor);
};

const includeLiferayMasterJwt = (token = null) => {
  if(!token)
    token = store.getters.getAccessToken;
  AzureLiferayClientWithMasterToken.interceptors.request.use(function(config) {
      config.headers.Authorization =
        "Bearer " + token
    return config;
  });
  //AzureLiferayClientWithMasterToken.interceptors.response.use(response => response, axiosNoAuthInterceptor);
};



//export default AzureLiferayClient;
export {AzureLiferayClient, AzureLiferayClientWithMasterToken, includeLiferayMasterJwt, includeAzureLiferayJwt}
