<template>
  <div class="comment-form">
    <form @submit="onSubmit" @keydown="onKeyDown">
      <!--<div class="alert alert--error" v-if="errorMessage" v-text="errorMessage"></div>
      <div class="alert alert--success" v-if="successMessage" v-text="successMessage"></div>-->
      <Toast type="success" ref="succcessMessage">{{$t('community.commentAdded')}}</Toast>
      <Toast type="error" ref="errorMessage">{{errorMessage}}</Toast>

      <CommunityMessageForm>
        <FormInput type="textarea" :placeholder="$t('common.addComment')" name="message" rows="10" v-model="form.body" ref="textareaStyled" />
        <template v-slot:footer v-if="canAttachImages">
          <AttachImages @on-images-selected="onImagesSelected" :loading="uploadingImages" />
        </template>
      </CommunityMessageForm>
      <template v-if="canAttachImages">
        <CommunityImageGrid :images="form.images" :canRemove="true" @on-remove="onImageRemove" />
      </template>
      <SignatureSelect :targetMessage="form.body" @on-select="onSignatureSelect" :html="false" />
      <div class="button-group text-center">
        <Button type="submit" :loading="sending">{{$t('comments.add.comment')}}</Button>
        <Button type="button" mode="link" @click="$emit('on-cancel')" v-if="displayCancelButton">{{$t('common.cancel')}}</Button>
      </div>
    </form>
  </div>
</template>

<script>
import Form from "@/utils/FormUtil";
import FormInput from "@/components/common/Form/Input";
import CommentService from "@/services/CommentService";
import Toast from "@/components/common/Toast";
import { mapGetters } from "vuex";
import CommunityMessageForm from "@/components/Community/CommunityMessageForm";
import SignatureSelect from "@/components/Profile/Signature/Select";
import AttachImages from "@/components/Community/AttachImages";
import CommunityImageGrid from "@/components/Community/ImageGrid";
import AzureDocumentService from "@/services/AzureDocumentService";
import { checkUploadedImage } from "@/utils/FileUtil";
import { isAxiosError } from 'axios'
import settings from "@/globals";

export default {
  name: "NewCommentForm",
  props: {
    referenceId: {type: String},
    parent: {},
    commentType: {type: String},
    displayCancelButton: {type: Boolean, default: false}
  },
  components: { FormInput, Toast, CommunityMessageForm, SignatureSelect, AttachImages, CommunityImageGrid },
  data() {
    return {
      sending: false,
      canAttachImages: true,
      documentApi: new AzureDocumentService(),
      uploadingImages: false,
      form: new Form(
        {
          body: "",
          images: []
        },
        {
          body: { required: true }
        }
      ),
      recipients: [],
      errorMessage: "",
      successMessage: "",
      api: new CommentService(),
      blobStorageTempFolder: settings.community.attachmentsTempFolder
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedApartment",
      "getUserDetails",
      "getSelectedHousingCompany",
      "isUserYit",
      "getCurrentUser"
    ]),
    showRecipientForm: function() {
      return this.recipients.length == 0;
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (this.form.validator.validate()) {
        this.form.saveLocalCopy();
        this.sending = true;
        
        let postData = {
          body: this.form.body,
          title: "",
          firstName: this.getUserDetails.firstName,
          type: this.commentType,
          projectId: this.getSelectedHousingCompany.hcExt,
          images: this.form.images
        };

        if(this.parent)
          postData.parent = this.parent;
        else if (this.referenceId)
          postData.refId = this.referenceId;
        if(this.getSelectedApartment && this.getSelectedApartment.aptext) {
          postData.apartmentId = this.getSelectedApartment.aptext;
        }

        this.api
          .newComment(postData)
          .then(res => {
            this.form.removeLocalCopy();
            this.$emit("response-posted");
            this.form.body = "";
            this.form.images = [];
            this.$refs.textareaStyled.resetContentEditableField();
            this.sending = false;
            this.$refs.succcessMessage.show();
          })
          .catch(err => {
            console.log(err);
            if (isAxiosError(err)) {
              this.errorMessage = err.response.data.message;
            }  else {
              this.errorMessage = err;
            }
            this.sending = false;
            this.$refs.errorMessage.show();
          });
        
        setTimeout(() => {}, 2000);
      } else {
        this.errorMessage =
          "Error on validating the form. Please check you have entered value on each required field.";
      }
    },
    onImagesSelected(e) {
      let files = Array.from(e.target.files);
      let createDate = new Date();

      if (files.length) {
        this.uploadingImages = true;
        files.map(file => {
          let isValid = checkUploadedImage(file);
          if(!isValid.error) {
            this.documentApi
              .addDocument({file, folder: this.blobStorageTempFolder})
              .then(response => {
                this.uploadingImages = false;
                if(response.data && response.data.file)
                  this.form.images.push(response.data.file);
              })
              .catch(err => {
                this.uploadingImages = false;
                console.log("Posting attachment failed: ", err);
                this.showErrorMessage(this.$t('common.messages.general-image-validation-failed-message'));
              });
          } else {
            this.showErrorMessage(this.$t('common.messages.general-image-validation-failed-message'));
            this.uploadingImages = false;
          }
        });
      }
      e.target.value = "";
    },
    onImageRemove(image = null) {
      if(!image)
        return;
      //remove image first from array
      this.form.images = this.form.images.filter(img => {
        if(img.name !== image.name)
          return img;
      });

      //then actually send api call to backend too
      this.documentApi.deleteDocument(image._id);
    },
    onKeyDown(e) {
      this.errorMessage = "";
      this.form.validator.clear(e.target.name);
    },
    onSignatureSelect(newValue) {
      this.form.body = newValue;
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.$refs.errorMessage.show();
    },
  },
  mounted() {
    this.form.getLocalCopy(null, false);
  }
};
</script>

<style lang="scss" scoped>
.comment-form {
  padding-bottom: 2rem;
}
</style>
