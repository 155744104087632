//import CampaignService from "@/services/CampaignService";
import {isCampaignVisible} from "@/utils/CampaignUtil";
import CampaignModel from "@/models/Campaign";
import store from "@/store";
import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import dayjs from "dayjs";

//const api = new CampaignService();

const kenticoApi = new KenticoDeliveryApiService();
kenticoApi.targetByDefaultBuildingTaxonomy = true; //target default by building taxonomy
kenticoApi.setContentType("campaign");

const getDefaultState = () => {
  return {
    campaigns: [],
    currentCampaign: [],
    partnerArticles: [],
    partnerCampaigns: [],
    currentKenticoAdvertisement: null,
    campaignStatuses: {
      draft: "common.draft",
      published: "common.published"
    },
  };
};

const state = getDefaultState();

const getters = {
  getCampaigns: state => state.campaigns,
  getCurrentCampaignById: state => campaignId => {
    let campaign = state.campaigns.find(
      campaigns => campaigns.campaignId == campaignId
    );

    if (campaign && campaign.extendedContent) {
      campaign.extendedContent = campaign.extendedContent.replace(
        /&amp;/g,
        "&"
      );
    }

    return campaign;
  },
  getCurrentPartnerContentById: state => partnerId => {
    return state.partnerArticles.find(
      partnerArticles => partnerArticles.partnerId == partnerId
    );
  },
  getPartnerCampaigns: state => partnerId => {
    let campaigns = state.partnerCampaigns.filter(function(campaign) {
      return campaign.partnerGroupId == partnerId;
    });
    return campaigns;
  },
  getCurrentKenticoAdvertisement: state => state.currentKenticoAdvertisement,
  getCampaignStatuses: state => state.campaignStatuses
};

const actions = {
  /*fetchKenticoAdverisements({commit}, params = {}) {
    return new Promise(function(resolve, reject) {
      
      console.log("FETCHING CAMPAIGNS!");

      //fetch campaigns
      kenticoApi.fetchList(params).then(response => {
        console.log("Fetched campaigns: ", response.data, params);

        if (append) commit("appendCampaigns", response.data.items);
        else commit("setCampaigns", response.data.items);

        response.data.items = response.data.items.map(c => CampaignModel.buildFromKenticoModel(c))
        console.log("fetched campaigns ", );
        resolve(response.data.items);
      })
      .catch(err => {
        console.log("Fetching campaigns failed: ", err);
        reject(err);
      });

    });
  },*/
  async fetchCampaignsForHc({ commit }, params = {}) {

      let articles = [];

      let append = false;
      if(params && params.append)
        append = true;
      
      //fetch campaigns
      try {
        params = _buildParamsForCampaignQuery(params);
        params.limit = 99;
        let response = await kenticoApi.fetchList(params);
          console.log("Fetched campaigns: ", response.data, params);

          //filter not visible campaigns
          let items = response.data.items.filter(c => isCampaignVisible(c));

          //match kentico model to liferay one
          articles = items.map(c => CampaignModel.buildFromKenticoModel(c));

          console.log("fetched campaigns ", articles);
      } catch(err) {
        console.log("Fetching campaigns failed: ", err);
      }

      if (append) commit("appendCampaigns", articles);
      else commit("setCampaigns", articles);
  },
  async fetchCampaignsForPartner({ commit }, partnerId) {

    try {
      let params = _buildParamsForCampaignQuery();
      params.limit = 99;
      //params.customStringQuery = `elements.partner_external_id=${partnerId}`;
      kenticoApi.addFilter('partner_external_id', partnerId, '=');
      console.log("fetch kentico campaigns with params: ", params, kenticoApi.filters);
      let response = await kenticoApi.fetchList(params);
      if (response && response.data && response.data.items) {
        console.log("Fetched campaigns: ", response.data);
        kenticoApi.resetFilters();

        //filter not visible campaigns
        let items = response.data.items.filter(c => isCampaignVisible(c) && c.elements && c.elements.partner_external_id && c.elements.partner_external_id.value === partnerId);

        //match kentico model to liferay one
        items = items.map(c => CampaignModel.buildFromKenticoModel(c));

        console.log("fetched azure campaigns ", items);
        commit("setPartnerCampaigns", items);
        return items;

      } else {
        commit("setPartnerCampaigns", []);
        return [];
      }
            
    } catch(err) {
      console.log("could not fetch partner campaigns ", err);
      commit("setPartnerCampaigns", []);
    }
    
  },
  fetchCurrentKenticoAdvertisement({ commit }, id) {
    return new Promise((resolve, reject) => {
      kenticoApi
        .fetchSingle(id)
        .then(response => {
          if (
            response.data.item.elements.partner &&
            response.data.item.elements.partner.value.length
          )
            response.data.item.elements.partner.data =
              response.data.modular_content[
                response.data.item.elements.partner.value[0]
              ];

          let item = CampaignModel.buildFromKenticoModel(response.data.item);
            
          console.log("Fetched single campaign: ", item);
          commit("setCurrentKenticoAdvertisement", item);
          resolve(item);
        })
        .catch(err => {
          console.log("Fetching single campaign failed: ", err);
          reject(err);
        });
    });
  },

  fetchPartnerContentById({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      if (store.getters.isStateB2C) {
        return Promise.resolve();
      }

      api
        .fetchPartnerContentById(partnerId)
        .then(response => {
          response.data.partnerId = partnerId;
          commit("setPartnerArticles", response.data);
          resolve(response);
        })
        .catch(err => {
          console.log("Fetching partners failed: ", err);
          reject(err);
        });
    });
  },
  sendCampaignEngagementToAnalytics({ commit }, campaignItem) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "campaign_engagement",
        eventCategory: "Partner Ads",
        eventAction: "Clicks",
        eventLabel: campaignItem.title + " - " + campaignItem.partnerName
      });

      /*window.dataLayer.push({
        partnerCompany: campaignItem.partnerName
      });*/
    }
  }
};

const mutations = {
  setCampaigns: (state, res) => (state.campaigns = res),
  appendCampaigns: (state, res) => (state.campaigns.unshift(res)),
  setPartnerArticles: (state, res) => state.partnerArticles.unshift(res),
  setPartnerCampaigns: (state, res) => {
    state.partnerCampaigns = res;
  },
  setCurrentCampaignItem: (state, res) => state.currentCampaign.unshift(res),
  setCurrentKenticoAdvertisement: (state, res) => (state.currentKenticoAdvertisement = res),
};


/**
 * Build parameters for kentico campaign query
 */
const _buildParamsForCampaignQuery = (params = {}) => {
  /*let user = store.getters.getUserDetails;
  let userAgeGroups = store.getters.getAgeGroups;*/
  let start_time = dayjs(new Date()).add(1, 'day').format('YYYY-MM-DD');
  let end_time = dayjs(new Date()).format('YYYY-MM-DD');

  //first reset all possible filters
  kenticoApi.resetFilters();

  //Date filter
  kenticoApi.addFilter("start_time", start_time, "lt");
  kenticoApi.addFilter("end_time", end_time, "gte");

  //Age group filtering
  /*let birthday = dayjs(user.birthday);
  let age = dayjs().format("YYYY") - birthday.format("YYYY");
  let ageGroupTerms = [];
  if(Array.isArray(userAgeGroups) && userAgeGroups.length) {
    userAgeGroups.map(group => {
      if (group.terms.length) {
        //let's take the main level taxonomy always
        ageGroupTerms.push(group.codename);

        group.terms.map(ageGroup => {
          if (_compareAgeGroup(ageGroup.name, age))
            ageGroupTerms.push(ageGroup.codename);
        });
      } else {
        if (_compareAgeGroup(group.name, age)) ageGroupTerms.push(group.codename);
      }
    });
    kenticoApi.addFilter("age_group", ageGroupTerms.toString(), "any");
  }*/

  //Rooms in apartment filter
  if ( Array.isArray(store.getters.getSelectedHousingCompany.apts) && store.getters.getSelectedHousingCompany.apts.length ) {
    let roomTerms = ["all"];
    store.getters.getSelectedHousingCompany.apts.map(apt => {
      if (apt.rooms) {
        if (parseInt(apt.rooms) < 5) roomTerms.push("n" + apt.rooms);
        else roomTerms.push("n5plus");
      }
    });
    kenticoApi.addFilter("room_type", roomTerms.toString(), "any");
  }

  //Building type filter
  if (
    store.getters.getSelectedHousingCompany &&
    store.getters.getSelectedHousingCompany.buildingType
  ) {
    let buildingTypeTerms = ["all"];
    let kenticoBuildingTypeCodename = _mapLiferayBuildingTypeToKenticoBuildingType(
      store.getters.getSelectedHousingCompany.buildingType
    );
    if (kenticoBuildingTypeCodename)
      buildingTypeTerms.push(kenticoBuildingTypeCodename);

    //kenticoApi.addFilter("building_type", buildingTypeTerms.toString(), "any");
  }

  if(!params.limit)
    params.limit == 99;
  if(!params.order) {
    params.order == 'end_time';
    params.orderDirection = 'asc';
  }

  return params;
};

const _fetchTaxonomyTermsForCampaigns = async () => {
  if(!store.getters.getBuildingTypes) {
    try {
      await store.dispatch("fetchTaxonomyGroup", 'building_type');
    } catch(err) {
      throw err;
    }
  }
  if(!store.getters.getAgeGroups) {
    try {
      await store.dispatch("fetchTaxonomyGroup", 'age_group');
    } catch(err) {
      throw err;
    }
  }
}

const _mapLiferayBuildingTypeToKenticoBuildingType = liferayType => {
  let buildingTypes = store.getters.getBuildingTypes;
  let ret = null;
  if (buildingTypes.length) {
    buildingTypes.map(type => {
      if (type.aliases.includes(liferayType)) {
        ret = type.codename;
      }
    });
  }
  return ret;
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
