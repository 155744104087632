import store from "@/store/index";
import BaseService from "./BaseService";
import {
  AzureFunctionClient
} from "./AzureFunctionClient";

export default class ArticleService extends BaseService {
  constructor() {
    super();
  }
  
  fetchYitCorporateArticles(params = {}) {
    let defaultParams = { limit: 999, skip: 0 };
    params = Object.assign(defaultParams, params);

    let query = this._buildUrl(`/getYitArticles`, params);
    return AzureFunctionClient.get(query);
  }
}
