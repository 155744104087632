import settings from "@/globals";
import filters from "@/filters";
import globalComponents from "@/components/common";
import { getFeature } from "@/features.js";

export default {
    install: (app, options) => {
        globalComponents.forEach(component => {
            app.component(component.name, component);
        });

        app.config.globalProperties.$AppSettings = settings;
        app.config.globalProperties.$App = {
            getFeature
        };

        app.config.globalProperties.$filters = filters;
    }
};