export default (user, { can, cannot, rules }) => {

  if (user.hasRole("yitMarketing") || user.hasRole("yitBusinessAdmin") || user.hasRole("yitProject")) {
    can("manage", "consumption");
  }

  if (Array.isArray(user.hcs)) {
    user.hcs.map(hc => {
      if (Array.isArray(hc.roles) && (hc.roles.includes("propertyManager") || hc.roles.includes("maintenanceManager") || hc.roles.includes("boardMember") || hc.roles.includes("chairman") || hc.roles.includes("ceeBoardMember") || hc.roles.includes("consumptionManager"))) {
        can("read", "consumption", {
          projectId: hc.hcExt
        });
      }
    });
  }
}