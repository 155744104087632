import BaseService from "./BaseService"
import store from "@/store/index"
import AzureFunctionClient from "./AzureFunctionClient"
import one4AllClient from "./clients/One4AllClient"

export default class One4AllService extends BaseService {
  constructor() {
    super()

    const currentHc = store.getters.getSelectedHousingCompany
    const currentApartment = store.getters.getSelectedApartment
    const currentUser = store.getters.getCurrentUser

    if (currentHc)
      this.projectId = currentHc.id
    if (currentApartment)
      this.apartmentId = currentApartment.aptext
    if (currentUser) {
      if (currentUser.externalId)
        this.userId = currentUser.externalId;
      else if (currentUser.uuid)
        this.userId = currentUser.uuid;
      else
        this.userId = currentUser.id;
    }
    
  }

  /**
   * 
   * @returns 
   */
  async getSsoToken() {
    const payload = {
      premise_id: this.projectId,
      apartment_id: this.apartmentId,
      tenant_id: this.userId
    }
    return AzureFunctionClient.post("/one4all/oauth/token", payload)
  }
  
  /**
   * 
   * @returns 
   */
   async getPin() {
    return one4AllClient.get("/pin")
  }

  /**
   * 
   */
  async getModules() {
    return one4AllClient.post("/modules")
  }

  /**
   * @returns 
   */
  async getBookings(module_uuid, params) {
    if (!module_uuid)
      throw new Error("Uuid is mandatory to get bookings")

    const payload = {
      ...params,
      module_uuid
    }

    return one4AllClient.post("/bookings", null, {
      params: payload
    })
    
  }
}
