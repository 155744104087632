<template>
  <form class="sub-comment-form">
    <ConversationReplyForm
        :conversation="getCurrentConversationById($route.params.id)"
        :parent="parent"
        @response-posted="onResponsePosted"
        @on-cancel="onCancel"
        :canAttachImages="true"
        :buildingTaxonomy="$route.meta.areaDiscussion ? getSelectedHousingCompany.marketingName : ''"
    />
  </form>
</template>

<script>
import ConversationReplyForm from "@/components/Community/ConversationReplyForm";
import {mapGetters} from "vuex";

export default {
    name: "SubComment",
    props: {
        parent: {type: Object, required: true }
    },
    components: {
        ConversationReplyForm
    },
    computed: {
        ...mapGetters(["getCurrentConversationById", "getSelectedHousingCompany"])
    },
    methods: {
        onCancel() {
            this.$emit('on-cancel');
        },
        onResponsePosted() {
            this.onCancel();
            this.$emit('response-posted');
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";

.sub-comment-form {
    .community-message-form {
        margin-top: 1rem;
        border: 1px solid $borderColorLight;
    }
}
</style>