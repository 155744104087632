<template>
  <article class="contact-list__item text--break-long-words">
    <span
      class="contact-list__item__title"
      v-if="title && showFields.includes('title')"
    >{{title}}</span>
    <span
      class="contact-list__item__name"
      v-if="name && showFields.includes('name')"
    >{{name}}</span>
    <span
      class="contact-list__item__opening-hours"
      v-if="opening_hours && showFields.includes('opening_hours')"
    >{{opening_hours}}</span>
    <span
      class="contact-list__item__tel"
      v-if="phone_number && showFields.includes('phone_number')"
    >
      <i class="tel-icon fas fa-phone"></i>
      <a
        :href="$filters.tel_link(phone_number)"
      >{{phone_number}} {{phoneNumberAdditionalInformation ? phoneNumberAdditionalInformation : ''}}</a>
    </span>
    <span
      class="contact-list__item__email"
      v-if="email && showFields.includes('email')"
    >
      <a
        :href="'mailto:'+email"
        v-if="email"
      >{{email}}</a>
    </span>
    <span
      class="contact-list__item__additional-info"
      v-if="info && showFields.includes('info')"
      v-html="addBr(info)"></span>
    <div
      class="contact-list__item__email-cta"
      v-if="email && showFields.includes('email_cta')"
    >
      <a
        :href="'mailto:'+email"
        class="button button--primary"
        v-if="((email && email) && (show_contact_button && show_contact_button.length))"
      >{{$t('common.button.sendmessage')}}</a>
    </div>
  </article>
</template>
<script>
import Vue from "vue";
export default {
  name: "ContactListItem",
  props: {
    name: {type: String},
    title: {type: String},
    email: {type: String},
    opening_hours: {type: String},
    phone_number: {type: String},
    info: {type: String},
    phoneNumberAdditionalInformation: {type: String},
    fields: {type: Array, default: () => []}
  },
  data() {
    return {
      showFields: [
        "name",
        "title",
        "email",
        "opening_hours",
        "info",
        "phone_number"
      ]
    };
  },
  methods: {
    addBr(value) {
      return this.$filters.nl2br(value);
    }
  },
  mounted() {
    if (this.fields && this.fields.length) {
      this.showFields = this.fields;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
.contact-list__item {

  &__address {
    font-size: 0.9rem;
  }
  &__tel {
    a {
      margin-bottom: .4rem;
      font-size: .9rem;
    }
    .tel-icon {
      display: none;
      background: #fff;
      border-radius: 50%;
      padding: 0.3em;
      font-size: 0.9rem;
      margin-right: 0.3em;
      &:before {
        color: $greyBlueMidDark;
      }
    }
  }
  &__email {
    margin-bottom: .2rem;
    a {
      font-size: .9rem;
    }
  }
  
  &__email-cta {
    margin-top: 0.8rem;
    a {
      font-size: .9rem;
    }
  }

  &:last-child {
    margin-right: 0;
    padding-bottom: 0;
  }
}
</style>