import * as rax from 'retry-axios';
import axios from "axios";
import store from "@/store/index";
import { TokenService, axiosNoAuthInterceptor } from "@/services/TokenService";
import UserService from "@/services/UserService";

let api = new UserService();

const AXIOS_CLIENT_CONFIGURATION = {
  baseURL: process.env.VUE_APP_AZURE_FUNCTION_BASE + "/api",
  timeout: 30000,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key":
      process.env.VUE_APP_AZURE_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": "true",
  }
};

const AzureFunctionClient = axios.create(AXIOS_CLIENT_CONFIGURATION);
const AzureFunctionClientWithRetry = axios.create(AXIOS_CLIENT_CONFIGURATION);

AzureFunctionClient.interceptors.request.use(async (config) => {
  const endpoint = config.url.replace(process.env.VUE_APP_AZURE_FUNCTION_BASE, "");
  const isTokenRefreshEndpoint = endpoint.startsWith("/getAccessToken") ? true : false;
  const isLoggedIn = store.getters.isLogged;
  let token = TokenService.getToken('AUTH', 'b2c');
  //Listen if token is expired already

  if (!isTokenRefreshEndpoint && isLoggedIn) {
    let tokenExpiry = TokenService.getToken('EXPIRY', 'b2c');
    let now = new Date().getTime() / 1000;

    console.log("Axios: Token expires in: ", new Date(tokenExpiry * 1000).toString());

    //no token at all
    //if (!token && !store.getters.getAccessToken) {
    if (!token) {
      console.log("Axios: No token found.");
      store.commit("setIsTokenExpired", true);
    }

    //token is expired
    if (tokenExpiry > 0 && now > tokenExpiry && !store.getters.getIsTokenExpired) {
      console.log("Axios: Token expired");
      store.commit("setIsTokenExpired", true);
      return;
    }

    //15 minutes before token is expired -> attempt to refresh token
    else if (tokenExpiry > 0 && now > tokenExpiry-900) {
      store.dispatch("refreshAccessToken");
    }
  }
  return config;
})

AzureFunctionClientWithRetry.interceptors.request.use(async (config) => {
  const endpoint = config.url.replace(process.env.VUE_APP_AZURE_FUNCTION_BASE, "");
  const isTokenRefreshEndpoint = endpoint.startsWith("/getAccessToken") ? true : false;
  const isLoggedIn = store.getters.isLogged;
  let token = TokenService.getToken('AUTH', 'b2c');
  //Listen if token is expired already

  if (!isTokenRefreshEndpoint && isLoggedIn) {
    let tokenExpiry = TokenService.getToken('EXPIRY', 'b2c');
    let now = new Date().getTime() / 1000;

    console.log("Axios: Token expires in: ", new Date(tokenExpiry * 1000).toString());

    //no token at all
    //if (!token && !store.getters.getAccessToken) {
    if (!token) {
      console.log("Axios: No token found.");
      store.commit("setIsTokenExpired", true);
    }

    //token is expired
    if (tokenExpiry > 0 && now > tokenExpiry && !store.getters.getIsTokenExpired) {
      console.log("Axios: Token expired");
      store.commit("setIsTokenExpired", true);
      return;
    }

    //15 minutes before token is expired -> attempt to refresh token
    else if (tokenExpiry > 0 && now > tokenExpiry-900) {
      store.dispatch("refreshAccessToken");
    }
  }
  return config;
})


//Add jwt token to services that needs it
const includeJwt = (token) => {
  AzureFunctionClient.interceptors.request.use(function(config) {
    let issuer = store.getters.getTokenIssuer;
    let token = store.getters.getAccessToken;
    let getCurrentUser = store.getters.getCurrentUser;

    //fix issue with huge liferay auth tokens
    if(!store.getters.isStateB2C && getCurrentUser && getCurrentUser.hasRole('yitBusinessAdmin')) {
      token = store.getters.getAuthToken;
    }

    if (store.getters.getServiceRequestAuthToken)
      token = store.getters.getServiceRequestAuthToken;

    //token = TokenService.getToken('AUTH', 'b2c');

    config.headers["Ocp-Apim-Issuer"] = issuer;
    config.headers.Authorization = "Bearer " + token;

    return config;
  });
  AzureFunctionClientWithRetry.interceptors.request.use(function(config) {
    let issuer = store.getters.getTokenIssuer;
    let token = store.getters.getAccessToken;
    let getCurrentUser = store.getters.getCurrentUser;

    //fix issue with huge liferay auth tokens
    if(!store.getters.isStateB2C && getCurrentUser && getCurrentUser.hasRole('yitBusinessAdmin')) {
      token = store.getters.getAuthToken;
    }

    if (store.getters.getServiceRequestAuthToken)
      token = store.getters.getServiceRequestAuthToken;

    //token = TokenService.getToken('AUTH', 'b2c');

    config.headers["Ocp-Apim-Issuer"] = issuer;
    config.headers.Authorization = "Bearer " + token;

    return config;
  });
};

AzureFunctionClientWithRetry.defaults.raxConfig = {
  instance: AzureFunctionClientWithRetry,
  retry: 2,
  noResponseRetries: 2,
  retryDelay: 1000,
  httpMethodsToRetry: ['HEAD', 'OPTIONS', 'POST'],
  statusCodesToRetry: [[500,504]],
  backoffType: 'static',
  onRetryAttempt: err => {
    const cfg = rax.getConfig(err);
    console.log(`[AzureFunctionClientWithRetry] Retry attempt #${cfg.currentRetryAttempt}`);
  }
};

rax.attach(AzureFunctionClientWithRetry);

export default AzureFunctionClient;
export { AzureFunctionClient, AzureFunctionClientWithRetry, includeJwt };
