export default [
  {
    path: "/support",
    name: "/support",
    meta: {
      sectionTitle: "navigation.supportcenter",
      title: "navigation.supportcenter"
    },
    component: () =>
      import(
        /* webpackChunkName: "service-requests" */ "@/views/Support.vue"
      ),
    children: [
      {
        path: "service-requests/new",
        name: "service-requests/new",
        meta: {
          sectionTitle: "navigation.supportcenter",
          title: "navigation.supportcenter"
        },
        component: () =>
          import(
            /* webpackChunkName: "service-requests" */ "@/views/ServiceRequest/New.vue"
          )
      },
      {
        path: "service-requests/:id",
        name: "service-requests/:id",
        meta: {
          sectionTitle: "navigation.supportcenter",
          title: "navigation.supportcenter"
        },
        component: () =>
          import(
            /* webpackChunkName: "service-requests" */ "@/components/ServiceRequest/View.vue"
          )
      },
      {
        path: "service-requests",
        name: "service-requests",
        component: () =>
          import(
            /* webpackChunkName: "service-requests" */ "@/views/ServiceRequest/List.vue"
          ),
        meta: {
          sectionTitle: "navigation.supportcenter",
          title: "navigation.supportcenter",
          introduction: "navigation.supportcenter.introduction",
          displayHeadSection: true,
          disableScrollTop: true
        }
      },
      {
        path: "/service-requests",
        redirect: "/support/service-requests"
      },
      {
        path: "faq/:id",
        redirect: "/faq/:id"
      },
      {
        path: "faq",
        redirect: "/faq"
      },
      {
        path: "/support/contact-book/",
        name: "/support/contact-book/",
        meta: {
          title: "support.center.tab.contacts-book",
          sectionTitle: "support.center.tab.contacts-book",
          displayHeadSection: false
        },
        component: () =>
          import(
            /* webpackChunkName: "contact-book" */ "@/views/Contacts.vue"
          )
      }
    ]
  },
  {
    path: "/faq",
    name: "/faq",
    component: () =>
      import(/* webpackChunkName: "faq" */ "@/views/Faq/List.vue"),
    meta: {
      sectionTitle: "support.center.faq",
      title: "support.center.faq",
      accessPolicies: {
        features: ['faq']
      },
      introduction: "support.center.faq.introduction",
      displayHeadSection: true,
      disableScrollTop: true
    }
  },
  {
    path: "/faq/:id",
    name: "/faq/:id",
    component: () =>
      import(/* webpackChunkName: "faq" */ "@/views/Faq/View.vue"),
    meta: {
      sectionTitle: "navigation.supportcenter",
      title: "support.center.faq",
      accessPolicies: {
        features: ['faq']
      }
    }
  },
  {
    path: "/service-requests/manage/:id/:userId/:token",
    name: "/service-requests/manage/:id/:userId/:token",
    meta: {
      public: true,
      layout: "fullwidth",
      sectionTitle: "navigation.supportcenter",
      title: "navigation.supportcenter"
    },
    component: () =>
      import(
        /* webpackChunkName: "service-requests-admin-single-not-logged-in" */ "@/views/Admin/ServiceRequests/SingleNotLoggedIn.vue"
      )
  },
  {
    path: "/admin/service-requests/:id",
    name: "/admin/service-requests/:id",
    component: () =>
      import(
        /* webpackChunkName: "admin-service-requests" */ "@/views/Admin/ServiceRequests/Single.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/service-requests",
    name: "/admin/service-requests",
    component: () =>
      import(
        /* webpackChunkName: "admin-service-requests" */ "@/views/Admin/ServiceRequests/Main.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'partner', 'boardMember', 'chairman', 'rentalManager', 'ceeMaintenanceManager'],
        features: ['service-requests']
      }
    }
  },
  {
    // This is to support old QR links, otherwise can be removed. See: INC0178802 for more details in service now.
    path: "/service-requests/new",
    redirect: "/support/service-requests/new"
  },
  // {
  //   // This is to support old QR links, otherwise can be removed. See: INC0178802 for more details in service now.
  //   // This should work, but not working will invetigate more, till than below code will be in effect
  //   path: "/service-requests/:id",
  //   redirect: "/support/service-requests/:id",
  // },
  {
    // This is to support old QR links, otherwise can be removed. See: INC0178802 for more details in service now.
    path: "/service-requests/:id",
    name: "/service-requests/:id",
    meta: {
      sectionTitle: "navigation.supportcenter",
      title: "navigation.supportcenter"
    },
    component: () =>
      import(
        /* webpackChunkName: "service-requests" */ "@/components/ServiceRequest/View.vue"
      )
  }
];