<template>
  <transition name="fade">
    <div class="comment-wrapper" :key="'comment-'+id">
      <div class="comment" :class="{ 'comment--me': isMe }">
        <div class="comment__header" v-if="!isCommentDeleted">
          <div class="comment__sender">
              <ProfileIcon type="user" :name="name" :apt="apartment" :roles="userRoles" />
            <div>
              <ProfileName :name="name" :message="this.obj" />
              <CommunityUserTagLabel :apartment="apartment" :roles="userRoles" />
            </div>
          </div>
          <div class="comment__date">
            {{ $filters.date_time_format(date,$i18n.locale) }}
          </div>
        </div>
        <div class="comment__body">
          <CommentEditForm
            class="message-item__content"
            :comment="obj"
            v-if="editMode"
            @close="onRemoveEditMode"
          />
          <slot v-else />
          <CommunityImageGrid class="conversation__images" :images="obj.images" v-if="obj.images" />
        </div>
        <div class="comment__footer" v-if="!editMode && !isCommentDeleted">
          <LikeButton :count="likes && Array.isArray(likes) ? likes.length : 0" :liked="hasUserLiked" @on-click="like" :likedUsers="likes" />
          <Button
            type="button"
            class="comment__display-messages"
            @click="toggleSubCommentList"
            v-if="!obj.parentComment && displaySubComments"
            >
              <i class="far fa-comment-alt"></i>
              <span>{{ obj.childMessageCount ? parseInt(obj.childMessageCount) : 0 }}</span>
          </Button>
          <SubCommentButton v-if="!obj.parentComment && displaySubComments" @click="toggleSubCommentForm"></SubCommentButton>
          <AdminToolbar
            :id="id"
            :showEdit="$can('update', this.obj)"
            @edit-click="onMessageEdit"
            :showDelete="$can('delete', this.obj)"
            :showBan="!isMe"
            @delete-click="onMessageDelete"
            :showConfirmOnDelete="true"
            :userId="userId"
          ></AdminToolbar>
          <div class="comment__report-message" v-if="showReportForm">
            <ReportMessage
              contentType="message"
              :targetObject="obj"
              ref="reportMessage"
            />
          </div>
        </div>
      </div>
      <div class="comment-sub-content" :class="{'has-active-form': displaySubCommentForm}">
        <slot name="sub-content"></slot>
        <Comments :comments="obj.comments" :displayForm="false" v-if="isSubCommentListVisible && Array.isArray(obj.comments) && obj.comments.length" :key="'sub-comment-list__'+id" />
        <div v-if="loading.subMessages" class="text-center"><LoaderIcon /></div>
        <NewCommentForm
          v-if="displaySubCommentForm"
          :parent="id"
          commentType="news"
          @on-cancel="toggleSubCommentForm"
          @response-posted="onAfterResponsePosted"
          :displayCancelButton="true"
          id="sub-comment-form"
          :key="'comment-form-'+id"
        />
        <!--<SubCommentList :items="obj.messages" :key="'sub-comment-list__'+id" v-if="isSubCommentListVisible" />
        <div v-if="loading.subMessages" class="text-center"><LoaderIcon /></div>
        <SubCommentForm v-if="displaySubCommentForm" :parent="obj" @on-cancel="toggleSubCommentForm" @response-posted="onAfterResponsePosted" id="sub-comment-form" />-->
      </div>
    </div>
  </transition>
</template>
<script>
import AdminToolbar from "@/components/Admin/Common/Toolbar";
import CommentEditForm from "@/components/Comments/CommentEditForm";
import { mapActions, mapGetters } from "vuex";
import ReportMessage from "@/components/Community/ReportMessage";
import ProfileIcon from "@/components/Community/ProfileIcon";
import LikeButton from "@/components/Community/LikeButton";
import CommunityImageGrid from "@/components/Community/ImageGrid";
import CommunityService from "@/services/CommunityService";
import SubCommentButton from "./SubCommentButton";
import SubCommentForm from "./SubCommentForm";
import SubCommentList from "./SubCommentList";
import NewCommentForm from "./NewCommentForm";
import CommunityUserTagLabel from "@/components/Community/UserTagLabel";
import ProfileName from "../Community/ProfileName.vue";

export default {
  name: "Comment",
  props: {
    id: String,
    name: String,
    apartment: String,
    userRoles: {type: Array},
    obj: Object,
    date: String,
    userId: String,
    showReportForm: {
      type: Boolean,
      default: false
    },
    likes: Array,
    displaySubComments: {type: Boolean, default: true}
  },
  components: {
    AdminToolbar,
    CommentEditForm,
    ReportMessage,
    ProfileIcon,
    LikeButton,
    SubCommentButton,
    SubCommentList,
    SubCommentForm,
    NewCommentForm,
    CommunityImageGrid,
    CommunityUserTagLabel,
    ProfileName
},
  data() {
    return {
      editMode: false,
      isSubCommentListVisible: false,
      displaySubCommentForm: false,
      loading: {
        subMessages: false
      }
    };
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    isMe() {
      if (this.getCurrentUser.getId() && this.userId) {
        if (this.getCurrentUser.getId() === this.userId) return true;
      }

      return false;
    },
    hasUserLiked() {
      if(this.likes) {
        let liked = false;
        let userId = this.getCurrentUser.getId();
        this.likes.map(l => {
          if(l.userUuid === userId)
            liked = true;
            return l;
        });
        return liked;
      }
      return false;
    },
    isCommentDeleted() {
      if (this.obj?.isDeleted === true) return true
      return false
    }
  },
  methods: {
    ...mapActions(["deleteComment", "likeComment", "fetchSubComments"]),
    onMessageEdit(id) {
      console.log("message have been edited. Yei!");
      this.editMode = true;
    },
    onMessageDelete(id) {
      console.log("Deleting message id: ", id);
      this.deleteComment(id);
    },
    onRemoveEditMode(event) {
      this.editMode = false;
    },
    async like(value) {
      try {
        await this.likeComment(this.id);
      } catch(err) {
        console.log(err);
      }
      //this.communityApi.processLike(this.id, 'conversation');
    },
    async fetch() {
      let payload = {
        parent: this.id
      }

      try {
        this.loading.subMessages = true;
        await this.fetchSubComments(payload);
      } catch(err) {
        console.log(err);
      } finally {
        this.loading.subMessages = false;
      }
    },
    toggleSubCommentList() {
      this.isSubCommentListVisible = !this.isSubCommentListVisible;
      if(this.isSubCommentListVisible === true) {
        this.fetch();
      }
    },
    toggleSubCommentForm() {
      this.displaySubCommentForm = !this.displaySubCommentForm;
      if(this.displaySubCommentForm === true) {
        setTimeout(() => {
          this.scrollToTop();
        }, 1);
      }
    },
    scrollToTop() {
      let container = this.$el.querySelector("#sub-comment-form");
      if (container) {
        container.scrollIntoView({
          block: "center",
          inline: "start",
          behavior: "smooth"
        });
      }
    },
    onAfterResponsePosted() {
      this.fetch();
      this.isSubCommentListVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
#sub-comment-form {
  scroll-margin-top: 82px;
  scroll-snap-margin-top: 82px;
}
</style>
