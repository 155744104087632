import DocumentServiceV2 from "@/services/AzureDocumentService/v2";
import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import KenticoVideo from "@/models/KenticoVideo";
import store from "@/store";
import { update, set, remove } from "@/store/mutators";
import _ from "lodash";
import { compare } from 'natural-orderby';

const api = new DocumentServiceV2();
const kenticoApi = new KenticoDeliveryApiService();
kenticoApi.setContentType(["video_list"]);
kenticoApi.targetByDefaultBuildingTaxonomy = true;

const getDefaultState = () => {
  return {
    documents: [],
    documentsGroupedByFolder: {},
    folders: [],
    latestDocuments: [],
    folderSections: [
      {
        scope: 'apartment',
        nameKey: 'documents.folders.apartment',
        descriptionKey: 'documents.folders.apartment.description'
      },
      {
        scope: 'project',
        nameKey: 'documents.folders.building',
        descriptionKey: 'documents.folders.building.description'
      }
    ],
    sortBy: {
      field: 'created',
      direction: 'desc'
    }
  };
};

const state = getDefaultState();

const getOrderedBy = (list) => {
  return list.sort((a, b) => compare({ order: state.sortBy.direction })(a[state.sortBy.field], b[state.sortBy.field]))
}

const getters = {
  getAllDocuments: state => state.documents,
  getDocumentsGroupedByFolder: state => folder => {
    if (typeof state.documentsGroupedByFolder[folder] == 'undefined' || !Array.isArray(state.documentsGroupedByFolder[folder]))
      return [];
    return getOrderedBy(state.documentsGroupedByFolder[folder])
  },
  getAllFolders: state => state.folders,
  getAllFoldersFlat: state => {
    let ret = [];
    state.folders.map(function f(_folder) {
      ret.push(_folder);
      if(_folder.children && _folder.children.length) {
          _folder.children.map(f);
      }
    });

    return ret;
  },
  getFoldersByScope: state => scope => state.folders.filter(f => f.subScope === scope),
  getVideos: state => state.videos,
  getDocumentSortObject: state => state.sortBy,
  getFolderSections: state => {
    if (!store.getters.getSelectedApartment || !store.getters.getSelectedApartment.aptext)
      return state.folderSections.filter(s => s.scope != 'apartment');
    return state.folderSections;
  },
  getLatestDocuments: state => (limit = 2) => {
    if (state.latestDocuments.length)
      return state.latestDocuments.slice(0, 2);
    return _.orderBy(state.documents, ['modified'], ['desc']).slice(0, 2);
  }
};

const actions = {
  async fetchFolders({ commit }, params) {
    try {
      let res = await api.getFolders(params);
      commit('setFolders', Array.isArray(res.data) ? res.data : []);
    } catch (err) {
      throw err;
    }
  },
  async fetchDocuments({ commit }, params) {
    try {
      let res = await api.fetchDocuments(params);
      console.log("fetchDocuments: ", res.data, params.folder);
      commit("appendAllFiles", res.data.files);
      commit("appendGroupedDocuments", res.data.files)
    } catch (err) {
      throw err;
    }
  },
  async deleteSingleDocument({ commit }, document){
    try {
      let response = await api.deleteDocument(document._id);
      commit("removeDocument", document);
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  setFolders: (state, data) => {

    //filter out hided folders
    data = data.filter(d => d.visible);

    //set folders
    set(state, data, 'folders');

    //initialize grouped files array
    let groupedFiles = [];
    data.map(d => {
      groupedFiles[d.key] = []
    })
    set(state, groupedFiles, 'documentsGroupedByFolder')

  },
  appendAllFiles: (state, data) => update(state, data, 'documents', '_id'),
  appendGroupedDocuments: (state, files) => {
    if (typeof state.documentsGroupedByFolder == 'undefined') {
      state.documentsGroupedByFolder = {};
    }
    if (Array.isArray(files)) {
      files.map(file => {
        if (!Array.isArray(state.documentsGroupedByFolder[file.folder]))
          state.documentsGroupedByFolder[file.folder] = [];
        
        let found = false;
        state.documentsGroupedByFolder[file.folder].map(f => {
          if (f._id === file._id) {
            f = file;
            found = true;
          }
          return f;
        });
        if (!found)
          state.documentsGroupedByFolder[file.folder].push(file);
      });

      //map hc_owners documents into apartment owner docs if present
      if (typeof state.documentsGroupedByFolder['hc_owners'] != 'undefined') {
        if (typeof state.documentsGroupedByFolder['apt_owner'] == 'undefined')
          state.documentsGroupedByFolder['apt_owner'] = [];

        let ownerFileIds = state.documentsGroupedByFolder['apt_owner'].map(f => f._id);
        files.map(file => {
          if (file.folder == 'hc_owners') {
            if (!ownerFileIds.includes(file._id))
              state.documentsGroupedByFolder['apt_owner'].push(file);
          }
        });
      
      }
    }
  },
  setDocumentSortObject: (state, data) => set(state, data, 'sortBy'),
  removeDocument: (state, file) => {
    state.documents = remove(state, file, 'documents', '_id', file._id);
    if (typeof state.documentsGroupedByFolder[file.folder] != 'undefined') {
      state.documentsGroupedByFolder[file.folder] = state.documentsGroupedByFolder[file.folder].filter(f => f._id !== file._id);
    }
  },
  resetModule: (state) => {
    
    state.documents = [];
    state.documentsGroupedByFolder = {};
    state.videos = [];
    state.folders = [];
    state.latestDocuments = [];
    state.sortBy = {
      field: 'created',
      direction: 'desc'
    };
  
  },
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
