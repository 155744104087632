const validateEmail = function(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const validateMaxLength = (str = '', maxLenght = 50) => {
  if(str.length > maxLenght)
    return false;

  return true;
}
const validateMinLength = (str = '', length = 5) => {
  if(str.length < length)
    return false;

  return true;
}

/**
 * 8-256 characters
 * containing 3 out of 4 of the following: 
 * - Lowercase characters
 * - uppercase characters
 * - digits (0-9)
 * - and one or more of the following symbols: @ # $ % ^ & * - _ + = [ ] { } | \ : ' , ? / ` ~ " ( ) ; .
 * @param {String} string 
 * @returns {boolean}
 */
const validatePassword = string => {

  const minPasswordLength = 8;
  const maxPasswordLength = 256;

  const rules = {
    length: false,
    lowerCaseChars: false,
    upperCaseChars: false,
    digits: false,
    symbols: false
  };

  if(!string)
    return false;

  if(string.length >= minPasswordLength && string.length <= maxPasswordLength)
    rules.length = true;

  if(/.*[a-z].*/.test(string))
    rules.lowerCaseChars = true;

  if(/.*[A-Z].*/.test(string))
    rules.upperCaseChars = true;

  if(/.*[0-9].*/.test(string))
    rules.digits = true;

  if(/.*[#&()\]\[}{|\^+%?\/*$~€é=,.:;'"`_-].*/.test(string))
    rules.symbols = true;
  
  //console.log("Validate password: ", rules);

  if(rules.length && Object.values(rules).filter(v => v === true).length >= 4)
    return true;

  return false;
}

/**
 * 
 * @param {*} str 
 * @returns 
 */
const isValidUuid = str => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(String(str));
}

export {
  validateEmail,
  validateMaxLength,
  validatePassword,
  isValidUuid,
  validateMinLength
};