import CalendarService from "@/services/CalendarService";
import { isDeadline } from "@/utils/CalendarUtil";
import { getToday } from "@/utils/DateHelper";

const api = new CalendarService();

const eventModel = {
  system: {
    id: "",
    name: "",
    codename: "",
    language: "",
    type: "",
    sitemap_locations: [],
    last_modified: ""
  },
  elements: {
    title: {
      type: "text",
      name: "Title",
      value: ""
    },
    content: {
      type: "rich_text",
      name: "Content",
      images: {},
      links: {},
      modular_content: [],
      value: ""
    },
    location: {
      type: "text",
      name: "Location",
      value: ""
    },
    images: {
      type: "asset",
      name: "Images",
      value: []
    },
    attachments: {
      type: "asset",
      name: "Attachments",
      value: []
    },
    type: {
      type: "multiple_choice",
      name: "Type",
      value: []
    },
    scheduled_time: {
      type: "date_time",
      name: "Scheduled time",
      value: ""
    },
    start_time: {
      type: "date_time",
      name: "Start time",
      value: ""
    },
    end_time: {
      type: "date_time",
      name: "End time",
      value: ""
    },
    buildingtaxonomy: {
      type: "taxonomy",
      name: "BuildingTaxonomy",
      taxonomy_group: "buildingtaxonomy",
      value: []
    },
    user_id: {
      type: "text",
      name: "User ID",
      value: ""
    },
    author: {
      type: "text",
      name: "Author Name",
      value: ""
    }
  }
};

const convertLiferayEventToKenticoEvent = event => {
  let convertedEvent = Object.assign(
    {},
    JSON.parse(JSON.stringify(eventModel))
  );
  convertedEvent.system.id = event.calendarBookingId;
  convertedEvent.system.codename = event.calendarBookingId;
  if (event.deadline === true)
    convertedEvent.elements.type.value.push({
      codename: "deadline",
      name: "Deadline"
    });
  else
    convertedEvent.elements.type.value.push({
      codename: "event",
      name: "Event"
    });

  convertedEvent.elements.content.value = event.description;
  if (event.documentLibraryLinkPath && event.documentLibraryLinkTitle) {
    convertedEvent.elements.attachments.value.push({
      url: process.env.VUE_APP_API_BASE_URL + event.documentLibraryLinkPath,
      name: event.documentLibraryLinkTitle
    });
  }

  convertedEvent.elements.start_time.value = new Date(event.startTime);
  convertedEvent.elements.end_time.value = new Date(event.endTime);

  //event.link;
  convertedEvent.elements.location.value = event.location;
  convertedEvent.elements.title.value = event.title;
  convertedEvent.elements.user_id.value = event.userId;

  return Object.assign({}, convertedEvent);
};

const getDefaultState = () => {
  return {
    calendarEntries: [],
    latestCalendarEntries: [],
    latestEvents: [],
    latestDeadlines: [],
    currentCalendarEntry: null
  };
};

const filterPassedEvents = events => {
  //filter off passed events
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return events.filter(item => {
    console.log("Compare: ", item.elements.start_time.value);
    if (
      new Date(item.elements.start_time.value).getTime() > today.getTime() ||
      new Date(item.elements.end_time.value).getTime() > today.getTime()
    )
      return item;
  });
};

const state = getDefaultState();

const getters = {
  getAllCalendarEntries: state => state.calendarEntries,
  getLatestCalendarEntries: state => {
    let originalState = state.latestCalendarEntries;
    //filter passed events
    originalState = filterPassedEvents(originalState);
    console.log(originalState);
    return originalState;
  },
  getLatestCalendarEvents: state => {
    let originalState = state.calendarEntries;

    //filter passed events
    originalState = filterPassedEvents(originalState);

    return originalState.filter(item => {
      if (!isDeadline(item)) return item;
    });
  },
  getLatestCalendarDeadlines: state => {
    let originalState = state.calendarEntries;

    //filter passed events
    originalState = filterPassedEvents(originalState);

    return originalState.filter(item => {
      if (isDeadline(item)) return item;
    });
  },
  getCurrentCalendarEntry: state => state.currentCalendarEntry
};

const actions = {
  fetchCalendarEntries({ commit }, params = {}) {
    if (params.start_time) params.startTime = params.start_time;
    if (params.end_time) params.endTime = params.end_time;
    return new Promise(function(resolve, reject) {
      api
        .getList(params)
        .then(response => {
          console.log("Fetching calendar entries: ", response.data, params);
          let ret = [];
          if (response.data.events.length) {
            ret = response.data.events.map(event => {
              return convertLiferayEventToKenticoEvent(event);
            });

            if (params.append) commit("appendCalendarEntries", ret);
            else commit("setCalendarEntries", ret);
          }
          resolve(ret);
        })
        .catch(err => {
          console.log("Fetching news failed: ", err);
          reject(err);
        });
    });
  },
  fetchLatestCalendarEntries({ commit }, params = {}) {
    if (!params.start) params.start = 0;
    if (!params.end) params.end = 10;
    if (!params.startTime) params.startTime = getToday().getTime();
    console.log("Fetching calendar entries: ", params);
    return new Promise(function(resolve, reject) {
      api
        .getList(params)
        .then(response => {
          console.log("Fetching calendar entries: ", response.data, params);
          let ret = [];
          if (response.data.events.length) {
            ret = response.data.events.map(event => {
              let convertedEvent = convertLiferayEventToKenticoEvent(event);
              return convertedEvent;
            });
            commit("setLatestCalendarEntries", ret);
          }
          resolve(ret);
        })
        .catch(err => {
          console.log("Fetching news failed: ", err);
          reject(err);
        });
    });
  },
  fetchSingleCalendarEntry({ commit }, id) {
    return new Promise((resolve, reject) => {
      return api
        .getSingle(id)
        .then(response => {
          //console.log("Fetched single calendar entry: ", response.data);
          commit(
            "setCurrentCalendarEntry",
            convertLiferayEventToKenticoEvent(response.data)
          );
          resolve(response.data);
        })
        .catch(err => reject(err));
    });
  }
};

const mutations = {
  setCalendarEntries: (state, res) => (state.calendarEntries = res),
  setLatestCalendarEntries: (state, res) => (state.latestCalendarEntries = res),
  setLatesCalendartEvents: (state, res) => (state.latestEvents = res),
  setLatesCalendarDeadlines: (state, res) => (state.latestDeadlines = res),
  appendCalendarEntries: (state, res) =>
    (state.calendarEntries = state.calendarEntries.concat(res)),
  setCurrentCalendarEntry: (state, res) => (state.currentCalendarEntry = res),
  resetCalendarData: (state, res) => {
    state.calendarEntries = [];
    state.latestCalendarEntries = [];
    state.latestEvents = [];
    state.latestDeadlines = [];
    state.currentCalendarEntry = {};
  }
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
