import CommunityService from "@/services/CommunityService";
import _ from "lodash";
import { isAxiosError } from "axios"
const api = new CommunityService();

const getDefaultState = () => {
  return {
    privateMessages: [],
    publicMessages: [],
    conversations: [],
    targetUsers: [],
    areaMessages: []
  };
};

const state = getDefaultState();
const getters = {
  getPrivateMessages: state => state.privateMessages,
  getPublicMessages: state => state.publicMessages,
  getTargetUsers: state => state.targetUsers,
  getCurrentConversationById: state => cid => {
    let conversations = state.conversations.find(
      item => item.conversationId == cid
    );
    return conversations;
  },
  getAreaMessageById: state => cid => {
    let areaMessages = state.areaMessages.find(item => item._id == cid);
    return areaMessages;
  },
  isConversationDeleted: (state, getter) => cid => {
    const privateConversation = getter.getPrivateMessagesById(cid)
    const currentConversation = getter.getCurrentConversationById(cid)
    const publicConversation = getter.getPublickMessagesById(cid)
    const areaConversations = getter.getAreaMessageById(cid)

    if (currentConversation || publicConversation || areaConversations || privateConversation) {
      if (currentConversation && currentConversation.firstMessage)
        return currentConversation.firstMessage.isDeleted
      if (areaConversations && areaConversations.isDeleted)
        return areaConversations.isDeleted
      if (privateConversation && privateConversation.isDeleted)
        return privateConversation.isDeleted
      if (currentConversation && currentConversation.isDeleted)
        return currentConversation.isDeleted
      if (publicConversation && publicConversation.isDeleted)
        return publicConversation.isDeleted
    }
    return false
  },
  getAllConversations: state => {
    return state.conversations;
  },
  getCurrentPublicConversationById: state => cid => {
    return state.publicMessages.find(item => item.conversationId == cid);
  },
  getPrivateMessagesById: state => cid => {
    return state.privateMessages.find(item => item._id == cid);
  },
  getPublickMessagesById: state => cid => {
    return state.publicMessages.find(item => item._id == cid);
  },
  getAreaMessages: state => state.areaMessages,
  getAreaMessagesById: state => cid => {
    return state.areaMessages.find(item => item._id == cid);
  },
};

const actions = {
  fetchConversationsForUser({ commit }, projectId) {
    return api
    .fetchConversationsForUser(projectId)
    .then(response => {
        commit("setPrivateMessages", response.data);
        return response
      })
      .catch(err => {
        console.log("Fetching private messages failed: ", err);
        return err
      });
  },
  fetchPublicMessages({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      api.fetchPublicMessages(params)
      .then(response => {
        if(params && params.append) {
          commit("appendPublicConversations", response.data);
        } else
          commit("setPublicMessages", response.data);

          resolve(response.data);
      })
      .catch(err => {
        console.log("Fetching public messages failed: ", err);
        reject(err);
      });
    });
  },
  fetchAreaMessages({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .fetchAreaMessages(params)
        .then(response => {
          if(params && params.append) 
            commit("appendAreaMessages", response.data);
          else
            commit("setAreaMessages", response.data);
          resolve(response.data);
        })
        .catch(err => {
          console.log("Fetching public messages failed: ", err);
          reject(err);
        });
    });
  },
  newPublicConversation({ commit }, postData) {
    return new Promise((resolve, reject) => {
      api
        .newConversation(postData)
        .then(res => {
          console.log("Added new conversation", res);
          if (res.data._id) {
            commit("unshiftPublicConversations", res.data);
            resolve(res);
          }
        })
        .catch(err => {
          if (isAxiosError(err)) {
            reject(err.response.data.message)
          } else {
            reject(err)
          }
        });
    });
  },
  async fetchTargetUsers({ commit }) {
    try {
      let response = await api.fetchTargetUsers();
      commit("setTargetUsers", response.data);
      return response.data;
    } catch (err) {
      console.log("Fetching target usersfailed: ", err);
    }
  },
  fetchConversation({ commit }, {id, params}) {
    return new Promise((resolve, reject) => {
      api
        .fetchConversation(id, params)
        .then(response => {

          let messageArray = _.clone(response.data);
          let firstMessage = response.data[0];
          
          if(params && params.append) {
            let newConversation = {
              conversationId: id,
              messages: response.data
            };
            commit("appendConversationMessages", newConversation);
          } else {
            let newConversation = {
              conversationId: id,
              messageCount: Array.isArray(response.data) ? response.data.length : 0,
              creatorUuid: Array.isArray(response.data) && response.data.length ? firstMessage.userUuid : 0,
              _type: "Conversation",
              likes: Array.isArray(response.data) && response.data.length ? firstMessage.likes : [],
              subscribedNotifications: firstMessage.subscribedNotifications,
              firstMessage: firstMessage,
              messages: messageArray.concat()
            };
            commit("setConversationMessages", [newConversation]);
          }
          resolve(response.data);
        })
        .catch(err => {
          console.log("Fetching conversation failed: ", err);
          reject(err);
        });
      });
  },
  deleteConversation({ commit }, params = {}) {
    if(!params.id)
      return Promise.resolve();
    return api
      .deleteConversation(params.id)
      .then((res) => {
        if(params.type == 'area') {
          const conversations = [
            ...state.areaMessages.map(it => it._id !== res.data.conversation._id 
              ? it 
              : { ...it, ...res.data.conversation }),
          ]
          commit("setAreaMessages", conversations);
        } else {
          const conversations = [
            ...state.publicMessages.map(it => it._id !== res.data.conversation._id 
              ? it 
              : { ...it, ...res.data.conversation }),
          ]
          commit("setPublicMessages", conversations);
        }
      })
      .catch(err => console.log(err));
  },

  updateConversation({ commit }, postData) {
    return new Promise((resolve, reject) => {
      api
        .updateConversation(postData)
        .then(res => {
          commit("editConversationMessage", res.data.conversation);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },

  deletePrivateConversation({ commit }, id) {
    return api
      .deleteConversation(id)
      .then(res => {
        let conversations = state.privateMessages.filter(conversation => {
          if (conversation._id !== id) return true;
        });
        console.log("setting conversations after delete: ", conversations);
        commit("setPrivateMessages", conversations);
      })
      .catch(err => {
        if (isAxiosError(err) && err.response.data) {
          throw err.response.data.message;
        }
        throw err
      });
      //.then(res => {})
      //.catch(err => console.log(err));
  },

  updateMessage({ commit }, postData) {
    return new Promise((resolve, reject) => {
      api
        .updateMessage(postData)
        .then(res => {
          //commit()
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },

  deleteMessage({ commit }, id) {
    console.log("public messages: ", state.conversations);
    return api
      .deleteMessage(id)
      .then(res => {
        /*let conversations = state.conversations.slice(0).map(conversation => {
          conversation.messages = conversation.messages.filter(message => {
            console.log("deleting message", message, id);
            if (message._id !== id) return message;
          });

          return conversation;
        });*/
        //commit("setConversations", conversations);
        console.log("setting messages after delete: ", res);
      })
      .catch(err => console.log(err));
  },

  async likeConversation({commit}, id) {
    try {
      let res = await api.processLike(id, 'conversation');
      if(res && res.data) {
        commit('appendConversationLikes', res.data);
      }

      return res;
    } catch(error) {
      console.log(error);
      throw error;
    }
  },
  async likeConversationMessage({commit}, id) {
    try {
      let res = await api.processLike(id, 'message');
      if(res && res.data) {
        commit('appendConversationMessageLikes', res.data);
      }

      return res;
    } catch(error) {
      console.log(error);
      throw error;
    }
  },
  async newPublicConversationMessage({commit}, params) {
    return api.newMessage(params).then(res => {
        commit('appendPublicConversationMessage', res.data);
      }).catch(err => {
        if (isAxiosError(err) && err.response.data) {
          throw err.response.data.message;
        }
        throw err
      });
  },
  async fetchSubMessages({ commit }, params = {}) {
    try {
      let res = await api.fetchConversation(params.conversation, { parent: params.parent ? params.parent : null });
      commit("setConversationSubMessages", { params, data: res.data });

      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async subscribeNotifications({ commit }, payload = {}) {
    try {
      let response = await api.subscribeNotifications(payload.id, payload.data);
      commit("setSubscribedNotifications", payload);
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  setPrivateMessages: (state, res) => (state.privateMessages = res),
  setPublicMessages: (state, res) => (state.publicMessages = res),
  unshiftPublicConversations: (state, res) => state.publicMessages.unshift(res),
  appendPublicConversations: (state, res) => {
    state.publicMessages = state.publicMessages.concat(res);
  },
  setTargetUsers: (state, res) => (state.targetUsers = res),
  setConversations: (state, res) => (state.conversations = res),
  setConversationMessages: (state, res) => {
    console.log("SET CONVERSATION MESSAGES", res);
    state.conversations = res
  },
  appendConversationMessages: (state, res) => {
    if(Array.isArray(res.messages)) { 
      state.conversations = state.conversations.map( (c) => {
        if (c.conversationId === res.conversationId) {
          //c.messages.unshift(res.messages);
            res.messages.map(m => {
              c.messages.unshift(m);
              return m;
            });
        }
          
        return c;
      });
    }
  },
  setAreaMessages: (state, res) => (state.areaMessages = res),
  unshiftAreaConversations: (state, res) => state.areaMessages.unshift(res),
  appendAreaMessages: (state, res) => state.areaMessages = state.areaMessages.concat(res),
  resetCommunityModule: (state, res) => {
    state.privateMessages = [];
    state.publicMessages = [];
    state.conversations = [];
    state.targetUsers = [];
    state.areaMessages = [];
  },
  appendConversationLikes: (state, res) => {
    if(state.publicMessages && state.publicMessages.length) {
      state.publicMessages = state.publicMessages.map(c => {
        if(c._id === res.id) {
          c.likes = res.likes;
        }
        return c;
      })
    }
    
    if(state.conversations && state.conversations.length) {
      state.conversations.map((c,k) => {
        if(c.conversationId === res.id) {
          state.conversations[k].likes = res.likes;
        }
        return c;
      })
    }

    if(state.areaMessages && state.areaMessages.length) {
      state.areaMessages = state.areaMessages.map(c => {
        if(c._id === res.id) {
          c.likes = res.likes;
        }
        return c;
      })
    }
  },
  appendConversationMessageLikes: (state, res) => {
    state.conversations = state.conversations.map(c => {
      if(Array.isArray(c.messages)) {
        c.messages = c.messages.map(m => {
          if(m._id == res.id) {
            m.likes = res.likes;
          }
          return m;
        });
      }
      //c.likes = res.likes;
      
      return c;
    });
  },
  appendPublicConversationMessage: (state, res) => {
    if(state.conversations.length) {
      state.conversations.map((c,k) => {
        if(c.conversationId === res.conversation) {
          state.conversations.messageCount++;
          if(!res.parentMessage)
            c.messages.push(res)
          else {
            c.messages = c.messages.map(m => {
              if (m && m._id === res._id) {
                m.childMessageCount++;
              }
              return m;
            });
          }
        }
        return c;
      });
    }
  },
  setConversationSubMessages: (state, res) => {
    if (!res.params || !res.params.conversation || !res.params.parent)
      return;
    
    state.conversations = state.conversations.map(c => {
      if(c.conversationId === res.params.conversation && Array.isArray(c.messages)) {
        c.messages = c.messages.map(m => {
          if(m && m._id === res.params.parent) {
            m.messages = res.data;
            m.childMessageCount = res.data.length;  //update count
          }
          return m;
        });
      }
      
      return c;
    });
  },
  editConversationMessage: (state, conversation) => {
    if (conversation && conversation._id && conversation.newestMessage && conversation.newestMessage.body) {
      state.publicMessages = state.publicMessages.map(c => {
        if (c._id === conversation._id) {
          if (c.newestMessage)
            c.newestMessage.body = conversation.newestMessage.body;
        }

        return c;
      });
    }
  },
  setSubscribedNotifications: (state, payload) => {
    if (Array.isArray(state.publicMessages)) {
      state.publicMessages = state.publicMessages.map(m => {
        if (m._id === payload.id) {
          m.subscribedNotifications = payload.data.subscribed;
        }
        return m;
      });
    }
  }
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
