<template>
    <div class="tooltip-container" :class="{'tooltip-container--white': color == 'white'}" @mouseover="handleMouseOver" @mouseout="hanldeMouseOut">
        <button type="button" @focusin="handleMouseOver" @focusout="hanldeMouseOut">
            <i class="fas fa-question-circle" v-if="color == 'white'"></i>
            <i class="far fa-question-circle" v-else></i>
        </button>
        <transition name="fadefast">
            <div :class="getCssClasses" v-if="isActive" role="tooltip"> 
                <div class="arrow"></div> 
                <div class="tooltip-inner">
                    {{message}}
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Tooltip",
    props: {
        message: {
            type: String
        },
        color: {
            type: String
        },
        position: {
            type: String,
            default: 'top'
        }
    },
    data() {
        return {
            isActive: false
        }
    },
    computed: {
        getCssClasses() {
            let classes = ['tooltip'];
            classes.push('tooltip--'+this.position)
            
            if(this.color)
                classes.push('tooltip--'+String(this.color))
            return classes;
        }
    },
    methods: {
        handleMouseOver() {
            this.isActive=true;
            setTimeout(() => {
                const elem = this.$el.querySelector('.tooltip');
                if(elem) {
                    this.fixElementPosition(elem);
                }
            }, 1);
        },
        hanldeMouseOut() {
            this.isActive=false;
            const elem = this.$el.querySelector('.tooltip');
            if(elem) {
                setTimeout(() => {
                    elem.style.marginLeft = 0;
                    elem.style.marginTop = 0;
                }, 200);
            }
        },
        fixElementPosition(elem) {
            const rect = elem.getBoundingClientRect();
            if(rect.x < 0) {
                elem.style.marginLeft = Math.abs(rect.x) + 10 + 'px';
            }
            //+60 because of fixed header
            if(rect.y < 60) {
                elem.style.marginTop = Math.abs(rect.y) + 70 + 'px';
            }
        }
    }
}
</script>