<template>
  <PageWrapperMain>
    <PageSection title="Icons">
      <h2>Inline, small</h2>
      <div class="d-flex">
        <LoaderIcon />
        <LoaderIcon loaderStyle="rect" />
      </div>
      <h2>Block, small</h2>
      <LoaderIcon displayType="block" />
      <LoaderIcon loaderStyle="rect" displayType="block" />
      <h2>Block, medium</h2>
      <LoaderIcon displayType="block" size="medium" />
      <LoaderIcon loaderStyle="rect" displayType="block" size="medium" />
      <h2>Block, large</h2>
      <LoaderIcon displayType="block" size="large" />
      <LoaderIcon loaderStyle="rect" displayType="block" size="large" />
    </PageSection>

    <PageSection :unstyled="true">
      <template v-slot:header>
        <h2>Article lists</h2>
      </template>
      <ArticleListItem
        title="Testi itemi"
        url="http://www.test.fi"
        thumbnailSrc="https://assets-eu-01.kc-usercontent.com:443/be0ec1ba-ff00-0111-cff1-a4da6a93bcef/347d4083-4292-4914-93fb-08c794678092/cam1f.jpg"
        :sticky="true"
        description="This is one hell of a description"
        :date="new Date(2019,10,10)"
        :useKenticoThumbnailer="true"
        mode="box"
      />

      <ArticleListItem
        title="Testi itemi"
        url="http://www.test.fi"
        thumbnailSrc="https://assets-eu-01.kc-usercontent.com:443/be0ec1ba-ff00-0111-cff1-a4da6a93bcef/347d4083-4292-4914-93fb-08c794678092/cam1f.jpg"
        thumbnailSize="large"
        :sticky="true"
        description="This is one hell of a description. Which actually can be very long so it will reach the second line ..well, hopefully."
        :date="new Date(2019,10,10)"
        mode="box"
        ctaButtonText="Read more"
      />

      <div class="row">
        <div class="col-md-6">
          <ArticleListItem
            title="Testi itemi"
            url="http://www.test.fi"
            thumbnailSrc="https://assets-eu-01.kc-usercontent.com:443/be0ec1ba-ff00-0111-cff1-a4da6a93bcef/347d4083-4292-4914-93fb-08c794678092/cam1f.jpg"
            thumbnailSize="large"
            :date="new Date(2019,10,10)"
            mode="box"
            ctaButtonText="Read more"
          />
        </div>
        <div class="col-md-6">
          <ArticleListItem
            title="Testi itemi"
            url="http://www.test.fi"
            thumbnailSrc="https://assets-eu-01.kc-usercontent.com:443/be0ec1ba-ff00-0111-cff1-a4da6a93bcef/347d4083-4292-4914-93fb-08c794678092/cam1f.jpg"
            thumbnailSize="large"
            headline="Custom headline"
            mode="box"
            ctaButtonText="Read more"
          />
        </div>
      </div>

      <div class="bg--white">
        <ArticleListItem
          title="Testi itemi"
          url="http://www.test.fi"
          thumbnailSrc="https://assets-eu-01.kc-usercontent.com:443/be0ec1ba-ff00-0111-cff1-a4da6a93bcef/347d4083-4292-4914-93fb-08c794678092/cam1f.jpg"
          :sticky="true"
          :date="new Date(2019,10,10)"
          :useKenticoThumbnailer="true"
          mode="list"
        />
        <ArticleListItem
          title="Testi itemi"
          url="http://www.test.fi"
          thumbnailSrc="https://assets-eu-01.kc-usercontent.com:443/be0ec1ba-ff00-0111-cff1-a4da6a93bcef/347d4083-4292-4914-93fb-08c794678092/cam1f.jpg"
          :date="new Date(2019,10,10)"
          :useKenticoThumbnailer="true"
          mode="list"
        />
        <ArticleListItem
          title="Testi itemi"
          url="http://www.test.fi"
          thumbnailSrc="https://assets-eu-01.kc-usercontent.com:443/be0ec1ba-ff00-0111-cff1-a4da6a93bcef/347d4083-4292-4914-93fb-08c794678092/cam1f.jpg"
          :date="new Date(2019,10,10)"
          :useKenticoThumbnailer="true"
          mode="list"
        />
      </div>
    </PageSection>

    <PageSection :unstyled="true">
      <template v-slot:header>
        <h2>Event lists</h2>
      </template>
      <EventListItem
        title="Testi itemi"
        url="http://www.test.fi"
        color="orange"
        :startTime="new Date()"
        :endTime="new Date()"
      />

      <div class="row">
        <div class="col-md-6">
          <EventListItem
            title="Testi itemi"
            url="http://www.test.fi"
            color="blue-medium"
            :startTime="new Date('2019,11,10')"
            :endTime="new Date('2019,11,15')"
          />
        </div>
        <div class="col-md-6">
          <EventListItem
            title="Testi itemi"
            url="http://www.test.fi"
            color="green"
            :startTime="new Date('2019,11,10')"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <EventListItem
            title="Testi itemi"
            url="http://www.test.fi"
            color="blue-medium"
            icon="far fa-calendar-times"
          />
        </div>
        <div class="col-md-6">
          <EventListItem
            title="Testi itemi"
            url="http://www.test.fi"
            color="green"
            :startTime="new Date('2019,11,10')"
            :disabled="true"
          />
        </div>
      </div>
    </PageSection>

    <PageSection :unstyled="true">
      <template v-slot:header>
        <h2>Simple list of items</h2>
      </template>
      <div class="page-section__body padding--no">
        <ul class="item-list">
          <li>
            <span>
              <span>List item with no link</span>
              <i class="arrow-link far fa-chevron-right arrow-icon"></i>
            </span>
          </li>
          <li>
            <span>List item with no link and icon</span>
          </li>
          <li>
            <a href="#">
              <span>List item with link and icon</span>
              <i class="arrow-link far fa-chevron-right arrow-icon"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <span>List item with link and icon</span>
              <i class="arrow-link far fa-chevron-right arrow-icon"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <span>List item with link and icon</span>
              <i class="arrow-link far fa-chevron-right arrow-icon"></i>
            </a>
          </li>
        </ul>
      </div>
    </PageSection>

    <PageSection :unstyled="true">
      <template v-slot:header>
        <h2>Links</h2>
      </template>
      <QuickLink href="#" iconCssClass="fal fa-info-circle">Link with icon</QuickLink>
      <QuickLink href="#">Link without icon</QuickLink>
      <QuickLink
        href="#"
        color="green"
        iconCssClass="fal fa-info-circle"
      >Link with green icon background</QuickLink>
    </PageSection>
  </PageWrapperMain>
</template>

<script>
// @ is an alias to /src
import PageWrapperMain from "@/components/common/PageWrapperMain";
import ArticleListItem from "@/components/common/Lists/Article";
import EventListItem from "@/components/common/Lists/Event";
import LoaderIcon from "@/components/common/LoaderIcon";
import QuickLink from "@/components/common/QuickLink";
import PageSection from "@/components/common/PageSection";

export default {
  name: "dashboard",
  components: {
    PageWrapperMain,
    ArticleListItem,
    EventListItem,
    LoaderIcon,
    QuickLink,
    PageSection
  }
};
</script>
