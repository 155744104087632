export default class BusinessPartnerModel {
  constructor(user) {
    this.id = null;
    this.logoUrl = "";
    this.imageUrl = "";
    this.name = "";
    this.rating = 0;
    this.ratingsCount = 0;
    this.businessId = "";
    this.externalId = null;
    this.country = "";
    this.email = "";
    this.phone = "";
    this.source = "";
    this.mappedTo = null;
  }

  getPartnerPageUrl() {
    if (this.source == 'liferay')
      return { name: '/partner/:id', params: { id: this.id } };
    else if (this.source == 'azure')
      return { name: '/service-shop/partner/:id', params: { id: this.id } };
  }

  static fromLiferayPartner(data) {
    let partner = new BusinessPartnerModel();
    partner.id = data.id;
    if(data.logoUrl)
      partner.logoUrl = process.env.VUE_APP_LIFERAY_API_BASE_URL + data.logoUrl;
    if(data.imageUrl)
      partner.imageUrl = process.env.VUE_APP_LIFERAY_API_BASE_URL + data.imageUrl;
    partner.name = data.name;
    partner.rating = data.rating;
    partner.ratingsCount = data.ratingsCount;
    partner.businessId = data.vatCode;
    partner.source = 'liferay';

    return partner;
  }

  static fromAzurePartner(data) {
    let partner = new BusinessPartnerModel();
    partner.id = data._id;
    partner.businessId = data.businessId;
    partner.name = data.name;
    partner.country = data.country;
    partner.email = data.email;
    if(data.logo && data.logo.fileUrl)
      partner.logoUrl = data.logo.fileUrl;
    if(data.featuredImage && data.featuredImage.fileUrl)
      partner.imageUrl = data.featuredImage.fileUrl;
    //partner.rating = data.rating;
    partner.source = 'azure';

    return partner;
  }
}

/*
companyRoles: ["VENDOR", "ADVERTISER"]
company_ID: "5089304074256384"
country: "FIN"
created: "2019-09-03T12:48:34.870Z"
email: "monika.huckmann@yit.pl"
name: "YIT Plus"
phoneNumber: "884200589"
picture: "https://lh3.googleusercontent.com/Pb51L5hfDHFbEPijbeYg-nNfiDZmW3SkN2JXYG5Ps5au2y8gMJhLTEVu7bJ-sKhP06JD08DKmiQwOr3dPuvIcR7TI6c"
rating: 0
removed: false
updated: "2019-10-11T18:19:48.364Z"
vatID: "12345678"

id: 134504
logoUrl: "/image/journal/article?img_id=227138&t=1597737417952"
name: "Liferay corporation"
rating: 0
ratingsCount: 0
uuid: "0032f671-f433-48c0-a33d-00c03db4ad5c"
vatCode: "123456-7"*/