import he from "he";

export function removeExtensionFromfilename(filename) {
  return filename.replace(/\.[^/.]+$/, "");
}

export function getAlphanumericString(val) {
  return val.replace(/[^0-9a-zA-Z/.]/g, "");
}

export function getParmFromHash(url, parm) {
  // assumes that parm doesn't contain any regex characters
  var re = new RegExp("#.*" + parm + "=([^&]+)(&|$)");
  var match = url.match(re);
  return match ? match[1] : "";
}

export function truncate(string, n, useWordBoundary) {
  if (string.length <= n) {
    return string;
  }
  var subString = string.substr(0, n - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}

export function getQueryVariable(variable, _url = "") {
  let url = _url ? String(_url) : window.location.search;
  if (url.includes("?"))
    url = url.split("?")[1];
  
  var vars = url.split("&");
  console.log("STRING UTILS: ", vars);
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log("Query variable %s not found", variable);
}

export function getURLParam(location, name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const stripTrailingSlash = str => {
  if (typeof str !== "string") return str;
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

export const stripLeadingSlash = str => {
  if (typeof str !== "string") return str;
  return str.startsWith("/") ? str.substr(1) : str;
};


export const alphabetPosition = (text) => {
  var result = "";
  for (var i = 0; i < text.length; i++) {
      var code = text.toUpperCase().charCodeAt(i)
      if (code > 64 && code < 91) result += (code - 64) + " ";
  }
  
  return result.slice(0, result.length - 1);
}
  
export const colorByString = string => {

  const colors = ['#e98300', '#db4d69', '#009fda', '#294754', '#3f9c36'];

  if(!string || string.length < 2)
    return;

  let firstLetter = string.charAt(0);
  let secondLetter = string.charAt(1);
  if(firstLetter && secondLetter) {
      let orderNumber = parseInt(alphabetPosition(firstLetter)) + parseInt(alphabetPosition(secondLetter));
      let modulo = orderNumber % (colors.length-1);
      if(typeof colors[modulo] !== 'undefined')
      return colors[modulo];
    
  } 
};

/**
 * Sanitize user text field input
 * - Fixes the issue with Azure Gateway sql injection rule to support dashes in payloads
 * @param {*} str 
 */
export const sanitizeTextInput = str => {
  if (str && typeof str === 'string') {
    //str = he.encode(str, {useNamedReferences: false });
    str = str.replace(/-/g, "&hyphen;");
  }
  return str;
}

export const disableEmojis = (str) => {
  return str.replace( new RegExp('(?:'+ "[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c\ude32-\ude3a]|[\ud83c\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]"+')'), "");
}

/**
 * 
 * @param {*} str 
 * @returns 
 */
export const isHTML = (str) => {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * 
 * @param {*} length length
 * @returns 
 */
export const generateRandomString = (length = 5) => {
  return Math.random().toString(36).substring(0,length);
}

/**
 * 
 * @param {string} str - String to replace variables
 * @param {object} map - Variable object
 * @returns 
 */
export const replaceVariables = (str, map) => {
  return str.replace(/{{(.+?)}}/g, (_, g1) => {
      let parts = String(g1).split(".");
      if (parts.length == 1)
          return map[g1] || g1
      if(parts.length == 2)
          return map[parts[0]][parts[1]] || g1;
      if(parts.length == 3)
          return map[parts[0]][parts[1]][parts[2]] || g1;

      return map[g1] || g1
  })
}

/**
 * @param {string} str - String
 * @param {number} validLength - Length
 * @returns 
 */
export function getReformString(str, validLength = 80) {
  return String(str).length > validLength ? String(str).substring(0,validLength)+'...' : str;
}