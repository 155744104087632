<template>
  <div class v-if="$can('create', 'message')">
    <form @submit="onSubmit" @keydown="onKeyDown">
      <Toast type="success" ref="succcessMessage">{{$t('community.commentAdded')}}</Toast>
      <Toast type="error" ref="errorMessage">{{errorMessage}}</Toast>
      <CommunityMessageForm>
        <FormInput type="textarea" :placeholder="$t('community.conversation.reply.placeholder')" name="message" rows="5" v-model="form.message" ref="textareaStyled" />
        <template v-slot:footer v-if="canAttachImages">
          <AttachImages @on-images-selected="onImagesSelected" :loading="uploadingImages" />
        </template>
      </CommunityMessageForm>
      <template v-if="canAttachImages">
        <CommunityImageGrid :images="form.images" :canRemove="true" @on-remove="onImageRemove" />
      </template>

      <SignatureSelect :targetMessage="form.message" @on-select="onSignatureSelect" :html="false" />

      <div class="button-group text-center">
        <Button
          type="submit"
          value="submit"
          class="button button--primary"
          :loading="sending"
        >{{$t('common.button.sendmessage')}}</Button>
        <button
          type="button"
          @click="$emit('on-cancel')"
          value="submit"
          class="button button--link"
        >{{$t('common.cancel')}}</button>
      </div>
    </form>
  </div>
</template>

<script>
import Form from "@/utils/FormUtil";
import FormInput from "@/components/common/Form/Input";
import CommunityService from "@/services/CommunityService";
import { mapGetters, mapActions, mapState } from "vuex";
import Toast from "@/components/common/Toast";
import AttachImages from "@/components/Community/AttachImages";
import AzureDocumentService from "@/services/AzureDocumentService";
import { checkUploadedImage } from "@/utils/FileUtil";
import CommunityImageGrid from "@/components/Community/ImageGrid";
import settings from "@/globals";
import CommunityMessageForm from "./CommunityMessageForm";
import SignatureSelect from "@/components/Profile/Signature/Select";

export default {
  name: "NewConversationForm",
  props: {
    conversation: Object,
    parent: Object,
    canAttachImages: {
      type: Boolean,
      default: false
    },
    buildingTaxonomy: {type: String}
  },
  components: { FormInput, Toast,  AttachImages, CommunityImageGrid, CommunityMessageForm, SignatureSelect },
  data() {
    return {
      form: new Form(
        {
          message: "",
          title: "",
          firstName: "",
          apartment: "",
          images: []
        },
        {
          message: { required: true }
        }
      ),
      recipients: [],
      errorMessage: "",
      successMessage: "",
      api: new CommunityService(),
      documentApi: new AzureDocumentService(),
      uploadingImages: false,
      blobStorageTempFolder: settings.community.attachmentsTempFolder,
      sending: false
    };
  },
  computed: {
    ...mapGetters([
      "getTargetUsers",
      "getSelectedApartment",
      "getUserDetails",
      "getCurrentUser",
      "getSelectedHousingCompany"
    ]),
    showRecipientForm: function() {
      return this.recipients.length == 0;
    }
  },
  methods: {
    ...mapActions(["fetchTargetUsers", "newPublicConversationMessage"]),
    recipientsChosen(recipients) {
      this.recipients = recipients;
    },
    onSubmit(e) {

      e.preventDefault();

      if (this.form.validator.validate()) {

        let postData = {
          conversationId: this.conversation.conversationId,
          message: this.form.message,
          title: "",
          projectId: this.getSelectedHousingCompany.hcExt,
          firstName: this.getUserDetails.firstName
        };

        if(this.getSelectedApartment && this.getSelectedApartment.aptext) {
          postData.apartmentId = this.getSelectedApartment.aptext;
        }

        if(Array.isArray(this.form.images) && this.form.images.length) {
          postData.images = this.form.images;
        }

        if(this.parent)
          postData.parentMessage = this.parent._id;

        this.sending = true;

        this.form.saveLocalCopy();

        this.newPublicConversationMessage(postData)
          .then(res => {
            console.log("Comment posted succesfully! ", res);
            this.form.images = [];
            this.form.message = "";
            //this.$refs.textareaStyled.resetContentEditableField();
            this.successMessage = "Comment added";
            this.$refs.succcessMessage.show();
            this.sending = false;
            this.form.removeLocalCopy();
            this.$emit("response-posted");
          })
          .catch(err => {
            console.log(err);
            this.errorMessage = err;
            this.sending = false;
            this.$refs.errorMessage.show();
          });

        setTimeout(() => {}, 2000);
      } else {
        this.errorMessage =
          "Error on validating the form. Please check you have entered value on each required field.";
      }
    },
    onKeyDown(e) {
      this.errorMessage = "";
      this.form.validator.clear(e.target.name);
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.$refs.errorMessage.show();
    },
    onImageRemove(image = null) {
      if(!image)
        return;
      //remove image first from array
      this.form.images = this.form.images.filter(img => {
        if(img.name !== image.name)
          return img;
      });

      //then actually send api call to backend too
      this.documentApi.deleteDocument(image._id);
    },
    onImagesSelected(e) {
      let files = Array.from(e.target.files);
      let createDate = new Date();
      console.log("selected images: ", files);

      if (files.length) {
        this.uploadingImages = true;
        files.map(file => {
          let isValid = checkUploadedImage(file);
          if(!isValid.error) {
            this.documentApi
              .addDocument({file, folder: this.blobStorageTempFolder})
              .then(response => {
                this.uploadingImages = false;
                console.log("Posting attachment was successful:", response);
                if(response.data && response.data.file)
                  this.form.images.push(response.data.file);
              })
              .catch(err => {
                this.uploadingImages = false;
                console.log("Posting attachment failed: ", err);
                this.showErrorMessage(this.$t('common.messages.general-image-validation-failed-message'));
              });
          } else {
            this.showErrorMessage(this.$t('common.messages.general-image-validation-failed-message'));
          }
        });
      }
      e.target.value = "";
    },
    onSignatureSelect(newValue) {
      this.form.message = newValue;
    }
  },
  mounted() {
    this.form.getLocalCopy();
  }
};
</script>

<style lang="scss" scoped>
</style>
