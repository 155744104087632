export default [
  {
    path: "/project-camera",
    name: "/project-camera",
    component: () =>
      import(
        /* webpackChunkName: "project-camera" */ "@/views/Apartment/ProjectCamera.vue"
      ),
    meta: {
      sectionTitle: "navigation.project-camera",
      title: "navigation.project-camera"
    }
  }
];