import store from "@/store";
import { replaceVariables } from "@/utils/StringUtils";
import filters from "@/filters";

const KOTIPOLKU_USER_REGISTERED_STEP_CODENAME = 'kotipolku_registered_to_plus_date';
const STEP_INTRODUCTION_MAX_LENGTH = 250;

export default class ProjectStep {
  constructor() {
    this.id = null;
    this.codename = null;
    this.title = "";
    this.introduction = "";
    this.content = "";
    this.date_start = null;
    this.parent = null;
    this.images = [];
    this.related = [];
    this.parentRaw = [];
    this.buildingTaxonomy = [];
    this.active = false;
    this.isNew = false;
    this.isUpdated = false;
    this.disable_configuring = false;
  }

  /**
   * 
   * @param {*} project 
   * @returns 
   */
  replaceVariables(project = null) {
    if (!project)
      project = store.getters.getSelectedHousingCompany;
    
    if (!project)
      return;
    
    let variableMap = {
      project: {
        completionDate: `<strong>${filters.date_format_numbers(project.completionDate)}</strong>`,
        finalizationDate: `<strong>${filters.date_format_numbers(project.finalizationDate)}</strong>`,
      }
    };

    if (this.introduction) {
      this.introduction = replaceVariables(this.introduction, variableMap);
    }
    if (this.content) {
      this.content = replaceVariables(this.content, variableMap);
    }
  }

  /**
   * Build from Kentico model
   * @param {*} article
   * @param {Object} modular_ontent
   * @param {Object} args - Arguments used to convert kentico item to projecet step model
   */
  static fromKenticoModel(item, modular_content = {}, args = {}) {
    let step = new ProjectStep();

    if (!item.elements || !item.system) return step;

    step.id = item.system.id;
    step.codename = item.system.codename;
    if (item.elements) {
      step.title = item.elements.title ? item.elements.title.value : "";
      step.introduction = item.elements.introduction ? String(item.elements.introduction.value).substring(0, STEP_INTRODUCTION_MAX_LENGTH) : "";
      step.content = item.elements.content ? item.elements.content.value : "";
      step.date_start = item.elements.date_start
        ? new Date(item.elements.date_start.value)
        : "";
      step.parentRaw = item.elements.parent ? item.elements.parent.value : [];
      if (step.parentRaw.length && modular_content) {
        let parentStep = modular_content[step.parentRaw[0]];
        step.parent = ProjectStep.fromKenticoModel(parentStep);
      }

      if (item.elements.images && item.elements.images.value.length) {
        step.images = item.elements.images.value;
      }

      step.relatedRaw = item.elements.related_content ? item.elements.related_content.value : [];

      if (step.relatedRaw.length && Object.values(modular_content)) {
        step.related = Object.values(modular_content).filter(r => step.relatedRaw.includes(r.system.codename));
      }

      if (item.elements.buildingtaxonomy) {
        item.elements.buildingtaxonomy.value.map((t) => {
          step.buildingTaxonomy.push(t.id);
          return t;
        });
      }

      if (
        item.elements.disable_configuring &&
        item.elements.disable_configuring.value.length > 0
      ) {
        step.disable_configuring = true;

        //override this spesific step date to user register date
        if (
          item.elements.parent && Array.isArray(item.elements.parent.value) && item.elements.parent.value.includes(KOTIPOLKU_USER_REGISTERED_STEP_CODENAME)
        ) {
          step.date_start = store.getters.getCurrentUser.registerDate;
        }
      }

      //Replace introduction from parent if item doesn't have introduction
      if(!step.introduction && step.parent && step.parent.introduction)
        step.introduction = step.parent.introduction;
    }

    //replace possible variables from introduction and content
    if (args.project)
      step.replaceVariables(args.project);

    return step;
  }
}

export {
  ProjectStep,
  KOTIPOLKU_USER_REGISTERED_STEP_CODENAME,
  STEP_INTRODUCTION_MAX_LENGTH
}