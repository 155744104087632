<template>
  <div class="yit-news-list" v-if="items && items.length">
    <div class="news-list--latest row" v-if="mode=='list'">
      <div class="col-md-6" v-for="item in getItems" :key="item.guid">
        <ArticleListItem
          :title="getReformString(item.title, 80)"
          :url="item.link"
          :isExternalLink="true"
          :thumbnailSrc="item.mediaUrl ? item.mediaUrl : ''"
          thumbnailSize="medium"
          :date="formatDate(item.pubDate)"
          :useKenticoThumbnailer="false"
          mode="box"
        />
      </div>
    </div>
    <div class="news-list--archive" v-else>
      <div class="news-list__item" v-for="item in getItems" :key="item.guid">
        <ArticleListItem
          :title="item.title"
          :url="item.link"
          :thumbnailSrc="item.mediaUrl ? item.mediaUrl : ''"
          thumbnailSize="large"
          :ctaButtonText="$t('common.readmore.link')"
          :date="formatDate(item.pubDate)"
          :useKenticoThumbnailer="false"
          :description="item.description ? item.description.substring(0,300) : ''"
          :showArrow="false"
          :isExternalLink="true"
          mode="box"
        >
        </ArticleListItem>
      </div>
    </div>
  </div>
  <div class v-else-if="!loading && !items.length">
    <span class="no-results">{{$t('common.no-items-found')}}</span>
  </div>
  <div class="text-center" v-else-if="loading">
    <LoaderIcon />
  </div>
</template>

<script>
import ArticleListItem from "@/components/common/Lists/Article";
import dayjs from "dayjs";
import {getReformString} from "@/utils/StringUtils";

export default {
    name: "YitCorporateNewsList",
    props: {
      items: {
        type: Array
      },
      mode: {
        type: String,
        default: 'list'
      },
      limit: {
        type: Number,
        default: 4
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    components: {
      ArticleListItem
    },
    computed: {
      getItems() {
        return this.items.slice(0,this.limit);
      }
    },
    methods: {
      formatDate(date) {
        if(!date)
          return new Date();
        let newDate = dayjs(date.replace("Z", ""));
        return new Date(newDate);
      },
      getReformString
    }
}
</script>

<style>

</style>
