import DocumentService from "@/services/AzureDocumentService";
import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import store from "@/store";
import _ from "lodash";
import { compare } from 'natural-orderby';

const api = new DocumentService();
const kenticoApi = new KenticoDeliveryApiService();
kenticoApi.setContentType(["video_list"]);
kenticoApi.targetByDefaultBuildingTaxonomy = true;

const getDefaultState = () => {
  return {
    groupedDocuments: [],
    userDocuments: [],
    homeFolderDocuments: null,
    introductionVideos: [],
    folders: [
      {
        name: "apartment",
        path: "",
        translationKey: "documents.folders.apartment.description",
        folder: "apt_documents",
        keys: [
          "APT_ALL",
          "APT_TYPE",
          "APT_DOCUMENTS",
          "APT_INVOICES",
          "APT_MAINTENANCE"
        ],
        files: [],
        subFolders: [
          {
            name: "owner",
            path: "",
            isSubFolder: true,
            folder: "apt_owner",
            files: [],
            keys: ["APT_OWNER"]
          },
          {
            name: "tenant",
            path: "",
            isSubFolder: true,
            folder: "apt_tenant",
            files: [],
            keys: ["APT_TENANT"]
          }
        ],
        canUpload: false
      },
      {
        name: "project",
        path: "",
        key: "HC_ALL",
        translationKey: "documents.folders.building.description",
        keys: ["HC_ALL", "HC_OWNERS"],
        files: [],
        subFolders: [
          {
            name: "hc_board",
            path: "",
            isSubFolder: true,
            folder: "hc_board",
            files: [],
            keys: ["HC_BOARD"]
          }
        ],
        canUpload: false,
        folder: "hc_all",
      }
    ],
    sortBy: {
      field: 'created',
      direction: 'desc'
    }
  };
};

const state = getDefaultState();

const getFileListCollectionOrdered = (filelist, field = '', direction = '') => {
  if(Array.isArray(filelist)) {
    return filelist.map(list => {
      const newList = list.files.sort((a, b) => compare({ order: direction })(a[field], b[field]))
      return { ...list, files: newList };
    });
  } else if (typeof filelist == 'object') {
    const newFileList = {...filelist}
    Object.keys(newFileList).map(listName => {
      newFileList[listName].files = filelist[listName].files.sort((a, b) => compare({ order: direction })(a[field], b[field]))
      return listName;
    });
    return newFileList;
  }
  return filelist;
}

const getters = {
  getAzureGroupedDocuments: state => getFileListCollectionOrdered(state.groupedDocuments, state.sortBy.field, state.sortBy.direction),
  getAzureAllDocuments: state => state.userDocuments,
  getAzureHomeFolderDocuments: state => getFileListCollectionOrdered(state.homeFolderDocuments, state.sortBy.field, state.sortBy.direction),
  hasAzureHomeFolderContent: state => {
    if(!state.homeFolderDocuments)
      return false;
    
    let ret = false;
    let folders = Object.values(state.homeFolderDocuments);

    if(Array.isArray(folders)) {
      folders.map(folder => {
        if(Array.isArray(folder.files) && folder.files.length) 
          ret = true;
        return folder;
      });
    }

    return ret;
  },
  getFolders: state => {
    if (!state || !state.folders) [];

    let selectedApt = {};
    if (store.getters.getSelectedApartment)
      selectedApt = store.getters.getSelectedApartment;

    //retrieve paths to folders
    let ret = state.folders.map(folder => {
      if (folder.name == "apartment" && store.getters.getSelectedApartment)
        folder.path =
          store.getters.getSelectedHousingCompany.hcExt +
          "/documents/apartments/" +
          selectedApt.aptext +
          "/all";
      else if (folder.name == "project") {
        folder.path =
          store.getters.getSelectedHousingCompany.hcExt + "/documents/all/";
        if (store.getters.getCurrentUser.hasAnyOfRoles(['yitUser', 'propertyManager']) || 
        (store.getters.getSelectedHousingCompany.roles.includes("roleBoardMember") || store.getters.getSelectedHousingCompany.roles.includes("roleChairman") || store.getters.getSelectedHousingCompany.roles.includes("boardMember") || store.getters.getSelectedHousingCompany.roles.includes("chairman") ) ) {
          folder.canUpload = true;
        }
      } else if (folder.name == "owner") {
        folder.path =
          store.getters.getSelectedHousingCompany.hcExt +
          "/documents/apartments/" +
          selectedApt.aptext +
          "/owner";
        if (store.getters.getSelectedHousingCompany.roles.includes("roleOwner") || store.getters.getSelectedHousingCompany.roles.includes("owner") ) {
          folder.canUpload = true;
        }
      }

      //reset the files array
      folder.files = [];

      return folder;
    });

    return ret;
  },
  getIntroductionVideos: state => state.introductionVideos,
  getSortingVariables: state => state.sortBy
};

const actions = {
  fetchAzureDocuments({ commit }, params) {
    commit("resetGroupedDocuments", null);

    return api
      .fetchDocuments()
      .then(response => {
        //Map response files into grouped folders
        let groupedDocuments = store.getters.getFolders;
        response.data.files.map(folder => {
          groupedDocuments = groupedDocuments.map(group => {
            if (group.keys.includes(folder.key)) {
              group.files = sortItemsByDate(group.files.concat(folder.files.filter(f => f.homeFolder !== true)));
            }
            if (Array.isArray(group.subFolders)) {
              group.subFolders = group.subFolders.map(subFolder => {
                if (subFolder.keys.includes(folder.key)) {
                  subFolder.files = sortItemsByDate(subFolder.files.concat(folder.files.filter(f => f.homeFolder !== true)));
                }
                return subFolder;
              });
            }

            return group;
          });
        });

        console.log("groupedDocuments: ", groupedDocuments);

        commit("setAzureGroupedDocuments", groupedDocuments);

        if (response.data.allFiles && response.data.allFiles.length)
          response.data.allFiles = sortItemsByDate(response.data.allFiles);

        if(response.data && response.data.allFiles)
          commit("setAzureAllDocuments", response.data.allFiles);
        
        if(response.data && response.data.homeFolder)
          commit("setAzureHomeFolderDocuments", response.data.homeFolder);
      
      })
      .catch(err => console.log("Fetching documents failed: ", err));
  },
  fetchIntroductionVideos({commit}, params = {}) {
    params = {
      order: "none"
    };
    return kenticoApi.fetchList(params).then(response => {
      console.log("Fetched videos: ", response.data.items, params);
      let items = [];
      response.data.items.map(item => {
        if(item.elements && item.elements.videos && item.elements.videos.value) {
          item.elements.videos.value = item.elements.videos.value.map(videoCode => {
            if(response.data.modular_content && response.data.modular_content.hasOwnProperty(videoCode))
            items.push(response.data.modular_content[videoCode]);
          });
        }
        return item;
      });

      commit("setIntroductionVideos", items);

    }).catch(error => {
      console.log(error);
      commit("setNews", []);
    });
  }
};

const mutations = {
  setAzureGroupedDocuments: (state, res) => (state.groupedDocuments = res),
  setAzureAllDocuments: (state, res) => (state.userDocuments = res),
  resetGroupedDocuments: (state, res) => {
    state.folders = getDefaultState().folders;
  },
  setAzureHomeFolderDocuments: (state, res) => (state.homeFolderDocuments = res),
  setIntroductionVideos: (state, res) => (state.introductionVideos = res),
  setSortingVariables: (state, res) => (state.sortBy = res)
};

const sortItemsByDate = function(items) {
  items.sort((a, b) => {
    return new Date(b.created) - new Date(a.created);
  });
  return items;
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
