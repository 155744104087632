import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";

import _ from "lodash";
import dayjs from "dayjs";

//Initialize kentico api
const kenticoApi = new KenticoDeliveryApiService();
kenticoApi.targetByDefaultBuildingTaxonomy = true;
kenticoApi.setContentType("event");

let today = new Date();
today.setHours(23);
today.setMinutes(59);
today.setSeconds(59);
const todayAtEndString = dayjs(today).format("YYYY-MM-DD");

const getDefaultState = () => {
  return {
    calendarEntries: [],
    latestCalendarEntries: [],
    latestEvents: [],
    latestDeadlines: [],
    currentCalendarEntry: null
  };
};

const state = getDefaultState();

const getters = {
  getAllCalendarEntries: state => state.calendarEntries,
  getLatestCalendarEntries: state => state.latestCalendarEntries,
  getLatestCalendarEvents: state => state.latestEvents,
  getLatestCalendarDeadlines: state => {
    return state.latestCalendarEntries.filter(item => {
      let ret = false;
      if (item.elements && item.elements.type) {
        item.elements.type.value.map(type => {
          if (type.codename == "deadline") ret = true;
        });
      }
      if (ret) return true;

      return false;
    });
  },
  getCurrentCalendarEntry: state => state.currentCalendarEntry
};

const actions = {
  fetchCalendarEntries({ commit }, params = {}) {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    let paramsDefault = setDefaults();

    params = _.merge(paramsDefault, params);
    if (!params.limit) params.limit = 100;

    if (params.start_time)
      kenticoApi.addFilter("start_time", params.start_time, "gte");

    if (params.end_time)
      kenticoApi.addFilter("end_time", params.end_time, "lte");

    return new Promise(function(resolve, reject) {
      kenticoApi
        .fetchList(params)
        .then(response => {
          console.log("Fetching calendar entries: ", response.data, params);
          if (response.data.items.length) {
            if (params.append)
              commit("appendCalendarEntries", response.data.items);
            else commit("setCalendarEntries", response.data.items);
          }
          resolve(response.data.items);
        })
        .catch(err => {
          console.log("Fetching events failed: ", err);
          reject(err);
        });
    });
  },
  fetchLatestCalendarEntries({ commit }, params = {}) {
    let paramsDefault = setDefaults();
    params = _.merge(paramsDefault, params);
    kenticoApi.addFilter("end_time", todayAtEndString, "gte");

    return new Promise((resolve, reject) => {
      kenticoApi
        .fetchList(params)
        .then(response => {
          /* filter out deadlines */
          commit("setLatestCalendarEntries", response.data.items);
          resolve(response.data.items);
        })
        .catch(err => {
          console.log("Fetching events failed: ", err);
          reject(err);
        });
    });
  },
  /*fetchLatestCalendarEvents({ commit }, params = {}) {
    let paramsDefault = setDefaults();
    params = _.merge(paramsDefault, params);
    kenticoApi.addFilter("type", "event", "contains");
    kenticoApi.addFilter("end_time", todayAtEndString, "gte");

    return new Promise((resolve, reject) => {
      return kenticoApi
        .fetchList(params)
        .then(response => {
          commit("setLatesCalendartEvents", response.data.items);
          resolve(response.data.items);
        })
        .catch(err => {
          console.log("Fetching events failed: ", err);
        });
    });
  },
  fetchLatestCalendarDeadlines({ commit }, params = {}) {
    let paramsDefault = setDefaults();
    params = _.merge(paramsDefault, params);
    kenticoApi.addFilter("type", "deadline", "contains");
    kenticoApi.addFilter("start_time", todayAtEndString, "gte");

    return new Promise((resolve, reject) => {
      return kenticoApi
        .fetchList(params)
        .then(response => {
          commit("setLatesCalendarDeadlines", response.data.items);
          resolve(response.data.items);
        })
        .catch(err => {
          console.log("Fetching events failed: ", err);
          reject(err);
        });
    });
  },*/
  fetchSingleCalendarEntry({ commit }, codename) {
    return new Promise((resolve, reject) => {
      return kenticoApi
        .fetchSingle(codename)
        .then(response => {
          //console.log("Fetched single calendar entry: ", response.data);
          if (response.data.item)
            commit("setCurrentCalendarEntry", response.data.item);

          resolve(response.data.item);
        })
        .catch(err => reject(err));
    });
  }
};

const mutations = {
  setCalendarEntries: (state, res) => (state.calendarEntries = res),
  setLatestCalendarEntries: (state, res) => (state.latestCalendarEntries = res),
  setLatesCalendartEvents: (state, res) => (state.latestEvents = res),
  setLatesCalendarDeadlines: (state, res) => (state.latestDeadlines = res),
  appendCalendarEntries: (state, res) =>
    (state.calendarEntries = state.calendarEntries.concat(res)),
  setCurrentCalendarEntry: (state, res) => (state.currentCalendarEntry = res),
  resetCalendarData: (state, res) => {
    state.calendarEntries = [];
    state.latestCalendarEntries = [];
    state.latestEvents = [];
    state.latestDeadlines = [];
    state.currentCalendarEntry = {};
  }
};

/**
 * Reset filters and set defaults before every api call
 */
const setDefaults = () => {
  kenticoApi.resetFilters();

  let today = new Date();
  kenticoApi.addFilter(
    "scheduled_time",
    dayjs(today).format("YYYY-MM-DDTHH:mm"),
    "lte"
  );

  return {
    order: "start_time",
    orderDirection: "asc",
    limit: 10
  };
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
