<template>
  <div class="quick-link-editor-wrapper">
    <div class="quick-link-editor">
      <Button type="button" mode="unstyled" class="close-editor" @click="$emit('on-close')">
        <i class="far fa-times"></i> 
        <span>{{$t('common.closeButton')}}</span>
      </Button>
      <div class="quick-links">
        <div class="quick-links__list">
          <p class="no-results" v-if="links.length === 0 && !addNewLink">
            {{ $t('user.quickLinks.noLinksSelected') }}
            <a href="#" @click="onClickAddNew">{{ $t('user.quickLinks.selectFirstLink') }}</a>
          </p>          
          <draggable group="links" v-model="links" @start="drag=true" @end="drag=false" @sort="afterDrag">
            <QuickLinkItem v-for="link in links" 
              :key="link.moduleId"
              :item="link"
              @on-remove="onRemove"
              @on-after-drag="afterDrag"
            />
          </draggable>
        </div>
      </div>
      <LoaderIcon v-if="adding" />
      <Button type="button" mode="link" @click="onClickAddNew" v-if="!addNewLink && !adding && links.length < 5">
        <i class="fal fa-plus"></i>
        <span>{{$t('common.add')}}</span>
      </Button>
      <QuickLinkForm v-if="addNewLink" @on-add="onAdd" @on-cancel="addNewLink=false" />
      <div class="text-center">
        <Button type="button" mode="primary" @click="onSave" v-if="displaySaveButton">
          {{$t('common.save-changes')}}
        </Button>
      </div>
    </div>
    <div class="site-overlay"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import QuickLinkForm from './Form';
import QuickLinkItem from "./Item";
import { VueDraggableNext } from 'vue-draggable-next'
import _ from "lodash";

export default {
  name:"QuickLinkEditor",
  components: {QuickLinkForm, QuickLinkItem, draggable: VueDraggableNext},
  data() {
    return {
      drag: false,
      addNewLink: false,
      links: [],
      adding: false,
      displaySaveButton: false
    }
  },
  computed: {
    ...mapGetters(['getSelectedHousingCompany', 'getUserQuickLinks'])
  },
  methods: {
    ...mapActions(['updateUserQuickLinks']),
    afterDrag(event) {
      this.displaySaveButton = true;
      //this.update();
    },
    onRemove(link) {
      this.links = this.links.filter(l => l.moduleId !== link.moduleId)
      this.update()
    },
    onClickAddNew(event) {
      event.preventDefault()
      this.addNewLink=true
    },
    async onAdd(links=[]) {

      const oldLenght = this.links.length

      links.map(l => {
        if(!this.isLinkAdded(l))
          this.links.push(l)
      })

      if(oldLenght === this.links.length)
        return;

      try {
        this.adding = true
        this.addNewLink = false
        await this.update()
      } catch(err) {
        console.error(err)
      } finally {
        this.adding = false
      }
    },
    async onSave() {
      try {
        this.adding = true
        this.displaySaveButton = false
        this.update()
      } catch(err) {
        console.error(err)
      } finally {
        this.adding = false
      }
    },
    update() {
      return this.updateUserQuickLinks({
        projectId: this.getSelectedHousingCompany.hcExt,
        links: [...this.links]
      })
    },
    isLinkAdded(link) {
      return this.links.find(l => l.moduleId === link.moduleId)
    }
  },
  mounted() {
    this.links = [...this.getUserQuickLinks(this.getSelectedHousingCompany.hcExt)]
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
.quick-link-editor {
  position: relative;
  z-index: 10;
  border: 2px dashed $blueLightMedium;
  padding: 3.75rem 1rem 1rem 1rem;
  .close-editor {
    position: absolute;
    top: -.25rem;
    right: 0;
    padding: 1rem;
    font-size: 1.2rem;
    i {
      position: relative;
      top: .15rem;
      font-size: 1.4rem;
    }
    span {
      font-size: .95rem;
    }
  }
}
.quick-links {
  &__list {

  }
}
</style>