const consumptionFeature = {
    id: "consumption",
    name: "feature.consumption.name",
    globalSettings: {},
    iconClass: "fal fa-chart-bar",
    schema: {
        /*displayWater: {
            type: Boolean,
            required: false,
            label: "feature.consumption.field.displayWater",
            default: false
        },
        displayElectricity: {
            type: Boolean,
            required: false,
            label: "feature.consumption.field.displayElectricity",
            default: false
        },
        displayTemperature: {
            type: Boolean,
            required: false,
            label: "feature.consumption.field.displayTemperature",
            default: false
        }*/
    }
};

export { consumptionFeature };