import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import ContactModel from "@/models/Contact";
import { getFeature } from "@/features";
import _ from "lodash";
import store from "@/store";

const api = new KenticoDeliveryApiService();
const MAIN_CONTACTS_MAX_LIMIT = 4;

api.setContentType(["contact"]);
api.targetByDefaultBuildingTaxonomy = true; //target default by building taxonomy

const settings = {
  contentType: "contact"
};
const getDefaultState = () => {
  return {
    contacts: [],
    buildingTaxonomyFilters: []
  };
};

const state = getDefaultState();

const getters = {
  getAllContacts: state => state.contacts,
  getMainContacts: state => (params = {}) => {
    let contacts = [];
    let yitContact = state.contacts.find(contact => contact.isYitDefaultContact);
    let mainKenticoContacts = state.contacts.filter(contact => contact.isPrimary && !contact.isYitDefaultContact);
    
    //@todo order main contacts by contact taxonomy ....
    mainKenticoContacts = _.orderBy(mainKenticoContacts, ['prioritizationNumber'], ['asc']);

    let partnerCompanyContacts = [];
    
    //append partner companies if feature is active
    if (getFeature('partner-company-detail-cards') && params.showPartnerCompanyContacts) {
      let currentHc = store.getters.getSelectedHousingCompany;
      if (currentHc) {
        if (Array.isArray(currentHc.selectedPropertyManagerCompanies) && currentHc.selectedPropertyManagerCompanies.length) {
          currentHc.selectedPropertyManagerCompanies.map(c => {
            partnerCompanyContacts.push(new ContactModel(c));
          });
        }

        if (Array.isArray(currentHc.selectedMaintenanceManagerCompanies) && currentHc.selectedMaintenanceManagerCompanies.length) {
          currentHc.selectedMaintenanceManagerCompanies.map(c => {
            partnerCompanyContacts.push(new ContactModel(c));
          });
        }
      }
    }

    if(partnerCompanyContacts.length)
      contacts = contacts.concat(partnerCompanyContacts);
    
    contacts = contacts.concat(mainKenticoContacts.slice(0, MAIN_CONTACTS_MAX_LIMIT - ((yitContact ? 1 + partnerCompanyContacts.length : 0 + partnerCompanyContacts.length))));

    if(yitContact)
      contacts.push(yitContact);

    return contacts.slice(0, MAIN_CONTACTS_MAX_LIMIT);
  },
  getContactsByTaxonomy: state => {
    return taxonomy => {
      return state.contacts.filter(contact => {
        let ret = false;
        if (
          contact.contactTaxonomies &&
          contact.contactTaxonomies.length
        ) {
          contact.contactTaxonomies.map(ctax => {
            if (ctax.codename == taxonomy) ret = true;
          });
        }

        if (ret) return contact;
      });
    };
  },
  getUncategorizedContacts: state => {
    return state.contacts.filter(
      contact =>
        !contact.isPrimary && 
        contact.contactTaxonomies.length == 0
    );
  }
};

const actions = {
  fetchContacts({commit}, params = null) {
    if (!params) {
      params = {
        limit: 50,
        order: "order",
        orderDirection: "asc"
      };
    }
    return api
      .fetchList(params)
      .then(response => {
        commit("setContacts", response.data.items);
      })
      .catch(err => {
        console.log("Fetching contact failed: ", err);
      });
  }
};

const mutations = {
  setContacts: (state, res) => (state.contacts = res.map(c => new ContactModel(c))),
  resetContacts: (state, res) => {
    state.contacts = [];
    state.buildingTaxonomyFilters = [];
  }
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
