import ATPService from "@/services/ATPService";
import store from "@/store";

const api = new ATPService();

const getDefaultState = () => {
  return {
    posts: [],
    latest: [],
    popular: [],
    current: null
  };
};

const state = getDefaultState();

// const fetchImagesToPosts = item => {
//     item.featured_image_path = '';
//     if(item.featured_media) {
//         api.getImagePath(item.featured_media).then((response) => {
//             //console.log('Image found: ', this.imageBaseUrl+response.data.media_details.sizes.thumbnail.source_url)
//             item.featured_image_path = process.env.VUE_APP_ATP_IMAGE_BASE_URL+'/'+response.data.media_details.sizes.thumbnail.source_url;
//         }).catch( err => console.log('Could not load the image: ', err));
//     }
//     return item;
// }

// const fetchImage = (imageId, imageSize = 'thumbnail') => {
//   return api.getImagePath(imageId).then((response) => {
//       //console.log('Image found: ', this.imageBaseUrl+response.data.media_details.sizes.thumbnail.source_url)
//       return process.env.VUE_APP_ATP_IMAGE_BASE_URL+'/'+response.data.media_details.sizes[imageSize].source_url;
//   }).catch( err => console.log('Could not load the image: ', err));
// }

const getters = {
  getATPPosts: state => state.posts,
  getATPLatest: state => state.latest,
  getATPPopular: state => state.popular,
  getATPCurrent: state => state.current
};

const actions = {
  async fetchATPPosts({ commit }, params = {}) {

    if(!params.per_page)
      params.per_page = 25;
    
      return new Promise((resolve, reject) => { 
        api
        .getPosts(params)
        .then(response => {

          // response.data = response.data.map(fetchImagesToPosts);

          if(params && params.append)
            commit("appendATPPosts", response.data);
          else
            commit("setATPPosts", response.data);

            resolve(response.data);
        })
        .catch(err => {
          console.log("Failed fetching ATP data: ", err);
          reject(err);
        });
      });
  },
  async fetchATPLatest({ commit }, params) {

    return new Promise((resolve, reject) => { 
      api
      .getLatest(params)
      .then(response => {

        // response.data = response.data.map(fetchImagesToPosts);

        if(params && params.append)
          commit("appendATPLatest", response.data);
        else
          commit("setATPLatest", response.data);

        resolve(response.data);
      })
      .catch(err => {
        console.log("Failed fetching ATP data: ", err);
        reject(err);
      });
    });
  },
  async fetchATPPopular({ commit }, params) {

    return new Promise((resolve, reject) => { 
      api
      .getPopular(params)
      .then(response => {

        // response.data = response.data.map(fetchImagesToPosts);

        if(params && params.append)
          commit("setATPPopular", response.data);
        else
          commit("setATPPopular", response.data);

        resolve(response.data);
      })
      .catch(err => {
        console.log("Failed fetching ATP data: ", err);
        reject(err);
      });
    });
  },
  async fetchSingleATPArticle({ commit }, id) {
    
    return new Promise((resolve, reject) => {
      api
      .getPost(id)
      .then(response => {
        
        if(response.data.featured_media) {
          // fetchImage(response.data.featured_media, 'large').then(imageUrl => {
            // response.data.featured_image_path = imageUrl;
            commit("setATPCurrent", response.data);
            resolve(response);
          // }).catch(err => {
          //   console.log(err);
          //   commit("setATPCurrent", response.data);
          // });
        } else {
          console.log("Fetched atp item: ", response.data);
          resolve(response);
        }

      })
      .catch(err => {
        console.log("Failed fetching ATP data: ", err);
        reject(err);
      });
    });
  },
  async fetchSingleATPArticleByCodename({ commit }, slug) {
    
    let articleFound = false;
    
    if(store.getters.getATPPosts.length) {
      articleFound = store.getters.getATPPosts.find(i => i.slug === slug);
    }

    if(!articleFound) {
      try {
        await store.dispatch('fetchATPPosts');
        articleFound = store.getters.getATPPosts.find(i => i.slug === slug);
        if(articleFound)
          commit("setATPCurrent", articleFound);
      } catch(err) {
        console.log(err);
        commit("resetATPCurrent");
      }
    } else 
      commit("setATPCurrent", articleFound);
  }
};


const mutations = {
    setATPPosts: (state, data) => {
      state.posts = data;
    },
    setATPLatest: (state, data) => {
      state.latest = data;
    },
    setATPPopular: (state, data) => {
      state.popular = data;
    },
    setATPCurrent: (state, data) => {
      state.current = data;
    },
    appendATPPosts: (state, data) => {
      state.posts = state.posts.concat(data);
    },
    appendATPLatest: (state, data) => {
      state.latest = state.latest.concat(data);
    },
    appendATPPopular: (state, data) => {
      state.popular = state.popular.concat(data);
    },
    resetATPCurrent: (state, data) => {
      state.current = null;
    }
  };

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
