export default class TimelineItemModel {
  constructor(item = {}) {
    this.id = null;
    this.type = "";
    this.title = null;
    this.description = null;
    this.date = null;
    this.date_end = null;
    this.thumbnailSrc = null;
    this.category = [];
    this.linkUrl = null;
    this.state = "active"; //confirmed|active|pending|disabled
    this.timeStamp = new Date().getTime();
    this.isYit = false;
    this.isPriority = false;

    Object.assign(this, item);
  }

  setId(value) {
    this.id = value;
  }
  setType(value) {
    this.type = value;
  }
  setTitle(value) {
    this.title = value;
  }
  setDescription(value) {
    this.description = value;
  }
  setDate(value) {
    this.date = new Date(value);
    this.timeStamp = new Date(value).getTime;
  }
  setDateEnd(value) {
    this.date_end = value;
  }
  setThumbnailSrc(value) {
    this.thumbnailSrc = value;
  }
  setCategory(value) {
    if (Array.isArray(value)) this.category = value;
    else value = [];
  }
  addCategory(value) {
    this.category.push(value);
  }
  setLinkUrl(value) {
    this.linkUrl = value;
  }
  setState(value) {
    this.state = value;
  }
  setIsYit(value) {
    this.isYit = value ? true : false;
  }
  setIsPriority(value) {
    this.isPriority = value ? true : false;
  }
}
