export default (user, { can, cannot, rules }) => {
  can("manage", 'my-settings');
  can("manage", 'my-profile');
  can("manage", 'notification-settings');

  //User can edit himself
  can("update", 'user', {
    "id": { $eq: user.id }
  });

  if (user.hasRole("yitUser") || user.hasRole("propertyManager") || user.hasRole("maintenanceManager")) {
    can("manage", 'user-signatures');
  }

  if(user.hasRole("partner")) {
    cannot("manage", 'my-profile');
    cannot("manage", 'notification-settings');
  }
}