<template>
  <div class="maintenance-page">
    <MaintenanceNotice />
  </div>
</template>

<script>
import MaintenanceNotice from "@/views/partials/Maintenance";

export default {
  name: "PageNotFound",
  components: {MaintenanceNotice}
};
</script>
<style scoped>
.error-page-icon {
  font-size: 3rem;
  margin: 1rem 0;
}
</style>