import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import KenticoService from "@/services/KenticoService";
import store from "@/store";
import ProjectService from "@/services/ProjectService";

const api = new KenticoDeliveryApiService();
const apiManagement = new KenticoService();
const projectApi = new ProjectService();

const kenticoTaxonomies = ["age_group", "room_type", "faqtaxonomy"];

const getDefaultState = () => {
  return {
    buildingTaxonomyFilters: [],
    age_group: [],
    room_type: [],
    faqtaxonomy: [],
    buildingTaxonomies: [],
    buildingTaxonomiesFlat: [],
    allBuildingTaxonomies: [],
    articleTypes: [],
    contactTaxonomies: [],
    currentBuildingTaxonomy: {},
    building_type: [
      {
        translationKey: "common.building_type.block_of_flats",
        codename: "block_of_flats",
        aliases: ["block of flats", "kerrostalo"]
      },
      {
        translationKey: "common.building_type.leisure_home",
        codename: "leisure_home",
        aliases: ["leisure home"]
      }
    ],
    fetchedFromApi: false,
    advertisementCategories: []
  };
};

const state = getDefaultState();

const findTaxonomyTerm = (value, field = 'id', tax = []) => {
  for (const item of tax) {
    if (item[field] === value) return item;
    if (item.terms) {
      const child = findTaxonomyTerm(value, field, item.terms);
      if (child) return child;
    }
  }
};

const countHousingCompanyTaxonomiesUnderBuildingTaxonomyTerm = (term, countedTermIds=[]) => {
  if(Array.isArray(term.terms) && term.terms.length) {
  for (const t of term.terms) {
      let terms = countHousingCompanyTaxonomiesUnderBuildingTaxonomyTerm(t, countedTermIds);
    }
  } else {
    if(!countedTermIds.includes(term.id))
      countedTermIds.push(term.id);
  }


  return countedTermIds;
};

const getHousingCompaniesUnderTaxonomy = (terms, state) => {
  let count = 0;
  if(Array.isArray(terms)) {
    terms.map(taxId => {
      let term = findTaxonomyTerm(taxId, 'id', state.allBuildingTaxonomies);
      if(term) {
        let terms = countHousingCompanyTaxonomiesUnderBuildingTaxonomyTerm(term);
        count = count + terms.length;
      }
      return taxId;
    });
  }

  return count;
};

const getters = {
  getBuildingTaxonomyFilters: state => state.buildingTaxonomyFilters,
  getAgeGroups: state => state.age_group,
  getBuildingTypes: state => state.building_type,
  getRoomsInApartment: state => state.room_type,
  getFaqTaxonomy: state => state.faqtaxonomy,
  getCurrentBuildingTaxonomy: state => state.currentBuildingTaxonomy,
  getBuildingTaxonomies: state => state.buildingTaxonomies,
  getBuildingTaxonomiesFlat: state => state.buildingTaxonomiesFlat,
  getAllBuildingTaxonomies: state => state.allBuildingTaxonomies,
  getArticleTypes: state => state.articleTypes,
  getContactTaxonomies: state => state.contactTaxonomies,
  getBuildingTaxonomyCodenameById: state => {
    return id => {
      //return state.allBuildingTaxonomies.filter(tax => findTaxonomyTerm(tax, id, 'id'));
      let ret = findTaxonomyTerm(id, 'id', state.allBuildingTaxonomies);
      if(ret)
        return [ret];

      return [];
    };
  },
  getBuildingTaxonomyIdByCodename: state => {
    return codename => {
      let ret = findTaxonomyTerm(codename, 'codename', state.allBuildingTaxonomies);
      if(ret)
        return [ret];

      return [];
    };
  },
  getBuildingTaxonomyByExternalId: state => {
    return extId => {
      let ret = findTaxonomyTerm(extId, 'external_id', state.allBuildingTaxonomies);
      if(ret)
        return [ret];

      return [];
    };
  },
  housingCompaniesCountUnderSelection: state => {
    return terms => {
      let count = getHousingCompaniesUnderTaxonomy(terms, state);
      return count;
    }
  },
  getAdvertisementCategories: state => state.advertisementCategories,
  getAdvertisementCategoriesByCountryCode: state => {
    return (countryCode = null) => {
      if (!countryCode)
        return [];
      
      countryCode = String(countryCode).toLowerCase();
      let terms = [];

      state.advertisementCategories.map(taxonomy => {
        if (taxonomy.codename === countryCode && taxonomy.terms)
          terms = taxonomy.terms;
      });

      return terms;
    }
  }
};

const actions = {
  setBuildingTaxonomyFilters({ commit }, filters) {
    commit("setBuildingTaxonomyFilters", filters);
  },
  /**
   * @todo check if we can remove this
   */
  fetchTaxonomies({ commit }) {
    let taxonomies = kenticoTaxonomies;

    return new Promise((resolve, reject) => {
      //do fetch only one time
      if (state.fetchedFromApi) {
        resolve(
          taxonomies.map(taxonomy => {
            return state[taxonomy];
          })
        );
      } else {
        api
          .fetchTaxonomies({
            codenames: taxonomies
          })
          .then(res => {
            console.log("Fetched taxonomies: ", res.data.taxonomies);
            if (res.data.taxonomies) {
              res.data.taxonomies.map(tax => {
                if (state.hasOwnProperty(tax.system.codename)) {
                  switch (tax.system.codename) {
                    case "age_group":
                      commit("setAgeGroups", tax.terms);
                      break;
                    case "building_type":
                      commit("setBuildingTypes", tax.terms);
                      break;
                    case "room_type":
                      commit("setRoomsInApartment", tax.terms);
                      break;
                    case "faqtaxonomy":
                      commit("setFaqTaxonomy", tax.terms);
                      break;
                  }
                }
              });

              state.fetchedFromApi = true;
              resolve(
                taxonomies.map(taxonomy => {
                  return state[taxonomy];
                })
              );
            }

            reject("No taxonomies found");
          })
          .catch(err => {
            console.log("Failed to get taxonomies ", err);
            reject(err);
          });
      }
    });
  },
  async fetchTaxonomyGroup({ commit }, taxonomy) {

    try {
      const response = await projectApi.fetchTaxonomyGroupTerms(taxonomy);

      if (taxonomy == "buildingtaxonomy") {
        let currentUser = store.getters.getCurrentUser;
        let taxonomies = [];
        let countries = [];
          
        //deep clone the array
        let allBuildingTaxonomies = JSON.parse(JSON.stringify(response.data.terms));

        //Restrict YIT project role taxonomies
        /*if (currentUser.hasRole("yitProject")) {
          let projects = currentUser.getProjects();

          response.data.terms.map(function f(tax) {
            if (projects.includes(tax.codename.toLowerCase())) {
              taxonomies.push(tax);
              return tax;
            }
            if (tax.terms && tax.terms.length)
              return (tax.terms = tax.terms.filter(f)).length;
          });

          commit("setBuildingTaxonomies", taxonomies);
          commit("setBuildingTaxonomiesFlat", taxonomies);

          //Restrict business partner taxonomies
        } else*/
        if (store.getters.isBusinessPartner) {

          let partnerOperationalArea = store.getters.getCurrentPartnerCompanyDetails && Array.isArray(store.getters.getCurrentPartnerCompanyDetails.operationalAreaBuildingTaxonomies) ? store.getters.getCurrentPartnerCompanyDetails.operationalAreaBuildingTaxonomies : [];
          let partnerOperationalAreaZips = store.getters.getCurrentPartnerCompanyDetails && Array.isArray(store.getters.getCurrentPartnerCompanyDetails.operationalAreaZips) ? store.getters.getCurrentPartnerCompanyDetails.operationalAreaZips : [];

          //attempt to upload operational area if there's new hc's under zipcodes which are not found under selected taxonomy uuids
          if (partnerOperationalAreaZips.length) {
            try {
              let hcsIds = await retrieveHousingCompaniesByOperationalAreaZipCodes(partnerOperationalAreaZips);
              if (Array.isArray(hcsIds) && hcsIds.length) {
                hcsIds.map(uuid => {
                  if (!partnerOperationalArea.includes(uuid)) {
                    partnerOperationalArea.push(uuid);
                  }
                });
              }

            } catch(err) {
              console.log(err);
            }
          }

          if (partnerOperationalArea.length) {
            response.data.terms.map(function f(tax) {
              if (partnerOperationalArea.includes(tax.id)) {
                taxonomies.push(tax);
                return tax;
              }
              if (tax.terms && tax.terms.length)
                return (tax.terms = tax.terms.filter(f)).length;
            });
          }
            
          commit("setBuildingTaxonomies", taxonomies);
          commit("setBuildingTaxonomiesFlat", taxonomies);

        } else {
          taxonomies = response.data.terms;
          
          commit("setBuildingTaxonomies", taxonomies);
          commit("setBuildingTaxonomiesFlat", taxonomies);
        }
          
        commit("setAllBuildingTaxonomies", allBuildingTaxonomies);
          
      } else if (taxonomy == "articletype")
        commit("setArticleTypes", response.data.terms);
      else if (taxonomy == "contact_taxonomy")
        commit("setContactTaxonomies", response.data.terms);
      else if (taxonomy == "room_type")
        commit("setRoomsInApartment", response.data.terms);
      else if (taxonomy == 'building_type')
        commit("setBuildingTypes", response.data.terms);
      else if (taxonomy == 'age_group')
        commit("setAgeGroups", response.data.terms);
      else if (taxonomy == 'campaign_category')
        commit("setAdvertisementCategories", response.data.terms);

      return response;
      
    } catch (err) {
      throw err;
    }
  },
  setBuildingTaxonomy({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (state.currentBuildingTaxonomies) {
        let housingCompanyTax, countryTax, businessUnitTax, cityTax, areaTax;
        countryTax = state.currentBuildingTaxonomies.find(
          countryTaxonomy => countryTaxonomy.external_id == payload.country
        );
        if (countryTax) {
          businessUnitTax = countryTax.terms.find(
            businessUnitTaxonomy =>
              businessUnitTaxonomy.external_id == payload.businessUnit
          );
        }
        if (businessUnitTax) {
          cityTax = businessUnitTax.terms.find(
            cityTaxonomy => cityTaxonomy.external_id == payload.city
          );
        }
        if (cityTax) {
          areaTax = cityTax.terms.find(
            areaTaxonomy => areaTaxonomy.external_id == payload.area
          );
        }
        if (areaTax) {
          housingCompanyTax = areaTax.terms.find(
            hcTaxonomy => hcTaxonomy.external_id == payload.hcExternal_id
          );
        }

        commit("setCurrentBuildingTaxonomy", housingCompanyTax);
        resolve(housingCompanyTax);
      } else {
        console.log("error getting buildingTaxonomy");
        reject("error getting buildingTaxonomy");
      }
    });
  }
};

const mutations = {
  setBuildingTaxonomyFilters: (state, res) =>
    (state.buildingTaxonomyFilters = res),
  setAgeGroups: (state, res) => (state.age_group = res),
  setBuildingTypes: (state, res) => (state.building_type = res),
  setRoomsInApartment: (state, res) => (state.room_type = res),
  setFaqTaxonomy: (state, res) => (state.faqtaxonomy = res),
  setCurrentBuildingTaxonomy: (state, res) =>
    (state.currentBuildingTaxonomy = res),
  setBuildingTaxonomies: (state, res) => (state.buildingTaxonomies = res),
  setBuildingTaxonomiesFlat: (state, res) => {
    let resultArray = [];
    if (Array.isArray(res)) {
      res.map( function add(i) {
        resultArray.push({
          codename: i.codename,
          external_id: i.external_id,
          id: i.id,
          name: i.name
        });
        if (Array.isArray(i.terms) && i.terms.length)
          i.terms.map(add);
      })
    }
    state.buildingTaxonomiesFlat = resultArray;
  },
  setAllBuildingTaxonomies: (state, res) => (state.allBuildingTaxonomies = res),
  setArticleTypes: (state, res) => (state.articleTypes = res),
  setContactTaxonomies: (state, res) => (state.contactTaxonomies = res),
  setAdvertisementCategories: (state, res) => (state.advertisementCategories = res)
};

export const retrieveHousingCompaniesByOperationalAreaZipCodes = async (zipCode) => {
  let ret = [];
  if(!zipCode)
    return;
  try {

    if(Array.isArray(zipCode))
      zipCode = zipCode.toString();

    let res = await projectApi.getProjects({zip: zipCode, fields: 'id'});
    if(res && Array.isArray(res.data)) {
      res.data.map(hc => {
        let taxonomies = store.getters.getBuildingTaxonomyByExternalId(hc.externalId);
        if(taxonomies.length) {
          taxonomies.map(t => {
            if(!ret.includes(t.id))
              ret.push(t.id);
          })
        }
      });
    }

    return ret;
  } catch(err) {
    throw err;
  }
}

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};

export {
  getDefaultState,
  state,
  getters,
  actions,
  mutations,
  findTaxonomyTerm
}