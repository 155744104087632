<template>
    <Dashboard />
</template>

<script>
import Dashboard from "./Dashboard";
import {mapGetters} from "vuex";

export default {
    name: 'AppIndex',
    components: {
        Dashboard
    },
    computed: {
        ...mapGetters(['isBusinessPartner'])
    },
    mounted() {
        if(this.isBusinessPartner) {
            this.$router.push('/business-partners/dashboard')
        }
    }
}
</script>

<style>

</style>