import { createStore } from 'vuex'

import Auth from "./modules/Auth";
import ServiceRequests from "./modules/ServiceRequests";
import User from "./modules/User";
import MainNavigation from "./modules/MainNavigation";
import News from "./modules/News";
import DocumentsV2 from "./modules/DocumentsV2";
import DocumentsV1 from "./modules/DocumentsV1";
import DocumentsAdmin from "./modules/Admin/AzureDocuments";
import Campaigns from "./modules/Campaigns";
import Calendar from "./modules/Calendar";
import Partners from "./modules/Partners";
import Contact from "./modules/Contact";
import Pages from "./modules/Pages";
import Timeline from "./modules/Timeline";
import Taxonomies from "./modules/Taxonomies";
import Faq from "./modules/Faq";
import Community from "./modules/Community";
import Neighbours from "./modules/Neighbours";
import Kentico from "./modules/Admin/Kentico";
import CalendarAdmin from "./modules/Admin/Calendar";
import Comments from "./modules/Comments";
import MaterialSelection from "./modules/MaterialSelection";
import FeedbackAdmin from "./modules/Admin/Feedback";
import Leanheat from "./modules/Leanheat";
import FeaturesAdmin from "./modules/Admin/Features";
import ContactsAdmin from "./modules/Admin/Contact";
import ApplicationSettings from "./modules/ApplicationSettings";
import Projects from "./modules/Admin/Projects";
import Warranty from "./modules/Warranty";
import CongridWarranty from "./modules/CongridWarranty";
import Consumption from "./modules/Consumption";
import Iot from "./modules/Iot";
import ATP from "./modules/ATP";
import UserAdmin from "./modules/Admin/UserAdmin";
import CampaignsAdmin from "./modules/Admin/Campaigns";
import PartnerCompaniesAdmin from "./modules/Admin/PartnerCompanies";
import EmailLetter from "./modules/Admin/EmailLetter";
import IotAdmin from "./modules/Admin/Iot";
import ArticleAdmin from "./modules/Admin/Articles";
import Steps from "./modules/Steps";
import CommonSpaces from "./modules/CommonSpaces";
import One4AllAuth from "./modules/One4AllAuth";

// initial state
const initialState = {
  Auth: Auth.getDefaultState(),
  Taxonomies: Taxonomies.getDefaultState(),
  ServiceRequests: ServiceRequests.getDefaultState(),
  User: User.getDefaultState(),
  MainNavigation: MainNavigation.getDefaultState(),
  News: News.getDefaultState(),
  //DocumentsV2: DocumentsV2.getDefaultState(),
  DocumentsV1: DocumentsV1.getDefaultState(),
  DocumentsAdmin: DocumentsAdmin.getDefaultState(),
  Campaigns: Campaigns.getDefaultState(),
  Calendar: Calendar.getDefaultState(),
  Partners: Partners.getDefaultState(),
  Contact: Contact.getDefaultState(),
  Faq: Faq.getDefaultState(),
  Community: Community.getDefaultState(),
  Neighbours: Neighbours.getDefaultState(),
  Comments: Comments.getDefaultState(),
  Kentico: Kentico.getDefaultState(),
  Timeline: Timeline.getDefaultState(),
  MaterialSelection: MaterialSelection.getDefaultState(),
  CalendarAdmin: CalendarAdmin.getDefaultState(),
  Timeline: Timeline.getDefaultState(),
  FeedbackAdmin: FeedbackAdmin.getDefaultState(),
  Leanheat: Leanheat.getDefaultState(),
  FeaturesAdmin: FeaturesAdmin.getDefaultState(),
  ApplicationSettings: ApplicationSettings.getDefaultState(),
  Projects: Projects.getDefaultState(),
  ContactsAdmin: ContactsAdmin.getDefaultState(),
  Warranty: Warranty.getDefaultState(),
  CongridWarranty: CongridWarranty.getDefaultState(),
  Consumption: Consumption.getDefaultState(),
  Iot: Iot.getDefaultState(),
  ATP: ATP.getDefaultState(),
  UserAdmin: UserAdmin.getDefaultState(),
  CampaignsAdmin: CampaignsAdmin.getDefaultState(),
  PartnerCompaniesAdmin: PartnerCompaniesAdmin.getDefaultState(),
  EmailLetter: EmailLetter.getDefaultState(),
  IotAdmin: IotAdmin.getDefaultState(),
  Steps: Steps.getDefaultState(),
  CommonSpaces: CommonSpaces.getDefaultState(),
  One4AllAuth: One4AllAuth.getDefaultState(),
  ArticleAdmin: ArticleAdmin.getDefaultState()
};

//create store
export default createStore({
  modules: {
    Auth,
    Taxonomies,
    User,
    ServiceRequests,
    MainNavigation,
    News,
    DocumentsV1,
    DocumentsV2,
    DocumentsAdmin,
    Campaigns,
    Calendar,
    Partners,
    Contact,
    Pages,
    Timeline,
    Faq,
    Community,
    Neighbours,
    Comments,
    Kentico,
    MaterialSelection,
    CalendarAdmin,
    FeedbackAdmin,
    Leanheat,
    FeaturesAdmin,
    ApplicationSettings,
    Projects,
    ContactsAdmin,
    Warranty,
    CongridWarranty,
    Consumption,
    Iot,
    ATP,
    UserAdmin,
    CampaignsAdmin,
    PartnerCompaniesAdmin,
    EmailLetter,
    IotAdmin,
    Steps,
    CommonSpaces,
    One4AllAuth,
    ArticleAdmin
  },
  mutations: {
    resetModule(state, module) {
      Object.assign(state[module], initialState[module]);
    },
    resetAll(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
    },
  },
});
