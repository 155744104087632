import store from "@/store";

const projectCameraFeature = {
    id: "project-camera",
    name: "feature.project-camera.name",
    globalSettings: {},
    iconClass: "fal fa-camera",
    schema: {
        url: {
            type: String,
            required: true,
            input: {
                type: "text"
            },
            label: "feature.project-camera.field.url",
            default: ""
        },
        kenticoCodename: {
            type: String,
            required: false,
            input: {
                type: "text"
            },
            label: "feature.project-camera.kenticoCodename",
            default: ""
        },
        navigationOrderNumber: {
            type: Number,
            required: false,
            input: {
                type: "number"
            },
            label: "feature.project-camera.field.navigationOrderNumber",
            default: 25
        }
    },
    initialize: (feature, settings) => {
        store.dispatch("addNavigationLink", {
            titleLanguageKey: "navigation.project-camera",
            href: "/project-camera",
            target: "_self",
            iconClass: "fal fa-camera",
            orderNumber: settings.navigationOrderNumber
                ? settings.navigationOrderNumber
                : 25
        });
    }
};

export { projectCameraFeature };