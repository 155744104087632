import {getFeature} from "@/features";
import store from "@/store";
import dayjs from "dayjs";

/**
 * Check if Kentico campaign is visible
 * @param {*} campaign 
 */
const isCampaignVisible = campaign => {

    //not kentico item -> pass thru
    if(!campaign.system || !campaign.elements)
        return true;

    let completionTime = store.getters.getSelectedHousingCompany.completionDate
      ? new Date(store.getters.getSelectedHousingCompany.completionDate).getTime()
      : 0;
    let completionDate = dayjs(completionTime);

    let now = dayjs();
    let registrationDate = store.getters.getCurrentUser.registerDate
      ? dayjs(store.getters.getCurrentUser.registerDate)
      : null;
    let buildingPhase = store.getters.getCurrentHcState;

    //building phase
    if(campaign.elements.building_phase && Array.isArray(campaign.elements.building_phase.value) && campaign.elements.building_phase.value.length) {
      let hasCorrectPhase = false;
      campaign.elements.building_phase.value.map(p => {
        if(p.codename == buildingPhase)
          hasCorrectPhase = true;
      });

      if(!hasCorrectPhase)
        return false;
    }
    
  
    //Weeks before completion
    if (campaign.elements.weeks_before_completion.value) {
      let weeksBeforeCompletion = dayjs(completionDate.valueOf()).subtract(
        campaign.elements.weeks_before_completion.value,
        "week"
      );
      if (
        now.valueOf() < weeksBeforeCompletion.valueOf()
      )
        return false;
    }
  
    //Weeks after completion
    if (campaign.elements.weeks_after_completion.value) {
      let weeksAfterCompletion = dayjs(completionDate.valueOf()).add(
        campaign.elements.weeks_after_completion.value,
        "week"
      );
      if (now.valueOf() > weeksAfterCompletion.valueOf())
        return false;
    }
  
    //Apartment size (user can have multiple apartments under the buildling!)
    if (
      campaign.elements.apartment_size_min.value ||
      campaign.elements.apartment_size_max.value
    ) {
      let currentApartmentSize = store.getters.getSelectedApartment.size ? store.getters.getSelectedApartment.size : 0;
      //if smaller than smallest accepted value
      if (
        campaign.elements.apartment_size_min.value && 
        (currentApartmentSize <
          parseFloat(campaign.elements.apartment_size_min.value))
      )
        return false;
        
      //if larger than largest accepted value
      if (
          campaign.elements.apartment_size_max.value && 
          (currentApartmentSize >
            parseFloat(campaign.elements.apartment_size_max.value))
        )
          return false;
    }
  
    return true;
  };

  export {
    isCampaignVisible
  };