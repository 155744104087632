export default (user, { can, cannot, rules }) => {
  if (user.hasRole("yitMarketing") || user.hasRole("yitBusinessAdmin") || user.hasRole("yitProject") || user.hasRole('yitProjectInternal')) {
    can("update", "apartment");
  }

  if (user.hasRole("yitMarketing") || user.hasRole("yitBusinessAdmin") || user.hasRole("yitProjectInternal") || user.hasRole("yitProject")) {
    can("manage", ["housingcompanies"]);
    can("manage", "admin/housingcompanies/wizard");
  }

  if(user.hasRole('chairman') || user.hasRole('boardMember') || user.hasRole('ceeBoardMember') || user.hasRole('propertyManager') || user.hasRole('maintenanceManager') || user.hasRole('yitUser') || user.hasRole('rentalManager')) {
    can("manage", "documents");
  }

  if (user.hasRole('chairman') || user.hasRole('boardMember') || user.hasRole('propertyManager') || user.hasRole('yitMarketing') || user.hasRole('yitBusinessAdmin') || user.hasRole('yitProject') || user.hasRole('ceeMaintenanceManager')) {
    can("read", "admin/housingcompanies");
  }

  if (user.hasRole('yitProjectInternal')) {
    can("read", "admin/housingcompanies");
  }

  if (user.hasRole('yitUser') || user.hasRole('ceeMaintenanceManager')) {
    can("manage", "admin/housingcompanies/apartments");
  }

  if(user.hasRole('chairman') || user.hasRole('boardMember') || user.hasRole('propertyManager') || user.hasRole('yitBusinessAdmin') || user.hasRole('yitMarketing') || user.hasRole('yitProject')) {
    can("read", "admin/housingcompanies/partnercompanies");
  }
  if(user.hasRole('chairman') || user.hasRole('boardMember') || user.hasRole('propertyManager') || user.hasRole('yitBusinessAdmin') || user.hasRole('yitMarketing') || user.hasRole('yitProject')) {
    can("manage", "admin/housingcompanies/partnercompanies/propertymanager/users", {

    });
    can("manage", "admin/housingcompanies/partnercompanies/maintenancemanager");
    can("manage", "admin/housingcompanies/partnercompanies/maintenancemanager/users");
    can("manage", "admin/housingcompanies/servicerequestsettings");
    can("manage", "admin/housingcompanies/moderation");
  }
  //board member and yit users can edit partner companany conjunctions to hc
  if(user.hasRole('chairman') || user.hasRole('boardMember') || user.hasRole('yitBusinessAdmin') || user.hasRole('yitMarketing') || user.hasRole('yitProject')) {
    can("manage", "admin/housingcompanies/partnercompanies/propertymanager");
    
  }
  //property manager can update itself
  if(user.hasRole('propertyManager') || user.hasRole('yitBusinessAdmin') || user.hasRole('yitMarketing') || user.hasRole('yitProject')) {
    can("delete", "admin/housingcompanies/partnercompanies/propertymanager");
    can("manage", "admin/housingcompanies/partnercompanies/maintenancemanager");
  }
  if(user.hasRole('maintenanceManager') || user.hasRole('yitBusinessAdmin') || user.hasRole('yitMarketing') || user.hasRole('yitProject')) {
    can("manage", "admin/housingcompanies/partnercompanies/maintenancemanager/users");
  }

  if (user.hasRole('propertyManager') || user.hasRole('ceeMaintenanceManager') || user.hasRole('yitBusinessAdmin') || user.hasRole('yitMarketing') || user.hasRole('yitProject') || user.hasRole('yitProjectInternal')) {
    can("manage", "project/reminders");
  }

  if (user.hasRole("yitProject") || user.hasRole("yitBusinessAdmin") || user.hasRole("yitMarketing")) {
    if (Array.isArray(user.hcs)) {
      user.hcs.map(hc => {
        can("update", "project", {
          externalId: hc.hcExt
        });
        can("manage", "steps", {
          externalId: hc.hcExt
        })
      });
    }
  }
}