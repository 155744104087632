export default [
  {
    path: "/warranty",
    name: "warranty",
    component: () =>
      import(
        /* webpackChunkName: "warranty" */ "@/views/Warranty/View.vue"
      ),
    meta: {
      title: "navigation.warranty",
      sectionTitle: "navigation.warranty",
      accessPolicies: {
        features: ['warranty']
      }
    }
  },  
  {
    path: "/warranty/contacts",
        name: "warranty/contacts",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Warranty/Contacts.vue"
          ),
        meta: {
          title: "navigation.warranty.contacts",
          sectionTitle: "navigation.warranty",
          accessPolicies: {
            features: ['warranty']
          }
        }
  },
  {
    path: "/warranty/add-fault",
        name: "warranty/add-fault",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Warranty/Faults.vue"
          ),
        meta: {
          title: "navigation.warranty.add.fault",
          sectionTitle: "navigation.warranty",
          accessPolicies: {
            features: ['warranty']
          }
        }
  },
  {
    path: "/warranty/edit-fault/:id",
        name: "warranty/edit-fault",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Warranty/Faults.vue"
          ),
        meta: {
          title: "navigation.warranty.edit.fault",
          sectionTitle: "navigation.warranty",
          accessPolicies: {
            features: ['warranty']
          }
        }
  },
  {
    path: "/warranty/summary",
        name: "warranty/summary",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Warranty/Summary.vue"
          ),
        meta: {
          title: "navigation.warranty.summary",
          sectionTitle: "navigation.warranty",
          accessPolicies: {
            features: ['warranty']
          }
        }
  },
  {
    path: "/apartment/warranty",
    name: "/apartment/warranty",
    component: () =>
      import(
        /* webpackChunkName: "warranty" */ "@/views/Warranty/Congrid/Index.vue"
      ),
    meta: {
      title: "navigation.warranty",
      sectionTitle: "navigation.warranty",
      accessPolicies: {
        features: ['warranty']
      }
    }
  },
  {
    path: "/apartment/warranty/welcome",
    name: "/apartment/warranty/welcome",
    component: () =>
      import(
        /* webpackChunkName: "warranty" */ "@/views/Warranty/Congrid/Welcome.vue"
      ),
    meta: {
      title: "navigation.warranty",
      sectionTitle: "navigation.warranty.welcome",
      accessPolicies: {
        features: ['warranty']
      }
    }
  },
  {
    path: "/apartment/warranty/contact-details",
        name: "/apartment/warranty/contact-details",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Warranty/Congrid/Contacts.vue"
          ),
        meta: {
          title: "navigation.warranty.contacts",
          sectionTitle: "navigation.warranty",
          accessPolicies: {
            features: ['warranty']
          }
        }
  },
  {
    path: "/apartment/warranty/fault/add",
        name: "/apartment/warranty/fault/add",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Warranty/Congrid/Fault/New.vue"
          ),
        meta: {
          title: "navigation.warranty.add.fault",
          sectionTitle: "navigation.warranty",
          accessPolicies: {
            features: ['warranty']
          }
        }
  },
  {
    path: "/apartment/warranty/fault/edit/:id",
        name: "/apartment/warranty/fault/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Warranty/Congrid/Fault/Edit.vue"
          ),
        meta: {
          title: "navigation.warranty.edit.fault",
          sectionTitle: "navigation.warranty",
          accessPolicies: {
            features: ['warranty']
          }
        }
  },
  {
    path: "/apartment/warranty/fault/view/:id",
        name: "/apartment/warranty/fault/view/:id",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Warranty/Congrid/Fault/View.vue"
          ),
        meta: {
          title: "navigation.warranty.view.fault",
          sectionTitle: "navigation.warranty",
          accessPolicies: {
            features: ['warranty']
          }
        }
  },
  {
    path: "/admin/warranty",
    name: "/admin/warranty",
    meta: {
      sectionTitle: "navigation.warranty",
      title: "navigation.warranty",
      layout: 'admin-default',
      admin: true,
    },
    component: () =>
      import(/* webpackChunkName: "warranty" */ "@/views/Admin/Warranty/Index.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Admin/Warranty/Dashboard.vue"
          ),
        meta: {
          title: "navigation.warranty.dashboard",
          sectionTitle: "navigation.warranty",
          layout: 'admin-default',
          admin: true,
        }
      },
      {
        path: "apartments",
        name: "apartments",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Admin/Warranty/Apartments.vue"
          ),
        meta: {
          title: "navigation.warranty.apartments",
          sectionTitle: "navigation.warranty",
          layout: 'admin-default',
          admin: true,
        }
      },
      {
        path: "faults",
        name: "faults",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Admin/Warranty/Faults.vue"
          ),
        meta: {
          title: "navigation.warranty.faults",
          sectionTitle: "navigation.warranty",
          layout: 'admin-default',
          admin: true,
        }
      },
      {
        path: "faults/:id",
        name: "faults/:id",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Admin/Warranty/Fault.vue"
          ),
        meta: {
          title: "navigation.warranty.view.fault",
          sectionTitle: "navigation.warranty",
          layout: 'admin-default',
          admin: true,
        }
      },
      {
        path: "apartments/:id",
        name: "apartments/:id",
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ "@/views/Admin/Warranty/Apartment.vue"
          ),
        meta: {
          title: "navigation.warranty.apartments",
          sectionTitle: "navigation.warranty",
          layout: 'admin-default',
          admin: true,
        }
      }
    ]
  }
]