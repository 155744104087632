import KenticoService from "@/services/KenticoService";
import AppSettings from "@/globals";
import _ from "lodash";

const contentTypeCodename = "event";

const api = new KenticoService();
api.setContentType(contentTypeCodename);

const getDefaultState = () => {
  return {
    calendarEntries: [],
    contentModel: null
  };
};

const state = getDefaultState();

const getters = {
  getCalendarEntriesAdmin: state => state.calendarEntries,
  getCalendarContentModel: state => state.contentModel
};

const actions = {
  fetchCalendarEntriesAdmin({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.resetFilters();

      let defaultParams = {
        order: "start_time",
        orderDirection: "desc",
        limit: 10
      };
      params = _.merge(defaultParams, params);

      if(params.start_time_from)
        api.addFilter("start_time", KenticoService.convertDateToKenticoDate(params.start_time_from), "gte");
      if(params.start_time_to)
        api.addFilter("start_time", KenticoService.convertDateToKenticoDate(params.start_time_to), "lte");

      return api
        .fetchItems(params)
        .then(res => {
          if(params.append)
            commit("appendCalendarEntriesAdmin", res.data.items);
          else
            commit("setCalendarEntriesAdmin", res.data.items);
          resolve(res.data);
        })
        .catch(err => {console.log(err); reject(err);});
    });
  },
  fetchCalendarContentModel({ commit }) {
    return api
      .fetchContentType(contentTypeCodename)
      .then(res => {
        commit("setCalendarContentModelData", res.data);
      })
      .catch(err => console.log(err));
  }
};

const mutations = {
  setCalendarEntriesAdmin: (state, res) => (state.calendarEntries = res),
  appendCalendarEntriesAdmin: (state, res) => (state.calendarEntries = state.calendarEntries.concat(res)),
  setCalendarContentModelData: (state, res) =>
    (state.contentModel = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
