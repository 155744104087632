import store from "@/store";
import BusinessPartnerModel from "@/models/BusinessPartner";
import {getFeature} from "@/features";
import ProjectService from "../../services/ProjectService";
import {getImageUrlFromDocumentResponseObject} from "@/utils/DocumentsUtil";

const projectApi = new ProjectService();

const getDefaultState = () => {
  return {
    partners: [],
    currentPartner: [],
    businessPartners: [],
    currentPartner: null
  };
};

const state = getDefaultState();

const getters = {
  getPartners: state => state.partners,
  getPartnerArticles: state => state.partnerArticles,
  getCurrentPartnerById: state => partnerId => {
    return state.currentPartner.find(
      partners => partners.partnerId == partnerId
    );
  },
  getBusinessPartners: state => {
    return state.businessPartners;
  },
  getBusinessIdByPartnerId: state => {
    return partnerId => {
      let businessId = null;
      state.businessPartners.map(p => {
        //console.log("retrieving business id", p.id, p.mappedTo, p.id == partnerId, p.mappedTo == partnerId, p.businessId, partnerId);
        if( (p.id == partnerId || p.mappedTo == partnerId) && p.businessId)
          businessId = p.businessId;
        return p;
      });
      //console.log("retrieved business id: ", businessId);
      return businessId;
    }
  },
  getCurrentAzurePartner: state => state.currentPartner,
};

const actions = {
  fetchPartnersForHc({ commit }) {

    projectApi
    .fetchBusinessPartnersForHousingCompany()
    .then(response => {
      if(Array.isArray(response.data.partners)) {
        commit("setPartners", response.data.partners);
      }
    })
    .catch(err => {
      console.log("Fetching partners failed: ", err);
      commit("setPartners", []);
    });

  },
  async fetchBusinessPartners({ commit }) {
    
    let businessIds = [];
    let partners = [];

    try {
      let azurePartnerResponse = await projectApi.fetchBusinessPartnersForHousingCompany(store.getters.getSelectedHousingCompany.hcExt);
      if(azurePartnerResponse && azurePartnerResponse.data && azurePartnerResponse.data.partners) {
        azurePartnerResponse.data.partners.map(partner => {
          if(!partner.businessId || (partner.businessId && !businessIds.includes(partner.businessId))) {
            partners.push(BusinessPartnerModel.fromAzurePartner(partner));
            businessIds.push(partner.businessId);
          } else {
            //if company was found, merge some information
            partners.map( (p, k) => {
              if(partner.businessId === p.businessId) {
                //console.log("mapping partner: ", partner.businessId, p.businessId, partner._id);
                partners[k].mappedTo = partner._id;
                //replace 
                if(partner.featuredImage && partner.featuredImage.fileUrl)
                  partners[k].imageUrl = getImageUrlFromDocumentResponseObject(partner.featuredImage, 'small');
                if(partner.logo && partner.logo.fileUrl)
                  partners[k].logoUrl = getImageUrlFromDocumentResponseObject(partner.logo, 'small');
              }
              return partner;
            });
          }
        });
      }
    } catch(err) {
      console.log(err);
    }
    
    //console.log("Fetched partners: ", partners);
    commit("setBusinessPartners", partners);
    return partners;
  },
  sendPartnerEngagementToAnalytics({ commit }, partner) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "partner_engagement",
        eventCategory: "Partner Pages",
        eventAction: "Clicks",
        eventLabel: partner.partnerName
      });

      /*window.dataLayer.push({
        partnerCompany: partner.partnerName
      });*/
    }
  },

  fetchSingleAzurePartner({commit}, id) {
    return new Promise((resolve, reject) => {
      return projectApi
        .fetchPartnerCompanyDetails(id)
        .then(response => {
          commit("setCurrentAzurePartner",response.data);
          resolve(response.data);
        })
        .catch(err => {
          console.log("Fetching Azure partner failed: ", err);
          reject(err);
        });
    });
  }
};

const mutations = {
  setPartners: (state, res) => (state.partners = res),
  setPartnerArticles: (state, res) => (state.partnerArticles = res),
  setCurrentPartnerItem: (state, res) => state.currentPartner.unshift(res),
  setBusinessPartners: (state, res) => state.businessPartners = res,
  setCurrentAzurePartner: (state, res) => state.currentPartner = res
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
