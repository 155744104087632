<template>
<div class="like-button" :class="{'like-button--active': isLiked}">
  <button type="button" class="like-button__button" @click="onClick" >
    <div class="sr-only">{{$t('community.like-button.button.aria-label')}}</div>
    <i class="fas fa-thumbs-up" v-if="isLiked"></i>
    <i class="far fa-thumbs-up" v-else></i>
  </button>
  <a class="like-button__count" @mouseover="userListVisible=true" @mouseout="userListVisible=false" @click="onLikeModalOpen" v-if="likedUsers.length">{{likeCount}}</a>
  <span class="like-button__count" @mouseover="userListVisible=true" @mouseout="userListVisible=false" v-else>{{likeCount}}</span>
  <transition name="fadefast">
    <div class="like-button__users" v-if="Array.isArray(getLikedUsers) && getLikedUsers.length && userListVisible">
        <div v-for="user in getLikedUsers.slice(0,10)" :key="user.userUuid">
          {{user.name}} {{user.apt}}
        </div>
      <div v-if="likeCount > 10 || likeCount !== getLikedUsers.length">+ {{parseInt(likeCount) - parseInt(getLikedUsers.slice(0,10).length)}}</div>
    </div>
  </transition>
  <LikesModal :likes="likedUsers" v-if="displayLikesModal" @close="displayLikesModal=false" />
</div>
</template>

<script>
import LikesModal from "./LikesModal";

export default {
  name: "LikeButton",
  components: {LikesModal},
  data() {
    return {
      isLiked: false,
      likeCount: 0,
      userListVisible: false,
      displayLikesModal: false
    }
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    liked: {
      type: Boolean,
      default: false
    ,
    },
    likedUsers: {
      type: Array
    }
  },
  computed: {
    getLikedUsers() {
      if(Array.isArray(this.likedUsers))
        return this.likedUsers.filter(u => u.name);
      else 
        return [];
    }
  },
  methods: {
    onClick() {
      if(this.isLiked)
        this.likeCount--;
      else
        this.likeCount++;
      this.isLiked = !this.isLiked;
      this.$emit('on-click', this.isLiked);
    },
    onLikeModalOpen(e) {
      if(e)
        e.preventDefault(); 
      
      this.displayLikesModal=true;
    }
  },
  mounted() {
    this.isLiked = this.liked;
    this.likeCount = this.count;
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/abstracts/_variables.scss";
  .like-button {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    position: relative;

    &__button {
      padding: .5rem;
    }

    &__count {
      display: inline-block;
      position: relative;
    }
    a.like-button__count {
      cursor: pointer;
      &:hover {
        margin-top: 1px;
        border-bottom: 1px solid $baseFontColor;
      }
    }

    &--active {
      color: $red;
      font-family: $fontPrimaryBold;
      .like-button__button {
        color: $red;
      }
      i {
        color: $red;
      }
      .like-button__count {
        color: $red;
      }
      a.like-button__count {
        &:hover {
          border-bottom: 1px solid $red;
        }
      }
    }

    &__users {
      position: absolute;
      display: block;
      z-index: 10;
      top: 2.5rem;
      width: 150px;
      max-height: 400px;
      margin-left: .3rem;
      word-wrap: break-word;
      text-transform: none;
      background: rgba($brandColorPrimary, .9);
      color: #fff;
      font-size: .7rem;
      border-radius: .4rem;
      line-height: 1.1;
      padding: .5rem;
      white-space: initial;

      span, div {
        font-size: .7rem;
        line-height: 1.2;
        margin: 0;
        padding: .15rem 0;
      }
    }
  }
</style>