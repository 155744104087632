/**
 * Miscellaneous set of utils communicating with mobile app
 */

const MOBILE_APP_PLATFORM_LOCAL_STORAGE_KEY = 'mobile-app-platform';
const MOBILE_APP_VERSION_LOCAL_STORAGE_KEY = 'mobile-app-version';


const resetMobileApplicationState = () => {
  try {
    localStorage.removeItem(MOBILE_APP_PLATFORM_LOCAL_STORAGE_KEY);
    localStorage.removeItem(MOBILE_APP_VERSION_LOCAL_STORAGE_KEY);
  } catch (err) {
    console.log(err);
  } 
}

const setMobileAppState = () => {

  resetMobileApplicationState();

  try {

    if (typeof URL === "function") {
      let url = new URL(window.location.href);
      if (url && url.searchParams) {
        let platform = url.searchParams.get("platform");
        if (platform) {
          localStorage.setItem(MOBILE_APP_PLATFORM_LOCAL_STORAGE_KEY, platform);
        }
        let version = url.searchParams.get("version");
        if (platform) {
          localStorage.setItem(MOBILE_APP_VERSION_LOCAL_STORAGE_KEY, version);
        }
      }
    }

  } catch (err) {
    console.log(err);
  } 
}

const getMobileAppState = () => {
  let ret = null;
  try {
    let platform = localStorage.getItem(MOBILE_APP_PLATFORM_LOCAL_STORAGE_KEY);
    let version = localStorage.getItem(MOBILE_APP_VERSION_LOCAL_STORAGE_KEY);
    if (platform && version) {
      return {
        platform,
        version
      };
    }
  } catch (err) {
    console.log(err);
  }

  return ret;
}

const cleanTokens = () => {
  if (window.cleanTokens) {
    try {
      cleanTokens.postMessage("Clean tokens");
    } catch (err) {
      console.error(err);
    }
  }
}

export default { resetMobileApplicationState, cleanTokens, getMobileAppState, setMobileAppState };