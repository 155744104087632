import AzureServiceRequestService from "@/services/AzureServiceRequestService";
import AzureServiceRequestModel from "@/models/ServiceRequest/AzureServiceRequest";
import { getFeature } from "@/features";
import store from "@/store";
import _ from "lodash";
import { isAxiosError } from 'axios'

const azureApi = new AzureServiceRequestService();

const getDefaultState = () => {
  return {
    serviceRequests: [],
    publicServiceRequests: [],
    serviceRequestSearchResults: [],
    currentServiceRequest: null,
    recipients: [],
    projectTypes: [],
    types: [],
    statuses: {
      open: "serviceRequest.status.open",
      inprogress: "serviceRequest.status.inprogress",
      closed: "serviceRequest.status.closed",
      declined: "serviceRequest.status.declined"
    },
    adminSearchParams: null,
    authToken: null,
    publicPage: 1,
    privatePage: 1
  };
};

const state = getDefaultState();

const getters = {
  getServiceRequests: state => _.orderBy(state.serviceRequests, ['createdTime']).reverse(),
  getPublicServiceRequests: state => _.orderBy(state.publicServiceRequests, ['createdTime']).reverse(),
  getServiceRequestSearchResults: state => state.serviceRequestSearchResults,
  getCurrentServiceRequest: state => state.currentServiceRequest,
  filterServiceRequests(state) {
    /**
     * @param object params | status: all|pending|closed
     */
    return params => {
      //console.log('filtering service requests with params: ', params);
      let ret = state.serviceRequests;

      let items = ret.filter(serviceRequest => {

        if (!params) {
          params = {
            status: "all"
          };
        }

        let rules = {};
        Object.keys(params).map(filterName => {
          rules[filterName] = false;
        });

        if (params.status == "all") {
          rules.status = true;
        } else {
          if (params.status == "closed") {
            if (serviceRequest.isClosed()) {
              rules.status = true;
            }
          } /*else {
            rules.status = true;
          }*/ else if (
            params.status == "pending"
          ) {
            if (
              serviceRequest.statusTypeKey== "PROGRESS" ||
              serviceRequest.statusTypeKey == "OPEN" ||
              (!serviceRequest.statusTypeName && !serviceRequest.closed)
            ) {
              if (!serviceRequest.isClosed()) rules.status = true;
            }
          }
        }

        //filter by publicity
        //console.log("params: ", params, typeof params.public, serviceRequest.public);
        if(typeof params.public === 'boolean') {
          if(params.public === true && serviceRequest.isPublic()) 
            rules.public = true;
          else if(params.public === false && !serviceRequest.isPublic())
            rules.public = true; 
          else 
            rules.public = false;
        }

        let rulesMet = true;
        Object.keys(rules).map(filterName => {
          if(rules[filterName] === false)
            rulesMet = false;
        });

        if (rulesMet) return serviceRequest;
      });

      return _.sortBy(items, ['createdTime']).reverse();
    };
  },
  getRecipients: state => state.recipients,
  getTypes: state => state.projectTypes,
  getAllTypes: state => state.types,
  getStatuses: state => state.statuses,
  getAdminParamsFromState: state => state.adminSearchParams,
  getServiceRequestAuthToken: state => state.authToken,
  getPrivatePage: state => state.privatePage,
  getPublicPage: state => state.publicPage
};
const actions = {
  async fetchServiceRequests({ commit }, params = {}) {
    let results = [];
    if(!params.page)
      params.page = 1;
    if (!params.limit) params.limit = 10;

    //Fetch from Azure
    if (isAzureServiceRequestsActive()) {

      //Fetch private service requests
      try {
        params.projectId = store.getters.getSelectedHousingCompany.hcExt;

        if (!params.public && store.getters.getSelectedApartment && store.getters.getSelectedApartment.aptext)
          params.aptExtId = store.getters.getSelectedApartment.aptext;

        params.public = false;
        let res = await azureApi.getList(params);
        let azureServiceRequests = [];

        //let azureServiceRequests = await azureApi.list();
        if (res.data && res.data.items) {
          azureServiceRequests = res.data.items.map(item => {
            return new AzureServiceRequestModel(item);
          });
          results = results.concat(azureServiceRequests);
        }
      } catch (err) {
        console.log(err);
      }

    }

    commit("setServiceRequests", results);
    commit("setPrivatePage", params.page);

    return results;
  },
  async fetchPublicServiceRequests({ commit }, params = {}) {
    let results = [];
    if(!params.page)
      params.page = 1;
    if (!params.limit) params.limit = 10;

      //Fetch from Azure
      if (isAzureServiceRequestsActive()) {
         //fetch public service requests
        try {
          params.projectId = store.getters.getSelectedHousingCompany.hcExt;
          params.public = true;
          let res = await azureApi.getList(params);
          let azureServiceRequests = [];

          //let azureServiceRequests = await azureApi.list();
          if (res.data && res.data.items) {
            console.log("Service requests: ", res.data.items);
            azureServiceRequests = res.data.items.map(item => {
              return new AzureServiceRequestModel(item);
            });
            results = results.concat(azureServiceRequests);
          }
        } catch (err) {
          console.log(err);
        }
      }

      commit("setPublicServiceGroups", results);
      commit("setPublicPage", params.page);
      return results;
  },
  async searchServiceRequests({ commit }, params) {
    let res = await azureApi.getList(params);
    let items = [];
    if (res.data.items && res.data.items) {
      items = res.data.items.map(
        item => new AzureServiceRequestModel(item)
      );
    }

    if(params.page > 1) {
      commit("appendServiceRequestSearchResults", items);
    } else {
      commit("setServiceRequestSearchResults", items);
    }

    return res.data;
  },
  fetchSingleAzureServiceRequest({ commit }, id) {
    return azureApi
      .getSingle(id)
      .then(res => {
        console.log("SR: ", res.data.item);
        let sr = new AzureServiceRequestModel(res.data.item);
        commit(
          "setCurrentServiceRequest", sr
        );
        commit("updateCurrentServiceRequestFromListData", sr);
      })
      .catch(e => {
        console.log(e);
      });
  },
  filterServiceRequestByID({ commit }, uuid) {
    console.log("getting single request by id", uuid, state);
    if (state.serviceRequests.length) {
      let serviceRequest = state.serviceRequests.filter(serviceRequest => {
        return serviceRequest.getId() === uuid;
      });
      //console.log("found", serviceRequest);
      if (serviceRequest.length)
        commit("setCurrentServiceRequest", serviceRequest[0]);
      else commit("setCurrentServiceRequest", null);
    }
  },
  async fetchTypes({ commit }) {
    try {
      const res = await azureApi.getTypes();
      commit("setAllTypes", res.data.types);
      commit("setProjectTypes", res.data.types);
      return res.data.types;
    } catch (err) {
      console.log(err);
    }
  },
  addAzureServiceRequest({ commit }, newRequest) {
    return new Promise((resolve, reject) => {
      const request = azureApi
        .createNew(newRequest)
        .then(response => {
          //commit("newServiceRequest", response.data);
          resolve(response);
        })
        .catch(err => {
          console.log("error inserting new service request");
          if (isAxiosError(err)) {
            reject(err.response.data.message)
          } else {
            reject(err)
          }
        });
    });
  },
  fetchAzureServiceRequestResponses({ commit }, id) {
    return new Promise((resolve, reject) => {
      azureApi
        .getResponses(id)
        .then(res => {
          commit("setServiceRequestResponses", res.data.items);
          resolve(res.data.items);
        })
        .catch(err => reject(err));
    });
  },
  addReplyToServiceRequest({ commit }, postData) {
    return new Promise((resolve, reject) => {
      azureApi
        .addResponse(postData)
        .then(res => {
          commit("appendResponseToServiceRequest", res.data);
          resolve(res.data);
        })
        .catch(err => {
          if (isAxiosError(err)) {
            reject(err.response.data.message)
          } else {
            reject(err)
          }
        });
    });
  }
};
const mutations = {
  setServiceRequests: (state, res) => {
    state.serviceRequests.push(...res);
  },
  setPublicServiceGroups: (state, res) => {
      state.publicServiceRequests.push(...res)
  },
  setServiceRequestSearchResults: (state, res) => {
    state.serviceRequestSearchResults = res;
  },
  appendServiceRequestSearchResults: (state, res) => {
    state.serviceRequestSearchResults = state.serviceRequestSearchResults.concat(res);
  },
  setCurrentServiceRequest: (state, res) => (state.currentServiceRequest = res),
  setRecipients: (state, res) => (state.recipients = res),
  setProjectTypes: (state, res) => (state.projectTypes = res),
  setAllTypes: (state, res) => (state.types = res),
  setServiceRequestResponses: (state, res) => {
    if (!Array.isArray(res) || !res.length) return;

    //Update service request in list first
    state.serviceRequests.map(item => {
      if (item.getId() === res[0].refId) {
        item.responses = res;
      }
    });

    //Then copy the same responses to current one
    state.currentServiceRequest.responses = res;
  },
  appendResponseToServiceRequest: (state, res) => {
    //Update service request in list first
    if (!res.refId) return;
    state.serviceRequests = state.serviceRequests.map(item => {
      if (item.getId() === res.refId) {
        item.responses = res;
      }
      return item;
    });

    if (
      state.currentServiceRequest &&
      Array.isArray(state.currentServiceRequest.responses)
    )
      state.currentServiceRequest.responses.push(res);
  },
  saveParamsToState: (state, res) => {
    state.adminSearchParams = res;
  },
  setServiceRequestAuthenticationToken: (state, res) => {
    state.authToken = res;
  },
  setPublicPage: (state, res) => (state.publicPage = res),
  setPrivatePage: (state, res)  => (state.privatePage = res),
  resetModule: (state) => {
    state.serviceRequests = [];
    state.publicServiceRequests = [];
    state.currentServiceRequest = {};
    state.recipients = [];
    state.types = [];
    state.publicPage = 0;
    state.privatePage = 0;
  },
  updateCurrentServiceRequestFromListData: (state, renewedServiceRequest) => {
    console.log("New service request: ", renewedServiceRequest);
    state.serviceRequests = state.serviceRequests.map(sr => {
      if(sr.getId() === renewedServiceRequest.getId())
        return renewedServiceRequest;
      else
        return sr;
    });
  }
  //newServiceRequest: (state, serviceRequest) => state.groups.unshift(serviceRequest)
};

const isAzureServiceRequestsActive = () => {
  return getFeature("service-requests");
}

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
