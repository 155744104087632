import KenticoService from "@/services/KenticoService";
import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import AppSettings from "@/globals";
import { getResultsTargetedByRole } from "@/utils/KenticoUtil";

const api = new KenticoService();
const deliveryApi = new KenticoDeliveryApiService();

const getDefaultState = () => {
  return {
    languages: [],
    workflowSteps: [],
    articles: [],
    plusUpdates: [],
    plusHelpFaq: [],
    types: []
  };
};

const state = getDefaultState();

const getters = {
  getArticlesAdmin: (state) => state.articles,
  getKenticoLanguages: (state) => state.languages,
  getKenticoWorkflowSteps: (state) => state.workflowSteps,
  getKenticoContentTypes: (state) => state.types,
  getPreviewItem: (state) => {
    try {
      if (window.localStorage) {
        let previewItem = localStorage.getItem("previewItem");
        return JSON.parse(previewItem);
      }
    } catch (err) {
      console.log(err);
    }
  },
  getLanguageCodeByIdentifier(state) {
    return (id) => {
      let code = "";

      //Default language has always
      if (id == "00000000-0000-0000-0000-000000000000")
        return AppSettings.defaultLang;

      state.languages.map((_lang) => {
        if (_lang.id == id) code = _lang.codename;
        return _lang;
      });

      return code;
    };
  },
  getLanguageIdentifierByCodename(state) {
    return (code) => {
      let lang = state.languages.filter((_lang) => {
        if (_lang.codename == code) return _lang;
      });
      if (lang.length) return lang[0].id;
      return "";
    };
  },
  getKenticoWorkflowStepIdByName(state) {
    return (name) => {
      let id = null;
      state.workflowSteps.map((step) => {
        if (step.name == name) id = step.id;
      });

      return id;
    };
  },
  getKenticoWorkflowStepById(state) {
    return (id) => {
      let step = null;
      state.workflowSteps.map((_step) => {
        if (_step.id == id) step = _step;
      });

      return step;
    };
  },
  getPlusUpdates: (state) => state.plusUpdates,
  getPlusHelpFaqs: (state) => getResultsTargetedByRole(state.plusHelpFaq),
  getPlusHelpFaqArticlesBySection: (state) => (section) => {
    let articles = getResultsTargetedByRole(
      state.plusHelpFaq.filter(function (faq) {
        if (faq.elements.faqtaxonomy && faq.elements.faqtaxonomy.value.length) {
          return faq.elements.faqtaxonomy.value[0].codename === section;
        }
      })
    );
    return articles;
  },
  getAllUngroupedPlusHelpFaqs: (state) =>
    getResultsTargetedByRole(
      state.plusHelpFaq.filter((faq) => {
        if (
          faq.elements.faqtaxonomy &&
          faq.elements.faqtaxonomy.value.length === 0
        ) {
          return true;
        }

        return false;
      })
    ),
  getKenticoContentTypeById: (state) => typeId => {
    return state.types.find(t => t.id === typeId)
  },
  getKenticoContentTypeByCodename: (state) => codename => {
    return state.types.find(t => t.codename === codename)
  }
};

const actions = {
  fetchKenticoLanguages({ commit }) {
    return api
      .fetchLanguages()
      .then((response) => {
        commit("setKenticoLanguages", response.data.languages);
      })
      .catch((err) => {
        console.log("Fetching kentico languages failed: ", err);
      });
  },
  fetchKenticoWorkflowSteps({ commit }) {
    return api
      .listWorkflowSteps()
      .then((res) => {
        commit("setKenticoWorkflowSteps", res.data);
      })
      .catch((err) => {
        console.log("Error getting workflow steps from kentico ", err);
      });
  },
  fetchKenticoContentTypes({ commit }) {
    return api
      .fetchContentTypes()
      .then((res) => {
        commit("setKenticoContentTypes", res.data.types);
      })
      .catch((err) => {
        console.log("Error getting workflow steps from kentico ", err);
      });
  },
  fetchArticlesAdmin({ commit }, params) {
    api.resetFilters();

    if (params.publish_date_from)
      api.addFilter(
        "publish_date",
        KenticoService.convertDateToKenticoDate(params.publish_date_from),
        "gte"
      );
    if (params.publish_date_to)
      api.addFilter(
        "publish_date",
        KenticoService.convertDateToKenticoDate(params.publish_date_to),
        "lte"
      );

    return new Promise((resolve, reject) => {
      api
        .fetchItems(params)
        .then((res) => {
          if (params.append) commit("appendArticlesAdmin", res.data.items);
          else commit("setArticlesAdmin", res.data.items);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fetchPlusUpdates({ commit }, params) {
    api.resetFilters();

    params.contentType = "article";

    return new Promise((resolve, reject) => {
      deliveryApi
        .fetchList(params)
        .then((res) => {
          if (params.append) commit("appendPlusUpdates", res.data.items);
          else commit("setPlusUpdates", res.data.items);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fetchPlusHelpFaqs({ commit }, params = {}) {
    let payload = Object.assign(
      {
        contentType: "faq",
        limit: 99,
        order: "question",
        orderDirection: "asc",
      },
      params
    );

    return new Promise(function (resolve, reject) {
      deliveryApi
        .fetchList(payload)
        .then((response) => {
          commit("setPlusHelpFaqs", response.data.items);
          resolve(response.data.items);
        })
        .catch((err) => {
          console.log("Fetching faq failed: ", err);
          reject(err);
        });
    });
  },
};

const mutations = {
  setKenticoLanguages: (state, res) => (state.languages = res),
  setKenticoWorkflowSteps: (state, res) => {
    state.workflowSteps = res;
  },
  setPreviewItem: (state, res) => {
    localStorage.setItem("previewItem", JSON.stringify(res));
  },
  resetPreviewItem: (state, res = {}) => {
    localStorage.setItem("previewItem", JSON.stringify({}));
  },
  setArticlesAdmin: (state, res) => (state.articles = res),
  appendArticlesAdmin: (state, res) =>
    (state.articles = state.articles.concat(res)),
  setPlusUpdates: (state, res) => (state.plusUpdates = res),
  appendPlusUpdates: (state, res) =>
    (state.plusUpdates = state.plusUpdates.concat(res)),
  setPlusHelpFaqs: (state, res) => (state.plusHelpFaq = res),
  setKenticoContentTypes: (state, res) => (state.types = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations,
};
