import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import TimelineItemModel from "@/models/TimelineItemModel";
import { getSourcesForTimeline } from "@/components/Timeline/settings.js";
import store from "@/store";
import _ from "lodash";
import dayjs from "dayjs";
import { parseXML } from "jquery";

const api = new KenticoDeliveryApiService();
api.setContentType(null);
const date_format = "YYYY-MM";

/**
 * Return items in monthly sections
 * @param {Array} items 
 * @returns 
 */
const buildTimelineSectionArray = items => {
  //build sections by month
  let sections = {};
  items.map(item => {
    let itemDate = dayjs(item.date).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
    let key = itemDate.format(date_format);
    
    if (typeof sections[key] === 'undefined')
      sections[key] = { date: itemDate.toDate(), items: [] };
    
    sections[key].items.push(item);
  });

  //order sections
  let sectionArray = Object.values(sections).sort(sortDescByDateField);
  
  //order items under section
  sectionArray = sectionArray.map(section => {
    section.items = section.items.sort(sortDescByDateField);
    return section;
  });

  return sectionArray;
};

const getDefaultState = () => {
  return {
    items: [],
    sections: [],
    params: {
      page: 1,
      limit: 50,
      skip: 0,
      initial: true
    },
    finalized: false,
    sourceStatus: null,
    selectedItem: null,
    selectedTags: []
  };
};

const getters = {
  getTimelineContent: state => state.sections,
  getAllTimelineItems: state => state.items,
  getIsTimelineFinalized: state => {
    if (state.sourceStatus) {
      let hasMoreItemsToLoad = Object.values(state.sourceStatus).find(source => source.hasMoreItems);
      return !hasMoreItemsToLoad ? true : false;
    }

    return false;
  },
  getTimelineOffsetDate: state => {
    if (state.sourceStatus) {
      let newestDate = null;
      Object.values(state.sourceStatus).map(source => {
        if (source.hasMoreItems) {
          if (newestDate == null)
            newestDate = source.lastItemDate;
          else if (newestDate.valueOf() < source.lastItemDate.valueOf())
            newestDate = source.lastItemDate;
        }
      });

      return newestDate;
    }

    return null;
  },
  getSelectedTimeLineItem: state => state.selectedItem,
  getSelectedTimeLineTags: state => state.selectedTags
};

const state = getDefaultState();
const actions = {
  async fetchTimelineContent({ commit }, params = {}) {
    try {

      //reset in initial load
      if (params.initial) {
        commit("setTimelineSourceStatus", null);
      }

      let payload = { ...state.params };

      let items = [];
      let sourceStatus = {};
      let res = await Promise.all(
        getSourcesForTimeline().map(async source => {

          let hasMoreItems = false;
          let itemsReceived = [];

          //If initial page load and getter is empty or not initial page load but source is "paginatable"
          if (
            (params.initial && (!Array.isArray(store.getters[source.getter]) || store.getters[source.getter].length === 0) ) ||
            source.pagination === true
            ) {
            try {
              
              //append possible default dispatcher args from source
              if (source.dispatcherArgs)
                payload = { ...payload, ...source.dispatcherArgs }
              
              let res = await store.dispatch(source.dispatcher, { ...payload });

              itemsReceived = Array.isArray(store.getters[source.getter]) ? store.getters[source.getter] : [];

              if (Array.isArray(itemsReceived)) {
                itemsReceived = itemsReceived.map(source.mapper);
                items = items.concat(itemsReceived);
                
                //if we fetched max number of items, let's assume we have more to paginate
                if (itemsReceived.length > 0 && itemsReceived.length === payload.limit)
                hasMoreItems = true;
              }
            } catch (err) {
              console.log(err);
            }
          }

          //If initial timeline request, but getter has already items
          else if (
            params.initial &&
            typeof store.getters[source.getter] !== 'undefined' &&
            store.getters[source.getter].length
          ) {
            itemsReceived = Array.isArray(store.getters[source.getter]) ? store.getters[source.getter].map(source.mapper) : [];
            items = items.concat(itemsReceived);
          }

          sourceStatus[source.name] = {
            lastItemDate: itemsReceived.length ? itemsReceived.sort(sortDescByDateField).reverse()[0].date : null,
            hasMoreItems: hasMoreItems && source.pagination ? true : false
          }
        })
      )

      //set source status into state
      commit("setTimelineSourceStatus", sourceStatus);
      
      //Remove possible duplicates and items that are created before user registered to plus
      items = getValidItems(items);
      
        
      if(payload.initial === true)
        commit("setTimelineContent", items);
      else 
        commit("appendTimelineContent", items);

      if (items.length === 0)
        commit("setTimelineFinalized", true);
      
    } catch (err) {
      console.log(err);
    }
  } 
};

const mutations = {
  setTimelineContent: (state, items) => {
    items = _.sortBy(items, ['date'], ['desc']);
    state.items = items;
    
    state.sections = buildTimelineSectionArray(state.items);
  },
  appendTimelineContent: (state, items = []) => { 
    let newItemArray = state.items.concat(items);

    state.items = _.sortBy(newItemArray, ['date'], ['desc']);
    state.sections = buildTimelineSectionArray(state.items);
  },
  addTodayToTimeline: (state) => {
    let isTodayAdded = state.items.find(item => item.type == 'today');
    if (!isTodayAdded) {
      let item = new TimelineItemModel({
        id: 'timeline-default-item-today',
        type: 'today',
        date: new Date()
      });
      state.items.push(item);
    }
    state.sections = buildTimelineSectionArray(state.items);
  },
  setTimelineParams: (state, params) => (state.params = params),
  setTimelineFinalized: (state, res) => (state.finalized = res === true ? true : false),
  setTimelineSourceStatus: (state, res) => (state.sourceStatus = res),
  setSelectedTimeLineItem: (state, res) => (state.selectedItem = res),
  setSelectedTimeLineTags: (state, res) => (state.selectedTags = res),
  resetTimelineModule: (state, resetRealState = true) => {
    if (resetRealState) {
      state.items = [];
      state.sections = [];
      state.params = {
        page: 1,
        limit: 50,
        skip: 0,
        initial: true
      };
      state.finalized = false;
      state.sourceStatus = null;
    }
    state.selectedItem = null;
    state.selectedTags = [];
  }
};

/**
 * 
 * @param {*} a 
 * @param {*} b 
 * @returns 
 */
const sortDescByDateField = (a, b) => {
  return b.date - a.date;
};

/**
 * 
 * @param {*} items 
 * @returns 
 */
const getValidItems = items => {
  let userRegistrationDate =
    store.getters.getCurrentUser.registerDate ?
      dayjs(store.getters.getCurrentUser.registerDate).set('hour', 0).set('minute', 0).set('second', 0) : null;
  let offsetDateAsUnixTimeStamp = store.getters.getTimelineOffsetDate ? dayjs(store.getters.getTimelineOffsetDate).valueOf() : null;
  
    return items.filter(newItem => {
      let found = state.items.find(i => i.id === newItem.id);

      if (found)
        return false;
      
      /*if (userRegistrationDate && dayjs(newItem.date).valueOf() < userRegistrationDate.valueOf()) {
        return false;
      }*/

      if (offsetDateAsUnixTimeStamp && dayjs(newItem.date).valueOf() < offsetDateAsUnixTimeStamp) {
        return false;
      }
      
      return true;
    });
}

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
