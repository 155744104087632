const calendarFeature = {
    id: "calendar",
    name: "feature.calendar.name",
    iconClass: "fal fa-calendar-alt",
    globalSettings: {},
    schema: {},
    forceActive: true,
    initialize: (feature, settings) => {
       
    }
};

export { calendarFeature };