import { getParmFromHash } from "@/utils/StringUtils";
//import { includeAuthToken } from "@/utils/JwtUtil";
import { getURLParam } from "@/utils/StringUtils";
import store from "@/store";
import { includeLiferayMasterJwt, includeAzureLiferayJwt } from "@/services/AzureLiferayClient";
import { includeJwt } from "@/services/AzureFunctionClient";
import {includeLiferayJwt} from "@/services/LiferayClient";
import UserService from "@/services/UserService";
import { AzureFunctionClient, authInterceptor } from "./AzureFunctionClient";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios from 'axios';

let api = new UserService();
//api.initAuthInterceptor();

const TOKEN_KEYS = {
  b2c: {
    ACCESS: 'b2c_access_token',
    AUTH: 'b2c_auth_token',
    EXPIRY: 'b2c_token_expiry'
  },
  YITPlus: {
    ACCESS: 'liferay_access_token',
    AUTH: 'liferay_auth_token',
    EXPIRY: 'liferay_token_expiry'
  }
}

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/


const includeTokensToClients = (token = null, issuer = null) => {
  if(token && issuer) {
    includeJwt(token);
    includeLiferayJwt(token);
    includeAzureLiferayJwt(token);
  }

  //If userdetails are fetched, let's do some magic to get access token to some of the clients
  if(store.getters.getTokenIssuer == 'YITPlus') {
    let liferayAccessToken = store.getters.getCurrentUser.jwt;
    if(liferayAccessToken) {
      TokenService.saveToken('ACCESS', 'YITPlus', liferayAccessToken);
      store.commit('setAccessToken', liferayAccessToken);
      //includeLiferayMasterJwt();
    }
  }
}

const TokenService = {
  /**
   * First, try to get liferay token and fallback to b2c token
   */
  async initToken(refresh=null) {
    let token = getURLParam(location.href, "jwt");  //Liferay
    let issuer = 'YITPlus';
    let mobile = false;
    if(!token) {
      token = getParmFromHash(location.href,"code");  //b2c
      if(token)
        issuer = 'b2c';
    }
    // mobile app
    if(!token) {
      token = getParmFromHash(location.href,"apptoken");  //b2c
      if(token)
        issuer = 'b2c';
        mobile = true;
    }
  
    if(issuer)
      store.commit('setTokenIssuer', issuer);
   
      if (window.localStorage) {
        if(this.getToken('AUTH', 'b2c')) {
          store.commit('setTokenIssuer', 'b2c');
          issuer = 'b2c';
        };
      }
    //if issuer b2c get actual token from token endpoint
    if((token && issuer == 'b2c') && !mobile) {
      try {
        token = await this.getIdToken(token, issuer, refresh);
      } catch (error) {
        throw error;
      }
    }
    

    //if token was present, add it to store
    if (token) {
      try {
        if (window.localStorage) {
          this.saveToken('AUTH', issuer, token);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      let localStorageIdToken = null;
      if (window.localStorage) {
        localStorageIdToken = this.getToken('AUTH', 'b2c');
        if(localStorageIdToken)
          issuer = 'b2c';
        if(!localStorageIdToken)
          localStorageIdToken = this.getToken('AUTH', 'YITPlus');
      }
      if (localStorageIdToken) {
        token = localStorageIdToken;
      } else {
        token = null;
      }

    }

    store.commit('setAuthToken', token);
    
    //b2c has same access token than auth token
    if(issuer == 'b2c' && token)
      store.commit('setAccessToken', token);

     includeTokensToClients(token, issuer);
  },

  getToken(type, issuer = 'YITPlus') {
      return localStorage.getItem(TOKEN_KEYS[issuer][type]);
  },
  saveToken(type, issuer, token) {
    localStorage.setItem(TOKEN_KEYS[issuer][type], token)
  },
  async refreshToken() {
    let token = await this.getIdToken(null, 'b2c');
    store.commit('setTokenIssuer', 'b2c');
    store.commit('setAccessToken', token);
    store.commit('setAuthToken', token);
    includeTokensToClients(token, 'b2c');
    return token;
  },
  removeToken(type, issuer) {
    try {  
      localStorage.removeItem(TOKEN_KEYS[issuer][type]);
    } catch(err) {
      console.log(err);
    }
  },

  async getIdToken(token=null, issuer=null, refresh) {
    try {
      const authToken = await api.getAccessToken(token);
      if(authToken && authToken.data && authToken.data.id_token) {
        token = authToken.data.id_token;
        if (window.localStorage) {
          let expiry = authToken.data.not_before + authToken.data.id_token_expires_in;
          this.saveToken("EXPIRY", issuer, expiry)
        }
      } else {
        token = null;
      }
      return token;
      
    } catch(err) {
      throw err;
    }
  }

}

/*const refreshAuthLogic = (failedRequest)  => {
      console.log("FAILED", failedRequest);
      console.log("ISSUER", store.getters.getTokenIssuer);
      let refreshToken = null;
      if(refreshToken) {
        api.getAccessToken(refreshToken, true).then(tokenRefreshResponse => {
          if (window.localStorage && refreshToken) {
            TokenService.saveToken('REFRESH', store.getters.getTokenIssuer, tokenRefreshResponse.data.refresh_token);
            TokenService.saveToken('AUTH', store.getters.getTokenIssuer, tokenRefreshResponse.data.id_token);
          }
            store.commit('setAuthToken', tokenRefreshResponse.data.id_token);
            store.commit('setAccessToken', tokenRefreshResponse.data.id_token);

            includeTokensToClients(tokenRefreshResponse.data.id_token, 'b2c');
          
          failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.id_token;
          return Promise.resolve("ok");
      });
    } 

    return Promise.resolve("ok");
}*/


const refreshToken = (store, cb)  => {
  let refreshToken = null;
  if (window.localStorage && store.getters.getTokenIssuer == 'b2c') {
    refreshToken = this.getToken('REFRESH', 'b2c');
  }
  if(!refreshToken) return null;

    const chained = store.state.auth.accessTokenRefreshingCall.then(cb);
    store.commit('setRefreshingCall', chained);
    return chained;
  
  store.commit('setAccessTokenRefreshingState', true);
  const refreshingCall = api.getAccessToken(token, true).then((response) => {
      let token = response.id_token;
      TokenService.saveToken('access', 'liferay', token);
      store.commit('setAccessTokenRefreshingState', false);
      store.commit('setRefreshingCall', undefined);
      return Promise.resolve(token);
  }).then(cb);
  store.commit('setRefreshingCall', refreshingCall);
  return refreshingCall;
};

const axiosNoAuthInterceptor = async (error) => {

  const status = error.response ? error.response.status : null;
  let responseData = error && error.response && error.response.data ? error.response.data : null;

  if (status === 401 && responseData && responseData.error == 'TOKEN_EXPIRED') {
      console.log("TOKEN EXPIRED");
      
  }

  return Promise.reject(error);
};

export { TokenService, refreshToken, axiosNoAuthInterceptor, includeTokensToClients }