<template>
  <article class="card-item">
    <router-link :to="url" v-if="ctaButtonText">
      <div
        class="card-item__thumbnail card-item__thumbnail--bg"
        v-if="getThumbnailUrl"
        :style="{backgroundImage: 'url('+getThumbnailUrl+')'}"
      >
        <!--<img :src="getThumbnailUrl" />-->
      </div>
      <div class="card-item__badge badge badge--right bg--red" v-if="badge">{{badge}}</div>
    </router-link>

    <div class="card-item__body">
      <div class="card-item__meta">
        <slot name="header">
          <span class="card-item__date" v-if="date">{{$filters.date_format(date, $i18n.locale)}}</span>
          <span class="card-item__headline" v-if="headline">{{headline}}</span>
          <span class="card-item__sticky" v-if="sticky">
            <i class="fal fa-thumbtack"></i>
          </span>
        </slot>
      </div>
      <div class="card-item__title text--break-long-words">{{title}}</div>
      <div class="card-item__description" v-if="description">
        <slot>{{description}}</slot>
      </div>
      <div class="card-item__footer">
        <slot name="footer">
          <div class="card-item__cta">
            <router-link
              :to="url"
              class="button button--primary"
              v-if="ctaButtonText"
            >{{ctaButtonText}}</router-link>
          </div>
        </slot>
        <slot name="footerBottom"></slot>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "Card",
  props: {
    title: {
      type: String
    },
    headline: {
      type: String
    },
    date: {
      type: String
    },
    sticky: {
      type: Boolean
    },
    description: {
      type: String
    },
    thumbnailSrc: {
      type: String
    },
    url: {},
    urlTarget: {
      type: String
    },
    ctaButtonText: {
      type: String
    },
    useKenticoThumbnailer: {
      type: Boolean,
      default: false
    },
    badge: {
      type: String
    }
  },
  data() {
    return {
      thumbnailWidth: 350,
      thumbnailHeight: 350,
      imageQuality: 70
    };
  },
  computed: {
    getThumbnailUrl() {
      if (!this.thumbnailSrc) return "";
      let url = this.thumbnailSrc;
      if (this.useKenticoThumbnailer)
        url +
          `?w=${this.thumbnailWidth}&h=${this.thumbnailHeight}&fit=crop&q=${this.imageQuality}`;
      return url;
    }
  },
  mounted() {}
};
</script>