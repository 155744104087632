<template>
    <a v-if="href && typeof href == 'string' && href.indexOf('http') !== -1" :target="target ? target : '_blank'" :href="href" class="content-pick action-link padding--default">
        <div class="content-pick__icon" v-if="iconCssClass">
            <i :class="iconCssClass"></i>
        </div>
        <div class="content-pick__title--medium">
            <slot><span v-if="text">{{ text }}</span></slot>
        </div>
        <a class="content-pick__arrow-link">
            <i class="fal fa-chevron-right"></i>
        </a>
    </a>
    <router-link v-else :to="href" :target="target" class="content-pick action-link padding--default">
        <div class="content-pick__icon" v-if="iconCssClass">
            <i :class="iconCssClass"></i>
        </div>
        <div class="content-pick__title--medium">
            <slot><span v-if="text">{{ text }}</span></slot>
        </div>
        <a class="content-pick__arrow-link">
            <i class="fal fa-chevron-right"></i>
        </a>
    </router-link>
</template>

<script>
export default {
  name: "ActionLinkItem",
  props: ["icon", "href", "iconCssClass", "text", "target"],
  computed: {
    getHref() {
      let href = "#";

      if (href) {
      }

      return ret;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
.action-link {
  border-top: 1px solid $borderColorLight;
  border-bottom: 0;
  align-items: center;
  margin-bottom: 0;
  &:last-child {
    border-bottom: 1px solid $borderColorLight;
  }
}
</style>
