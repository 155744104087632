export default (user, { can, cannot, rules }) => {
  if (user.hasRole("yitMarketing") || user.hasRole("yitBusinessAdmin")) {
    can("manage", "article");
    can("manage", "event");
    can("manage", "campaign");
    can("manage", "feedback");
    can("manage", "contact");
  }

  if (user.hasRole("yitProject") || user.hasRole("yitProjectInternal")) {
    can("manage", ["article", "event", "contact"]);
    /*can("manage", ["article", "event", "contact"], {
      "buildingTaxonomyValues": {
        $in: user.getProjects()
      }
      });*/
  }


  if (user.hasRole("propertyManager") || user.hasRole("maintenanceManager") || user.hasRole("boardMember") || user.hasRole("chairman") || user.hasRole("ceeBoardMember") || user.hasRole("ceeMaintenanceManager") || user.hasRole('contentPublisher') ) {
    can("create", "article");
    can("create", "event");

    can("manage", ["article", "event"], {
      "user_id": { $eq: user.id }
    });

    if (user.hasRole("propertyManager")) {
      can("create", ["article", "event", "contact"]);
      can("manage", ["article", "event"], {
        "buildingTaxonomyValues": {
          $in: user.getProjects(),
        },
        "authorRoles": { $nin: ['yitUser', 'yitBusinessAdmin', 'yitMarketing', 'admin', 'yitAdmin', 'yitProject', 'boardMember', 'chairman', 'maintenanceManager', 'ceeBoardMember'] }
      });
      can("manage", ["contact"], {
        "buildingTaxonomyValues": {
          $in: user.getProjects(),
        },
        "authorRoles": { $nin: ['yitUser', 'yitBusinessAdmin', 'yitMarketing', 'admin', 'yitAdmin', 'yitProject'] }
      });

    } else if (user.hasRole("boardMember") || user.hasRole("chairman")) {
      can("create", ["article", "event"]);
      can("manage", ["article", "event"], {
        "buildingTaxonomyValues": {
          $in: user.getProjects()
        },
        "authorRoles": { $nin: ['yitUser', 'yitBusinessAdmin', 'yitMarketing', 'admin', 'yitAdmin', 'yitProject'] }
      });
    }
  }

  if(user.hasRole('contentPublisher')) {
    can("create", "article");
    can("create", "event");

    can("manage", ["article", "event"], {
      "user_id": { $eq: user.id }
    });
  }
  
  if(user.hasRole("boardMember") || user.hasRole("chairman")) {
    can("create", ["article", "event"]);
  }


  if(user.hasRole("partner")) {
    can(['create'], 'campaign');
    if (Array.isArray(user.partnerCompanies)) {
      user.partnerCompanies.map(c => {
        can("manage", "campaign", {
          partnerExternalId: c._id
        });
        return c;
      });
    }
    can("manage", ["campaign"], {
      "user_id": { $eq: user.id }
    });
  }
}