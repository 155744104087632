<template>
  <div class="campaign-list campaign-list--ads" v-if="!loading.campaigns && !loading.liferayAds">
    <div class="row" v-if="getItems && getItems.length">
      <div
        :class="gridClass ? gridClass : 'col-sm-6'"
        v-for="(campaign, key) in getItems"
        :key="key"
      >
        <KenticoAdvertisement :campaign="campaign" v-if="campaign.source == 'kentico' && campaign.id" />
        <LiferayCampaign :campaign="campaign" v-else-if="campaign && campaign.campaignId" />
        <Campaign :campaign="campaign" v-else-if="campaign && campaign.campaign_ID" />
      </div>
    </div>
    <div v-else>
      <p class="no-results">{{$t('campaigns.no-items-found')}}</p>
    </div>
  </div>
  <div class="text-center" v-else>
    <LoaderIcon />
  </div>
</template>   
<script>
import LiferayCampaign from "@/components/Campaign/LiferayCampaign";
import KenticoAdvertisement from "@/components/Campaign/KenticoAdvertisement";
import Campaign from "@/components/Campaign/Campaign";
import { mapGetters, mapActions } from "vuex";
import {getRandom} from "@/utils/ArrayUtil";

export default {
  name: "CampaignList",
  props: ["items", "source", "type", "mode", "gridClass", "count", "fetch", "sortBy"],
  components: {
    LiferayCampaign,
    Campaign,
    KenticoAdvertisement
  },
  data() {
    return {
      loading: {
        offers: false,
        categories: false,
        campaigns: false,
        ads: false,
        liferayAds: false
      }
    };
  },
  computed: {
    ...mapGetters(["getCampaigns"]),
    getCount() {
      return this.count > 0 ? this.count : 99;
    },
    getItems() {
      let campaigns = [];

      if (this.fetch && !this.items) {
        if (this.getCampaigns && this.getCampaigns.length)
          campaigns = campaigns.concat(this.getCampaigns);
        
      } else if (this.items && this.items.length) campaigns = this.items;

      //map start and end date properties
      campaigns = campaigns.map(campaign => {
        if (campaign.start && !campaign.activeStartDate)
          campaign.activeStartDate = campaign.start;
        else if (!campaign.start && campaign.activeStartDate)
          campaign.start = campaign.activeStartDate;

        if (campaign.end && !campaign.activeEndDate)
          campaign.activeEndDate = campaign.end;
        else if (!campaign.end && campaign.activeEndDate)
          campaign.end = campaign.activeEndDate;

        return campaign;
      });

      /**
       * filter sticky campaigns out of the list
       */
      let stickyCampaigns = [];
      campaigns = campaigns.filter(c => {
        console.log("campaign: ", c.sticky, new Date(c.stickyExpirationDate).getTime() > new Date().getTime(),  new Date(c.stickyExpirationDate));
        if(c.sticky === true || ( c.stickyExpirationDate && new Date(c.stickyExpirationDate).getTime() > new Date().getTime()) ) {
          stickyCampaigns.push(c);
          return false;
        } else
          return true;
      });

      /**
       * Sorting of the returned campaign array
       * Random: Pick [n] count of elements from 20 "first"
       */
      if(this.sortBy == 'random') {

        campaigns = campaigns
        .sort((a, b) => {
          a.start > b.start ? 1 : -1;
        })
        .reverse().slice(0, 20);
        
        if(stickyCampaigns.length > this.getCount) {
          campaigns = getRandom(stickyCampaigns, this.getCount);
        } else {
          campaigns = stickyCampaigns.concat( getRandom(campaigns, this.getCount - stickyCampaigns.length) );
        }
        //campaigns = getRandom(campaigns, this.getCount);

        console.log("getting campaigns randomly: ", campaigns);

        return campaigns;

      } else if (this.sortBy == 'ending') {
        console.log("getting campaigns by ending date");

        if(stickyCampaigns.length > this.getCount) {
          campaigns = stickyCampaigns.sort(this.sortByEndingDate);
        } else {
          campaigns = campaigns.slice(0, this.getCount - stickyCampaigns.length);
          campaigns = stickyCampaigns.sort(this.sortByEndingDate).concat(campaigns.sort(this.sortByEndingDate));
        }

      } else {
        console.log("getting campaigns by start date");

        if(stickyCampaigns.length > this.getCount) {
          campaigns = stickyCampaigns.sort(this.sortByStartingDate);
        } else {
          campaigns = campaigns.slice(0, this.getCount - stickyCampaigns.length);
          campaigns = stickyCampaigns.sort(this.sortByStartingDate).concat(campaigns.sort(this.sortByStartingDate));
        }
      }

      return campaigns.slice(0, this.getCount);
    }
  },
  methods: {
    ...mapActions(["fetchCampaignsForHc"]),
    sortByEndingDate(a, b) {
      return new Date(a.end) - new Date(b.end);
    },
    sortByStartingDate(a, b) {
      return new Date(a.start) - new Date(b.start);
    }
  },
  async mounted() {
    if (this.fetch) {
      if (!this.getCampaigns || this.getCampaigns.length == 0) {
        try {
          this.loading.liferayAds = true;
          await this.fetchCampaignsForHc();
        } catch(err) {
          console.log(err);
        } finally {
          this.loading.liferayAds = false;
        }
      }
    }

    if(this.getItems.length > 0)
      this.$emit('on-items-to-display');
    else
      this.$emit('on-no-items-to-display');
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
.campaign {
  .campaign-partner {
    font-size: 0.9rem;
    padding: 0.3rem 0 0.2rem 0;
  }
  .campaign-duration {
    color: $fontColorLight;
    padding-top: 1.8rem;
    font-size: 0.7rem;
  }
}
@media screen and (min-width: $breakpoint-xxl) {
  .campaign-list--ads *[class*="col-"] {
    flex-basis: 33%;
  }
}
</style>