export default [
  {
    path: "/admin",
    name: "/admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/AdminView.vue"),
    meta: {
      admin: true,
      layout: "admin-frontpage"
    }
  },
  {
    path: "/admin/settings",
    name: "/admin/settings",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Settings.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/content/copy/:entity",
    name: "/admin/content/copy/:entity",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Common/Copy.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/settings/features",
    name: "/admin/settings/features",
    meta: {
      sectionTitle: "Features",
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['admin']
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/Admin/Features/Index.vue"
      )
  },
  {
    path: "/admin/feedback",
    name: "/admin/feedback",
    meta: {
      admin: true,
      layout: "admin-default",
      sectionTitle: "Feedback",
      accessPolicies: {
        roles: ['yitUser']
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/Admin/Feedback/List.vue"
      )
  }
];