export default (user, { can, cannot, rules }) => {

  if (user.hasRole("yitMarketing") || user.hasRole("yitBusinessAdmin") || user.hasRole("yitProject")) {
    can("read", "warranty-dashboard");
    can("read", "warranty-reports");
    can('read', 'warranty-apartments');
    can("read", "warranty-notes");
    can("read", "warranty-report-details");
    can("read", "warranty-report-owners");
  }

   //Warranty dashboard
  if (user.hasRole("propertyManager") || user.hasRole("boardMember") || user.hasRole("chairman")) {
    can("read", "warranty-dashboard");
  }

  if (user.hasRole("propertyManager")) {
    can("read", "warranty-reports");
    //can('read', 'warranty-apartments')
    can("read", "warranty-notes");
  }

  if (user.hasRole("boardMember") || user.hasRole("chairman")) {
    can("read", "warranty-reports");
    can("read", "warranty-notes");
  }
}