import { i18n } from "@/plugins/i18n";

const CONTACTS_CATEGORIES_FOR_PRIORITIZATION = {
  'contact_building_administration': 1,
  'contact_property_management': 2,
  'contact_about_maintenance': 3,
  'contact_construction_site': 4,
  'contact_about_material_selections_and_material_changes': 5,
  'contact_about_changes_to_house_stuctures_and_layout': 5
};

export default class ContactModel {
    constructor(item) {
      this.id = null;
      this.codename = "";
      this.name = "";
      this.title = "";
      this.email = "";
      this.opening_hours = "";
      this.phone_number = "";
      this.info = "";
      this.isPrimary = false;
      this.isYitDefaultContact = false;
      this.buildingTaxonomies = [];
      this.contactTaxonomies = [];
      this.prioritizationNumber = 999;
      this.phoneNumberAdditionalInformation = "";

      if (item) {
        if (item.elements && item.system) {
          this.fromKenticoModel(item);
        }

        else if (item.externalId && item.companyDetails) {
          this.fromPartnerCompanyModel(item);
        }
      }
    }

    /**
     * Build from Kentico model
     * @param {*} item 
     */
  fromKenticoModel(item) {
    
    if (!item || !item.elements)
      return;

    this.id = item.system.id;
    this.codename = item.system.codename;

    this.name = item.elements.name ? item.elements.name.value : "";
    this.title = item.elements.title ? item.elements.title.value : "";
    this.email = item.elements.email ? item.elements.email.value : "";
    this.opening_hours = item.elements.opening_hours ? item.elements.opening_hours.value : "";
    this.phone_number = item.elements.phone_number ? item.elements.phone_number.value : "";
    this.info = item.elements.info ? item.elements.info.value : "";
    this.phoneNumberAdditionalInformation = item.elements.phone_number__additional_information ? item.elements.phone_number__additional_information.value : "";
  
    if(item.elements.buildingtaxonomy) {
      this.buildingTaxonomies = item.elements.buildingtaxonomy.values;
    }

    if (item.elements.contact_taxonomy && Array.isArray(item.elements.contact_taxonomy.value)) {
      this.contactTaxonomies = item.elements.contact_taxonomy.value;
      item.elements.contact_taxonomy.value.map(term => {
        if (term.codename && typeof CONTACTS_CATEGORIES_FOR_PRIORITIZATION[term.codename] != 'undefined') {
          if (this.prioritizationNumber > CONTACTS_CATEGORIES_FOR_PRIORITIZATION[term.codename])
            this.prioritizationNumber = parseInt(CONTACTS_CATEGORIES_FOR_PRIORITIZATION[term.codename]);
        }
      })
    }
  
    if (item.elements && item.elements.primary_contact_information && Array.isArray(item.elements.primary_contact_information.value) && item.elements.primary_contact_information.value.length ) {
      this.isPrimary = true;
    }
    if (item.elements && item.elements.yit_default_contact && Array.isArray(item.elements.yit_default_contact.value) && item.elements.yit_default_contact.value.length ) {
      this.isYitDefaultContact = true;
    }

  }

  /**
   * 
   * @param {*} item 
   */
  fromPartnerCompanyModel(item) {
    this.id = item.externalId;
    if (item.companyDetails) {
      this.name = item.companyDetails.name ? item.companyDetails.name : "";
      this.email = item.companyDetails.email ? item.companyDetails.email : "";
      //this.opening_hours = item.companyDetails.opening_hours ? item.companyDetails.opening_hours : "";
      this.phone_number = item.companyDetails.phone ? item.companyDetails.phone : "";
      //this.info = item.companyDetails.info ? item.companyDetails.info : "";

      if (item.companyDetails.type == 'PropertyManager') {
        this.title = i18n.global.t('partnerCompany.type.PROPERTY_MANAGER');
      }
      else if (item.companyDetails.type == 'MaintenanceManager') {
        this.title = i18n.global.t('partnerCompany.type.MAINTENANCE_MANAGER');
      }
    }
  }
}

export { ContactModel, CONTACTS_CATEGORIES_FOR_PRIORITIZATION }