<template>
  <div class="container-fluid">
    <div class="row" v-if="isLogged">
      <div class="col-lg-4 sidebar d-none d-lg-block">
        <MainNavigation />
      </div>
      <div class="col-lg-8 main">
        <main id="main-content">
          <PageHeadSection 
            v-if="$route.meta.displayHeadSection"
            :title="getTitle" 
            :lead="$route.meta.introduction ? $t($route.meta.introduction) : ''" />
          <slot />
        </main>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <div class="col-lg-10">
        <main id="main-content">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import MainNavigation from "@/components/Navigation/MainNavigation";
import PageHeadSection from "@/views/partials/PageHead";
import {mapGetters} from "vuex";

export default {
  name: "LayoutDefault",
  components: {
    MainNavigation,
    PageHeadSection
  },
  computed: {
    ...mapGetters(['isLogged']),
    getTitle() {
      if(this.$route.meta.sectionTitle)
        return this.$t(this.$route.meta.sectionTitle);
      else if(this.$route.meta.title)
        return this.$t(this.$route.meta.title);
      else
        return '';
    }
  }
};
</script>