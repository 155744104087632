export default [
  {
    path: "/material-selection/deadline/:id",
    name: "/material-selection/deadline/:id",
    component: () =>
      import(
        /* webpackChunkName: "my-apartment" */ "@/views/Apartment/MaterialSelection/Deadline.vue"
      ),
    meta: {
      sectionTitle: "navigation.material-selection",
      title: "navigation.material-selection"
    }
  },
  {
    path: "/material-selection",
    name: "/material-selection",
    component: () =>
      import(
        /* webpackChunkName: "my-apartment" */ "@/views/Apartment/MaterialSelection.vue"
      ),
    meta: {
      sectionTitle: "navigation.material-selection",
      title: "navigation.material-selection",
      introduction: "navigation.material-selection.introduction",
      displayHeadSection: true
    }
  },
  {
    path: "/my-apartment/material-selection",
    redirect: "/material-selection"
  }
];