export default (user, { can, cannot, rules }) => {
  if (Array.isArray(user.hcs)) {
    user.hcs.map(hc => {
      if (Array.isArray(hc.roles) && hc.roles.includes('rentalManager')) {
        cannot("create", ["message", "conversation"]);
        cannot("read", "neighbours");
      } else {
        can("create", ["message", "conversation"]);
        can("read", "neighbours");
      }
    })
  }

  //if (user.hasRole("owner")) {
  can(["update", "delete"], "message", { userUuid: user.id });
  can(["update", "delete"], "comment", { userUuid: user.id });
  can(["update"], "conversation", {
    creatorUuid: user.id
  });
  can(["delete"], "conversation", {
    creatorUuid: user.id,
    messageCount: { $lt: 2 }
  });
}