import LiferayClient from "./LiferayClient";
import {
  AzureFunctionClient
} from "./AzureFunctionClient";
import BaseService from "./BaseService";
import store from "@/store/index";
import settings from "@/globals";
import {AzureLiferayClient} from "./AzureLiferayClient";


export default class DocumentService extends BaseService {
  constructor() {
    super();
    this.baseUrl = "/delegate/yithome-api/functions";
    this.hcID = null;
  }

  /**
   * Override BaseService function
   
  routeViaLiferay() {
    return false;
  }*/

  /**
   * Fetch Partner Content
   * @param postData New conversation object
   */

  newConversation(postData) {
    if(this.routeViaLiferay() && false) {
      
      if(!postData.projectId && !postData.buildingTaxonomy)
        postData.projectId = store.getters.getSelectedHousingCompany.hcExt;

      return AzureLiferayClient.post(`${this.baseUrl}/newConversation`, postData);
    } else {
      //postData.userUuid = store.getters.getUserDetails.id;
      if(!postData.projectId && !postData.buildingTaxonomy)
        postData.projectId = store.getters.getSelectedHousingCompany.hcExt;
      
      if(!postData.apartmentId && store.getters.getSelectedApartment)
        postData.apartmentId = store.getters.getSelectedApartment.aptext;

      return AzureFunctionClient.post(`/newConversation`, postData);
    }
  }

  /**
   * Fetch Partner Content
   * @param postData New conversation object
   */

  newMessage(postData) {
    if(this.routeViaLiferay() && false) {
      return AzureLiferayClient.post(`${this.baseUrl}/newMessage`, postData);
    } else {
      //postData.userUuid = store.getters.getCurrentUser.id;
      return AzureFunctionClient.post(`/newMessage`, postData);
    }
  }

  /**
   * Fetch Partner Content
   * @param userId
   */

  fetchConversationsForUser(projectId = null) {
    let params = {};
    if (projectId)
      params.projectId = projectId;
    
    let query = this._buildUrl(`/getConversationsForUser`, params);
    return AzureFunctionClient.get(query);
  }

  /**
   * Fetch Partner Content
   * @param postData New conversation object
   */

  fetchTargetUsers() {
    if (this.routeViaLiferay()) {
      return LiferayClient.get(
        `/delegate/yithome-api/messages/get-target-users/${this.getHcId()}`
      );
    } else {
      let params = {
        projectId: store.getters.getSelectedHousingCompany.hcExt
      };
      let query = this._buildUrl(`/getUsersForPrivateMessages`, params);
      return AzureFunctionClient.get(query);
    }
  }

  /**
   * Fetch Partner Content
   * @param cid Conversation ID
   */
  fetchConversation(cid, params = {}) {

    /*if(!params.limit)
      params.limit = settings.community.itemsPerPage;
    if(!params.skip)
      params.skip = 0;*/

    if (this.routeViaLiferay() && false) {
      let query = this._buildUrl(`${this.baseUrl}/getConversationMessages/${cid}`, params);
      return LiferayClient.get(query);
    } else {
      params.cid = cid;
      params.userId = store.getters.getCurrentUser.id;

      let query = this._buildUrl(`/getConversationMessages`, params);
      return AzureFunctionClient.get(query);
    }
  }

  /**
   * Fetch Partner Content
   * @param cid HC ID
   */
  fetchPublicMessages(params = {}) {
    
    let projectId = store.getters.getSelectedHousingCompany.hcExt;
    params.projectId = projectId;
    if(!params.limit)
      params.limit = settings.community.itemsPerPage;
    if(!params.skip)
      params.skip = 0;
    
    if(this.routeViaLiferay() && false) {
      let query = this._buildUrl(`${this.baseUrl}/getPublicDiscussions/${this.getHcId()}`, params);
      return LiferayClient.get(query);
    } else {
      
      let query = this._buildUrl(`getPublicDiscussions`, params);
      return AzureFunctionClient.get(query);
    }
  }

  /**
   * Fetch Partner Content
   * @param cid HC ID
   */
  async fetchAreaMessages(params = {}, buildingTaxonomy = "") {

    params.buildingTaxonomy = buildingTaxonomy ? buildingTaxonomy : store.getters.getSelectedHousingCompany.marketingName;

    if(!params.buildingTaxonomy)
      return;

      params.buildingTaxonomy = params.buildingTaxonomy.replace(/\s/g, "+");

    if(!params.limit)
      params.limit = settings.community.itemsPerPage;
    if(!params.skip)
      params.skip = 0;

    if (this.routeViaLiferay() && false) {
      let query = this._buildUrl(`${this.baseUrl}/getAreaDiscussions/${params.buildingTaxonomy}`, params);
      return LiferayClient.get(query);
    } else {
      let query = this._buildUrl(`getPublicDiscussions`, params);
      return AzureFunctionClient.get(query);
    }
  }

  /**
   * Update message
   * @param postData conversation
   */
  updateMessage(postData) {
    if (this.routeViaLiferay() && false) {
      return AzureLiferayClient.put(`${this.baseUrl}/message`, postData);
    } else {
      if (postData && Array.isArray(postData.images) && postData.images.length)
        postData.images = postData.images.map(this._removeThumbnailsProperty)
      return AzureFunctionClient.put(`/message`, postData);
    }
  }

  /**
   * Delete message
   * @param id message id
   */
  deleteMessage(id) {
    let postData = {
      data: {
        _id: id
      }
    };

    if (this.routeViaLiferay() && false) {
      return AzureLiferayClient.delete(`${this.baseUrl}/message`, postData);
    } else {
      postData.data.checkIsAdmin = true;
      return AzureFunctionClient.delete(`/message`, postData);
    }
  }

  /**
   * add like
   * @param id
   * @param type
   */
  processLike(id, type) {

    let postData = {
      id,
      type,
      //userUuid: store.getters.getCurrentUser.id
    };

    let currentUser = store.getters.getCurrentUser;

    if(currentUser.publicProfile) {
      postData.name = currentUser.firstName;
      postData.apt = store.getters.getSelectedApartment.no;
    }

    if(type == 'article')
      postData.projectId = store.getters.getSelectedHousingCompany.hcExt;

    if (this.routeViaLiferay() && false) {
      return AzureLiferayClient.post(`${this.baseUrl}/like`, postData);
    } else {
      return AzureFunctionClient.post(`/like`, postData);
    }
  }

  /**
   * Delete conversation
   * @param postdata conversation
   */
  updateConversation(postData) {

    if (postData && postData.newestMessage && Array.isArray(postData.newestMessage.images) && postData.newestMessage.images.length)
      postData.newestMessage.images = postData.newestMessage.images.map(this._removeThumbnailsProperty)

    return AzureFunctionClient.put(
      `/editConversation`,
      postData
    );
    
  }

  /**
   * Delete conversation
   * @param postdata conversation
   */
  deleteConversation(id) {
    let postData = {
      data: {
        _id: id
      }
    };

    if (this.routeViaLiferay() && false) {
      return AzureLiferayClient.delete(
        `${this.baseUrl}/deleteConversation`,
        postData
      );
    } else {
      postData.data.checkIsAdmin = true;
      console.log(postData);
      return AzureFunctionClient.delete(
        `/deleteConversation`,
        postData
      );
    }
  }

  /**
   *
   */
  reportViolation(data) {
    let postData = data;
    //postData.userUuid = store.getters.getUserDetails.uuid;
    postData.projectId = store.getters.getSelectedHousingCompany.hcExt;

    if (this.routeViaLiferay() && false) {
      return AzureLiferayClient.post(
        `${this.baseUrl}/reportViolation`,
        postData
      );
    } else {
      return AzureFunctionClient.post(
        `/reportViolation`,
        postData
      );
    }
  }

  /**
   * 
   * @param {*} id 
   * @param {*} params 
   * @returns 
   */
  subscribeNotifications(id, params = {}) {
    return AzureFunctionClient.post(`/notification/subscribe/${id}`, params);
  }

  _removeThumbnailsProperty = m => {
    if (m.thumbnails)
      delete m.thumbnails;
    return m;
  }
}
