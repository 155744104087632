export default [
  {
    path: "/business-partners/dashboard",
    name: "/business-partners/dashboard",
    meta: {
      sectionTitle: "navigation.business-partners.dashboard",
      title: "navigation.business-partners.dashboard",
      layout: "admin-default"
    },
    component: () =>
      import(
        /* webpackChunkName: "business-partners" */ "@/views/BusinessPartners/Dashboard.vue"
      )
  },
  {
    name: "/partner/company/edit",
    path: "/partner/company/edit",
    meta: {
      sectionTitle: "navigation.business-partners.dashboard",
      title: "navigation.business-partners.dashboard",
      layout: "admin-default"
    },
    component: () =>
      import(
        /* webpackChunkName: "business-partners" */ "@/views/BusinessPartners/Company/Edit.vue"
      )
  },
  {
    path: "/partner/company/users",
    name: "/partner/company/users",
    meta: {
      sectionTitle: "navigation.business-partners.dashboard",
      title: "navigation.business-partners.dashboard",
      layout: "admin-default"
    },
    component: () =>
      import(
        /* webpackChunkName: "business-partners" */ "@/views/BusinessPartners/Company/Users.vue"
      )
  },
  {
    path: "/partner/company/operational-area",
    name: "/partner/company/operational-area",
    meta: {
      sectionTitle: "navigation.business-partners.dashboard",
      title: "navigation.business-partners.dashboard",
      layout: "admin-default"
    },
    component: () =>
      import(
        /* webpackChunkName: "business-partners" */ "@/views/BusinessPartners/Company/OperationalArea.vue"
      )
  },
  {
    path: "/business-partners",
    name: "/business-partners",
    meta: {
      sectionTitle: "navigation.business-partners.dashboard",
      title: "navigation.business-partners.dashboard"
    },
    redirect: "/business-partners/dashboard",
  },
  {
    path: "/yit-news-archive",
    name: "/yit-news-archive",
    meta: {
      sectionTitle: "navigation.yit-news",
      title: "navigation.yit-news"
    },
    component: () =>
    import(
      /* webpackChunkName: "news" */ "@/views/News/Yit/Archive.vue"
    ),
  },
  {
    path: "/admin/partner-company/list",
    name: "/admin/partner-company/list",
    component: () =>
      import(
        /* webpackChunkName: "partner-companies-admin" */ "@/views/Admin/Partners/List.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "admin.housing-companies.settings.partners",
      title: "admin.housing-companies.settings.partners",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser']
      }
    }
  },
  {
    path: "/admin/partner-company/new",
    name: "/admin/partner-company/new",
    component: () =>
      import(
        /* webpackChunkName: "partner-companies-admin" */ "@/views/Admin/Partners/New.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.partner-companies",
      title: "navigation.partner-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser']
      }
    }
  },
  {
    path: "/admin/partner-company/edit/:id",
    name: "/admin/partner-company/edit/:id",
    component: () =>
      import(
        /* webpackChunkName: "partner-companies-admin" */ "@/views/Admin/Partners/Edit.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.partner-companies",
      title: "navigation.partner-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser','propertyManager','maintenanceManager','partner']
      }
    }
  },
  {
    path: "/admin/partner-company/edit-details",
    name: "/admin/partner-company/edit-details",
    component: () =>
      import(
        /* webpackChunkName: "partner-companies-admin" */ "@/views/Admin/Partners/Edit.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.partner-companies",
      title: "navigation.partner-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser','propertyManager','maintenanceManager','partner']
      }
    }
  }
]