<template>
  <button
    v-bind="$attrs"
    @click="$emit('click', $event)"
    :type="type"
    class="button"
    :class="getButtonClasses"
    :disabled="disabled || loading"
  >
    <LoaderIcon v-if="loading" :class="getLoaderClass" />
    <span>
      <slot></slot>
    </span>
  </button>
</template>
<script>
import LoaderIcon from "@/components/common/LoaderIcon";
export default {
  name: "Button",
  emits: ['click'],
  props: {
    type: {
      type: String,
      default: "button"
    },
    mode: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LoaderIcon
  },

  data() {
    return {};
  },
  computed: {
    getButtonClasses() {
      let cssClasses = "";
      if (this.mode) cssClasses += "button--" + this.mode + " ";
      if (this.loading) cssClasses += "button--loading";

      return cssClasses;
    },
    getLoaderClass() {
      if(this.mode == 'primary' || this.type == 'submit' || this.mode == 'primary')
        return 'color--white';

      return '';
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/abstracts/variables";
.button--loading {
  position: relative;
  margin: 0 auto;
  &.button--primary,
  &[type="submit"] {
    &:focus,
    &:hover,
    &:active {
      background: $brandColorPrimary !important;
      border-color: $brandColorPrimary !important;
      color: #fff !important;
    }
  }
  .loader-icon {
    position: absolute;
    top: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0.4rem;
  }
  span {
    /*padding-left: 2rem;*/
    visibility: hidden;
    opacity: 0;
  }
}
</style>