import PrimaClient from "./PrimaClient";

export default class PrimaService {
  /**
   *
   * @param {obj} params
   *
   */
  fetchApartmentInfo(params) {
    if (!params || !params.aptExtId)
      return Promise.reject("hc external id and apartment id needs to be provided when querying apartment info from Prima.");

    const urlParams = "/" + params.hcExtId + "/productItems/" + params.aptExtId;
    return PrimaClient.get(urlParams);
  }

  fetchHousingCompanyData(hcExtId) {
    const urlParams = "/" + hcExtId;
    return PrimaClient.get(urlParams);
  }

}
