import store from "@/store";

const initialize = (feature, settings) => {
    if (!store.getters.getMainNavigationItemByUrl('/community')) {
        let hcState = store.getters.getCurrentHcState;
        store.dispatch("addNavigationLink", {
            titleLanguageKey: "navigation.community",
            href: "/community",
            iconClass: "fal fa-users",
            orderNumber: 40
        });
    }
};

const areaDiscussionFeature = {
    id: "area-discussion",
    name: "feature.area-discussion.name",
    globalSettings: {},
    iconClass: "fal fa-users",
    schema: {},
    initialize
};

const neighboursFeature = {
    id: "neighbours",
    name: "feature.neighbours.name",
    globalSettings: {},
    iconClass: "fal fa-users",
    schema: {},
    initialize
};

const publicDiscussionFeature = {
    id: "public-discussion",
    name: "feature.public-discussion.name",
    globalSettings: {},
    iconClass: "fal fa-users",
    schema: {},
    initialize
};

export { areaDiscussionFeature, neighboursFeature, publicDiscussionFeature };