import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import KenticoService from "@/services/KenticoService";
import ProjectStep, {} from "@/models/ProjectStep";
import store from "@/store";

const articleContentTypeName = 'step';
let deliveryApi = new KenticoDeliveryApiService();
let managementApi = new KenticoService();
deliveryApi.setContentType(articleContentTypeName);
deliveryApi.targetByDefaultBuildingTaxonomy = true; //target default by building taxonomy
managementApi.setContentType(articleContentTypeName);
managementApi.targetByDefaultBuildingTaxonomy = true; //target default by building taxonomy

const isLifecycleStepsActive = () => {
  if (store.getters.getSelectedHousingCompany.lifecycleStepsActive) 
    return true;
  return false;
}

const getDefaultState = () => {
  return {
    steps: [],
    contentModel: null,
    currentStep: null
  };
};

const state = getDefaultState();

const getters = {
  getSteps: (state) => state.steps,
  isLifecycleStepsActive: (state) => isLifecycleStepsActive(),
  getStepContentModel: (state) => state.contentModel,
  getCurrentStep: (state) => state.currentStep,
};

const actions = {
  async fetchCurrentProjectSteps({ commit }) {
    if (!isLifecycleStepsActive()) return;

    try {
      let res = await deliveryApi.fetchList();
      let steps = res.data.items.map((i) =>
        ProjectStep.fromKenticoModel(i, res.data.modular_content, {project: store.getters.getSelectedHousingCompany})
      ).filter(i => i.date_start);
      commit(
        "setSteps",
        steps.sort((a,b) => new Date(a.date_start) - new Date(b.date_start))
      );
      return res.data;
    } catch (err) {
      console.error(err);
      commit("setSteps", []);
      throw err;
    }
  },
  async fetchSteps({ commit }, params = {}) {
    if (!isLifecycleStepsActive()) return;

    try {
      let res = await managementApi.fetchItems(params);
      let steps = res.data.items.map((i) =>
        ProjectStep.fromKenticoModel(i, res.data.modular_content, {project: store.getters.getSelectedHousingCompany})
      ).filter(i => i.date_start);
      commit(
        "setSteps",
        steps.sort((a,b) => new Date(a.date_start) - new Date(b.date_start))
      );
      return res.data;
    } catch (err) {
      console.error(err);
      commit("setSteps", []);
      throw err;
    }
  },
  async fetchStepContentModel({ commit }) {
    try {
      const res = await managementApi.fetchContentType(articleContentTypeName)
      commit("setArticleContentModel", res.data);
    } catch (err) {
      commit("setArticleContentModel", null);
      throw err;
    }
  },
  async fetchCurrentStep({ commit }, id) {
    if (!isLifecycleStepsActive()) return;

    try {
      const res = await deliveryApi.fetchSingle(id)
      if (res.data) {
        const step = ProjectStep.fromKenticoModel(res.data.item, res.data.modular_content, { project: store.getters.getSelectedHousingCompany });
        commit("setCurrentStep", step)
      }
      return res.data;
    } catch (err) {
      console.error(err);
      commit("setCurrentStep", null);
      throw err;
    }
  },
};

const mutations = {
  setSteps: (state, data) => {
    state.steps = data;
  },
  setArticleContentModel: (state, data) => {
    state.contentModel = data;
  },
  setCurrentStep: (state, data) => {
    state.currentStep = data;
  }
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations,
};
