import EmailLetterService from "@/services/EmailLetterService";
import _ from "lodash";

const api = new EmailLetterService();

const getDefaultState = () => {
  return {
    emailTemplates: [],
    emailLetters: [],
    currentEmailTemplate: null,
    currentEmailLetter: null,
    currentEmailLetterLogs: null
  };
};

const state = getDefaultState();

const getters = {
  getEmailTemplates: state => state.emailTemplates,
  getEmailLetters: state => state.emailLetters,
  getCurrentEmailTemplate: state => state.currentEmailTemplate,
  getCurrentEmailLetter: state => state.currentEmailLetter,
  getCurrentEmailLetterLogs: state => state.currentEmailLetterLogs
};

const actions = {
  async fetchEmailTemplates({ commit }, params = {}) {
    try {
      let res = await api.getLetterTemplates(params);
      commit("setEmailTemplates", res.data);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async fetchEmailTemplate({ commit }, params = {}) {
    try {
      let res = await api.getLetterTemplate(params.id, params.payload);
      commit("setCurrentEmailTemplate", res.data);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async fetchEmailLetters({ commit }, params = {}) {
    try {
      let res = await api.getEmailLetters(params);
      commit("setEmailLetters", res.data);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async fetchEmailletter({ commit }, id) {
    try {
      let res = await api.getEmailLetter(id);
      commit("setCurrentEmailLetter", res.data);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async createNewEmailletter({ commit }, params) {
    try {
      let res = await api.createEmailLetter(params);
      commit("setCurrentEmailLetter", res.data);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async updateEmailLetter({ commit }, payload) {
    try {
      let res = await api.updateEmailLetter(payload.id, payload.payload);
      commit("setCurrentEmailLetter", res.data);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async deleteEmailLetter({ commit }, id) {
    try {
      let res = await api.deleteEmailLetter(id);
      commit("setCurrentEmailLetter", null);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
   async fetchEmailLetterLogs({ commit }, id) {
    try {
      let res = await api.getEmailLetterLogs(id);
      commit("setCurrentEmailLetterLogs", res.data.logs);
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}

const mutations = {
  setEmailTemplates: (state, data) => (state.emailTemplates = data),
  setEmailLetters: (state, data) => (state.emailLetters = data),
  setCurrentEmailTemplate: (state, data) => (state.currentEmailTemplate = data),
  setCurrentEmailLetter: (state, data) => (state.currentEmailLetter = data),
  setCurrentEmailLetterLogs: (state, data) => (state.currentEmailLetterLogs = data)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
