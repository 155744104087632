<template>
  <div class="image-grid" v-if="images && images.length" :class="{'image-grid--thumbs': images.length > 1}">
      <div class="image-grid__thumb" v-if="images.length == 1">
            <a :href="getImageUrlFromDocumentResponseObject(images[0], 'large')" @click="openPreview(images[0], $event)" class="image-grid__thumb">
                <img :src="getImageUrlFromDocumentResponseObject(images[0], 'medium')" :alt="images[0].fileName" />
            </a>
            <button type="button"
                @click="onRemoveAttachment(images[0], $event)"
                class="badge badge--remove badge--right badge--circle"
                :title="$t('admin.common.remove')"
                v-if="canRemove"
            >
                <i class="far fa-times"></i>
                <span class="sr-only">{{$t('admin.common.remove')}}</span>
            </button>
        </div>
        <template v-else>
            <div class="image-grid__thumb" v-for="image in images" :key="image._id" :aria-label="image.fileName" :style="getThumbnailAsBg(image)">
                <a :href="getImageUrlFromDocumentResponseObject(image, 'large')" @click="openPreview(image, $event)"></a>
                <button type="button"
                    @click="onRemoveAttachment(image, $event)"
                    class="badge badge--remove badge--right badge--circle"
                    :title="$t('admin.common.remove')"
                    v-if="canRemove"
                >
                    <i class="far fa-times"></i>
                    <span class="sr-only">{{$t('admin.common.remove')}}</span>
                </button>
            </div>
        </template>
        <ImagePreviewModal v-if="previewActive && images && images.length > 1" :imageSrc="getImageUrlFromDocumentResponseObject(previewImage, 'large')" :multiple="true" :images="images.map(image => getImageUrlFromDocumentResponseObject(image, 'large'))" @close="closePreview" />
        <ImagePreviewModal v-else-if="previewActive" :imageSrc="getImageUrlFromDocumentResponseObject(previewImage, 'large')" @close="closePreview" />
    </div>
</template>

<script>
import ImagePreviewModal from "@/components/common/ImagePreviewModal";
import {getImageUrlFromDocumentResponseObject} from "@/utils/DocumentsUtil";

export default {
    name: 'CommunityImageGrid',
    components: {ImagePreviewModal},
    props: {
        images: {
            type: Array
        },
        canRemove: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            previewActive: false,
            previewImage: null
        }
    },
    methods: {
        getImageUrlFromDocumentResponseObject,
        getThumbnailAsBg(image) {
            return {backgroundImage: `url('${image.thumbnails && image.thumbnails.medium ? image.thumbnails.medium.url : image.fileUrl}')`};
        },
        onRemoveAttachment(image = null, e) {
        if(e)
            e.preventDefault();

        if(!image)
            return;

        this.$emit("on-remove", image);
        },
        openPreview(image = null, event = null) {
            if(event)
                event.preventDefault();

            if(!image)
                return;
            
            this.previewImage = image;
            this.previewActive = true;
        },
        closePreview() {
            this.previewActive = false;
            this.previewImage = null;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
.image-grid {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    padding: .5rem 0;
    position: relative;

    &__thumb {
        display: block;
        position: relative;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    button {
        z-index: 2;
    }

    &--thumbs {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .image-grid__thumb {
        width: 10rem;
        height: 10rem;
        margin: .25rem;
        min-width: 100px;
        background-size: cover;

        @media screen and (min-width: $breakpoint-sm) {
          min-width: 200px;
        }
        @media screen and (min-width: $breakpoint-md) {
          min-width: 200px;
        }
        @media screen and (min-width: $breakpoint-lg) {
          min-width: 250px;
        }
      }
    }
  }
</style>