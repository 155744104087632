<template>
  <div class="report-violation">
    <button
      type="button"
      class="report-message"
      @click="show"
      :title="$t('community.report-message')"
    >
      <i class="far fa-flag-alt"></i>
      <span class="report-message__text d-none d-sm-inline">{{ $t("report-violation.reportButton") }}</span>
    </button>
    <Modal v-if="visible" @close="hide">
      <h2>{{ $t("report-violation.title") }}</h2>
      <p class="report-violation__instructions">
        {{ $t("report-violation.content") }}
      </p>
      <form @submit="onSubmit">
        <AlertMessage v-if="errorMessage" type="error" showIcon="true">{{
          errorMessage
        }}</AlertMessage>
        <AlertMessage v-if="successMessage" type="success" showIcon="true">{{
          successMessage
        }}</AlertMessage>
        <div v-if="!successMessage">
          <FormInput
            type="select"
            :options="reportTypes"
            v-model="form.reportType"
            :validator="form.validator"
            :label="$t('report-violation.reportType.label')"
          />
          <FormInput
            type="textarea"
            v-model="form.description"
            :validator="form.validator"
            :label="$t('report-violation.description.label')"
          />
          <div class="button-group text-center">
            <Button type="submit" :loading="sending">{{
              $t("common.submit")
            }}</Button>
            <button
              type="button"
              class="button button--link"
              @click="visible = false"
              v-text="$t('common.backButton')"
            ></button>
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <div class="text-center"></div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/common/Modal";
import FormInput from "@/components/common/Form/Input";
import Form from "@/utils/FormUtil";
import AlertMessage from "@/components/common/AlertMessage";
import CommunityService from "@/services/CommunityService";
import store from "@/store";

export default {
  name: "ReportMessage",
  props: {
    contentType: {
      type: String,
      required: true
    },
    targetObject: {
      type: Object
    }
  },
  components: {
    Modal,
    FormInput,
    AlertMessage
  },
  data() {
    return {
      sending: false,
      visible: false,
      reportTypes: {
        "report-violation.reportType.improper_or_inappropriate_content": this.$t(
          "report-violation.reportType.improper_or_inappropriate_content"
        ),
        "report-violation.reportType.content_breach_your_privacy": this.$t(
          "report-violation.reportType.content_breach_your_privacy"
        ),
        "report-violation.reportType.racist_or_discriminatory_content": this.$t(
          "report-violation.reportType.racist_or_discriminatory_content"
        ),
        "report-violation.reportType.copyright-protected_contents": this.$t(
          "report-violation.reportType.copyright-protected_contents"
        ),
        "report-violation.reportType.other_reason": this.$t(
          "report-violation.reportType.other_reason"
        )
      },
      form: new Form(
        /* Form fields */
        {
          reportType:
            "report-violation.reportType.improper_or_inappropriate_content",
          description: "",
          targetType: this.contentType
        },
        /* Validation */
        {
          reportType: { required: true }
        }
      ),
      errorMessage: "",
      successMessage: "",
      api: new CommunityService()
    };
  },
  computed: {
    /*getReportTypes() {
      return this.reportTypes.map(type => {
        return this.$t(type);
      });
    }*/
  },
  methods: {
    buildUrl() {
      let url = document.URL;

      if (this.targetObject && this.targetObject._id) {
        if (!url.includes("/public-discussions/" + this.targetObject._id))
          url = url.replace(
            "/public-discussions",
            "/public-discussions/" + this.targetObject._id
          );
      }

      let urlSeparator = "?";
      if (url.includes("?")) urlSeparator = "&";

      url +=
        urlSeparator + "_hc=" + store.getters.getSelectedHousingCompany.hcExt;

      return url;
    },
    onSubmit(e) {
      e.preventDefault();
      this.errorMessage = '';
      if (this.form.validator.validate()) {
        this.sending = true;
        let postData = {
          targetObject: this.targetObject,
          targetType: this.form.targetType,
          targetHref: this.buildUrl(),
          reportType: this.$t(this.form.reportType),
          description: this.form.description
        };
        this.api.reportViolation(postData).then(res => {
          this.successMessage = this.$t("report-violation.successMessage");
          this.sending = false;
        }).catch(err => {
          console.log(err);
          this.errorMessage = this.$t("report-violation.errorMessage");
          this.sending = false;
        });
      } else {
        this.errorMessage = this.$t("report-violation.errorMessage");
        this.sending = false;
      }
    },
    show() {
      this.initForm();
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.initForm();
    },
    initForm() {
      this.errorMessage = '';
      this.successMessage = '';
      this.sending = false;
      this.form.description = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.report-violation {
  &__instructions {
    margin: 1rem 0 2rem 0;
    padding: 0px 20px 0 0;
    text-align: left;
  }
}
.report-message {
  &__report-button {
    cursor: pointer;
  }
  &__text {
    font-size: .85rem;
    margin-left: .4rem;
  }
}
</style>
