import { start } from "./main";
import store from "@/store/index";
import { loadLanguageAsync } from "@/plugins/i18n";
import { initializeFeatures } from "@/features";
import { defineAbilitiesFor } from "./config/ability";
import { getQueryVariable } from "@/utils/StringUtils";
import { TokenService, includeTokensToClients } from "@/services/TokenService";
import { getParmFromHash } from "@/utils/StringUtils";
import settings from "@/globals";
import MobileAppUtil from "@/utils/MobileAppUtil";

//Redirect foorgotpassword link to Azure B2C
const error = getParmFromHash(location.href,"error");
if(error && error == "access_denied"){
  //Azure return different error descriptions with errors. Forgot password error includes login_hint string.
  if(window.location.href.includes('login_hint'))
    window.location.href = process.env.VUE_APP_B2C_FORGOT_PASSWORD_URL
};


/**
 * Maintenance break
 */
if(process.env.VUE_APP_MAINTENANCE_PAGE_ACTIVE == 'true' || process.env.VUE_APP_MAINTENANCE_PAGE_ACTIVE === true) {
  start();
} else {

  //Initialize tokens
  TokenService.initToken(false).then(() => {
      bootstrapApp();
  }).catch(error => {

      TokenService.initToken(true).then(() => {
        
        bootstrapApp();

      }).catch(err => {
        if ((err && err.response && err.response.status === 401) || !err.status) {
          store.commit("logout");
          start();
        } else {
          store.commit("setApplicationError", true);
        }
        start();
      });
  });


  function bootstrapApp() {
    store
      .dispatch("fetchUserDetails")
      .then(res => {

        //Check if token and b2c groups have mismatch and communicate this to mobile app
        if (res.data && res.data.tokenGroupsMismatch) {
          MobileAppUtil.cleanTokens(res);
        }

        //Set current user
        if (res && res.data) store.commit("setCurrentUser", res.data);

        //If there's no access token set, let's set it now (if user has entered site without token and there's no token in local storage)
        store.dispatch('retrieveAccessToken');
        includeTokensToClients();

        MobileAppUtil.setMobileAppState();

        //get housing company and apartment from local storage, if stored
        let localStorageSelectedHc = null;
        let localStorageSelectedApt = null;

        try {
          if (window.localStorage) {
            localStorageSelectedHc = localStorage.getItem(
              "selectedHousingCompany"
            );
            localStorageSelectedApt = localStorage.getItem(
              "selectedApartment"
            );
          }
        } catch (err) {
          console.log(err);
        }

        //select housing company
        /**
         * 1) url parameter _hc
         * 2) Localstorage
         * 3) Pick first hc from user array
         */
        if (
          (store.getters.getUserDetails.hcs &&
          store.getters.getUserDetails.hcs.length) || 
          store.getters.isBusinessPartner
        ) {
          let userHc;
          let userApt;

          console.log("Bootstrap: Hcs found");

          //1) url parameter _hc
          if(store.getters.getUserDetails.hcs && store.getters.getUserDetails.hcs.length) {
            if (typeof URL === "function") {
              let url = new URL(window.location.href);
              if (url && url.searchParams) {
                let hcIdFromUri = url.searchParams.get("_hc");
                
                console.log("Bootstrap: trying to get _hc from url: ", url, url.searchParams.get("_hc"));

                if (hcIdFromUri) {
                  userHc = store.getters.getUserDetails.hcs.find(
                    obj => obj.hcExt == hcIdFromUri
                  );
                }
              } else {
                let hcIdFromUri = getQueryVariable("_hc");

                if (hcIdFromUri) {
                  userHc = store.getters.getUserDetails.hcs.find(
                    obj => obj.hcExt == hcIdFromUri
                  );
                }
              }

              //If we have arrived to plus from b2c, we might have redirect url saved in local storage including the hc, where we redirect user (happens in router.js) -> do only for users that have more than 1 hc
              if (!userHc && store.getters.getUserDetails.hcs.length > 1 && url.hash && url.hash.includes('#code')) {

                try {
                  if (window.localStorage) {
                    let redirectPath = localStorage.getItem(
                      "redirectAfterLogin"
                    );

                    if(redirectPath && typeof redirectPath == 'string' && redirectPath.includes('_hc=')) {
                      console.log("Bootstrap: checking _hc from redirect path: ", window.location.protocol + window.location.hostname + redirectPath, redirectPath.includes('_hc='));
                      if (typeof URL === "function") {
                        let pathObject = new URL(window.location.protocol + window.location.hostname + redirectPath);
                        console.log("Bootstrap: checking _hc from redirect path (object): ", pathObject);
                        if (pathObject && pathObject.searchParams) {
                          let hcFromPath = pathObject.searchParams.get("_hc");
                          if (hcFromPath) {
                            userHc = store.getters.getUserDetails.hcs.find(
                              obj => obj.hcExt == hcFromPath
                            );
                          }
                          console.log("Bootstrap: checking _hc from redirect path (after): ", userHc);
                        }
                      }
                    }
                  }

                } catch(error) {
                  console.log(error);
                }
              }

            }

            //2) Localstorage
            if ((!userHc || !userHc.id) && localStorageSelectedHc) {
              console.log(
                "Bootstrap: Hc not found from url, try to get it from localstorage"
              );
              try {
                localStorageSelectedHc = JSON.parse(localStorageSelectedHc);
                if (localStorageSelectedHc) {
                  userHc = store.getters.getUserDetails.hcs.find(
                    obj => obj.id == localStorageSelectedHc.id
                  );

                  console.log(
                    "Bootstrap: Hc found from localstorage? ",
                    userHc
                  );

                  //If hc from localstorage was not found from user, let's empty the storage
                  if (!userHc) {
                    try {
                      if (window.localStorage) {
                        localStorageSelectedHc = localStorage.setItem(
                          "selectedHousingCompany",
                          "{}"
                        );
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }
                }
              } catch (err) {
                console.log(err);
              }
            }

            //3) Pick first hc from user array
            if (!userHc || !userHc.id) {
              console.log(
                "Bootstrap: Hc not found from localstorage, try go get first one"
              );
              if (
                store.getters.getUserDetails.hcs &&
                store.getters.getUserDetails.hcs[0]
              ) {
                userHc = store.getters.getUserDetails.hcs[0];
              }
            }
          }

          //HC found, let's select it and add it to the local storage
          if (
            (userHc && userHc.id) || store.getters.isBusinessPartner
            ) {
            
            store.dispatch("selectHousingCompany", userHc).then(res => {

              //Set User abilities
              defineAbilitiesFor(store.getters.getCurrentUser)

              store.dispatch("fetchUserFeatures").then(res => {
                initializeFeatures();
                loadLanguageAsync(store.getters.getLang).then(function(result) {
                  start();
                });
              }).catch(err => {
                console.log(err);
                initializeFeatures();
                loadLanguageAsync(store.getters.getLang).then(function(result) {
                  start();
                });
              }); 
              
            });

            //Select apartment from query params if user has multiple apartments
            if (typeof URL === "function" && userHc && Array.isArray(userHc.apts) && userHc.apts.length) {
              let url = new URL(window.location.href);
              if (url && url.searchParams) {
                let aptIdFromUrl = url.searchParams.get("_apt");
                
                console.log("Bootstrap: trying to get _apt from url: ", url, url.searchParams.get("_apt"));

                if (aptIdFromUrl) {
                  userApt = userHc.apts.find(
                    obj => obj.aptext == aptIdFromUrl
                  );
                }
              } else {
                let aptIdFromUrl = getQueryVariable("_apt");

                if (aptIdFromUrl) {
                  userApt = userHc.apts.find(
                    obj => obj.aptext == aptIdFromUrl
                  );
                }
              }

              //If user arrived from notification link and logged in at b2c
              if (!userApt && url.hash && url.hash.includes('#code')) {

                try {
                  if (window.localStorage) {
                    let redirectPath = localStorage.getItem(
                      "redirectAfterLogin"
                    );

                    if(redirectPath && typeof redirectPath == 'string' && redirectPath.includes('_apt=')) {
                      console.log("Bootstrap: checking _apt from redirect path: ", window.location.protocol + window.location.hostname + redirectPath, redirectPath.includes('_apt='));
                      if (typeof URL === "function") {
                        let pathObject = new URL(window.location.protocol + window.location.hostname + redirectPath);
                        console.log("Bootstrap: checking _apt from redirect path (object): ", pathObject);
                        if (pathObject && pathObject.searchParams) {
                          let hcFromPath = pathObject.searchParams.get("_apt");
                          if (hcFromPath) {
                            userApt = userHc.apts.find(
                              obj => obj.aptext == hcFromPath
                            );
                          }
                          console.log("Bootstrap: checking _apt from redirect path (after): ", userApt);
                        }
                      }
                    }
                  }

                } catch(error) {
                  console.error(error);
                }
              }
            }

            //Select apartment from local storage
            if (!userApt && localStorageSelectedApt) {
              console.log("Bootstrap: Try to get apt from localstorage");
              try {
                localStorageSelectedApt = JSON.parse(localStorageSelectedApt);
                if (localStorageSelectedApt && userHc.apts) {
                  userApt = userHc.apts.find(
                    apt => apt.id == localStorageSelectedApt.id
                  );

                  console.log(
                    "Bootstrap: Apt found from localstorage? ",
                    userApt
                  );

                  //IF apartment was not found, let's empty the storage
                  if (!userApt || !userApt.id) {
                    try {
                      if (window.localStorage) {
                        localStorageSelectedHc = localStorage.setItem(
                          "selectedApartment",
                          "{}"
                        );
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }
                }
              } catch (err) {
                console.error(
                  "Could not set apartment from local storage, ",
                  err
                );
              }
            }

            //Get first apartment from hc
            if (!userApt || !userApt.id) {
              console.log("Bootstrap: Try to get first apt");
              if (userHc && userHc.apts && userHc.apts[0]) {
                userApt = userHc.apts[0];
              }
            }

            //IF apartment found, let's select it
            if (userApt && userApt.id) {
              console.log("Bootstrap: Apt found: ", userApt);
              store.commit("setApartment", userApt);
            }
          }

        } else {
          console.log("Bootstrap: Hcs not found at all");
          //reset local storage
          try {
            if (window.localStorage) {
              localStorage.setItem("selectedHousingCompany", "{}");
              localStorage.setItem("selectedApartment", "{}");
            }
          } catch (error) {
            console.log(error);
          }
          
          //business partners do not have housing companies
          if(!store.getters.isBusinessPartner)
            store.commit("setApplicationError", true);

          start();
        }
      })
      .catch(err => {
        console.error("Bootstrap error: ", err);
        if ((err && err.response && err.response.status === 401) || !err.status) {
          store.commit("logout");
          loadLanguageAsync(settings.locale).then(function(result) {
            start();
          });
        } else {
          store.commit("setApplicationError", true);
          start();
        }
        loadLanguageAsync(settings.locale).then(function(result) {
          start();
        }).catch(err => {
          console.error(err);
        });
      });
    }

}