<template>
  <form @submit="onSubmit" class="add-quick-link">
    <div class="input-group">
      <FormInput type="select"
        :placeholder="$t('common.choose')"
        v-model="newQuickLink"
      >
        <option :value="linkModule.moduleId" v-for="linkModule in getQuickLinkModules" :key="linkModule.moduleId">{{$t(linkModule.translationKey)}}</option>
      </FormInput>
    </div>
    <div class="buttons">
      <Button type="submit" mode="primary">{{ $t('common.add') }}</Button>&nbsp;
      <Button type="button" mode="link" @click="$emit('on-cancel')">{{ $t('common.cancel') }}</Button>
    </div>
  </form>

</template>

<script>
import {getModules, buildModuleData} from '@/config/quick-link-modules'

export default {
  name: "QuickLinkForm",
  data() {
    return {
      newQuickLink: null
    }
  },
  computed: {
    getQuickLinkModules() {
      const modules = getModules()

      //Remove documentsv1 module if v2 exists
      const hasDocumentsV1Module = modules.find(m => m.moduleId === 'link-documents-v1');
      const hasDocumentsV2Module = modules.find(m => m.moduleId === 'link-documents-v2');
      if(hasDocumentsV1Module && hasDocumentsV2Module)
        return modules.filter(m => m.moduleId !== 'link-documents-v1')

      return modules
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$emit('on-add', buildModuleData([this.newQuickLink]))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
.add-quick-link {
  display: flex;
  flex-direction: column;
  background-color: $blueLightest;
  border: 1px solid $blueLight;
  margin-top: 1rem;
  .input-group {
    margin: .5rem 0 .5rem 0;
  }
  padding: .5rem 1rem;
  .buttons {
    margin-left: 1rem;
  }

  @media screen and (min-width: $breakpoint-sm) {
    flex-direction: row;
    align-items: center;
    .input-group {
      max-width: 400px;
      margin: 0 0 0 .5rem;
    }
  }
}
</style>