<template>
  <div class="error-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 offset-md-2 text-center">
          <div class="error-page-icon">
            <i class="fal fa-exclamation-triangle"></i>
          </div>
          <h1>Something went wrong</h1>
          <p>Page couldn't be loaded. Please try to reload the page.</p>
          <p>
            <a href="/">{{$t('page.error.to-homepage-link-text')}}</a>
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  components: {}
};
</script>
<style scoped>
.error-page-icon {
  font-size: 3rem;
  margin: 1rem 0;
}
</style>