<template>
  <div class="timeline__no-items text-center">
    <i class="text--xxl fal fa-calendar-times"></i>
    <p>
      <strong>{{$t("timeline.no-items.title") }}</strong>
    </p>
    <p>{{$t("timeline.no-items.text") }}</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .timeline__no-items {
    padding-top: 3rem;
  }
</style>