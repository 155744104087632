<template>
  <div class="quick-link-item">
    <QuickLink 
      href="#"
      :iconCssClass="item.icon ? `fal ${item.icon}` : ''"
    >
      {{$t(item.translationKey)}}
    </QuickLink>
    <button type="button" class="quick-link-item__remove" @click="$emit('on-remove', item)">
      <i class="fal fa-times"></i>
      <span class="sr-only">{{$t('common.remove')}}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    item: {type: Object, required: true}
  }
}
</script>

<style lang="scss" scoped>
.quick-link-item {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  &__drag, &__remove {
    margin-bottom: 1rem;
  }
}
</style>
<style lang="scss">
.quick-link-item a.quick-link {
  cursor: grab !important;
}
</style>