export default [
  {
    path: "/admin/housing-companies",
    name: "/admin/housing-companies",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/List.vue"
      ),
    meta: {
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      admin: true
    }
  },
  {
    path: "/admin/housing-company/:id/images",
    name: "/admin/housing-company/:id/images",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Images.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/service-requests",
    name: "/admin/housing-company/:id/service-requests",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/ServiceRequests.vue"
      ),
    meta: {
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      admin: true,
      accessPolicies: {
        roles: ['yitUser','propertyManager','chairman','boardMember']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/moderation",
    name: "/admin/housing-company/:id/moderation",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Moderation.vue"
      ),
    meta: {
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      admin: true,
      accessPolicies: {
        roles: ['yitUser','propertyManager','chairman','boardMember']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/partners",
    name: "/admin/housing-company/:id/partners",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/PartnerCompanies.vue"
      ),
    meta: {
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      admin: true,
      accessPolicies: {
        roles: ['yitUser','propertyManager','chairman','boardMember']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/partners/edit/:type",
    name: "/admin/housing-company/:id/partners/edit/:type",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Partners/Edit.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser','propertyManager','chairman','boardMember']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/partners/:type/users",
    name: "/admin/housing-company/:id/partners/:type/users",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Partners/ChangeKeyContact.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser','propertyManager','maintenanceManager','chairman','boardMember']
      }
    }
  },
  {
    path: "/admin/housing-company/edit/:id",
    name: "/admin/housing-company/edit/:id",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Edit.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser']
      }
    }
  },
  {
    path: "/admin/housing-company/:id",
    name: "/admin/housing-company/:id",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Settings.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser','propertyManager','maintenanceManager','chairman','boardMember']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/reminder",
    name: "/admin/housing-company/:id/reminder",
    component: () =>
    import(
          /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Reminder.vue"
          ),
    meta: {
      admin: true,
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'ceeMaintenanceManager']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/apartments",
    name: "/admin/housing-company/:id/apartments",
    component: () =>
    import(
          /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Apartments.vue"
          ),
    meta: {
      admin: true,
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser','propertyManager','maintenanceManager','chairman','boardMember','ceeMaintenanceManager']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/warranty",
    name: "/admin/housing-company/:id/warranty",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Warranty/ApartmentMapping.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/features",
    name: "/admin/housing-company/:id/features",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Features.vue"
      ),
    meta: {
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      admin: true,
      accessPolicies: {
        roles: ['admin', 'yitBusinessAdmin']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/steps",
    name: "/admin/housing-company/:id/steps",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Steps/Main.vue"
      ),
    meta: {
      sectionTitle: "navigation.housing-companies.steps",
      title: "navigation.housing-companies.steps",
      layout: "admin-default",
      admin: true,
      accessPolicies: {
        roles: ['admin', 'yitBusinessAdmin', 'yitProject', 'yitMarketing']
      }
    }
  },
  {
    path: "/admin/housing-company/:id/wizard",
    name: "/admin/housing-company/:id/wizard",
    component: () =>
      import(
        /* webpackChunkName: "housing-companies-admin" */ "@/views/Admin/HousingCompanies/Wizard/Main.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "navigation.housing-companies",
      title: "navigation.housing-companies",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser']
      }
    }
  },
]