<template>
  <div class="loader-icon" :class="getCssClasses">
    <div class="spinner" v-if="loaderStyle == 'spinner'">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="rect" v-else-if="loaderStyle == 'rect'">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoaderIcon",
  props: {
    loaderStyle: {
      type: String,
      default: "spinner"
    },
    center: Boolean,
    size: {
      type: String,
      default: "small"
    },
    displayType: {
      type: String,
      default: "inline"
    }
  },
  computed: {
    getCssClasses() {
      let cssClasses = "";
      cssClasses += "loader-icon--" + this.size;
      if (this.center) cssClasses += " loader-icon--" + this.center;
      if (this.displayType === "flex") cssClasses += " loader-icon--flex";
      else if (this.displayType !== "inline") cssClasses += " loader-icon--block";

      return cssClasses;
    }
  }
};
</script>