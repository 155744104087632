<template>
    <div class="sub-comment-list">
        <Button type="button" mode="link" v-if="hideByDefault && displaySubComments && items.length">{{$t('comment.show-sub-comments', [this.items.length])}}</Button>
        <div v-if="items.length">
            <Message
                v-for="comment in items"
                :key="comment._id"
                :id="comment._id"
                :date="comment.createdAt"
                :name="comment.firstName"
                :apartment="comment.apartment"
                :userRoles="Array.isArray(comment.roles) ? comment.roles : []"
                :obj="comment"
                :userId="comment.userUuid"
                :images="comment.images"
                :likes="comment.likes"
                :showLikeButton="true"
                :conversationType="conversationType"
            >
                <div v-html="comment.message" v-linkify></div>
            </Message>
        </div>
    </div>
</template>

<script>
export default {
    name: "SubCommentList",
    components: {
        //Message
    },
    props: {
        items: { type: Array, default: () => [] },
        hideByDefault: {type: Boolean, default: true },
        conversationType: {type: String, default: 'public'},

    },
    data() {
        return {
            displaySubComments: false
        }
    },
    computed: {
        getItems() {
            return this.items;
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";

.sub-comment-list {
    padding: 1rem 0 0 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-left: 3px solid rgba(255,255,255,.8);
    position: relative;
    font-size: .9rem;
    &:before {
        content: "";
        position: absolute;
        border: 1px solid #fff;
    }

    @media screen and (min-width: $breakpoint-md) {
        margin-top: 0;
        padding-top: 0;
    }
}
</style>