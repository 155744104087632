<template>
<div>
  <transition name="slideleft">
    <nav class="offcanvas offcanvas--right" v-if="getIsOpen"
        v-closable="{
            exclude: ['offcanvas__toggle'],
            handler: 'close'
        }">

        <div class="offcanvas__header">
          <button class="offcanvas__close" @click="close">
            <i class="fal fa-times"></i>
          </button>
          <slot name="title"></slot>
        </div>

        <div class="offcanvas__body">
          <slot></slot>
        </div>
    </nav>
  </transition>
  <div  v-if="getIsOpen" class="site-overlay"></div>
</div>
</template>

<script>
import Vue from "vue";

export default {
  name: "OffcanvasMenuNavigation",
  props: ["open"],
  data() {
    return {};
  },
  computed: {
    getIsOpen: function() {
      return this.open;
    }
  },
  methods: {
    close() {
      this.$emit("onClose");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>