import ProjectService from "@/services/ProjectService";
import store from "@/store";
import { ability } from "@/config/ability";

let api = new ProjectService();

const getDefaultState = () => {
  return {
    currentPartnerCompany: null
  };
};

const state = getDefaultState();

const getters = {
  getCurrentPartnerCompany: state => state.currentPartnerCompany,
  getPartnerCompanyTypes: state => {
    let ret = {};
    if (store.getters.getApplicationSettings && store.getters.getApplicationSettings.partners && store.getters.getApplicationSettings.partners.companyTypes) {
      Object.keys(store.getters.getApplicationSettings.partners.companyTypes).map(companyTypeKey => {
        let value = store.getters.getApplicationSettings.partners.companyTypes[companyTypeKey];
        if (ability.can('update', { _type: 'partnercompany', type: value.key })) {
          ret[companyTypeKey] = value;
        }
      });
    }

    return ret;
  }
};

const actions = {
  fetchPartnerCompany({commit}, id) {
    return api.fetchPartnerCompanyDetails(id)
      .then(response => {
        commit("setCurrentPartnerCompany", response.data);
      })
      .catch(err => {
        console.log(err);
        commit("setCurrentPartnerCompany", null);
      });
  },
  updatePartnerCompany({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.editPartnerCompany(payload)
      .then(response => {
        commit("setCurrentPartnerCompany", response.data);
        resolve(response.data);
      })
      .catch(err => {
        console.log(err);
        commit("setCurrentPartnerCompany", null);
        reject(err);
      });
    });
  }
};

const mutations = {
  setCurrentPartnerCompany: (state, res) => (state.currentPartnerCompany = res),
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
