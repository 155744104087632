<template>
  <div id="app" class="maintennance-break" v-if="isMaintenanceBreak">
      <MaintenanceNotice />
  </div>
  <div id="app" v-else>
    <Header
      :title="$route.meta.sectionTitle ? $t($route.meta.sectionTitle) : ''"
      v-if="isLogged"
    />
    <HousingCompanyDropdown
      :open="housingCompanyDropdownState"
      :activeHc="getSelectedHousingCompany"
      @toggle="housingCompanyDropdownState=!housingCompanyDropdownState"
      @close="housingCompanyDropdownState=false"
      v-if="!isAdminSection"
    />

    <template v-if="$route.meta.mainImage && $route.meta.mainImage.image && $route.meta.mainImage.imageDesktop">
      <HeroImage 
        v-if="$route.meta.mainImage" 
        :src="$route.meta.mainImage.image"
        :title="$t($route.meta.mainImage.title)"
        class="d-md-none"
        />
      <HeroImage 
        v-if="$route.meta.mainImage" 
        :src="$route.meta.mainImage.imageDesktop"
        :title="$t($route.meta.mainImage.title)"
        class="d-none d-md-block"
      />
    </template>
    <template v-else-if="$route.meta.mainImage && $route.meta.mainImage.image">
      <HeroImage 
        v-if="$route.meta.mainImage" 
        :src="$route.meta.mainImage.image"
        :title="$t($route.meta.mainImage.title)"
        />
    </template>
    
    <div class="body-section">
      <component :is="getLayout">
        <router-view v-if="$route.name == '*' || $route.name == '/news/:id' || !$route.name" :key="$route.path" />
        <router-view v-else />
      </component>
    </div>
    <Footer />
    <div
      v-if="showTestEnvironmentNag === true || showTestEnvironmentNag == 'true'"
      class="test-environment-nag"
    >
      <i class="fal fa-exclamation-triangle"></i>
      {{ $t("common.thisIsTestEnvironment") }}
    </div>
    <AuthenticationRequiredModal v-if="getIsTokenExpired" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from "vuex";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import MaintenanceNotice from "@/views/partials/Maintenance";
import AuthenticationRequiredModal from "@/components/AuthenticationRequiredModal";
import HousingCompanyDropdown from "@/components/HousingCompanyDropdown";
import HeroImage from "@/components/common/HeroImage";
import _ from "lodash";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    MaintenanceNotice,
    AuthenticationRequiredModal,
    HousingCompanyDropdown,
    HeroImage
  },
  data() {
    return {
      showTestEnvironmentNag: false,
      returningToOldPlus: false,
      housingCompanyDropdownState: false
    };
  },
  methods: {
    getBgImg: function() {
      return "background-image: url(" + this.getHousingCompanyImages.url + ")";
    },
  },
  computed: {
    ...mapState(["User"]),
    ...mapGetters([
      "isLogged",
      "getHousingCompanyImages",
      "getSelectedApartment",
      "getIsTokenExpired",
      "getSelectedHousingCompany"
    ]),
    getLayout() {
      return this.$route.meta.layout
        ? "layout-" + this.$route.meta.layout
        : "layout-" + this.$AppSettings.defaultLayout;
    },
    getBackgroundImage() {
      let image = "";

      if (this.$route.meta && this.$route.meta.bg) {
        if (this.$route.meta.bg == "default") {
          image = this.getHousingCompanyImages;
        } else image = this.$route.meta.bg;

        return `background-image: url(${image})`;
      }

      return "";
    },
    isMaintenanceBreak() {
      return process.env.VUE_APP_MAINTENANCE_PAGE_ACTIVE == 'true' || process.env.VUE_APP_MAINTENANCE_PAGE_ACTIVE === true ? true : false;
    },
    isAdminSection() {
      return this.$route && this.$route.meta && this.$route.meta.admin === true
    }
  },
  mounted() {
    this.showTestEnvironmentNag = process.env.VUE_APP_TEST_ENVIRONMENT_NAG;
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";

.test-environment-nag {
  display: none;
  @media screen and (min-width: $breakpoint-lg) {
    display: block;
    position: fixed;
    top: 0rem;
    right: 0rem;
    font-size: 0.65rem;
    background: $red;
    color: #fff;
    padding: 0.4rem 0.6rem;
    z-index: 10;
    top: 62px;
  }

  &.page-has-old-plus-link {
    top: 100px;
  }
}
.back-to-old-plus {
  position: fixed;
  display: flex;
  align-items: center;
  right: 0;
  top: 60px;
  z-index: 10;
  padding: 0.5rem;
  font-size: 0.85rem;
  i {
    margin-left: 0.2rem;
  }
  .loader-icon {
    position: relative;
    margin: 0;
    font-size: 0.7rem;
    height: 1rem;
    top: 0.3rem;
  }
}
.maintennance-break {
  padding: 10rem 2rem 0 2rem;
}
</style>
