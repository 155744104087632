/**
 * @param object item from kentico
 * @return boolean
 */
const isDeadline = (item) => {
    let ret = false;
      if(item.elements && item.elements.type && item.elements.type.value.length) {
          item.elements.type.value.map( type => {
            if(type.codename == 'deadline')
                ret = true;
          });
      } 

      return ret;
}

export {isDeadline};