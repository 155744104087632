<template>
  <header class="site-header site-header--fixed">
    <div class="container-fluid site-header__inner">
      <div class="site-header__left">
        <router-link :to="$route.meta && $route.meta.admin === true ? '/admin' : '/'">
          <div class="logo-wrapper">
            <img :src="yitLogo" alt="YIT Plus" />
            <div v-if="$route.meta && $route.meta.admin === true" class="color--white d-none d-md-flex align-items-center">
              <i class="fal fa-cog"></i>&nbsp;
              <strong>{{$t('admin.title')}}</strong>
            </div>
            <div to="/admin" class="color--white d-none d-md-flex" v-else-if="isBusinessPartner && getCurrentPartnerCompanyDetails">
              {{getCurrentPartnerCompanyDetails.name}}
            </div>
            <div class="color--white" v-else>
              {{ getPageName }}
            </div>
          </div>
        </router-link>
      </div>
      <div class="site-header__right">
        <ProfileMenu @toggle="toggleComponents('profileMenuState')" @close="componentStates.profileMenuState=false" :open="componentStates.profileMenuState" />
        <NotificationPrompt
          :open="componentStates.notificationsPromptState"
          @toggle="toggleComponents('notificationsPromptState')"
          @on-item-click="componentStates.notificationsPromptState=false"
          @close="componentStates.notificationsPromptState=false"
        />
        <div class="site-header__navigation">
          <OffCanvasMenuToggle @toggle="menuState = !menuState" />
          <OffCanvasMenuNavigation :open="menuState" @onClose="menuState = false">
            <MainNavigation @on-item-click="menuState=false" />
          </OffCanvasMenuNavigation>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import OffCanvasMenuNavigation from "@/components/common/Navigation/OffCanvasMenu/Navigation";
import OffCanvasMenuToggle from "@/components/common/Navigation/OffCanvasMenu/Toggle";
import NotificationPrompt from "@/components/Notification/NotificationPrompt";
import MainNavigation from "@/components/Navigation/MainNavigation";
import ProfileMenu from "@/components/ProfileMenu";
import { mapGetters } from "vuex";
import yitLogo from "@/assets/images/yit-logo-white.svg";

export default {
  components: {
    OffCanvasMenuNavigation,
    OffCanvasMenuToggle,
    NotificationPrompt,
    MainNavigation,
    ProfileMenu
  },
  props: ["title"],
  computed: {
    ...mapGetters(["getMainNavigationItems", "isBusinessPartner", "getCurrentPartnerCompanyDetails", "getPageName", "getSelectedHousingCompany"])
  },
  data() {
    return {
      menuState: false,
      componentStates: {
        notificationsPromptState: false,
        profileMenuState: false
      },
      yitLogo
    };
  },
  methods: {
    toggleComponents(component) {
      Object.keys(this.componentStates).map(comp => {
        if(comp !== component)
          this.componentStates[comp] = false;
      });
      this.componentStates[component] = !this.componentStates[component];
    }
  },
  events: {
    closeOffcanvas: function() {
      this.menuState = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: .9rem;
  line-height: 1.3;
  white-space: nowrap;
  > div {
    border-left: 1px solid rgba(255,255,255, .5);
    color: #fff;
    font-family: $fontPrimaryRegular;
    margin-left: .7rem;
    padding-left: .7rem;
    line-height: 1;
    padding-top: 0.32rem;
    padding-bottom: 0.2rem;
  }
  @media screen and (min-width: $breakpoint-md) {
    > div {
      margin-left: .75rem;
      padding-left: .75rem;
    }
  }
}
</style>