import axios from "axios"
import store from "@/store"

const One4AllClient = axios.create({
  baseURL: `${process.env.VUE_APP_ONE4ALL_API_BASE_URL}`,
  timeout: 30000,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
})  

One4AllClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      // network error
    } else {
      const code = error.response.status
      const response = error.response.data
      const originalRequest = error.config

      // TODO: endless loop ?
      if (code === 400 && response.data.error === "invalid_token") {
        const res = await store.dispatch("fetchOne4allAccessToken")
        if(res && res.data)
          originalRequest["headers"]["Authorization"] = `Bearer ${res.data.access_token}`
        return axios.request(originalRequest)
      }
    }
    return Promise.reject(error)
  }
)

One4AllClient.interceptors.request.use(async (config) => {
  const tokenExpiry = store.getters.one4AllTokenExpiriesIn
  const accessToken = store.getters.one4AllAccessToken || localStorage.getItem("one4allAccessToken")
  const now = new Date().getTime()

  // no token is expired, 15 minutes before token is expired -> attempt to refresh token
  if (!accessToken || tokenExpiry > 0 && now > tokenExpiry || tokenExpiry > 0 && now > tokenExpiry - 900) {
    const res = await store.dispatch("fetchOne4allAccessToken")
    if(res && res.data)
      config["headers"]["Authorization"] = `Bearer ${res.data.access_token}`
  }
  config["headers"]["Authorization"] = `Bearer ${accessToken}`
  return config
})

export default One4AllClient
