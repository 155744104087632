import FormValidationUtil from "./FormValidationUtil";

const FORM_DATA_LOCAL_STORAGE_KEY = "currentFormData";

class FormUtil {
  /**
   * Create a new Form instance.
   *
   * @param {object} data
   */
  constructor(data, validation) {
    this.originalData = data;

    for (let field in data) {
      this[field] = data[field];
    }

    this.validator = new FormValidationUtil(this, validation);
  }

  /**
   * Fetch all relevant data for the form.
   */
  data() {
    let data = {};

    for (let property in this.originalData) {
      data[property] = this[property];
    }

    return data;
  }

  /**
   * Reset the form fields.
   */
  reset() {
    for (let field in this.originalData) {
      this[field] = "";
    }

    this.validator.clear();
  }

  /**
   * Submit the form.
   *
   * @param {string} requestType
   * @param {string} url
   */
  submit(requestType, url) {
    return new Promise((resolve, reject) => {
      if (this.validator.validate()) {
        resolve();
        this.onSuccess();
      } else {
        reject();
        this.onFail();
      }
    });
  }

  /**
   * Handle a successful form submission.
   *
   * @param {object} data
   */
  onSuccess(data) {
    this.reset();
  }

  /**
   * Handle a failed form submission.
   *
   * @param {object} errors
   */
  onFail(errors) {
   
  }

  /**
   * Save copy of the form data into local storage
   */
  saveLocalCopy(targetData = null) {
      try {
        let formData = null;
        if (targetData)
          formData = targetData;
        else
          formData = this.data();

        localStorage.setItem(
          FORM_DATA_LOCAL_STORAGE_KEY,
          JSON.stringify(formData)
        );
      } catch(err) {
        console.error(err);
      }
  }
  
  /**
   * Get saved data from local storage
   * @param {*} target 
   * @param {Boolean} resetLocalCopy 
   * @returns 
   */
  getLocalCopy(targetData = null, resetLocalCopy=true) {
    try {
      let formData = JSON.parse(localStorage.getItem(FORM_DATA_LOCAL_STORAGE_KEY));
      if(formData) {
        if (targetData) {
          for (let property in formData) {
            if (typeof targetData[property] != 'undefined')
              targetData[property] = formData[property];
          }
          if(resetLocalCopy)
            this.removeLocalCopy();
          return targetData;
          
        } else {
          for (let property in formData) {
            if (typeof this[property] != 'undefined')
              this[property] = formData[property];
          }
          if(resetLocalCopy)
            this.removeLocalCopy();
          return this;
        }
        
      } else 
        return null;
        
    } catch(err) {
      console.error(err);
    }
  }

  /**
   * 
   */
  removeLocalCopy() {
    try {
      localStorage.removeItem(FORM_DATA_LOCAL_STORAGE_KEY);
    } catch(err) {
      console.error(err);
    }
  }
}

export default FormUtil;
