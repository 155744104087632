import KenticoService from "@/services/KenticoService";
import _ from "lodash";

const contentTypeCodename = "campaign";

const api = new KenticoService();
api.setContentType(contentTypeCodename);

const getDefaultState = () => {
  return {
    calendarEntries: [],
    campaignContentModelData: null
  };
};

const state = getDefaultState();

const getters = {
  getCampaignsAdmin: state => state.calendarEntries,
  getCampaignContentModel: state => state.campaignContentModelData
};

const actions = {
  fetchCampaignsAdmin({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.resetFilters();

      let defaultParams = {
        order: "start_time",
        orderDirection: "desc",
        limit: 10
      };
      params = _.merge(defaultParams, params);

      if(params.start_time_from)
        api.addFilter("start_time", KenticoService.convertDateToKenticoDate(params.start_time_from), "gte");
      if(params.start_time_to)
        api.addFilter("start_time", KenticoService.convertDateToKenticoDate(params.start_time_to), "lte");
        return api
        .fetchItems(params)
        .then(res => {
          if(params.append)
            commit("appendCampaignsAdmin", res.data.items);
          else
            commit("setCampaignsAdmin", res.data.items);
          resolve(res.data);
        })
        .catch(err => {console.log(err); reject(err);});
    });
  },
  fetchCampaignContentModel({ commit }) {
    return api
      .fetchContentType(contentTypeCodename)
      .then(res => {
        commit("setCampaignContentModelData", res.data);
      })
      .catch(err => console.log(err));
  }
};

const mutations = {
  setCampaignsAdmin: (state, res) => (state.calendarEntries = res),
  appendCampaignsAdmin: (state, res) => (state.calendarEntries = state.calendarEntries.concat(res)),
  setCampaignContentModelData: (state, res) => (state.campaignContentModelData = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
