export default class KenticoVideo {
    constructor(obj = null) {
        this.id = "";
        this.codename = "";
        this.title = "";
        this.source = "";
        this.identifier = "";
        this.full_url = "";
        this.document_folder_key = "";
        this._type = "Video";

        if(obj) {
            Object.keys(obj).map(field => {
                if(this.hasOwnProperty(field))
                    this[field] = obj[field];
            })

            //handle kentico style of object
            if(obj.elements) {
                Object.keys(obj.elements).map(field => {
                    if(this.hasOwnProperty(field))
                        this[field] = obj.elements[field].value;
                })
            }
            if(obj.system) {
                Object.keys(obj.system).map(field => {
                    if(this.hasOwnProperty(field))
                        this[field] = obj.system[field];
                })
            }
        }
    }
  
    asKenticoModel() {
        return {
            elements: {
                title: { value: this.title },
                document_folder_key: {value: this.document_folder_key},
                source: {value: this.source},
                identifier: {value: this.identifier},
                full_url: {value: this.full_url}
            },
            system: {
                id: this.id,
                codename: this.codename
            }
        };
    }
  }
  