import axios from "axios";
import { axiosNoAuthInterceptor } from "@/services/TokenService";
import store from "@/store";

const LiferayClient = axios.create({
  baseURL: process.env.VUE_APP_LIFERAY_API_BASE_URL,
  timeout: 60000,
  withCredentials: true, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

const includeLiferayJwt = (token = null) => {
  if(!token)
    token = store.getters.getAuthToken;
  LiferayClient.interceptors.request.use(function (config) {
    config.headers.Authorization =
      "Bearer " + token
    return config;
  });
};


export default LiferayClient;
export { LiferayClient, includeLiferayJwt };