import settings from "@/globals";
import { getMappedTaxonomy } from "@/utils/MappedTaxonomies";
import { normalizeSync } from "normalize-diacritics";
import store from "@/store";
export default class User {
  constructor(user) {
    this.id = null;
    this.birthday = null;
    this.firstName = null;
    this.lastName = null;
    this.locale = "en";
    this.roles = [];
    this.publicProfile = true;
    this.buildingTaxonomies = [];
    this.countries = [];
    this.businessUnits = [];
    this.areas = [];
    this.cities = [];
    this.projects = [];
    this.liferayToken = null;
    
    this.hcExtIds = [];
    if(user && Array.isArray(user.hcs)) {
      user.hcs.map(hc => {
        this.hcExtIds.push(hc.hcExt);
      });
    }

    if(user && Array.isArray(user.partnerCompanies)) {
      user.partnerCompanies.map(hc => {
        this.hcExtIds.push(hc.externalId);
      });
    }

    if (user && typeof user == "object") {
      Object.assign(this, user);
      
      //override uuid with liferay users
      if (user.uuid && !store.getters.isStateB2C) this.id = user.uuid;

    }

  }

  getId() {
    return this.id;
  }

  getDisplayName() {
    return this.firstName;
  }

  hasRole(role = "", params = {}) {
    const defaultParams = {
      context: 'project',
    }
    params = {...defaultParams, ...params}
    if (!role) return false;
    return this.getRoles(params).includes(role);
  }

  hasAnyOfRoles(roles = []) {
    let curRoles = this.getRoles();
    return roles.some(r => curRoles.includes(r));
  }

  getRoles(params = {}) {

    const defaultParams = {
      context: 'project',
    }

    params = {...defaultParams, ...params}
    let roles = [];

    //Get project roles
    let targetContext = [];
    if(params.context == 'apartment') {
      targetContext = store.getters.getSelectedApartment;
    } else {
      targetContext = store.getters.getSelectedHousingCompany;
    }
    

    if(targetContext && Array.isArray(targetContext.roles) && targetContext.roles.length) {
      Object.values(settings.UIRoles).map(role => {
        if (settings.roleMap[role.name]) {
          if (settings.roleMap[role.name].some(r => targetContext.roles.includes(r)))
            roles.push(role.name);
        }
      });
    }
    
    //admin roles are not under hc's so we need to check them individually
    if (this.roles && this.roles.length) {
      settings.projectRoleGroups.map(roleName => {
        settings.roleMap[roleName].map(role => {
          if(this.roles.includes(role) && !roles.includes(role)) {
            roles.push(roleName);
          }
        })
      });
      /*settings.roleMap['admin'].map(r => {
        if(this.roles.includes(r))
          roles.push('admin');
      });
      settings.roleMap['yitAdmin'].map(r => {
        if(this.roles.includes(r))
          roles.push('yitAdmin');
      });
      settings.roleMap['yitUser'].map(r => {
        if(this.roles.includes(r))
          roles.push('yitUser');
      });
      settings.roleMap['businessPartner'].map(r => {
        if(this.roles.includes(r))
          roles.push('businessPartner');
      });*/
    }

    return roles;
  }

  getProjects() {
    if (!this.projects.length) this._buildBuildingTaxonomyFiltersForUser();
    return this.projects;
  }

  getCountries() {
    if (!this.countries.length) this._buildBuildingTaxonomyFiltersForUser();
    return this.countries;
  }

  isResident() {
    return this.hasAnyOfRoles(['owner', 'tenant', 'familyMember']);
  }

  _buildBuildingTaxonomyFiltersForUser() {
    if (!this.hcs || !this.hcs.length) return;

    this.hcs.map(hc => {
      //Countries
      let country = this._retrieveTaxonomyName(hc.addressCountry);
      if (country && !this.countries.includes(country))
        this.countries.push(country);

      let project = this._retrieveTaxonomyName(hc.name);
      let roles = this.getRoles();
      if(roles.includes('admin') || roles.includes('yitUser')) {
        if (project && !this.projects.includes(project))
        this.projects.push(project);
      } else {
        if (project && !this.projects.includes(project)) {
          if (settings.roleMap.contributor.some(r => hc.roles.includes(r))) {
           this.projects.push(project);
          }
       }  
      }
          
    });
  }

  _retrieveTaxonomyName(taxonomy) {
    return getMappedTaxonomy(normalizeSync(taxonomy))
      .toLowerCase()
      .replace(/\s/g, "_")
      .replace("-", "_")
      .replace(".", "_");
  }
}
