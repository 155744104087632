//const KenticoCloud = require("kentico-cloud-delivery");
import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import store from "@/store";
//import NewsService from "@/services/NewsService";

const api = new KenticoDeliveryApiService();
api.setContentType(["page"]);

const getDefaultState = () => {
  return {
    pages: [],
    currentPage: {}
  };
};

const state = getDefaultState();

const getters = {
  getPages: state => state.pages,
  getCurrentPage: state => state.currentPage
};

const actions = {
  fetchPages({ commit }) {
    return new Promise(function(resolve, reject) {
      api
        .fetchList(
          {
            order: "url",
            orderDirection: "asc"
          },
          ["url", "title"]
        )
        .then(response => {
          console.log("Fetched news: ", response.data.items);
          commit("setPages", response.data.items);
          resolve(response.data.items);
        })
        .catch(err => {
          console.log("Fetching news failed: ", err);
          reject(err);
        });
    });
  },
  fetchSinglePage({ commit }, params) {
    let slug  = params.slug && params.slug.startsWith("/") ? params.slug.substring(1) : params.slug;
    params.order = "url";
    params.orderDirection = "asc";
    params.customStringQuery = "elements.url=" + slug;
    return new Promise(function(resolve, reject) {
      api
        .fetchList({
          ...params
         })
        .then(response => {
          //const hc = store.getters.getSelectedHousingCompany;
          const buildingTaxonomies = store.getters.getBuildingTaxonomyFilters;

          //Primarily get the "building taxonomy" spesific page if found
          let foundPage = null;
          if (response.data.items && response.data.items.length > 1) {
            response.data.items.map(page => {
              if (
                page.elements.buildingtaxonomy &&
                page.elements.buildingtaxonomy.value &&
                page.elements.buildingtaxonomy.value.length
              ) {
                let taxonomyFound = false;
                page.elements.buildingtaxonomy.value.map(tax => {
                  if (buildingTaxonomies.includes(tax.codename))
                    taxonomyFound = true;
                });
                if (taxonomyFound) foundPage = page;
              }

              return page;
            });
          }

          //If no pages found with taxonomy, return the first one
          if (!foundPage) {
            foundPage = response.data.items.length
              ? response.data.items[0]
              : {};
          }
          commit("setCurrentPage", foundPage);

          resolve(foundPage);
        })
        .catch(err => {
          console.log("Fetching pages failed: ", err);
          reject(err);
        });
    });
  },
  fetchPublicPage({ commit }, params) {
    return new Promise(function(resolve, reject) {
      api
        .fetchPublicPage(params)
        .then(response => {
            const foundPage = response.data.item
              ? response.data.item
              : {};
          commit("setCurrentPage", foundPage);
          resolve(foundPage);
        })
        .catch(err => {
          console.log("Fetching pages failed: ", err);
          reject(err);
        });
    });
  },
};

const mutations = {
  setPages: (state, res) => (state.pages = res),
  setCurrentPage: (state, res) => (state.currentPage = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
