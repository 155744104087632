<template>
  <Modal @close="displayWelcomeModal=false" colorMode="dark" :displayCloseButton="false" class="welcome-to-yit-plus-modal" v-if="displayWelcomeModal">
    <h2>{{$t('welcome-dialog.title')}}</h2>
    <p class="lead">{{$t('welcome-dialog.text')}}</p>
    <div class="position--relative welcome-to-yit-plus-modal__carousel-wrapper" v-if="getSteps.length">
      <ContentCarousel ref="contentCarousel" :loop="true" :duration="500" easing="ease-out" :autoplay="true" :draggable="true" :autoplay-duration="8000" @change="change">
        <div v-for="(step, index) in getSteps" :key="step.id">
          <transition>
            <ProjectStepCard :step="step" :backgroundDirection="index % 2 === 0 ? 'left' : 'right'" />
          </transition>
        </div>
      </ContentCarousel>
      <CarouselArrowNavigation @on-select-next="next" @on-select-previous="prev" />
    </div>
    <template v-slot:footer>
      <div class="button-group text-center">
        <Button type="button" mode="link" @click="onCloseDialog">{{$t('welcome-dialog.close')}}</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import ProjectStepCard from "./Card";
import {ContentCarousel, CarouselArrowNavigation, CarouselDotsNavigation} from "@/components/common/Carousel";
import Modal from "@/components/common/Modal";
import { mapActions, mapGetters } from 'vuex';
import { isPastDate } from '@/utils/DateHelper';

export default {
  name: "WelcomeDialog",
  components: {
    Modal,
    ProjectStepCard,
    ContentCarousel, 
    CarouselArrowNavigation, 
    CarouselDotsNavigation
  },
  data() {
    return {
      id: 'welcome-to-plus-dialog',
      displayWelcomeModal: true,
      activeIndex: 0
    }
  },
  computed: {
    ...mapGetters(['getSteps', 'getSelectedHousingCompany'])
  },
  methods: {
    ...mapActions(['markUserDialogRead']),
    prev() {
      if(typeof this.$refs.contentCarousel != 'undefined') {
        this.$refs.contentCarousel.prev();
      }
    },
    next() {
      if(typeof this.$refs.contentCarousel != 'undefined') {
        this.$refs.contentCarousel.next();
      }
    },
    change(activeIndex) {
      if(typeof activeIndex != 'undefined') {
        if(typeof this.$refs.contentCarousel != 'undefined') {
          this.activeIndex = parseInt(activeIndex);
        }
      }
    },
    setActiveIndex() {
      if(this.getSteps.length) {
        let nextStepIndex = 0;
        this.getSteps.map( (step, index) => {
          if(!isPastDate(step.date_start) && !nextStepIndex) {
            nextStepIndex = index;
          }
        })

        if(nextStepIndex)
          this.$refs.contentCarousel.goTo(nextStepIndex);
      }
    },
    onCloseDialog() {
      this.markUserDialogRead( {id: this.id, projectId: this.getSelectedHousingCompany.hcExt} );
      this.displayWelcomeModal=false;
    }
  },
  mounted() {
    this.setActiveIndex();
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/variables";
.welcome-to-yit-plus-modal {

  &__carousel-wrapper {
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-color: $blueLightest;
  }

  @media screen and (max-width: $breakpoint-md) {
    .modal-container {
      max-width: 100vw;
    }
  }
  .modal__body {
    overflow-x: hidden;
  }

  .step-card {
    min-height: 17rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    h1,h2,h3,h4,h5,h6 {
      font-size: 1.6rem;
    }
  }

  .content-carousel__arrows {
    .right, .left {
      color: #fff;
    }
    .right {
      right: 0rem;
    }
    .left {
      left: 0rem;
    }
  }
}
</style>