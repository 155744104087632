<template>
  <Modal @close="onClose" colorMode="dark" class="authentication-required-modal">

      <i class="icon--large fal fa-clock"></i>
      <p>
        <strong>{{$t("authentication.sessionExpired.title") }}</strong>
      </p>
      <p>{{$t("authentication.sessionExpired.description") }}</p>

      <template v-slot:footer>
        <button
          class="button button--primary button--cta"
          @click="onButtonClick"
        >{{$t("authentication.sessionExpired.buttonText") }}</button>
      </template>
    </Modal>
</template>

<script>
import Modal from "@/components/common/Modal";

export default {
    name: 'AuthenticationRequiredModal',
    components: {
        Modal
    },
    methods: {
        onClose(event) {
            if(event)
                event.preventDefault();
        },
        onButtonClick(event) {
            if(event)
                event.preventDefault();

          try {
            localStorage.setItem(
              "redirectAfterLogin",
              this.$route.fullPath
            );
          } catch(err) {
            console.error(err);
          }
          window.location.href = process.env.VUE_APP_B2C_LOGIN_URL;
        }
    }
}
</script>

<style lang="scss">
.authentication-required-modal {
    .button__close {
        display: none !important;
    }
}
</style>