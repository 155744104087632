import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import { getFeature } from "@/features";

const api = new KenticoDeliveryApiService();
api.setContentType(["faq"]);
api.targetByDefaultBuildingTaxonomy = true; //target default by building taxonomy

const excludedSectionsByFeature = {
  'bookings': 'one4all-bookings'
}

const getDefaultState = () => {
  return {
    faq: [],
    selectedAccordionItems: []
  };
};

const state = getDefaultState();

const getters = {
  getAllFaqArticles: state => state.faq,
  getFaqArticles: state => {
    return filter => {
      let ret = state.faq;
      filter = filter.toLowerCase();

      return ret.filter(faq => {
        if (
          faq.elements.question.value.toLowerCase().includes(filter) ||
          faq.elements.answer.value.toLowerCase().includes(filter)
        )
          return true;
      });
    };
  },
  getFaqArticlesBySection: state => section => {
    if (Object.keys(excludedSectionsByFeature).includes(section)) {
      let requiredFeatureId = excludedSectionsByFeature[section];

      if (!getFeature(requiredFeatureId))
        return [];
    }
    let articles = state.faq.filter(function(faq) {
      if (faq.elements.faqtaxonomy && faq.elements.faqtaxonomy.value.length) {
        return faq.elements.faqtaxonomy.value[0].codename === section;
      }
    });
    return articles;
  },
  getCurrentFaqArticle: state => id => {
    let article = state.faq.find(function(faqArticle) {
      return faqArticle.system.codename == id;
    });
    return article;
  },
  getSelectedAccordionItems: state => state.selectedAccordionItems
};

const actions = {
  fetchFaqArticles({ commit }, limit = 50) {
    return new Promise(function(resolve, reject) {
      api
        .fetchList({
          limit,
          order: "priority",
          orderDirection: "desc"
        })
        .then(response => {
          console.log("Fetched faq: ", response.data.items);
          commit("setFaqArticles", response.data.items);
          resolve(response.data.items);
        })
        .catch(err => {
          console.log("Fetching faq failed: ", err);
          reject(err);
        });
    });
  },
  setSelectedAccordionItem({ commit }, id) {
    const items = state.selectedAccordionItems.includes(id) 
      ? state.selectedAccordionItems.filter(it => it !== id) 
      : [ ...state.selectedAccordionItems, id ]
    commit("setSelectedAccordionItems", items);
  }
};

const mutations = {
  setFaqArticles: (state, res) => (state.faq = res),
  setSelectedAccordionItems: (state, res) => (state.selectedAccordionItems = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
