import LiferayClient from "./LiferayClient";
import BaseService from "./BaseService";

export default class CalendarService extends BaseService {
  constructor() {
    super();
    this.baseUrl = "/delegate/yithome-api/calendarevents";
  }

  /**
   * @param {*} object form data
   * @return Promise
   */
  getList(_params = {}) {
    if (this.getHcId()) {
      let params = {
        start: _params.start ? _params.start : 0,
        end: _params.end ? _params.end : 10
      };

      if (_params.startTime) params.startTime = _params.startTime;
      if (_params.endTime) params.endTime = _params.endTime;

      let uri = `${this.baseUrl}/list/${this.getHcId()}`;

      console.log("Started to fetch calendar entries: ", uri, params);

      return LiferayClient.post(uri, params);
    }
  }

  /**
   * @param {*} object form data
   * @return Promise
   */
  getAll(params = {}) {
    return LiferayClient.get(`${this.baseUrl}/list/${this.getHcId()}`);
  }

  /**
   * @param {Integer} id
   * @return Promise
   */
  getSingle(id) {
    return LiferayClient.get(`${this.baseUrl}/get/${id}`);
  }
}
