<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 d-none d-lg-block">
        <MainNavigation />
      </div>
      <div class="col-lg-8">
        <main id="main-content">
          <div class="page-section">
            <div class="page-section__body">
              <slot />
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import MainNavigation from "@/components/Navigation/MainNavigation";
export default {
  name: "LayoutTwoColumns",
  components: {
    MainNavigation
  }
};
</script>