import axios from "axios";
import BaseService from "./BaseService";
import store from "@/store";

const GbuilderClient = axios.create({
  baseURL: process.env.VUE_APP_AZURE_FUNCTION_BASE + "/api",
  timeout: 20000,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default class GbuilderService extends BaseService {
  constructor() {
    super();
    this.baseUrl = "";
    this.hcID = null;
  }

  async fetchMaterialDeadlineBundles(hcExtId = null, aptExtId = null) {
    if (!hcExtId) hcExtId = store.getters.getSelectedHousingCompany.hcExt;
    if (!aptExtId) aptExtId = store.getters.getSelectedApartment && store.getters.getSelectedApartment.aptext ? store.getters.getSelectedApartment.aptext : null;

     //Temporarily hard coded id's for testing
    //hcExtId = "1-I6LMY9";
    //aptExtId = "1-IMX3ID";


    if(!hcExtId || !aptExtId) {
      throw new Error("need project ext id and apt ext id for fetching material deadline bundles");
    }
    
    return GbuilderClient.get(
      `https://api-v2.gbuilder.com/api/v6/public/organisations/YIT/integrations/YIT/projects/${hcExtId}/apartments/${aptExtId}/bundles`
    );
  }

  async fetchMaterialDeadlines(hcExtId = null, aptExtId = null) {
    if (!hcExtId) hcExtId = store.getters.getSelectedHousingCompany.hcExt;
    if (!aptExtId) aptExtId = store.getters.getSelectedApartment && store.getters.getSelectedApartment.aptext ? store.getters.getSelectedApartment.aptext : null;

    //Temporarily hard coded id's for testing
    //hcExtId = "1-HOTPHX";
    //aptExtId = "1-KQR1AZ";


    if(!hcExtId || !aptExtId) {
      throw new Error("need project ext id and apt ext id for fetching material deadline bundles");
    }
    
    return GbuilderClient.get(
      `https://api-v2.gbuilder.com/api/v6/public/organisations/YIT/integrations/YIT/projects/${hcExtId}/apartments/${aptExtId}/bundles/deadlines`
      //`https://api.beta.gbuilder.com/api/v6/public/organisations/YIT/integrations/YIT/projects/${hcExtId}/apartments/${aptExtId}/bundles/deadlines`
    );
  }
}
