export default [
  {
    path: "/campaign/:id",
    name: "/campaign/:id",
    meta: {
      sectionTitle: "navigation.service-shop",
      title: "navigation.service-shop"
    },
    component: () =>
      import(
        /* webpackChunkName: "service-shop" */ "@/views/ServiceShop/SingleLiferayCampaign.vue"
      )
  },
  {
    path: "/service-shop/advertisement/:id",
    name: "/service-shop/advertisement/:id",
    meta: {
      sectionTitle: "navigation.service-shop",
      title: "navigation.service-shop",
      source: "kentico",
      skipBuildPageMetaInRouter: true
    },
    component: () =>
      import(
        /* webpackChunkName: "service-shop" */ "@/views/ServiceShop/SingleLiferayCampaign.vue"
      )
  },
  {
    path: "/service-shop/all-campaigns",
    name: "/service-shop/all-campaigns",
    component: () =>
      import(
        /* webpackChunkName: "service-shop" */ "@/views/ServiceShop/AllCampaigns.vue"
      ),
    meta: {
      sectionTitle: "navigation.service-shop",
      title: "navigation.service-shop.all-campaigns"
    }
  },
  {
    path: "/service-shop/",
    name: "/service-shop/",
    component: () =>
      import(
        /* webpackChunkName: "service-shop" */ "@/views/ServiceShop/View.vue"
      ),
    meta: {
      sectionTitle: "navigation.service-shop",
      title: "navigation.service-shop",
      introduction: "serviceshop.description",
      displayHeadSection: true,
    }
  },
  {
    path: "/service-shop/partner/:id",
    name: "/service-shop/partner/:id",
    meta: {
      sectionTitle: "navigation.service-shop",
      title: "navigation.service-shop",
      source: "azure",
      skipBuildPageMetaInRouter: true
    },
    component: () =>
      import(
        /* webpackChunkName: "partner" */ "@/views/ServiceShop/SinglePartner.vue"
      )
  },
  {
    path: "/admin/campaigns",
    name: "/admin/campaigns",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Campaigns/List.vue"),
    meta: {
      admin: true,
      sectionTitle: "navigation.campaigns",
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'partner']
      }
    }
  },
  {
    path: "/admin/campaigns/edit/:id",
    name: "/admin/campaigns/edit/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Campaigns/Edit.vue"),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'partner']
      }
    }
  },
  {
    path: "/admin/campaigns/created/:id",
    name: "/admin/campaigns/created/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Campaigns/AfterCreate.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/campaigns/new",
    name: "/admin/campaigns/new",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Campaigns/Edit.vue"),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'partner']
      }
    }
  },
  {
    path: "/admin/campaigns/wizard",
    name: "/admin/campaigns/wizard",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Campaigns/Wizard.vue"),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'partner']
      }
    }
  }
]