import ProjectService from "@/services/ProjectService";
import KenticoService from "@/services/KenticoService";
import UserService from "@/services/UserService";
import { getMappedTaxonomy } from "@/utils/MappedTaxonomies";
import { normalizeSync } from "normalize-diacritics";
import ProjectStep from "@/models/ProjectStep";
import { remove } from "@/store/mutators";
import store from "@/store/index";
import _ from "lodash";

let api = new ProjectService();
let userApi = new UserService();
let kenticoApi = new KenticoService();
kenticoApi.setContentType("step");

/*function byKey(key) {
    return function (o) {
        var v = parseInt(String(o[key]).replace(/[^0-9.]/g, ''), 10);
        return isNaN(v) ? o[key] : v;
    };
}*/

const roleMap = {
  propertyManager: ["propertyManager"],
  maintenanceManager: ["maintenanceManager"],
};

const _retrieveTaxonomyName = (taxonomy = "") => {
  return getMappedTaxonomy(normalizeSync(taxonomy))
    .toLowerCase()
    .replace(/\s/g, "_")
    .replace("-", "_")
    .replace(".", "_");
};

const getDefaultState = () => {
  return {
    apartments: [],
    currentProject: null,
    propertyManagerCompanies: [],
    maintenanceManagerCompanies: [],
    housingCompanyPropertyManagers: [],
    housingCompanyMaintenanceManagers: [],
    partnerCompanies: [],
    businessPartners: [],
    apartmentDetails: null,
    defaultSteps: [],
    projectSteps: [],
    projectWizardByPasssed: []
  };
};

const state = getDefaultState();

const getters = {
  //getHcApartments: state => _.sortBy(state.apartments, byKey('no')),
  //getHcApartments: state => state.apartments,
  getHcApartments: (state) => {
    return state.apartments.sort((a, b) => {
      if (!a.no) a.no = "";
      if (!b.no) b.no = "";
      return a.no.localeCompare(b.no, undefined, { numeric: true });
    });
  },

  getCurrentAdminProject: (state) => state.currentProject,
  getPropertyManagerCompanies: (state) => state.propertyManagerCompanies,
  getMaintenanceManagerCompanies: (state) => state.maintenanceManagerCompanies,
  getPartnerCompanies: (state) => state.partnerCompanies,
  getHousingCompanyPropertyManagers: (state) =>
    state.housingCompanyPropertyManagers,
  getHousingCompanyMaintenanceManagers: (state) =>
    state.housingCompanyMaintenanceManagers,
  getAllBusinessPartners: (state) => state.businessPartners,
  getNormalizedKenticoBuildingTaxonomiesForHousingCompany: (state) => {
    return (project) => {
      let ret = [];
      ret.push(_retrieveTaxonomyName(project.addressCountry));
      ret.push(_retrieveTaxonomyName(project.businessUnit));
      ret.push(_retrieveTaxonomyName(project.addressCity));
      ret.push(_retrieveTaxonomyName(project.marketingName));
      ret.push(_retrieveTaxonomyName(project.name));

      return ret.toString();
    };
  },
  getApartmentDetails: (state) => state.apartmentDetails,
  getDefaultSteps: (state) => state.defaultSteps,
  getProjectSteps: (state) => state.projectSteps,
  isProjectWizardByPassed: (state) => projectId => {
    return state.projectWizardByPasssed.includes(projectId)
  }
};

const actions = {
  fetchApartmentsByHcExtId({ commit }, hcExtId) {
    return api
      .getApartments(hcExtId)
      .then((response) => {
       commit(
          "setApartments",
          Array.isArray(response.data) ? response.data : []
        );
      })
      .catch((err) => {
        console.log(err);
        commit("setApartments", []);
      });
  },
  async fetchAdminProject({ commit }, hcExtId) {
    return api
      .fetchProjectDetails(hcExtId)
      .then((response) => {
        commit(
          "setCurrentProject",
          Object.assign(response.data.item, { _type: "Project" })
        );
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  async fetchPartnerCompanies({ commit }, params) {
    try {
      let response = await api.fetchPartnerCompanies(params);

      if (params.append) commit("appendPartnerCompanies", response.data);
      else commit("setPartnerCompanies", response.data);

      return response.data;
    } catch (err) {
      console.log(err);
      commit("setPartnerCompanies", []);
      throw err;
    }
  },
  async fetchPropertyManagerCompanies({ commit }, params = {}) {
    try {
      let response = await api.fetchAvailablePropertyManagers(params);
      if (response.data.length)
        commit("setPropertyManagerCompanies", response.data);
    } catch (err) {
      console.log(err);
      commit("setPropertyManagerCompanies", []);
    }
  },
  async fetchMaintenanceManagerCompanies({ commit }, params = {}) {
    try {
      let response = await api.fetchAvailableMaintenanceManagers(params);
      if (response.data.length)
        commit("setMaintenanceManagerCompanies", response.data);
    } catch (err) {
      console.log(err);
      commit("setMaintenanceManagerCompanies", []);
    }
  },
  async removePartnerCompanySelection({ commit }, payload) {
    return api.removePartnerCompanyConnectionFromHousingCompany(payload);
  },
  async fetchHousingCompanyPropertyManagers({ commit }, projectId) {
    try {
      let response = await userApi.getUsers({
        projectId,
        roles: roleMap.propertyManager,
      });
      let users = [];
      if (response.data) {
        if (Array.isArray(response.data)) {
          response.data.map((roleArray) => {
            if (Array.isArray(roleArray.value)) {
              roleArray.value.map((user) => {
                users.push(user);
                return user;
              });
            }
            return roleArray;
          });
        }
      }
      commit("setHousingCompanyPropertyManagers", users);
    } catch (err) {
      console.log(err);
    }
  },
  async fetchHousingCompanyMaintenanceManagers({ commit }, projectId) {
    try {
      let response = await userApi.getUsers({
        projectId,
        roles: roleMap.maintenanceManager,
      });
      let users = [];
      if (response.data) {
        if (Array.isArray(response.data)) {
          response.data.map((roleArray) => {
            if (Array.isArray(roleArray.value)) {
              roleArray.value.map((user) => {
                users.push(user);
                return user;
              });
            }
            return roleArray;
          });
        }
      }
      commit("setHousingCompanyMaintenanceManagers", users);
    } catch (err) {
      console.log(err);
    }
  },
  async updatePartnerCompanyKeyContact({ commit }, payload) {
    try {
      let res = await api.updatePartnerCompanyKeyContact(payload);
      if (res && res.data) {
        if (payload.type == "propertyManager")
          commit("updateCurrentProjectPropertyManagerKeyContact", res.data);
        else if (payload.type == "propertyManager")
          commit("updateCurrentProjectMaintenanceManagerKeyContact", res.data);
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  },
  fetchApartment({ commit }, apartmentId) {
    return new Promise((resolve, reject) => {
      api
        .getApartmentDetails(apartmentId)
        .then((response) => {
          commit("setApartmentDetails", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fetchAllBusinessPartners({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      let country = [];
      let businessPartnersPayload = {
        type: "BusinessPartner",
      };

      let currentUser = store.getters.getCurrentUser;
      if (currentUser) {
        country = currentUser.getCountries();
      }

      if (Array.isArray(country) && country.length === 1)
        businessPartnersPayload.country = country[0];

      if (params && params.limit)
        businessPartnersPayload.limit = parseInt(params.limit);

      api
        .fetchPartnerCompanies(businessPartnersPayload)
        .then((response) => {
          commit("setAllBusinessPartners", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  async fetchProjectSteps({ commit }, args = {}) {
    try {
      let payload = {
        buildingTaxonom: [],
        orderDirection: "asc",
      };
      if (args.projectId) {
        let terms = store.getters.getBuildingTaxonomyByExternalId(
          args.projectId
        );
        if (terms.length === 1)
          payload.buildingTaxonomies = [terms[0].codename];
      }

      if (!Array.isArray(payload.buildingTaxonomies) || payload.buildingTaxonomies.length === 0) {
        commit("setProjectSteps", []);
        return;
      }

      let res = await kenticoApi.fetchItems(payload);
      commit(
        "setProjectSteps",
        res.data.items.map((i) =>
          ProjectStep.fromKenticoModel(i, res.data.modular_content, {project: store.getters.getCurrentAdminProject})
        )
      );
    } catch (err) {
      commit("setProjectSteps", []);
      throw err;
    }
  },
  async fetchDefaultSteps({ commit }) {
    try {
      let payload = {
        buildingTaxonomies: ["admin"],
        order: "date_start",
        orderDirection: "asc",
      };

      let res = await kenticoApi.fetchItems(payload);
      commit(
        "setDefaultSteps",
        res.data.items.map((i) =>
          ProjectStep.fromKenticoModel(i, res.data.modular_content, {project: store.getters.getCurrentAdminProject})
        )
      );
    } catch (err) {
      commit("setDefaultSteps", []);
      throw err;
    }
  },
  async deleteProjectStep({ commit }, step) {
    try {
      await kenticoApi.deleteItem(step.id);
      commit("removeProjectStep", step);
    } catch (err) {
      throw err;
    }
  },
  cancelProjectWizardInCurrentSession({ commit }, projectId) {
    commit("setProjectWizardCanceledInProject", projectId);
  }
};

const mutations = {
  setApartments: (state, res) => (state.apartments = res),
  chooseProject: (state, hcExtId) => {
    //reset aparatments when choosing project
    state.apartments = [];
  },
  setCurrentProject: (state, res) => (state.currentProject = res),
  setPropertyManagerCompanies: (state, res) => {
    state.propertyManagerCompanies = res;
  },
  setMaintenanceManagerCompanies: (state, res) =>
    (state.maintenanceManagerCompanies = res),
  setPartnerCompanies: (state, res) => (state.partnerCompanies = res),
  appendPartnerCompanies: (state, res) =>
    (state.partnerCompanies = state.partnerCompanies.concat(res)),
  setHousingCompanyPropertyManagers: (state, res) =>
    (state.housingCompanyPropertyManagers = res),
  setHousingCompanyMaintenanceManagers: (state, res) =>
    (state.housingCompanyMaintenanceManagers = res),
  updateCurrentProjectPropertyManagerKeyContact: (state, res) => {
    if (
      state.currentProject &&
      Array.isArray(state.currentProject.selectedPropertyManagerCompanies) &&
      state.currentProject.selectedPropertyManagerCompanies.length
    ) {
      state.currentProject.selectedPropertyManagerCompanies[0].keyContact =
        res.selectedPropertyManagerCompanies[0].keyContact;
    }
  },
  updateCurrentProjectMaintenanceManagerKeyContact: (state, res) => {
    if (
      state &&
      state.currentProject &&
      Array.isArray(state.currentProject.selectedMaintenanceManagerCompanies) &&
      state.currentProject.selectedMaintenanceManagerCompanies.length
    ) {
      state.currentProject.selectedMaintenanceManagerCompanies[0].keyContact =
        res.selectedMaintenanceManagerCompanies[0].keyContact;
    }
  },
  setApartmentDetails: (state, res) => (state.apartmentDetails = res),
  setAllBusinessPartners: (state, res) => (state.businessPartners = res),
  setDefaultSteps: (state, res) => (state.defaultSteps = res),
  setProjectSteps: (state, res) => (state.projectSteps = res),
  removeProjectStep: (state, step) => {
    state.projectSteps = remove(state, step, "projectSteps", "id", step.id);
  },
  setProjectWizardCanceledInProject: (state, projectId) => (state.projectWizardByPasssed.push(projectId))
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations,
};
