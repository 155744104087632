import {TokenService} from "@/services/TokenService";
import store from "@/store";
import { getFeature } from "@/features";
import settings from "@/globals";
import UserService from "@/services/UserService";

const getDefaultState = () => {
  return {
    issuer: 'YITPlus',
    accessToken: null,
    authToken: null,
    tokenExpired: false,
    isRefreshingToken: false
  }
};

const state = getDefaultState();

const getters = {
  getTokenIssuer: state => state.issuer,
  isStateB2C: state => state.issuer == 'b2c' ? true : false,
  getLoginUrl: state => state.issuer === 'b2c' ? process.env.VUE_APP_B2C_LOGIN_URL + "&ui_locales=" + settings.locale : process.env.VUE_APP_LIFERAY_API_BASE_URL,
  getLogOutUrl: state => state.issuer === 'b2c' ? process.env.VUE_APP_B2C_LOGOUT_URL + "&ui_locales=" + settings.locale : process.env.VUE_APP_LIFERAY_API_BASE_URL + "/c/portal/logout",
  getAccessToken: state => state.accessToken,
  getAuthToken: state => state.authToken,
  getIsTokenExpired: state => state.tokenExpired,
  getTokenRefreshState: state => state.isRefreshingToken
};

const actions = {
  fetchAccessToken({commit}) {
    //...logic to fetch the access token
  },
  retrieveAccessToken({commit}) {
    //in b2c auth token is also access token
    if(state.getTokenIssuer == 'b2c' && state.authToken)
      commit('setAccessToken', state.authToken);
    if(state.getTokenIssuer == 'liferay' && state.authToken)
      commit('setAccessToken', store.getters.getCurrentUser.jwt);
  },
  async refreshAccessToken({ commit }) {

    let api = new UserService();

    if (!store.getters.getTokenRefreshState) {
        commit('setTokenRefreshState', true);
        console.log("Axios: Token expiring soon, refreshing token.");
        try {
          const tokenResponse = await api.getAccessToken(null, true, {
            headers: {
              authorization: 'Bearer: ' + TokenService.getToken('AUTH', 'b2c')
            }
          });

          if (tokenResponse && tokenResponse.data && tokenResponse.data.id_token) {
            let token = tokenResponse.data.id_token;
            let expiry = tokenResponse.data.not_before + tokenResponse.data.id_token_expires_in;
            if (window.localStorage) {
              TokenService.saveToken("AUTH", 'b2c', token);
              TokenService.saveToken("ACCESS", 'b2c', token);
              TokenService.saveToken("EXPIRY", 'b2c', expiry);
              commit("setAccessToken", token);
              console.log("Axios: Updated token and expiry");
            }
          }

        } catch (err) {
          console.log("Axios: ", err);
          let status = err && err.response && err.response.status ? err.response.status : null;
          let message = err && err.response && err.response.data ? err.response.data.error : null;
          if (status === 401 && message == 'TOKEN_EXPIRED') {
            console.log("Axios: Token already expired");
            store.commit('setTokenRefreshState', false);
            store.commit("setIsTokenExpired", true);
            store.dispatch("clearTokens");
          }
        } finally {
          commit('setTokenRefreshState', false);
        }
        
      }
    
  },
  clearTokens: ({commit}) => {
    let issuer = state.issuer;
    commit('setTokenIssuer', null);
    commit('setAuthToken', null);
    commit('setAccessToken', null);

    TokenService.removeToken('AUTH', issuer);
    TokenService.removeToken('ACCESS', issuer);
    TokenService.removeToken('REFRESH', issuer);
    TokenService.removeToken('EXPIRY', issuer);
  }
};

const mutations = {
  setTokenIssuer: (state, res) => {
    console.log("setTokenIssuer: ", res);
    state.issuer = res ? res : 'YITPlus';
  },
  setAccessToken: (state, res) => (state.accessToken = res),
  setAuthToken: (state, res) => (state.authToken = res),
  setIsTokenExpired: (state, res) => (state.tokenExpired = res ? true : false),
  setTokenRefreshState: (state, refresh)  => {
    state.isRefreshingToken = refresh;
  },
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};