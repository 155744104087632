import KenticoService from "@/services/KenticoService";

const contentTypeCodename = 'article';
const api = new KenticoService();
api.setContentType(contentTypeCodename);

const getDefaultState = () => {
  return {
    contentModel: null
  };
};

const state = getDefaultState();

const getters = {
  getArticleContentModel: state => state.contentModel
};

const actions = {
  async fetchArticleContentModel({ commit }) {
    try {
      const res = await api.fetchContentType(contentTypeCodename)
      commit("setArticleContentModel", res.data);
    } catch (err) {
      commit("setArticleContentModel", null);
      throw err;
    }
  }
};

const mutations = {
  setArticleContentModel: (state, res) => (state.contentModel = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
