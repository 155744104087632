import AppIndex from "@/views/Index.vue";
import PageTemplate from "@/views/Page";
import ErrorPage from "@/views/ErrorPage";
import MaintenancePage from "@/views/MaintenancePage";
import SwitchHousingCompany from "@/views/SwitchHousingCompany.vue";
import UISandbox from "@/views/UISandbox";
import DashboardBgMobile from "@/assets/images/dashboard-bg-mobile.jpg"
import DashboardBgDesktop from "@/assets/images/dashboard-bg-desktop.jpg"

export default [
  {
    path: "/",
    name: "AppIndex",
    component: AppIndex,
    meta: {
      title: "navigation.homepage",
      layout: "default",
      mainImage: {
        image: DashboardBgDesktop,
        title: 'homepage.slogan'
      }
    }
  },
  {
    path: "/logout",
    name: "Logout"
  },
  {
    path: "/error",
    component: ErrorPage,
    name: "error-page",
    meta: {
      public: true,
      layout: "fullwidth"
    }
  },
  {
    path: "/maintenance",
    name: "maintenance-page",
    component: MaintenancePage,
    meta: {
      public: true,
      layout: "fullwidth"
    }
  },
  {
    path: "/ui-sandbox",
    component: UISandbox,
    name: "ui-sandbox",
    meta: {
      accessPolicies: {
        roles: ['admin']
      }  
    }
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageTemplate,
    meta: {
      public: true,
      layout: "subpage",
      skipBuildPageMetaInRouter: true
    }
  },
  {
    path: "/change-housing-company",
    name: "/change-housing-company",
    component: SwitchHousingCompany,
    meta: {
      layout: "fullwidth"
    }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login/View.vue"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      layout: "fullwidth"
    }
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      layout: "fullwidth"
    },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/views/Register/View.vue"
      )
  },
  {
    path: "/firstlogin",
    name: "firstlogin",
    component: () =>
      import(/* webpackChunkName: "firstlogin" */ "@/views/FirstLogin/View.vue"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      layout: "fullwidth"
    }
  },
  {
    path: "/profile/",
    name: "/profile/",
    component: () =>
      import(/* webpackChunkName: "my-profile" */ "@/views/User/Profile.vue"),
    meta: {
      sectionTitle: "navigation.mysettings",
      title: "navigation.mysettings",
      layout: 'fullwidth'
    }
  },
  {
    path: "/my-profile/",
    name: "/my-profile/",
    component: () =>
      import(
        /* webpackChunkName: "my-profile" */ "@/views/User/MyProfile.vue"
      ),
    meta: {
      sectionTitle: "navigation.mysettings",
      title: "navigation.mysettings"
    }
  },
  {
    path: "/profile/email-changed/:userUuid/:confirmCode",
    name: "/profile/email-changed/:userUuid/:confirmCode",
    component: () =>
      import(/* webpackChunkName: "my-profile" */ "@/views/User/EmailChangeConfirmed.vue"),
    meta: {
      sectionTitle: "navigation.mysettings",
      title: "navigation.mysettings",
      layout: 'fullwidth',
      public: true
    }
  },
  {
    path: "/my-settings/",
    name: "/my-settings/",
    component: () =>
      import(
        /* webpackChunkName: "my-profile" */ "@/views/User/MySettings.vue"
      ),
    meta: {
      sectionTitle: "navigation.mysettings",
      title: "navigation.mysettings",
      layout: "subpage"
    }
  },
  {
    path: "/my-notifications/",
    name: "/my-notifications/",
    component: () =>
      import(
        /* webpackChunkName: "my-profile" */ "@/views/User/Notifications.vue"
      )
  },
  {
    path: "/deactivate/",
    name: "/deactivate/",
    component: () =>
      import(
        /* webpackChunkName: "my-profile" */ "@/views/User/Deactivate.vue"
      )
  },
  {
    path: "/profile/gdpr-report",
    name: "/profile/gdpr-report",
    meta: {
      layout: "fullwidth"
    },
    component: () =>
      import(
        /* webpackChunkName: "my-profile" */ "@/views/User/GDPReport.vue"
      )
  },
  {
    path: "/my-apartment/",
    name: "/my-apartment/",
    component: () =>
      import(
        /* webpackChunkName: "my-apartment" */ "@/views/Apartment/MyApartment.vue"
      ),
    meta: {
      sectionTitle: "navigation.myapartment",
      title: "navigation.myapartment",
      introduction: "navigation.myapartment.introduction",
      displayHeadSection: true,
    }
  },
  {
    path: "/my-apartment/about-apartment",
    name: "/my-apartment/about-apartment",
    component: () =>
      import(
        /* webpackChunkName: "my-apartment" */ "@/views/Apartment/ApartmentInfo.vue"
      ),
    meta: {
      sectionTitle: "navigation.myapartment",
      title: "navigation.myapartment",
      layout: "subpage"
    }
  },
  {
    path: "/my-apartment/about-building",
    name: "/my-apartment/about-building",
    component: () =>
      import(
        /* webpackChunkName: "my-apartment" */ "@/views/Apartment/BuildingInfo.vue"
      ),
    meta: {
      sectionTitle: "navigation.myapartment",
      title: "navigation.myapartment",
      layout: "subpage"
    }
  }
];