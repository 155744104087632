<template>
  <div></div>
</template>

<script>
import switchHousingCompany from "@/utils/switchHousingCompany";

export default {
  name: "SwitchHousingCompany",
  components: {},
  methods: {
    async onComponentLoad() {
      try {
        await switchHousingCompany(null);
      } catch(error) {
        console.log(error);
      } finally {
        this.$router.push("/");
      }
    }
  },
  mounted() {
    this.onComponentLoad();
  }
};
</script>
