<template>
  <div
    class="site-header__announcements"
    v-closable="{
      exclude: ['site-header__announcements__toggle'],
      handler: 'handleOutside'
    }"
  >
    <button class="site-header__announcements__toggle" @click="onToggle">
      <i class="far fa-bell"></i>
      <span
        class="announcements-count"
        v-if="getNotificationCount && !isDelayed"
      ></span>
    </button>

    <transition name="slidedown">
      <div
        class="notification-prompt bg--grey site-header--fixed--top-by-height-with-top-margin"
        v-if="getIsOpen"
      >
        <LoaderIcon v-if="loading"></LoaderIcon>
        <NotificationGroup
          :title="$t('notificationpage.common.nextdeadlines')"
          :items="getAllDeadlines"
          @on-item-click="$emit('on-item-click')"
          v-if="$App.getFeature('calendar')"
        />
        <NotificationGroup v-if="false" title="New messages" items />
        <NotificationGroup
          :title="$t('notificationpage.common.latestactivities')"
          :items="getAppNotifications"
          @on-item-click="$emit('on-item-click')"
        />
      </div>
    </transition>
    <div
      v-if="getIsOpen"
      @click="$emit('close')"
      class="site-overlay site-header--fixed--top-by-height"
    ></div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters, mapMutations } from "vuex";
import NotificationGroup from "./Group";
import Vue from "vue";
import { isToday } from "@/utils/DateHelper";
import {getQueryVariable} from "@/utils/StringUtils";
import NotificationService from "@/services/NotificationService";
import _ from "lodash";

export default {
  name: "NotificationPrompt",
  components: {
    NotificationGroup
  },
  props: ["open"],
  data() {
    return {
      announcementsPromptState: false,
      isDelayed: true,
      delayLength: 1000,
      announcementsCount: 0,
      loading: false,
      submitting: false,
      api: new NotificationService()
    };
  },
  computed: {
    ...mapGetters([
      "getAnnouncements",
      "getLatestCalendarDeadlines",
      "getUserNotifications",
      "getFutureMaterialSelectionDeadlines",
      "isTaskMarkedDone"
    ]),
    getAppNotifications() {
      let notifications = this.getUserNotifications;

      return notifications
        .map(notification => {
          return {
            id: notification._id,
            title: notification.title,
            headline: this.$t(
              "common.contenttype." + notification.type.toLowerCase()
            ),
            href: notification.targetUrl,
            isRead: notification.isRead
            //date: notification.createdAt
          };
        })
        .slice(0, 5);

      return [];
    },
    getAllDeadlines() {
      let deadlines = [];
      deadlines = deadlines.concat(this.convertCalendarDeadlinesToNotificationItems);

      if(this.$App.getFeature('material-selection'))
        deadlines = deadlines.concat(this.convertMaterialSelectionDeadlinesToNotificationItems);

      
      deadlines = _.sortBy(deadlines, ['date_value'], ['desc']);

      return deadlines.slice(0,2);
    },
    convertMaterialSelectionDeadlinesToNotificationItems() {
      return this.getFutureMaterialSelectionDeadlines.map((item, key) => {

        let daysLeft;

         if (isToday(new Date(item.deadline)))
          daysLeft = this.$t("common.text.today");
        else
          daysLeft =
            this.$filters.date_daysleft(item.deadline) +
            " " +
            this.$t("task.deadline.common.daysleft");

        return {
          id: 'material-selection-'+key,
          title: this.$t("common.materialSelectionDeadline"),
          headline: daysLeft,
          date_value: item.deadline,
          url: {
            path: "/material-selection"
          }
          //date: deadline.elements.publish_date.value
        };
      });
    },
    convertCalendarDeadlinesToNotificationItems() {
      if (this.getLatestCalendarDeadlines) {
        return this.getLatestCalendarDeadlines
          .filter(item => !this.isTaskMarkedDone(item.system.id))
          .map(item => {
            let daysLeft = "";
            if (isToday(item.elements.start_time.value))
              daysLeft = this.$t("common.text.today");
            else
              daysLeft =
                this.$filters.date_daysleft(item.elements.start_time.value) +
                " " +
                this.$t("task.deadline.common.daysleft");

            return {
              id: item.system.id,
              title: item.elements.title.value,
              headline: daysLeft,
              date_value: item.elements.start_time.value,
              url: {
                name: "/calendar/entry/:id",
                params: {
                  id: item.system.codename
                    ? item.system.codename
                    : item.system.id
                }
              }
              //date: deadline.elements.publish_date.value
            };
          });
      }
      return [];
    },
    getIsOpen() {
      return this.open;
    },
    getNotificationCount() {
      let calendarDeadlines = this.getLatestCalendarDeadlines;

      let notifications = this.getAppNotifications.filter(
        notification => !notification.isRead
      );

      return notifications.length;
    }
  },
  methods: {
    ...mapActions([
      "fetchAnnouncements",
      "fetchLatestCalendarEntries",
      "fetchUserNotifications",
      "fetchMaterialSelectionDeadlines"
    ]),
    ...mapMutations(['setUserNotifications']),
    onToggle() {

      if(!this.open && !this.submitting) {
        this.submitting = true;
        if(this.getUserNotifications && this.getUserNotifications.length) {

          let ids = [];
          this.getUserNotifications.map(n => {
            if(n.isRead === false || n.isRead == 'false') {
              ids.push(n._id);
            }
          });
          
          if(ids.length) {

            this.setUserNotifications(this.getUserNotifications.map(n => {
              n.isRead = true;
              return n;
            }));

            this.api.markNotificationsRead({
              ids
            }).then(res => {
              this.submitting = false;
            }).catch(err => console.log(err));
          }
        }
      }
      
      this.$emit('toggle');
    },
    close() {
      this.open = false;
    },
    toggleOpen() {
      console.log("toggling..", this.open);
      this.open = !this.open;
    },
    handleOutside() {
      this.$emit("close");
    }
  },
  async mounted() {
    this.loading = true;

    //mark notification read if we came to site with notification link
    let notificationId = getQueryVariable('notification_id');
    if(notificationId) {
      try {
        await this.api.markNotificationRead(notificationId);
      } catch(error) {
        console.log(error);
      }
    }

    /*try {
      this.fetchAnnouncements();
    } catch (error) {
      console.log(error);
    }*/

    if (!this.getLatestCalendarDeadlines.length) {
      try {
        await this.fetchLatestCalendarEntries();
      } catch (error) {
        console.log(error);
      }
    }

    try {
      this.fetchUserNotifications();
    } catch (error) {
      console.log(error);
    }

    if(this.$App.getFeature('material-selection')) {
      try {
        await this.fetchMaterialSelectionDeadlines();
      } catch (error) {
        console.log(error);
      }
    }

    this.loading = false;

    console.log("notification_id: ", getQueryVariable('notification_id'));

    setTimeout(() => {
      this.isDelayed = false;
    }, this.delayLength);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
.announcements-count {
  width: 0.8rem;
  height: 0.8rem;
  padding: 1px;
  border-radius: 50%;
  background-color: $red;
  color: #fff;
  display: block;
  position: absolute;
  font-size: 0.6rem;
  top: 0.5rem;
  right: 0.1rem;
  /*border: 1px solid #fff;*/
  animation: scaleUp 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);

  @media screen and (min-width: $breakpoint-md) {
    width: 0.7rem;
    height: 0.7rem;
    top: 0.8rem;
    right: 0.25rem;
  }
}
.notification-prompt {
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 300px;
  z-index: 10;
  color: $baseFontColor;
  &.is-visible {
    box-shadow: 0px;
  }
  &.is-visible {
    display: block;
  }

  &:before {
    content: "";
    top: -8px;
    left: auto;
    right: 1rem;
    z-index: 0;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1rem 1rem;
    border-color: transparent transparent $greyBlueMidDark transparent;
    transition: all 0.2s ease-in-out 0.5s;
  }

  @media screen and (min-width: $breakpoint-lg) {
    max-width: 500px;
    right: -1rem;
    left: auto;
  }
}
</style>
