<template>
  <strong v-if="name">
    <router-link
      target="_blank"
      :to="{ path: '/admin/user/' + getUuid }"
      v-if="retrieveProjectId && getUuid && $can('manage', { _type: 'users', projectId: retrieveProjectId })"
    >
      {{ name }}
    </router-link>
    <span v-else>
      {{ name }}
    </span>
  </strong>
</template>

<script>
import store from '../../../src/store';

export default {
  name: "ProfileName",
  props: {
    name: String,
    userUuid: String,
    message: Object
  },
  computed: {
    retrieveProjectId() {
      return store.getters.getSelectedHousingCompany.hcExt;
    },
    getUuid() {
      if (this.message) 
        if (this.message.creatorUuid)  
          return this.message.creatorUuid
        else if (this.message.userUuid)
          return this.message.userUuid
      
      return this.userUuid
    }
  }
}
</script>

<style>

</style>