<template>
  <div class="campaign">
    <Card
      :title="campaign.title"
      :url="{ name: '/service-shop/advertisement/:id', params: { id: campaign.codename } }"
      :thumbnailSrc="campaign.imageURL"
      :headline="campaign.partnerName"
      :ctaButtonText="$t('campaigns.common.moreinfo.button')"
    >
      <template v-slot:header>
        <div class="campaign-partner" v-if="campaign.partnerName">
          {{ campaign.partnerName }}
        </div>
      </template>
      <template v-slot:footerBottom>
        <div class="campaign-duration">
          {{ $t("campaigns.is-valid-between") }}
          {{ $filters.date_format_numbers(campaign.start) }} -
          {{ $filters.date_format_numbers(campaign.end) }}
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/common/Lists/Card";

export default {
  name: "LiferayCampaign",
  props: ["campaign"],
  components: {
    Card
  }
};
</script>
<style lang="scss" scoped>
.campaign {
  height: 100%;
  padding-bottom: 2rem;
}
</style>
