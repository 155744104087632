<template>
    <ATPList :items="getATPLatest" :loading="loading" />
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import ATPList from "./List";

export default {
    name: 'ATPLatestItems',
    components: {
        ATPList
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters(['getATPLatest'])
    },
    methods: {
        ...mapActions(['fetchATPLatest'])
    },
    async mounted() {
        try {
            await this.fetchATPLatest();
        } catch(err){
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
</script>

<style>

</style>