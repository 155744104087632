<template>
  <div class="event-list row">
    <div class="loader-wrapper text-center" v-if="isLoading">
      <LoaderIcon />
    </div>
    <div class="col-md-6" v-for="item in getterMethod" :key="item.id">
      <EventListItem
        :title="item.title"
        :url="item.url"
        :color="item.color"
        :startTime="item.startTime"
        :endTime="item.endTime"
        :icon="item.icon"
      />
    </div>
    <div class="col-md-12 padding--no-v" v-if="noItemsText && !isLoading && getterMethod.length === 0">
      <div class="no-results padding--no-v">
        <i class="fal fa-calendar-alt"></i>
        {{noItemsText}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventListItem from "@/components/common/Lists/Event";
import LoaderIcon from "@/components/common/LoaderIcon";
import {isPastDate} from "@/utils/DateHelper"

export default {
  name: "CalendarList",
  props: {
    type: {},
    limit: {},
    title: {},
    items: {
      type: Array
    },
    noItemsText: {
      type: String
    }
  },
  data() {
    return {
      loading: {
        events: false,
        deadlines: false,
        bookingsConfirmed: false,
        bookingsWaiting: false,
        commonSpaceBookings: false
      }
    };
  },
  components: {
    EventListItem,
    LoaderIcon
  },
  computed: {
    ...mapGetters([
      "getLatestCalendarEntries",
      "getLatestCalendarEvents",
      "getLatestCalendarDeadlines",
      "getLatestCalendarEvents",
      "getFutureMaterialSelectionDeadlines",
      "getPendingBookingsWaitingClientConfirmation",
      "getOne4AllBookings",
      "isTaskMarkedDone"
    ]),
    isLoading() {
      return this.loading.events || this.loading.deadlines || this.loading.bookingsConfirmed || this.loading.bookingsWaiting;
    },
    getterMethod() {
      let merged = [];

      //Get items from property instead fetching from db
      if (this.items && this.items.length) {
        merged = this.items.map(item => {
          if (item.system && item.system.id)
            return this.normalizeData(item, "event");
          else if(item.serviceTitle) return this.normalizeData(item, "booking");
          else if(item.bundles && typeof item.deadline !== 'undefined') return this.normalizeData(item, "material_selection");
          else console.log("Could not map calendar item in calendar list: ", item);
        });
      } else {
        if (!this.isLoading) {
          if (
            this.getLatestCalendarEntries &&
            Array.isArray(this.getLatestCalendarEntries)
          ) {
            this.getLatestCalendarEntries.forEach(item => {
              if(!this.isTaskMarkedDone(item.system.id))
              merged.push(this.normalizeData(item, "event"));
            });
          }
          if (this.$App.getFeature("material-selection")) {
            if (this.getFutureMaterialSelectionDeadlines) {
              this.getFutureMaterialSelectionDeadlines.forEach(item => {
                merged.push(this.normalizeData(item, "material_selection"));
              });
            }
          }
          if (this.$App.getFeature("one4all-bookings")) {
            if(this.getOne4AllBookings.length) {
              merged = merged.concat( this.getOne4AllBookings.map(item => this.normalizeData(item, "one4all_booking")) );
            }
          }
        }
      }

      if(merged.length > this.limit)
        this.$emit('on-items-to-display');

      return this.sortItemsByDate(merged)
        .filter(i => !i.endTime || !isPastDate(i.endTime))
        .slice(
          0,
          this.limit ? this.limit : 4
        );
    }
  },
  methods: {
    ...mapActions(["fetchLatestCalendarEntries", "fetchMaterialSelectionDeadlines", "fetchOne4AllBookings"]),
    isDeadline(item) {
      let ret = false;
      if (item.elements.type && item.elements.type.value.length) {
        item.elements.type.value.map(type => {
          if (type.codename == "deadline") ret = true;
        });
      }

      return ret;
    },
    sortItemsByDate(items) {
      items.sort((a, b) => {
        return new Date(a.startTime) - new Date(b.startTime);
      });
      return items;
    },
    normalizeData(object, type) {

      if(!object)
        return;

      switch (type) {
        case "event":
          return {
            title: object.elements.title.value,
            color: this.isDeadline(object) ? "orange" : "blue-medium",
            startTime: object.elements.start_time
              ? object.elements.start_time.value
              : false,
            endTime: object.elements.end_time && !this.isDeadline(object)
              ? object.elements.end_time.value
              : false,
            id: object.system.id,
            url: {
              name: "/calendar/entry/:id",
              params: { id: object.system.codename },
              query: { showLinkToTimeline: true }
            }
          };
          break;
        case "booking":
          const color = object.waiting_client_confirmation ? "blue-medium" : "green";
          const bookingType = object.waiting_client_confirmation ? "waiting_client_confirmation" : "confirmed";
          const icon = object.waiting_client_confirmation ? "fal fa-user-clock" : false;
          return {
            title: object.serviceTitle,
            color: color,
            startTime: object.startDate,
            endTime: false,
            id: object.booking_ID,
            icon,
            url: {
              name: "/service-shop/my-bookings/:id/:type?",
              params: {
                type: bookingType,
                id: object.booking_ID
              },
              query: { showLinkToTimeline: true }
            }
          };
          break;
        case "material_selection":
          return {
            title: object.event ? object.event.name : this.$t("common.materialSelectionDeadline"),
            color: "red",
            startTime: object.date,
            endTime: false,
            id: object.event ? object.event.id : object.date,
            url: {
              name: "/material-selection/deadline/:id",
              params: {id: object.event ? object.event.id : null}
            }
          };
          break;

        case "one4all_booking":
          return {
            title: object.calendarName,
            color: "green",
            startTime: object.begins,
            endTime: object.ends,
            id: object.uuid,
            url: {
              name: "/booking/:id",
              params: {id: object.uuid}
            }
          };
          break;
      }
    }
  },
  async mounted() {
    if (!this.items) {
      this.loading.events = true;

      this.fetchLatestCalendarEntries().then(res => {
        this.loading.events = false;
      }).catch(err => {
        console.log();
        this.loading.events = false;
      });

      if (this.$App.getFeature('material-selection') && !this.getFutureMaterialSelectionDeadlines.length) {
        //this.loading = true;
          try {
            this.loading.deadlines = true;
            await this.fetchMaterialSelectionDeadlines();
          } catch (err) {
            console.log(err);
          } finally {
            this.loading.deadlines = false;
          }
      }

      if (this.$App.getFeature("one4all-bookings")) {
        try {
            this.loading.commonSpaceBookings = true;
            await this.fetchOne4AllBookings({past: false});
          } catch (err) {
            console.log(err);
          } finally {
            this.loading.commonSpaceBookings = false;
          }
      }

    }
  }
};
</script>
<style lang="scss" scoped>
.loader-wrapper {
  margin: auto;
}
</style>
