import store from "@/store/index";
import settings from "@/globals";
import { getFeature } from "@/features";
import _ from "lodash";

const getDefaultState = () => {
  return {
    isAdminArea: false,
    currentNavigationItem: null,
    living: {
      items: [
        {
          titleLanguageKey: "navigation.homepage",
          href: "/",
          iconClass: "fal fa-home",
          exact: true,
          orderNumber: 0
        },
        {
          titleLanguageKey: "navigation.myapartment",
          href: "/my-apartment",
          iconClass: "fal fa-info-square",
          orderNumber: 10
        }
      ]
    },
    construction: {
      items: [
        {
          titleLanguageKey: "navigation.homepage",
          href: "/",
          iconClass: "fal fa-home",
          exact: true,
          orderNumber: 0
        },
        {
          titleLanguageKey: "navigation.myapartment",
          href: "/my-apartment",
          iconClass: "fal fa-info-square",
          orderNumber: 15
        },
      ]
    },
    admin: {
      items: [
        {
          titleLanguageKey: "navigation.admin.dashboard",
          href: "/admin",
          iconClass: "fal fa-browser",
          exact: true,
          orderNumber: 0
        }
      ]
    }
  };
};

const defaultState = getDefaultState();
const state = Object.assign({}, defaultState);

const actions = {
  addNavigationLink({ commit }, item) {
    let state = store.getters.getCurrentHcState
      ? store.getters.getCurrentHcState
      : "construction";
    if (state == "construction") commit("appendConstructionNavigation", item);
    else commit("appendLivingNavigation", item);
  }
};

const getters = {
  getIsAdminSection: state => state.isAdminArea,
  getMainNavigationItems: state => {
    let hcStatus = store.getters.getCurrentHcState;

    if (hcStatus !== "construction" && hcStatus !== "living") {
      hcStatus = "construction";
    }

    /*if (hcStatus == "construction" && getFeature("material-selection")) {
      state[hcStatus].items = state[hcStatus].items.map(navItem => {
        if (navItem.titleLanguageKey == "navigation.material-selection")
          navItem.hide = false;
        return navItem;
      });
    } else {
      state[hcStatus].items = state[hcStatus].items.map(navItem => {
        if (navItem.titleLanguageKey == "navigation.material-selection")
          navItem.hide = true;
        return navItem;
      });
    }*/

    if (settings.debugMode)
      console.log("App is currently on " + hcStatus + " mode.");

    if (!getters.getCurrentNavigationItem) return state[hcStatus].items;

    //check active main navigation section
    let items = state[hcStatus].items.map(item => {
      if (getters.getCurrentNavigationItem.href == item.href)
        item.active = true;
      else item.active = false;

      return item;
    });

    if (getFeature('documentsv2')) {
      items = items.filter(i => i.href != "/documents");
    }

    return _.orderBy(items, ["orderNumber"]);
  },
  getCurrentNavigationItem: state => state.currentNavigationItem,
  getCurrentNavigationItemSubpages: state => {
    if (
      state.currentNavigationItem &&
      Array.isArray(state.currentNavigationItem.items) &&
      state.currentNavigationItem.items.length
    )
      return state.currentNavigationItem.items.filter(item => !item.hide);
    return [];
  },
  getMainNavigationItemByUrl: state => href => {
    let hcStatus = store.getters.getCurrentHcState;
    if (hcStatus !== "construction" && hcStatus !== "living") {
      hcStatus = "construction";
    }
    return state[hcStatus].items.find(i => {
      if (i.href === href)
        return true;
      return false;
    })
  }
};

const mutations = {
  setCurrentNavigationItem: (state, res) => (state.currentNavigationItem = res),
  setIsAdminArea: (state, res) =>
    (state.isAdminArea = res === true ? true : false),
  resetMainNavigation: state => {
    Object.assign(state, getDefaultState());
  },
  appendLivingNavigation: (state, item) => {
    let items = state.living.items.slice(0);
    items = items.concat(item);
    state.living.items = [...items];
  },
  appendConstructionNavigation: (state, item) => {
    state.construction.items = state.construction.items.concat(item);
  }
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
