<template>
  <div class="insufficient-privileges-partial">
    <BackButton />
    <PageSection class="text-center">
      <div class="insufficient-privileges bg--white padding--large text-center">
        <div class="insufficient-privileges__icon">
          <i class="far fa-lock"></i>
        </div>
        <h2>{{$t('common.insufficient-privileges.title')}}</h2>
        <p>{{$t('common.insufficient-privileges.title.description')}}</p>
      </div>
    </PageSection>
  </div>
</template>

<script>
export default {
  name: 'InsufficientPrivilegesPartial'
}
</script>

<style lang="scss" scoped>
.insufficient-privileges {
    &__icon {
        font-size: 3rem;
        margin: 1rem 0;
    }
}
</style>