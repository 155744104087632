<template>
  <div class="news-list" v-if="getterMethod && getterMethod.length">
    <div class="news-list--latest row" v-if="mode=='latest' || mode=='statusUpdates'">
      <div class="col-md-6" v-for="item in getterMethod" :key="item.system.id">
        <ArticleListItem
          :title="getReformString(item.elements.title.value, 80)"
          :url="{name: '/news/:id', params:{id: item.system.codename}, query: {showLinkToTimeline: true}}"
          :thumbnailSrc="item.elements.images && item.elements.images.value.length ? item.elements.images.value[0].url : ''"
          thumbnailSize="medium"
          :ctaButtonText="ctaButtonText"
          :sticky="item.elements.sticky_news && item.elements.sticky_news.value.length ? true : false"
          :date="item.elements.publish_date.value"
          :useKenticoThumbnailer="true"
          :yit="isYitNewsPosting(item)"
          :priorityItem="isPriorityNewsPosting(item)"
          mode="box"
        />
      </div>
    </div>
    <div class="news-list--archive" v-else>
      <div class="news-list__item" v-for="item in getterMethod" :key="item.system.id">
        <ArticleListItem
          :title="getReformString(item.elements.title.value, 80)"
          :url="{name: '/news/:id', params:{id: item.system.codename}, query: {showLinkToTimeline: true}}"
          :thumbnailSrc="item.elements.images && item.elements.images.value.length ? item.elements.images.value[0].url : ''"
          thumbnailSize="large"
          :ctaButtonText="ctaButtonText"
          :sticky="item.elements.sticky_news && item.elements.sticky_news.value.length ? true : false"
          :date="item.elements.publish_date.value"
          :useKenticoThumbnailer="true"
          :description="item.elements.content.value.substring(0,300)"
          :showArrow="false"
          :yit="isYitNewsPosting(item)"
          mode="box"
          :priorityItem="isPriorityNewsPosting(item)"
        >
          <template v-slot:footer>
            <button class="button button--link">{{$t('common.readmore.link')}}</button>
          </template>
        </ArticleListItem>
      </div>
    </div>
  </div>
  <div class v-else>
    <span class="no-results">{{$t('common.no-items-found')}}</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ArticleListItem from "@/components/common/Lists/Article";
import {isYitNewsPosting, isPriorityNewsPosting} from "@/utils/KenticoUtil";
import {getReformString} from "@/utils/StringUtils";


export default {
  name: "NewsList",
  props: ["mode", "limit", "thumbSize", "showCtaButton", "ctaButtonText"],
  components: {
    ArticleListItem
  },
  computed: {
    ...mapGetters(["getNews", "getLatestNews", "getLatestStatusUpdates", "getWelcomeArticle", "getCurrentHcState"]),
    getterMethod() {
      let articles = [];
      if (!this.mode) return this.getNews;

      if (this.mode == "latest") {
        articles = this.getLatestNews.slice(0, this.limit ? this.limit : 4);
      } else if (this.mode == "statusUpdates") {
        articles = this.getLatestStatusUpdates.slice(
          0,
          this.limit ? this.limit : 4
        );
      } else articles = this.getNews.slice(0, this.limit ? this.limit : 4);
      

      //append welcome article 
      if(this.getWelcomeArticle && this.getCurrentHcState == 'construction') {
        articles = articles.concat(this.getWelcomeArticle);
        articles = articles.sort((a, b) => {
          return new Date(b.elements.publish_date.value) - new Date(a.elements.publish_date.value);
        });
        articles = articles.slice(0,this.limit);
      }

      return articles;
    }
  },
  data() {
    return {
      thumbnailWidth: 250,
      thumbnailHeight: 250
    };
  },
  methods: {
    ...mapActions(["fetchNews", "fetchLatestNews", "fetchLatestStatusUpdates", "fetchWelcomeArticle"]),
    isYitNewsPosting,
    isPriorityNewsPosting,
    getReformString
  },
  mounted() {
    if (!this.mode) return this.fetchNews();

    if (this.mode == "latest") this.fetchLatestNews();
    else if (this.mode == "archive")
      this.fetchNews({
        limit: this.limit ? this.limit : 100
      });
    else if (this.mode == "statusUpdates") this.fetchLatestStatusUpdates();
    else this.fetchNews();

    //get this only in construction phase
    if(!this.getWelcomeArticle && this.getCurrentHcState == 'construction')
      this.fetchWelcomeArticle();
  }
};
</script>