import { AzureDocumentServiceV2 } from "@/services/AzureDocumentService";
import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import KenticoService from "@/services/KenticoService";
import {normalizeBuildingTaxonomySync} from "@/utils/KenticoFiltersForHc";
import { stripTrailingSlash } from "@/utils/StringUtils";
import store from "@/store";
import { i18n } from "@/plugins/i18n";
import { ability } from "@/config/ability";
import { reject } from "lodash";

const api = new AzureDocumentServiceV2();
const kenticoVideoListApi = new KenticoService();
kenticoVideoListApi.setContentType(["video_list"]);
kenticoVideoListApi.targetByDefaultBuildingTaxonomy = false;

const kenticoVideoApi = new KenticoService();
kenticoVideoApi.setContentType(["video"]);
kenticoVideoApi.targetByDefaultBuildingTaxonomy = false;


const getDefaultState = () => {
  return {
    documents: [],
    allDocuments: [],
    folders: [],
    adminPageParams: {
      hcExtId: null,
      aptExtId: null
    },
    introductionVideos: [],
    introductionVideosList: null,
    videos: [],
    currentFolder: null
  };
};

const state = getDefaultState();

const getters = {
  getDocumentsAdmin: state => state.documents,
  getAllDocumentsAdmin: state => state.allDocuments,
  getFoldersAdmin: state => state.folders,
  getDocumentsByPath: state => {
    return (path, params = null) => {
      let pathToTest = String(path).toString();
      if(typeof params === 'object') {
        if(params.HCID)
          pathToTest = pathToTest.replace("{HCID}", params.HCID)
        if(params.APTID)
          pathToTest = pathToTest.replace("{APTID}", params.APTID)
      }
      let ret = state.documents.filter(folder => {
        if (folder.path === pathToTest) {
          return true;
        } else return false;
      });

      if (ret.length) return ret[0];

      return null;
    };
  },
  getAdminSelectedHc: state => state.adminPageParams.hcExtId,
  getAdminSelectedApt: state => state.adminPageParams.aptExtId,
  retrieveFolderByPath: state => {
    return path => {
      let ret = state.documents.filter(
        folder => stripTrailingSlash(folder.path) === stripTrailingSlash(path)
      );
      if (ret && ret.length) {
        return ret[0];
      }

      return;
    };
  },
  getIntroductionVideosAdmin: state => state.introductionVideos,
  getIntroductionVideosList: state => state.introductionVideosList,
  getAllVideos: state => state.videos,
  getAvailableFolders: state => (aptExtId) => {
    //return this.getAzureAllDocuments.files;
    if (state.documents.length) {
        
        let folders = state.documents.slice(0).filter(folder => {
          //check privileges
          if(!ability.can('read', {_type: "document", folder: folder.name}))
            return false;
          //filter out apartment folders
          folder.nameTranslated = i18n.global.t("documents.folders." + folder.name);
          if (
            (aptExtId && folder.path.includes("/apartments/") && folder.name != 'apt_all') ||
            (!aptExtId && (!folder.path.includes("/apartments/") || folder.name == 'apt_all'))
          ) {
            return true;
          } else {
            return false;
          }
        });
        return folders;
      } else {
        return null;
      }
  },
  getCurrentFolder: state => state.currentFolder
};

const actions = {
  fetchDocumentsAdminV1({ commit }, params) {
    return api
      .fetchDocuments(params)
      .then(response => {
        //Map response files into grouped folders
        /*response.data.files.map(folder => {
          groupedDocuments = groupedDocuments.map(group => {
            if (group.keys.includes(folder.key))
              group.files = sortItemsByDate(group.files.concat(folder.files));

            return group;
          });
        });*/

        commit("setDocumentsAdmin", response.data.files);

        if (response.data.allFiles.length)
          response.data.allFiles = sortItemsByDate(response.data.allFiles);

        commit("setAllDocumentsAdmin", response.data.allFiles);
      })
      .catch(err => console.log("Fetching documents failed: ", err));
  },
  async fetchDocumentsAdminV2({ commit }, params) {
    try {
      let response = await api.fetchDocuments(params);
      commit("setDocumentsAdmin", response.data.files);
      //commit("setAllDocumentsAdmin", sortItemsByDate(response.data.files));
      commit("setAllDocumentsAdmin", response.data.files);
    } catch (err) {
      throw err;
    }
  },
  async fetchFoldersAdmin({ commit }, params) {
    try {
      let response = await api.getFolders(params);
      commit("setFoldersAdmin", response.data);
    } catch (err) {
      throw err;
    }
  },
  /**
   *
   * @param {*} param0
   * @param {*} uploadRequest
   */
  async uploadDocument({ commit }, uploadRequest) {
    try {
      let result = await api.addDocument(uploadRequest);
      if (result.data && result.data.file) {
        if (IS_DOCUMENTS_V2())
          commit("appendUploadedDocumentV2", result.data.file);
        else
          commit("appendUploadedDocumentV1", result.data.file);
        
        return result;
      }
      return result;
    } catch (error) {
      throw error;
    }
  },
  deleteDocument({ commit }, file) {
    return new Promise((resolve, reject) => {
      api
        .deleteDocument(file._id)
        .then(res => {
          if (IS_DOCUMENTS_V2())
            commit("removeDocumentV2", file);
          else
            commit("removeDocumentV1", file);
          
          resolve(res.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  async updateDocument({ commit }, payload) {
    try {
      let res = await api.updateMetaData(payload.id, payload.data);
      
      if (IS_DOCUMENTS_V2()) {
        commit("updateDocumentV2", res.data.file);
      } else {
        if (res.data && res.data.file)
        commit("updateDocumentV1", res.data.file);
      }
      
      return res.data;
    } catch(err) {
      console.log(err);
      throw err;
    }
  },
  fetchIntroductionVideosAdmin({commit}, hcExtId) {
    let params = {
      order: "none",
      limit: 1
    };

    let kenticoTerm = store.getters.getBuildingTaxonomyByExternalId(hcExtId);
    if (Array.isArray(kenticoTerm) && kenticoTerm.length) {
      params.buildingTaxonomies = kenticoTerm[0].codename;

      return kenticoVideoListApi.fetchItems(params).then(response => {
        let items = [];
        response.data.items.map(item => {
          if (item.elements && item.elements.videos && item.elements.videos.value) {
            item.elements.videos.value = item.elements.videos.value.map(videoCode => {
              if (response.data.modular_content && response.data.modular_content.hasOwnProperty(videoCode))
                items.push(response.data.modular_content[videoCode]);
              return videoCode;
            });
          }
          let listTaxonomyCodenames = item.elements.buildingtaxonomy.value.map(b => b.codename);
          if (listTaxonomyCodenames.includes(kenticoTerm[0].codename))
            commit("setIntroductionVideosListAdmin", item);
          return item;
        });

        commit("setIntroductionVideosAdmin", items);

      }).catch(error => {
        console.log(error);
        commit("setNews", []);
      });
    } else {
      throw "Kentico taxonomy term for this project could not be determined.";
    }
  },
  fetchVideos({commit}, params = {}) {
    if(!params) {
      params = {};
    }

    if(!params.order)
      params.order = "title";
    if(!params.page)
      params.page = 1;

    return new Promise((resolve, reject) => {
      kenticoVideoApi.fetchItems(params).then(response => {
        
        if(params.append)
          commit("appendAllVideos", response.data.items);
        else
          commit("setAllVideos", response.data.items);

        resolve(response.data.items);
      }).catch(error => {
        console.log(error);
        commit("setNews", []);
        reject(error);
      });
    });
  },
  async addVideo({ commit }, payload) {
    try {
      const fileResponse = await api.addVideo(payload);
      if (Array.isArray(fileResponse.data))
        fileResponse.data.map(file => commit("appendUploadedDocumentV2", file));
      else
        commit("appendUploadedDocumentV2", fileResponse.data);
    } catch(err) {
      throw err;
    }
  },
  async deleteVideo({commit}, id) {
    try {
      if (IS_DOCUMENTS_V2()) {
        const file = await api.deleteVideo(id);
        commit("removeDocumentV2", file.data);
      } else {
        await kenticoVideoApi.deleteItem(id);
        commit("deleteFromVideos", id)
      }
    } catch(err) {
      throw err;
    }
  },
  async fetchCurrentFolder({ commit }, folderId) {
    try {
      let res = await api.getFolder(folderId);
      commit('setCurrentFolder', res.data);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async createFolder({ commit }, payload) {
    try {
      let res = await api.createFolder(payload);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async updateCurrentFolder({ commit }, payload) {
    try {
      let res = await api.updateFolder(payload.folderId, payload.data);
      commit('setCurrentFolder', res.data);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async deleteCurrentFolder({ commit }, folderId) {
    try {
      let res = await api.deleteFolder(folderId);
      commit('setCurrentFolder', null);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async deleteDocumentsByUploadBatchId({ commit }, batchId){
    try {
      let response = await api.deleteDocumentsByBatchId(batchId);
      commit("removeDocuments", response.data.files);
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  setDocumentsAdmin: (state, res) => (state.documents = res),
  setAllDocumentsAdmin: (state, res) => (state.allDocuments = res),
  truncateApartmentFiles: (state, res) => {
    state.documents = state.documents.filter(d => {
      return !d.aptExtId ? true : false;
    })
    state.allDocuments = state.allDocuments.filter(d => {
      return !d.aptExtId ? true : false;
    })
  },
  removeDocumentV1: (state, res) => {
    state.allDocuments = state.allDocuments.filter(
      file => file.name !== res.name
    );
    state.documents = state.documents.map(folder => {
      folder.files = folder.files.filter(file => file.name !== res.name);
      return folder;
    });
  },
  removeDocuments: (state, files = []) => {
    if (!Array.isArray(files))
      return;
    files.map(doc => {
      if (doc.folderId) {
        state.allDocuments = state.allDocuments.filter(
          file => file._id !== doc._id
        );
        state.documents = [...state.allDocuments];
      } else {
        state.allDocuments = state.allDocuments.filter(
          file => file._id !== doc._id
        );
        state.documents = state.documents.map(folder => {
          folder.files = folder.files.filter(file => file._id !== doc._id);
          return folder;
        });
      }
    });
  },
  removeDocumentV2: (state, res) => {
    state.allDocuments = state.allDocuments.filter(
      file => file._id !== res._id
    );
    state.documents = [...state.allDocuments];
  },
  appendUploadedDocumentV1: (state, res) => {
    state.allDocuments.push(res);
    state.documents = state.documents.map(folder => {
      if (res.folderName === folder.path) {
        folder.files.push(res);
      }
      return folder;
    });
  },
  appendUploadedDocumentV2: (state, res) => {
    let fileFound = state.allDocuments.find(f => f._id === res._id);
    if(!fileFound)
      state.allDocuments.push(res);
    
    fileFound = state.documents.find(f => f._id === res._id);
    if(!fileFound)
      state.documents.push(res);
  },
  setAdminPageParams: (state, res) => (state.adminPageParams = res),
  setIntroductionVideosAdmin: (state, res) => (state.introductionVideos = res),
  setIntroductionVideosListAdmin: (state, res) => (state.introductionVideosList = res),
  appendIntroductionVideosAdmin: (state, res) => {
    if(state.introductionVideos) {
      state.introductionVideos = state.introductionVideos.concat(res);
    }
  },
  deleteFromIntroductionVideosAdmin: (state, targetCodename) => {
    if(state.introductionVideos && state.introductionVideos.length) {
      state.introductionVideos = state.introductionVideos.filter(item => item.system.codename !== targetCodename);
    }
  },
  setAllVideos: (state, res) => (state.videos = res),
  appendAllVideos: (state, res) => (state.videos = state.videos.concat(res)),
  deleteFromVideos: (state, id) => {
    state.videos = state.videos.filter(video => id !== video.system.id);
  },
  updateDocumentV1: (state, res) => {
    if (state.allDocuments) {
      state.allDocuments = state.allDocuments.map(file => {
        if (file._id === res._id) {
          file.folder = res.folder;
        }
        
        return file;
      });
    }

    if (state.documents) {

      let fileToMove = null;

      state.documents = state.documents.map(folder => {
        folder.files = folder.files.filter(file => {
          if (file._id === res._id) {
            file.folder = res.folder;
            fileToMove = file;
            return false;
          }
          return true;
        });
        //folder.files = folder.files.filter(file => file._id !== res._id);

        return folder;
      })

      //if folder was changed, move the file into correct place
      if (fileToMove) {
        state.documents = state.documents.map(folder => {
          if (folder.name === fileToMove.folder)
            folder.files.push(fileToMove);
            
          return folder;
        });
      }
    }
  },
  updateDocumentV2: (state, res) => {
    ['documents', 'allDocuments'].map(key => {
      if (state[key]) {
        state[key] = state[key].map(file => {
          if (file._id === res._id) {
            file.folder = res.folder;
            file.folderId = res.folderId;
          }
          
          return file;
        });
      }
    });
  },
  setFoldersAdmin: (state, res) => {
    let currentUser = store.getters.getCurrentUser;

    //filter out hided folders
    if (!currentUser.hasRole('admin')) {
      res = res.filter(d => d.visible);
    }
    state.folders = res;
  },
  setCurrentFolder: (state, res) => (state.currentFolder = res)
};

const sortItemsByDate = function(items) {
  return items.sort((a, b) => {
    return new Date(b.created) - new Date(a.created);
  });
};

const IS_DOCUMENTS_V2 = () => {
  let currentAdminProject = store.getters.getCurrentAdminProject;
  if (currentAdminProject)
    return currentAdminProject && currentAdminProject.documentsSettings && currentAdminProject.documentsSettings.scope ? true : false;
  
  return false;
}

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
