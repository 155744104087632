//const KenticoCloud = require("kentico-cloud-delivery");
import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import GbuilderService from "@/services/GbuilderService";
import dayjs from "dayjs";
//import NewsService from "@/services/NewsService";
import {isPastDate} from "@/utils/DateHelper"
import store from "@/store";
import { getFeature } from "@/features.js";

const api = new KenticoDeliveryApiService();
api.targetByDefaultBuildingTaxonomy = true; //target default by building taxonomy

const gbuilderApi = new GbuilderService();

const getDefaultState = () => {
  return {
    materialSelections: [],
    selectionSchedule: [],
    scheduleInfo: [],
    selectionFaqs: [],
    selectionContacts: [],
    deadlines: null,
    bundles: null
  };
};

const state = getDefaultState();

const getters = {
  getMaterialSelections: state => state.materialSelections,
  getSelectionSchedule: state => state.selectionSchedule,
  getSelectionFaqs: state => state.selectionFaqs,
  getSelectionContacts: state => state.selectionContacts,
  getScheduleInfo: state => state.scheduleInfo,
  getMaterialSelectionDeadlines: state => state.deadlines,
  getMaterialSelectionDeadlineBundles: state => state.bundles,
  getFutureMaterialSelectionDeadlines: state => {
    if(!state.deadlines)
      return [];

    if(!Array.isArray(state.deadlines))
      return [];

    return state.deadlines.filter(d => {
      if (d && d.date && !isPastDate(d.date)) {
        return true;
      }
    });
  },
  getMaterialSelectionDeadlineById: state => id => {
    if (!state.deadlines || !state.deadlines.length)
      return null;
    
    console.log(id, state.deadlines);
    return state.deadlines.find(d => d && d.event && d.event.id === parseInt(id));
  }
};

const actions = {
  /**
   *
   * @param {*} param
   * @param {*} mode normal|admin
   */
  fetchMaterialSelectionArticle({ commit }, params = {}) {
    params.contentType = "material_selection";
    params.order = "order";

    console.log("FETCHING MATERIAL SELECTION: ", params);
    return new Promise(function(resolve, reject) {
      api
        .fetchList(params)
        .then(response => {
          console.log(
            "Fetched material selection article: ",
            response.data,
            params
          );
          if (response.data.items.length > 0) {
            const materialSelections = [];
            const selectionSchedule = [];
            const selectionFaqs = [];
            //const selectionContacts = [];

            // Get material selections block order and push it to array
            const material_selection_blocks =
              response.data.items[0].elements.material_selection.value;
            material_selection_blocks.forEach(block => {
              materialSelections.push(response.data.modular_content[block]);
            });

            // Get selection schedule block order and push it to array
            const schedule_blocks =
              response.data.items[0].elements.selection_schedule.value;
            schedule_blocks.forEach(block => {
              selectionSchedule.push(
                migrateKenticoScheduleToStudioDeadline(
                  response.data.modular_content[block]
                )
              );
            });

            // Get selection FAQ's block order and push it to array
            const faq_blocks =
              response.data.items[0].elements.frequently_asked_questions.value;
            faq_blocks.forEach(block => {
              selectionFaqs.push(response.data.modular_content[block]);
            });

            // Get selection contact block order and push it to array
            //const contact_blocks = response.data.items[0].elements.contacts.value;
            //contact_blocks.forEach(block => {
            //  selectionContacts.push(response.data.modular_content[block]);
            //});

            commit("setMaterialSelections", materialSelections);
            commit("setSelectionSchedule", selectionSchedule);
            commit("setSelectionFaqs", selectionFaqs);
            //  commit("setSelectionContacts", selectionContacts);
            let info = '';
            let deadline;
            let deadlineName;
            let studioButton;
            let eventStaticText = '';

            if (
              response.data &&
              response.data.items[0].elements.selection_schedule_info_content && 
              Array.isArray(response.data.items[0].elements.selection_schedule_info_content.value) && 
              response.data.items[0].elements.selection_schedule_info_content.value.length
            ) {
              if (response.data.modular_content[response.data.items[0].elements.selection_schedule_info_content.value[0]] != 'undefined') {
                let contentBlockItem = response.data.modular_content[response.data.items[0].elements.selection_schedule_info_content.value[0]];
                if (contentBlockItem && contentBlockItem.elements && contentBlockItem.elements.content) {
                  info = contentBlockItem.elements.content.value;
                }
              }
            } else if (
              response.data &&
              response.data.items[0].elements.selection_schedule_info
            ) {
              info =
                response.data.items[0].elements.selection_schedule_info.value;
            }

            if (
              response.data &&
              response.data.items[0].elements.event_static_content &&
              Array.isArray(response.data.items[0].elements.event_static_content.value) && 
              response.data.items[0].elements.event_static_content.value.length
            ) {
                if (response.data.modular_content[response.data.items[0].elements.event_static_content.value[0]] != 'undefined') {
                let contentBlockItemStatic = response.data.modular_content[response.data.items[0].elements.event_static_content.value[0]];
                if (contentBlockItemStatic && contentBlockItemStatic.elements && contentBlockItemStatic.elements.content) {
                  eventStaticText = contentBlockItemStatic.elements.content.value;
                }
              }
            }
            else if (
              response.data &&
              response.data.items[0].elements.event_static_text
            ) {
              eventStaticText =
                response.data.items[0].elements.event_static_text.value;
            }

            if (
              response.data &&
              response.data.items[0].elements.next_deadline
            ) {
              deadline = response.data.items[0].elements.next_deadline.value;
            }

            if (
              response.data &&
              response.data.items[0].elements.deadline_name
            ) {
              deadlineName =
                response.data.items[0].elements.deadline_name.value;
            }

            deadline = {
              date: deadline,
              name: deadlineName
            };

            if (
              response.data &&
              response.data.items[0].elements.material_selection_button_url
            ) {
              studioButton = {
                url:
                  response.data.items[0].elements.material_selection_button_url,
                text:
                  response.data.items[0].elements.material_selection_button_text
              };
            }

            const scheduleInfo = {
              info,
              deadline,
              studioButton,
              eventStaticText
            };
            commit("setScheduleInfo", scheduleInfo);
            resolve(response.data);
          } else {
            resolve();
          }
        })
        .catch(err => {
          console.error("Fetching material selection failed: ", err);
          reject(err);
        });
    });
  },
  fetchMaterialSelectionContacts({ commit }, params = {}) {
    params.contentType = "contact";
    params.order = "order";
    params.customStringQuery =
      "elements.contact_taxonomy[any]=contact_about_material_selections_and_material_changes,contact_about_changes_to_house_stuctures_and_layout";

    console.log("FETCHING MATERIAL SELECTION: ", params);
    return new Promise(function(resolve, reject) {
      api
        .fetchList(params)
        .then(response => {
          console.log(
            "Fetched material selection article: ",
            response.data,
            params
          );
          commit("setSelectionContacts", response.data.data);
          resolve(response.data.data);
        })
        .catch(err => {
          console.error("Fetching material selection failed: ", err);
          reject(err);
        });
    });
  },
  fetchMaterialSelectionDeadlineBundles({ commit }) {
    return new Promise((resolve, reject) => {

      if(store.getters.getCurrentHcState != 'construction') {
        commit("setMaterialSelectionDeadlineBundles", null);
        resolve(null);
        return null;
      }

      let materialSelectionFeature = getFeature('material-selection');
      if (!materialSelectionFeature) {
        resolve(null);
        return;
      } else {
        if (materialSelectionFeature.settings && materialSelectionFeature.settings.disableStudioIntegration === true) {
          resolve(null);
          return;
        }
      }

      gbuilderApi
        .fetchMaterialDeadlineBundles()
        .then(res => {
          console.log(res.data.data);
          if (res.data.data && !res.data.error) {
            commit("setMaterialSelectionDeadlineBundles", res.data.data);
          }
          resolve(res.data.data);
        })
        .catch(err => {
          console.log(err);
          reject(err)
        });
    });
  },
  fetchMaterialSelectionDeadlines({ commit }) {
    return new Promise((resolve, reject) => {

      if(store.getters.getCurrentHcState != 'construction') {
        commit("setMaterialSelectionDeadlines", null);
        resolve(null);
        return null;
      }

      let materialSelectionFeature = getFeature('material-selection');
      if (!materialSelectionFeature) {
        resolve(null);
        return;
      } else {
        if (materialSelectionFeature.settings && materialSelectionFeature.settings.disableStudioIntegration === true) {
          resolve(null);
          return;
        }
      }

      gbuilderApi
        .fetchMaterialDeadlines()
        .then(res => {
          console.log(res.data.data);
          if (res.data.data && res.data.data.deadlines && !res.data.error) {
            commit("setMaterialSelectionDeadlines", res.data.data.deadlines.filter(d => d.event));
          }
          resolve(res.data.data.deadlines);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const mutations = {
  setMaterialSelections: (state, res) => (state.materialSelections = res),
  setSelectionSchedule: (state, res) => (state.selectionSchedule = res),
  setSelectionFaqs: (state, res) => (state.selectionFaqs = res),
  setSelectionContacts: (state, res) => (state.selectionContacts = res),
  setScheduleInfo: (state, res) => (state.scheduleInfo = res),
  setMaterialSelectionDeadlineBundles: (state, res) => (state.bundles = res),
  setMaterialSelectionDeadlines: (state, res) => (state.deadlines = res)
};

const migrateKenticoScheduleToStudioDeadline = kenticoItem => {
  if (!kenticoItem.elements || !kenticoItem.system) return;

  let studioItem = {
    id: null,
    date: false,
    bundles: [],
    name: "",
    description: "",
    imageUrl: null,
    attachments: []
  };
  if (kenticoItem.system.id) studioItem.id = kenticoItem.system.id;
  if (kenticoItem.elements.end_time)
    studioItem.date = kenticoItem.elements.end_time.value;
  if (kenticoItem.elements.title)
    studioItem.name = kenticoItem.elements.title.value;
  if (kenticoItem.elements.content)
    studioItem.description = kenticoItem.elements.content.value;
  if (kenticoItem.elements.images && Array.isArray(kenticoItem.elements.images.value) && kenticoItem.elements.images.value.length)
    studioItem.imageUrl = kenticoItem.elements.images.value[0].url;
  if (kenticoItem.elements.attachments && Array.isArray(kenticoItem.elements.attachments.value) && kenticoItem.elements.attachments.value.length)
    studioItem.attachments = kenticoItem.elements.attachments.value;

  return studioItem;
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
