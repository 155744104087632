export default [
  {
    path: "/admin/email-letters",
    name: "/admin/email-letters",
    meta: {
      sectionTitle: "navigation.email-letters",
      title: "navigation.email-letters",
      layout: 'admin-default',
      admin: true
    },
    component: () =>
    import(
      /* webpackChunkName: "email-letter" */ "@/views/Admin/EmailLetter/Main.vue"
    )
  },
  {
    path: "/admin/email-letters/:projectId/messages",
    name: "/admin/email-letters/:projectId/messages",
    meta: {
      sectionTitle: "navigation.email-letters",
      title: "navigation.email-letters",
      layout: 'admin-default',
      admin: true
    },
    component: () =>
    import(
      /* webpackChunkName: "email-letter" */ "@/views/Admin/EmailLetter/List.vue"
    ),
  },
  {
    path: "/admin/email-letters/:projectId/message/new",
    name: "/admin/email-letters/:projectId/message/new",
    meta: {
      sectionTitle: "navigation.email-letters",
      title: "navigation.email-letters",
      layout: 'admin-default',
      admin: true
    },
    component: () =>
    import(
      /* webpackChunkName: "email-letter" */ "@/views/Admin/EmailLetter/Edit.vue"
    ),
  },
  {
    path: "/admin/email-letters/:projectId/message/:id/logs",
    name: "/admin/email-letters/:projectId/message/:id/logs",
    meta: {
      sectionTitle: "navigation.email-letters",
      title: "navigation.email-letters",
      layout: 'admin-default',
      admin: true
    },
    component: () =>
    import(
      /* webpackChunkName: "email-letter" */ "@/views/Admin/EmailLetter/Logs.vue"
    ),
  },
  {
    path: "/admin/email-letters/:projectId/message/:id",
    name: "/admin/email-letters/:projectId/message/:id",
    meta: {
      sectionTitle: "navigation.email-letters",
      title: "navigation.email-letters",
      layout: 'admin-default',
      admin: true
    },
    component: () =>
    import(
      /* webpackChunkName: "email-letter" */ "@/views/Admin/EmailLetter/Edit.vue"
    ),
  },
  {
    path: "/admin/email-letters/preview/:id",
    name: "/admin/email-letters/preview/:id",
    meta: {
      sectionTitle: "navigation.email-letters",
      title: "navigation.email-letters",
      layout: 'admin-default',
      admin: true
    },
    component: () =>
    import(
      /* webpackChunkName: "email-letter" */ "@/views/Admin/EmailLetter/Preview.vue"
    ),
  }
]