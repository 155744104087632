import CommentService from "@/services/CommentService";
import CommunityService from "@/services/CommunityService";

const api = new CommentService();
const communityApi = new CommunityService();

const getDefaultState = () => {
  return {
    comments: [],
    likes: [],
    notificationSubscriptions: []
  };
};

const state = getDefaultState();

const getters = {
  getComments: state => state.comments,
  getCommentsById: state => refId => {
    return state.comments.filter(item => item.refId == refId);
  },
  getAllNewsArticleLikes: state => state.likes,
  getNewsArticleLikes: state => refId => {
    let obj = state.likes.find(article => {
      if(article.refId === refId) {
        return true;
      }
      return false;
    });
    return obj ? obj.likes : [];
  },
  getNewsArticleCommentCount: state => refId => {
    let obj = state.likes.find(article => {
      if(article.refId === refId) {
        return true;
      }
      return false;
    });
    
    return obj && parseInt(obj.count) > 0 ? obj.count : 0;
  },
  isLikesFetchedForArticle: state => refId => {
    return state.likes.find(article => article.refId === refId) ? true : false
  },
  getHasSubscribedToArticleComments: state => refId => {
    let obj = state.notificationSubscriptions.find(article => {
      if(article.refId === refId && article.subscribedNotifications) {
        return true;
      }
      return false;
    });
    return obj ? true : false;
  }
};

const actions = {
  fetchComments({ commit }, refId) {
    return new Promise((resolve, reject) => {
      api
        .fetchComments({ refId })
        .then(response => {
          if (response.data) {
            if (response.data.comments)
              commit("setComments", response.data.comments);
            if (Array.isArray(response.data.likes) && response.data.likes.length)
              commit("setNewsArticleLikes", {
                refId,
                likes: response.data.likes
              });
            if (response.data.subscribedNotifications)
              commit("setNewsArticleNotificationSubscriptions", {
                refId,
                subscribedNotifications: response.data.subscribedNotifications
              });
          }
          
          resolve(response.data);
        })
        .catch(err => {
          console.log("Fetching COMMENTS FOR NEWS failed: ", err);
          reject(err);
        });
    });
  },
  updateComment({ commit }, postData) {
    return new Promise((resolve, reject) => {
      api
        .updateComment(postData)
        .then(res => {
          if (res && res.data && res.data.item) {
            let comments = state.comments.map(comment => {
              if (comment._id === res.data.item._id) {
                comment.body = postData.body;
              }
              return comment;
            });

            commit("setComments", comments);
          }
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  deleteComment({ commit }, id) {
    return api
      .deleteComment(id)
      .then(res => {
        if (res.data) {
            const matchId = (it) => it._id === res.data.item._id
            const comments = state.comments?.map(parentComment => matchId(parentComment)
              ? { ...parentComment, ...res.data.item }
              : { 
                  ...parentComment, 
                  comments: parentComment?.comments?.map(subComment => matchId(subComment)  
                    ? { ...subComment, ...res.data.item } 
                    : parentComment)
                }
            )
          commit("setComments", comments);
        }
      })
      .catch(err => console.log(err));
  },
  async likeComment({commit}, id) {
    try {
      let res = await communityApi.processLike(id, 'comment');
      if(res && res.data) {
        commit('appendCommentLikes', res.data);
      }

      return res;
    } catch(error) {
      console.log(error);
      throw error;
    }
  },
  async likeNewsArticle({commit}, id) {
    try {
      let res = await communityApi.processLike(id, 'article');
      if(res && res.data) {
        commit('appendNewsArticleLikes', res.data);
      }

      return res;
    } catch(error) {
      console.log(error);
      throw error;
    }
  },
  fetchNewsItemCommentCount({commit}, id) {
    return new Promise((resolve, reject) => {
      api.fetchCommentsCount(id).then(res => {
        if(res.data && Array.isArray(res.data.likes) && res.data.likes.length) {
          commit('appendNewsArticleLikes', {refId: id, likes: res.data.likes, count: res.data.count});
        }
        resolve(res.data);
      }).catch(err => {
        console.log(err);
        reject(err);
      }) 
    });
  },
  async fetchSubComments({ commit }, params = {}) {
    try {
      let res = await api.fetchComments(params);
      commit("setSubComments", res.data);

      return res.data;
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  setComments: (state, res) => {
    state.comments = res;
  },
  setNewsArticleLikes: (state, res) => {
    let found = false;
    state.likes = state.likes.map(c => {
      if(res.refId === c.refId) {
        c.likes = res.likes;
        found = true;
      }
      return c;
    });
    if(!found)
      state.likes.push(res);
  },
  setNewsArticleNotificationSubscriptions: (state, res) => {
    let found = false;
    state.notificationSubscriptions = state.notificationSubscriptions.map(c => {
      if(res.refId === c.refId) {
        c.subscribedNotifications = res.subscribedNotifications;
        found = true;
      }
      return c;
    });
    if(!found)
      state.notificationSubscriptions.push({
        refId: res.refId,
        subscribedNotifications: res.subscribedNotifications
      });
  },
  appendNewsArticleLikes: (state, res) => {
    let found = false;
    state.likes = state.likes.map(article => {
      if(article.refId === res.refId) {
        found = true;
        article.likes = res.likes;
        if(res.count)
          article.count = res.count;
      }
      return article;
    });

    if(!found)
      state.likes.push(res);
  },
  appendCommentLikes: (state, res) => {
    state.comments = state.comments.map(c => {
      if(c._id === res.id) {
        c.likes = res.likes;
      }
      return c;
    });
  },
  setSubComments: (state, res) => {
    if (Array.isArray(res.comments) && res.comments.length) {

      let firstComment = res.comments[0];

      if (firstComment && firstComment.parentComment) {
        state.comments = state.comments.map(comment => {
          if (comment._id === firstComment.parentComment) {
            if (!Array.isArray(comment.comments))
              comment.comments = [];
            comment.comments = res.comments;
            comment.childMessageCount = res.comments.length;  //update count
          }
          return comment;
        });
      }

    }
  }
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
