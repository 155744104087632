<template>
  <Button type="button" class="reply-to-comment-button" @click="$emit('click', $event)" v-if="$can('create', 'message')">
    <slot>
        <i class="far fa-reply"></i>
        <span class="d-none d-md-inline">{{$t('comments.add.comment')}}</span>
    </slot>
  </Button>
</template>

<script>
export default {
  name: "SubCommentButton",
  emits: ['click']
}
</script>

<style lang="scss" scoped>
.reply-to-comment-button {
  border: 0px;
  margin-right: 1.8rem;
  &:focus {
    border: 0px;
  }
}
</style>