<template>
  <!-- If we have url and we don't show cta button, wrap the item with link -->
  <router-link
    :to="url"
    v-if="url && !ctaButtonText && !isExternalLink"
    class="content-pick content-pick--border-v bg--chateau-grey-light"
    :class="{'content-pick--hover-with-bg': thumbnailSize !== 'large', 'content-pick--box': mode == 'box', 'margin--no-v': mode == 'list', 'content-pick--direction-vertical': fieldDirection == 'vertical', 'text-center': center}"
  >
    <i class="fas fa-exclamation-circle content-pick__priority-item" v-if="priorityItem" :aria-label="$t('common.news.isPriorityItem')"></i>

    <div
      class="content-pick__thumbnail content-pick__thumbnail--bg"
      :class="thumbnailSize ? thumbnailSize : 'medium'"
      v-if="getThumbnailUrl"
      :style="{backgroundImage: 'url('+getThumbnailUrl+')'}"
    ></div>
    <div class="content-pick__thumbnail content-pick__thumbnail--placeholder" :class="thumbnailSize ? thumbnailSize : 'medium'" v-else ></div>

    <div class="content-pick__content">
      <div class="content-pick__meta">
        <slot name="header">
          <span class="content-pick__date" v-if="date">{{$filters.date_format_ordinal(date, $i18n.locale)}}</span>
          <span class="content-pick__headline" v-if="headline">{{headline}}</span>
          <span class="content-pick__sticky" v-if="sticky">
            <i class="fal fa-thumbtack"></i>
          </span>
          <span class="content-pick__yit" v-if="yit"><YitIcon /></span>
        </slot>
      </div>
      <div class="content-pick__title text--break-long-words">
        {{title}}
      </div>
      <div class="content-pick__description">
        <slot>{{ description }}</slot>
      </div>
      <div class="content-pick__footer">
        <slot name="footer">
          <div class="content-pick__cta">
            <button class="button button--link" v-if="ctaButtonText">{{ctaButtonText}}</button>
          </div>
        </slot>
      </div>
    </div>
    <div class="content-pick__arrow-link" v-if="showArrow">
      <i class="far fa-chevron-right"></i>
    </div>
  </router-link>
   <!-- Same as above, but with external link -->
  <a
    :href="url"
    target="_blank"
    v-else-if="url && !ctaButtonText && isExternalLink"
    class="content-pick content-pick--border-v bg--chateau-grey-light"
    :class="{'content-pick--hover-with-bg': thumbnailSize !== 'large', 'content-pick--box': mode == 'box', 'margin--no-v': mode == 'list', 'content-pick--direction-vertical': fieldDirection == 'vertical', 'text-center': center}"
  >
    <i class="fas fa-exclamation-circle content-pick__priority-item" v-if="priorityItem" :aria-label="$t('common.news.isPriorityItem')"></i>

    <div
      class="content-pick__thumbnail content-pick__thumbnail--bg"
      :class="thumbnailSize ? thumbnailSize : 'medium'"
      v-if="getThumbnailUrl"
      :style="{backgroundImage: 'url('+getThumbnailUrl+')'}"
    ></div>
    <div class="content-pick__thumbnail content-pick__thumbnail--placeholder" :class="thumbnailSize ? thumbnailSize : 'medium'" v-else ></div>

    <div class="content-pick__content">
      <div class="content-pick__meta">
        <slot name="header">
          <span class="content-pick__date" v-if="date">{{$filters.date_format_ordinal(date, $i18n.locale)}}</span>
          <span class="content-pick__headline" v-if="headline">{{headline}}</span>
          <span class="content-pick__sticky" v-if="sticky">
            <i class="fal fa-thumbtack"></i>
          </span>
          <!--<span class="content-pick__yit"><YitIcon /></span>-->
          <span class="content-pick__external-link"><i class="fal fa-external-link"></i></span>
        </slot>
      </div>
      <div class="content-pick__title text--break-long-words">
        {{title}}
      </div>
      <div class="content-pick__description">
        <slot>{{ description }}</slot>
      </div>
      <div class="content-pick__footer">
        <slot name="footer">
          <div class="content-pick__cta">
            <button class="button button--link" v-if="ctaButtonText">{{ctaButtonText}}</button>
          </div>
        </slot>
      </div>
    </div>
    <div class="content-pick__arrow-link" v-if="showArrow">
      <i class="far fa-chevron-right"></i>
    </div>
  </a>
  <!-- with CTA button and has external link-->
  <article
    v-else-if="ctaButtonText && isExternalLink"
    class="content-pick content-pick--border-v bg--chateau-grey-light"
    :class="{'content-pick--hover-with-bg': thumbnailSize !== 'large', 'content-pick--box': mode == 'box', 'content-pick--direction-vertical': fieldDirection == 'vertical', 'text-center': center}"
  >
    <a :href="url" v-if="ctaButtonText" class="d-flex" target="_blank">
      <i class="fas fa-exclamation-circle content-pick__priority-item" v-if="priorityItem" :aria-label="$t('common.news.isPriorityItem')"></i>

      <div
        class="content-pick__thumbnail content-pick__thumbnail--bg"
        :class="thumbnailSize ? thumbnailSize : 'medium'"
        v-if="getThumbnailUrl"
        :style="{backgroundImage: 'url('+getThumbnailUrl+')'}"
      ></div>
      <div class="content-pick__thumbnail content-pick__thumbnail--placeholder" :class="thumbnailSize ? thumbnailSize : 'medium'" v-else ></div>
    </a>

    <div class="content-pick__content">
      <div class="content-pick__meta">
        <slot name="header">
          <span class="content-pick__date" v-if="date">{{$filters.date_format(date, $i18n.locale)}}</span>
          <span class="content-pick__headline" v-if="headline">{{headline}}</span>
          <span class="content-pick__sticky" v-if="sticky">
            <i class="fal fa-thumbtack"></i>
          </span>
           <!--<span class="content-pick__yit"><YitIcon /></span>-->
           <span class="content-pick__external-link"><i class="fal fa-external-link"></i></span>
        </slot>
      </div>
      <div class="content-pick__title text--break-long-words">{{title}}</div>
      <div class="content-pick__description">
        <slot>{{ description }}</slot>
      </div>
      <div class="content-pick__footer">
        <slot name="footer">
          <div class="content-pick__cta">
            <a
              :href="url"
              target="_blank"
              class="button button--link"
              v-if="ctaButtonText"
            >{{ctaButtonText}}</a>
          </div>
        </slot>
      </div>
    </div>
  </article>
  <!-- with CTA button -->
  <article
    v-else
    class="content-pick content-pick--border-v bg--chateau-grey-light"
    :class="{'content-pick--hover-with-bg': thumbnailSize !== 'large', 'content-pick--box': mode == 'box', 'content-pick--direction-vertical': fieldDirection == 'vertical', 'text-center': center}"
  >
    <router-link :to="url" v-if="ctaButtonText" class="d-flex">
      <i class="fas fa-exclamation-circle content-pick__priority-item" v-if="priorityItem" :aria-label="$t('common.news.isPriorityItem')"></i>

      <div
        class="content-pick__thumbnail content-pick__thumbnail--bg"
        :class="thumbnailSize ? thumbnailSize : 'medium'"
        v-if="getThumbnailUrl"
        :style="{backgroundImage: 'url('+getThumbnailUrl+')'}"
      ></div>
      <div class="content-pick__thumbnail content-pick__thumbnail--placeholder" :class="thumbnailSize ? thumbnailSize : 'medium'" v-else ></div>
    </router-link>

    <div class="content-pick__content">
      <div class="content-pick__meta">
        <slot name="header">
          <span class="content-pick__date" v-if="date">{{$filters.date_format(date, $i18n.locale)}}</span>
          <span class="content-pick__headline" v-if="headline">{{headline}}</span>
          <span class="content-pick__sticky" v-if="sticky">
            <i class="fal fa-thumbtack"></i>
          </span>
           <span class="content-pick__yit" v-if="yit"><YitIcon /></span>
        </slot>
      </div>
      <div class="content-pick__title text--break-long-words">{{title}}</div>
      <div class="content-pick__description">
        <slot>{{ description }}</slot>
      </div>
      <div class="content-pick__footer">
        <slot name="footer">
          <div class="content-pick__cta">
            <router-link
              :to="url"
              class="button button--link"
              v-if="ctaButtonText"
            >{{ctaButtonText}}</router-link>
          </div>
        </slot>
      </div>
    </div>
  </article>
</template>

<script>
import YitIcon from "@/components/common/YitIcon";

export default {
  name: "ArticleListItem",
  components: {
    YitIcon
  },
  props: {
    title: {
      type: String
    },
    headline: {
      type: String
    },
    date: {
    },
    sticky: {
      type: Boolean
    },
    description: {
      type: String
    },
    footer: {
      type: String
    },
    thumbnailSrc: {
      type: String
    },
    thumbnailSize: {
      type: String
    },
    url: {},
    urlTarget: {
      type: String
    },
    ctaButtonText: {
      type: String
    },
    useKenticoThumbnailer: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    fieldDirection: {
      type: String,
      default: "horizontal"
    },
    center: {
      type: Boolean,
      default: false
    },
    yit: {
      type: Boolean,
      default: false
    },
    priorityItem: {
      type: Boolean,
      default: false
    },
    isExternalLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getThumbnailUrl() {
      if (!this.thumbnailSrc) return "";
      let url = this.thumbnailSrc;
      if (this.useKenticoThumbnailer)
        url +
          `?w=${this.thumbnailWidth}&h=${this.thumbnailHeight}&fit=crop&q=${this.imageQuality}`;
      return url;
    }
  },
  data() {
    return {
      thumbnailWidth: 350,
      thumbnailHeight: 350,
      imageQuality: 70
    };
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/variables";
.content-pick {
  background-color: #fff;
  @media screen and (min-width: $breakpoint-md) {
    &__arrow-link {
      margin-right: 1rem !important;
    }
  }
}
</style>