export default [
  {
    path: "/news/archive",
    name: "/news/archive",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/News/Archive.vue"),
    meta: {
      sectionTitle: "navigation.news",
      title: "navigation.news"
    }
  },
  {
    path: "/news/:id",
    name: "/news/:id",
    meta: {
      sectionTitle: "navigation.news",
      title: "navigation.news",
      skipBuildPageMetaInRouter: true
    },
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/News/View.vue")
  },
  {
    path: "/news/",
    name: "/news/",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/News/ListView.vue"),
    meta: {
      sectionTitle: "navigation.news",
      title: "navigation.news",
      displayHeadSection: true,
      disableScrollTop: true
    }
  },
  {
    path: "/admin/news",
    name: "/admin/news",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/News/List.vue"),
    meta: {
      admin: true,
      sectionTitle: "News admin",
      layout: "admin-default"
    }
  },
  {
    path: "/admin/news/preview/:id",
    name: "/admin/news/preview/:id",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/Admin/News/Preview.vue"
      ),
    meta: {
      admin: true
    }
  },
  {
    path: "/admin/news/edit/:id",
    name: "/admin/news/edit/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/News/Edit.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/news/created/:id",
    name: "/admin/news/created/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/News/AfterCreate.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/news/new",
    name: "/admin/news/new",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/News/Edit.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  }
]