import store from "@/store";

const navigationLinkFeature = {
    id: "navigationlink",
    name: "feature.navigationlink.name",
    iconClass: "fal fa-bars",
    globalSettings: {},
    schema: {
        links: {
            type: Array,
            children: {
                title: {
                    type: String,
                    label: "feature.navigationlink.field.title",
                    default: "",
                    required: true
                },
                href: {
                    type: String,
                    label: "feature.navigationlink.field.href",
                    default: "",
                    required: true
                },
                language: {
                    type: String,
                    input: {
                        type: "language"
                    },
                    label: "feature.navigationlink.field.language",
                    default: ""
                },
                target: {
                    type: String,
                    input: {
                        type: "select"
                    },
                    options: {
                        "_self": 'link.target.self',
                        "_blank": 'link.target.blank'
                    },
                    label: "feature.navigationlink.field.target",
                    default: "_self",
                    required: false
                },
                icon: {
                    type: String,
                    label: "feature.navigationlink.field.icon",
                    default: "",
                    required: false
                }
            }
        }
    },
    initialize: (feature, settings) => {
        if (settings.links && settings.links.length) {
            const currentUser = store.getters.getCurrentUser;
            const currentUserLanguage = currentUser ? String(currentUser.locale).split("_")[0] : 'en';
            settings.links.map(link => {
                if (!link.language || (link.language && link.language === currentUserLanguage)) {
                    store.dispatch("addNavigationLink", {
                        titleLanguageKey: link.title,
                        href: link.href,
                        target: link.target,
                        iconClass: link.icon
                    });
                }
            });
        }
    }
};

export { navigationLinkFeature };