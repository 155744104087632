<template>
<div class="attach-images" :class="{'attach-images--active': isLiked}">
  <LoaderIcon v-if="loading"></LoaderIcon>
  <label for="attach-images" class="attach-images__button" v-else>
    <input
        type="file"
        id="attach-images"
        multiple
        @change="onAddImages"
        tabindex="-1"
      />
      <div class="sr-only">{{$t('community.attach-images.button.aria-label')}}</div>
      <i class="far fa-images"></i>
  </label>
</div>
</template>

<script>
export default {
  name: "AttachImages",
  data() {
    return {
      isLiked: false,
      errorMessage: ""
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    
  },
  methods: {
    onAddImages(e) {
      this.$emit("on-images-selected", e);
    },
    onImageRemove(value) {
      this.$emit('on-image-remove', value);
    }
  },
  mounted() {
    this.isLiked = this.liked;
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/abstracts/_variables.scss";
  .attach-images {
    display: flex;
    align-items: center;

    label {
      display: flex;
    }

    &__button {
      padding: .8rem;
      cursor: pointer;
      i {
        font-size: 1.3rem;
      }
      input[type="file"] {
        position: absolute;
        height: 1px;
        width: 1px;
        &:focus {
          outline: 0;
          border: 0;
        }
      }
    }
  }
</style>