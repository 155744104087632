export default [
  {
    path: "/book-common-spaces",
    name: "/book-common-spaces",
    component: () =>
      import(
        /* webpackChunkName: "common-spaces" */ "@/views/Apartment/BookCommonSpaces.vue"
      ),
    meta: {
      sectionTitle: "navigation.book-common-spaces",
      title: "navigation.book-common-spaces",
      introduction: "navigation.book-common-spaces.introduction",
      displayHeadSection: true
    }
  },
  {
    path: "/booking/:id",
    name: "/booking/:id",
    component: () =>
      import(
        /* webpackChunkName: "common-spaces" */ "@/views/Bookings/Single.vue"
      ),
    meta: {
      sectionTitle: "navigation.book-common-spaces",
      title: "navigation.book-common-spaces",
      meta: {
        layout: "subpage"
      }
    }
  }
];