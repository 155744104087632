<template>
  <div class="select-signature" v-if="getUserSignatures.length">
      <FormInput type="checkbox" class="input-group--small select-signature__toggle" name="select-signature" v-model="active" :label="$t('user.signature.select')" @change="onSelectSignature(getUserSignatures[0]._id)" v-if="getUserSignatures.length == 1" />
      <FormInput type="checkbox" class="input-group--small select-signature__toggle" name="select-signature" v-model="active" :label="$t('user.signature.select')" v-else />
      <div v-if="active && getUserSignatures.length > 1" class="select-signature__signatures">
          <FormInput type="select" @change="onSelectSignature" value="true" v-model="selectedSignatureId">
              <option value="" :selected="true">{{$t('common.choose')}}</option>
              <option v-for="signature in getUserSignatures" :key="signature._id" :value="signature._id" :selected="selectedSignature === signature._id">{{signature.name}}</option>
          </FormInput>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormInput from "@/components/common/Form/Input";

export default {
    name: "SignatureSelect",
    components: {FormInput},
    props: {
        targetMessage: {type: String, default: ""},
        html: {type: Boolean, default: false }
    },
    data() {
        return {
            loading: false,
            active: false,
            selectedSignature: null,
            selectedSignatureId: ""
        }
    },
    computed: {
        ...mapGetters(["getUserSignatures"]),
        getSignaturePrefix() {
            if(this.html)
                return "<br />";
            else
                return "\n\n";
        }
    },
    methods: {
        ...mapActions(['fetchUserSignatures']),
        prepareSignature(content) {
            if(this.html)
                return content.replace(/(?:\r\n|\r|\n)/g, '<br>');
            else
                return content;
        },
        onSelectSignature(signatureId) {
            let signature = this.getUserSignatures.find(s => s._id == signatureId);
            
            let oldSignature = this.selectedSignature;
            this.selectedSignature = signature ? signature : null;

            console.log("onSelectSignature: ", signatureId, oldSignature, this.active);

            if(this.selectedSignature) {
                console.log("selected");
                let str = this.targetMessage;
                if(oldSignature)
                    str = str.replace(this.getSignaturePrefix + this.prepareSignature(oldSignature.content), "");

                if(this.active)
                    this.$emit('on-select', str + this.getSignaturePrefix + this.prepareSignature(signature.content));
                else
                    this.$emit('on-select', str);
            } else if(oldSignature) {
                console.log("selected");
                this.$emit('on-select', this.targetMessage.replace(this.getSignaturePrefix + this.prepareSignature(oldSignature.content), ""));
            } else {
                console.log("else");
                this.$emit('on-select', this.targetMessage);
            }
        }
    },
    async mounted() {

        if(!this.$can('manage', 'user-signatures'))
            return;

        if(!this.getUserSignatures.length) {
            try {
                this.loading = true;
                await this.fetchUserSignatures();
            } catch(err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";
.select-signature {
    max-width: 300px;
    text-align: left;
    padding-bottom: 1rem;

    .input-group {
        margin-top: .3rem;
        font-size: .9rem;
    }

    @media screen and (min-width: $breakpoint-md) {
        display: flex;
        align-items: center;
        min-height: 52px;

        .input-group {
            margin: 0;
        }

        &__toggle {
            white-space: nowrap;
            padding-right: 1rem;
        }
    }
}
</style>