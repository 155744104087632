import store from "@/store/index";

export default class BaseService {
  constructor() {
    this.baseUrl = "/delegate/yithome-api";
  }

  /**
   * Housing Company id lives in User state
   */
  getHcId() {
    let hc = store.state.User.selectedHousingCompany;
    
    if(!hc)
      return null;

    if(hc.liferayId && this.routeViaLiferay())
      return hc.liferayId;

    return hc.id;
  }

  /**
   * Get active language code
   * @return String lang code
   */
  getLanguage() {
    const lang = store.getters.getLang;
    return lang ? lang : 'en';
  }

  /**
   * Build url from baseUrl and params object
   *
   * @param {*} baseUrl
   * @param {*} params
   * @param {Boolean} hasQueryString - Uri already has some query parameters defined
   */
  _buildUrl(baseUrl, params = {}, hasQueryString = false) {
    let first = true;
    let query = baseUrl;
    Object.keys(params).map(param => {
      if (
        typeof params[param] !== "undefined" ||
        typeof params[param] !== "null"
      )
        if (first && !hasQueryString) {
          query += `?${param}=${params[param]}`;
          first = false;
        } else query += `&${param}=${params[param]}`;
    });

    return query;
  }

  /**
   * Check if we user Liferay
   * 
   */
  routeViaLiferay() {
    let routeViaLiferay = store.getters.getTokenIssuer != 'b2c' ? true : false;
    return routeViaLiferay;
  }
}
