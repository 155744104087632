import _ from "lodash";
import UserService from "@/services/UserService";
import B2CUserService from "@/services/B2CUserService";
import PrimaService from "@/services/PrimaService";
import FeaturesService from "@/services/FeaturesService";
import NotificationService from "@/services/NotificationService";
import { checkBetaHc } from "@/utils/checkBetaHcs";
import User from "@/models/User";
import store from "@/store";
import settings from "@/globals";
import { i18n } from "@/plugins/i18n";
import { getBuildingTaxonomyFiltersForKentico } from "@/utils/KenticoFiltersForHc";
import { getDefaultQuickLinksForUser, buildModuleData } from "@/config/quick-link-modules";

const api = new UserService();
const primaApi = new PrimaService();
const featureApi = new FeaturesService();
const b2cApi = new B2CUserService();
const notificationsApi = new NotificationService();

//hcExt id list of demo housing companies for YIT staff
const yitDemoHcs = ["1-YITHCOMP"];

const getDefaultState = () => {
  return {
    isLogged: false,
    userDetails: {},
    currentUser: new User(),
    logoutError: false,
    statusError: false,
    selectedHousingCompany: {},
    selectedApartment: {},
    apartmentLayoutImages: [],
    housingCompanyImages: [],
    features: {},
    temporaryZipCodeForDemoUser: "",
    applicationError: false,
    userNotifications: [],
    areaHousingCompanies: [],
    accessTokenRefreshingState: false,
    accessTokenRefreshingCall: null,
    isRefreshing: false,
    notificationSettings: null,
    signatures: []
  };
};

const state = getDefaultState();

const mutations = {
  setApplicationError: (state, res) => (state.applicationError = true),
  status_success: (state, isLogged) => {
    state.isLogged = isLogged;
  },
  status_error: state => {
    state.statusError = true;
  },
  logout: state => {
    state.isLogged = false;
    state.logoutError = false;
  },
  logoutError: state => {
    state.logoutError = true;
  },
  setHousingCompany: (state, hc) => {

    if (checkBetaHc(hc.id)) {
      window.location.replace(
        process.env.VUE_APP_LIFERAY_API_BASE_URL + "/group/" + hc.id
      );
    }
    state.selectedHousingCompany = hc;
    try {
      if (window.localStorage) {
        localStorage.setItem("selectedHousingCompany", JSON.stringify(hc));
      }
    } catch (err) {
      console.log(err);
    }
  },
  setApartment: (state, apartment) => {
    state.selectedApartment = apartment;

    if (apartment) {
      try {
        if (window.localStorage) {
          localStorage.setItem("selectedApartment", JSON.stringify(apartment));
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
  setApartmentLayoutImages: (state, images) => {
    state.apartmentLayoutImages = images;
  },
  setHousingCompanyImages: (state, images) => {
    state.housingCompanyImages = images;
  },
  setUserFeatures: (state, res) => (state.features = res),
  setCurrentUser: (state, res) => {
    state.currentUser = new User(res);
  },
  setTemporaryZipCodeForDemoUser: (state, res) => {
    state.temporaryZipCodeForDemoUser = res;
    try {
      if (window.localStorage)
        localStorage.setItem("yit-demo-user-zipcode", res);
    } catch (err) {}
  },
  setUserNotifications: (state, res) => (state.userNotifications = res),
  setAreaHousingCompanies: (state, res) => (state.areaHousingCompanies = res),
  /**
   * Update user details
   */
  updateUserDetails: (state, res) => {
    if(res && typeof res === 'object') {
      Object.keys(res).map(key => {
        if(typeof state.currentUser[key] !== undefined) {
          state.currentUser[key] = res[key];
        }
      });
    }
  },

  setAccessTokenRefreshingState: (state, res) => (state.accessTokenRefreshingState = res),
  setRefreshingCall: (state, res) => (state.accessTokenRefreshingCall = res),
  resetUserState: (state, res) => {
    state.userNotifications = [];
    state.areaHousingCompanies = [];
  },
  setNotificationSettings: (state, res) => (state.notificationSettings = res),
  resetNotificationSettings: (state, res) => (state.notificationSettings = null),
  appendApartmentFields: (state, res) => {
    if (typeof res == 'object') {
      Object.keys(res).map(key => {
        if (typeof res[key] !== 'undefined')
          state.selectedApartment[key] = res[key];
      });
    }
  },
  setUserSignatures: (state, res) => (state.signatures = res),
  setUserDialogs: (state, res) => {
    if(Array.isArray(res))
      state.currentUser.dialogs = [...state.currentUser.dialogs, res]
  },
  setUserTasks: (state, res = []) => (state.currentUser.tasks = res),
  setUserQuickLinks: (state, res = []) => {
    state.currentUser.quickLinks = [...res];
  }
};

const actions = {
  fetchAccessToken({ commit }, params ) {
    const token = params.token;
    const refresh = params.refresh ? params.refresh : false;
    return new Promise((resolve, reject) => {
      api
        .getAccessToken(token, refresh)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          let status = err && err.response && err.response.status ? err.response.status : null;
          let message = err && err.response && err.response.data ? err.response.data.error : null;
          if (status === 401 && message == 'TOKEN_EXPIRED') {
            store.commit('setTokenRefreshState', false);
            store.commit("setIsTokenExpired", true);
            store.commit("clearTokens");
          }
          reject(err);
        });
    });
  },
  fetchUserDetails({ commit }) {
    return new Promise((resolve, reject) => {

      b2cApi.getUserDetails()
        .then(response => {
          commit("status_success", true);

          if (response.data.birthday) {
            const finnishDate = response.data.birthday.split(".");
            /*response.data.birthday = new Date(
              finnishDate[1] + "." + finnishDate[0] + "." + finnishDate[2]
            );*/
            response.data.birthday = new Date(
              finnishDate[2],
              parseInt(finnishDate[1]) - 1,
              finnishDate[0]
            );
          }

          let userDetails = response.data;

          //Order hcs into alphabetical order
          if (Array.isArray(userDetails.hcs) && userDetails.hcs.length > 1) {
            userDetails.hcs = _.sortBy(userDetails.hcs, ["name"]);
            let specialHcs = [];

            userDetails.hcs = userDetails.hcs.filter(hc => {
              if (yitDemoHcs.includes(hc.hcExt)) {
                specialHcs.push(hc);
                return false;
              } else return true;
            });
            if (specialHcs.length) {
              specialHcs.map(hc => {
                userDetails.hcs.unshift(hc);
                return hc;
              });
            }
          }

          //Set current user
          if (userDetails) store.commit("setCurrentUser", userDetails);

          resolve(response);
        })
        .catch(error => {
          console.log("SETTING USER DETAILS FAILED!", error);
          //commit("status_error");
          //commit("status_success", false);
          reject(error);
        });
    });
  },
  async fetchApartmentPrimaData({ commit }, params) {
    try {
      let response = await primaApi.fetchApartmentInfo(params);

      if (response.data) {
        let info = {}
        let locale = i18n.locale;

        //Append some selected prima data into selectedApartment object
        if (response.data.floorNumber)
          info.floorNumber = parseInt(response.data.floorNumber);
        if (response.data.subProjectNumberOfFloors)
          info.subProjectNumberOfFloors = parseInt(response.data.subProjectNumberOfFloors);
        if (response.data.terrace) {
          if (response.data.terrace.localizedStrings && Object.keys(response.data.terrace.localizedStrings).length) {
            if (typeof response.data.terrace.localizedStrings[locale] !== 'undefined')
              info.terrace = response.data.terrace.localizedStrings[locale];
          }
        }
        if (response.data.balcony) {
          if (response.data.balcony.localizedStrings && Object.keys(response.data.balcony.localizedStrings).length) {
            if (typeof response.data.balcony.localizedStrings[locale] !== 'undefined')
              info.balcony = response.data.balcony.localizedStrings[locale];
          }
        }
        if (response.data.sauna) {
          if (response.data.sauna.localizedStrings && Object.keys(response.data.sauna.localizedStrings).length) {
            if (typeof response.data.sauna.localizedStrings[locale] !== 'undefined')
              info.sauna = response.data.sauna.localizedStrings[locale];
          }
        }
        if (response.data.projectCommonSpaces) {
          info.projectCommonSpaces = response.data.projectCommonSpaces;
        }

        commit("appendApartmentFields", info);
        commit("setApartmentLayoutImages", response.data.resources ? response.data.resources : []);

        return { info, resources: response.data.resources ? response.data.resources : [] }
      }
        
    } catch(err) {
      console.log("failed fetching layout image: ", err);
    }
  },
  deactivateUser({ commit }) {
    return api
      .deactivateUser()
      .then(response => {
        console.log("User is deactivated: ", response);
      })
      .catch(err => {
        console.log("Could not deactivate User: ", err);
      });
  },
  removeUser({ commit }) {
    return api
      .removeUser()
      .then(response => {
        console.log("User is removed: ", response);
      })
      .catch(err => {
        console.log("Could not remove User: ", err);
      });
  },
  logout({ commit }) {
    return api.logout().then(res => {
      commit("logout");
    });
  },
  changeApartment({ commit }, apartment) {
    commit("setApartment", apartment);
  },
  fetchHousingCompanyPrimaData({ commit }, params) {
    return primaApi
      .fetchHousingCompanyData(params)
      .then(response => {
        let layoutImage = null;

        //1) Try to fetch image from resource group named yitplushchero
        if (
          response.data.resourceGroups &&
          response.data.resourceGroups.length
        ) {
          response.data.resourceGroups.map(group => {
            if (
              typeof group.resourceGroupName == 'string' && 
              group.resourceGroupName.replace(/[^A-Za-z0-9]+/g, "") == "yitplushchero" &&
              group.resources &&
              group.resources.length
            ) {
              if (
                group.resources[0] &&
                group.resources[0].subResources &&
                group.resources[0].subResources[0] &&
                !layoutImage
              )
                layoutImage = group.resources[0].subResources[0];
            }
          });
        }

        //2) Try to fetch first image under ProjectResourceHero subtype
        if (!layoutImage && response.data.resources.length) {
          response.data.resources.map(res => {
            if (
              res.subType &&
              res.subType == "ProjectResourceHero" &&
              res.subResources.length &&  
              //res.index == 0
              !layoutImage
            ) {
              layoutImage = res.subResources[0];
            }
          });
          //layoutImage = response.data.resources[0].subResources[0];
        }

        commit("setHousingCompanyImages", layoutImage);
      })
      .catch(err => {
        console.log(err);
      });
  },
  fetchUserFeatures({ commit }) {
    return featureApi
      .fetchUserFeatures()
      .then(res => {
        commit("setUserFeatures", res.data.features);
      })
      .catch(err => console.log("Could not get user features, ", err));
  },
  fetchUserNotifications({ commit }) {
    return notificationsApi
      .fetchUserNotifications(store.getters.getSelectedHousingCompany.hcExt)
      .then(res => {
        commit("setUserNotifications", res.data.items);
      })
      .catch(err => console.log("Error getting notifications", err));
  },
  fetchAreaHousingCompanies({commit}) {
    return api.getHousingCompaniesInArea().then(res => {
      commit("setAreaHousingCompanies", res.data);
    }).catch(err => console.log(err));
  },
  async selectHousingCompany({commit}, hc) {
    try {
      let hcDetails = await api.getHousingCompanyDetails(hc);
      if(hcDetails && hcDetails.data) {
        
        let finalDetails = hc;
        if(hcDetails.data.marketingName && hcDetails.data.businessUnit)
          finalDetails = Object.assign(hc, hcDetails.data);

        commit("setHousingCompany", finalDetails);

        //set building taxonomies
        if (finalDetails.buildingTaxonomies) {
          store.dispatch("setBuildingTaxonomyFilters", finalDetails.buildingTaxonomies);
        } else {
          try {
            let buildingTaxonomies = await getBuildingTaxonomyFiltersForKentico();
            store.dispatch("setBuildingTaxonomyFilters", buildingTaxonomies);
          } catch (err) {
            console.log(err);
          }
        }

        //update current hc's apartment details also if only apartment was changed / full details fetched
        let selectedApt = state.selectedApartment;
        if(selectedApt && selectedApt.aptext && Array.isArray(finalDetails.apts) && finalDetails.apts.length) {
          let newSelectedApt = finalDetails.apts.find(a => a.aptext === selectedApt.aptext);
          if(newSelectedApt && newSelectedApt.aptext) {
            commit("setApartment", newSelectedApt);
          }
        }
        
      }
    } catch(error) {
      console.error(error);
    }
  },
  async fetchUserNotificationSettings({ commit }, payload = {}) {
    try {
      let project = store.getters.getSelectedHousingCompany;
      let apartment = store.getters.getSelectedApartment;
      let apartmentId = apartment ? apartment.aptext : null;
      let projectId = project.hcExt;

      if (payload.projectId)
        projectId = payload.projectId;
      if (payload.apartmentId)
        apartmentId = payload.apartmentId;
      
      let res = await notificationsApi.fetchNotificationSettings(Object.assign({ projectId, apartmentId }, payload));
      commit("setNotificationSettings", res.data);
      return res;
    } catch (err) {
      console.log(err);
      commit("setNotificationSettings", null);
      throw err;
    }
  },
  async saveUserNotificationSettings({ commit }, params = {}) {
    try {
      let query = params && params.query ? params.query : {};
      let payload = params && params.payload ? params.payload : {};

      let project = store.getters.getSelectedHousingCompany;
      let projectId = project.hcExt;
      if (!query.projectId)
        query.projectId = projectId;

      let res = await notificationsApi.setNotificationSettings(query, payload);
      commit("setNotificationSettings", payload);
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async fetchUserSignatures({ commit }) {
    try {
      let res = await api.getUserSignatures();
      commit("setUserSignatures", Array.isArray(res.data) ? res.data : []);
      return res.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async saveUserSignature({ commit }, signature) {
    try {
      let res = null;

      if (signature._id)
        res = await api.createUserSignature(signature);
      else
        res = await api.saveUserSignature(signature);
      
      commit("setUserSignatures", Array.isArray(res.data) ? res.data : []);
      return res.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async deleteUserSignature({ commit }, signature) {
    try {
      let res = await api.deleteUserSignature(signature);
      commit("setUserSignatures", res.data && Array.isArray(res.data) ? res.data : []);
      return res.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async banUser({ commit }, payload) {
    return api.banUser(payload.userUuid, { expiryDate: payload.expiryDate, projectId: payload.projectId })
  },
  async markUserDialogRead({ commit }, payload = {}) {
    const dialogId = payload.id;
    if (!dialogId)
      return;
    try {
      let res = await api.markUserDialogRead(payload);
      commit("setUserDialogs", res.data.dialogs);

      if (window.localStorage) {
        localStorage.setItem("dialogs", JSON.stringify([...store.getters.getCurrentUser.dialogs.filter(d => d._id === dialogId).map(d => Object.assign(d, {userId: store.getters.getCurrentUser.id})), {_id: dialogId, userId: store.getters.getCurrentUser.id, projectId: store.getters.getSelectedHousingCompany.hcExt}]));
      }

      return res.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async markTaskDone({ commit }, payload = {}) {
    try {
      await api.markTaskDone(payload.id, payload.status);
      let tasks = store.getters.getCurrentUser.tasks ? store.getters.getCurrentUser.tasks : [];
      if (payload.status === true || payload.status == 'true') {
        tasks = tasks.filter(t => t.kenticoId !== payload.id);
        tasks.push({ kenticoId: payload.id, status: payload.status });
      } else {
        tasks = tasks.filter(t => t.kenticoId !== payload.id);
      }
      commit("setUserTasks", tasks);

    } catch (err) {
      throw err;
    }
  },
  async updateUserQuickLinks({ commit }, payload) {
    let res = await api.updateUserQuickLinks(payload.projectId, payload.links)
    commit("setUserQuickLinks", res.data);
  }
};

const getters = {
  getApplicationError: state => state.applicationError,
  isLogged: state => state.isLogged,
  getStatusError: state => state.statusError,
  getCurrentUser: state => state.currentUser,
  getUserDetails: state => state.currentUser,
  getSelectedHousingCompany: state => {
    let hc = state.selectedHousingCompany;
    //Override user details zip code if user is scrolling as YIT Demo user
    if (
      getters.isDemoHousingCompany(state) &&
      getters.getTemporaryZipCodeForDemoUser(state) &&
      getters.getTemporaryZipCodeForDemoUser(state)
    ) {
      hc.addressZip = getters.getTemporaryZipCodeForDemoUser(state);
    }

    return hc;
  },
  getApartments: state => {
    let apartments = [];

    //if there's no housing companies, there's no apartments under those
    if (state.selectedHousingCompany) {
      apartments = state.selectedHousingCompany.apts;
    }

    return apartments;
  },
  getSelectedApartment: state => state.selectedApartment,
  getLang: state => {
    let lang = state.currentUser.locale
      ? state.currentUser.locale.split("_")[0]
      : "en";
    if(settings.langs.find(l => l.langKey === lang))
      return lang.toLowerCase();

    return "en";
  },
  getUserLocale: state => {
    let lang = state.currentUser.locale
      ? state.currentUser.locale
      : "en_US";

    if(settings.langs.find(l => l.locale === lang))
      return lang;

    return "en_US";
  },
  isRegistered: state => state.currentUser.registered,
  getApartmentLayoutImages: state => state.apartmentLayoutImages,
  getHousingCompanyImages: state => state.housingCompanyImages,
  getCurrentHcState: state => {
    //return "construction";
    let hc = state.selectedHousingCompany;

    if (hc && hc.id) {
      if (hc.completionDate) {
        let today = new Date();
        let completionTime = new Date(hc.completionDate).getTime();
        today.setHours(0, 0, 0, 0);
        if (completionTime < today.getTime()) return "living";
        else return "construction";
      }
    }

    return "construction";
  },
  isUserYit: state => {
    let userRoles = [];
    if (state.currentUser.roles) {
      userRoles = state.currentUser.roles;
    }

    let isYituser = false;
    if (
      userRoles.includes("liferayAdmin") ||
      userRoles.includes("roleYitAdmin") ||
      userRoles.includes("roleYitBusinessAdmin") ||
      userRoles.includes("roleYitService") ||
      userRoles.includes("roleYitProject") ||
      userRoles.includes("roleYitMarketing")
    ) {
      isYituser = true;
    }
    return isYituser;
  },
  getUserRole: state => {
    let userRoles = [];
    if (state.currentUser.roles) {
      userRoles = state.currentUser.roles;
    }

    let ret = false;
    if (
      userRoles.includes("liferayAdmin") ||
      userRoles.includes("roleYitAdmin")
    ) {
      return "admin";
    }

    if (
      userRoles.includes("roleYitService") ||
      userRoles.includes("roleYitProject") ||
      userRoles.includes("roleYitBusinessAdmin") ||
      userRoles.includes("roleYitMarketing")
    ) {
      return "editor";
    }

    return "resident";
  },
  isUserAdmin: state => {
    return (
      getters.getUserRole(state) == "admin" ||
      getters.getUserRole(state) == "editor"
    );
  },
  getUserCountries: state => {
    let countries = state.currentUser.hcs.map(hc => {
      return hc.addressCountry.toLowerCase();
    });

    countries = countries.filter(function(item, pos) {
      return countries.indexOf(item) == pos;
    });

    return countries;
  },
  getUserFeatures: state => state.features,
  isDemoHousingCompany: state => {
    return yitDemoHcs.includes(state.selectedHousingCompany.hcExt);
  },
  getTemporaryZipCodeForDemoUser: state => {
    let zip = state.temporaryZipCodeForDemoUser;
    if (!zip) {
      try {
        if (window.localStorage)
          zip = localStorage.getItem("yit-demo-user-zipcode");
      } catch (err) {}
    }

    return zip;
  },
  getUserNotifications: state => state.userNotifications,
  getAreaHousingCompanies: state => state.areaHousingCompanies,
  getAreaNameByExtId: state => {
    return hcExtId => {
      let ret = null;
      if(Array.isArray(state.areaHousingCompanies) && state.areaHousingCompanies.length) {
        let results = state.areaHousingCompanies.filter(hc => hc.hcExt === hcExtId);
        if(results.length)
          ret = results[0].name;
      }
      return ret;
    }
  },
  isBusinessPartner: state => {
    if(state.currentUser && state.currentUser.roles.includes('partner') && Array.isArray(state.currentUser.partnerCompanies) && state.currentUser.partnerCompanies.length)
      return true;
    return false;
  },
  isExternalPartnerUser: state => {
    if(state.currentUser.roles.includes('partner') || state.currentUser.roles.includes('propertyManager') || state.currentUser.roles.includes('maintenanceManager'))
      return true;
    return false;
  },
  /**
   * Return current partner company for business partner user (partner can have only one company so let's just return index 0)
   */
  getCurrentPartnerCompanyDetails: state => {
    if(Array.isArray(state.currentUser.partnerCompanies) && state.currentUser.partnerCompanies.length) {
      return state.currentUser.partnerCompanies[0];
    }

    return null;
  },
  /**
   * Get housing companies where user has some sort of admin permissions
   */
  getUserHousingCompaniesForAdmin: state => {
    let user = state.currentUser;
    let hcs = Array.isArray(user.hcs) && user.hcs.length ? user.hcs : [];
    
    if(user.hasAnyOfRoles(settings.projectRoleGroups))
      return hcs;
    return hcs.filter(hc => {
      if (hc.roles.includes('roleBoardMember') || hc.roles.includes('roleChairman') || hc.roles.includes('boardMember') || hc.roles.includes('chairman') || hc.roles.includes('roleCeeBoardMember') || hc.roles.includes('ceeBoardMember'))
        return true;
      else
        return false;
    })
  },
  /**
   * TEMPORARY
   * 
   * @todo remove after plus-20-merge 
   */
  getLiferayUserJWT: state => {
    let token = store.getters.getCurrentUser.liferayToken;
    if(!token)
      token = state.currentUser.jwt;

    return token;
  },
  getNotificationSettings: state => state.notificationSettings,
  getUserSignatures: state => state.signatures,
  isDialogRead: state => dialogId => {
    let ret = state.currentUser && Array.isArray(state.currentUser.dialogs) && state.currentUser.dialogs.length
      ? (state.currentUser.dialogs.find(t => t._id === dialogId) ? true : false)
      : false
    
    if (!ret) {
      if (window.localStorage) {
        let dialogsFromLocalStorage = JSON.parse(localStorage.getItem("dialogs"));
        if (Array.isArray(dialogsFromLocalStorage) && dialogsFromLocalStorage.length) {
          ret = dialogsFromLocalStorage.find(t => t._id === dialogId && store.getters.getCurrentUser.id === t.userId) ? true : false
        }
      }
    }
    
    return ret;
  },
  isTaskMarkedDone: state => itemId => {
    if (state.currentUser && Array.isArray(state.currentUser.tasks)) {
      return state.currentUser.tasks.find(task => task.kenticoId === itemId && task.status !== false) ? true : false
    }

    return false;
  },
  getUserQuickLinks: state => projectId => {
    let projectQuickLinks = (state.currentUser.quickLinks || []).find(c => c.projectId === projectId);
    if (projectQuickLinks)
      return buildModuleData(projectQuickLinks.links);
    return getDefaultQuickLinksForUser(true);
  },
  getPageName: state => {
    const pageName = process.env.VUE_APP_SITE_TITLE ? process.env.VUE_APP_SITE_TITLE : 'YIT Plus';
    const currentHc = state.selectedHousingCompany;
    if(currentHc && String(currentHc.addressCountry).toUpperCase() == 'SVK') {
      return String(pageName).toUpperCase();
    }  
    
    return pageName;
  }
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
