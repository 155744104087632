<template>
    <span class="profile-icon" :class="getCssClasses" :style="{backgroundColor: colorByString(name)}">
        <i class="far fa-users" v-if="type == 'group'"></i>
        <i class="far fa-user is-private" v-else-if="type == 'private'"></i>
        <template v-else>
            <span v-if="getRoles.includes('yit') || getRoles.includes('admin') || getRoles.includes('yitBusinessAdmin') || getRoles.includes('yitProject') || getRoles.includes('yitMarketing') || getRoles.includes('yitUser')" class="icon--yit"><img :src="getYitLogo" /></span>
            <i class="fas fa-wrench" v-else-if="getRoles.includes('maintenanceManager') || getRoles.includes('propertyManager')"></i>
            <i v-else-if="getRoles.includes('owner') && !name" class="far fa-user"></i>
            <span v-else-if="getRoles.includes('owner') || name" class="icon--letter">{{ name.charAt(0).toUpperCase() }}</span>
            <i v-else class="far fa-user"></i>
        </template>
    </span>
</template> 

<script>
import {colorByString} from "@/utils/StringUtils";
import yitLogo from "@/assets/images/yit-logo-white.svg";

export default {
    name: "ProfileIcon",
    props: {
        type: {
            type: String,
            default: 'user' //user, group, private
        },
        isMe: {
            type: Boolean,
            default: false
        },
        roles: {
            type: Array,
            default: () => []
        },
        name: {
            type: String
        },
        apt: {
            type: String
        },
        size: {
            type: String,
            default: "normal"
        }
    },
    computed: {
        getYitLogo() {
            return yitLogo;
        },
        retrieveUserRoleByApt() {
            if(this.apt && this.apt.toLowerCase() == 'yit')
                return ['yit'];
            else if (this.apt == 'community.label.administration' || this.apt == 'user.admin.role.rolePropertyManager')
                return ['propertyManager']
            else if (this.apt == 'community.label.maintenance' || this.apt == 'user.admin.role.roleMaintenanceManager')
                return ['maintenanceManager']
            return ['owner'];
        },
        getRoles() {
            if(this.roles.length)
                return this.roles;
            
            return this.retrieveUserRoleByApt;
        },
        getCssClasses() {
            let cssClasses = {
                'profile-icon--me': this.isMe, 
                'profile-icon--is-yit': this.getRoles.includes('yit') || this.getRoles.includes('admin') || this.getRoles.includes('yitBusinessAdmin') || this.getRoles.includes('yitProject') || this.getRoles.includes('yitMarketing') || this.getRoles.includes('yitUser')
            };
            cssClasses['profile-icon--'+this.size] = true;
            return cssClasses;
        }
    },
    methods: {
        colorByString
    }
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";
.profile-icon {
  background: $brandColorPrimary;
  border-radius: 50%;
  display: flex;
  width: 2.6rem;
  height: 2.6rem;
  color: #fff;
  align-items: center;
  justify-content: center;
  i,
  span {
    color: #fff;
    font-size: 0.85rem;
  }
  .icon--letter {
      font-family: $fontPrimaryBold;
  }
  .icon--yit {
    width: 100%;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 80%;
        height: auto;
    }
  }
  
  &--is-yit {
      background-color: $blueMedium !important;
  }

  &--small {
      width: 1.8rem;
    height: 1.8rem;
  }
}
</style>
<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss"; 
/*.comment--me,
.conversation--me {
    .profile-icon {
        background: $green;
    }
}*/
</style>