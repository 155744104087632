<template>
  <div>
    <label class="label" v-if="label">{{label}}</label>
    <date-picker
      v-bind="$attrs"
      :value="value"
      :not-after="disabledDates"
      :lang="getLanguage"
      :format="dateFormat"
      :first-day-of-week="1"
      :type="type"
      :time-picker-options="timePickerOptions"
      :placeholder="placeholder ? placeholder : $t('common.choose')"
      @change="onChange"
    ></date-picker>
    <span class="error-text" v-if="validator && name && validator.has(name)">{{
      validator.get(name)
    }}</span>
    <div class="instructions-text" v-if="instruction" v-unescape>{{instruction}}</div>
  </div>
</template>

<script>
import DatePicker from "vue-datepicker-next";
import 'vue-datepicker-next/index.css';
import en from 'vue-datepicker-next/locale/en';
import fi from 'vue-datepicker-next/locale/fi';
import cs from 'vue-datepicker-next/locale/cs';
import et from 'vue-datepicker-next/locale/et';
import lt from 'vue-datepicker-next/locale/lt';
import lv from 'vue-datepicker-next/locale/lv';
import pl from 'vue-datepicker-next/locale/pl';
import sv from 'vue-datepicker-next/locale/sv';
import { i18n } from "@/plugins/i18n";

export default {
  name: "DatePickerWrapper",
  components: { DatePicker },
  props: {
    type: {type: String, default: 'datetime'},
    dateFormat: {type: String},
    disabledDates: {type: Boolean},
    modelValue: {},
    required: {type: Boolean, default: false},
    name: {type: String},
    validator: {},
    label: {type: String},
    placeholder: {type: String},
    instruction: {type: String},
    range: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'input'],
  data() {
    return {
      value: null,
      timePickerOptions: {
        start: "00:00",
        step: "00:30",
        end: "23:30"
      }
    };
  },
  computed: {
    getLanguage() {
      let lang = i18n.locale ? i18n.locale : 'fi';
      let languages = {
        en,
        fi,
        et,
        lv,
        lt,
        pl,
        cs,
        sv
      };
      if(languages.hasOwnProperty(lang))
        return languages[lang];
      else
        return null;
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
      this.$emit('update:modelValue', value)
    },
    toDateObject(value) {
      if(value) {
        if(value instanceof Date)
          return value;
        else {
          //handle finnish date format (we need to transform DD.MM.YYYY into YYYY.MM.DD)
          if(String(this.dateFormat).toLowerCase() === 'DD.MM.YYYY') {
            const dateParts = String(value).split(".");
            if(parts.length === 3)
              return new Date(`${parts[2]}.${parts[1]}.${parts[0]}`);
          } 
            return new Date(String(value).toString());
        }
      }
    }
  },
  watch: {
    modelValue(value) {
      console.log("VALUE: ", value)
      this.value = this.toDateObject(value)
    }
  },
  mounted() {
    this.value = this.toDateObject(this.modelValue)
  }
};
</script> 