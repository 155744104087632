import store from "@/store/index";
import BaseService from "./BaseService";
import { AzureFunctionClient } from "./AzureFunctionClient";

export default class ProjectService extends BaseService {
  constructor() {
    super();
  }

  getProjects(params = {}) {
    let payload = {};

    if (params.zip) {
      if (Array.isArray(params.zip)) payload.zip = params.zip.toString();
      else payload.zip = params.zip;

      if (params.fields) payload.fields = params.fields;
    }

    if (params.propertyManagerCompany)
      payload.propertyManagerCompany = params.propertyManagerCompany;
    if (params.maintenanceManagerCompany)
      payload.maintenanceManagerCompany = params.maintenanceManagerCompany;

    return AzureFunctionClient.get(this._buildUrl("/getProjects", payload));
  }

  /**
   * Get list of apartments by housing company id
   * @param {*} params
   */
  getApartments(hcExtId = null) {
    let req = {
      projectId: hcExtId,
    };

    req.userUuid = store.getters.getCurrentUser.id;

    let query = this._buildUrl(`/getApartments`, req);
    return AzureFunctionClient.get(query);
  }

  getApartmentDetails(apartmentId) {
    return AzureFunctionClient.get(`/apartment?apartmentId=${apartmentId}`);
  }

  updateApartmentDetails(apartmentId, payload) {
    return AzureFunctionClient.put(
      `/apartment?apartmentId=${apartmentId}`,
      payload
    );
  }

  fetchProjectDetails(hcExtId) {
    let req = {
      projectId: hcExtId,
    };

    let query = this._buildUrl(`/getProjectDetails`, req);
    return AzureFunctionClient.get(query);
  }

  saveProjectSettings(postData) {
    let payload = {
      projectId: postData.projectId,
    };

    let availableFields = [
      "serviceRequestSettings",
      "housingCompanyAdminEmail",
      "selectedPropertyManagerCompanies",
      "selectedMaintenanceManagerCompanies",
      "porrasFeedUrl",
      "completionDate",
      "finalizationDate",
      "energyClass",
      "numberOfApartments",
      "numberOfNonSellableParkingPlaces",
      "additionalInformationAboutParkingPlaces",
      "one4allUuid",
      "one4allIncludeAllResidents",
      "warrantyReportStartDate",
      "warrantyReportEndDate",
      "warrantyRepairMeetingDate",
      "warrantyRepairNoticeInfoDate",
      "warrantyRepairEndDate",
      "warrantyPeriodEndDate",
      "warrantyRepairProcessEndDate",
      "warrantyContacts",
      "congridId",
      "isYSEProject",
      "lifecycleStepsActive",
      "wizardCompleted"
    ];

    Object.keys(postData).map((field) => {
      if (availableFields.includes(field)) {
        payload[field] = postData[field];
      }
    });

    return AzureFunctionClient.put("/project", payload);
  }

  updateProjectPartnerCompany(payload) {
    return AzureFunctionClient.post("/updateProjectPartnerCompany", payload);
  }

  updateProjectPartnerCompanyDetails(params, payload) {
    if (!params.projectId || !params.type)
      throw "Required params projectId and type missing";
    return AzureFunctionClient.post(
      this._buildUrl("updatePartnerCompanyContactDetails", {
        projectId: params.projectId,
        type: params.type,
      }),
      payload
    );
  }

  fetchPartnerCompanies(params = {}) {
    let payload = Object.assign({}, params);
    if (payload.append) delete payload.append;
    if (payload.country) payload.country = payload.country.toUpperCase();
    return AzureFunctionClient.get(
      this._buildUrl("/getPartnerCompanies", payload)
    );
  }

  fetchAvailablePropertyManagers(params = {}) {
    let payload = {
      type: "PropertyManager",
    };
    if (params.country) payload.country = params.country;
    if (params.limit) payload.limit = params.limit;

    return AzureFunctionClient.get(
      this._buildUrl("/getPartnerCompanies", payload)
    );
  }

  fetchAvailableMaintenanceManagers(params = {}) {
    let payload = {
      type: "MaintenanceManager",
    };
    if (params.country) payload.country = params.country;
    if (params.limit) payload.limit = params.limit;
    return AzureFunctionClient.get(
      this._buildUrl("/getPartnerCompanies", payload)
    );
  }

  fetchBusinessPartnersForHousingCompany(projectId) {
    if (!projectId) projectId = store.getters.getSelectedHousingCompany.hcExt;
    return AzureFunctionClient.get(
      this._buildUrl("/getBusinessPartnersForHousingCompany", { projectId })
    );
  }

  fetchPartnerCompanyDetails(id) {
    return AzureFunctionClient.get(this._buildUrl("/partnerCompany", { id }));
  }

  fetchPartnerCompanyDetailsByExternalId(id) {
    return AzureFunctionClient.get(
      this._buildUrl("/partnerCompany", { externalId: id })
    );
  }

  createPartnerCompany(payload) {
    if (!payload) throw "You must send payload to create partner";

    let formData = {};

    if (payload.type) formData.type = payload.type;
    if (payload.businessId) formData.businessId = payload.businessId;
    if (payload.name) formData.name = payload.name;
    if (payload.externalId) formData.id = payload.externalId;
    if (typeof payload.street != "undefined") formData.street = payload.street;
    if (typeof payload.zip != "undefined") formData.zip = payload.zip;
    if (typeof payload.city != "undefined") formData.city = payload.city;
    if (payload.country) formData.country = payload.country;
    if (typeof payload.phone != "undefined") formData.phone = payload.phone;
    if (typeof payload.email != "undefined") formData.email = payload.email;
    if (typeof payload.webPageUrl != "undefined")
      formData.webPageUrl = payload.webPageUrl;
    if (typeof payload.description != "undefined")
      formData.description = payload.description;
    if (payload.logo) formData.logo = payload.logo;
    if (payload.featuredImage) formData.featuredImage = payload.featuredImage;

    if (typeof payload.active !== "undefined")
      formData.active = payload.active ? true : false;

    return AzureFunctionClient.post("/partnerCompany", formData);
  }

  editPartnerCompany(payload) {
    if (!payload) throw "You must send payload to update partner";

    if (!payload.externalId && !payload.id)
      throw "You must send partner external id in payload to update partner details";

    let formData = {};
    
    if(payload.id)
      formData.id = payload.id;
    if(payload.externalId)
      formData.externalId = payload.externalId;
    if(payload.type)
      formData.type = payload.type;
    if(payload.businessId)
      formData.businessId = payload.businessId;
    if(payload.name)
      formData.name = payload.name;
    if(typeof payload.street != 'undefined')
      formData.street = payload.street;
    if(typeof payload.zip != 'undefined')
      formData.zip = payload.zip;
    if(typeof payload.city != 'undefined')
      formData.city = payload.city;
    if(payload.country)
      formData.country = payload.country;
    if(typeof payload.phone != 'undefined')
      formData.phone = payload.phone;
    if(typeof payload.email != 'undefined')
      formData.email = payload.email;
    if(typeof payload.webPageUrl != 'undefined')
      formData.webPageUrl = payload.webPageUrl;
    if(Array.isArray(payload.operationalAreaZips))
      formData.operationalAreaZips = payload.operationalAreaZips;
    if(payload.operationalAreaBuildingTaxonomies)
      formData.operationalAreaBuildingTaxonomies = payload.operationalAreaBuildingTaxonomies;
    if (payload.logo) {
      formData.logo = payload.logo;
      delete formData.logo.thumbnails;
    }
    if (payload.featuredImage) {
      formData.featuredImage = payload.featuredImage;
      delete formData.featuredImage.thumbnails;
    }
    if(typeof payload.description)
      formData.description = payload.description;
    if (typeof payload.active !== "undefined")
      formData.active = payload.active ? true : false;

    return AzureFunctionClient.put("/partnerCompany", formData);
  }

  deletePartnerCompany(id) {
    return AzureFunctionClient.delete(
      this._buildUrl("/partnerCompany", { id })
    );
  }

  updatePartnerCompanyKeyContact(payload) {
    return AzureFunctionClient.post("/updatePartnerCompanyKeyContact", payload);
  }

  removePartnerContactFromHousingCompany(payload) {
    return AzureFunctionClient.post(
      "/removePartnerContactFromHousingCompany",
      payload
    );
  }

  removePartnerCompanyConnectionFromHousingCompany(params = {}) {
    if (!params || !params.projectId || !params.partnerCompanyId)
      throw "ProjectId and partnerCompanyId are required";

    let payload = {
      projectId: params.projectId,
      partnerCompanyId: params.partnerCompanyId,
    };
    return AzureFunctionClient.post(
      "/removePartnerCompanyConnectionFromHousingCompany",
      payload
    );
  }

  fetchPartnerCompanyUsers(params) {
    let payload = {
      id: params.projectId,
    };
    if (params.roles) payload.roles = params.roles;
    return AzureFunctionClient.get(
      this._buildUrl("/getPartnerCompanyUsers", payload)
    );
  }

  /**
   * Fetch kentico taxonomy group terms
   * @param {*} codename
   * @returns
   */
  fetchTaxonomyGroupTerms(codename) {
    return AzureFunctionClient.get(`/taxonomy/terms/${codename}`);
  }

  /**
   * 
   * @param {String} projectId 
   * @returns {Promise}
   */
  markWizardCompleted(projectId) {
    return this.saveProjectSettings({projectId, wizardCompleted: new Date()})
  }
}
