import { i18n } from "@/plugins/i18n";
import { ServiceRequestModel, types } from "./ServiceRequestModel";
import store from "@/store";

export default class AzureServiceRequestModel extends ServiceRequestModel {
  constructor(item) {
    super(item);
    this.id = item._id;
    this.internalId = item.internalId;
    this.source = types.AZURE_SERVICE_REQUEST_SOURCE_KEY;
    this.urgentRequest = item.urgentRequest ? item.urgentRequest : false;
    this.subject = item.subject;
    this.typeTranslated = i18n.global.t("service.request.type." + this.typeId);
    this.statusTranslated = i18n.global.t("serviceRequest.status." + this.statusKey);
    this.createdTime = new Date(this.createdAt).getTime();
  }

  getCreatedDate() {
    return this.createdAt;
  }

  getSubject() {
    if (this.subject) return this.subject;
    else if (this.typeId) return i18n.global.t("service.request.type." + this.typeId);
  }

  isPublic() {
    return this.public ? true : false;
  }

  isClosed() {
    return this.statusKey === 'closed' || this.statusKey === 'declined' ? true : false;
  }

  isMy() {
    return this.senderUserUuid === store.getters.getCurrentUser.getId()
      ? true
      : false;
  }

  getStatusString() {
    let status = this.getDefaultStatusKey();

    if (this.statusKey) status = "serviceRequest.status." + this.statusKey;
    if (this.closed) status = "serviceRequest.status.closed";

    return i18n.global.t(status);
  }
}
