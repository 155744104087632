<template>
  <div class="search-input" :class="{'search-input--full-width': componentStyle == 'full-width'}">
    <i class="far fa-search search-input__icon"></i>
    <input
      v-bind="$attrs"
      class="search-input__input"
      type="search"
      name="search"
      :id="id"
      :placeholder="placeholder"
      v-bind:value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      autocomplete="off"
    />
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  emits:['update:modelValue'],
  props: {
    placeholder: {
      type: String
    },
    modelValue: {},
    id: {
      type: String
    },
    componentStyle: {
      type: String,
      default: "default"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
.search-input {
  display: flex;
  align-items: center;
  overflow: hidden;
  background: #fff;
  border: 1px solid #fff;
  padding: 0.65rem 1.3rem;
  margin: 0.5rem auto;
  :focus {
    outline: none;
    border: 0 !important;
  }
  input[type="search"] {
    -webkit-appearance: none;
  }
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
  }

  &__icon {
    font-size: 0.85rem;
    padding: 0 1rem 0 0;
    color: $lighterFontColor;
  }
  &__input {
    font-size: .95rem;
    border: 0;
    padding: 0;
    margin: 0;
    flex: 1;
    background: transparent;
    color: $greyBlueMidDark;
  }

  &__button {
    background: #538ac5;
    border: 0;
    color: white;
    padding: 0.5rem;
    border-radius: 0;
  }

  &--borders {
    border: 1px solid $borderColorLight;
  }

  &--full-width {
    border: 1px solid $borderColorLight;
    padding: 1.2rem 1.5rem;
     .search-input__icon {
      font-size: 1rem;
    }
  }
}
</style>
