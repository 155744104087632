import One4AllService from "@/services/One4allService"
import { TokenService } from "../../services/TokenService";
import store from "@/store"

const getDefaultState = () => {
  return {
    one4AllAccessToken: null,
    one4AllTokenExpiriesIn: null,
  }
}

const state = getDefaultState()

const getters = {
  getOne4AllAccessToken: state => state.one4AllAccessToken,
  getOne4AllTokenExpiriesIn: state => state.one4AllTokenExpiriesIn,
}

const actions = {
  async fetchOne4allAccessToken({ commit, state }) {
    const one4AllService = new One4AllService();
    try {
      const res = await one4AllService.getSsoToken();
      if(res && res.data) {
        commit('setOne4AllAccessToken', res.data.access_token)
        commit('setOne4AllTokenExpiriesIn', res.data.expires_in)
      }
      return res
    } catch(err) {
      console.log(err);
    }
  },
  clearOne4AllTokens: ({ commit }) => {
    commit('setOne4allAccessToken', null)
    commit('setOne4AllTokenExpiriesIn', null)
  }
}

const mutations = {
  setOne4AllAccessToken: (state, accessToken) => {
    state.one4AllAccessToken = accessToken
    try {
      localStorage.setItem("one4allAccessToken", accessToken)
    } catch (err) {
      console.log(err);
    }
  },
  setOne4AllTokenExpiriesIn: (state, expiriesTimeStamp) => (state.one4AllTokenExpiriesIn = expiriesTimeStamp),
}

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
}