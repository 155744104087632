/**
 * All globally registered components. These will be registered automatically as global
 */
import AlertMessage from "./AlertMessage";
import BackButton from "./BackButton";
import QuickLink from "./QuickLink";
import ToolbarLink from "./ToolbarLink";
import ActionLinkItem from "./ActionLinkItem";
import BooleanField from "./Form/BooleanField";
import Toast from "./Toast";
import LoaderIcon from "./LoaderIcon";
import PageSection from "./PageSection";
import PageWrapper from "./PageWrapper";

import FormInput from "./Form/Input";
import SearchInput from "./Form/SearchInput";
import Button from "./Button";

import CommonErrorNotification from "@/views/partials/CommonErrorNotification";
import InsufficientPrivilegesPartial from "@/views/partials/InsufficientPrivileges";

import Message from "@/components/Comments/Message";
import Comments from "@/components/Comments/Comments";
import Comment from "@/components/Comments/Comment";

export default [
  AlertMessage,
  BackButton,
  QuickLink,
  ActionLinkItem,
  FormInput,
  SearchInput,
  Toast,
  LoaderIcon,
  BooleanField,
  PageSection,
  PageWrapper,
  Button,
  ToolbarLink,
  CommonErrorNotification,
  InsufficientPrivilegesPartial,
  Message,
  Comments,
  Comment
];
