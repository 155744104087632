<template>
  <div
    v-if="type == 'text' || type == 'number' || type == 'tel' || type == 'password' || !type"
    :class="getCssClasses"
  >
    <label class="label" :for="name" v-if="label">
      {{ label }}
      <template v-if="required">*</template>
      <Tooltip v-if="tooltip">{{tooltip}}</Tooltip>
    </label>
    <input
      v-bind="$attrs"
      :type="type ? type : 'text'"
      :id="name"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :value="modelValue"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      :required="required"
    />
    <slot></slot>
    <div class="instructions-text" v-if="instruction" v-unescape>{{instruction}}</div>
    <span class="error-text" v-if="validator && validator.has(name)">{{
      validator.get(name)
    }}</span>
  </div>
  <div 
    v-else-if="type == 'select' || !type"
    :class="getCssClasses"
  >
    <label class="label" :for="name" v-if="label">
      {{ label }}
      <template v-if="required">*</template>
      <Tooltip v-if="tooltip">{{tooltip}}</Tooltip>
    </label>
    <select
      v-bind="$attrs"
      :id="name"
      :name="name"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      :value="modelValue"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
    >
      <option value v-if="placeholder">{{ placeholder }}</option>
      <option
        v-for="(option, key) in options"
        :key="key"
        :value="key ? key : option"
        v-text="option"
      ></option>
      <slot></slot>
    </select>
    <div class="instructions-text" v-if="instruction" v-unescape>{{instruction}}</div>
    <span class="error-text" v-if="validator && validator.has(name)">{{
      validator.get(name)
    }}</span>
  </div>
  <div 
    v-else-if="type == 'textarea'"
    :class="getCssClasses"
  >
    <label class="label" :for="name" v-if="label">
      {{ label }}
      <template v-if="required">*</template>
      <Tooltip v-if="tooltip">{{tooltip}}</Tooltip>
    </label>
    <textarea
      v-bind="$attrs"
      :id="name"
      :placeholder="placeholder"
      :name="name"
      :disabled="disabled"
      :readonly="readonly"
      :rows="rows ? rows : 10"
      :value="modelValue"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
    ></textarea>
    <div class="instructions-text" v-if="instruction" v-unescape>{{instruction}}</div>
    <span class="error-text" v-if="validator && validator.has(name)">{{
      validator.get(name)
    }}</span>
    <slot></slot>
  </div>
  <div 
    v-else-if="type == 'textarea-styled'"
    :class="getCssClasses"
  >
    <label class="label" :for="name" v-if="label">
      {{ label }}
      <template v-if="required">*</template>
      <Tooltip v-if="tooltip">{{tooltip}}</Tooltip>
    </label>
    <p 
      v-bind="$attrs"
      class="textarea--styled"
      :id="name"
      :name="name"
      :disabled="disabled"
      :readonly="readonly"
      :rows="rows ? rows : 10"
      :value="modelValue"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      contenteditable
    ></p>
    <span class="textarea--styled__placeholder" v-if="placeholder && !value">{{placeholder}}</span>
    <div class="instructions-text" v-if="instruction" v-unescape>{{instruction}}</div>
    <span class="error-text" v-if="validator && validator.has(name)">{{
      validator.get(name)
    }}</span>
    <slot></slot>
  </div>
  <div
    v-else-if="(type == 'checkbox' || type == 'radio') && options"
    :class="getCssClasses"
  >
    <span class="error-text" v-if="validator && validator.has(name)">
      {{validator.get(name)}}
      <Tooltip v-if="tooltip">{{tooltip}}</Tooltip>
    </span>

    <div
      :class="type == 'radio' ? 'radio c-radio' : 'checkbox c-cb'"
      v-for="(option, key) in options"
      :key="key"
    >
      <input
        v-bind="$attrs"
        :type="type"
        :value="key ? key : option"
        :checked="isChecked(key ? key : option)"
        :name="name"
        :id="name + key"
        :disabled="disabled"
        :readonly="readonly"
        @change="onChange"
      />
      <label :for="name + key" v-if="label">{{ option }}</label>
      <slot></slot>
    </div>
    <div class="instructions-text" v-if="instruction" v-unescape>{{instruction}}</div>
  </div>
  <div
    v-else-if="type == 'radio' && !options"
    :class="{ 'input-group--inline': inline, type, 'has-error': validator && validator.has(name) }"
  >
    <span class="error-text" v-if="validator && validator.has(name)">{{
      validator.get(name)
    }}</span>
    <label :for="name + key" v-if="label">
      {{ option }}
      <Tooltip v-if="tooltip">{{tooltip}}</Tooltip>
    </label>
    <slot></slot>
    <div class="instructions-text" v-if="instruction" v-unescape>{{instruction}}</div>
  </div>
  <div
    v-else-if="(type == 'checkbox') && !options"
    :class="getCssClasses"
  >
    <div class="checkbox c-cb" :class="type">
      <input
        v-bind="$attrs"
        :type="type"
        :value="value"
        :checked="isChecked(value)"
        :name="name"
        :id="name"
        :disabled="disabled"
        :readonly="readonly"
        @change="onChange"
      />
      <label :for="name" v-if="label">
        {{ label }}
        <Tooltip v-if="tooltip">{{tooltip}}</Tooltip>
      </label>
      <span class="error-text" v-if="validator && validator.has(name)">{{
        validator.get(name)
      }}</span>
      <slot></slot>
    </div>
    <div class="instructions-text" v-if="instruction" v-unescape>{{instruction}}</div>
  </div>
  <div v-else-if="type == 'wrapper'" class="input-group">
    <slot></slot>
  </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip";

export default {
  name: "FormInput",
  components: {
    Tooltip
  },
  computed: {
    getCssClasses() {
      let classes = {
        'input-group': true,
        'input-group--inline': this.inline,
        'has-error': this.validator && this.validator.has(this.name)
      };

      classes['input-group--'+this.type] = true;

      return classes;
    }
  },
  props: [
    "type",
    "name",
    "modelValue",
    "value",
    "label",
    "readonly",
    "placeholder",
    "disabled",
    "rows",
    "inline",
    "options",
    "errors",
    "validator",
    "checked",
    "tooltip",
    "instruction",
    "required"
  ],
  model: {
    prop: "modelValue",
    event: "update:modelValue"
  },
  emits:['blur', 'change', 'update:modelValue'],
  methods: {
    onInput(event) {
      this.handleValidation();
      if(event.target.type == 'checkbox' && !this.options) {
        this.$emit("update:modelValue", event.target.checked);
      } 
      
      else if (this.type == 'textarea-styled') {
        this.$emit("update:modelValue", event.target.innerText);
      }

      else 
      this.$emit("update:modelValue", event.target.value);
    },
    onChange(event) {
      this.handleValidation();
      if(!event.target)
        return;

      if(event.target.type == 'checkbox' && !this.options) {
        this.$emit("update:modelValue", event.target.checked);
        this.$emit("change", event.target.checked);
      } else if (this.type == 'textarea-styled') {
        this.$emit("update:modelValue", event.target.innerText);
        this.$emit("change", event.target.innerText);
      } else {
        this.$emit("update:modelValue", event.target.value);
        this.$emit("change", event.target.value);
      }
    },
    onBlur(event) {
      this.handleValidation();
      if(!event.target)
        return;

      if (this.type == 'textarea-styled') {
        this.$emit("blur", event.target.innerText);
      }
    },
    resetContentEditableField() {
      let el = this.$el.querySelector(".textarea--styled");
      console.log(el);
      if (el) {
        el.innerHTML = "";
      }
    },
    /**
     * @TODO There's seems to be issue with MS Edge, that value cannot be changed in select inputs if this is in use ..probably should return something from the method? Other browsers seems to work just fine
     */
    handleValidation() {
      if (this.validator) {
        this.validator.validate();
      }
    },
    isChecked(option) {
      let selectedValue = this.modelValue;

      if (!selectedValue) return false;

      //Array values
      if (Array.isArray(selectedValue)) {
        if (!selectedValue.length) return false;

        if (typeof this.options === "object")
          return (
            this.options.hasOwnProperty(option) &&
            selectedValue.includes(option)
          );
        return false;

        //String/Number values
      } else if (
        typeof selectedValue == "string" ||
        typeof selectedValue == "number"
      ) {
        if (selectedValue == option) return true;
        else return false;
      } else if (
        typeof selectedValue == "boolean"
      ) {
        if (selectedValue === Boolean(option)) return true
      }

      return false;
    }
  }
};
</script>
<style scoped></style>
