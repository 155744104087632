<template>
    <div :class="getCssClass">
        <i v-if="showIcon" :class="icon ? icon : getDefaultIconByType" aria-hidden="true"></i>
        <div>
          <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
  name: "AlertMessage",
  props: {
    type: {type: String}, 
    icon: {type: String},
    showIcon: {type: Boolean, default: false}
  },
  computed: {
    getCssClass() {
      let type = this.type;

      if (!type) type = "notification";

      return "alert alert--" + type;
    },
    getDefaultIconByType() {
      let ret  = "";
      switch (this.type) {
        case "info":
        case "error":
          ret = "fas fa-info-circle";
          break;
        case "success":
          ret = "fas fa-check-circle";
          break;
        case "reminder":
          ret = "fas fa-circle-info";
          break;
        default:
          ret = "fas fa-info-circle";
      }

      return ret;
    }
  }
};
</script>
