<template>
  <div class="button-with-confirmation">
    <Button
      class="button"
      type="button"
      :class="{
        'button--primary': type === 'primary',
        'button--secondary': type === 'secondary',
        'button--link': type === 'link',
        'button--unstyled': type === 'none',
      }"
      :mode="type"
      @click="onButtonClick"
      :disabled="disabled"
      :loading="loading"
    >
      <slot></slot>
    </Button>
    <Modal @close="modalOpen = false" v-if="modalOpen">
      <template v-slot:header>
        <slot name="header"></slot>
      </template>
      <slot name="content">{{ confirmText }}</slot>
      <template v-slot:footer>
        <div v-if="useClose" class="button-group text-center">
          <Button
            type="button"
            class="button button--primary"
            @click="modalOpen = false"
          >
            {{ $t("common.closeButton") }}
          </Button>
        </div>
        <div v-else class="button-group text-center">
          <Button
            type="button"
            class="button button--primary"
            @click="onApproveModal"
          >
            {{ $t("common.yes") }}
          </Button>
          <Button
            type="button"
            class="button button--link"
            @click="modalOpen = false"
          >
            {{ $t("common.cancel") }}
          </Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/common/Modal";

export default {
  name: "ButtonWithConfirm",
  props: {
    type: {
      type: String,
      default: "primary",
    },
    confirmModalText: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    useClose: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Modal,
  },
  data() {
    return {
      confirmText: "",
      modalOpen: false,
    };
  },
  methods: {
    onButtonClick(event) {
      this.modalOpen = true;
    },
    onApproveModal(event) {
      this.$emit("on-click", this.id, event);
      this.modalOpen = false;
    },
  },
  mounted() {
    this.confirmText = this.confirmModalText
      ? this.confirmModalText
      : this.$t("common.confirmDialogText");
  },
};
</script>
<style lang="scss" scoped>
.button-with-confirmation {
  cursor: pointer;
}
</style>