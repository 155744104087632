export default [
   {
    path: "/my-apartment/movement-sensors",
    name: "/my-apartment/movement-sensors",
    component: () =>
      import(
        /* webpackChunkName: "movement-sensors" */ "@/views/Apartment/MovementSensors.vue"
      ),
    meta: {
      title: "navigation.myapartment"
    }
  },
  {
    path: "/admin/iot",
    name: "/admin/iot",
    component: () =>
      import(
        /* webpackChunkName: "movement-sensors" */ "@/views/Admin/Iot/Index.vue"
      ),
    meta: {
      title: "navigation.iot-devices",
      layout: "admin-default",
      accessPolicies: {
        roles: ['admin','yitUser']
      },
      admin: true
    }
  },
  {
    path: "/admin/iot/devices/:projectId",
    name: "/admin/iot/devices/:projectId",
    component: () =>
      import(
        /* webpackChunkName: "movement-sensors" */ "@/views/Admin/Iot/Devices.vue"
      ),
    meta: {
      title: "navigation.iot-devices",
      layout: "admin-default",
      accessPolicies: {
        roles: ['admin','yitUser']
      },
      admin: true
    }
  }
]