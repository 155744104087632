import LiferayClient from "./LiferayClient";
import BaseService from "./BaseService";

export default class WarrantyService extends BaseService {
  constructor() {
    super();
    this.warrantyApi = "/delegate/yithome-api/warrantyrepairs/v2";
    this.userApi = "/delegate/yithome-api/user";
  }


  getApartments() {
    return LiferayClient.get(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments`);
  }

  getRepairsByApartment(aptid) {
    return LiferayClient.get(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptid}`);
  };

  saveRepairItems(faultData) {
    let postData = [{
      id: faultData.id,
      description: faultData.description,
      comments: faultData.comments,
      contractor: faultData.contractor,
      creator: faultData.creator,
      createdByUser: faultData.createdByUser,
      reason: faultData.reason,
      stage: faultData.stage,
      status: faultData.status,
      imageLinks: faultData.imageLinks,
    }];
    return LiferayClient.put(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${faultData.apartmentId}/faultCategories/${encodeURIComponent(faultData.faultCategory)}/faults`, postData);
  };

  uploadImage(aptid, imageFile) {
    var formData = new FormData();
    formData.append('image', imageFile);
    return LiferayClient.post(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptid}/faultImages/`, formData);
  };

  removeImages(aptid, imageIds) {
    return LiferayClient.post(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptid}/purgeFaultImages/`, imageIds);
  };

  getApartmentContactInfo(aptid) {
    return LiferayClient.get(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptid}/contactInfo/`);
  };

  saveApartmentContactInfo(contactInfo) {
    let postData = {
      id: contactInfo.id,
      apartmentId: contactInfo.apartmentId,
      useMasterKey: contactInfo.useMasterKey,
      phoneNumber: contactInfo.phoneNumber,
      tenant: contactInfo.tenant,
      tenantName: contactInfo.tenantName,
      tenantNumber: contactInfo.tenantNumber,
      otherNotes: contactInfo.otherNotes,
      owners: contactInfo.owners
    };
    return LiferayClient.put(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${contactInfo.apartmentId}/contactInfo/`, postData);
  };

  reopenFault(faultId, reason) {

    if (!reason) {
      return Promise.reject("reject_requires_reason");
    } else {
      return LiferayClient.put(`${this.warrantyApi}/faults/${faultId}/stage?stage=REJECTED`, reason);
    }
  };

  deleteFault(faultId) {
    return LiferayClient.delete(`${this.warrantyApi}/faults/${faultId}`);
  }

  getTypes() {
    return LiferayClient.get(`${this.warrantyApi}/enums`);
  };

  getNames() {
    return LiferayClient.get(`${this.warrantyApi}/inspector/contractors/${this.getHcId()}`);
  };

  toggleAllTenantsAllowed(aptid, allTenantsAllowed) {
    return LiferayClient.put(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptid}/allowTenantFaults?status=${allTenantsAllowed}`);
  }

  getUserInfo(aptid) {
    const userId = store.getters.getUserDetails.userId;
    return LiferayClient.get(`${this.userApi}/${userId}?hid=${this.getHcId()}&aid=${aptId}`);
  };

  getUserRole() {
    return LiferayClient.get(`${this.warrantyApi}/role/${this.getHcId()}`);
  }

  getAllContractors() {
    return LiferayClient.get(`${this.warrantyApi}/inspector/contractors?hc=${this.getHcId()}`);
  };

  getHCContractors() {
    return LiferayClient.get(`${this.warrantyApi}/inspector/contractors/${this.getHcId()}`);
  };

  saveHCContractors(hcContractors) {
    return LiferayClient.put(`${this.warrantyApi}/inspector/contractors/${this.getHcId()}`, hcContractors);
  };

  searchContractors(input) {
    return LiferayClient.get(`${this.warrantyApi}/contractors/?q=${input}`);
  };

  getAllowTenantFaults = function (aptid) {
    return LiferayClient.put(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptid}/allowTenantFaults`);
  }

  getDeadlines() {
    return LiferayClient.get(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/deadlines`);
  };

  setDeadlines(deadlines) {
    return LiferayClient.get(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/deadlines`, deadlines);
  };

  getContactInfoOptions() {
    return LiferayClient.get(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/contactInfo/options`);
  };

  getContactInfo(phase) {

    var url = `${this.warrantyApi}/housingcompanies/${this.getHcId()}/contactInfo/contact?`;

    if (phase) {
      url += 'phase=' + phase;
    }
    return LiferayClient.get(url);
  };

  setContactInfo(contactInfoId) {
    return LiferayClient.get(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/contactInfo/contact`, contactInfoId);
  };

  getApartment(aptId) {
    return LiferayClient.get(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptId}`);
  };

  setApartmentState(aptId, state) {
    return LiferayClient.put(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptId}/state`, state);
  };

  submitReport(aptId) {
    return LiferayClient.put(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptId}/state`, 'REPORT_SUBMITTED');
  };

  releaseLiability(aptId) {
    return LiferayClient.put(`${this.warrantyApi}/housingcompanies/${this.getHcId()}/apartments/${aptId}/state`, 'RELEASED_OF_LIABILITY');
  };

}
