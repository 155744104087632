import CongridWarrantyService from "../../services/CongridWarrantyService";
import { isPast, isPastDate } from "@/utils/DateHelper";
import store from "@/store";
import dayjs from "dayjs";
import { i18n } from "@/plugins/i18n";
import _ from "lodash";

const api = new CongridWarrantyService();

const noFaultCategoryName = "ei toimenpiteitä / no actions";

const getDefaultState = () => {
  return {
    warrantySelectedApartment: null,
    apartmentWarrantyDetails: null,
    warrantyFaults: [],
    selectedAptId: null,
    warrantyCategories: [],
    defaultCategories: {
      apartment: [
        {
          key: "hallway",
          name: "Eteinen",
          translationKey: "warranty.category.hallway",
        },
        {
          key: "kitchen",
          name: "Keittiö",
          translationKey: "warranty.category.kitchen",
        },
        {
          key: "bathroom",
          name: "Kylpyhuone",
          translationKey: "warranty.category.bathroom",
        },
        {
          key: "living-room",
          name: "Olohuone",
          translationKey: "warranty.category.living-room",
        },
        {
          key: "toilet",
          name: "WC",
          translationKey: "warranty.category.toilet",
        },
        {
          key: "balcony",
          name: "Parveke",
          translationKey: "warranty.category.balcony",
        },
        {
          key: "bedroom1",
          name: "Makuuhuone1",
          translationKey: "warranty.category.bedroom1",
        },
        {
          key: "bedroom2",
          name: "Makuuhuone2",
          translationKey: "warranty.category.bedroom2",
        },
        {
          key: "bedroom3",
          name: "Makuuhuone3",
          translationKey: "warranty.category.bedroom3",
        },
        {
          key: "bedroom4",
          name: "Makuuhuone4",
          translationKey: "warranty.category.bedroom4",
        },
      ],
      project: [],
    },
    warrantyStatuses: [
      {
        key: "PENDING",
        translationKey: "warranty.faultlist.fault.status.PENDING",
        visible: true,
      },
      {
        key: "RECEIVED",
        translationKey: "warranty.faultlist.fault.status.RECEIVED",
        visible: true,
      },
      {
        key: "WIP",
        translationKey: "warranty.faultlist.fault.status.WIP",
        visible: false,
      },
      {
        key: "INCOMPLETE",
        translationKey: "warranty.faultlist.fault.status.INCOMPLETE",
        visible: true,
      },
      {
        key: "COMPLETED",
        translationKey: "warranty.faultlist.fault.status.COMPLETED",
        visible: true,
      },
      {
        key: "VERIFIED",
        translationKey: "warranty.faultlist.fault.status.VERIFIED",
        visible: true,
      },
      {
        key: "ACCEPTED",
        translationKey: "warranty.faultlist.fault.status.ACCEPTED",
        visible: true,
      },
      {
        key: "REJECTED",
        translationKey: "warranty.faultlist.fault.status.REJECTED",
        visible: true,
      },
      {
        key: "FIX",
        translationKey: "warranty.faultlist.fault.status.FIX",
        visible: false,
      },
      {
        key: "WAIT",
        translationKey: "warranty.faultlist.fault.status.WAIT",
        visible: false,
      },
      {
        key: "FOLLOW",
        translationKey: "warranty.faultlist.fault.status.FOLLOW",
        visible: false,
      },

      {
        key: "NO_ACTIONS",
        translationKey: "warranty.faultlist.fault.status.NO_ACTIONS",
        visible: false,
      },
    ],
    housingCompanyCategories: [],
    apartmentCategories: [],
    isLiabilityReleased: false,
    repairStatus: "",
    adminParams: null,
    apartments: [],
    statistics: null,
  };
};

const state = getDefaultState();

const getters = {
  getApartmentWarrantyDetails: (state) => state.apartmentWarrantyDetails,
  getCongridWarrantySelectedApartment: (state) =>
    state.warrantySelectedApartment,
  getWarrantyFaults: (state) => state.warrantyFaults,
  getWarrantyCategories: (state) => (params) => {
    let categories = [];
    if (params.id && !params.apartmentId) {
      categories = state.defaultCategories.project;
      if (state.housingCompanyCategories.length)
        categories = categories.concat(state.housingCompanyCategories);
    } else if (params.id && params.apartmentId) {
      categories = state.defaultCategories.apartment;
      if (state.apartmentCategories.length) {
        let categoryNames = categories.map((c) => {
          return c.name;
        });
        state.apartmentCategories.map((c) => {
          if (!categoryNames.includes(c.name)) {
            categoryNames.push(c.name);
            categories.push(c);
          }
        });
      }
    }

    categories.map((c) => {
      if (c.translationKey) c.displayName = i18n.global.t(c.translationKey);
      else c.displayName = c.name;
      return c;
    });

    categories = _.sortBy(categories, ["displayName"]);

    //Add "other" always at bottom of the list
    categories.push({
      key: "other",
      name: "other",
      displayName: i18n.global.t("warranty.category.other"),
      translationKey: "warranty.category.other",
    });

    return categories;
  },
  getWarrantyHousingCompanyCategories: (state) =>
    state.housingCompanyCategories,
  getHousingCompanyCategories: (state) => state.housingCompanyCategories,
  getApartmentCategories: (state) => state.apartmentCategories,
  getApartmentFaultByCategory: (state) => (categoryName) => {
    if (state.warrantyFaults) {
      if (categoryName == "other") {
        /*let categoryNames = [];
        Object.values(state.warrantyCategories).map(c => {
          if (c.name != 'other')
            categoryNames.push(c.name);
        });*/
        return state.warrantyFaults.filter((item) => {
          if (item.description == noFaultCategoryName) return false;

          if (!item.targetName) return true;

          //!categoryNames.includes(item.targetName)
          if (!item.targetName || item.targetName == "other") return true;

          return false;
        });
      } else {
        return state.warrantyFaults.filter(
          (item) =>
            item.targetName == categoryName &&
            item.description != noFaultCategoryName
        );
      }
    }

    return [];
  },
  getApartmentFaultByFaultId: (state) => (id) => {
    console.log("getApartmentFaultByFaultId: ", id, state.warrantyFaults);
    if (state.warrantyFaults) {
      return state.warrantyFaults.find((item) => item.id === id);
    }

    return null;
  },
  isWarrantyReportingActive: (state) => {
    let currentProject = store.getters.getSelectedHousingCompany;
    if (currentProject) {
      let reportStartDate = currentProject.warrantyReportStartDate;
      let repairEndDate = currentProject.warrantyRepairEndDate;
      if (reportStartDate && repairEndDate) {
        return !isPastDate(repairEndDate) && isPast(reportStartDate);
      }
    }
    return false;
  },
  isWarrantyProcessActive: (state) => {
    //Idea here to return date when warranty process has ended (also in relation for notifications)
    let currentProject = store.getters.getSelectedHousingCompany;
    if (currentProject.warrantyRepairProcessEndDate)
      return !isPastDate(currentProject.warrantyRepairProcessEndDate);

    return true;
  },
  isWarrantyPeriodActive: (state) => {
    let warrantyPeriodEndDate = null;
    let currentProject = store.getters.getSelectedHousingCompany;
    if (currentProject && currentProject.warrantyPeriodEndDate)
      warrantyPeriodEndDate = currentProject.warrantyPeriodEndDate;
    if (warrantyPeriodEndDate) {
      return !isPastDate(warrantyPeriodEndDate);
    }

    return false;
  },
  isWarrantyReportingStarted: (state) => {
    let currentProject = store.getters.getSelectedHousingCompany;
    if (currentProject && currentProject.warrantyReportStartDate) {
      let reportStartDate = currentProject.warrantyReportStartDate;
      if (reportStartDate) {
        return isPast(reportStartDate);
      }
    }
    return false;
  },
  getProjectWarrantyDeadlines() {
    let project = store.getters.getSelectedHousingCompany;
    if (project)
      return {
        warrantyReportStartDate: project.warrantyReportStartDate,
        warrantyReportEndDate: project.warrantyReportEndDate,
        warrantyRepairMeetingDate: project.warrantyRepairMeetingDate,
        warrantyRepairNoticeInfoDate: project.warrantyRepairNoticeInfoDate,
        warrantyRepairEndDate: project.warrantyRepairEndDate,
        warrantyPeriodEndDate: project.warrantyPeriodEndDate,
        warrantyContacts: project.warrantyContacts,
      };
    return null;
  },
  getWarrantyAdminSelectedAptId: (state) => state.selectedAptId,
  canReportWarrantyFaults() {
    let currentProject = store.getters.getSelectedHousingCompany;
    if (currentProject) {
      const reportStart = dayjs(currentProject.warrantyReportStartDate)
        .set("hour", 0)
        .set("minute", 0)
        .set("seconds", 0);
      const reportEnd = dayjs(currentProject.warrantyReportEndDate)
        .set("hour", 23)
        .set("minute", 59)
        .set("seconds", 59);
      // const repairNoticeInfo = dayjs(currentProject.warrantyRepairNoticeInfoDate)
      //   .set("hour", 23)
      //   .set("minute", 59)
      //   .set("seconds", 59);
      const repairMeeting = dayjs(currentProject.warrantyRepairMeetingDate)
        .set("hour", 0)
        .set("minute", 0)
        .set("seconds", 0);
      const repairEnd = dayjs(currentProject.warrantyRepairEndDate)
        .set("hour", 23)
        .set("minute", 59)
        .set("seconds", 59);

      let now = dayjs().valueOf();

      //console.log("canReportWarrantyFaults: ", reportEnd.toString(), repairEnd.toString(), (now > reportEnd.valueOf() && now < repairNoticeInfo.valueOf()) || now > repairEnd.valueOf());

      //if before report start, cannot
      // if (now < reportStart.valueOf()) return false;

      //if past report end, cannot
      // if (now > reportEnd.valueOf()) return false;

      //if between report end and repair notice info, cannot
      if (now > reportEnd.valueOf() && now < repairMeeting.valueOf()) return false;

      /*if (
        (now >= reportStart.valueOf() && now <= reportEnd.valueOf()) ||
        (now >= repairNoticeInfo.valueOf() && now <= repairEnd.valueOf())
      )*/
      if (now >= reportStart.valueOf() && now <= repairEnd.valueOf())
        return true;
    }

    return false;
  },
  isApartmentMarkedCheckedWithoutFaults: (state) => {
    if (state.warrantyFaults && state.warrantyFaults.length === 1) {
      if (state.warrantyFaults[0].description == noFaultCategoryName)
        return true;
    }
    return false;
  },
  getCurrentHousingCompanyRepairStatus: (state) => state.repairStatus,
  getWarrantyStatuses: (state) =>
    state.warrantyStatuses.filter((s) => s.visible),
  getWarrantySearchAdminParams: (state) => state.adminParams,
  getReportDownloadUrl:
    (state) =>
    (params = {}) => {
      return `${process.env.VUE_APP_APIM_PUBLIC_API_URL}/congridReport?code=${
        process.env.VUE_APP_WARRANTY_REPORT_API_KEY
      }&id=${
        params.hcExtId
          ? params.hcExtId
          : store.getters.getSelectedHousingCompany.hcExt
      }&type=${params.type ? params.type : "owners,details"}`;
    },
  getProjectApartmentsWithWarrantyData: (state) => state.apartments,
  getApartmentWarrantyData: (state) => (apartmentId) => {
    return state.apartments.find((apt) => apt.externalId === apartmentId);
  },
  getHousingCompanyWarrantyStatistics: (state) => state.statistics,
};

const actions = {
  async fetchApartmentWarrantyDetails({ commit }, aptId) {
    try {
      let res = await api.getApartmentWarrantyDetails(aptId);
      console.log("fetched apartment warranty details: ", res.data);
      if (res && res.data) {
        commit("setApartmentContactDetails", res.data);
        return res.data;
      } else commit("setApartmentContactDetails", null);

      return null;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async updateApartmentWarrantyDetails({ commit }, params) {
    if (!params.apartmentId) throw "Missing property apartmentId";
    if (!params.payload) throw "Missing property payload";

    return new Promise((resolve, reject) => {
      api
        .updateApartmentWarrantyDetails(params.apartmentId, params.payload)
        .then((response) => {
          console.log("updated apartment warranty details.");
          commit("appendApartmentContactDetails", response.data);
          resolve(response);
        })
        .catch((err) => {
          console.log("error updating new contact info", err);
          reject(err);
        });
    });
  },
  async addWarrantyFault({ commit }, fault) {
    try {
      let res = await api.addFault(fault);
      if (res && res.data) {
        commit("appendWarrantyFaults", res.data);
      }
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async updateWarrantyFault({ commit }, fault) {
    try {
      let res = await api.updateFault(fault);
      commit("updateWarrantyFault", fault); //quick hack to get automatically fetch notes..
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async deleteWarrantyFault({ commit }, fault) {
    try {
      let res = await api.deleteFault(fault);
      commit("deleteWarrantyFault", fault);
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  fetchWarrantyFaults({ commit }, params) {
    return new Promise(function (resolve, reject) {
      if (params.aptId == "all_apartments") {
        params.apartmentFaultsOnly = true;
        delete params.aptId;
      } else params.apartmentFaultsOnly = false;

      if (params.aptId == "common_space_only") {
        params.commonSpaceFaultsOnly = true;
        delete params.aptId;
      } else params.commonSpaceFaultsOnly = false;

      api
        .getFaults(params)
        .then((response) => {
          commit(
            "setWarrantyFaults",
            Array.isArray(response.data) ? response.data : []
          );
          resolve(response.data);
        })
        .catch((err) => {
          console.log(
            "Fetching warranty getApartmentContactInfo failed: ",
            err
          );
          reject(err);
        });
    });
  },
  fetchWarrantyFault({ commit }, params) {
    return new Promise(function (resolve, reject) {
      if (!params || params.id) reject("Missing required parameters");

      api
        .getFault(params)
        .then((response) => {
          commit("mergeInWarrantyFaults", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          console.log(
            "Fetching warranty getApartmentContactInfo failed: ",
            err
          );
          reject(err);
        });
    });
  },
  async fetchWarrantyTargets({ commit }, params) {
    //Fetch targets for building
    if (params.id && !params.aptId) {
      try {
        let categories = [];
        let ret = await api.getTargets(params);
        if (ret && ret.data && ret.data.subTargets) {
          categories = ret.data.subTargets.filter(
            (area) => area.targetType == "Tila" && area.name != "other"
          );
        }
        commit("setWarrantyTargetsForHousingCompany", categories);
        return categories;
      } catch (err) {
        console.log(err);
        throw err;
      }
    }

    //Fetch targets for single apartment
    else if (params.id && params.aptId) {
      try {
        let categories = [];
        let ret = await api.getTargets(params);
        if (ret && ret.data && ret.data.subTargets) {
          categories = ret.data.subTargets.filter(
            (area) => area.targetType == "Tila" && area.name != "other"
          );
        }
        commit("setWarrantyTargetsForApartment", categories);
        return categories;
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  },
  async fetchHousingCompanyRepairStatus({ commit }, params) {
    if (!params || !params.id) {
      let currentHc = store.getters.getSelectedHousingCompany;
      if (!currentHc) return;

      params.id = currentHc.hcExt;
    }

    if (!params || !params.aptId) {
      let currentApt = store.getters.getSelectedApartment;
      if (currentApt && currentApt.aptext) params.aptId = currentApt.aptext;
    }

    try {
      let ret = await api.getRepairStatus(params);
      let repairStatus = "";
      if (ret && ret.data && ret.data.status) {
        repairStatus = ret.data.status;
      }
      commit("setHousingCompanyRepairStatus", repairStatus);
      return repairStatus;
    } catch (err) {
      console.log(err);
    }
  },
  async fetchProjectApartmentsWithWarrantyData({ commit }, params) {
    try {
      let res = await api.getApartmentAndOwnerReport(params);
      let results = Array.isArray(res.data) ? res.data : [];

      //set status key
      results = results.map((r) => {
        r.statusKey = "INPROGRESS";

        if (r.completed === true) {
          r.statusKey = "ACCEPTED";
        } else {
          if (
            !r.warrantyDetails ||
            typeof r.warrantyDetails.tenantContactInformation == "undefined"
          )
            r.statusKey = "OPEN";
        }

        return r;
      });

      commit("setProjectApartmentsWithWarrantyData", results);
      return res;
    } catch (err) {
      console.log(err);
      commit("setProjectApartmentsWithWarrantyData", []);
    }
  },
  async fetchHousingCompanyWarrantyStatistics({ commit }, params = {}) {
    try {
      let res = await api.getApartmentAndOwnerReport({
        id: params.id,
        type: "statistics",
        dataType: "json",
      });
      if (res.data && res.data.statistics) {
        commit("setHousingCompanyWarrantyStatistics", res.data.statistics);
      } else {
        commit("setHousingCompanyWarrantyStatistics", null);
      }
    } catch (err) {
      console.log(err);
    }
  },
  async confirmWarrantyFault({ commit }, note) {
    await api.acceptRepair(note);
    commit("markNoteAccepted", note);
  }
};

const mutations = {
  setApartmentContactDetails: (state, res) =>
    (state.apartmentWarrantyDetails = res),
  appendApartmentContactDetails: (state, res) => {
    if (!state.apartmentWarrantyDetails) state.apartmentWarrantyDetails = res;
    else
      state.apartmentWarrantyDetails = Object.assign(
        state.apartmentWarrantyDetails,
        res
      );
    console.log(
      "Appending warranty contact details: ",
      state.apartmentWarrantyDetails
    );
  },
  appendWarrantyFaults: (state, res) =>
    (state.warrantyFaults = state.warrantyFaults.concat(res)),
  setWarrantyFaults: (state, res) => (state.warrantyFaults = res),
  mergeInWarrantyFaults: (state, res) => {
    if (state.warrantyFaults.length) {
      state.warrantyFaults = state.warrantyFaults.map((f) => {
        if (f.id === res.id) {
          Object.keys(res).map((key) => {
            if (res[key]) f[key] = res[key];
          });
        }
        return f;
      });
    } else {
      state.warrantyFaults.push(res);
    }
  },
  updateWarrantyFault: (state, res) => {
    state.warrantyFaults = state.warrantyFaults.map((fault) => {
      if (fault.id === res.noteId) {
        fault.name = res.name;
        fault.description = res.description;
      }

      return fault;
    });
  },
  deleteWarrantyFault: (state, res) => {
    state.warrantyFaults = state.warrantyFaults.filter(
      (f) => f.id !== res.noteId
    );
  },
  truncateWarrantyFaults: (state, res) => {
    state.warrantyFaults = [];
  },
  getCongridWarrantySelectedApartment: (state, res) =>
    (state.warrantySelectedApartment = res),
  setWarrantyAdminSelectedAptId: (state, res) => (state.selectedAptId = res),
  setWarrantyTargetsForHousingCompany: (state, res) =>
    (state.housingCompanyCategories = res),
  setWarrantyTargetsForApartment: (state, res) =>
    (state.apartmentCategories = res),
  resetCongridWarrantyState: (state) => {
    state.warrantySelectedApartment = null;
    state.apartmentWarrantyDetails = null;
    state.warrantyFaults = [];
    state.selectedAptId = null;
    state.warrantyCategories = [];
    state.defaultCategories;
    state.housingCompanyCategories = [];
    state.apartmentCategories = [];
    state.isLiabilityReleased = false;
    state.repairStatus = "";
    state.apartments = [];
    state.statistics = null;
    state.adminParams = null;
  },
  setHousingCompanyRepairStatus: (state, res) => (state.repairStatus = res),
  setWarrantySearchAdminParams: (state, res) => (state.adminParams = res),
  setProjectApartmentsWithWarrantyData: (state, res) =>
    (state.apartments = res),
  setHousingCompanyWarrantyStatistics: (state, res) => (state.statistics = res),
  markNoteAccepted: (state, note) => {
    state.warrantyFaults = state.warrantyFaults.map(fault => {
      console.log("accepting ", fault, note);
      if (note.noteId === fault.id) {
        console.log("MARKED ACCEPTED!!");
        fault.statusId = "ACCEPTED";
      }
      return fault;
    })
  }
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations,
};
