<template>
  <DefaultLayout>
    <div class="page-section">
      <div class="page-section__body">
        <BackButton />
        <PageWrapper>
          <slot></slot>
        </PageWrapper>
      </div>
      <div class="page-section__footer" v-if="$route.meta && $route.meta.title"></div>
    </div>
  </DefaultLayout>
</template>
<script>
import PageWrapper from "@/components/common/PageWrapper";
import DefaultLayout from "./Default";

export default {
  name: "LayoutSubpage",
  components: {
    DefaultLayout,
    PageWrapper
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/variables";
</style>