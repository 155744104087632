import settings from "@/globals";
import store from "@/store";
import { getUniqueListBy } from "./ArrayUtil";

const attachmentErrorCodes = {
  FILE_SIZE: "file_size",
  MIME_TYPE: "mime_type",
  FILE_EXTENSION: "file_extension",
  ILLEGAL_CHARACTERS: "illegal_characters",
};

const ILLEGAL_CHARACTERS = ['\\'];

/**
 *
 * @param {*} file
 */
const readFileAsync = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

/**
 *
 * @param {*} file
 */
const readFileAsyncWithValidation = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      let check = checkUploadedAttachment(file);
      if (check === true) resolve(reader.result);
      else reject(check);
    };
    reader.onerror = reject;
    //reader.readAsArrayBuffer(file);
    reader.readAsDataURL(file);
  });
};

/**
 * 
 * @param {*} value 
 */
const prepareFileName = value => {
  //console.log("prepareFileName called ", value);
  let regex = /[$&?"]/g;
  //console.log("prepareFileName output ", value.replace(regex,'').replace(String.fromCharCode(92), ""));
  return value.replace(regex,'').replace(String.fromCharCode(92), "");
}

/**
 *
 * @param {*} file
 */
const checkUploadedAttachment = file => {
  if (file.size > settings.attachments.maxSizeInBytes) {
    return {
      error: true,
      code: attachmentErrorCodes.FILE_SIZE
    };
  }

  let ret = false;

  //check file extension and illegal characters
  let extensionMatch = false;
  let hasIllegalCharacters = false;
  if (file.name) {
    
    //check illegal characters
    ILLEGAL_CHARACTERS.map(illegalCharacter => {
      if (file.name.includes(illegalCharacter))
        hasIllegalCharacters = true;
    });

    if (hasIllegalCharacters)
      return {
        error: true,
        code: attachmentErrorCodes.FILE_EXTENSION
      }

    let parts = file.name.split('.');
    if (parts.length > 1) {
      let extension = parts[parts.length - 1].toLowerCase().trim();
      if (extension) {
        settings.attachments.allowedFileExtensions.map(rule => {
          if (extension == rule)
            extensionMatch = true;
        })

        //YIT user and admin can upload also zip-files
        let archiveExtensions = ['zip', 'rar', 'tar'];
        let archiveWhiteListedRoles = ['yitUser', 'admin'];
        if (archiveExtensions.includes(extension) && !extensionMatch && store.getters.getCurrentUser.hasAnyOfRoles(archiveWhiteListedRoles)) {
          extensionMatch = true;
        }
      }
    }
  }

  if (extensionMatch) {
    return true;
  }

  return {
    error: true,
    code: attachmentErrorCodes.FILE_EXTENSION
  };
  

  return true;
};

/**
 *
 * @param {*} file
 */
const checkUploadedImage = file => {
    if (file.size > settings.imageFiles.maxSizeInBytes) {
      return {
        error: true,
        code: attachmentErrorCodes.FILE_SIZE
      };
    }
  
    if (!file.type) {
      return {
        error: true,
        code: attachmentErrorCodes.MIME_TYPE
      };
    }
  
    let ret = false;
    let checkMime = file.type.toLowerCase().trim();
    settings.imageFiles.allowedMimeTypes.map(rule => {
      if (checkMime.includes(rule)) {
        ret = true;
      }
    });
    if (!ret) {
      return {
        error: true,
        code: attachmentErrorCodes.MIME_TYPE
      };
    }
  
    return true;
  };

/**
 * 
 * @param {string} filename 
 * @param {any[]} apartments 
 * @returns 
 */
const mapFileToApartmentFolders = (filename, apartments) => {
  const genericApt = (apt) => String(apt).replace(/\.|\s/g, "")
  const findBest = (apt) => {
    const match = apartments.find(it => {
      if (apt === it.no) {
        return true
      } else if (apt === it.visibleApartmentNumber) {
        return true
      } else if (String(apt).toLowerCase() === String(it.externalName).toLowerCase()) {
        return true
      } else if (genericApt(apt).toLowerCase() === String(it.no).toLowerCase()) {
        return true
      } else if (String(apt).toLowerCase() === genericApt(it.no).toLowerCase()) {
        return true
      } else if (genericApt(apt).toLowerCase() === String(it.visibleApartmentNumber).toLowerCase()) {
        return true
      } else if (String(apt).toLowerCase() === genericApt(it.visibleApartmentNumber).toLowerCase()) {
        return true
      } else if (genericApt(apt).toLowerCase() === genericApt(it.no).toLowerCase()) {
        return true
      } else if (genericApt(apt).toLowerCase() === genericApt(it.visibleApartmentNumber).toLowerCase()) {
        return true
      } else if (genericApt(apt).toLowerCase() === genericApt(it.externalName).toLowerCase()) {
        return true
      }
      return false
    })
    if (match) {
      return {
        no: match.no,
        externalId: match.externalId
      }
    }
  }
  const aptArray = filename.split("__").slice(0, -1)
  const apts = aptArray.map(findBest).filter(it => it && it.no) || []
   // get actual filename
  const filenameWithoutApt = filename.split("__").pop()
  return {
    apts: getUniqueListBy(apts, "no"),
    filename: filenameWithoutApt
  }
}

export {
  readFileAsync,
  readFileAsyncWithValidation,
  checkUploadedAttachment,
  checkUploadedImage,
  prepareFileName,
  mapFileToApartmentFolders
}
