import { createApp } from 'vue';
import SettingsPlugin from "@/plugins/global";
import { i18n } from "@/plugins/i18n";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import ability from "./config/ability";
import { abilitiesPlugin } from "@casl/vue";
import "./layouts.js";
import "./filters";
import "@fortawesome/fontawesome-pro/css/all.min.css";
//import '@/assets/fontawesome-pro-5.8.2/css/all.min.css';
import "bootstrap/scss/bootstrap-grid.scss";
import "@/assets/scss/main.scss";
import { Cloudinary } from "@cloudinary/url-gen";
import LoadScript from 'vue-plugin-load-script';
import Layouts from "@/layouts";
import closable from "@/directives/closable"
import unescape from "@/directives/unescape"
import linkify from "@/directives/linkify"

//Prevent application starting multiple times
let applicationStarted = false;

export function start() {
  console.log("YIT Plus SPA application started");

  /* send information to GTM when application is being bootstrapped */
  if(window.dataLayer) {
    window.dataLayer.push({
      event: "application_started",
      eventCategory: "Application",
      eventAction: "Start",
      eventLabel: "Application started"
    });
  }

  if(!applicationStarted) {
    applicationStarted = true;

    //Hide the loading icon
    let loadingIcon = document.getElementById("yit-startpage-loader-icon");
    if (loadingIcon) {
      loadingIcon.classList.add("hide");
    }
    setTimeout(() => {
      //Vue.config.productionTip = false;

      //the next line is added for debugging purposes and should be removed for production build
      if (process.env.VUE_APP_ENV == "local") window.ability = ability;

      const app = createApp(App);
      app.use(SettingsPlugin)
      app.use(Layouts)
      app.use(LoadScript)
      app.use(store)
      app.use(router)
      app.use(i18n)
      app.use(linkify)
      app.use(closable)
      app.use(unescape)

      //Cloudinary initialization
      app.use(new Cloudinary, {
        cloud: {
          cloudName: process.env.VUE_APP_CLOUDINARY_CLOUDNAME,
        }
      });

      app.use(abilitiesPlugin, ability, {
        useGlobalProperties: true
      });

      app.mount("#app");

    }, 150);
  }
}
