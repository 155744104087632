<template>
  <transition :name="!isDesktop ? 'sliderightslowly' : ''">
    <div class="container-area bg--white panel-mobile" v-if="visible">
      <div class="panel-mobile__header">
        <slot name="mobileHeader">
          <button class="button button--link back-button" @click="$router.go(-1)">
            <i class="yit-icon far fa-chevron-left"></i>
            {{$t('common.backButton')}}
          </button>
        </slot>
      </div>
      <div class="panel-mobile__content">
        <slot></slot>
      </div>
      <div class="panel-mobile__footer" v-if="hasFooter">
        <slot name="footer"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PageWrapper",
  data() {
    return {
      visible: false
    };
  },
  computed: {
    isDesktop: () => {
      if (window.innerWidth > 992) {
        return true;
      }

      return false;
    },
    hasFooter() {
      return !!this.$slots.footer;
    }
  },
  mounted() {
    setTimeout(() => {
      this.visible = true;
    }, 1);
  }
};
</script>
<style lang="scss" scoped>
.back-button {
  i {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    i {
      transform: translateX(-0.4rem);
    }
  }
  border-bottom: none;
}
</style>
