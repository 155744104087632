import store from "@/store";
import { i18n } from "@/plugins/i18n";
import Vue from "vue";
import MobileAppUtil from "@/utils/MobileAppUtil";

let pageTitle = {
  siteName: "YIT Plus",
  mainSection: "",
  subSection: "",
  mainSectionKey: "",
  subSectionKey: ""
};

const buildPageMeta = route => {
  let pageSectionTitle = "";
  let subSectionTitle = "";
  let mainSectionKey = "";
  let subSectionKey = "";

  //Determine the page main and sub section titles
  if (route.meta) {
    if (route.meta.sectionTitle) {
      pageSectionTitle = i18n.global.t(route.meta.sectionTitle);
      mainSectionKey = route.meta.sectionTitle;
      if (route.meta.title) {
        //if main section title and meta title are the same, assume it's main section
        if (pageSectionTitle != i18n.global.t(route.meta.title)) {
          subSectionTitle = i18n.global.t(route.meta.title);
          subSectionKey = route.meta.title;
        }
      }
    } else {
      if (route.meta.title) {
        pageSectionTitle = i18n.global.t(route.meta.title);
        mainSectionKey = route.meta.title;
      }
    }
  }

  pageTitle.mainSection = pageSectionTitle;
  pageTitle.subSection = subSectionTitle;
  pageTitle.mainSectionKey = mainSectionKey;
  pageTitle.subSectionKey = subSectionKey;

  updateDocumentTitle();
  sendDataLayer(route);
};

const sendDataLayer = (route, options = null) => {
  if (window.dataLayer) {
    let userDetails = store.getters.getCurrentUser;
    let hc = store.getters.getSelectedHousingCompany;

    let mobileAppInfo = MobileAppUtil.getMobileAppState();
    
    i18n.locale = 'en';

    let payload = {
      event: "page_view",
      page_path: route.path,
      page_title_unlocalized: buildBaseLanguageDocumentTitle(),
      housing_company: store.getters.getSelectedHousingCompany.name,
      user_id: userDetails.id,
      user_role: userDetails.roles ? userDetails.roles.toString() : null,
      user_city: hc.addressCity ? hc.addressCity : null,
      user_area: hc.marketingName ? hc.marketingName : null,
      user_country: hc.addressCountry ? hc.addressCountry : null,
      user_bu: hc.businessUnit ? hc.businessUnit : null,
      page_category: null,
      page_section: pageTitle.mainSection ? pageTitle.mainSection : null,
      page_subsection: pageTitle.subSection ? pageTitle.subSection : null,
      page_language: store.getters.getLang,
      partner_company: null
    };

    i18n.locale = store.getters.getLang;

    if (mobileAppInfo) {
      if (mobileAppInfo.platform)
        payload.mobile_app_platform = mobileAppInfo.platform;
      if (mobileAppInfo.version)
        payload.mobile_app_version = mobileAppInfo.version;
    }

    if (options && typeof options == 'object' && Object.values(options).length) {
      payload = Object.assign(payload, options);
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(payload);
  }
};

/**
 * Update page title
 * @param {*} mainSection
 * @param {*} subSection
 */
const setPageTitle = (mainSection = "", subSection = "") => {
  pageTitle.mainSection = mainSection;
  pageTitle.subSection = subSection;
  updateDocumentTitle();
};

/**
 * Update sub page title
 * @param {*} subSection
 */
const setPageSubTitle = (title = "") => {
  pageTitle.subSection = title;
  updateDocumentTitle();
};

const sendPageLoadToGTM = (route, mainSection = "", subSection = "", datalayerOptions = null) => {
  if(mainSection || !subSection)
    pageTitle.mainSection = mainSection;
  
  pageTitle.subSection = subSection;
  updateDocumentTitle();
  sendDataLayer(route, datalayerOptions);
}

const buildDocumentTitle = () => {
  let title = "";

  if (pageTitle.mainSection && pageTitle.subSection)
    title = `${pageTitle.subSection} - ${pageTitle.mainSection} - ${pageTitle.siteName}`;
  else if (pageTitle.mainSection && !pageTitle.subSection)
    title = `${pageTitle.mainSection} - ${pageTitle.siteName}`;
  else title = pageTitle.siteName;

  return title;
}

const buildBaseLanguageDocumentTitle = () => {
  let title = "";

  if (pageTitle.mainSectionKey && pageTitle.subSectionKey)
    title = `${i18n.global.t(pageTitle.subSectionKey)} - ${i18n.global.t(pageTitle.mainSectionKey)} - ${pageTitle.siteName}`;
  else if (pageTitle.mainSectionKey && !pageTitle.subSectionKey)
    title = `${i18n.global.t(pageTitle.mainSectionKey)} - ${i18n.global.t(pageTitle.siteName)}`;
  else title = pageTitle.siteName;

  return title;
}

/**
 * Updates the document title
 */
const updateDocumentTitle = () => {
  document.title = buildDocumentTitle();
};

export { buildPageMeta, setPageTitle, setPageSubTitle, sendDataLayer, sendPageLoadToGTM };
