<template>
  <div class="atp-list" v-if="items && items.length">
    <div class="news-list--latest row" v-if="mode=='list'">
      <div class="col-md-6" v-for="item in getItems" :key="item.id">
        <ArticleListItem
          :title="getReformString(item.title.rendered, 80)"
          :url="{name: '/isannointiliitto/:id/:slug', params:{id: item.id, slug: item.slug}}"
          :thumbnailSrc="item.featured_image_path_medium ? item.featured_image_path_medium : ''"
          thumbnailSize="medium"
          :date="item.date"
          :useKenticoThumbnailer="false"
          mode="box"
        />
      </div>
    </div>
    <div class="news-list--archive" v-else>
      <div class="news-list__item" v-for="item in getItems" :key="item.id">
        <ArticleListItem
          :title="item.title.rendered"
          :url="{name: '/isannointiliitto/:id/:slug', params:{id: item.id, slug: item.slug}}"
          :thumbnailSrc="item.featured_image_path ? item.featured_image_path : ''"
          thumbnailSize="large"
          :ctaButtonText="$t('common.readmore.link')"
          :date="item.date"
          :useKenticoThumbnailer="true"
          :description="item.excerpt && item.excerpt.rendered ? item.excerpt.rendered.substring(0,300) : ''"
          :showArrow="false"
          mode="box"
        >
          <template v-slot:footer>
            <button class="button button--link" @click="openLink(item)">{{$t('common.readmore.link')}}</button>
          </template>
        </ArticleListItem>
      </div>
    </div>
  </div>
  <div class v-else-if="!loading && !items.length">
    <span class="no-results">{{$t('common.no-items-found')}}</span>
  </div>
  <div class="text-center" v-else-if="loading">
    <LoaderIcon />
  </div>
</template>

<script>
import ArticleListItem from "@/components/common/Lists/Article";
import {getReformString} from "@/utils/StringUtils";

export default {
    name: "ATPList",
    props: {
      items: {
        type: Array
      },
      mode: {
        type: String,
        default: 'list'
      },
      limit: {
        type: Number,
        default: 4
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    components: {
      ArticleListItem
    },
    computed: {
      getItems() {
        return this.items.slice(0,this.limit);
      }
    },
    methods: {
      openLink(item) {
        this.$router.push({name: '/isannointiliitto/:id/:slug', params:{id: item.id, slug: item.slug}})
      },
      getReformString
    }
}
</script>

<style>

</style>