import { defineAbility, AbilityBuilder, Ability } from "@casl/ability";
import communityRules from "./ability/community";
import userProfileRules from "./ability/user";
import contentRules from "./ability/content";
import serviceRequestRules from "./ability/service-requests";
import documentsRules from "./ability/documents";
import userManagementRules from "./ability/user-management";
import IOTRules from "./ability/iot";
import projectRules from "./ability/project";
import consumptionRules from "./ability/consumption";
import partnerCompaniesRules from "./ability/partner-companies";
import warrantyRules from "./ability/warranty";
import emailLetterRules from "./ability/email-letters";
import featureToggleRules from "./ability/features";

const abilityRules = [
  communityRules,
  userProfileRules,
  contentRules,
  serviceRequestRules,
  documentsRules,
  userManagementRules,
  IOTRules,
  projectRules,
  consumptionRules,
  partnerCompaniesRules,
  warrantyRules,
  emailLetterRules,
  featureToggleRules
];

/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
function subjectName(item) {
  let type = null;
  //if (typeof item == "object") console.log("ABILITY CHECK: ", item);
  if (!item || typeof item === "string") {
    type = item;
  }
  //Azure items
  if (item._type) type = item._type;
  //Azure items as well
  if (item.__t) type = item.__t;
  //Kentico items
  if (item.system && item.system.type) {
    //console.log("IM FROM KENTICO!", item.system.type);
    type = item.system.type;
  }

  if (typeof item === 'object' && item.hasOwnProperty('firstName') && typeof item.hasOwnProperty('lastName') && item.hasOwnProperty('email') && item.hasOwnProperty('accountEnabled')) {
    type = 'user';
  }

  if (type) return type.toLowerCase();

  return "null";
}

const { build } = new AbilityBuilder(Ability);

const ability = build({ detectSubjectType: subjectName });
const defineAbilitiesFor = user => {
  console.log("defineAbilitiesFor was called: ", user);
  const { can, cannot, rules } = new AbilityBuilder();
  if (user) {

    //Administrator
    if (user.hasRole("admin")) {
      can("manage", "all");
    }

    //loop through all the ability rule modules
    abilityRules.map(f => f(user, { can, cannot, rules }));

  }
  ability.update(rules);
};

export default ability;
export { ability, defineAbilitiesFor };
