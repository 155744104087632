import store from "@/store/index";
import BaseService from "../BaseService";
import { AzureFunctionClient, AzureFunctionClientWithRetry } from "../AzureFunctionClient";
import { prepareFileName } from "@/utils/FileUtil";

export default class DocumentService extends BaseService {
  constructor() {
    super();
  }

  /**
   * Fetch user documents and folders
   */
  async fetchDocuments(params = null) {
    let req = {};
    let lang = store.getters.getLang;

    if (params) req = Object.assign({}, params);

    if (!req.hcExtId)
      req.hcExtId = store.getters.getSelectedHousingCompany.hcExt;

    if (!req.aptExtId && store.getters.getSelectedApartment && store.getters.getSelectedApartment.aptext)
      req.aptExtId = store.getters.getSelectedApartment.aptext;

    if (lang) req.language = lang;

    //req.userUuid = store.getters.getCurrentUser.id;

    if (!req.hcExtId)
      return Promise.reject("You need to clarify project id to get documents.");

    let query = this._buildUrl(`/listProjectFiles`, req);
    return AzureFunctionClient.get(query);
  }

  /**
   * Fetch user documents and folders
   */
  deleteDocument(id) {
    let params = {
      _id: id,
    };
    let query = this._buildUrl(`/deleteFile`, params);

    return AzureFunctionClient.delete(query);
  }

  /**
   * 
   * @param {*} id - Upload batch id
   * @returns 
   */
  deleteDocumentsByBatchId(id) {
    return AzureFunctionClient.delete(`/deleteFilesByBatchId/${id}`);
  }

  /**
   * Add document
   * @param {*} object form data
   */
  addDocument(params = {}) {
    if (!params.hcExtId)
      params.hcExtId = store.getters.getSelectedHousingCompany.hcExt;

    return new Promise((resolve, reject) => {
      let urlParams = {
        hcExtId: params.hcExtId,
        folder: params.folder,
        fileMeta: params.file.type,
        fileName: prepareFileName(params.file.name),
        fileSize: params.file.size,
      };
      if (params.aptExtId) urlParams.aptExtId = params.aptExtId;

      if (params.meta) {
        if (params.meta.homeFolder === true || params.meta.homeFolder == "true")
          urlParams.metaHomeFolder = true;
        if (params.meta.tags) urlParams.metaTags = params.meta.tags.toString();
      }

      if (params.notify_users) urlParams.notify_users = 1;

      if (params.uploadBatchId) urlParams.uploadBatchId = params.uploadBatchId;

      let query = this._buildUrl("/addFileMultipart", urlParams);

      if (
        !params.file ||
        (!params.folder && !params.folderPath) ||
        !params.hcExtId
      ) {
        console.log("addDocument request params: ", params);
        reject("Mandatory params not set to addDocument request");
        return;
      }

      let postData = new FormData();
      postData.append("file", params.file);

      let result = AzureFunctionClientWithRetry.post(query, postData, {
        "Content-Type": "multipart/form-data",
        timeout: 300000,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  /**
   * 
   * @param {*} buffer 
   * @param {*} fileInfo 
   * @param {*} params 
   */
  async addDocumentFromBlob(buffer, fileInfo = {}, params = {}) {
    if (!params.hcExtId) params.hcExtId = store.getters.getSelectedHousingCompany.hcExt;

    let urlParams = {
      hcExtId: params.hcExtId,
      folder: params.folder,
      fileMeta: fileInfo.type,
      fileName: prepareFileName(fileInfo.filename)
    };
    if(params.aptExtId)
      urlParams.aptExtId = params.aptExtId;

    if(params.meta) {
      if(params.meta.homeFolder === true || params.meta.homeFolder == 'true') 
        urlParams.metaHomeFolder = true;
      if(params.meta.tags) 
        urlParams.metaTags = params.meta.tags.toString();
    }

    if(params.aptExtId)
      urlParams.aptExtId = params.aptExtId;

    if (params.notify_users)
      urlParams.notify_users = 1;
    
    let query = this._buildUrl("/addFileMultipart", urlParams);

    if(!fileInfo.filename || (!params.folder && !params.folderPath) || !params.hcExtId) {
      console.log("addDocument request params: ",params);
      throw 'Mandatory params not set to addDocumentFromBlob request';
      return;
    }

    let postData = new FormData();
    postData.append('file', buffer, fileInfo);

    return AzureFunctionClient.post(query, postData, {
      'Content-Type': 'multipart/form-data',
      timeout: 300000,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }

   /** 
    * Fetch file meta data
   */
  getDocumentMetaData(id) {
    let params = {
      _id: id,
    };
    let query = this._buildUrl(`/getFileMetaData`, params);

    return AzureFunctionClient.get(query);
  }

  /**
   * Fetch file meta data
   * @param {string} path
   * @param {object} payload
   */
  updateMetaData(id, payload) {
    if (!id) throw "You must specify document id to update document meta data.";

    let params = {
      _id: id,
    };
    let query = this._buildUrl(`/updateFileMetaData`, params);

    return AzureFunctionClientWithRetry.put(query, payload);
  }

  /**
   *
   * @param {String} id - Array of file object ids
   */
  async download(id = null, params = {}) {
    if (!id) throw "You need to provide id in order to download a file";

    let query = this._buildUrl(`/readFile`, Object.assign({ _id: id }, params));

    try {
      let fileBuffer = await AzureFunctionClient.get(query, {
        responseType: "blob",
        timeout: 300000
      });

      let fileName = "";
      let fileType = "";
      if (fileBuffer.headers && fileBuffer.headers["content-disposition"]) {
        fileName = fileBuffer.headers["content-disposition"]
          .split("filename=")[1]
          .replaceAll('"', "")
          .replaceAll("'", "");
      }
      if (fileBuffer.headers && fileBuffer.headers["content-type"]) {
        fileType = fileBuffer.headers["content-type"];
      }

      console.log("download file: ", fileName, fileType);

      let fileURL = window.URL.createObjectURL(
        new Blob([fileBuffer.data], { type: fileType })
      );
      let fileLink = document.createElement("a");

      fileLink.href = fileURL;

      if (params.download) {
        fileLink.setAttribute("download", `${fileName}`);
      }

      fileLink.setAttribute("target", "_blank");
      document.body.appendChild(fileLink);

      fileLink.click();

      setTimeout(() => window.URL.revokeObjectURL(url), 0);

      return true;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Read file info
   * @param {*} id
   */
  async getFileInfo(id) {
    return AzureFunctionClient.get(
      this._buildUrl(`/readFile`, { _id: id, info: 1 })
    );
  }

  /**
   *
   * @param {*} ids - Array of file object ids
   */
  async downloadFilesAsZip(ids = []) {
    try {
      let fileBuffer = await AzureFunctionClient.post(`/readFile`, {ids}, {
        responseType: 'blob',
        timeout: 600000
      });

      let fileURL = window.URL.createObjectURL(new Blob([fileBuffer.data]));
      let fileLink = document.createElement("a");

      fileLink.href = fileURL;
      let now = new Date();
      fileLink.setAttribute(
        "download",
        `yitplus-download-${now.getTime()}.zip`
      );
      document.body.appendChild(fileLink);

      fileLink.click();

      return true;
    } catch (err) {
      throw err;
    }
  }

 /**
  * 
  * @param {*} params 
  * @returns 
  */
  async getFolders(params = {}) {
    if (!params || !params.projectId)
      throw "Project id needs to be provided in order to fetch folders";

    let lang = store.getters.getLang;

    let payload = {
      projectId: params.projectId,
    };
    if (params.apartmentId) payload.apartmentId = params.apartmentId;
    if (params.subScope) payload.subScope = params.subScope;
    if (params.folderIds) payload.folderIds = params.folderIds;
    if (params.folders) payload.folders = params.folders;

    if (lang) payload.language = lang;

    return AzureFunctionClient.get(this._buildUrl(`/folders`, payload));
  }

  /**
   * 
   * @param {*} folderId 
   * @returns 
   */
  async getFolder(folderId) {
    let params = {};
    let lang = store.getters.getLang;
    if (lang) params.language = lang;
    return AzureFunctionClient.get(
      this._buildUrl(`/folders/${folderId}`, params)
    );
  }

  /**
   * 
   * @param {*} params 
   * @returns 
   */
  async createFolder(params) {
    let queryParams = {};
    let lang = store.getters.getLang;
    if (lang) queryParams.language = lang;
    return AzureFunctionClient.post(
      this._buildUrl(`/folders`, queryParams),
      params
    );
  }

  /**
   * 
   * @param {*} folderId 
   * @param {*} params 
   * @returns 
   */
  async updateFolder(folderId, params) {
    let queryParams = {};
    let lang = store.getters.getLang;
    if (lang) queryParams.language = lang;
    return AzureFunctionClient.put(
      this._buildUrl(`/folders/${folderId}`, queryParams),
      params
    );
  }

  /**
   * 
   * @param {*} folderId 
   * @returns 
   */
  async deleteFolder(folderId) {
    return AzureFunctionClient.delete(`/folders/${folderId}`);
  }

    /**
   * 
   * @param {*} args 
   * @returns 
   */
    async getVideo(id) {
      return AzureFunctionClient.get(`/video/${id}`);
    }

  /**
   * 
   * @param {*} args 
   * @returns 
   */
  async addVideo(args = {}) {
    return AzureFunctionClient.post(`/video/`, args);
  }

  /**
   * 
   * @param {*} id 
   * @param {*} args 
   * @returns 
   */
  async updateVideo(id = null, args = {}) {
    if (!id)
      throw new Error('You must specify id to update video.');
    return AzureFunctionClient.put(`/video/${id}`, args);
  }

  /**
   * 
   * @param {*} id 
   * @param {*} args 
   * @returns 
   */
  async deleteVideo(id = null) {
    if (!id)
      throw new Error('You must specify id to remove video.');
    return AzureFunctionClient.delete(`/video/${id}`);
  }
}
