import {
  AzureFunctionClient
} from "./AzureFunctionClient";
import {
  AzureLiferayClient
} from "./AzureLiferayClient";
import BaseService from "./BaseService";
import store from "@/store/index";

export default class CommentService extends BaseService {
  constructor() {
    super();
    this.baseUrl = "/delegate/yithome-api/functions";
    this.hcID = null;
  }

  /**
   * New Comment
   * @param postData New conversation object
   */

  async newComment(postData) {
    if(this.routeViaLiferay() && false) {
      return AzureLiferayClient.post(`${this.baseUrl}/newComment`, postData);
    } else {
      return AzureFunctionClient.post(`/newComment`, postData);
    }
  }
  /**
   * Fetch Comments for Article
   * @param refId Reference ID
   */
  fetchComments(params = {}) {
    
    if (!params.parent && !params.projectId)
      params.projectId = store.getters.getSelectedHousingCompany.hcExt;

    let query = this._buildUrl(`getComments`, params);
    return AzureFunctionClient.get(query);
    
  }

  fetchCommentsCount(refId) {
    const projectId = store.getters.getSelectedHousingCompany.hcExt;
    let params = {
      projectId,
      refId,
      countOnly: true
    };

    if (this.routeViaLiferay() && false) {
      return AzureLiferayClient.get(
        `${this.baseUrl}/getNewsComments/${params.refId}/${params.projectId}/count`
      );
    } else {
      let query = this._buildUrl(`getComments`, params);
      return AzureFunctionClient.get(query);
    }
  }
  
  /**
   * New Comment
   * @param postData New conversation object
   */
  updateComment(postData) {
    if (this.routeViaLiferay() && false) {
      return AzureLiferayClient.put(`${this.baseUrl}/comment`, postData);
    } else {
      if (postData && Array.isArray(postData.images) && postData.images.length)
        postData.images = postData.images.map(this._removeThumbnailsProperty)
      return AzureFunctionClient.put(`/comment`, postData);
    }
  }

  /**
   * Delete Comment
   * @param postData New conversation object
   */
  deleteComment(id) {
    let postData = {
      data: {
        _id: id
      }
    };

    if (this.routeViaLiferay() && false) {
      return AzureLiferayClient.delete(`${this.baseUrl}/comment`, postData);
    } else {
      postData.data.checkIsAdmin = true;
      return AzureFunctionClient.delete(`/comment`, postData);
    }
  }

  _removeThumbnailsProperty = m => {
    if (m.thumbnails)
      delete m.thumbnails;
    return m;
  }
}
