import store from "@/store";
import IOTAppService from "@/services/IOTAppService";
import { i18n } from "@/plugins/i18n";
import { normalizeSync } from "normalize-diacritics";

const getDefaultState = () => {
  return {
    devices: [],
    deviceTypes: []
  };
};

const state = getDefaultState();

const getters = {
  getIotDevices: state => state.devices,
  getIotDeviceTypes: state => state.deviceTypes
};

const actions = {
    async fetchIotDevices({ commit }, params = {}) {
    const api = new IOTAppService();
    
    try {
        let response = await api.fetchIotDevices(params);
        let devices = [];

        if (response.data && response.data.devices) {
            response.data.devices = response.data.devices.map(d => {
                d.deviceNameTranslated = translateIotDeviceName(d.deviceName)
                return d;
            })
            devices = response.data.devices;
        }

        commit("setIotDevices", devices);
        return devices;
            
    } catch(err) {
        throw err;
    }

  },
  async addIotDevice({ commit }, payload) {
    const api = new IOTAppService();
    try {
      let itemResponse = await api.addIotDevice(payload);
      if (itemResponse.data) {
        commit("addIotDevice", itemResponse.data);
      }
    } catch (err) {
      throw err;
    }
  },
  async saveIotDevice({ commit }, payload) {
    const api = new IOTAppService();
    try {
      let itemResponse = await api.saveIotDevice(payload.id, payload);
      if (itemResponse.data) {
        commit("updateIotDevice", itemResponse.data);
      }
    } catch (err) {
      throw err;
    }
  },
  async deleteIotDevice({ commit }, deviceId) {
    const api = new IOTAppService();
    try {
      let itemResponse = await api.deleteIotDevice(deviceId);
      if (itemResponse.data) {
        commit("deleteFromIotDevices", deviceId);
      }
    } catch (err) {
      throw err;
    }
  },
  async fetchIotDeviceTypes({ commit }) {
    const api = new IOTAppService();
    try {
      let itemResponse = await api.fetchDeviceTypes();
      if (itemResponse.data.deviceTypes) {
        commit("setIotDeviceTypes", itemResponse.data.deviceTypes);
      }
    } catch (err) {
      throw err;
    }
  }
};


const mutations = {
  setIotDevices: (state, data) => {
        state.devices = data;
  },
  addIotDevice: (state, data) => {
    state.devices.push(data);
  },
  updateIotDevice: (state, data) => {
    console.log("updateIotDevice: ", data);
    let exists = false;
    state.devices = state.devices.map(d => {
      if (d && data && d.id === data.id) {
        d = data;
        exists = true;
      }
      return d;
    });

    if (!exists)
      state.devices.push(data);
  },
  deleteFromIotDevices: (state, id) => {
    state.devices = state.devices.filter(d => id && d.id !== id);
  },
  setIotDeviceTypes: (state, data) => {
    state.deviceTypes = data;
  },
  resetIotState: (state, data) => {
    state.devices = [];
    state.deviceTypes = [];
  } 
};
  
/**
 * 
 */
export const translateIotDeviceName = (name = "") => {
  return i18n.global.t('smart-home.sensors.devices.name.' + normalizeSync(name.replace(" ", "-")).toLowerCase())
}

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
