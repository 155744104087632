import { createRouter, createWebHistory } from 'vue-router'
import store from "./store";

import AppSettings from "@/globals";
import { buildPageMeta } from "@/utils/PageMetaUtil";
import { getFeature } from "./features";
import AppRoutes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  base: "/",
  routes: AppRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta) {
      if (to.meta.disableScrollTop) return savedPosition;
    }

    return { top: 0 };
  }
});

router.beforeEach(async (to, from, next) => {

  console.log("Router: navigating to route: ", to.path);

  const user = store.getters.getCurrentUser;
  const hcDetails = store.getters.getSelectedHousingCompany;
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  const onlyAdmin = to.matched.some(record => record.meta.admin);
  const loggedIn = store.getters.isLogged;
  const isRegistered = store.getters.isRegistered;
  const isYitUser = user.hasAnyOfRoles([
    "admin",
    "yitUser",
    "contributor"
  ]);
  const isPartnerUser = user.hasAnyOfRoles([
    "rolePartner",
    "partner"
  ]);

  let redirectBackToRoot = false;

  /* Check for country spesific maintenance message */
  if(hcDetails && hcDetails.addressCountry) {
    let countryList = String(process.env.VUE_APP_MAINTENANCE_MESSAGE_FOR_COUNTRIES).split(",");
    if(!user.hasRole('admin')) {
      if(hcDetails.addressCountry && countryList.includes(hcDetails.addressCountry) && to.path != '/maintenance') {
        console.log("CALLING NEXT #1")
        return next({
          path: "/maintenance"
        });
      }
    }
  }

  //Catch entry path and set it to local storage if user is not logged in
  if (!loggedIn) {
    try {
      if (window.localStorage) {

        let redirectPath = localStorage.getItem(
          "redirectAfterLogin"
        );
        
        //if (!redirectPath || redirectPath == "/") {
        if (!redirectPath) {
          if(to.fullPath != "/" && !to.fullPath.startsWith("/login")) {
            console.log("Router: Saving redirectAfterLogin variable to local storage");
            localStorage.setItem(
              "redirectAfterLogin",
              to.fullPath
            );
          }
        }
      }

    } catch(error) {
      console.log(error);
    }
  }

  /**
   * Check user access policies from route meta data
   * - Check that user role matches
   * - Check that feature is activated
   */
  if(to.meta && to.meta.accessPolicies && !user.hasRole('admin')) {
    if(Array.isArray(to.meta.accessPolicies.roles)) {
      if (!user.hasAnyOfRoles(to.meta.accessPolicies.roles)) {
        console.log(`Router: User role access policies not matched in route ${to.name}, redirecting to /`);
        redirectBackToRoot = true;
      }
    }
    if (Array.isArray(to.meta.accessPolicies.features)) {
      let featureFound = to.meta.accessPolicies.features.filter(featureId => getFeature(featureId));
      if (featureFound.length === 0) {
        console.log(`Router: Feature access policies not matched in route ${to.name}, redirecting to /`);
        redirectBackToRoot = true;
      }
    }
  }

  let classes = "";
  if (to.path == "/") {
    classes = "dashboard has--hero-image";
  } else if (to.name == "login") {
    classes = "login-page";
  } else if (to.name == "/my-apartment/material-selection") {
    classes = "material-selection has--hero-image";
  } else if (to.name == "/service-requests/manage/:id/:userId/:token") {
    classes = "service-request-maintenance-view";
  }

  classes += to.meta.layout
    ? " layout-" + to.meta.layout
    : " layout-" + AppSettings.defaultLayout;
  if (screen.width > 992) {
    classes = classes + " desktop";
  }

  if (to.meta.layout == "admin-section") classes += " admin-section";

  //add classes to body
  document.body.className = classes;

  if (
    to.path &&
    (to.path.toString().includes("/service-shop") ||
      to.path.toString().includes("/campaign/")) &&
    store.getters.isDemoHousingCompany &&
    !store.getters.getTemporaryZipCodeForDemoUser
  ) {
    to.params.showServiceLoungeDemoUserForm = true;
  }

  //If redirect info was found from local storage, redirect
  if(loggedIn) {
    try {
      if (window.localStorage) {
        let redirectPath = localStorage.getItem(
          "redirectAfterLogin"
        );

        if(redirectPath && typeof redirectPath == 'string') {

          //remove the variable
          console.log("Router: Removing redirectAfterLogin variable from local storage");
          localStorage.removeItem(
            "redirectAfterLogin"
          );
          
          console.log("CALLING NEXT #4")
          return next({
            path: redirectPath
          });
        }
      }

    } catch(error) {
      console.log(error);
    }
  }

  //Custom redirects after login
  if (loggedIn && user && (user.hasRole('consumptionManager') && user.roles.length === 1) && to && (!String(to.path).startsWith('/admin') && !String(to.path).startsWith('/profile') && !String(to.path).startsWith('/logout') && !String(to.path).startsWith('/register') ) ) {
    console.log("CALLING NEXT #5")
    return next({
      path: "/admin"
    });
  }


  /**
   * Build page meta data:
   * - Update page title
   * - Send information to Google tag manager
   */
  if(!to.meta || !to.meta.skipBuildPageMetaInRouter)
    buildPageMeta(to);

  if (
    loggedIn &&
    !isRegistered &&
    to.path !== "/register" &&
    !store.getters.getApplicationError
  ) {
    console.log("CALLING NEXT #9")
    return next({
      path: "/register",
      query: { redirect: to.fullPath } // Store the full path to redirect the user to after login
    });
  }

  //IF user has no rights to admin area, return user into dashboard
  if (loggedIn && (!isYitUser && !isPartnerUser) && onlyAdmin) {
    redirectBackToRoot = true;
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    redirectBackToRoot = true;
  }
  //If we are on error page but no error, redirect to front page
  if(!store.getters.getApplicationError && to.path == '/error') {
    redirectBackToRoot = true;
  }
  
  // Redirect back to root
  if(redirectBackToRoot && to.path != '/') {
    console.log("CALLING NEXT #14") 
    return next({
      path: "/"
    });
  }

  //Redirect to error page
  else if (
    store.getters.getApplicationError
  ) {
    if (to.path != "/error") {
      console.log("CALLING NEXT #12")  
      return next({
        path: "/error"
      });
    }
  }

  //Redirect to login page if not public route
  else if(!loggedIn && (!to.meta || to.meta.public !== true)) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath } // Store the full path to redirect the user to after login
    });
  }

  console.log("router before each finished");
  next();
});

export default router;
