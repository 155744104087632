import store from "@/store/index";
import BaseService from "../BaseService";
import { AzureFunctionClient, AzureFunctionClientWithRetry } from "../AzureFunctionClient";
import { prepareFileName } from "@/utils/FileUtil";

export default class DocumentService extends BaseService {
  constructor() {
    super();
  }

  /**
   * Fetch user documents and folders
   */
  fetchDocuments(params = null) {
    let req = {};

    if (params) req = Object.assign({}, params);

    if (!req.hcExtId)
      req.hcExtId = store.getters.getSelectedHousingCompany.hcExt;

    if (!req.aptExtId && store.getters.getSelectedApartment && store.getters.getSelectedApartment.aptext)
      req.aptExtId = store.getters.getSelectedApartment.aptext;

    //req.userUuid = store.getters.getCurrentUser.id;

    if (!req.hcExtId)
      return Promise.reject("You need to clarify project id to get documents.");

    let query = this._buildUrl(`/listProjectFiles`, req);
    return AzureFunctionClient.get(query);
  }

  /**
   * Fetch user documents and folders
   */
  deleteDocument(id) {
    let params = {
      _id: id,
    };
    let query = this._buildUrl(`/deleteFile`, params);

    return AzureFunctionClient.delete(query);
  }

  /**
   * 
   * @param {*} id - Upload batch id
   * @returns 
   */
   deleteDocumentsByBatchId(id) {
    return AzureFunctionClient.delete(`/deleteFilesByBatchId/${id}`);
  }

  /**
   * Add document
   * @param {*} object form data
   */
  addDocument(params = {}, forceB2C = false) {
    if (!params.hcExtId)
      params.hcExtId = store.getters.getSelectedHousingCompany.hcExt;

    return new Promise((resolve, reject) => {
      let urlParams = {
        hcExtId: params.hcExtId,
        folder: params.folder,
        fileMeta: params.file.type,
        fileName: prepareFileName(params.file.name),
        fileSize: params.file.size,
      };
      if (params.aptExtId) urlParams.aptExtId = params.aptExtId;

      if (params.meta) {
        if (params.meta.homeFolder === true || params.meta.homeFolder == "true")
          urlParams.metaHomeFolder = true;
        if (params.meta.tags) urlParams.metaTags = params.meta.tags.toString();
      }

      if (params.aptExtId) urlParams.aptExtId = params.aptExtId;

      if (params.notify_users) urlParams.notify_users = 1;

      if (params.uploadBatchId) urlParams.uploadBatchId = params.uploadBatchId;

      let query = this._buildUrl("/addFileMultipart", urlParams);

      if (
        !params.file ||
        (!params.folder && !params.folderPath) ||
        !params.hcExtId
      ) {
        console.log("addDocument request params: ", params);
        reject("Mandatory params not set to addDocument request");
        return;
      }

      let postData = new FormData();
      postData.append("file", params.file);

      let result = AzureFunctionClientWithRetry.post(query, postData, {
        "Content-Type": "multipart/form-data",
        timeout: 300000,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  /**
   * Fetch file meta data
   */
  getDocumentMetaData(id) {
    let params = {
      _id: id,
    };
    let query = this._buildUrl(`/getFileMetaData`, params);

    return AzureFunctionClient.get(query);
  }

  /**
   * Fetch file meta data
   * @param {string} path
   * @param {object} payload
   */
  updateMetaData(id, payload) {
    if (!id) throw "You must specify document id to update document meta data.";

    let params = {
      _id: id,
    };
    let query = this._buildUrl(`/updateFileMetaData`, params);

    return AzureFunctionClient.put(query, payload);
  }

  /**
   * Read file info
   * @param {*} id
   */
  async getFileInfo(id) {
    return AzureFunctionClient.get(
      this._buildUrl(`/readFile`, { _id: id, info: 1 })
    );
  }

  /**
   *
   * @param {*} ids - Array of file object ids
   */
  async downloadFilesAsZip(ids = []) {
    let query = this._buildUrl(`/readFile`, { ids: ids.join(",") });

    try {
      let fileBuffer = await AzureFunctionClient.get(query, {
        responseType: "blob",
        timeout: 600000
      });

      let fileURL = window.URL.createObjectURL(new Blob([fileBuffer.data]));
      let fileLink = document.createElement("a");

      fileLink.href = fileURL;
      let now = new Date();
      fileLink.setAttribute(
        "download",
        `yitplus-download-${now.getTime()}.zip`
      );
      document.body.appendChild(fileLink);

      fileLink.click();

      return true;
    } catch (err) {
      throw err;
    }
  }
}
