export default [
  {
    path: "/isannointiliitto/archive",
    name: "/isannointiliitto/archive",
    meta: {
      sectionTitle: "navigation.atp",
      title: "navigation.atp"
    },
    component: () =>
    import(
      /* webpackChunkName: "news" */ "@/views/ATP/Archive.vue"
    ),
  },
  {
    path: "/isannointiliitto/:id/:slug",
    name: "/isannointiliitto/:id/:slug",
    meta: {
      sectionTitle: "navigation.atp",
      title: "navigation.atp"
    },
    component: () =>
    import(
      /* webpackChunkName: "news" */ "@/views/ATP/View.vue"
    ),
  }
]