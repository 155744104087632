import LiferayClient from "./LiferayClient";
import BaseService from "./BaseService";
import { AzureFunctionClient } from "./AzureFunctionClient";
import store from "@/store";

export default class NeighbourService extends BaseService {
  constructor() {
    super();
    this.baseUrl = "/delegate/yithome-api/messages";
  }

  /**
  * Fetch Neighbours for HC
  */

  fetchNeighbours(all=false) {

    let projectId = store.getters.getSelectedHousingCompany.hcExt;

    if(store.getters.getTokenIssuer !="b2c") {
      return LiferayClient.get(`${this.baseUrl}/get-apartments-and-names/${this.getHcId()}`);
    } else {
      return AzureFunctionClient.get( this._buildUrl(`/getNeighbourList`, {projectId, all}));
     }
    
  }
}
