export default [
  {
    path: "/calendar",
    name: "/calendar",
    meta: {
      sectionTitle: "navigation.calendar",
      title: "navigation.calendar"
    },
    component: () =>
      import(/* webpackChunkName: "calendar" */ "@/views/Calendar/List.vue")
  },
  {
    path: "/calendar/entry/:id",
    name: "/calendar/entry/:id",
    meta: {
      sectionTitle: "navigation.news",
      title: "navigation.news",
      skipBuildPageMetaInRouter: true
    },
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "@/views/Calendar/View.vue"
      )
  },
  {
    path: "/admin/calendar",
    name: "/admin/calendar",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/Admin/Calendar/List.vue"
      ),
    meta: {
      admin: true,
      sectionTitle: "News admin",
      layout: "admin-default"
    }
  },
  {
    path: "/admin/calendar/preview/:id",
    name: "/admin/calendar/preview/:id",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/Admin/Calendar/Preview.vue"
      ),
    meta: {
      admin: true
    }
  },
  {
    path: "/admin/calendar/edit/:id",
    name: "/admin/calendar/edit/:id",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/Admin/Calendar/Edit.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/calendar/created/:id",
    name: "/admin/calendar/created/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Calendar/AfterCreate.vue"),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/calendar/new",
    name: "/admin/calendar/new",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/Admin/Calendar/Edit.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/step/:id",
    name: "/step/:id",
    meta: {
      sectionTitle: "navigation.step",
      title: "navigation.news",
      skipBuildPageMetaInRouter: true
    },
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "@/views/Calendar/Step.vue"
      )
  },
];