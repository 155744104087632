const getMappedTaxonomy = taxonomy => {

    const taxonomies = {
        prod: {
            "Tallinn": "tallinn_9e538b9",
            "Saani 2": "saani_2_2ed5517",
            "AALTO Cibulka": "aalto_cibulka_5c24f8c",
            "Green Motol": "green_motol_1e3987e",
            "Koru Vinohradská": "koru_vinohradska_a223317",
            "Talo Kavalírka": "talo_kavalirka_461bdd4",
            "Matau Vilnių": "matau_vilniu_a119037",
            "Solo city I": "solo_city_i_0001c98",
            "Solo city II etapas": "solo_city_ii_etapas_0d819c2",
            "Solo City III": "solo_city_iii_d6243d2",
            "Čiurlionio 3": "ciurlionio_3_428b09b",
            "Domino": "domino_be96291",
            "Dzirciema nams": "dzirciema_nams_b147274",
            "Midtown Apartments": "midtown_apartments_4c09e98",
            "BYTY VILLINKI": "byty_villinki_ac4466d",
            "Pari": "pari_dbac8f1",
            "Rozeta": "rozeta_6108327",
            "DEMO20 Building":"demo20_building_b59f4de",
            "PARVI Cibulka":"parvi_cibulka_96898fa",
            "Naujasis Skansenas":"naujasis_skansenas_a",
            "VESI Hostivař":"vesi_hostivar_6593362"
        },
        test: {
            "Byty Slany": "byty_slany_986f82e",
            "Pari": "pari_dbac8f1",
            "Solo City": "solo_city_f74d005",
            "Green Motol": "green_motol_1e3987e"
        },
        local: {
            "Newquota": "Newquota",
            "Koru Vinohradská": "koru_vinohradska_a223317"
        }
    }

    if (taxonomies[process.env.VUE_APP_ENV][taxonomy])
        return taxonomies[process.env.VUE_APP_ENV][taxonomy];
    else return taxonomy;
}

export {
    getMappedTaxonomy
};