import FeaturesService from "@/services/FeaturesService";

let api = null;
if (FeaturesService) api = new FeaturesService();

const getDefaultState = () => {
  return {
    settings: []
  };
};

const state = getDefaultState();

const getters = {
  getSettingsByBuildingTaxonomy: state => buildingTaxonomy => {
    let ret = {};
    Object.keys(state.settings).map(settingName => {
      if (settingName == buildingTaxonomy) {
        ret = state.settings[settingName];
      }
    });

    return ret;
  },
  getAllFeatureSettings: state => state.settings
};

const actions = {
  async fetchFeatureSettings({ commit }, payload) {
    if (!api) api = new FeaturesService();

    try {
      let response = await api.fetchFeatures(payload);
      commit("setFeatureSettings", response.data.items);
      return response;
    } catch(err) {
      throw err;
    }
  }
};

const mutations = {
  setFeatureSettings: (state, res) => (state.settings = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
