const isElementInViewport = (el, verticalOffset = 100) => {
  if (!el)
    return;
  var rect = el.getBoundingClientRect();
  
  return (
    el.offsetParent !== null &&
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /*or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /*or $(window).width() */
  );
};

export { isElementInViewport };
