<template>
  <div class="booking-list-snippet">
    <PageSection :unstyled="true" :hideFooter="true" :hideHeader="true" v-if="items.length">
      <h4 v-if="getTitle">{{getTitle}}</h4>
      <div class="event-list upcoming-bookins row">
        <div class="col-md-6" v-for="item in items.slice(0, limit)" :key="item.booking_ID">
        <EventListItem
            :title="item.serviceTitle"
            :url="getUrl(item)"
            :color="getColor"
            :startTime="item.startDate"
            :icon="item.state == 'canceled' ? 'far fa-calendar-times' : ''"
        />
        </div>
      </div>
    </PageSection>
  </div>
</template>

<script>
import EventListItem from "@/components/common/Lists/Event";
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'BookingsSnippet',
  components: {EventListItem},
  props: {
    items: {
      type: Array,
      default: () => []
    },
    title: {type: String},
    limit: {type: Number, default: 4},
    type: {type: String, default: 'upcoming'}, //upcoming/waiting-confirmation
    keepState: {type: Boolean, default: false},
  },
  computed: {
    getItems() {
      return this.items;
    },
    getTitle() {
      if(this.title)
        return this.title;
      else if(this.type == 'upcoming')
        return this.$t('bookings.upcoming.title');
      else if(this.type == 'waiting-confirmation')
        return this.$t('bookings.waiting_confirmation.title');

      return "";
    },
    getColor() {
      let color = "green";

      if(this.type == 'waiting-confirmation')
        color = 'orange';

      return color;
    }
  },
  methods: {
    getUrl(item) {
      if(!item)
        return;
      let ret = '/service-shop/my-bookings/'+item.booking_ID;
      if(this.keepState)
        ret += '?keepState=true';
      return ret;
    }
  },
  mounted() {
    if(this.fetch) {

    }
  }
}
</script>

<style lang="scss">
  @import "@/assets/scss/abstracts/_variables.scss";
  .booking-list-snippet {
    h2,h3,h4 {
      font-family: $fontPrimaryRegular;
    }
  }
</style>