import store from "@/store";
import IOTAppService from "@/services/IOTAppService";
import { i18n } from "@/plugins/i18n";
import { normalizeSync } from "normalize-diacritics";

const getDefaultState = () => {
  return {
    sensors: {
        movement: {
            devices: [],
            data: null
        }
    },
  };
};

const state = getDefaultState();

const getters = {
    getMovementSensorDevices: state => state.sensors.movement.devices,
    getMovementSensorData: state => state.sensors.movement.data
};

const actions = {
  fetchMovementSensorsData({ commit }, params = {}) {
    const api = new IOTAppService();
    return new Promise((resolve, reject) => {

      console.log("fetchMovementSensorsData: ", params);
      
      if(!params.limit)
        params.limit = 25;
      if(!params.offset)
        params.offset = 0;

      let payload = { limit: params.limit, offset: params.offset };

      if (params.aptExtId)
        payload.aptExtId = params.aptExtId;
      if (params.deviceId)
        payload.deviceId = params.deviceId;
      if (params.showAll)
        payload.showAll = true;
        
      api
        .fetchMovementSensorDataByApartment(payload)
        .then(response => {
          if (response.data && response.data.events && response.data.events.rows) {

            response.data.events.rows = response.data.events.rows.map(r => {
              r.deviceNameTranslated = translateIotDeviceName(r.deviceName);
              return r;
            })

            if(params.initial === false)
              commit("appendMovementSensorData", response.data.events.rows);
            else
              commit("setMovementSensorData", response.data.events.rows);
          }
          resolve(response.data);
        })
        .catch(err => {
          console.log("Failed fetching Leanheat data: ", err);
          reject(err);
        });
    });
  },
  fetchMovementSensorDevices({commit}, aptExtId = null) {
    const api = new IOTAppService();
    return new Promise((resolve, reject) => {

      if(!aptExtId)
        aptExtId = store.getters.getSelectedApartment && store.getters.getSelectedApartment.aptext ? store.getters.getSelectedApartment.aptext : null;

      //aptExtId = "1-25GR9D";  //For testing purposes
      
      if(!aptExtId) {
        reject('No apartment defined for user!');
      }  

      api
        .fetchMovementSensorDevicesByApartment(aptExtId)
        .then(response => {
          console.log("Fetched movement sensor devices: ", response);
          if (response.data && response.data.devices) {
            response.data.devices = response.data.devices.map(d => {
              d.deviceNameTranslated = translateIotDeviceName(d.deviceName)
              return d;
            })
            commit("setMovementSensorDevices", response.data.devices);
          }
          resolve(response.data);
        })
        .catch(err => {
          console.log("Failed fetching Leanheat data: ", err);
          reject(err);
        });

    });
  }
};


const mutations = {
    setMovementSensorDevices: (state, data) => {
        state.sensors.movement.devices = data;
    },
    setMovementSensorData: (state, data) => {
        state.sensors.movement.data = data;
    },
    appendMovementSensorData: (state, data) => {
      state.sensors.movement.data = state.sensors.movement.data.concat(data);
    }
};
  
/**
 * 
 */
export const translateIotDeviceName = (name = "") => {
  const translationKey = 'smart-home.sensors.devices.name.' + normalizeSync(name.replace(" ", "-")).toLowerCase();
  const translation = i18n.global.t(translationKey);
  return translation != translationKey ? translation : name;
}

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
