import ProjectService from "@/services/ProjectService";
import UserService from "@/services/UserService";

let api = new UserService();

const getDefaultState = () => {
  return {
    hcUsers: [],
    aptUsers: [],
    selectedUser: {},
    selectedApt: {},
    aptUsers: [],
    directSearchUsers: [],
    directSearchKeyword: ""
  };
};

const state = getDefaultState();

const getters = {
    getHcUsers: state => state.hcUsers,
    getAptUsers: state => state.aptUsers,
    getSelectedUser: state => state.selectedUser,
    getSelectedApt: state => state.selectedApt,
  getApartmentUsers: state => state.aptUsers,
  getDirectSearchUsers: state => state.directSearchUsers,
  getDirectSearchKeyword: state => state.directSearchKeyword
};

const actions = {
  fetchHcUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
        api
        .getUsers(params)
        .then(response => {
            console.log("fetched Hc users", response.data);
            commit("setHcUsers", response.data);
            resolve(response.data);
        })
        .catch(err => {
            console.log(err);
            reject(err)
        });
    })
  },
  fetchAptUsers({ commit }, filter) {
    return new Promise((resolve, reject) => {
        api
        .getAptUsers(filter)
        .then(response => {
            console.log("fetched Apt users", response.data);
            commit("setAptUsers", response.data);
            resolve(response.data);
        })
        .catch(err => {
            console.log(err);
            reject(err)
        });
    })
  },
  fetchUser({ commit }, params) {
    return new Promise((resolve, reject) => {
        api
        .getUser(params)
        .then(response => {
            console.log("fetched User", response.data);
            commit("setSelectedUser", response.data);
            resolve(response.data);
        })
        .catch(err => {
            console.log(err);
            reject(err)
        });
    })
  }
};

const mutations = {
    setHcUsers: (state, res) => (state.hcUsers = res),
    setSelectedUser: (state, res) => (state.selectedUser = res),
    setAptUsers: (state, res) => (state.aptUsers = res),
  
  chooseProject: (state, hcExtId) => {
    //reset aparatments when choosing project
    state.apartments = [];
  },
  setDirectSearchUsers: (state, res) => (state.directSearchUsers = res),
  setDirectSearchKeyword: (state, res) => (state.directSearchKeyword = res),
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
