import NeighbourService from "@/services/NeighbourService";

const api = new NeighbourService();

const getDefaultState = () => {
  return {
    neighbours: [],
    allNeighbours: []
  }
}

const state = getDefaultState();

const getters = {
  getNeighbours: state => state.neighbours,
  getAllNeighbours: state => state.allNeighbours
};

const actions = {
  async fetchNeighbours({ commit }, projectId) {
    try {
      let response = await api.fetchNeighbours();
      commit("setNeighbours", response.data);
      return response.data;
    } catch(err) {
      console.log("Fetching Neighbours failed: ", err);
    }
  },
  async fetchAllNeighbours({ commit }, projectId) {
    try {
      let response = await api.fetchNeighbours(true);
      commit("setAllNeighbours", response.data);
    } catch(err) {
      console.log("Fetching Neighbours failed: ", err);
    }
 },
};

const mutations = {
  setNeighbours: (state, res) => (state.neighbours = res),
  setAllNeighbours: (state, res) => (state.allNeighbours = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
