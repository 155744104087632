import store from "@/store";

const one4allFeature = {
    id: "one4all-bookings",
    name: "feature.one4all-bookings.name",
    iconClass: "fal fa-calendar",
    globalSettings: {},
    schema: {
        showPin: {
            type: Boolean,
            required: false,
            label: "feature.one4all-bookings.field.showPin",
            default: false
        },
        useCalendar: {
            type: Boolean,
            required: false,
            label: "feature.one4all-bookings.field.useCalendar",
            default: false
        },
        moduleId: {
            type: String,
            required: false,
            input: {
                type: "text"
            },
            label: "feature.one4all-bookings.field.module",
            default: ""
        },
        navigationOrderNumber: {
            type: Number,
            required: false,
            input: {
                type: "number"
            },
            label: "feature.one4all-bookings.field.navigationOrderNumber",
            default: 35
        }
    },
    initialize: async (feature, settings) => {
        store.dispatch("addNavigationLink", {
            titleLanguageKey: "navigation.one4all-bookings",
            href: "/book-common-spaces",
            target: "_self",
            iconClass: "fal fa-calendar",
            orderNumber: settings.navigationOrderNumber
                ? settings.navigationOrderNumber
                : 35
        });
        if (!store.getters.getOne4AllAccessToken) {
            await store.dispatch("fetchOne4allAccessToken")
            store.dispatch("fetchOne4AllModules")
        } else {
            store.dispatch("fetchOne4AllModules")
        }
    }
};

const electroluxFeature = {
    id: "electrolux-bookings",
    name: "feature.electrolux-bookings.name",
    iconClass: "fal fa-calendar",
    globalSettings: {},
    schema: {
        showMainNavigationLink: {
            type: Boolean,
            label: "feature.common.showMainNavigationLink.label",
            default: false,
            required: true
        }
    },
    initialize: (feature, settings) => {
        if (settings && settings.showMainNavigationLink) {
            store.dispatch("addNavigationLink", {
                titleLanguageKey: "navigation.book-common-spaces",
                href: "/book-common-spaces",
                target: "_self",
                iconClass: "fal fa-calendar",
                hide: false,
                orderNumber: 35
            });
        }
    }
};

export { one4allFeature, electroluxFeature };