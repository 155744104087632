import {
  AzureFunctionClient,
  includeJwt
} from "./AzureFunctionClient";
import BaseService from "./BaseService";
import store from "@/store";
import { sanitizeTextInput } from "@/utils/StringUtils";
import dayjs from 'dayjs';

export default class AzureServiceRequestService extends BaseService {
  constructor() {
    super();
    includeJwt();
  }

  /**
   * Get list of available types
   */
  getTypes(p = {}) {
    let params = {
      projectId: p && p.hcExtId ? p.hcExtId : store.getters.getSelectedHousingCompany.hcExt
    };
    let query = this._buildUrl(`getServiceRequestTypes/`, params);
    return AzureFunctionClient.get(query);
  }

  /**
   * @param {*} object form data
   * @return Promise
   */
  getList(_params = {}) {
    let params = Object.assign({}, _params);

    if (Array.isArray(params.type) && params.type.length) {
      params.type.toString();
    }

    if (Array.isArray(params.status) && params.status.length) {
      params.status.toString();
    }

    let query = this._buildUrl(`getServiceRequests/`, params);
    return AzureFunctionClient.get(query);
  }

  async downloadReport(params = {}) {

    params.format = 'csv';

    let response = await this.getList(params);
    
    let now = new Date();

    let fileName = `service-requests-${dayjs().format('YYYY-MM-DD')}.csv`;

    let fileURL = window.URL.createObjectURL(new Blob(["\ufeff" + response.data], {
      type : 'text/csv;charset=utf-8'
    }));
    let fileLink = document.createElement('a');
  
    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    return;
  }

  /**
   * @param {Integer} uuid
   * @return Promise
   */
  getSingle(uuid) {
    let params = {};

    params.id = uuid;
    let query = this._buildUrl(`serviceRequest/`, params);
    return AzureFunctionClient.get(query);
  }

  /**
   * @param {*} object form data
   * @return Promise
   */
  getResponses(id) {
    let params = {};
    params.id = id;
    let query = this._buildUrl(`getServiceRequestResponses/`, params);
    return AzureFunctionClient.get(query);
  }

  /**
   * @param {*} object form data
   * @return Promise
   */
  createNew(data) {
    let postData = {
      typeId: data.typeId,
      public: data.public,
      attachments: data.attachments ? data.attachments : [],
      subject: data.subject ? data.subject : data.typeKey,
      content: sanitizeTextInput(data.content),
      senderTel: data.senderTel,
      sender: store.getters.getCurrentUser.getDisplayName(),
      hcExtId: store.getters.getSelectedHousingCompany.hcExt,
      aptExtId: data.aptExtId ? data.aptExtId : "",
      senderUserUuid: store.getters.getCurrentUser.getId(),
      visibleInApartment: data.visibleInApartment,
      partnerCompanyId: data.partnerCompanyId,
      urgentRequest: data.urgentRequest,
    };

    if(data.targetedToUser && !data.public) {
      if(!data.targetUserUuid)
        throw "Target user must be defined when sending service request to resident";
      postData.targetedToUser = true;
      postData.targetUserUuid = data.targetUserUuid;
    }

    /*if (data.recipientUser) {
      postData.recipients = [parseInt(data.recipientUser)];
      postData.recipientType = "partner";
    }*/

    let params = {};

    let query = this._buildUrl(`serviceRequest/`, params);
    return AzureFunctionClient.post(query, postData);
  }

  /**
   *
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    let postData = {
      _id: id,
      statusKey: data.statusKey,
      public: data.public,
      visibleInApartment: data.visibleInApartment,
      sendNotification: data.sendNotification ? data.sendNotification : false
    };

    if (data.typeId)
      postData.typeId = data.typeId;
    
    if (typeof data.internalId != 'undefined')
      postData.internalId = data.internalId;
    
    if (data.content)
      postData.content = data.content;

    let params = {};

    let query = this._buildUrl(`serviceRequest/`, params);
    return AzureFunctionClient.put(query, postData);
  }

  delete(id) {
    let queryParams = {};

    let postData = {
      data: {
        _id: id
      }
    };

    let query = this._buildUrl(`serviceRequest/`, queryParams);
    return AzureFunctionClient.delete(query, postData);
  }

  /**
   *
   * @param {*} serviceRequestId
   * @param {*} file
   */
  async addAttachment(serviceRequestId, file) {
    return new Promise((resolve, reject) => {
      let postData = {
        serviceRequestId,
        file: {},
        hcExtId: store.getters.getSelectedHousingCompany.hcExt
      };

      let queryParams = {};

      try {
        //Base64 encode file
        let reader = new FileReader();
        reader.onload = () => {
          let imgBase64 = reader.result;
          postData.file = {
            name: file.name,
            size: file.size,
            type: file.type,
            data: imgBase64
          };

          let query = this._buildUrl(`serviceRequestAttachment/`, queryParams);
          AzureFunctionClient.post(query, postData)
            .then(res => resolve(res))
            .catch(err => reject(err));
        };
        reader.readAsDataURL(file);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   *
   * @param {*} serviceRequestId
   * @param {*} filePath
   */
  deleteAttachment(serviceRequestId, fileId) {
    let queryParams = {};

    let postData = {
      data: {
        serviceRequestId,
        _id: fileId,
        hcExtId: store.getters.getSelectedHousingCompany.hcExt
      }
    };
    let query = this._buildUrl(`serviceRequestAttachment/`, queryParams);
    return AzureFunctionClient.delete(query, postData);
  }

  addResponse(params = {}) {
    let queryParams = {};

    let postData = {
      serviceRequestId: params.serviceRequestId,
      content: sanitizeTextInput(params.content),
      senderName: params.senderName,
      apartment: params.apartment ? params.apartment : "",
      hcExtId: params.hcExtId,
      //userUuid: params.userUuid,
      attachments: params.attachments ? params.attachments : []
    };

    if (!postData.hcExtId)
      postData.hcExtId = store.getters.getSelectedHousingCompany.hcExt;

    let query = this._buildUrl(`serviceRequestResponse/`, queryParams);
    return AzureFunctionClient.post(query, postData);
  }

  updateResponse(id, item) {
    let queryParams = {};

    let postData = {
      _id: id,
      content: item.content
    };

    let query = this._buildUrl(`serviceRequestResponse/`, queryParams);
    return AzureFunctionClient.put(query, postData);
  }

  deleteResponse(id) {
    let queryParams = {};

    let postData = {
      data: {
        _id: id
      }
    };

    let query = this._buildUrl(`serviceRequestResponse/`, queryParams);
    return AzureFunctionClient.delete(query, postData);
  }

  /**
   *
   * @param {*} serviceRequestResponseId
   * @param {*} file
   */
  async addAttachmentToResponse(serviceRequestResponseId, file) {
    return new Promise((resolve, reject) => {
      let postData = {
        serviceRequestResponseId,
        file: {},
        hcExtId: store.getters.getSelectedHousingCompany.hcExt
      };

      let queryParams = {};

      try {
        //Base64 encode file
        let reader = new FileReader();
        reader.onload = () => {
          let imgBase64 = reader.result;
          postData.file = {
            name: file.name,
            size: file.size,
            type: file.type,
            data: imgBase64
          };

          let query = this._buildUrl(
            `serviceRequestResponseAttachment/`,
            queryParams
          );
          AzureFunctionClient.post(query, postData)
            .then(res => resolve(res))
            .catch(err => reject(err));
        };
        reader.readAsDataURL(file);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   *
   * @param {*} serviceRequestResponseId
   * @param {*} filePath
   */
  deleteResponseAttachment(serviceRequestResponseId, fileId) {
    let queryParams = {};

    let postData = {
      data: {
        serviceRequestResponseId,
        _id: fileId,
        hcExtId: store.getters.getSelectedHousingCompany.hcExt
      }
    };
    let query = this._buildUrl(
      `serviceRequestResponseAttachment/`,
      queryParams
    );
    return AzureFunctionClient.delete(query, postData);
  }

  /**
   * 
   * @param {*} id 
   * @param {*} payload 
   * @returns 
   */
  addRecipient(id, payload = {}) {

    if (!id)
      throw "service request id must be defined";

    let queryParams = {
      id
    };

    let query = this._buildUrl(`serviceRequestRecipient/`, queryParams);
    return AzureFunctionClient.post(query, payload);
  }
}
