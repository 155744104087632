<template>
  <div class="timeline__bg">
    <div class="timeline" :class="'timeline--' + getCurrentHcState">

      <slot></slot>
      
      <div class="text-center" v-if="loadingMore">
        <LoaderIcon />
      </div>

      <transition name="fade">
        <button
          class="load-more load-more__later button button--secondary"
          @click="$emit('on-load-more')"
          v-if="!loadingMore && displayLoadMoreButton"
        >
          {{ $t("timeline.button.later") }}
        </button>
      </transition>
      <button
        href="#"
        class="rounded-button rounded-button--right-down rounded-button--fixed"
        :class="{ hide: !showBackToTodayButton }"
        @click="scrollToToday"
      >
        <i class="far fa-arrow-up" v-if="backToTOdayButtonIcon == 'up'"></i>
        <i class="far fa-arrow-down" v-if="backToTOdayButtonIcon == 'down'"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isElementInViewport } from "@/utils/ViewportUtil";
import _ from "lodash";
import "./timeline.scss";

export default {
  name: "Timeline",
  props: {
    loadingMore: {type: Boolean, default: false},
    displayLoadMoreButton: {type: Boolean, default: false}
  },
  components: {},
  data() {
    return {
      showBackToTodayButton: false,
      backToTOdayButtonIcon: "up",
      currentYear: new Date().getFullYear()
    };
  },
  computed: {
    ...mapGetters(["getCurrentHcState"]),
  },
  methods: {
    scrollToToday() {
      let container = this.$el.querySelector(".timeline-item__today");
      this.showBackToTodayButton = false;
      if (container) {
        if (typeof container.scrollIntoView == "function") {
          container.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth"
          });
        }
      }
    },
    scrollHandler() {
      let el = this.$el.querySelector(".timeline-item__today");
      let direction = "up";
      if (el) {
        let rect = el.getBoundingClientRect();
        if (rect.y >= 0) direction = "down";
        else direction = "up";
      }
      this.showBackToTodayButton = !isElementInViewport(el);
      this.backToTOdayButtonIcon = direction;
    },
    filterItems(items) {
      if(this.typeFilter.length)
        return items.concat().filter(item => {
          let ret = false;
          item.category.map(c => {
            if(this.typeFilter.includes(c))
              ret = true;
          });
          return ret;
        });
      return items;
    }
  },
  created() {
    if (window.addEventListener) {
      addEventListener("scroll", _.debounce(this.scrollHandler, 300), false);
      addEventListener("resize", _.debounce(this.scrollHandler, 300), false);
    } else if (window.attachEvent) {
      attachEvent("onload", _.debounce(this.scrollHandler, 300));
      attachEvent("onscroll", _.debounce(this.scrollHandler, 300));
    }
  },
  mounted() {
    
  },
  beforeUnmount() {
    if (window.removeEventListener) {
      removeEventListener("scroll", _.debounce(this.scrollHandler));
      removeEventListener("resize", _.debounce(this.scrollHandler));
    } else if (window.detachEvent) {
      detachEvent("onload", _.debounce(this.scrollHandler));
      detachEvent("onscroll", _.debounce(this.scrollHandler));
    }
  }
};
</script>