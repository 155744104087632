<template>
  <div class="carousel-wrapper">
    <Carousel>
      <Slide v-for="(image, index) in images" :key="index">
        <div>
          <transition>
            <img v-show="isLoaded" :src="image.url + '?w=1200'" @load="loaded" />
          </transition>
          <span class="article-page__image__caption" v-if="image.description && image.description != image.name"
            v-text="image.description"></span>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>


export default {
  name: "ContentCarousel",
  components: {
    
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  computed: {
    getCssClasses() {
      let classes = ['content-carousel'];
      return classes;
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/variables";
@import "~@/assets/scss/components/_carousel.scss";
.carousel-wrapper {
  position: relative;
}
</style>