<template>
  <article class="step-card" :class="{'bg--right': backgroundDirection == 'right'}">
    <div class="step-heart"><img :src="heartIcon" alt="" aria-hidden="true" /></div>
    <h1 class="step-card__title">{{step.title}}</h1>
    <p class="step-card__description" v-if="step.introduction" v-html="this.$filters.nl2br(step.introduction)"></p>
    <p class="step-card__description" v-else-if="step.content" v-html="String(step.content).substring(0,stepMaxLength)"></p>
    <div class="step-card__date">
      <i class="far fa-calendar-alt"></i>
      {{$filters.month_and_year(step.date_start)}}
    </div>
  </article>
</template>

<script>
import dayjs from 'dayjs';
import { i18n } from "@/plugins/i18n";
import StepModel from "@/models/ProjectStep";
import {isPastDate} from "@/utils/DateHelper";
import { STEP_INTRODUCTION_MAX_LENGTH } from "@/models/ProjectStep";
import heartIcon from "@/assets/images/heart.svg"

export default {
  name: "ProjectStepCard",
  props: {
    step: {type: StepModel, required: true},
    backgroundDirection: {type: String, default: 'left'}  //left|right
  },
  data() {
    return {
      stepMaxLength: STEP_INTRODUCTION_MAX_LENGTH,
      heartIcon
    }
  },
  methods: {
    isPastDate
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/variables";
.step-card {
  background-image: url("~@/assets/images/timeline-dash-left-white.svg");
  background-repeat: no-repeat;
  background-position: 33.33% 50%;
  background-size: contain;
  position: relative;
  &.bg--right {
    background-image: url("~@/assets/images/timeline-dash-right-white.svg");
    background-position: 66.66% 50%;
  }
  h1,h2,h3,h4,h5,h6, &__title {
    font-size: 1rem;
    font-family: $fontPrimaryBold;
  }
  &__description {
    font-size: .9rem;
  }
  &__date {
    i {
      margin-right: .3rem;
    }
    font-size: .85rem;
    top: .5rem;
    right: .5rem;
    color: #556a7b;
  }
  &__icon {
    font-size: 2.6rem;
    margin: .5rem 0 1rem 0;
  }
  text-align: center;
  position: relative;
  background-color: $blueLightest;
  padding: 1rem 2.5rem;
  position: relative;
  height: 100%;
  @media screen and (min-width: $breakpoint-lg) {
    padding: 1.2rem 3rem;
  }
}
</style>