<template>
  <div class="content-carousel__arrows">
    <button @click="$emit('on-select-previous')" class="left">
      <i class="far fa-chevron-left" aria-hidden="true"></i>
      <span class="sr-only">{{$t('common.previous')}}</span>
    </button>
    <button @click="$emit('on-select-next')" class="right">
      <i class="far fa-chevron-right" aria-hidden="true"></i>
      <span class="sr-only">{{$t('common.next')}}</span>
    </button>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/variables";
button {
  margin: 0;
  padding: 1rem;
  position: absolute;
  font-size: 1.8rem;
  transform: translateY(-50%);
  top: 50%;
  
  @media screen and (min-width: $breakpoint-xl) {
    font-size: 2rem;
  }

  &:focus {
    outline: none;
  }

  &.left {
    left: -2.2rem;
  }
  &.right {
    right: -2.2rem;
  }

  @media screen and (min-width: $breakpoint-lg) {
    &.left {
      left: -2.5rem;
    }
    &.right {
      right: -2.5rem;
    }
  }

  @media screen and (min-width: $breakpoint-xl) {
    &.left {
      left: -3rem;
    }
    &.right {
      right: -3rem;
    }
  }
}
</style>