import LayoutDefault from "@/layouts/Default";
import LayoutSubpage from "@/layouts/Subpage";
import LayoutFullWidth from "@/layouts/FullWidth";
import LayoutTwoColumns from "@/layouts/TwoColumns";
import LayoutAdminDefault from "@/layouts/AdminDefault";
import LayoutAdminFrontPage from "@/layouts/AdminFrontPage";
import LayoutFullWidthNarrow from "@/layouts/FullWidthNarrow";

export default {
  install: (app, options) => {
    app.component("layout-default", LayoutDefault);
    app.component("layout-subpage", LayoutSubpage);
    app.component("layout-two-columns", LayoutTwoColumns);
    app.component("layout-fullwidth", LayoutFullWidth);
    app.component("layout-admin-default", LayoutAdminDefault);
    app.component("layout-admin-frontpage", LayoutAdminFrontPage);
    app.component("layout-fullwidthnarrow", LayoutFullWidthNarrow);
  }
}