<template>
  <div class="type-filter" v-if="tags">
    <button type="button" class="unstyled bg--white type-filter__toggle" @click="open=true">
      <i class="fal fa-sliders-h"></i>
      <span class="type-filter__toggle_title">{{$t('timeline.filter.type')}}</span>
      <span class="type-filter__count" v-if="getSelectedTagsCount">{{getSelectedTagsCount}}</span>
    </button>
    <Modal v-if="open" @close="open=false" colorMode="dark">
      <h4 class="type-filter__heading">
        <h2 class="page-title">{{$t('timeline.filter.type')}}:</h2>
      </h4>
      <button type="button" :class="`button--unstyled type__${key.toLowerCase()}`" v-for="(tag,key) in tags" :key="key" @click="select(tag.key)" :aria-selected="!selectedTags.includes(tag.key)">
        <span class="tag" :class="{'bg--grey-lighter': !selectedTags.includes(tag.key), 'color--default': !selectedTags.includes(tag.key), 'bg--blue-medium': selectedTags.includes(tag.key)}">{{$t(tag.translateKey)}}</span>
      </button>
      <template v-slot:footer>
        <div class="button-group text-center">
          <Button type="button" mode="secondary" @click="open=false">{{$t('common.closeButton')}}</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import {CONTENT_TYPES} from "./settings.js";
import Modal from "@/components/common/Modal";
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "TimelineTypeFilter",
  components: {
    Modal
  },
  data() {
    return {
      open: false,
      tags: null,
      selectedTags: [],
      featureTypeNameMap: {
        news: ['news', 'announcement', 'status_update', 'priority_news', 'marketing'],
        calendar: ['task', 'event', 'deadline'],
        one4allBookings: ['booking']
      },
      hidedTagNames: ['deadline', 'priority_news']
    }
  },
  computed: {
    ...mapGetters(['getSelectedHousingCompany', 'getSelectedTimeLineTags']),
    getSelectedTagsCount() {
      return this.selectedTags.filter(t => !this.hidedTagNames.includes(t)).length
    }
  },
  methods: {
    ...mapMutations(['setSelectedTimeLineTags']),
    select(key) {
      if(!this.selectedTags.includes(key))
        this.selectedTags.push(key);
      else
        this.selectedTags = this.selectedTags.filter(t => t !== key);
      
      this.$emit('on-select', this.selectedTags);

      if(key == 'task')
        this.select('deadline');
      if(key == 'news')
        this.select('priority_news');

      this.setSelectedTimeLineTags(this.selectedTags);
    }
  },
  mounted() {
    this.tags = {};

    if(this.getSelectedTimeLineTags.length) {
      this.selectedTags = this.getSelectedTimeLineTags;
      this.$emit('on-select', this.selectedTags);
    }

    let hc = this.getSelectedHousingCompany;

    Object.keys(CONTENT_TYPES).filter(typeKey => {
      if(this.$App.getFeature('news') && this.featureTypeNameMap.news.includes(typeKey))
        this.tags[typeKey] = CONTENT_TYPES[typeKey];
      if(this.$App.getFeature('calendar') && this.featureTypeNameMap.calendar.includes(typeKey))
        this.tags[typeKey] = CONTENT_TYPES[typeKey];
      if(typeKey == 'step' && hc && hc.lifecycleStepsActive)
        this.tags[typeKey] = CONTENT_TYPES[typeKey];
      if(this.$App.getFeature('one4all-bookings') && this.featureTypeNameMap.one4allBookings.includes(typeKey))
        this.tags[typeKey] = CONTENT_TYPES[typeKey];
    });
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/variables";
.type-filter {
  &__toggle {
    display: block;
    padding: .75rem 3rem;
    width: 100%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    i {
      font-size: 1.4rem;
      position: absolute;
      left: .75rem;
    }
    &_title {
      font-size: .9rem;
      font-family: $fontPrimaryMedium;
      text-align: center;
    }
    .type-filter__count {
      font-size: .7rem;
    }
  }

  padding-bottom: 1.5rem;
  &__heading {
    font-size: .9rem;
    margin-top: 0;
    margin-bottom: .5rem;
  }
  .type__atparticle,
  .type__deadline,
  .type__priority_news {
    display: none;
  }

  .tag {
    font-size: .9rem;
    margin: 0.4rem 0.4rem 0.4rem 0;
  }

  &__count {
    display: inline-block !important;
    margin-left: 1rem;
    background: $blueMedium;
    color: #fff;
    border-radius: 50%;
    font-size: .7rem;
    width: 2.2em;
    height: 2.2em;
    padding: .4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>