import axios from "axios";
import store from "@/store/index";

const AzureIOTAppClient = axios.create({
  baseURL: process.env.VUE_APP_AZURE_IOTAPP_HOST + "/api",
  timeout: 30000,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

//Add jwt token to services that needs it
const includeJwt = (token) => {
  if(!token)
    token = store.getters.getUserDetails.jwt;
  AzureIOTAppClient.interceptors.request.use(function(config) {
    let issuer = store.getters.getTokenIssuer;
    let token = store.getters.getAccessToken;

    config.headers["Ocp-Apim-Issuer"] = issuer;
    config.headers["Ocp-Apim-Trace"] = 'true';
    config.headers.Authorization = "Bearer " + token;
    config.headers['Ocp-Apim-Subscription-Key'] = process.env.VUE_APP_AZURE_IOTAPP_SUBSCRIPTION_KEY;
    return config;
  });
};

export default AzureIOTAppClient;
export { AzureIOTAppClient, includeJwt };
