<template>
  <component 
    :is="getComponent"  
    :item="item"
  />
</template>

<script>
import TimelineItemDefault from "./Item/Default";
import TimelineItemModel from "@/models/TimelineItemModel";
import {CONTENT_TYPES} from "./settings";

export default {
  name: "TimelineItem",
  props: {
    item: {type: TimelineItemModel},
    showHeader: {type: Boolean, default: true},
    showFooter: {type: Boolean, default: true}
  },
  components: { TimelineItemDefault},
  computed: {
    getComponent() {
      let component = TimelineItemDefault;
      if(this.item && this.item.type && typeof CONTENT_TYPES[this.item.type] != 'undefined' && CONTENT_TYPES[this.item.type].component) {
        component = CONTENT_TYPES[this.item.type].component;
      }
      return component;
    }
  },
  methods: {
   
  }
}
</script>

<style>

</style>