<template>
  <div class="comments-list" v-if="!loading && !isLoading">
    <Comment
      v-for="comment in listComments"
      :key="comment._id"
      :id="comment._id"
      :date="comment.createdAt"
      :name="comment.firstName"
      :apartment="comment.apartment"
      :userRoles="Array.isArray(comment.roles) ? comment.roles : []"
      :obj="comment"
      :userId="comment.userUuid"
      :likes="comment.likes"
    >
      <div v-text="comment.body" v-linkify></div>
    </Comment>

    <div class="padding--no-h margin--default-top" v-if="displayForm">
      <NewCommentForm
        :referenceId="referenceId"
        commentType="news"
        @response-posted="refreshComponent"
        :key="'news-comments-'+referenceId"
      />
    </div>
  </div>
  <LoaderIcon v-else-if="loading || isLoading" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewCommentForm from "./NewCommentForm";
import yitLogo from "@/assets/images/logo.svg";
import AdminToolbar from "@/components/Admin/Common/Toolbar";
import CommentEditForm from "./CommentEditForm";
import Comment from "@/components/Comments/Comment";

export default {
  name: "Comments",
  props: ["referenceId"],
  components: {
    NewCommentForm
  },
  props: {
    referenceId: {
      type: String
    },
    comments: {
      type: Array,
      default: () => []
    },
    displayForm: {
      type: Boolean,
      default: true
    },
    fetchData: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      showUsers: false,
      yitLogo,
      editMessageId: null
    };
  },
  computed: {
    ...mapGetters(["getComments"]),
    listComments() {
      if(this.comments.length)
        return this.comments;
      else
        return this.getComments;
    },
    isMyMessage() {
      if (typeof this.me === "undefined") return false;

      if (this.me == "true" || this.me === true) return true;
      else return false;
    }
  },
  methods: {
    ...mapActions(["fetchComments", "deleteComment"]),
    refreshComponent() {
      if(this.referenceId) {
        this.fetchComments(this.referenceId);
      }
      this.$forceUpdate();
    },
    onMessageEdit(id) {
      this.editMessageId = id;
    },
    onMessageDelete(id) {
      this.deleteComment(id);
    },
    onRemoveEditMode(event) {
      this.editMessageId = null;
    }
  },
  mounted() {
    if(this.fetchData && this.referenceId) {
      this.loading = true;
      this.fetchComments(this.referenceId)
        .then(res => (this.loading = false))
        .catch(err => (this.loading = false));
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";

.border-bottom__h {
  border-bottom: 1px solid $borderColorLight;
}
.comments__no-comments {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 1rem;
}
.public-messages {
  background: $greyLight;
  margin-top: 2em;

  .comments__title {
    font-family: $fontPrimaryBold;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-top: 1.5rem;
  }
  .message-item {
    &:first-child {
      background: white;

      .apartment-label {
        background: $blueLightest;
      }
      .message-item__sender {
        font-family: $fontPrimaryBold;
      }
    }
  }
  .message-item {
    padding-left: 1rem;
    padding-right: 1rem;
    &__sender {
      font-size: 1rem;
      margin-bottom: 0.2rem;
    }
    &__time {
      font-family: $fontPrimaryLight;
      font-size: 0.7rem;
    }
  }
}
.conversation-label-yit {
  max-width: 50px;
  vertical-align: baseline;
}
</style>
