import store from "@/store/index";
import { loadLanguageAsync } from "@/plugins/i18n";
import { initializeFeatures } from "@/features";
import { defineAbilitiesFor } from "@/config/ability";

const switchHousingCompany = async (hcId) => {

  if(hcId) {
    let newHc = store.getters.getUserDetails.hcs.find(hc => hc.id == hcId);
    if(newHc)
      await store.dispatch("selectHousingCompany", newHc);
  }
  
  //reset store modules state
  store.commit("resetNotificationSettings");
  store.commit("resetContacts");
  store.commit("resetModule", "News");
  store.commit("resetTimelineModule");
  store.commit("resetCalendarData");
  store.commit("resetMainNavigation");
  store.commit("resetModule", "Partners");
  store.commit("resetModule", "ServiceRequests");
  store.commit("resetCommunityModule");
  store.commit("resetUserState", []);
  store.commit("resetConsumptionData");
  store.commit("resetModule", "MaterialSelection");
  store.commit("resetCongridWarrantyState");
  store.commit("setNeighbours", []);
  store.commit("resetModule", "DocumentsV2");
  store.commit("setSteps", []);

  try {
    await store.dispatch("fetchUserNotifications");
  } catch (error) {
    console.log(error);
  }

  return store
    .dispatch("fetchUserFeatures")
    .then((res) => {
      initializeFeatures();
      //Set User abilities
      defineAbilitiesFor(store.getters.getCurrentUser);

      store.dispatch("fetchContacts");
      store.dispatch("fetchCampaignsForHc");

      loadLanguageAsync(store.getters.getLang).then((result) => {
        return true;
      });
    })
    .catch((err) => {
      console.log(err);
      loadLanguageAsync(store.getters.getLang).then((result) => {
        return true;
      });
    });
};

export default switchHousingCompany;
