<template>
    <button class="offcanvas__toggle" @click="$emit('toggle')" ref="toggleButton">
        <i class="fas fa-bars"></i>
    </button>
</template>

<script>
export default {
    name: 'OffcanvasMenuToggle'
};
</script>

<style lang="scss">
.offcanvas__toggle {
  font-size: 1.3rem;
  padding: .3rem;
}
</style>