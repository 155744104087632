import { createI18n } from 'vue-i18n';
import en from '@/lang/en.json';

export const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: { en }
});

const loadedLanguages = ['en'] // our default language that is preloaded 

function setI18nLanguage (lang) {
  i18n.global.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export const loadLanguageAsync = async (lang) => {
  lang = lang.split('_')[0];
  if (i18n.global.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`).then(msgs => {
        i18n.global.setLocaleMessage(lang, msgs.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    } 
    setI18nLanguage(lang);
  }
  return lang;
}

export default i18n;