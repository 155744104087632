<template>
  <span class="yit-small-icon"><img :src="yitLogo" /></span>
</template>

<script>
import yitLogo from "@/assets/images/yit-logo-dark-grey-blue.svg";
export default {
    name: "YitIcon",
    data() {
        return {
            yitLogo
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
.yit-small-icon {
    display: flex;
    padding: 0;
    line-height: 1;
    align-items: center;
    justify-content: center;
    width: 1.7rem;
    height: 1.7rem;
    img {
        width: 100%;
        height: auto;
        margin-top: -1rem;
    }
}
</style>