<template>
  <div :class="getCssClasses">
    <div class="page-section__header" :class="{'padding--no': !hasPaddingInHeader}" v-if="!hideHeader">
      <slot name="header">
        <h2 v-if="title">{{ title }}</h2>
      </slot>
    </div>
    <div
      class="page-section__body"
      :class="{
        'bg--transparent': unstyled,
        'padding--no': unstyled || !hasPadding
      }"
    >
      <slot></slot>
    </div>
    <div class="page-section__footer" v-if="!hideFooter" :class="{'padding--no': !hasPaddingInFooter}">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageSection",
  props: {
    title: {
      type: String
    },
    unstyled: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    hasPadding: {
      type: Boolean,
      default: true
    },
    hasPaddingInHeader: {
      type: Boolean,
      default: true
    },
    hasPaddingInFooter: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: 'blank'
    }
  },
  computed: {
    getCssClasses() {
      let ret = ['page-section'];
      ret.push('page-section--'+this.theme.trim().toLowerCase());
      return ret;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/variables";
.page-section {
  &--light {
    .page-section__body {
      background: $backgroundColorGreyMid;
    }
  }
  &--outline {
    .page-section__body {
      border: 1px solid $borderColorDefault;
    }
  }
}
</style>