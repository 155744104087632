<template>
    <PageSection class="text-center" :unstyled="unstyled">
        <p class="text--xl" v-if="iconCssClass"><i :class="iconCssClass"></i></p>
        <h1>{{title ? title : $t('common.partial.error-page.title') }}</h1>
        <p>{{description ? description : $t('common.partial.error-page.description')}}</p>
        <p v-if="link && link.title && link.path">
            <router-link :to="link.path">{{link.title}}</router-link>
        </p>
        <slot />
    </PageSection>
</template>

<script>
export default {
    name: "CommonErrorNotification",
    props: {
        title: {
            type: String
        },
        description: {
            type: String
        },
        iconCssClass: {
            type: String
        },
        link:{},
        unstyled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped lang="scss">

</style>