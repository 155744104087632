export default (user, { can, cannot, rules }) => {

  /**
   * Documents
   */
  can(["read", "update", "delete"], ["document", "video"], {
    createdUserUuid: user.id
  });
  can(["read", "create"], "document", {
    folder: {$eq: 'community_all'},
    hcExt: {$in: user.hcExtIds}
  });
  if(user.hasRole('yitBusinessAdmin')) {
    can("manage", ["document", "video"], {
      folder: {$in: ['hc_all', 'apt_all', 'hc_board', 'hc_owners', 'apt_documents', 'apt_owner', 'apt_invoices', 'apt_maintenance', 'apt_tenant', 'partner_company']}
    });
  }
  if(user.hasRole('yitProject') || user.hasRole('yitProjectInternal')) {
    can("manage", ["document", "video"], {
      folder: {$in: ['hc_all', 'apt_all', 'hc_board', 'hc_owners', 'apt_documents']},
      hcExt: {$in: user.hcExtIds}
    });
    can(["create"], ["document", "video"], {
      folder: {$in: ['apt_owner', 'apt_invoices', 'apt_maintenance', 'apt_tenant']},
      hcExt: {$in: user.hcExtIds}
    });
  }
  if(user.hasRole('yitMarketing')) {
    can("manage", ["document", "video"], {
      folder: {$in: ['hc_all', 'apt_all', 'hc_board', 'hc_owners', 'apt_documents', 'partner_company']}
    });
  }

  if (Array.isArray(user.hcs)) {
    user.hcs.map(hc => {

      if(user.hasRole('maintenanceManager')) {
        can(["read", "create"], "document", {
          folder: { $in: ['hc_all', 'apt_all', 'apt_maintenance'] },
          hcExt: hc.hcExt
        });
      }
      if(user.hasRole('ceeMaintenanceManager')) {
        can(["read", "create"], "document", {
          folder: { $in: ['hc_all', 'apt_maintenance'] },
          hcExt: hc.hcExt
        });
      }
      if(user.hasRole('propertyManager')) {
        can(["manage"], "document", {
          folder: { $in: ['hc_all', 'apt_all', 'hc_board', 'hc_owners', 'apt_documents'] },
          hcExt: hc.hcExt
        });
        can(["read", "create"], "document", {
          folder: { $in: ['apt_invoices'] },
          hcExt: hc.hcExt
        });
      }
      if(user.hasRole('chairman') || user.hasRole('boardMember')) {
        can(["manage"], "document", {
          folder: { $in: ['hc_board'] },
          hcExt: hc.hcExt
        });
        can(["read", "create"], "document", {
          folder: { $in: ['hc_all', 'apt_documents'] },
          hcExt: hc.hcExt
        });
        can(["read"], "document", {
          folder: { $in: ['hc_owners', 'apt_all'] },
          hcExt: hc.hcExt
        });
      }

      if (user.hasRole("ceeBoardMember")) {
        can(["read", "create"], "document", {
          folder: { $in: ['hc_board'] },
          hcExt: hc.hcExt
        });
      }

      if (user.hasRole('rentalManager')) {
        can(["read"], ["document"], {
          folder: { $in: ['hc_all', 'apt_all', 'hc_owners'] },
          hcExt: hc.hcExt
        });
      }

      if(Array.isArray(hc.apts)) {
        hc.apts.map(apt => {
          if(user.hasRole('owner')) {
            can(["manage"], "document", {
              folder: {$in: ['apt_owner']},
              aptExt: apt.aptext
            });
            can(["read", "create"], "document", {
              folder: {$in: ['apt_tenant']},
              aptExt: apt.aptext
            });
            can(["read"], "document", {
              folder: {$in: ['hc_owners', 'apt_invoices', 'apt_maintenance']},
              aptExt: apt.aptext
            });
          }
          if (user.hasRole('tenant')) {
            can(["read", "create"], "document", {
              folder: {$in: ['apt_tenant']},
              aptExt: apt.aptext
            });
          }
          if(user.hasRole('rentalManager')) {
            can(["read", "create"], "document", {
              folder: {$in: ['apt_owner', 'apt_documents', 'apt_tenant']},
              hcExt: hc.hcExt
            });
            can(["read"], "document", {
              folder: {$in: ['hc_owners', 'apt_invoices', 'apt_maintenance']},
              hcExt: hc.hcExt
            });
          }
          if(user.hasRole('owner') || user.hasRole('familyMember') || user.hasRole('tenant') || user.hasRole('rentalManager')) {
            can(["read"], "document", {
              folder: {$in: ['hc_all', 'apt_all', 'apt_documents']},
              aptExt: apt.aptext
            });
          }
        })
      }

      /**
       * Managing folders (DHOM)
       */
      can(["manage"], "folder", {
        createdUserUuid: user.id,
        scope: "project"
      }); 
      if (user.hasRole('yitUser') || user.hasRole('propertyManager') || user.hasRole('boardMember') || user.hasRole('chairman')) {
        can(["create"], "folder", {
          projectId: hc.hcExt
        }); 
        can(["create", "update", "delete"], "folder", {
          projectId: hc.hcExt,
          scope: "project"
        }); 
      }

    });
  }

  cannot(["delete", "folder"], {
    scope: 'global'
  });

  /**
   * Video management
   */
  if(user.hasRole('yitAdmin')) {
    can("manage", "video");
  }

  if (user.hasRole("yitProject") || user.hasRole('yitProjectInternal')) {
    if (Array.isArray(user.hcs)) {
      user.hcs.map(hc => {
        can("manage", "video", {
          projectId: hc.hcExt
        });
      });
    }
  }
}