import BaseService from "./BaseService";
import {AzureIOTAppClient, includeJwt} from "./clients/AzureIOTAppClient";


export default class IOTAppService extends BaseService {
  constructor() {
    super();
    includeJwt();
  }
  
  /**
   * 
   * @param {*} aptExtId 
   * @param {*} params 
   */
  fetchMovementSensorDataByApartment(params = {}) {
    if(typeof params !== 'object')
        params = {};

    if (!params.aptExtId && !params.deviceId)
      throw "aptExtId or deviceId is required to fetch sensor data.";

    let query = this._buildUrl(`/getData`, params);
    return AzureIOTAppClient.get(query);
  }

  fetchMovementSensorDevicesByApartment(aptExtId = null) {
    let query = this._buildUrl(`/getDevices`, {aptExtId: aptExtId});
    return AzureIOTAppClient.get(query);
  }

  fetchIotDevices(params = {}) {
    let query = this._buildUrl(`/getDevices`, params);
    return AzureIOTAppClient.get(query);
  }

  async addIotDevice(payload) {
    return AzureIOTAppClient.post('/device/', payload);
  }

  async saveIotDevice(deviceId, payload) {
    return AzureIOTAppClient.put(`/device/${deviceId}`, payload);
  }

  async deleteIotDevice(deviceId) {
    return AzureIOTAppClient.delete(`/device/${deviceId}`);
  }

  async fetchDeviceTypes() {
    return AzureIOTAppClient.get(`/deviceTypes`);
  }
}