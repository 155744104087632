<template>
  <router-link
    :to="url"
    class="content-pick padding--no content-pick--box bg--rose30"
    :class="{'content-pick--disabled': this.disabled}"
  >
    <div class="content-pick__highlight bg--red" v-if="icon">
      <span class="content-pick__highlight__icon">
        <i :class="icon"></i>
      </span>
    </div>
    <div
      class="content-pick__highlight bg--red"
      v-else-if="startTime"
    >
      <span class="value" v-if="!isToday()">{{ $filters.format_date(startTime, 'D') }}</span>
      <span class="unit" v-if="isToday()">{{$t('common.text.today')}}</span>
      <span class="unit" v-else>{{$filters.format_date(startTime, 'MMM')}}</span>
    </div>
    <div class="content-pick__content">
      <div
        v-if="startTime && endTime"
        class="content-pick__headline"
      >{{ printDatesBetween(startTime, endTime, "short") }}</div>
      <div
        v-else-if="startTime && !endTime"
        class="content-pick__headline"
      >{{ $filters.date_format_ordinal(startTime) }}</div>
      <h1 v-if="title" class="content-pick__title text--break-long-words">{{ title }}</h1>
      <slot></slot>
    </div>
    <div class="content-pick__arrow-link">
      <i class="far fa-chevron-right"></i>
    </div>
  </router-link>
</template>

<script>
import { isToday, isTodayBetween, printDatesBetween } from "@/utils/DateHelper";

export default {
  name: "EventListItem",
  props: ["title", "startTime", "endTime", "icon", "url", "color", "disabled"],
  computed: {
    retrieveHighlightAreaCssClass() {
      if (!this.color) return "";
      return "bg--" + this.color;
    }
  },
  methods: {
    isToday() {
      if (
        isToday(this.startTime) ||
        isTodayBetween(this.startTime, this.endTime)
      )
        return true;

      return false;
    },
    printDatesBetween
  }
};
</script>
<style lang="scss" scoped>
.content-pick {
  background-color: #fff;
}
</style>