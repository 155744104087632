export default [
  {
    path: "/my-apartment/consumption",
    name: "/my-apartment/consumption",
    meta: {
      sectionTitle: "navigation.water.consumption",
      title: "navigation.water.consumption",
      accessPolicies: {
        features: ['consumption']
      }
    },
    component: () =>
      import(/* webpackChunkName: "consumption" */ "@/views/SmartHome/Consumption/Index.vue"),
    children: [
      {
        path: "/my-apartment/consumption/water",
        name: "/my-apartment/consumption/water",
        component: () =>
          import(
            /* webpackChunkName: "consumption" */ "@/views/SmartHome/Consumption/Water.vue"
          ),
        meta: {
          title: "navigation.community",
          sectionTitle: "navigation.community",
          accessPolicies: {
            features: ['consumption']
          }
        }
      },
      {
        path: "/my-apartment/consumption/electricity",
        name: "/my-apartment/consumption/electricity",
        component: () =>
          import(
            /* webpackChunkName: "consumption" */ "@/views/SmartHome/Consumption/Electricity.vue"
          ),
        meta: {
          title: "navigation.community",
          sectionTitle: "navigation.community",
          accessPolicies: {
            features: ['consumption']
          }
        }
      },
      {
        path: "/my-apartment/consumption/temperature",
        name: "/my-apartment/consumption/temperature",
        component: () =>
          import(
            /* webpackChunkName: "consumption" */ "@/views/SmartHome/Consumption/Temperature.vue"
          ),
        meta: {
          title: "navigation.community",
          sectionTitle: "navigation.community",
          accessPolicies: {
            features: ['consumption']
          }
        }
      },
    ]
  },
  {
    path: "/my-apartment/leanheat",
    name: "/my-apartment/leanheat",
    component: () =>
      import(
        /* webpackChunkName: "my-apartment" */ "@/components/SmartHome/Leanheat.vue"
      ),
    meta: {
      title: "navigation.myapartment"
    }
  },
  {
    path: "/admin/consumption/settings/:id",
    name: "/admin/consumption/settings/:id",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/Admin/Consumption/Settings/Main.vue"
      ),
    meta: {
      sectionTitle: "admin.consumption.settings.title",
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/admin/consumption",
    name: "/admin/consumption",
    meta: {
      sectionTitle: "navigation.consumption"
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-consumption" */ "@/views/Admin/Consumption/Index.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default"
    },
    children: [
      {
        path: "water/metervalue",
        name: "water/metervalue",
        component: () =>
          import(
            /* webpackChunkName: "admin-consumption" */ "@/views/Admin/Consumption/Water.vue"
          ),
        meta: {
          title: "navigation.consumption.water",
          sectionTitle: "navigation.consumption.water",
          layout: "admin-default",
          admin: true
        }
        },
      {
        path: "water",
        name: "water",
        component: () =>
          import(
            /* webpackChunkName: "admin-consumption" */ "@/views/Admin/Consumption/Water.vue"
          ),
        meta: {
          title: "navigation.consumption.water",
          sectionTitle: "navigation.consumption.water",
          layout: "admin-default",
          admin: true
        }
      },
      {
        path: "electricity",
        name: "electricity",
        component: () =>
          import(
            /* webpackChunkName: "admin-consumption" */ "@/views/Admin/Consumption/Electricity.vue"
          ),
        meta: {
          title: "navigation.consumption.electricity",
          sectionTitle: "navigation.consumption.electricity",
          layout: "admin-default",
          admin: true
        }
      },
      {
        path: "temperature",
        name: "temperature",
        component: () =>
          import(
            /* webpackChunkName: "admin-consumption" */ "@/views/Admin/Consumption/Temperature.vue"
          ),
        meta: {
          title: "navigation.consumption.temperature",
          sectionTitle: "navigation.consumption.temperature",
          layout: "admin-default",
          admin: true
        }
      }
    ]
  }
]