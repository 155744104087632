import BaseService from "./BaseService";
import store from "@/store";
import {AzureLiferayClient} from "./AzureLiferayClient";
import {
  AzureFunctionClient
} from "./AzureFunctionClient";

export default class FeaturesService extends BaseService {
  constructor() {
    super();
    this.liferayBaseUrl = "/delegate/yithome-api/functions";
  }

  /**
   * Override BaseService function
   */
  routeViaLiferay() {
    return false;
  }

  /**
   * Fetch mapped object what features user has
   */
  fetchUserFeatures(buildingTaxonomyString = "") {
    if (!buildingTaxonomyString) buildingTaxonomyString = store.getters.getBuildingTaxonomyFilters.toString();
    if (!buildingTaxonomyString) buildingTaxonomyString = "global";

    let url = "/getUserFeatures"
    if (buildingTaxonomyString)
      url += "?buildingTaxonomies=" + buildingTaxonomyString;
    return AzureFunctionClient.get(url);
    
  }

  /**
   * Fetch all feature documents from db (grouped by building taxonomy)
   */
  fetchFeatures(queryParams = {}) {
    return AzureFunctionClient.get(this._buildUrl("/featureToggle", queryParams));
  }

  /**
   * Save Feature
   */
  postFeature(formData, queryParams = {}) {
    return AzureFunctionClient.post(
      this._buildUrl("/featureToggle", queryParams),
      formData
    );
  }

  /**
   * Save Feature
   */
  updateFeature(formData, queryParams = {}) {
    return AzureFunctionClient.put(
      this._buildUrl("/featureToggle", queryParams),
      formData
    );
  }

  /**
   * Save Feature
   */
  deleteFeature(id, queryParams = {}) {
    return AzureFunctionClient.delete(this._buildUrl("/featureToggle", queryParams), {
      _id: id
    });
  }
}
