import LeanheatService from "@/services/LeanheatService";

const api = new LeanheatService();

const getDefaultState = () => {
  return {
    leanheatData: {},
    availableData: {}
  };
};

const state = getDefaultState();

const mutations = {
  setLeanheatData: (state, data) => {
    state.leanheat = data;
  },
  setAvailableData: (state, data) => {
    state.availableData = data;
  }
};

const actions = {
  fetchLeanheatData({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .fetchLeanheatData(params)
        .then(response => {
          console.log("Fetched Leanheat Data: ", response);
          commit("setLeanheatData", response);
          resolve(response);
        })
        .catch(err => {
          console.log("Failed fetching Leanheat data: ", err);
          reject(err);
        });
    });
  },
  fetchAvailableData({ commit }, aptId) {
    return new Promise((resolve, reject) => {
      api
        .fetchAvailableData(aptId)
        .then(response => {
          console.log("Fetched Available Leanheat Data: ", response);
          commit("setAvailableData", response);
          resolve(response);
        })
        .catch(err => {
          console.log("Failed fetching Available Leanheat data: ", err);
          reject(err);
        });
    });
  }
  ,
  fetchSiteMetadata() {
    return new Promise((resolve, reject) => {
      api
        .fetchSiteMetadata()
        .then(response => {
          console.log("Fetched Available Leanheat Site MetaData: ", response);
          resolve(response);
        })
        .catch(err => {
          console.log("Failed fetching Available eanheat Site MetaData: ", err);
          reject(err);
        });
    });
  }
  ,
  fetchSiteApartmentMetadata({ commit }, siteName) {
    return new Promise((resolve, reject) => {
      api
        .fetchSiteApartmentMetadata(siteName)
        .then(response => {
          console.log("Fetched Available Leanheat Apartment MetaData: ", response);
          resolve(response);
        })
        .catch(err => {
          console.log("Failed fetching Available Leanheat Apartment MetaData: ", err);
          reject(err);
        });
    });
  }
};

const getters = {
  getLeanheatData: state => state.leanheatData,
  getAvailableData: state => state.availableData
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
