import BaseService from "./BaseService";
// import ATPClient from "./clients/ATPClient";
import AzureFunctionClient from "./AzureFunctionClient";

export default class ATPService extends BaseService {
  constructor() {
    super();
    this.routeViaLiferay = true;
  }

  getLatest(params = null) {
    if(!params) {
        params = {}
    }

    params.categories = '6';
    return this.getPosts(params);
  }

  getPopular(params = null) {
    if(!params) {
        params = {}
    }

    params.categories = '4';
    return this.getPosts(params);
  }

  getPosts(_params = null) {
    
    if(!_params)
      _params = {};

    //copy the param object
    let params = Object.assign({}, _params);
    
    if(!params.per_page)
        params.per_page = 10;
    if(!params.offset)
        params.offset = 0;
        
    // params.koodi = process.env.VUE_APP_ATP_API_CODE;
    // params.status = 'publish';
    // params.orderby = 'date';
    // params.order = 'desc';
    //params.page = 1;    //should this be static or use as "pagination"?

    if(params.offset == 0)
      delete params.offset;

    if(params.append)
      delete params.append;
    
      let query = this._buildUrl('/getArticlesFromAsumisenTietopankki', params);
      // let query = this._buildUrl('/posts', params);

      return AzureFunctionClient.get(query); 
      // return ATPClient.get(query); 
    //'/posts?koodi='++'&context=view&categories='+categories+'&page=1&per_page='+limit+'&offset='+offset+'&order=desc&orderby=date&status=publish');
  }

  // getImagePath(id) {
  //   return ATPClient.get(`/media/${id}?koodi=${process.env.VUE_APP_ATP_API_CODE}`);
  // }

  getPost(id) {
    return AzureFunctionClient.get(`/getArticlesFromAsumisenTietopankki?id=${id}`);
    // return ATPClient.get(`/posts/${id}?koodi=${process.env.VUE_APP_ATP_API_CODE}`);
  }
}