<template>
  <div class="message-edit-form" v-if="newMessage">
    <form>
      <AlertMessage type="error" v-if="errorMessage">{{errorMessage}}</AlertMessage>
      <FormInput
        type="textarea"
        name="message"
        rows="4"
        v-model="newMessage.body"
        @input="errorMessage=''"
        v-if="messageFieldName == 'body'"
      />
      <FormInput
        type="textarea"
        name="message"
        rows="4"
        v-model="newMessage.message"
        @input="errorMessage=''"
        v-else
      />
      <div class="button-group text-center margin--no-bottom">
        <button
          type="button"
          value="submit"
          class="button button--primary"
          @click="submitChanges"
        >{{$t('common.save')}}</button>
        <button class="button button--link" @click="onCancel">{{$t('common.cancel')}}</button>
      </div>
    </form>
  </div>
</template>

<script>
import AlertMessage from "@/components/common/AlertMessage";
import Form from "@/utils/FormUtil";
import FormInput from "@/components/common/Form/Input";
import { mapActions } from "vuex";

export default {
  name: "MessageEditForm",
  props: {
    message: {
      type: Object
    },
    type: {
      type: String,
      default: 'message'
    },
    objectId: {
      type: String
    },
    messageFieldName: {
      type: String
    }
  },
  components: { FormInput, AlertMessage },
  data() {
    return {
      newMessage: null,
      errorMessage: "",
      originalMessageText: ""
    };
  },
  computed: {
    getMessageFieldName() {
      if(this.messageFieldName)
        return this.messageFieldName;
      else if(this.type == 'conversation')
        return 'body';
      return 'message';
    }
  },
  methods: {
    ...mapActions(["updateMessage", "updateConversation"]),
    submitChanges(e) {

      switch(this.type) {
        case 'message':
          this.updateMessage(this.newMessage)
          .then(res => {
            console.log(res);
            if (res && res.data.item) this.$emit("close");
            else this.showErrorMessage(this.$t("comments.errorSavingComment"));
          })
          .catch(err => {
            console.log(err);
            this.showErrorMessage(this.$t("comments.errorSavingComment"));
          });
          break;

        case 'conversation':

          this.updateConversation({_id: this.objectId ? this.objectId : this.$route.params.id, newestMessage: this.newMessage})
          .then(res => {
            console.log(res);
            if (res && res.data) this.$emit("close");
            else this.showErrorMessage(this.$t("comments.errorSavingComment"));
          })
          .catch(err => {
            console.log(err);
            this.showErrorMessage(this.$t("comments.errorSavingComment"));
          });

          break;
      }

    },
    showErrorMessage(message) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 2000);
    },
    onCancel(e) {
      this.message[this.getMessageFieldName] = this.originalMessageText;
      this.$emit("close", e);
    }
  },
  mounted() {
    if (this.message) {
      this.newMessage = this.message;
      this.originalMessageText = this.message[this.getMessageFieldName];
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
