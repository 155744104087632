<template>
  <div class="comment-form" v-if="newComment">
    <form>
      <AlertMessage type="error" v-if="errorMessage">{{errorMessage}}</AlertMessage>
      <FormInput
        type="textarea"
        name="message"
        rows="4"
        v-model="newComment.body"
        @input="errorMessage=''"
      />
      <div class="button-group text-center margin--no-bottom">
        <button
          type="button"
          value="submit"
          class="button button--primary"
          @click="submitChanges"
        >{{$t('common.save')}}</button>
        <button class="button button--link" @click="$emit('close', $event)">{{$t('common.cancel')}}</button>
      </div>
    </form>
  </div>
</template>

<script>
import AlertMessage from "@/components/common/AlertMessage";
import Form from "@/utils/FormUtil";
import FormInput from "@/components/common/Form/Input";
import { mapActions } from "vuex";

export default {
  name: "CommentEditForm",
  props: {
    comment: {
      type: Object
    }
  },
  components: { FormInput, AlertMessage },
  data() {
    return {
      newComment: null,
      errorMessage: ""
    };
  },
  methods: {
    ...mapActions(["updateComment"]),
    submitChanges(e) {
      this.updateComment(this.newComment)
        .then(res => {
          if (res && res.data && res.data.item) this.$emit("close");
          else this.showErrorMessage(this.$t("comments.errorSavingComment"));
        })
        .catch(err => {
          this.showErrorMessage(this.$t("comments.errorSavingComment"));
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 2000);
    }
  },
  mounted() {
    if (this.comment && this.comment._id) {
      this.newComment = Object.assign({}, this.comment);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
