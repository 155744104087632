<template>
  <transition name="modal">
    <div class="image-preview-mask">
      <a class="image-preview__close" @click="$emit('close')">    
        <i class="fas fa-times"></i>
        <span class="sr-only">{{ $t("common.closeButton") }}</span>
      </a>
      <div class="image-preview__navigation" v-if="hasNavigation">
        <button type="button" class="image-preview__navigation__prev" @click="onNavigatePrevious">
          <i class="fal fa-angle-left"></i>
          <span class="sr-only">{{ $t("common.previous") }}</span>
        </button>
        <button type="button" class="image-preview__navigation__next" @click="onNavigateNext">
          <i class="fal fa-angle-right"></i>
          <span class="sr-only">{{ $t("common.next") }}</span>
        </button>
      </div>
      <div class="image-preview">
        <transition name="fade">
          <img :src="getCurrentImage" v-if="getCurrentImage" :key="pos" v-on:load="onLoaded" v-show="mainImageLoaded" />
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  component: "ImagePreviewModal",
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: () => []
    },
    loop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showModal: false,
      pos: 0,
      mainImageLoaded: false
    };
  },
  computed: {
    hasNavigation() {
      return this.multiple || this.images.length;
    },
    getCurrentImage() {
      return this.hasNavigation ? this.images[this.pos] : this.imageSrc
    }
  },
  methods: {
    onLoaded() {
      this.mainImageLoaded = true;
    },
    onNavigatePrevious(e) {
      if(e)
        e.preventDefault();
      this.navigate('prev');
    },
    onNavigateNext(e) {
      if(e)
        e.preventDefault();
      this.navigate('next');
    },
    navigate(direction = 'next') {
      this.mainImageLoaded = false;
      console.log("navigating: ", direction, this.pos);
      if(direction == 'prev') {
        if(this.pos === 0) {
          if(this.loop) {
            this.pos = this.images.length -1;
          }
        } else 
          this.pos--;
      }
      else if (direction = 'next') {
        if(this.pos == this.images.length-1) {
          if(this.loop) {
            this.pos = 0;
          }
        } else 
          this.pos++;
      }
    },
    bindKeys(e) {
      let keyCode = e.keyCode ? e.keyCode : '';
      let keyName = e.key ? e.key : e.code;

      if(this.multiple && this.images.length) {
        if(keyCode == 37)
          this.onNavigatePrevious();
        if(keyCode == 39)
          this.onNavigateNext();
      }
      if(keyName.toLowerCase() == 'escape')
        this.$emit('close');
    }
  },
  mounted() {
    if(this.multiple && this.images.length) {
      if(this.imageSrc)
        this.pos = this.images.findIndex(i => i === this.imageSrc);
    }
    window.addEventListener('keyup', this.bindKeys);
  },
  beforeUnmount: function () {
    window.removeEventListener('keyup', this.bindKeys);
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
.image-preview-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.65);
  transition: opacity 0.3s ease;
  top: 0;
  left: 0;
}

.modal-enter .image-preview,
.modal-leave-active .image-preview {
  @media screen and (max-width: $breakpoint-md-down) {
    transform: scale(1.1);
  }
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  @media screen and (max-width: $breakpoint-md-down) {
    transform: scale(1.1);
  }
}

.image-preview {
  
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  transform: translateY(-50%) translateX(-50%);
  top: 50%;
  position: absolute;
  left: 50%;

  &__close {
    position: absolute;
    color: $brandColorPrimary;
    right: 1rem;
    top: 1rem;
    z-index: 102;
    background: #fff;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      margin: 0;
      &:before {
        margin-left: -.1rem;
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      i:before {
        margin-left: 0;
      }
    }
  }

  img {
    top: 5rem;
    max-width: 90%;
    max-height: 75%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    height: auto;

    /*img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      object-fit: cover;
    }*/

    @media screen and (min-width: $breakpoint-md) {
      max-width: 75%;
      max-height: 75%;
    }
    @media screen and (min-width: $breakpoint-xl) {
      max-width: 1200px;
      max-height: 75%;
    }
  }

  &__navigation {
    position: absolute;
    height: 100%;
    width: 100%;
    button {
      position: absolute;
      color: #fff;
      border: 0;
      padding: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 5rem;
      z-index: 103;
    }
    &__prev {
      left: 5%;
    }
    &__next {
      right: 5%;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    max-width: 75%;
    max-height: 75%;
  }
   @media screen and (min-width: $breakpoint-xl) {
    max-width: 1200px;
    max-height: 75%;
  }
}
</style>
