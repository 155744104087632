const atpFeature = {
    id: "atp-feed",
    name: "feature.atp-feed.name",
    iconClass: "fal fa-newspaper",
    globalSettings: {},
    schema: {
      
    }
};

export { atpFeature };