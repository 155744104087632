<template>
  <div class="comment-count">
    <i class="far fa-comment-alt"></i>
    {{ count }}
  </div>
</template>

<script>
import CommentService from "@/services/CommentService";
import {mapActions, mapGetters} from "vuex";
import { isElementInViewport } from "@/utils/ViewportUtil";
import _ from "lodash";

export default {
  name: "CommentCount",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      api: new CommentService(),
      count: 0,
      loading: false,
      fetched: false
    };
  },
  computed: {
    ...mapGetters(['isLikesFetchedForArticle', 'getNewsArticleCommentCount', 'getNewsArticleLikes'])
  },
  methods: {
    ...mapActions(['fetchNewsItemCommentCount']),
    getCommentCount() {
      if(this.fetched || this.loading || this.isLikesFetchedForArticle(this.id)) return;
      if (!this.id) return;
      this.loading = true;
      this
        .fetchNewsItemCommentCount(this.id)
        .then(res => {
          if (res.count) this.count = parseInt(res.count);
          else {
            if (Array.isArray(res) && res.length > 0)
              this.count = res.length;
          }
          this.loading = false;
          this.fetched = true;
          this.$emit('on-update', res.likes);
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },
    checkIsElementInViewPort() {
      let el = this.$el;
      if(el && isElementInViewport(el)) {
        this.getCommentCount();
      }
    }
  },
  mounted() {
    this.count = this.getNewsArticleCommentCount(this.id);
    let likes = this.getNewsArticleLikes(this.id);
    if(likes.length) {
      this.$emit('on-update', likes);
    }
    if (window.addEventListener) {
      addEventListener("scroll", _.debounce(this.checkIsElementInViewPort, 500), false);
      addEventListener("resize", _.debounce(this.checkIsElementInViewPort, 500), false);
    }
  },
  beforeUnmount() {
    if (window.removeEventListener) {
      removeEventListener("scroll", _.debounce(this.checkIsElementInViewPort));
      removeEventListener("resize", _.debounce(this.checkIsElementInViewPort));
    }
  }
};
</script>