<template>
  <Modal @close="$emit('close')" class="likes-modal">
    <h3>{{$t('common.likes')}}</h3>
    <ul class="likes-modal__list">
      <li class="liked-user-entry" v-for="user in getPublicUsersLikes" :key="user._id">
        <span>{{user.name}}</span>
        <div class="tag-label">{{user.apt}}</div>
      </li>
    </ul>

    <p v-if="getPrivateProfileUsersCount > 0">{{$t('common.likes.unknown', [privateProfileUsersCount])}}</p>

  </Modal>
</template>

<script>
import Modal from "@/components/common/Modal";
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LikesModal',
  components: { Modal},
  props: {
    likes: {type: Array, default: () => []}
  },
  data() {
    return {
      publicProfileUsersCount: 0,
      privateProfileUsersCount: 0
    }
  },
  computed: {
    getPublicUsersLikes() {
      return this.likes.filter(u => u.name);
    },
    getPrivateProfileUsersCount() {
      return this.privateProfileUsersCount;
    }
  },
  mounted() {
    if(this.likes.length) {
      this.publicProfileUsersCount = this.likes.filter(u => u.name).length;
      this.privateProfileUsersCount = this.likes.length - this.publicProfileUsersCount;
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";
.likes-modal {
  color: $baseFontColor;
  font-family: $fontPrimaryRegular;
  cursor: auto !important;
  font-size: .9rem;

  .modal-container {
    max-width: 400px;
    cursor: auto !important;
    h1,h2,h3 {
      margin-top: 0;
    }
    .modal__body {
      margin-top: .45rem;
    }
  }
  .tag-label {
    color: inherit;
    margin-left: .5rem;
  }
}
</style>