import store from "@/store";

const materialSelectionFeature = {
    id: "material-selection",
    name: "feature.material-selection.name",
    iconClass: "fal fa-wrench",
    globalSettings: {},
    schema: {
        /*showMainNavigationLink: {
          type: Boolean,
          label: "feature.common.showMainNavigationLink.label",
          default: true,
          required: true
        }*/
        disableStudioIntegration: {
            type: Boolean,
            label: "feature.material-selection.disableStudioIntegration.label",
            default: false,
            required: false
        }
    },
    initialize: (feature, settings) => {
        if (store.getters.getCurrentHcState == 'construction') {
            store.dispatch("addNavigationLink", {
                titleLanguageKey: "navigation.material-selection",
                href: "/material-selection",
                iconClass: "fal fa-wrench",
                hide: false,
                orderNumber: 15
            });
        }
    }
};

export { materialSelectionFeature };