import KenticoDeliveryApiService from "@/services/KenticoDeliveryApiService";
import ArticleService from "@/services/ArticleService";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import store from "@/store";

dayjs.extend(utc);

const api = new KenticoDeliveryApiService();
const articleApi = new ArticleService();

api.targetByDefaultBuildingTaxonomy = true; //target default by building taxonomy
const settings = {
  articleType: "news",
  buildingTaxonomy: "news"
};

const getDefaultState = () => {
  return {
    news: [],
    announcements: [],
    latestNews: [],
    statusUpdates: [],
    latestStatusUpdates: [],
    currentNewsArticle: {},
    yitCorporateArticles: [],
    welcomeArticle: null
  };
};

const state = getDefaultState();

const getters = {
  getNews: state => state.news,
  getLatestNews: state => state.latestNews,
  getStatusUpdates: state => state.statusUpdates,
  getLatestStatusUpdates: state => state.latestStatusUpdates,
  getAnnouncements: state => state.announcements,
  getCurrentNewsArticle: state => state.currentNewsArticle,
  getYitCorporateArticles: state => state.yitCorporateArticles,
  getWelcomeArticle: state => state.welcomeArticle,
  getWelcomeArticleAsArray: state => state.welcomeArticle ? [state.welcomeArticle] : []
};

const actions = {
  /**
   *
   * @param {*} param
   * @param {*} append append news to existing array
   * @param {*} mode normal|admin
   */
  fetchNews({ commit }, params = {}) {

    setDefaultFilters();

    params.articleTypes = ['news', 'status_update', 'announcement', 'priority_news', 'marketing'];
    let append = params.append === true ? true : false;

    return new Promise(function(resolve, reject) {
      api
        .fetchList(params)
        .then(response => {

          //filter welcome message
          let items = response.data.items.filter(i => i && i.system && i.system.codename && i.system.codename != process.env.VUE_APP_KENTICO_WELCOME_ARTICLE_CODENAME);

          if (append) commit("appendNews", items);
          else commit("setNews", items);

          resolve(items);
        })
        .catch(err => {
          console.log("Fetching news failed: ", err);
          reject(err);
        });
    });
  },
  fetchAnnouncements({ commit }, params = {}) {
    
    setDefaultFilters();

    params.articleTypes = "announcement";
    let append = params.append === true ? true : false;


    return new Promise(function(resolve, reject) {
      api
        .fetchList(params)
        .then(response => {
          if (append) commit("appendAnnouncements", response.data.items);
          else commit("setAnnouncements", response.data.items);

          resolve(response.data.items);
        })
        .catch(err => {
          console.log("Fetching news failed: ", err);
          reject(err);
        });
    });
  },
  fetchStatusUpdates({ commit }, params = {}) {

    setDefaultFilters();

    params.articleTypes = "status_update";

    return new Promise(function(resolve, reject) {
      api
        .fetchList(params)
        .then(response => {
          commit("setStatusUpdates", response.data.items);
          resolve(response.data.items);
        })
        .catch(err => {
          console.log("Fetching news failed: ", err);
          reject(err);
        });
    });
  },
  fetchLatestStatusUpdates({ commit }) {

    setDefaultFilters();

    //fetch first sticky items and if there's not enough them, fetch normal
    return new Promise((resolve, reject) => {
      let items = [];
      let ids = [];
      /*api
        .fetchList({
          limit: 2,
          articleTypes: ["priority_news"]
        })
        .then(priorityNews => {
          priorityNews.data.items.map(_item => {
            items.push(_item);
            ids.push(_item.system.id);
          });*/
      
          api.addFilter(
            "publish_date",
            dayjs().subtract(1, 'year').utc().format(),
            "gte"
          );
      
          api
            .fetchList({
              limit: 5,
              articleTypes: ["status_update", "announcement", "priority_news", "marketing"],
              sticky: true
            })
            .then(stickyResults => {
              stickyResults.data.items.map(_item => {
                if (!ids.includes(_item.system.id)) {
                  items.push(_item);
                  ids.push(_item.system.id);
                }
              });

              if (items.length < 5) {
                return api
                  .fetchList({
                    limit: 5,
                    articleTypes: ["status_update","announcement", "priority_news", "marketing"],
                  })
                  .then(results => {

                    results.data.items.map(_item => {
                      if (!ids.includes(_item.system.id)) {
                        items.push(_item);
                        ids.push(_item.system.id);
                      }
                    });

                    //filter welcome message
                    items = items.filter(i => i && i.system && i.system.codename && i.system.codename != process.env.VUE_APP_KENTICO_WELCOME_ARTICLE_CODENAME);

                    commit("setLatestStatusUpdates", items);
                    resolve(items);
                  })
                  .catch(err => {
                    console.log("Fetching news failed: ", err);
                  });
          } else {
            commit("setLatestStatusUpdates", items);
            resolve(items);
          }
        })
        .catch(err => {
          console.log("Error getting sticky status updates, ", err);
          reject(err);
        });
      /*})
      .catch(err => {
        console.log("Error getting sticky status updates, ", err);
        reject(err);
      });*/
        
    });
  },
  fetchLatestNews({ commit }) {
    
    setDefaultFilters();
    
    return new Promise((resolve, reject) => {
      let items = [];
      let ids = [];
      
      //fetch first priority news, then sticky items and if there's not enough them, fetch normal
      /*api
        .fetchList({
          limit: 2,
          articleTypes: ["priority_news"]
        })
        .then(priorityNews => {
          priorityNews.data.items.map(_item => {
            items.push(_item);
            ids.push(_item.system.id);
          });*/
          
          api.addFilter(
            "publish_date",
            dayjs().subtract(1, 'year').utc().format(),
            "gte"
          );
      
          api
            .fetchList({
              limit: 5,
              articleTypes: ["news","announcement", "status_update", "priority_news", "marketing"],
              sticky: true
            })
            .then(stickyResults => {
              stickyResults.data.items.map(_item => {
                if (!ids.includes(_item.system.id)) {
                  items.push(_item);
                  ids.push(_item.system.id);
                }
              });

              //filter welcome message
              items = items.filter(i => i && i.system && i.system.codename && i.system.codename != process.env.VUE_APP_KENTICO_WELCOME_ARTICLE_CODENAME);

              if (items.length < 5) {
                return api
                  .fetchList({
                    limit: 5,
                    articleTypes: ["news","announcement", "status_update", "priority_news", "marketing"]
                  })
                  .then(results => {

                    results.data.items.map(_item => {
                      if (!ids.includes(_item.system.id)) {
                        items.push(_item);
                        ids.push(_item.system.id);
                      }
                    });

                    //filter welcome message
                    items = items.filter(i => i && i.system && i.system.codename && i.system.codename != process.env.VUE_APP_KENTICO_WELCOME_ARTICLE_CODENAME);

                    commit("setLatestNews", items);
                    resolve(items);
                  })
                  .catch(err => {
                    console.log("Fetching news failed: ", err);
                  });
              } else {
                commit("setLatestNews", items);
                resolve(items);
              }
            })
            .catch(err => {
              console.log("Error getting sticky news, ", err);
              reject(err);
            });
        /*}).catch(err => {
          console.log("Error getting priority news, ", err);
          reject(err);
        })*/
    });
  },
  fetchSingleNewsArticle({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .fetchSingle(id)
        .then(response => {

          let hcStartDate = store.getters.getSelectedHousingCompany && store.getters.getSelectedHousingCompany.projectCreatedDate ? store.getters.getSelectedHousingCompany.projectCreatedDate : null;
          if (hcStartDate && response.data.item && response.data.item.system.codename === process.env.VUE_APP_KENTICO_WELCOME_ARTICLE_CODENAME) {
            response.data.item.elements.publish_date.value = hcStartDate;
          }

          commit("setCurrentNewsArticle", response.data.item);
          resolve(response);
        })
        .catch(err => {
          console.log("Fetching single news article failed: ", err);
          reject(err);
        });
    });
  },
  fetchWelcomeArticle({ commit }) {

    if(store.getters.getCurrentHcState && (store.getters.getCurrentHcState != 'construction'))
      return;
    
    let codename = process.env.VUE_APP_KENTICO_WELCOME_ARTICLE_CODENAME;
    let hcStartDate = store.getters.getSelectedHousingCompany && store.getters.getSelectedHousingCompany.projectCreatedDate ? store.getters.getSelectedHousingCompany.projectCreatedDate : null;

    if(!hcStartDate)
      return;
    
    return new Promise((resolve, reject) => {
      api
        .fetchSingle(codename)
        .then(response => {
          let item = response.data.item;
          if(item && item.elements && item.elements.publish_date) {
            item.elements.publish_date.value = hcStartDate;
          }
          commit("setWelcomeArticle", response.data.item);
          resolve(response);
        })
        .catch(err => {
          console.log("Fetching single news article failed: ", err);
          reject(err);
        });
    });
  },
  async fetchYitCorporateArticles({commit}, params) {
    try {
      let articles = [];
      let articleResponse = await articleApi.fetchYitCorporateArticles(params);
      if(articleResponse && articleResponse.data)
        articles = articleResponse.data;

      commit("setYitCorporateArticles", articleResponse.data);
      return articleResponse.data;
    } catch(err) {
      console.log(err);
      throw err;
    }
  }
};

const setDefaultFilters = () => {
  api.resetFilters();
  let today = new Date();
  api.addFilter(
    "publish_date",
    dayjs(today).utc().format(),
    "lte"
  );
};

const mutations = {
  setNews: (state, res) => (state.news = res),
  appendNews: (state, res) => (state.news = state.news.concat(res)),
  setAnnouncements: (state, res) => (state.announcements = res),
  appendAnnouncements: (state, res) =>
    (state.announcements = state.announcements.concat(res)),
  setLatestNews: (state, res) => (state.latestNews = res),
  setStatusUpdates: (state, res) => (state.statusUpdates = res),
  setLatestStatusUpdates: (state, res) => (state.latestStatusUpdates = res),
  appendStatusUpdates: (state, res) =>
    (state.statusUpdates = state.statusUpdates.concat(res)),
  setCurrentNewsArticle: (state, res) => (state.currentNewsArticle = _enrichPostingData(res)),
  setYitCorporateArticles: (state, res) => (state.yitCorporateArticles = res),
  setWelcomeArticle: (state, res) => (state.welcomeArticle = res)
};

/**
 * Enrich kentico item data
 * - Add default image if no images are added
 */
const _enrichPostingData = (item) => {
  /*if (item && item.system && item.elements) {
    //add default image if no images are added
    if (item.elements.images && item.elements.images.value.length === 0) {
      item.elements.images.value.push({
        description: "",
        width: 1200,
        height: 800,
        name: item.elements.title.value,
        size: 500378,
        type: "image/jpeg",
        url: defautNewsPostingImage
      })
    }
  }*/
  return item;
}

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
