import base from './base';
import admin from './admin';
import community from './community';
import supportCenter from './support-center';
import userManagement from './user-management';
import news from './news';
import calendar from './calendar';
import contacts from './contacts';
import serviceLounge from './service-lounge';
import consumption from './consumption';
import warranty from './warranty';
import materialSelection from './material-selection';
import projectCamera from './project-camera';
import documents from './documents';
import housingCompanies from './housing-companies';
import partnerCompanies from './partner-companies';
import commonSpaces from './common-spaces';
import iot from './iot';
import atp from './atp';
import adminHelp from './admin-help';
import emailLetters from './email-letters';

const routes = [
    ...base,
    ...admin,
    ...community,
    ...supportCenter,
    ...userManagement,
    ...news,
    ...calendar,
    ...contacts,
    ...serviceLounge,
    ...consumption,
    ...warranty,
    ...materialSelection,
    ...projectCamera,
    ...documents,
    ...housingCompanies,
    ...partnerCompanies,
    ...commonSpaces,
    ...iot,
    ...atp,
    ...adminHelp,
    ...emailLetters
];

export default routes;