<template>
  <div v-if="editMode">
    <QuickLinkEditor ref="quickLinkEditor" @on-close="editMode=false" />
  </div>
  <div class="quick-links" v-else>
    <p class="no-results" v-if="getLinks.length === 0">
      {{ $t('user.quickLinks.noLinksSelected') }}
      <a href="#" @click="onAddFirstLink">{{ $t('user.quickLinks.selectFirstLink') }}</a>
    </p>
    <QuickLink 
      v-for="link in getLinks"
      :key="link._id"
      :href="link.href" 
      :iconCssClass="link.icon ? `fal ${link.icon}` : ''"
    >
      {{$t(link.translationKey)}}
    </QuickLink>
    <div class="quick-links__management text-right">
      <Button type="button" class="read-more" mode="link" @click="editMode=true">
        <i class="fal fa-edit"></i>
        <span>{{$t('common.edit')}}</span>
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QuickLinkEditor from "./Editor"

export default {
  name: "QuickLinksList",
  components: {
    QuickLinkEditor
  },
  data() {
    return {
      editMode: false
    }
  },
  computed: {
    ...mapGetters(['getSelectedHousingCompany', 'getUserQuickLinks']),
    getLinks() {
      return this.getUserQuickLinks(this.getSelectedHousingCompany.hcExt)
    }
  },
  methods: {
    onAddFirstLink(event) {
      if(event) event.preventDefault()
      this.editMode=true
      setTimeout(() => {
        this.$refs.quickLinkEditor.onClickAddNew(event);
      },10)

    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
.quick-links {
  &__management {
    @media screen and (max-width: $breakpoint-md-down) {
      text-align: center !important;
    }
  }
}
</style>