import store from "@/store";

const serviceRequestsFeature = {
    id: "service-requests",
    name: "feature.service-requests.name",
    iconClass: "fal fa-question-circle",
    globalSettings: {},
    schema: {},
    initialize: (feature, settings) => {
        if (!store.getters.getMainNavigationItemByUrl('/support')) {
            let hcState = store.getters.getCurrentHcState;
            store.dispatch("addNavigationLink", {
                titleLanguageKey: "navigation.supportcenter",
                href: "/support",
                iconClass: "fal fa-question-circle",
                hideChildren: true,
                exact: null,
                orderNumber: 55
            });
        }
    }
};

export { serviceRequestsFeature };