import {
  AzureFunctionClient
} from "./AzureFunctionClient";
import BaseService from "./BaseService";

export default class B2CUserService extends BaseService {
  constructor() {
    super();
  }
    /**
   * Get User details
   */
  getUserDetails() {
    //AzureFunctionClient.defaults.headers.common['authorization'] = 'Bearer ' + token
    return AzureFunctionClient.get(`/getUserDetails`);
  }

  updateUser(form) {
    let postData = {
      firstName: form.firstName,
      lastName: form.lastName,
      locale: form.locale,
      birthday: form.birthday,
      phoneNumber: form.phoneNumber,
      male: form.male,
      publicProfile: form.publicProfile
    };

    //const tempBaseUrl= "https://yit-plus-test-function-app.azurewebsites.net/api";
    return AzureFunctionClient.put(`/user`, postData);
  }

  userUpdate(body) {
    return AzureFunctionClient.post(`/user`, body);
  }
  /**
   * Deactivate User
   */
  deactivateUser(id, payload) {
    const url = id ? `/user?id=${id}` : `/user`
    return AzureFunctionClient.put(url, payload);
  }

   /**
   * Activate User
   */
  activateUser(id, payload) {
    return AzureFunctionClient.put(`/user?id=${id}`, payload);
  }
  /**
   * Remove User
   */
  removeUser(id = false) {
    const url = id ? `/user?id=${id}` : `/user`
    return AzureFunctionClient.delete(url);
  }

  logout() {
    return AzureFunctionClient.post(process.env.VUE_APP_B2C_LOGOUT_URL);
  }

}
