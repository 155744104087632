import store from "@/store";

const ALLOWED_OPERATIONS = ['read', 'create', 'update', 'delete'];
const OPERATION_DOCUMENT_MAP = {
    read: 'canRead',
    create: 'canUpload',
    update: 'canUpdate',
    delete: 'canDelete'
};

const can = (operation, object) => {

    if (!object || !operation)
        return false;

    if (operation == 'update' && !typeof object.canUpdate != 'undefined')
        return object.canUpdate;
    
    if (operation == 'delete' && !typeof object.canDelete != 'undefined')
        return object.canDelete;
    
    let currentUser = store.getters.getCurrentUser;

    if (object.createdUserUuid && object.createdUserUuid === currentUser.id)
        return true;

    if (ALLOWED_OPERATIONS.includes(operation)) {
        let folders = store.getters.getAllFolders;
        if (Array.isArray(folders) && folders.length) {
            let targetFolder = folders.find(f => f._id === object.folderId);
            if (targetFolder) {
                let key = OPERATION_DOCUMENT_MAP[operation];
                if (targetFolder[key] === true)
                    return true;
            }
        }
    }

    return false;
}

const getImageUrlFromDocumentResponseObject = (document, thumbnailVersion = "") => {
    if (!document)
        return "";
    if (!thumbnailVersion)
        return document.fileUrl;
    
    thumbnailVersion = thumbnailVersion.toLowerCase().trim();
    
    return document.thumbnails && document.thumbnails[thumbnailVersion] ? document.thumbnails[thumbnailVersion].url : document.fileUrl;
}

const getFilesFromFolderRecursive = (folderKey) => {
    const folder = store.getters.getAllFoldersFlat.find(f => f.key === folderKey);
    if (folder) {
        let searchFolderKeys = [];

        searchFolderKeys.push(folder.key);
        if(folder.key == 'apt_owner')
            searchFolderKeys.push('hc_owners');

        if(folder.children && folder.children.length) {
            folder.children.map(function f(_children) {
            searchFolderKeys.push(_children.key);
            if(_children.key == 'apt_owner') 
                searchFolderKeys.push('hc_owners');
            if(_children.children && _children.children.length) {
                _children.children.map(f);
            }
            });
        }

        return store.getters.getAllDocuments.filter(d => searchFolderKeys.includes(d.folder));
    }
    return [];
}

export { can, getImageUrlFromDocumentResponseObject, getFilesFromFolderRecursive };