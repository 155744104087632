<template>
  <footer class="site-footer bg--grey-blue-mid-dark">
    <div class="container-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col" v-if="getMainContacts({showPartnerCompanyContacts: true}) && getMainContacts({showPartnerCompanyContacts: true}).length">
            <ContactList
              :items="getMainContacts({showPartnerCompanyContacts: true})"
              :showLinkToServiceRequests="false"
              :fields="footerContactFields"
              listComponent="ContactListItemV1"
            />
          </div>
        </div>
        <slot />
      </div>
    </div>
    <div class="site-footer__copy">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-lg-2">
            <div class="site-footer__logo">
                <img :src="yitLogo" alt="YIT" />
              </div>
          </div>
          <div class="col-lg-12 padding--no">
            <ul class="corporation-navigation">
              <li>
                <router-link :to="getTermsLink()" v-if="isStateB2C">{{$t('footer.termsofuse')}}</router-link>
                <a :href="getTermsLink()" v-else>{{$t('footer.termsofuse')}}</a>
              </li>
              <li>
                <router-link :to="getPrivacyLink()"  v-if="isStateB2C">{{$t('footer.privacypolicy')}}</router-link>
                <a :href="getPrivacyLink()" v-else>{{$t('footer.privacypolicy')}}</a>
              </li>
              <li v-if="$t('footer.cookies.linkHref') !== 'footer.cookies.linkHref'">
                <router-link :to="$t('footer.cookies.linkHref')">{{$t('footer.cookies.linkTitle')}}</router-link>
              </li>
            </ul>
          </div>
        </div>
        
        <div class="site-footer__copyright">&copy; {{getYear()}} {{$t('common.yit.corporation')}}</div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import yitLogo from "@/assets/images/logo.svg";
import { i18n } from "@/plugins/i18n";
import ContactList from "@/components/Contact/List";

export default {
  components: {
    ContactList
  },
  props: ["title"],
  data() {
    return {
      yitLogo,
      footerContactFields: [
        "name",
        "title",
        "email",
        "opening_hours",
        "phone_number"
      ]
    };
  },
  computed: {
    ...mapGetters(["getMainContacts", 'isStateB2C'])
  },
  methods: {
    ...mapActions(["fetchContacts"]),
    getTermsLink() {
      return this.$t('footer.termsofuse.linkHref');
    },
    getPrivacyLink() {
      return this.$t('footer.privacypolicy.linkHref');
    },
    getYear() {
      return new Date().getFullYear();
    }
  },
  mounted() {
    this.fetchContacts();
  }
};
</script>


<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
.site-footer {
  color: #fff;
  padding: 1rem 0 0;
  border-top: 2px solid $blueMedium;
  margin-top: 1rem;

  @media screen and (min-width: $breakpoint-lg) {
    padding-top: 2rem;
  }

  a {
    color: #fff;
    border-bottom-color: #fff;
  }

  &__logo {
    max-width: 100px;
    text-align: center;
    margin-top: .4rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: $breakpoint-lg) {
      position: absolute;
      text-align: left;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__copy {
    font-size: 0.85rem;
    background-color: #1e3540;
    margin-top: 1rem;
    padding: 1rem;

    text-align: center;
    @media screen and (min-width: $breakpoint-lg) {
      text-align: left;
    }
  }
  &__copyright {
    padding: 0.5rem 0;
    font-size: 0.75rem;
    text-align: center;
    @media screen and (min-width: $breakpoint-lg) {
      //text-align: left;
    }
  }
  .corporation-navigation {
    list-style: none;
    font-family: $fontPrimaryLight;
    text-align: center;
    margin-bottom: 1rem;

    @media screen and (min-width: $breakpoint-lg) {
      margin-top: .2rem;
    }

    li {
      display: inline-block;
      margin: 0 1rem 0.5rem 1rem;
      a {
        display: block;
        padding-bottom: 0.2rem;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      li {
        margin-bottom: 0;
      }
    }
  }

  /**
   * Style override in footer
  */
  .contact-list {
    justify-content: flex-start;
    padding-top: 1rem;
    font-size: 0.9rem;
    &__item {
      &__tel {
        margin: 0.2rem 0 0.4rem 0;
        .yit-icon,
        .fas,
        .far,
        .fal {
          transition: all 0.2s ease-in-out;
          //display: inline-block;
        }
        &:hover {
          .yit-icon,
          .fas,
          .far,
          .fal {
            background-color: $borderHoverColor;
          }
        }
      }
    }

    @media screen and (min-width: $breakpoint-sm) {
      &__item {
        margin-bottom: 1.8rem;
        width: 100%;
        max-width: 45%;
        flex-basis: 45%;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-top: 0;
    }

    @media screen and (min-width: $breakpoint-lg) {
      &__item {
        max-width: 25%;
        flex-basis: 25%;
        padding-right: 3%;
      }
    }
  }
}
</style>