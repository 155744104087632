<template>
  <div>
    <ul class="content-carousel__dots-navigation">
      <li
        v-for="index in getIndexes"
        :key="index"
        :class="{ 'active': activeIndex === index }"
      >
        <button @click="$emit('on-select', index)">{{index}}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CarouselDotsNavigation",
  props: {
    count: {type: Number, default: 0},
    activeIndex: {type: Number, default: 0}
  },
  computed: {
    getIndexes() {
      let res = [];
      if(this.count > 0) {
        for(let i = 0; i < this.count; i++) {
          res.push(i);
        }
      }

      return res;
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/variables";
.content-carousel__dots-navigation {
  position: relative;
  display: inline-block;
  margin: 1rem 0 0 0;
  padding: 0;
  list-style: none;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  li {
    position: relative;
    display: block;
    float: left;
    margin: 0 0.5rem;
    width: 12px;
    height: 12px;
    cursor: pointer;

    button {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;
      border-radius: 50%;
      background-color: $greyBlueMidDark;
      text-indent: -999em;
      cursor: pointer;
      position: absolute;
      padding: 0;
      transition: background-color 0.3s ease;

      &:focus,
      &:hover {
        outline: none;
        background-color: $greyBlueMidDark;
      }
    }
    &.active button {
      background-color: transparent;
      border: solid 1px $greyBlueMidDark;
    }
  }
}
</style>