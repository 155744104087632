import striptags from "striptags";

const linkify = (text) => {
    text = striptags(text);

    //URLs starting with http://, https://
    let replacePattern1 = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    text = text.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    let replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    text = text.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    let replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    text = text.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return text;
}

export default {
    install: (app, options) => {
        app.directive("linkify", {
            beforeMount: function (el) {
                el.innerHTML = linkify(el.innerHTML);
            }
        })
    }
}