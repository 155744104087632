const emailLettersFeature = {
  id: "email-letters",
  name: "feature.email-letters.name",
  iconClass: "fal fa-paper-plane",
  globalSettings: {},
  schema: {
      
  }
};

export { emailLettersFeature };