import dayjs from "dayjs";
import store from "@/store";
import { ability } from "@/config/ability";
import Campaign from "@/models/Campaign";
import Article from "@/models/Article";

const isYitNewsPosting = (item) => {
    if(item.elements.author_roles) {
      let roles = item.elements.author_roles.value;
      if(roles.length) {
        if(roles.includes('yitUser') || roles.includes('yitAdmin'))
          return true;
      } else 
        return false;
    }

    return false;
}

const hasArticleType = (item, search) => {
  let ret = false;
  if(item.elements.articletypes && Array.isArray(item.elements.articletypes.value) && search) {
    let types = item.elements.articletypes.value.find(i => i.codename == search);
    if(types)
      ret = true;
  }

  return ret;
}

const isPriorityNewsPosting = (item) => {
  if(hasArticleType(item, 'priority_news')) {

    if(!item.elements.publish_date)
      return false;
      
    let today = dayjs().subtract(1, 'month');
    let articlePublishedDate = dayjs(item.elements.publish_date.value);

    if(today.valueOf() < articlePublishedDate.valueOf())
      return true;
  }

  return false;
}

const getResultsTargetedByRole = items => {
  let currentUser = store.getters.getCurrentUser;
  return items.filter(item => {
    if (!item || !item.elements || !item.elements.user_role || !item.elements.user_role.value || item.elements.user_role.value.length === 0)
      return true;
    
    if (currentUser.hasRole('admin'))
      return true;
    
    let ret = false;

    if (Array.isArray(currentUser.roles) && currentUser.roles.length) {

      let roles = item.elements.user_role.value;
      //add chairman role automatically if board_member role is included
      if (roles.some(r => r.codename ==='board_member'))
        roles.push({ codename: 'chairman' });
      
      roles.map(role => {
        if (currentUser.roles.includes(_normalizeKenticoUserRoleValue(role.codename)) || currentUser.hasRole(_normalizeKenticoUserRoleValue(role.codename)))
          ret = true;
      });
    }

    return ret;
  })
}

const _normalizeKenticoUserRoleValue = roleName => {
  let parts = roleName.split("_");
  if (parts.length === 0)
    return roleName;
  
  let returnString = "";
  parts.map((part, key) => {
    if (key > 0)
      returnString += part.charAt(0).toUpperCase() + part.slice(1);
    else
      returnString += part;
  });

  return returnString;
}

const hasAcessToKenticoItem = item => {
  let currentUser = store.getters.getCurrentUser;
  let availableTaxonomyCodeNames = store.getters.getBuildingTaxonomiesFlat.map(t => t.codename);

  if (currentUser.hasRole('admin'))
    return true;
  
  if (item.elements) {
    
    if (item.elements.user_id && item.elements.user_id.value === currentUser.id)
      return true;
    
    if (currentUser.hasAnyOfRoles(['boardMember', 'chairman', 'propertyManager', 'yitUser'])) {

      if (!currentUser.hasRole('yitUser')) {
        //console.log("hasAcessToKenticoItem: not yit user");
        if (item.elements.author_roles && item.elements.author_roles.value) {
          let authorRoles = item.elements.author_roles.value.split(",");
          let canEditBasedOnAuthorRole = false;
          let authorTargetRoles = ['yitUser', 'yitBusinessAdmin', 'yitMarketing', 'admin', 'yitAdmin', 'yitProject'];
          if (currentUser.hasRole('propertyManager'))
            authorTargetRoles = ['yitUser', 'yitBusinessAdmin', 'yitMarketing', 'admin', 'yitAdmin', 'yitProject', 'boardMember', 'chairman', 'maintenanceManager', 'ceeBoardMember'];
          authorRoles.map(role => {
            if (authorTargetRoles.includes(role))
              canEditBasedOnAuthorRole = true;
          });

          //console.log("hasAcessToKenticoItem: ", canEditBasedOnAuthorRole);

          if (canEditBasedOnAuthorRole)
            return false;
        }
      }
      
      let itemBuildingTaxonomyCodeMames = item.elements.buildingtaxonomy ? item.elements.buildingtaxonomy.value.map(v => v.codename) : [];
      let isInlcudedInTaxonomies = itemBuildingTaxonomyCodeMames.some(t => availableTaxonomyCodeNames.includes(t));

      if (!isInlcudedInTaxonomies)
        return false;
    }
  }

  if (item.system && item.system.type) {
    switch (String(item.system.type).toLowerCase()) {
      case 'campaign':
        if (ability.can('update', Campaign.buildFromKenticoModel(item)))
          return true;
        break;
      case 'article':
      default:
        if (ability.can('update', Article.fromKenticoModel(item)))
          return true;
        break;
    }
    
  }

  
  return false;
}

export {
  isYitNewsPosting,
  hasArticleType,
  isPriorityNewsPosting,
  getResultsTargetedByRole,
  hasAcessToKenticoItem
}