<template>
  <transition :name="(!isDesktop) ? transitionName : ''" mode="out-in" @beforeLeave="beforeLeave">
    <div v-if="visible" class="page-container">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PageWrapperMain",
  data() {
    return {
      transitionName: "fadeslowly",
      visible: false
    };
  },
  computed: {
    isDesktop: () => {
      if (window.innerWidth > 992) {
        return true;
      }

      return false;
    }
  },
  methods: {
    beforeLeave(element) {
      this.visible = false;
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.visible = true;
      next();
    });

    this.visible = true;
  }
};
</script>
<style lang="scss" scoped>
.back-button {
  i {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    i {
      transform: translateX(-0.4rem);
    }
  }
  border-bottom: none;
}
</style>
