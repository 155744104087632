import store from "@/store";

const newsFeature = {
    id: "news",
    name: "feature.news.name",
    iconClass: "fal fa-newspaper",
    globalSettings: {},
    schema: {},
    forceActive: true,
    initialize: (feature, settings) => {
       if (!store.getters.getMainNavigationItemByUrl('/news')) {
            let hcState = store.getters.getCurrentHcState;
            store.dispatch("addNavigationLink", {
                titleLanguageKey: "navigation.news",
                href: "/news",
                iconClass: "fal fa-newspaper",
                orderNumber: 5
            });
        }
    }
};

export { newsFeature };