<template>
  <div class="404 text-center">
    <div class="error-page-icon">
      <i class="fal fa-hammer"></i>
    </div>
    <h1>{{$t('page.maintenance-break.title')}}</h1>
    <p>{{$t('page.maintenance-break.content')}}</p>
    <p>
      <br />
    </p>
  </div>
</template>

<script>
import { setPageSubTitle } from "@/utils/PageMetaUtil";

export default {
  name: "MaintenanceNotice",
  components: {},
  mounted() {
    setPageSubTitle(this.$t("page.maintenance-break.title"));
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";

.error-page-icon {
  font-size: 3rem;
  margin: 1rem 0;
  @media screen and (min-width: $breakpoint-md) {
    font-size: 6rem;
  }
}
</style>