import store from "@/store";

const faqFeature = {
    id: "faq",
    name: "feature.faq.name",
    iconClass: "fal fa-question-circle",
    globalSettings: {},
    schema: {},
    initialize: (feature, settings) => {
        if (!store.getters.getMainNavigationItemByUrl('/faq')) {
            let hcState = store.getters.getCurrentHcState;
            store.dispatch("addNavigationLink", {
                titleLanguageKey: "support.center.faq",
                href: "/faq",
                iconClass: "fal fa-info-square",
                orderNumber: 50
            });
        }
    }
};

export { faqFeature };