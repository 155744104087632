<template>
  <article :class="'timeline-item timeline-item--'+getType">
    <a :href="getItemLink" @click="onSelectTimelineItem">
      <!-- <i class="fas fa-exclamation-circle content-pick__priority-item" v-if="item.isPriority" :aria-label="$t('common.news.isPriorityItem')"></i> -->
      <div v-if="imageSize == 'thumbnail'" class="timeline-item__thumbnail">
        <div
          class="content-pick__thumbnail__image content-pick__thumbnail--bg"
          v-if="item.thumbnailSrc"
          :style="{backgroundImage: 'url('+item.thumbnailSrc+')'}"
        ></div>
        <div class="content-pick__highlight" :class="getHighLightClasses" v-else>
          <span class="value">{{ $filters.format_date(item.date, 'D') }}</span>
          <span class="unit">{{ $filters.format_date(item.date, 'MMM') }}</span>
        </div>
      </div>
      <div class="timeline-item__content">
        <div class="timeline-item__header" v-if="showHeader && item.type">
          {{$t(item.type.translateKey)}}
        </div>

        <div class="timeline-item__body">
          <span class="timeline-item__yit-tag"><YitIcon v-if="item.isYit" /></span>
          <div class="timeline-item__meta">
            <span class="timeline-item__date">
              <template v-if="item.date_end">{{ printDatesBetween(item.date, item.date_end, 'short') }}</template>
              <template v-else>{{ $filters.date_format_ordinal(item.date,$i18n.locale) }}</template>
            </span>
          </div>
          <div class="timeline-item__title text--break-long-words">
            <h1 class="text--break-long-words">{{item.title}}</h1>
          </div>
        
        </div>
        <div class="timeline-item__footer" v-if="showFooter">
          <div class="timeline-item__tags timeline-item__footer__left">
            <span
              v-if="item.category.length"
              :class="'tag tag--'+item.type"
            >
              {{ $t(item.category[0].translateKey) }}
            </span>
            <span class="tag" v-else-if="item.type">{{$t('news.tag.'+item.type)}}</span>
          </div>
          <div class="timeline-item__like timeline-item__footer__right" v-if="item.type == 'news'">
            <div class="like-count">
              <i class="far fa-thumbs-up"></i>
              {{likeCount}}
            </div>
            <CommentCount
              :id="item.id"
              @on-update="refreshLikes"
            />
          </div>
        </div>
        <div class="timeline__item__image" v-if="item.thumbnailSrc && imageSize == 'large'">
          <a :href="getItemLink" @click="onSelectTimelineItem">
            <img class :src="item.thumbnailSrc" :alt="item.title" />
          </a>
        </div>
      </div>
    </a>
  </article>
</template>

<script>
import TimelineItemModel from "@/models/TimelineItemModel";
import CommentCount from "@/components/Comments/CommentCount";
import { printDatesBetween } from "@/utils/DateHelper";
import YitIcon from "@/components/common/YitIcon";
import { mapMutations } from 'vuex';

export default {
  name: "TimelineItemDefault",
  props: {
    item: {type: TimelineItemModel},
    showHeader: {type: Boolean, default: false},
    showFooter: {type: Boolean, default: true},
    imageSize: {type: String, default: 'thumbnail'}
  },
  components: {
    CommentCount,
    YitIcon
  },
  data() {
    return {
      likeCount: 0
    }
  },
  computed: {
    getType() {
      if(this.item.category.length) {
        return this.item.category[0].key;
      }

      return this.item.type ? this.item.type : '';
    },
    getItemLink() {
      if(!this.item || !this.item.linkUrl)
        return "";

      let resolvedPath = this.$router.resolve(this.item.linkUrl);
      if(resolvedPath) {
        return resolvedPath.href;
      }

      return "";
    },
    getHighLightClasses() {
      let classes = [];
      if(['event', 'deadline', 'task'].includes(this.item.type))
        classes.push('bg--red')
      else 
        classes.push('bg--grey-blue-mid-light')

      return classes;
    }
  },
  methods: {
    ...mapMutations(['setSelectedTimeLineItem']),
    printDatesBetween,
    refreshLikes(value) {
      if(Array.isArray(value))
        this.likeCount = value.length;
    },
    onSelectTimelineItem(e) {
      e.preventDefault();
      this.setSelectedTimeLineItem(this.item);
      this.$router.push(this.item.linkUrl);
    }
  }
}
</script>

<style>

</style>