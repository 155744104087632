import store from "@/store";

const documentsFeature = {
    id: "documents",
    name: "feature.documents.name",
    iconClass: "fal fa-folder-open",
    globalSettings: {},
    schema: {},
    initialize: (feature, settings) => {
        if (!store.getters.getMainNavigationItemByUrl('/documents')) {
            let hcState = store.getters.getCurrentHcState;
            store.dispatch("addNavigationLink", {
                titleLanguageKey: "navigation.documents",
                href: "/documents",
                iconClass: "fal fa-folder-open",
                orderNumber: 30
            });
        }
    }
};

const documentsv2Feature = {
    id: "documentsv2",
    name: "feature.documentsv2.name",
    iconClass: "fal fa-folder-open",
    globalSettings: {},
    schema: {}
};

export { documentsFeature, documentsv2Feature };