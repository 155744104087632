<template>
  <button type="button" class="button button--link back-button" :class="{'back-button--inline': inline}" @click="onClick">
    <i class="far fa-chevron-left"></i>
    <slot>{{$t('common.backButton')}}</slot>
  </button>
</template>
<script>
export default {
  name: "BackButton",
  props: {
    path: {},
    callback: {
      type: Function
    },
    inline: {
      type: Boolean
    }
  },
  methods: {
    onClick(e) {

      if(e)
        e.preventDefault();

      if (this.path) this.$router.push(this.path);
      else if(this.callback) this.callback();
      else this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
.back-button {
  display: block;
  margin-top: 0;
  margin-bottom: 2rem;
  cursor: pointer;

  &--inline {
    display: inline-block;
    @media screen and (min-width: $breakpoint-sm) {
      float: left;
    } 
  }
}
a.back-button, button.back-button {
  color: $blueMedium;
  border-bottom-color: $blueMedium;
}
</style>