import store from "@/store/index";
import BaseService from "./BaseService";
import {AzureLiferayClient} from "./AzureLiferayClient";
import { AzureFunctionClient } from "./AzureFunctionClient";

export default class FeedbackService extends BaseService {
  constructor() {
    super();
    this.baseUrl = "/delegate/yithome-api/functions";
  }

  routeViaLiferay() {
    return false;
  }

  postFeedback(data = {}) {
    let hc = store.state.User.selectedHousingCompany;

    let postData = {
      rating: data.rating,
      comment: data.comment,
      country: hc.addressCountry,
      area: hc.addressArea,
      city: hc.addressCity,
      business_unit: hc.businessUnit,
      project_id: hc.hcExt ? hc.hcExt : ""
    };

    if (this.routeViaLiferay()) {
      return AzureLiferayClient.post(
        `${this.baseUrl}/postFeedbackEntry`,
        postData
      );
    } else return AzureFunctionClient.post(`/postFeedbackEntry`, postData);
  }

  getFeedbackEntries(params = {}) {
    if (this.routeViaLiferay()) {
      return AzureLiferayClient.get(
        `${this.baseUrl}/getFeedbackEntries/${
          params.country ? params.country : "all"
        }`
      );
    } else {
      let query = "/getFeedbackEntries";

      if (params.country) query += "?country=" + params.country;

      return AzureFunctionClient.get(query);
    }
  }
}
