import KenticoService from "@/services/KenticoService";
import _ from "lodash";

const contentTypeCodename = 'contact';
const api = new KenticoService();
api.setContentType([contentTypeCodename]);
api.targetByDefaultBuildingTaxonomy = false;

const settings = {
  contentType: "contact",
  contentModel: null
};
const getDefaultState = () => {
  return {
    contacts: [],
    contentModel: null
  };
};

const state = getDefaultState();

const getters = {
  getContactsAdmin: state => state.contacts,
  getContactContentModel: state => state.contentModel
};

const actions = {
  fetchContactsAdmin({commit}, params = null) {
    return new Promise((resolve, reject) => {
      if (!params) {
        params = {
          limit: 50,
          order: "order",
          orderDirection: "asc"
        };
      }
      return api
        .fetchItems(params)
        .then(response => {
          if(params.append)
            commit("appendContactsAdmin", response.data.items);
          else
            commit("setContactsAdmin", response.data.items);
          resolve(response.data);
        })
        .catch(err => {
          console.log("Fetching contact failed: ", err);
          reject(err);
        });
    });
  },
  async fetchContactContentModel({ commit }) {
    try {
      const res = await api.fetchContentType(contentTypeCodename)
      commit("setContactContentModel", res.data);
    } catch (err) {
      commit("setContactContentModel", null);
      throw err;
    }
  }
};

const mutations = {
  setContactsAdmin: (state, res) => (state.contacts = res),
  appendContactsAdmin: (state, res) => (state.contacts = state.contacts.concat(res)),
  setContactContentModel: (state, res) => (state.contentModel = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
