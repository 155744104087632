import {
  KenticoManagementClient,
  KenticoPreviewClient,
  includeJwt,
} from "./KenticoClient";
import BaseService from "./BaseService";
import { v4 as uuidv4 } from "uuid";
import { arrayBufferToBlob } from "blob-util";
import {
  removeExtensionFromfilename,
  getAlphanumericString,
} from "@/utils/StringUtils";
import dayjs from "dayjs";
import striptags from "striptags";
import AppSettings from "@/globals";
import store from "@/store/index";

export default class KenticoService extends BaseService {
  constructor() {
    super();
    this.contentType = "article";
    this.filters = [];
    this.targetByDefaultBuildingTaxonomy = false;

    includeJwt();
  }

  setContentType(type) {
    this.contentType = type;
  }

  /**
   * Add new content item
   * @param {*} data
   * @returns
   */
  addItem(data = {}) {
    if (!data.lang) data.lang = AppSettings.defaultLang;

    let type = { codename: this.contentType };
    if (typeof data.type == "string") type = { codename: data.type };
    else if (
      typeof data.type == "object" &&
      (data.type.id || data.type.codename)
    )
      type = data.type;
    else if (data.codename) type = { codename: data.codename };

    let payload = {
      name: data.title.substring(0, 50),
      type,
      sitemap_locations: [],
    };

    return KenticoManagementClient.post(`/items`, payload);
  }

  /**
   * Upsert or add new content item
   * @param {*} object form data
   * @param {*} id Identifier (leave empty if you like to add new item)
   * @return Promise
   */
  upsertItem(data = {}, id = null) {
    //	if (!data.uuid) data.uuid = uuidv4();
    if (!data.lang) data.lang = AppSettings.defaultLang;
    if (!id) id = uuidv4();

    let typeCodename = this.contentType;
    if (data.type) typeCodename = data.type;
    else if (data.codename) typeCodename = data.codename;

    let payload = {
      name: data.title.substring(0, 50),
      type: {
        codename: typeCodename,
      },
      sitemap_locations: [],
    };

    return KenticoManagementClient.put(`/items/${id}`, payload);
  }

  /**
   * Upsert with external id or add new content item
   * @param {*} object form data
   * @param {*} id Identifier (leave empty if you like to add new item)
   * @return Promise
   */
  upsertItemByExternalId(data = {}, id = null) {
    //	if (!data.uuid) data.uuid = uuidv4();
    if (!data.lang) data.lang = AppSettings.defaultLang;
    if (!id) id = uuidv4();

    let typeCodename = this.contentType;
    if (data.type) typeCodename = data.type;
    else if (data.codename) typeCodename = data.codename;

    let payload = {
      name: data.title.substring(0, 50),
      type: {
        codename: typeCodename,
      },
      sitemap_locations: [],
    };

    return KenticoManagementClient.put(`/items/external-id/${id}`, payload);
  }

  /**
   *
   * @param {*} contentItemId
   */
  deleteItem(contentItemId) {
    return KenticoManagementClient.delete(`/items/${contentItemId}`);
  }

  /**
   * @param {*} object response data
   * @return Promise
   */
  upsertLanguageVariant(data, contentItemId = null) {
    if (!data.formData.lang) data.formData.lang = AppSettings.defaultLang;

    if (!contentItemId) {
      if (data.formData.id) contentItemId = data.formData.id;
      else if (data.response.data.id)
        this.contentItemId = data.response.data.id;
    }

    const entries = Object.keys(data.formData);
    let elements = [];

    for (var i = 0; i < entries.length; i++) {
      if (
        entries[i] != "originalData" &&
        entries[i] != "lang" &&
        entries[i] != "validator" &&
        entries[i] != "codename" &&
        entries[i] != "hidden" &&
        entries[i] != "id"
        //data.formData[entries[i]] != ""
      ) {
        if (Array.isArray(data.formData[entries[i]])) {
          let arrayValues = [];
          for (var c = 0; c < data.formData[entries[i]].length; c++) {
            if (entries[i] == "images" || entries[i] == "attachments" || entries[i] == "related_content") {
              arrayValues.push({
                id: data.formData[entries[i]][c].id,
              });
            } else if (
              entries[i] == "sticky_news" ||
              entries[i] == "type" ||
              entries[i] == "building_phase" ||
              entries[i] == "notify_users" ||
              entries[i] == "show_contact_button" ||
              entries[i] == "primary_contact_information" ||
              entries[i] == "source" ||
              entries[i] == "disable_configuring"
            ) {
              arrayValues.push({
                codename: data.formData[entries[i]][c],
              });
            } else if (entries[i] == "videos") {
              arrayValues.push({
                codename: data.formData[entries[i]][c],
              });
            } else {
              arrayValues.push({
                id: data.formData[entries[i]][c],
              });
            }
          }
          elements.push({
            element: {
              codename: entries[i],
            },
            value: arrayValues,
          });
        } else {
          if (entries[i] == "content" || entries[i] == "introduction") {
            let validForKentico = data.formData[entries[i]];
            /*let validForKentico = striptags(data.formData[entries[i]], [
              "a",
              "p",
              "h1",
              "h2",
              "h3",
              "h4",
              "strong",
              "em",
              "ol",
              "li",
              "ul",
              "table",
              "tbody",
              "tr",
              "td",
              "figure",
              "img",
              "br"
            ]);*/

            elements.push({
              element: {
                codename: entries[i],
              },
              value: validForKentico,
            });
          } else {
            elements.push({
              element: {
                codename: entries[i],
              },
              value:
                typeof data.formData[entries[i]] != "undefined"
                  ? data.formData[entries[i]]
                  : "",
            });
          }
        }
      }
    }

    console.log("Upserting language variant with data: ", elements);
    let postData = '{ "elements": ' + JSON.stringify(elements) + "}";
    return KenticoManagementClient.put(
      `/items/${contentItemId}/variants/codename/${data.formData.lang}`,
      postData
    );
  }

  /**
   *
   * @param {*} contentItemId
   * @param {*} languageCode
   */
  deleteContentVariant(contentItemId, languageCode) {
    return KenticoManagementClient.delete(
      `/items/${contentItemId}/variants/codename/${languageCode}`
    );
  }

  /**
   *
   * @param {*} contentItemId
   * @param {*} lang
   */
  publishContentVariant(contentItemId, lang, publishDate) {
    const today = dayjs(new Date());
    const formattedDate = dayjs(publishDate);
    let postData = "";
    if (formattedDate.isAfter(today)) {
      let schedule = { scheduled_to: dayjs(publishDate).format() };
      postData = JSON.stringify(schedule);
    }
    return KenticoManagementClient.put(
      `/items/${contentItemId}/variants/codename/${lang}/publish/`,
      postData
    );
  }

  /**
   *
   * @param {*} contentItemId
   * @param {*} lang
   */
  unPublishContentVariant(contentItemId, lang) {
    return KenticoManagementClient.put(
      `/items/${contentItemId}/variants/codename/${lang}/unpublish/`
    );
  }

  /**
   * List workflow steps of a project
   */
  listWorkflowSteps() {
    return KenticoManagementClient.get(`/workflow`);
  }

  /**
   * Change language variant workflow step to draft or published
   * @param {*} languageVariantId
   * @param {*} lang
   * @param {*} step
   */
  setContentItemWorkflow(contentItemId, lang, step) {
    return KenticoManagementClient.put(
      `/items/${contentItemId}/variants/codename/${lang}/workflow/${step}`
    );
  }

  publishLanguageVariant(langId, itemId, publishDate = new Date()) {
    const today = dayjs(new Date());
    const formattedDate = dayjs(publishDate);
    let postData = "";
    if (formattedDate.isAfter(today)) {
      let schedule = { scheduled_to: dayjs(publishDate).format() };
      postData = JSON.stringify(schedule);
      console.log("schedule", postData);
    }
    return KenticoManagementClient.put(
      `/items/${itemId}/variants/${langId}/publish`,
      postData
    );
  }

  fetchTaxonomyGroup(groupIdentifier) {
    return KenticoManagementClient.get(`/taxonomies/${groupIdentifier}`);
  }

  /**
   * Add attachment for the message
   * @param {*} object form data
   */

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * Upload Binary data to Kentico
   * @param {*} object data
   *
   *
   */
  uploadBinaryFile(data) {
    const binaryFile = arrayBufferToBlob(data.fileBuffer);
    const fileName = data.fileName;

    return KenticoManagementClient.post(`/files/${fileName}`, binaryFile, {
      headers: {
        "Content-Type": data.type,
        "Content-length": data.size,
      },
    });
  }

  /**
   * Upload Binary data to Kentico
   * @param {*} object data
   *
   *
   */
  fetchTaxonomyGroup(taxonomy) {
    return KenticoManagementClient.get(`/taxonomies/codename/${taxonomy}`);
  }

  /**
   * Add attachment for the message
   * @param {*} object form data
   */
  addAsset(data) {
    const response = data.response.data;
    const file = data.file;

    if (!data.lang) data.lang = "all";

    if (!data.uuid) data.uuid = uuidv4();

    let postData = {
      file_reference: {
        id: response.id,
        type: "internal",
      },
      title: file.name,
      descriptions: [
        {
          language: {
            codename: data.lang,
          },
          description: "",
        },
      ],
    };

    return KenticoManagementClient.put(
      `/assets/external-id/${data.uuid}`,
      postData
    );
  }

  /**
   *
   * @param {String} id
   */
  getAsset(id) {
    return KenticoManagementClient.get(`/assets/${id}`);
  }

  /**
   * 
   * @param {String} id 
   * @param {Object} payload 
   */
   async updateAsset(id, payload) {
    return KenticoManagementClient.put(`/assets/${id}`, payload);
  }

  /**
   *
   * @param {String} id
   */
  deleteAsset(id) {
    return KenticoManagementClient.delete(`/assets/${id}`);
  }

  /**
   *
   * @param {*} field
   * @param {*} value
   * @param {*} operator
   * @param {*} prefix
   */
  addFilter(field = "", value = null, operator = "=", prefix = "elements") {
    this.filters.push({
      field,
      value,
      operator,
      prefix,
    });
  }

  getFilters() {
    return this.filters;
  }

  resetFilters() {
    this.filters = [];
  }

  _buildFilterQuery() {
    let ret = "";
    this.filters.map((filter, key) => {
      let operator =
        filter.operator == "=" ? filter.operator : "[" + filter.operator + "]=";
      ret += `&${filter.prefix}.${filter.field}${operator}${filter.value}`;
    });

    return ret;
  }

  fetchItem(id) {
    return KenticoManagementClient.get(`/items/${id}`);
  }

  /**
   * @param Object params
   * @returns Promise
   */
  fetchItems(params = {}, elements = []) {
    if (params.contentType) {
      this.contentType = params.contentType;
    }

    //let language = this.getLanguage();
    //if (params.language) language = params.language;

    if (params.title) {
      this.addFilter("title", params.title, "=");
    }

    let queryString = `/items?system.type=${this.contentType}`;
    if (params.language) queryString += `&language=${params.language}`;

    //set defaults
    if (!params.limit) params.limit = 100;
    if (!params.skip) params.skip = 0;
    if (!params.sticky) params.sticky = false;
    if (!params.order && !params.order != "none") {
      params.order = "publish_date";
    }
    if (!params.orderDirection) {
      params.orderDirection = "desc";
    }

    //article type filtering
    if (params.articleTypes) {
      queryString += `&elements.articletypes[contains]=${params.articleTypes}`;
    }

    //building taxonomy filtering
    if (params.buildingTaxonomies) {
      console.log("BUILDING TAX: ", params.buildingTaxonomies);
      if (typeof params.buildingTaxonomies === "string")
        queryString += `&elements.buildingtaxonomy[any]=${params.buildingTaxonomies}`;
      else if (
        Array.isArray(params.buildingTaxonomies) &&
        params.buildingTaxonomies.length
      )
        queryString += `&elements.buildingtaxonomy[any]=${params.buildingTaxonomies.toString()}`;
    } else if (this.targetByDefaultBuildingTaxonomy) {
      queryString += `&elements.buildingtaxonomy[any]=${store.getters.getBuildingTaxonomyFilters.toString()}`;
    }
    if (params.sticky) {
      params.sticky_expiration = dayjs().format("YYYY-MM-DD");
      queryString += `&elements.sticky_news[contains]=true&elements.expiration_date_of_the_sticky[gte]=${params.sticky_expiration}]`;
    }

    //@todo remove customStrignQuery at some point
    if (params.customStringQuery) {
      queryString += "&" + params.customStringQuery;
    } else if (this.filters.length) {
      queryString += this._buildFilterQuery();
    }

    //Limit queyry result set elements
    if (elements.length) {
      let elemQuery = "&elements=";
      elements.map((elem, index) => {
        elemQuery += elem;
        if (index + 1 < elements.length) elemQuery += ",";
      });
      queryString += elemQuery;
    }

    if (params.publishDateFrom || params.publishDateTo) {
      if (params.publishDateFrom && !params.publishDateTo) {
        queryString +=
          "&elements.publish_date[gte]=" +
          dayjs(params.publishDateFrom).format("YYYY-MM-DD");
      } else if (!params.publishDateFrom && params.publishDateTo) {
        queryString +=
          "&elements.publish_date[lte]=" +
          dayjs(params.publishDateTo).format("YYYY-MM-DD");
      } else if (params.publishDateFrom && params.publishDateTo) {
        queryString +=
          "&elements.publish_date[range]=" +
          dayjs(params.publishDateFrom).format("YYYY-MM-DD") +
          "," +
          dayjs(params.publishDateTo).format("YYYY-MM-DD");
      }
    }

    queryString += `&limit=${params.limit}`;

    if (params.partner_external_id)
      queryString += `&elements.partner_external_id=${params.partner_external_id}`;

    if (params.status)
      queryString += `&system.workflow_step=${params.status.toLowerCase()}`;

    if (params.skip) {
      queryString += `&skip=${params.skip}`;
    }

    if (params.order && params.order != "none" && params.orderDirection) {
      if (
        !params.order.startsWith("elements") &&
        !params.order.startsWith("system")
      )
        params.order = "elements." + params.order;
      queryString += `&order=${params.order}[${
        params.orderDirection ? params.orderDirection : "asc"
      }]`;
    }

    console.log(`Kentico search query: ${queryString}`);
    return KenticoPreviewClient.get(`${queryString}`);
  }

  /**
   * @param String
   * @uses API V1
   * @returns Promise
   * Done with API v1, because V2 could'nt return element values including element field name (codename)
   */
  fetchLanguageVariant(contentItemId, lang = "all") {
    return KenticoManagementClient.get(
      `/items/${contentItemId}/variants/codename/${lang}`
    );
  }

  /**
   *
   * @param {*} contentItemId
   * @param {*} variantId
   */
  fetchLanguageVariantById(contentItemId, variantId) {
    return KenticoManagementClient.get(`/items/${contentItemId}/variants/${variantId}`);
  }

  getLanguageVariantByContentIdAndLanguageCodeName(id, lang = "all") {
    return KenticoManagementClient.get(
      `/items/${id}/variants/codename/${lang}`
    );
  }

  /**
   *
   * @param {*} id
   */
  fetchLanguageVariants(id) {
    return KenticoManagementClient.get(`/items/${id}/variants`);
  }

  fetchLanguages() {
    return KenticoManagementClient.get(`/languages`);
  }

  /**
   * Content types
   */
   fetchContentTypes(codename) {
    return KenticoManagementClient.get(`/types`);
  }

  /**
   * Content types
   */
  fetchContentType(codename) {
    return KenticoManagementClient.get(`/types/codename/${codename}`);
  }

  static convertDateToKenticoDate(value) {
    return dayjs(value).format("YYYY-MM-DD");
  }

  /**
   *
   * @param {*} contentItemId
   * @param {*} lang
   */
  cancelScheduling(contentItemId, lang = "all") {
    return KenticoManagementClient.put(
      `/items/${contentItemId}/variants/codename/${lang}/cancel-scheduled-publish`
    );
  }
}
