export default {
  install: (app, options) => {
    let handleOutsideClick;

    app.directive("closable", {
      beforeMount(el, binding, vnode) {
        const vm = binding.instance;

        // Here's the click/touchstart handler
        // (it is registered below)
        handleOutsideClick = e => {
          e.stopPropagation();
          // Get the handler method name and the exclude array
          // from the object used in v-closable
          const { handler, exclude } = binding.value;
          // This variable indicates if the clicked element is excluded
          let clickedOnExcludedEl = false;
          exclude.forEach(refName => {
            // We only run this code if we haven't detected
            // any excluded element yet
            if (!clickedOnExcludedEl) {
              // Get the element using the reference name
              let excludedEls = document.getElementsByClassName(refName);
              if (excludedEls.length) {
                let excludedEl = excludedEls[0];

                // See if this excluded element
                // is the same element the user just clicked on

                if (excludedEl) clickedOnExcludedEl = excludedEl.contains(e.target);
              }
            }
          });
          // We check to see if the clicked element is not
          // the dialog element and not excluded
          if (!el.contains(e.target) && !clickedOnExcludedEl) {
            // If the clicked element is outside the dialog
            // and not the button, then call the outside-click handler
            // from the same component this directive is used in
            if(typeof vm[handler] !== 'undefined')
              vm[handler](e);
          }
        };
        // Register click/touchstart event listeners on the whole page
        document.addEventListener("click", handleOutsideClick);
        document.addEventListener("touchstart", handleOutsideClick);
      },
      unmounted() {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener("click", handleOutsideClick);
        document.removeEventListener("touchstart", handleOutsideClick);
      }
    });

    app.directive('click-outside', {
      beforeMount: function (el, binding, vnode) {

        const vm = binding.instance;

        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            if(binding && binding.expression)
              vm[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    });
    
  }
}