<template>
  <div :class="getCssClasses">
    <div class="page-head-section__top">
        <div class="page-head-section__top__left">
            <slot name="left">
                <BackButton :path="backButtonPath" v-if="level > 1 || backButtonPath" />
            </slot>
        </div>
        <div class="page-head-section__top__right">
            <slot name="right" />
        </div>
    </div>
    <div class="page-head-section__bg">
        <h1 class="page-title" v-if="title">{{title}}</h1>
    </div>

    <p class="page-head-section__lead" v-if="lead" v-html="this.$filters.nl2br(lead)"></p>

  </div>
</template>

<script>
export default {
    name: "PageHeadSection",
    props: {
        title: {type: String},
        lead: {type: String},
        backButtonPath: {type: String},
        level: { type: Number, default: 1 }
    },
    computed: {
        getCssClasses() {
            let ret = ['page-head-section', 'text--center'];
            ret.push('page-head-section--level-'+this.level.toString().trim().toLowerCase());
            return ret;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/abstracts/_variables.scss';
.page-head-section {
    margin: 0 0 2rem 0;

    &__top {
        display: flex;
        align-items: flex-start;
        &__left {
            text-align: left;
        }
        &__right {
            margin-left: auto;
        }
    }

    h1,h2,h3,h4,h5,h6 {
        margin-bottom: 0;
        font-family: $fontPrimaryRegular;
    }
    .lead {
        margin-top: 1rem;
    }

    &__bg {
        p:last-child {
            margin-bottom: 0;
        }
    }

    &__lead {
        margin-top: 1.8rem;
        text-align: left;
    }

    &--level-1 {
        .page-head-section__bg {
            background-color: #F8DBE0;
            padding: 1.2rem 1.5rem;
        }
        h1,h2,h3,h4,h5,h6 {
            font-size: 1.15rem;
            font-family: $fontPrimaryRegular;
        }
    }

    &--level-2 {
        text-align: left;
    }

    @media screen and (min-width: $breakpoint-md) {
        &--level-1 {
            .page-head-section__bg {
                padding-top: 1.2rem;
                padding-bottom: 1.2rem;
            }
            h1,h2,h3,h4,h5,h6 {
                font-size: 1.5rem;
            }
            .lead {
                font-size: 1.1rem;
            }
        }
    }
    @media screen and (min-width: $breakpoint-xl) {
        margin-bottom: 2.5rem;
        &--level-1 {
            &__bg {
                padding: 1.8rem 1.8rem;
            }
            h1,h2,h3,h4,h5,h6 {
                font-family: $fontPrimaryLight;
                font-size: 1.8rem;
            }
        }
    }
}
</style>