<template>
  <transition name="fade">
    <div class="comment-wrapper">
      <div class="comment" :class="{ 'comment--me': isMe, 'comment--deleted': isMessageDeleted }">
        <div class="comment__header" v-if="!isMessageDeleted">
          <div class="comment__sender">
            <ProfileIcon type="user" :isMe="isMe" :name="name" :apt="apartment" :roles="userRoles" />
            <div>
              <ProfileName :name="name" :message="this.obj" />
              <CommunityUserTagLabel :apartment="apartment" :externalName="externalName" :roles="userRoles" :conversationType="conversationType" :projectId="obj && obj.projectId ? obj.projectId : ''" />
            </div>
          </div>
          <div class="comment__date">{{ $filters.date_time_format(date,$i18n.locale) }}</div>
        </div>
        <div class="comment__body">
          <MessageEditForm
            class="message-item__content"
            :message="obj"
            v-if="editMode"
            @close="onRemoveEditMode"
          />
          <slot v-else />
          <CommunityImageGrid class="conversation__images" :images="images" />
        </div>
        <div class="comment__footer" v-if="!editMode && !isMessageDeleted">
          <LikeButton :count="likes && Array.isArray(likes) ? likes.length : 0" :liked="hasUserLiked" @on-click="like" v-if="showLikeButton" :likedUsers="likes" />
          <Button
            type="button"
            class="comment__display-messages"
            @click="toggleSubCommentList"
            v-if="!obj.parentMessage && displaySubComments"
            >
              <i class="far fa-comment-alt"></i>
              <span>{{ obj.childMessageCount ? parseInt(obj.childMessageCount) : 0 }}</span>
          </Button>
          <SubCommentButton v-if="displaySubComments" @click="toggleSubCommentForm" />
          <AdminToolbar
            :id="id"
            :showEdit="$can('update', this.obj)"
            @edit-click="onMessageEdit"
            :showDelete="$can('delete', this.obj)"
            :showBan="!isMe"
            @delete-click="onMessageDelete"
            :showConfirmOnDelete="true"
            :userId="userId"
          ></AdminToolbar>
          <div class="comment__report-message" v-if="showReportForm">
            <ReportMessage contentType="message" :targetObject="obj" ref="reportMessage" />
          </div>
        </div>
      </div>
       <div class="comment-sub-content" :class="{'has-active-form': displaySubCommentForm}">
        <slot name="sub-content"></slot>
        
        <SubCommentList :items="obj.messages" :key="'sub-comment-list__'+id" v-if="isSubCommentListVisible" :conversationType="conversationType" />
        <div v-if="loading.subMessages" class="text-center"><LoaderIcon /></div>
        <SubCommentForm v-if="displaySubCommentForm" :parent="obj" @on-cancel="toggleSubCommentForm" @response-posted="onAfterResponsePosted" id="sub-comment-form" />
      </div>
    </div>
  </transition>
</template>
<script>
import dayjs from "dayjs";
import SearchInput from "@/components/common/Form/SearchInput";
import AdminToolbar from "@/components/Admin/Common/Toolbar";
import MessageEditForm from "@/components/Community/MessageEditForm";
import { mapActions, mapGetters } from "vuex";
import ReportMessage from "@/components/Community/ReportMessage";
import store from "@/store";
import ProfileIcon from "@/components/Community/ProfileIcon";
import LikeButton from "@/components/Community/LikeButton";
import CommunityImageGrid from "@/components/Community/ImageGrid";
import SubCommentList from "./SubCommentList";
import SubCommentButton from "./SubCommentButton";
import SubCommentForm from "./SubCommentForm";
import CommunityUserTagLabel from "@/components/Community/UserTagLabel";
import ProfileName from "../Community/ProfileName.vue";

export default {
  name: "Message",
  props: {
    id: String,
    name: String,
    apartment: String,
    externalName: String,
    userRoles: {
      type: Array
    },
    obj: Object,
    date: String,
    userId: String,
    showReportForm: {
      type: Boolean,
      default: false
    },
    images: Array,
    likes: Array,
    showLikeButton: {
      type: Boolean,
      default: false
    },
    displaySubComments: {type: Boolean, default: false},
    conversationType: {type: String, default: 'public'}
  },
  components: {
    AdminToolbar,
    MessageEditForm,
    ReportMessage,
    ProfileIcon,
    LikeButton,
    CommunityImageGrid,
    SubCommentList,
    SubCommentButton,
    SubCommentForm,
    CommunityUserTagLabel,
    ProfileName
},
  data() {
    return {
      editMode: false,
      displaySubCommentForm: false,
      isSubCommentListVisible: false,
      loading: {
        subMessages: false
      }
    };
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    isMe() {
      if (this.getCurrentUser.getId() && this.userId) {
        if (this.getCurrentUser.getId() === this.userId) return true;
      }

      return false;
    },
    hasUserLiked() {
      if(this.likes) {
        let liked = false;
        let userId = this.getCurrentUser.getId();
        this.likes.map(l => {
          if(l.userUuid === userId)
            liked = true;
            return l;
        });
        return liked;
      }
      return false;
    },
    isMessageDeleted() {
      if (this.obj?.isDeleted === true) return true
      return false
    }
  },
  methods: {
    ...mapActions(["deleteMessage", "likeConversationMessage", "fetchSubMessages"]),
    onMessageEdit(id) {
      console.log("message have been edited. Yei!");
      this.editMode = true;
    },
    onMessageDelete(id) {
      console.log("Deleting message id: ", id);
      this.deleteMessage(id)
        .then(res => {
          this.$emit("after-delete");
          this.$forceUpdate()
        })
        .catch(err => this.$emit("after-delete"));
    },
    onRemoveEditMode(event) {
      this.editMode = false;
    },
    async like(value) {
      try {
        await this.likeConversationMessage(this.id);
      } catch(err) {
        console.log(err);
      }
      //this.communityApi.processLike(this.id, 'conversation');
    },
    toggleSubCommentForm() {
      console.log("toggleSubCommentForm")
      this.displaySubCommentForm = !this.displaySubCommentForm;
      if(this.displaySubCommentForm === true) {
        setTimeout(() => {
          this.scrollToTop();
        }, 1);
      }
    },
    scrollToTop() {
      let container = this.$el.querySelector("#sub-comment-form");
      if (container) {
        container.scrollIntoView({
          block: "center",
          inline: "start",
          behavior: "smooth"
        });
      }
    },
    toggleSubCommentList() {
      this.isSubCommentListVisible = !this.isSubCommentListVisible;
      if(this.isSubCommentListVisible === true) {
        this.fetchMessages();
      }
    },
    async fetchMessages() {
      let payload = {
        conversation: this.$route.params.id,
        parent: this.id
      }

      try {
        this.loading.subMessages = true;
        await this.fetchSubMessages(payload);
      } catch(err) {
        console.log(err);
      } finally {
        this.loading.subMessages = false;
      }
    },
    onAfterResponsePosted() {
      this.fetchMessages();
      this.isSubCommentListVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
#sub-comment-form {
  scroll-margin-top: 82px;
  scroll-snap-margin-top: 82px;
}
.comment-wrapper {
  margin-bottom: 1rem;
  @media screen and (min-width: $breakpoint-md) {
    margin-bottom: 0;
  }
}
</style>
<style lang="scss">
.has-active-form {
  .sub-comment-list {
    padding-bottom: 0;
  }
}
</style>