import BaseService from "./BaseService";
import AzureFunctionClient from "./AzureFunctionClient";

export default class IOTAppService extends BaseService {
  constructor() {
    super();
  }
  
  /**
   * 
   */
  getLetterTemplates(params = {}) {
    return AzureFunctionClient.get(this._buildUrl(`/email-letter/template`, params));
  }

  /**
   * 
   * @param {*} params 
   * @returns 
   */
  getLetterTemplate(id, params = {}) {

    return AzureFunctionClient.get(this._buildUrl(`/email-letter/template/${id}`, params));
  }

  /**
   * 
   * @param {*} params 
   * @returns 
   */
  getEmailLetters(params = {}) {
    if (!params || !params.projectId)
      throw "ProjectId is required to get email letters.";
    let query = this._buildUrl(`/email-letter/message`, params);
    return AzureFunctionClient.get(query);
  }

  /**
   * 
   * @param {*} id 
   * @returns 
   */
  getEmailLetter(id) {
    return AzureFunctionClient.get(`/email-letter/message/${id}`);
  }

  /**
   * 
   * @param {*} params 
   * @returns 
   */
  createEmailLetter(params = {}) {
    return AzureFunctionClient.post(`/email-letter/message`, params);
  }

  /**
   * 
   * @param {*} id 
   * @param {*} params 
   * @returns 
   */
  updateEmailLetter(id, params = {}) {
    if (!id)
      throw "id must be defined in order updating email letter";
    return AzureFunctionClient.put(`/email-letter/message/${id}`, params);
  }

  /**
   * 
   * @param {*} id 
   * @returns 
   */
  deleteEmailLetter(id) {
    return AzureFunctionClient.delete(`/email-letter/message/${id}`);
  }

  /**
   * 
   * @param {*} id 
   * @param {*} payload 
   * @returns 
   */
  sendTestEmailLetter(id, payload) {
    payload.test = true;
    return AzureFunctionClient.post(`/email-letter/message/${id}/send`, payload);
  }

  /**
   * 
   * @param {*} id 
   * @param {*} payload 
   * @returns 
   */
  sendEmailLetter(id, payload) {
    return AzureFunctionClient.post(`/email-letter/message/${id}/send`, payload);
  }

  /**
   * 
   * @param {*} id 
   * @returns 
   */
  getEmailLetterLogs(id) {
    return AzureFunctionClient.get(`/email-letter/message/${id}/logs`);
  }

  /**
   * 
   * @param {*} id 
   * @returns 
   */
  retrieveEmailLetterLogs(id) {
    return AzureFunctionClient.get(`/email-letter/message/${id}/retrieve-logs`);
  }
}