<template>
  <div class="community-message-form">
      <div class="community-message-form__content">
          <slot />
      </div>
      <div class="community-message-form__footer">
          <slot name="footer" />
      </div>
  </div>
</template>

<script>
export default {
    name: "CommunityMessageForm"
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";
.community-message-form {
    border: 1px solid $borderColorDefault;
    background: #fff;
    margin-bottom: 1.5rem;
    &__content {
        padding: 1rem;
        input, .textarea--styled {
            border: 0px;
            padding: 0;
        }
        .input-group {
            margin: 0;
            padding: 0;
        }
        textarea {
            border: 0;
            padding: 0;
            resize: none;
        }
    }
    &__footer {
        border-top: 1px solid $borderColorDefault;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0rem 1rem 0 1rem;
    }
}
</style>