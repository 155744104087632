<template>
    <div>
        <YitCorporateNewsList :items="getYitCorporateArticles" :loading="loading" />
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import YitCorporateNewsList from "./List";

export default {
    name: 'YitCorporateNews',
    components: {
        YitCorporateNewsList
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters(['getYitCorporateArticles'])
    },
    methods: {
        ...mapActions(['fetchYitCorporateArticles'])
    },
    async mounted() {
        try {
            await this.fetchYitCorporateArticles();
        } catch(err){
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
</script>

<style>

</style>