<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <main id="main-content">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "LayoutFullWidthNarrow",
  components: {

  }
};
</script>