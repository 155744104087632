import One4AllService from "@/services/One4allService";
import store from "..";

const getDefaultState = () => {
  return {
    one4allData: null,
    bookings: [],
    modules: [],
    selectedModule: null,
    pin: null
  }
};

const state = getDefaultState();

const getters = {
  getOne4allData: state => state.one4allData,
  getOne4AllPin: state => state.pin,
  getOne4AllModules: state => state.modules,
  getOne4AllModule: state => uuid => state.modules.find(m => m.uuid === uuid),
  getCurrentOne4AllModule: state => state.selectedModule,
  getOne4AllBookings: state => state.bookings,
  getOne4AllBooking: state => uuid => state.bookings.find(m => m.uuid === uuid),
};

const actions = {
  async fetchOne4AllPin({ commit }) {
    const api = new One4AllService();
    const res = await api.getPin()
    commit("setOne4AllPin", res.data.pin);
  },
  async fetchOne4AllBookings({ commit, state }, params = {}) {

    if (!state.modules.length)
      await store.dispatch("fetchOne4AllModules");

    const api = new One4AllService();
    if (!state.selectedModule)
      return []
    const moduleUuid = state.selectedModule.uuid;
    const res = await api.getBookings(moduleUuid, params)
    commit("setOne4AllBookings", res.data);
    return res.data;
  },
  async fetchOne4AllModules({ commit }) {
    const api = new One4AllService();
    try {
      const res = await api.getModules()
      commit("setOne4AllModules", res.data);
      if (Array.isArray(res.data) && res.data.length === 1) 
        commit("setCurrentOne4AllModule", res.data[0]);
      
      return res.data;
    } catch(err) {
      console.log(err);
    }
  }
};

const mutations = {
  setOne4allData: (state, res) => (state.one4allData = res),
  setOne4AllPin: (state, res) => (state.pin = res),
  setOne4AllBookings: (state, res = []) => (state.bookings = Array.isArray(res) ? res : []),
  setOne4AllModules: (state, res = []) => (state.modules = res),
  setCurrentOne4AllModule: (state, res = []) => (state.selectedModule = res)
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
